import { useTranslation } from 'react-i18next';

export default function localeFunction() {
  const { t } = useTranslation();
  return {
    moduleType: 'locale',
    name: 'lang',
    dictionary: {
      Autoscale: t('plotly.dictionary.autoscale'),
      'Box Select': t('plotly.dictionary.box-select'),
      'Click to enter Colorscale title': t(
        'plotly.dictionary.click-to-enter-colorscale-title'
      ),
      'Click to enter Component A title': t(
        'plotly.dictionary.click-to-enter-component-a-title'
      ),
      'Click to enter Component B title': t(
        'plotly.dictionary.click-to-enter-component-b-title'
      ),
      'Click to enter Component C title': t(
        'plotly.dictionary.click-to-enter-component-c-title'
      ),
      'Click to enter Plot title': t(
        'plotly.dictionary.click-to-enter-plot-title'
      ),
      'Click to enter X axis title': t(
        'plotly.dictionary.click-to-enter-x-axis-title'
      ),
      'Click to enter Y axis title': t(
        'plotly.dictionary.click-to-enter-y-axis-title'
      ),
      'Compare data on hover': t('plotly.dictionary.compare-data-on-hover'),
      'Double-click on legend to isolate one trace': t(
        'plotly.dictionary.double-click-on-legend-to-isolate-one-trace'
      ),
      'Double-click to zoom back out': t(
        'plotly.dictionary.double-click-to-zoom-back-out'
      ),
      'Download plot as a png': t('plotly.dictionary.download-plot-as-a-png'),
      'Download plot': t('plotly.dictionary.download-plot'),
      'Edit in Chart Studio': t('plotly.dictionary.edit-in-chart-studio'),
      'IE only supports svg.  Changing format to svg.': t(
        'plotly.dictionary.ie-only-supports-svg-changing-format-to-svg'
      ),
      'Lasso Select': t('plotly.dictionary.lasso-select'),
      'Orbital rotation': t('plotly.dictionary.orbital-rotation'),
      Pan: t('plotly.dictionary.pan'),
      'Produced with Plotly.js': t('plotly.dictionary.produced-with-plotly-js'),
      Reset: t('plotly.dictionary.reset'),
      'Reset axes': t('plotly.dictionary.reset-axes'),
      'Reset camera to default': t('plotly.dictionary.reset-camera-to-default'),
      'Reset camera to last save': t(
        'plotly.dictionary.reset-camera-to-last-save'
      ),
      'Reset view': t('plotly.dictionary.reset-view'),
      'Reset views': t('plotly.dictionary.reset-views'),
      'Show closest data on hover': t(
        'plotly.dictionary.show-closest-data-on-hover'
      ),
      'Snapshot succeeded': t('plotly.dictionary.snapshot-succeeded'),
      'Sorry, there was a problem downloading your snapshot!': t(
        'plotly.dictionary.sorry-there-was-a-problem-downloading-your-snapshot'
      ),
      'Taking snapshot - this may take a few seconds': t(
        'plotly.dictionary.taking-snapshot-this-may-take-a-few-seconds'
      ),
      Zoom: t('plotly.dictionary.zoom'),
      'Zoom in': t('plotly.dictionary.zoom-in'),
      'Zoom out': t('plotly.dictionary.zoom-out'),
      'close:': t('plotly.dictionary.close'),
      trace: t('plotly.dictionary.trace'),
      'lat:': t('plotly.dictionary.lat'),
      'lon:': t('plotly.dictionary.lon'),
      'q1:': t('plotly.dictionary.q1'),
      'q3:': t('plotly.dictionary.q3'),
      'source:': t('plotly.dictionary.source'),
      'target:': t('plotly.dictionary.target'),
      'lower fence:': t('plotly.dictionary.lower-fence'),
      'upper fence:': t('plotly.dictionary.upper-fence'),
      'max:': t('plotly.dictionary.max'),
      'mean ± σ:': t('plotly.dictionary.mean-with-deviation'),
      'mean:': t('plotly.dictionary.mean'),
      'median:': t('plotly.dictionary.median'),
      'min:': t('plotly.dictionary.min'),
      'Turntable rotation': t('plotly.dictionary.turntable-rotation'),
      'Toggle Spike Lines': t('plotly.dictionary.toggle-spike-lines'),
      'open:': t('plotly.dictionary.open'),
      'high:': t('plotly.dictionary.high'),
      'low:': t('plotly.dictionary.low'),
      'Toggle show closest data on hover': t(
        'plotly.dictionary.toggle-show-closest-data-on-hover'
      ),
      'incoming flow count:': t('plotly.dictionary.incoming-flow-count'),
      'outgoing flow count:': t('plotly.dictionary.outgoing-flow-count'),
      'kde:': t('plotly.dictionary.kde'),
    },
    format: {
      days: [
        t('plotly.days.Sun'),
        t('plotly.days.Mon'),
        t('plotly.days.Tue'),
        t('plotly.days.Wed'),
        t('plotly.days.Thu'),
        t('plotly.days.Fri'),
        t('plotly.days.Sat'),
      ],
      shortDays: [
        t('plotly.short-days.Sun'),
        t('plotly.short-days.Mon'),
        t('plotly.short-days.Tue'),
        t('plotly.short-days.Wed'),
        t('plotly.short-days.Thu'),
        t('plotly.short-days.Fri'),
        t('plotly.short-days.Sat'),
      ],
      months: [
        t('plotly.months.Janunary'),
        t('plotly.months.February'),
        t('plotly.months.March'),
        t('plotly.months.April'),
        t('plotly.months.May'),
        t('plotly.months.June'),
        t('plotly.months.July'),
        t('plotly.months.August'),
        t('plotly.months.September'),
        t('plotly.months.October'),
        t('plotly.months.November'),
        t('plotly.months.December'),
      ],
      shortMonths: [
        t('plotly.short-months.Janunary'),
        t('plotly.short-months.February'),
        t('plotly.short-months.March'),
        t('plotly.short-months.April'),
        t('plotly.short-months.May'),
        t('plotly.short-months.June'),
        t('plotly.short-months.July'),
        t('plotly.short-months.August'),
        t('plotly.short-months.September'),
        t('plotly.short-months.October'),
        t('plotly.short-months.November'),
        t('plotly.short-months.December'),
      ],
      date: '%Y-%m-%d',
    },
  };
}
