import { useMutation, useQuery, useQueryClient } from 'react-query';
import { client } from 'src/utils/api-client';
import {
  CbamInstallationInSchema,
  CbamInstallationOutSchema,
  CbamElectricitySchema,
  CbamProductionProcessOutSchema,
  CbamImportedGoodOutSchema,
  CbamImportedGoodInSchema,
  CbamProductionProcessSurveyInSchema,
  FuelTypesOutSchema,
  CbamActorOutSchema,
  CbamActorInSchema,
  ProductionProcessGeneralInformation,
  SectorSpecificSchema,
  CbamHeatSchema,
  CbamWasteGasOutSchema,
  CbamWasteGasInSchema,
  CbamDirecrtEmissionsSchema,
  CbamDirectCombustionEmissionOutSchema,
  CbamDirectCombustionEmissionInSchema,
  CbamDirectProcessEmissionOutSchema,
  CbamDirectProcessEmissionInSchema,
  CbamMassBalanceInSchema,
  CbamMassBalanceOutSchema,
  CbamMeasurementBasedOutSchema,
  CbamMeasurementBasedInSchema,
  CbamCo2TransferSchema,
  CbamPrecursorInSchema,
  CbamPrecursorOutSchema,
  CbamReportOutSchema,
  CbamReportInSchema,
  CbamInstallationOperatorOutSchema,
  CbamInstallationOperatorInSchema,
  CbamSupportingDocumentForImportedGoodOutSchema,
  CbamSupportingDocumentForImportedGoodInSchema,
  CbamSupportingDocumentForProductionProcessInSchema,
  CbamSupportingDocumentForProductionProcessOutSchema,
} from './types';
import { toast } from 'react-toastify';

export const useInstallations = (productionProcessId?: string) => {
  const url = '/web/cbam/installations';
  const queryClient = useQueryClient();
  const queryKey = ['installations', { productionProcessId }];

  const { data } = useQuery(queryKey, () =>
    client
      .get<CbamInstallationOutSchema[]>(url, {
        params: productionProcessId
          ? { production_process_id: productionProcessId }
          : {},
      })
      .then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (data: Partial<CbamInstallationOutSchema>) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamInstallationInSchema) => client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return {
    data: (data || []).sort((a, b) => a.id - b.id),
    update,
    create,
    delete: _delete,
  };
};

export const useOperators = () => {
  const url = '/web/cbam/operators';
  const queryClient = useQueryClient();
  const queryKey = 'operators';

  const { data } = useQuery(queryKey, () =>
    client.get<CbamInstallationOperatorOutSchema[]>(url).then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (data: Partial<CbamInstallationOperatorOutSchema>) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamInstallationOperatorInSchema) =>
      client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return {
    data: (data || []).sort((a, b) => a.id - b.id),
    update,
    create,
    delete: _delete,
  };
};

export const useProductionProcesses = () => {
  const url = '/web/cbam/production-processes';
  const queryClient = useQueryClient();
  const queryKey = 'production-processes';

  const { data } = useQuery(queryKey, () =>
    client.get<CbamProductionProcessOutSchema[]>(url).then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (data: Partial<CbamProductionProcessOutSchema>) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: ProductionProcessGeneralInformation) =>
      client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const updateSurvey = useMutation({
    mutationFn: (data: CbamProductionProcessSurveyInSchema & { id: number }) =>
      client.put(`${url}/${data.id}/survey`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data: data || [], update, create, delete: _delete, updateSurvey };
};

export const useImportedGoods = (reportId?: string) => {
  const url = '/web/cbam/imported-goods';
  const queryClient = useQueryClient();
  const queryKey = 'imported-goods';

  const { data } = useQuery([queryKey, { reportId }], () =>
    client
      .get<CbamImportedGoodOutSchema[]>(url, {
        params: reportId ? { report_id: reportId } : {},
      })
      .then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (data: Partial<CbamImportedGoodOutSchema>) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamImportedGoodInSchema) => client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data: data || [], update, create, delete: _delete };
};

export const useFuelTypes = () => {
  const queryKey = 'fuel-types';

  const query = useQuery(queryKey, () =>
    client
      .get<FuelTypesOutSchema>('/web/cbam/fuel-types')
      .then((res) => res.data)
  );

  return { ...query, data: query.data || {} };
};

export const useActors = () => {
  const url = '/web/cbam/actors';
  const queryClient = useQueryClient();
  const queryKey = 'actors';

  const { data } = useQuery(queryKey, () =>
    client.get<CbamActorOutSchema[]>(url).then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (data: Partial<CbamActorOutSchema>) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamActorInSchema) => client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return {
    data: data || [],
    update,
    create,
    delete: _delete,
  };
};

export const useSectorSpecific = (id: number) => {
  const url = `/web/cbam/production-processes/${id}/sector-specific`;
  const queryClient = useQueryClient();
  const queryKey = ['production-process', id, 'sector-specific'];

  const { data } = useQuery(
    queryKey,
    () => client.get<SectorSpecificSchema>(url).then((res) => res.data),
    { enabled: !!id }
  );

  const update = useMutation({
    mutationFn: (data: SectorSpecificSchema) => client.put(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data, update };
};

export const useElectricity = (id: number) => {
  const url = `/web/cbam/production-processes/${id}/electricity`;
  const queryClient = useQueryClient();
  const queryKey = ['production-process', id, 'electricity'];

  const { data } = useQuery(
    queryKey,
    () => client.get<CbamElectricitySchema>(url).then((res) => res.data),
    { enabled: !!id }
  );

  const update = useMutation({
    mutationFn: (data: CbamElectricitySchema) => client.put(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data, update };
};

export const useHeat = (id: number) => {
  const url = `/web/cbam/production-processes/${id}/heat`;
  const queryClient = useQueryClient();
  const queryKey = ['production-process', id, 'heat'];

  const { data } = useQuery(
    queryKey,
    () => client.get<CbamHeatSchema>(url).then((res) => res.data),
    { enabled: !!id }
  );

  const update = useMutation({
    mutationFn: (data: CbamHeatSchema) => client.put(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data, update };
};

export const useCo2Transfer = (id: number) => {
  const url = `/web/cbam/production-processes/${id}/co2-transfer`;
  const queryClient = useQueryClient();
  const queryKey = ['production-process', id, 'co2-transfet'];

  const { data } = useQuery(
    queryKey,
    () => client.get<CbamCo2TransferSchema>(url).then((res) => res.data),
    { enabled: !!id }
  );

  const update = useMutation({
    mutationFn: (data: CbamCo2TransferSchema) => client.put(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data, update };
};

export const useWasteGasesConsumed = (productionProcessId?: number) => {
  const url = `/web/cbam/waste-gases-consumed`;
  const queryClient = useQueryClient();
  const queryKey = ['waste-gases-consumed', { productionProcessId }];

  const { data } = useQuery(queryKey, () =>
    client
      .get<CbamWasteGasOutSchema[]>(url, {
        params: { production_process_id: productionProcessId },
      })
      .then((res) => res.data)
  );

  const create = useMutation({
    mutationFn: (data: CbamWasteGasInSchema) => client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  const update = useMutation({
    mutationFn: (data: CbamWasteGasOutSchema) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  return { data: data || [], create, update, delete: _delete };
};

export const useReports = () => {
  const url = '/web/cbam/reports';
  const queryClient = useQueryClient();
  const queryKey = 'reports';

  const { data } = useQuery('reports', () =>
    client.get<CbamReportOutSchema[]>(url).then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (data: Partial<CbamReportOutSchema>) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamReportInSchema) => client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data: data || [], create, update, delete: _delete };
};

export const useWasteGasesExported = (productionProcessId: number) => {
  const url = `/web/cbam/waste-gases-exported`;
  const queryClient = useQueryClient();
  const queryKey = ['waste-gases-exported', { productionProcessId }];

  const { data } = useQuery(
    queryKey,
    () =>
      client
        .get<CbamWasteGasOutSchema[]>(url, {
          params: { production_process_id: productionProcessId },
        })
        .then((res) => res.data),
    { enabled: !!productionProcessId }
  );

  const create = useMutation({
    mutationFn: (data: CbamWasteGasInSchema) => client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  const update = useMutation({
    mutationFn: (data: CbamWasteGasOutSchema) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data: data || [], create, update, delete: _delete };
};

export const useDirectEmissions = (id: number) => {
  const url = `/web/cbam/production-processes/${id}/direct-emissions`;
  const queryClient = useQueryClient();
  const queryKey = ['production-process', id, 'direact-emissions'];

  const { data } = useQuery(
    queryKey,
    () => client.get<CbamDirecrtEmissionsSchema>(url).then((res) => res.data),
    { enabled: !!id }
  );

  const update = useMutation({
    mutationFn: (data: CbamDirecrtEmissionsSchema) => client.put(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data, update };
};

export const useDirectCombustionEmissions = (productionProcessId?: number) => {
  const url = `/web/cbam/direct-emissions-combustion`;
  const queryClient = useQueryClient();
  const queryKey = ['direct-combustion-emissions', { productionProcessId }];

  const { data } = useQuery(queryKey, () =>
    client
      .get<CbamDirectCombustionEmissionOutSchema[]>(url, {
        params: { production_process_id: productionProcessId },
      })
      .then((res) => res.data)
  );

  const create = useMutation({
    mutationFn: (data: CbamDirectCombustionEmissionInSchema) =>
      client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  const update = useMutation({
    mutationFn: (data: CbamDirectCombustionEmissionOutSchema) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data: data || [], create, update, delete: _delete };
};

export const useDirectProcessEmissions = (productionProcessId?: number) => {
  const url = `/web/cbam/direct-emissions-process`;
  const queryClient = useQueryClient();
  const queryKey = ['direct-process-emissions', { productionProcessId }];

  const { data } = useQuery(queryKey, () =>
    client
      .get<CbamDirectProcessEmissionOutSchema[]>(url, {
        params: { production_process_id: productionProcessId },
      })
      .then((res) => res.data)
  );

  const create = useMutation({
    mutationFn: (data: CbamDirectProcessEmissionInSchema) =>
      client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  const update = useMutation({
    mutationFn: (data: CbamDirectProcessEmissionOutSchema) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data: data || [], create, update, delete: _delete };
};

export const useMassBalance = (productionProcessId?: number) => {
  const url = `/web/cbam/mass-balance`;
  const queryClient = useQueryClient();
  const queryKey = ['mass-balance', { productionProcessId }];

  const { data } = useQuery(queryKey, () =>
    client
      .get<CbamMassBalanceOutSchema[]>(url, {
        params: { production_process_id: productionProcessId },
      })
      .then((res) => res.data)
  );

  const create = useMutation({
    mutationFn: (data: CbamMassBalanceInSchema) => client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  const update = useMutation({
    mutationFn: (data: CbamMassBalanceOutSchema) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data: data || [], create, update, delete: _delete };
};

export const useMeasurementBased = (productionProcessId?: number) => {
  const url = `/web/cbam/measurement-based`;
  const queryClient = useQueryClient();
  const queryKey = ['measurement-based', { productionProcessId }];

  const { data } = useQuery(queryKey, () =>
    client
      .get<CbamMeasurementBasedOutSchema[]>(url, {
        params: { production_process_id: productionProcessId },
      })
      .then((res) => res.data)
  );

  const create = useMutation({
    mutationFn: (data: CbamMeasurementBasedInSchema) => client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  const update = useMutation({
    mutationFn: (data: CbamMeasurementBasedOutSchema) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data: data || [], create, update, delete: _delete };
};

export const usePrecursors = (productionProcessId?: number) => {
  const url = `/web/cbam/precursors`;
  const queryClient = useQueryClient();
  const queryKey = ['precursors', { productionProcessId }];

  const { data } = useQuery(queryKey, () =>
    client
      .get<CbamPrecursorOutSchema[]>(url, {
        params: { production_process_id: productionProcessId },
      })
      .then((res) => res.data)
  );

  const create = useMutation({
    mutationFn: (data: CbamPrecursorInSchema) => client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  const update = useMutation({
    mutationFn: (data: CbamPrecursorOutSchema) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });
  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return { data: data || [], create, update, delete: _delete };
};

export const useSupportingDocumentsForImportedGoods = () => {
  const url = '/web/cbam/supporting-documents-for-imported-goods';
  const queryClient = useQueryClient();
  const queryKey = 'supporting-documents-for-imported-goods';

  const { data } = useQuery(queryKey, () =>
    client
      .get<CbamSupportingDocumentForImportedGoodOutSchema[]>(url)
      .then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (
      data: Partial<CbamSupportingDocumentForImportedGoodOutSchema>
    ) => client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamSupportingDocumentForImportedGoodInSchema) =>
      client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return {
    data: data || [],
    update,
    create,
    delete: _delete,
  };
};

export const useSupportingDocumentsForProductionProcesses = () => {
  const url = '/web/cbam/supporting-documents-for-production-processes';
  const queryClient = useQueryClient();
  const queryKey = 'supporting-documents-for-production-processes';

  const { data } = useQuery(queryKey, () =>
    client
      .get<CbamSupportingDocumentForProductionProcessOutSchema[]>(url)
      .then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (
      data: Partial<CbamSupportingDocumentForProductionProcessOutSchema>
    ) => client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const create = useMutation({
    mutationFn: (data: CbamSupportingDocumentForProductionProcessInSchema) =>
      client.post(url, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  const _delete = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success('Success');
    },
  });

  return {
    data: data || [],
    update,
    create,
    delete: _delete,
  };
};
