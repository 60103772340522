import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/redux-file/hooks';
import { client } from 'src/utils/api-client';
import { setLoading, setInitialRequest } from 'src/redux-file/platform/slice';

import Loader, { Container } from 'src/components/Loader';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import { useQuery } from 'react-query';
import { InitialRequestSchema } from '../redux-file/platform/slice.types';
import { LocalStorageKeys } from '../common/constants';
import { useLanguage } from '../common/hooks';

export default function InitialRequest(props: any) {
  const dispatch = useDispatch();
  const { isLoading, error } = useAppSelector((state) => state.platform);
  const { setLanguage } = useLanguage();

  const fetchInitialRequest = async () => {
    const res = await client.get<InitialRequestSchema>('/web/users/me');
    return res.data;
  };

  const query = useQuery(['platform', 'initial-request'], fetchInitialRequest, {
    onSuccess: (data) => {
      dispatch(setInitialRequest(data));
      const syncedLanguageUserId = localStorage.getItem(
        LocalStorageKeys.LANGUAGE_SYNC_USER_ID
      );
      const userId = `${data.id}`;
      if (syncedLanguageUserId !== userId) {
        // we haven't yet synced the language for this user
        setLanguage(data.language);
        localStorage.setItem(LocalStorageKeys.LANGUAGE_SYNC_USER_ID, userId);
      }
    },
    refetchOnMount: 'always',
  });

  React.useEffect(() => {
    dispatch(setLoading(query.isLoading));
  }, [query.isLoading]);

  if (error)
    return (
      <Container>
        <SomethingWentWrong errorMessege={error.msg} />
      </Container>
    );

  if (isLoading) return <Loader />;

  return props.children;
}
