import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from '@mui/material';

const T_KEY = 'questionnaireV3.summary.buttons';

interface Props {
  format: 'csv' | 'pdf';
}

export default function DownloadButton({
  format,
  ...props
}: Props & ButtonProps) {
  const { t } = useTranslation();
  const text = t(
    `${T_KEY}.${format === 'csv' ? 'downloadCsv' : 'downloadPdf'}`
  );
  return (
    <Button variant="outlined" {...props}>
      {text}
    </Button>
  );
}
