import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { StyledFlex } from '../../components/StyledFlex';
import SupportingDocumentForm from './Form';
import { SupportingDocumentUsage } from '../types';
import {
  useSupportingDocumentsForImportedGoods,
  useSupportingDocumentsForProductionProcesses,
} from '../hooks';

export default function SupportingDocument() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const usage = location.state.usage as SupportingDocumentUsage;

  const { data } =
    usage === SupportingDocumentUsage.ForImportedGood
      ? useSupportingDocumentsForImportedGoods()
      : useSupportingDocumentsForProductionProcesses();

  const documentId = Number(id);
  const document = (data as any).find(
    (document: any) => document.id === documentId
  );

  const usageString =
    usage === SupportingDocumentUsage.ForImportedGood
      ? 'for Imported Good'
      : 'for Production Process';

  return (
    <Box>
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <IconButton onClick={() => navigate('..')}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="h2">
          Supporting Document ({usageString}):{' '}
          {document?.reference_number || 'New'}
        </Typography>
      </StyledFlex>
      <br />
      <SupportingDocumentForm
        key={data.length}
        id={documentId}
        onSaved={() => navigate('..')}
      />
    </Box>
  );
}
