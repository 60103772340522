import React from 'react';
import HelpIcon from '@mui/icons-material/Help';
import ApiIcon from '@mui/icons-material/Api';

import { appConfig } from '../config';
import SidebarExternalNavItem from '../views/components/Sidebar/ExternalNavItem';
import { usePlatformNavigation } from '../utils/navigation';
import { ProductPlatform } from '../utils/api.interfaces';
import { useTranslation } from 'react-i18next';
import { usePlatform } from '../redux-file/hooks';
import { OrganizationTag, hasTag } from '../utils/tags';
import { helpCenterPdfs } from './constants';

export const useHelpCenterUrl = () => {
  const { activeOrganization } = usePlatform();
  const { i18n } = useTranslation();
  const { activeProduct } = usePlatformNavigation();
  let lang = i18n.language.toLowerCase();
  let fileName = helpCenterPdfs[activeProduct!];
  if (!fileName) return '#';
  // special case for mBNP (no scope 3)
  if (
    activeProduct === ProductPlatform.Co2 &&
    activeOrganization &&
    hasTag(activeOrganization, OrganizationTag.HideScope3)
  ) {
    fileName = 'GHG-12.pdf';
    lang = 'pl'; // only available in Polish
  }
  return `https://assets.envirly.pl/intro/${lang}/${fileName}`;
};

export function HelpCenterNavItem() {
  const url = useHelpCenterUrl();

  return (
    <SidebarExternalNavItem
      to={url}
      icon={<HelpIcon />}
      textKey="menu.helpCenter"
    />
  );
}

export function ApiDocsNavItem() {
  const apiUrl = `${appConfig.backendApiUrl}/docs`;

  return (
    <SidebarExternalNavItem to={apiUrl} icon={<ApiIcon />} textKey="menu.api" />
  );
}
