import styled from 'styled-components';

export const CalendarOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const CalendarWrapper = styled.div`
  position: absolute;
  z-index: 3;
  right: 40px;
`;

export const CalendarButton = styled.button<{
  calendarVariant: 'mini' | 'normal' | undefined;
}>`
  background-color: #fff;
  height: 32px;
  width: ${({ calendarVariant }) =>
    calendarVariant === 'normal' ? 'auto' : '32px'};
  border: 1px solid #d9dfdd;
  border-radius: 4px;
  padding: ${({ calendarVariant }) =>
    calendarVariant === 'normal' ? '0 10px ' : '0'};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  img {
    width: 12px;
  }

  span {
    margin-right: 8px;
    text-transform: capitalize;
  }
`;
