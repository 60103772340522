import { Text, View, Link } from '@react-pdf/renderer';
import React from 'react';
import { RowQuestionField } from '../../../../components/ESGSummary/types';
import { Li } from '../components';
import { hasValue } from 'src/views/components/QuestionnaireV3/Summary/SummaryTable/utils';
import { linkStyles } from '../index.styles';
import * as Sentry from '@sentry/react';

const parseMultiValue = (field: RowQuestionField, fieldValue: any) => {
  if (field.type === 'multifile') {
    return (
      <Text>
        {field.label && (
          <Text>
            {field.label}:{'\n'}
          </Text>
        )}
        {fieldValue.map((x: { id: number; url: string; name: string }) => (
          <Link key={x.id} src={x.url} style={linkStyles.link}>
            {x.name}
            {'\n'}
          </Link>
        ))}
      </Text>
    );
  }
  if (field.type === 'file')
    return (
      <Link src={fieldValue.url} style={linkStyles.link}>
        {fieldValue.name}
      </Link>
    );
  if (field.type === 'boolean') return field.label;
  else if (field.type === 'multiselect')
    return `${field.label ? `${field.label}: ` : ''}${
      fieldValue.join(', ') || '-'
    }`;
  if (['text', 'decimal'].includes(field.type))
    return `${field.label ? `${field.label}: ` : ''}${
      hasValue(fieldValue) ? fieldValue : '-'
    }`;
  if (fieldValue === 0) return 0;
  if (typeof fieldValue === 'object')
    throw new Error(
      `FieldValue is an unhandled object: ${JSON.stringify(fieldValue)}`
    );
  return hasValue(fieldValue) ? fieldValue : '-';
};

const parseYesOrNoValue = (field: RowQuestionField, fieldValue: any) => {
  if (field.type === 'boolean') return null;
  else if (field.type === 'multiselect')
    return fieldValue.map((item: string) => <Li key={item} value={item} />);
  return <Li value={fieldValue} />;
};

export function MultianswerField({ field }: { field: RowQuestionField }) {
  const fieldValue = Object.values(field.answer)[0];
  // no answer
  if (!hasValue(fieldValue)) return null;
  try {
    return <Li value={parseMultiValue(field, fieldValue)} />;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    return <Li value="" />;
  }
}

export function DetailField({ field }: { field: RowQuestionField }) {
  const fieldValue = Object.values(field.answer)[0];

  // no answer
  if (!hasValue(fieldValue)) return null;

  return (
    <View style={{ marginTop: '20px' }}>
      <Text style={{ fontStyle: 'bold' }}>{field.label}</Text>
      {parseYesOrNoValue(field, fieldValue)}
    </View>
  );
}
