import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { client } from 'src/utils/api-client';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PasswordVerificationForm from 'src/components/PasswordVerificationForm';
import { ChangePasswordSchema, FormErrors, validateForm } from './form';
import { useMutation } from 'react-query';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import TextField from 'src/components/TextField';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function ChangePasswordDialog(props: Props) {
  const { t } = useTranslation();
  const { open, onClose } = props;
  const initialForm: ChangePasswordSchema = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  };
  const [form, setForm] = useState<ChangePasswordSchema>(initialForm);
  const [errors, setErrors] = useState<FormErrors>({});

  const handleClose = () => {
    setForm(initialForm);
    onClose();
  };

  const mutation = useMutation({
    mutationFn: () =>
      client.post('/web/settings_v2/my-account/change-password', form),
    onError: (err: any) => {
      const extractedErrors = extractSchemaErrors(err, form);
      setErrors(extractedErrors);
    },
    onSuccess: () => {
      handleClose();
      toast.success(t('superadmin.myAccount.changePassword.success') as string);
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogContent>
        <Typography variant="h4" sx={{ p: '20px 0px' }}>
          {t('superadmin.myAccount.changePassword.title')}
        </Typography>
        <Box sx={{ mb: '16px' }}>
          <TextField
            fullWidth
            label={t(`superadmin.myAccount.changePassword.oldPassword.label`)}
            placeholder={t(
              `superadmin.myAccount.changePassword.oldPassword.placeholder`
            )}
            value={form.old_password}
            onChange={(e) => setForm({ ...form, old_password: e.target.value })}
            type="password"
            errorText={mapError(errors, 'old_password', form.old_password)}
            required
          />
        </Box>
        <PasswordVerificationForm
          setNewPassword={(value: string) => {
            setForm({ ...form, new_password: value, confirm_password: value });
          }}
          translationPrefix="superadmin.myAccount.changePassword"
        />
      </DialogContent>
      <DialogActions sx={{ m: '10px' }}>
        <Button size="medium" color="secondary" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
        <Button
          size="medium"
          variant="contained"
          onClick={() => mutation.mutate()}
          disabled={
            Object.keys(validateForm(form)).length > 0 || mutation.isLoading
          }
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
