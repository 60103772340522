import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns:
    minmax(0, 3fr) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2fr)
    minmax(0, 1fr) minmax(0, 1fr);
  height: 67px;
`;
export const Row2Grid = styled.div`
  display: grid;
  grid-template-rows: 2fr 3fr;
`;

export const Row3Grid = styled.div`
  display: grid;
  grid-template-rows: 2fr 1.5fr 1.5fr;
`;

export const Column2Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
`;

export const GridItem = styled.div`
  border: 1px solid #b6bbb9;
  text-align: center;
  font-size: 11px;
  font-family: 'Poppins';
  font-weight: 600;
  line-height: 16px;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns:
    minmax(0, 3fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
    minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  height: 61px;
`;

export const GridWrapper = styled.div`
  position: relative;
  color: #38414f !important;
  ::before {
    // hide borders on main grid sides
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid white;
  }
`;
