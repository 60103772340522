import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../../images/info.png';
import InfoIconHover from '../../images/green-info.png';
import { TitleProps } from './index.interfaces';
import {
  SectionGroupTitle,
  Icon,
  Text,
  InfoContainer,
  DescriptionContainer,
  IconTitle,
  Important,
} from './index.styles';

export default function Title({
  title,
  description,
  icon,
  size,
  important,
  margin = true,
  titleposition,
}: TitleProps) {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  return (
    <SectionGroupTitle>
      {icon ? <IconTitle src={icon} /> : null}
      <Text size={size} margin={margin}>
        {t(title)}
        {important && <Important>*</Important>}
      </Text>
      {description ? (
        <DescriptionContainer
          onMouseEnter={onHover}
          onMouseLeave={onLeave}
          role="button"
        >
          <Icon src={hover ? InfoIconHover : InfoIcon} />
          {hover ? (
            <InfoContainer titleposition={titleposition}>
              {t(description)}
            </InfoContainer>
          ) : null}
        </DescriptionContainer>
      ) : null}
    </SectionGroupTitle>
  );
}
