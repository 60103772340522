import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';

import { StyledButton } from './index.styles';

export default function ProcessButton({
  Icon,
  onClick,
  isActive = false,
}: {
  Icon: React.ComponentType<SvgIconProps>;
  onClick: () => void;
  isActive: boolean;
}) {
  const activeStyle = isActive
    ? {
        backgroundColor: '#F6F5F3',
      }
    : {};
  return (
    <StyledButton
      style={{
        marginRight: 20,
        borderRadius: 10,
        width: 75,
        height: 75,
        ...activeStyle,
      }}
      onClick={onClick}
    >
      <Icon fontSize="large" />
    </StyledButton>
  );
}
