import React from 'react';
import { useProductionProcesses } from '../hooks';

import { Box, Typography } from '@mui/material';
import GeneralInformation from './Form/GeneralInformation';
import Electricity from './Form/Electricity';
import Heat from './Form/Heat';
import WasteGases from './Form/WasteGases';
import DirectEmissions from './Form/DirectEmissions';
import MassBalances from './Form/MassBalances';
import MeasurementsBased from './Form/MeasurementsBased';
import Co2Transfer from './Form/Co2Transfer';
import Precursors from './Form/Precursors';
import {
  CounterProvider,
  NavigationBlockerWithCounter,
} from './Form/components/CounterProvider';
import { AggregatedGoodsCategory } from '../types';
import InfoPanel from '../components/InfoPanel';
import ProductionProcessInfoPanel from './Form/components/ProductionProcessInfoPanel';

interface Props {
  id: number;
  survey?: boolean;
}

export default function ProductionProcessEdit(props: Props) {
  const { id, survey } = props;
  const { data, delete: _delete } = useProductionProcesses();
  const productionProcess = data.find((p) => p.id === id);
  const isImportedElectricity =
    productionProcess?.aggregated_goods_category ===
    AggregatedGoodsCategory.Electricity;

  return (
    <CounterProvider>
      <NavigationBlockerWithCounter />
      <Box sx={{ mb: '12px' }}>
        <ProductionProcessInfoPanel />
      </Box>
      <Box>
        <GeneralInformation id={id} survey={survey} />
        {!isImportedElectricity && (
          <>
            <br />
            <br />
            <Electricity id={id} />
          </>
        )}
        <br />
        <br />
        <Typography variant="h2">Direct Emissions</Typography>
        <br />
        <InfoPanel>
          <Typography>
            Direct Emissions means emissions from the Production Processes of
            goods, including emissions from the production of heating and
            cooling that is consumed during the Production Processes,
            irrespective of the location of the production of the heating or
            cooling.
          </Typography>
        </InfoPanel>
        <br />
        <Heat id={id} />
        <br />
        <br />
        <WasteGases id={id} />
        <br />
        <br />
        <DirectEmissions id={id} />
        <br />
        <br />
        <MassBalances id={id} />
        <br />
        <br />
        <MeasurementsBased id={id} />
        <br />
        <br />
        <Co2Transfer id={id} />
        <br />
        <br />
        {!survey && <Precursors id={id} />}
      </Box>
    </CounterProvider>
  );
}
