import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTitle = styled(Typography)`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0.48px;
`;

export const StyledSubtitle = styled(Typography)`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.54px;
`;

export const StyledButtonContainer = styled(Box)({
  // flex row, aligned to right
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '10px',
  marginTop: '20px',
});

export const StyledInputContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});
