import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import ReportTabs from 'src/components/esg/reportTabs';
import { useReports } from '../hooks';
import { IconButton, Typography } from '@mui/material';
import { StyledFlex } from 'src/components/StyledFlex';
import { ChevronLeft } from '@mui/icons-material';

export default function Report() {
  const { reportId } = useParams();
  const navigate = useNavigate();
  const { data } = useReports();
  const report = data.find((report) => report.id === Number(reportId));

  const TabData = [
    {
      name: 'About',
      url: `/cbam/reports/${reportId}/about`,
    },
    {
      name: 'Imported Goods',
      url: `/cbam/reports/${reportId}/imported-goods`,
    },
    {
      name: 'Summary',
      url: `/cbam/reports/${reportId}/summary`,
    },
  ];

  return (
    <>
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <IconButton onClick={() => navigate('..')}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="h2">
          {report?.name} ({report?.period})
        </Typography>
      </StyledFlex>
      <br />
      <ReportTabs data={TabData} />
      <Outlet />
    </>
  );
}
