import React from 'react';

export default function soldProductsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="2" width="22" height="2.66664" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29346 6H4.88169V19.3331L19.1162 19.3331V6.00003H21.7044V19.3331H21.7055V21.9998H21.7044V21.9999H19.1162V21.9998H4.88169V21.9998H2.29346V6Z"
        fill="currentColor"
      />
      <path
        d="M7.46953 11.5573L8.76364 11.5573L8.76364 14.224L10.0578 14.224L10.0578 11.5573L11.3519 11.5573L9.4107 8.89071L7.46953 11.5573ZM12.646 15.5573L7.46953 15.5573L7.46953 16.8906L12.646 16.8906L12.646 15.5573Z"
        fill="currentColor"
      />
      <path
        d="M16.529 11.5573L15.2349 11.5573L15.2349 14.224L13.9408 14.224L13.9408 11.5573L12.6467 11.5573L14.5878 8.89071L16.529 11.5573ZM11.3525 15.5573L16.529 15.5573L16.529 16.8906L11.3525 16.8906L11.3525 15.5573Z"
        fill="currentColor"
      />
    </svg>
  );
}
