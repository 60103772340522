import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';

import Button from 'src/Lca/components/Button';

export default function CategoryButton({
  Icon,
  categoryKey,
  onClick,
  isActive = false,
}: {
  Icon: React.ComponentType<SvgIconProps>;
  categoryKey?: string;
  onClick: () => void;
  isActive?: boolean;
}) {
  const isActiveStyle = isActive
    ? {
        boxShadow: 'none',
        transform: 'translateY(4px)',
      }
    : {};

  return (
    <Button
      color={categoryKey || 'default'}
      style={{
        marginRight: 20,
        borderRadius: 10,
        width: 75,
        height: 75,
        ...isActiveStyle,
      }}
      onClick={onClick}
    >
      <Icon fontSize="large" />
    </Button>
  );
}
