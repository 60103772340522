import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

/* eslint-disable no-unused-vars */

export const TabButton = styled(
  ({
    active,
    ...props
  }: { active: boolean } & ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button type="button" {...props} />
  )
)`
  font-size: 20px;
  padding: 10px 60px;
  cursor: pointer;
  border: 0;
  outline: 0;
  margin: 10px 0;
  background: transparent;
  ${(props) => props.active && 'border-bottom: 4px solid #FDC342;'}
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 10px;
`;

export default { TabButton, TabContainer };
