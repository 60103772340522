import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { LcaAreaOfImpactSchema } from 'src/Lca/types';
import { client } from 'src/utils/api-client';

export const useLcaObjects = () => {
  const queryClient = useQueryClient();

  const requestCopy = useMutation({
    mutationFn: (objectId: number) =>
      client.post(`/web/lca/objects/${objectId}/copy`),
    onSuccess: () => {
      toast.success('Successfully copied');
      queryClient.invalidateQueries({
        queryKey: ['lca-diagram'],
      });
    },
    onError: () => {
      toast.error('error');
    },
  });

  return { requestCopy };
};

export const useLcaProcesses = () => {
  const queryClient = useQueryClient();

  const requestCopy = useMutation({
    mutationFn: (objectId: number) =>
      client.post(`/web/lca/processes/${objectId}/copy`),
    onSuccess: () => {
      toast.success('Successfully copied');
      queryClient.invalidateQueries({
        queryKey: ['lca-diagram'],
      });
    },
    onError: () => {
      toast.error('error');
    },
  });

  return { requestCopy };
};

export const useAreasQuery = () => {
  const { data } = useQuery('lca-areas', () =>
    client
      .get<LcaAreaOfImpactSchema[]>('/web/lca/areas-of-impact')
      .then((response) => response.data)
  );
  return data;
};
