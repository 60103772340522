import React, { useEffect, useState } from 'react';
import assets from '../assets';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import PageLoading from '../../../components/PageLoading';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import {
  StyledContainer,
  StyledQuestionTitle,
} from '../../../views/components/QuestionnaireV3/Question/index.styles';
import SurveyContainer from '../../../components/Survey/Container';
import { Logo } from '../../../components/Survey/icons';
import ImageHeader from '../../../components/Survey/ImageHeader';
import Title from '../../../components/Survey/Title';
import { toast } from 'react-toastify';
import { useCodeOfConductSurvey } from '../hooks';
import { Box, Button, Divider, Typography } from '@mui/material';
import TextField, { TextFieldProps } from '../../../components/TextField';
import { ErrorBuilder, SchemaErrors, mapError } from 'src/utils/validation';
import { Subtitle } from '../../SupplierSurvey/styles';
import {
  GhgSupplierCodeOfConductSurveyAnswerInSchema,
  UploadedFileSchema,
} from '../types';
import SingleFileUpload from '../../../components/SingleFileUpload';
import Download from '@mui/icons-material/Download';
import { format } from 'date-fns';
import { dateFormat } from 'src/common/constants';
import OrganizationalLogo from 'src/components/OrganizationalLogo';

type InSchema = GhgSupplierCodeOfConductSurveyAnswerInSchema;

type FormState = {
  company_name: string;
  contact_email: string;
  signed_file: UploadedFileSchema | null;
};
type FormErrors = SchemaErrors<FormState>;

const validateForm = (data: Partial<FormState>): FormErrors => {
  const builder = new ErrorBuilder(data).checkTruthy(
    ['company_name', 'contact_email', 'signed_file'],
    'validation.required'
  );

  return builder.build();
};

export default function CodeOfConductSurveyForm() {
  const navigate = useNavigate();
  const {
    t,
    i18n: { t: tRoot },
  } = useTranslation(undefined, {
    keyPrefix: 'ghg.codeOfConductSurvey',
  });
  const { query, submit, attachmentUploadUrl, isAnswerUptoDate } =
    useCodeOfConductSurvey();
  const survey = query.data;

  const [form, setForm] = useState<FormState>({
    company_name: '',
    contact_email: '',
    signed_file: null,
  });
  const [errors, setErrors] = useState<FormErrors>({});

  useEffect(() => {
    const survey = query.data;
    const answer = survey?.answer;
    if (answer) {
      // if response is not valid (or not up to date), reset the attachment
      if (!isAnswerUptoDate) {
        setForm({ ...answer, signed_file: null });
      } else {
        setForm(answer);
      }
    }
  }, [survey]);

  if (query.isError) return <SomethingWentWrong />;
  if (!survey) return <PageLoading />;

  const nextLink = {
    onClick: () => {
      const validationErrors = validateForm(form);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length > 0) return;
      const schema: InSchema = {
        ...form,
        survey_id: query.data.id,
        shown_file: query.data.code_of_conduct,
        signed_file: form.signed_file!,
      };

      submit
        .mutateAsync(schema)
        .then(() => navigate('../end'))
        .catch(() => toast.error(t('submitError') as string));
    },
    label: 'buttons.send',
  };

  const textFieldProps = (
    field: keyof FormState
  ): Partial<TextFieldProps<undefined>> => {
    return {
      value: form[field] || '',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setForm({ ...form, [field]: e.target.value }),
      errorText: mapError(errors, field, form[field]),
    };
  };

  return (
    <SurveyContainer
      logo={<Logo />}
      header={
        <ImageHeader
          imageSrc={assets.form.image}
          text={t('mainHeader')}
          small
        />
      }
      nextLink={nextLink}
    >
      <Title title={t('intro.title')} />
      {survey.answer && !isAnswerUptoDate && (
        <Typography sx={{ mb: 2, fontWeight: 600 }} color="error">
          {t('intro.responseOutOfDate', {
            date_updated: format(
              new Date(survey.code_of_conduct.uploaded_at),
              dateFormat
            ),
          })}
        </Typography>
      )}
      <Box>
        <Subtitle>{t('intro.subtitle')}</Subtitle>
        <Trans t={t} i18nKey="intro.message" />
        <Typography>{query.data.organization_name}</Typography>
        <Box>
          <OrganizationalLogo logo={query.data.organization_logo} />
        </Box>
        <Button
          variant="contained"
          href={query.data.code_of_conduct.url}
          target="_blank"
          sx={{ mt: 2 }}
        >
          <Download />
          {t('download')}
        </Button>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box display="flex" flexDirection="column">
        <StyledContainer>
          <StyledQuestionTitle>{t('questions.q01.title')}</StyledQuestionTitle>
          <TextField
            fullWidth
            label={t('questions.q01.label')}
            placeholder={t('questions.q01.placeholder')}
            {...textFieldProps('company_name')}
          />
        </StyledContainer>
        <StyledContainer>
          <StyledQuestionTitle>{t('questions.q02.title')}</StyledQuestionTitle>
          <TextField
            fullWidth
            label={t('questions.q02.label')}
            placeholder={t('questions.q02.placeholder')}
            {...textFieldProps('contact_email')}
            errorText={
              mapError(errors, 'contact_email', form.contact_email) &&
              'validation.invalidEmail'
            }
          />
        </StyledContainer>
        <StyledContainer>
          <StyledQuestionTitle>{t('questions.q03.title')}</StyledQuestionTitle>
          <SingleFileUpload
            file={form.signed_file}
            url={attachmentUploadUrl}
            onSuccess={(file) => setForm({ ...form, signed_file: file })}
          />
          {mapError(errors, 'signed_file', form.signed_file) && (
            <Typography color="error">
              {tRoot('validation.required')}
            </Typography>
          )}
        </StyledContainer>
      </Box>
    </SurveyContainer>
  );
}
