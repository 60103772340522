import React, { useState } from 'react';
import { LoginButton } from '../Login/index.styles';
import {
  SectionGroupContainer,
  FormContainer,
  FieldsContainer,
} from '../../components/AboutCompany/ChangableFormsets/index.styles';
import Title from '../../../components/Title';
import TextFieldNumber from '../../../components/TextFieldNumber';
import TextFieldOutline from '../../../components/TextFieldOutline';
import { getRespectEnergyRaportAPI } from '../../../utils/api';
import { Container } from './index.styles';

export default function PdfRaport() {
  const [page, setPage] = useState<number>(1);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [factor, setFactor] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleClick = () => {
    setPage(2);
    // TODO: Currently getRespectEnergyRaportAPI uses an authenticated-user-only client
    getRespectEnergyRaportAPI(name, +value, +factor)
      .then((pdfdata) => {
        setError(false);
        const file = new Blob([pdfdata], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        setLoading(false);
        window.open(fileURL);
      })
      .catch(() => setError(true));
  };

  return (
    <div>
      {page === 1 ? (
        <div style={{ maxWidth: '1024px', margin: '0 auto' }}>
          <SectionGroupContainer>
            <Title
              title="Respect Energy"
              description={null}
              important={false}
            />
            <FormContainer selected={false}>
              <FieldsContainer>
                <TextFieldOutline
                  label="Company Name"
                  value={name}
                  setValue={setName}
                  error={false}
                  active
                  max={0}
                  index={0}
                />
                <TextFieldNumber
                  value={value}
                  setValue={setValue}
                  index={1}
                  label="value"
                  error={false}
                  active
                  max={1}
                />
                <TextFieldNumber
                  value={factor}
                  setValue={setFactor}
                  index={1}
                  label="factor"
                  error={false}
                  active
                  max={1}
                />
              </FieldsContainer>
            </FormContainer>
          </SectionGroupContainer>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoginButton type="button" onClick={handleClick}>
              <span>Dalej</span>
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none">
                <path
                  d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z"
                  fill="white"
                />
              </svg>
            </LoginButton>
          </div>
        </div>
      ) : (
        <Container>
          {loading ? (
            <div>
              <p>Trwa generowanie dokumentu</p>
              <p>
                aby móc go pobrać prosze zezwolic tej stronie na otwieranie
                &quot;wyskakujących okienek&quot;
              </p>
            </div>
          ) : (
            <p>dokument gotowy do pobrania</p>
          )}
          {error && (
            <p>
              Coś poszło nie tak, proszę &quot;odświeyć&quot; stronę i spróbować
              jeszcze raz
            </p>
          )}
        </Container>
      )}
    </div>
  );
}
