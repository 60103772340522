import React from 'react';
import { EsgUserPermissionSubtopic } from '../types';
import TableCollapsable from './TableCollapsable';
import { useEsgUserPermissions } from '../hooks';
import QuestionCollapse from './QuestionCollapse';
import { MenuItem, TableRow, Typography } from '@mui/material';
import { StyledTableCell, StyledTableStickyCell } from '../styles';
import { StyledFlex } from 'src/components/StyledFlex';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PermissionSelect from './PermissionSelect';
import { useTranslation } from 'react-i18next';

interface Props {
  subtopic: EsgUserPermissionSubtopic;
  isExpanded: boolean;
  userId: number;
  year: number;
}
export default function IndicatorCollapse(props: Props) {
  const { subtopic, isExpanded: isIndicatorExpanded, userId, year } = props;
  const { t } = useTranslation();

  const { options, userOrganizations } = useEsgUserPermissions(userId);
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const reports = options.reports.filter((report) => report.period === year);

  const handleExpandClick = (id: string, isExpanded: boolean) =>
    isExpanded
      ? setExpanded(expanded.filter((_id) => _id !== id))
      : setExpanded([...expanded, id]);

  return (
    <TableCollapsable isExpanded={isIndicatorExpanded}>
      {subtopic.indicators.map((indicator) => {
        const key = indicator.questionnaire_id;
        const isExpanded = expanded.includes(key);
        const _handleExpandClick = () => handleExpandClick(key, isExpanded);
        const Icon = isExpanded ? ExpandLessIcon : ExpandMoreIcon;
        return (
          <React.Fragment key={key}>
            <TableRow>
              <StyledTableStickyCell sx={{ p: 0 }}>
                <MenuItem
                  onClick={_handleExpandClick}
                  sx={{ pt: '16px', pb: '16px', whiteSpace: 'normal' }}
                >
                  <StyledFlex>
                    <Icon sx={{ m: '12px' }} />
                    <Typography sx={{ textWrap: 'wrap' }}>
                      {indicator.questionnaire_title}
                    </Typography>
                  </StyledFlex>
                </MenuItem>
              </StyledTableStickyCell>

              {userOrganizations.map((organization) => {
                const report = reports.find(
                  (r) => r.esg_organization_id === organization.id
                );
                if (!report)
                  return (
                    <StyledTableCell key={key + organization.id}>
                      <Typography>
                        {t(
                          'superadmin.settings.tabs.users.usersTable.permissions.other.noReport'
                        )}
                      </Typography>
                    </StyledTableCell>
                  );
                return (
                  <StyledTableCell
                    key={key + organization.id}
                    sx={{ p: '8px' }}
                  >
                    <PermissionSelect
                      userId={userId}
                      organizationId={organization.id}
                      questionId={null}
                      indicatorId={indicator.questionnaire_id}
                      indicatorCategoryId={indicator.esg_indicator_category_id}
                      reportId={report.id}
                    />
                  </StyledTableCell>
                );
              })}
            </TableRow>

            <QuestionCollapse
              isExpanded={isExpanded}
              key={indicator.questionnaire_id}
              indicator={indicator}
              userId={userId}
              year={year}
            />
          </React.Fragment>
        );
      })}
    </TableCollapsable>
  );
}
