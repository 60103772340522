import React from 'react';

import { ContentProps } from './index.types';
import Structure from './Structure';
import { SETTINGS_TABS } from '../Tabs/index.types';
import Users from './Users';

const TabContent = (props: ContentProps) => {
  const { tab } = props;
  switch (tab) {
    case SETTINGS_TABS.STRUCTURE_TAB:
      return <Structure {...props} />;
    case SETTINGS_TABS.USERS_TAB:
      return <Users {...props} />;
    default:
      return <div />;
  }
};

export default function TabContentContainer(props: ContentProps) {
  return (
    <div style={{ marginTop: '54px' }}>
      <TabContent {...props} />
    </div>
  );
}
