import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import { useActors, useImportedGoods, useProductionProcesses } from '../hooks';
import Row from './Row';
import { useNavigate, useParams } from 'react-router-dom';

export default function ImportedGoods() {
  const { reportId } = useParams();
  const navigate = useNavigate();
  const { data } = useImportedGoods(reportId!);
  const productionProcesses = useProductionProcesses();
  const actors = useActors();

  return (
    <Box>
      <StyledPanel>
        <Box
          sx={{
            overflowX: 'auto',
            overflowY: 'hidden',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Importer</TableCell>
                <TableCell>Production Process</TableCell>
                <TableCell>
                  Specific emissions <br />
                  [tCO2e/unit]
                </TableCell>
                <TableCell>
                  Benchmark specific emissions <br />
                  [tCO2e/unit]
                </TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <Row key={row.id} data={row} />
              ))}
            </TableBody>
          </Table>
          <br />
          <Button
            startIcon={<Add />}
            disabled={
              productionProcesses.data.length === 0 || actors.data.length === 0
            }
            onClick={() =>
              navigate(`/cbam/imported-goods/create`, { state: { reportId } })
            }
          >
            Add imported good
          </Button>
        </Box>
      </StyledPanel>
    </Box>
  );
}
