import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloudText, CloudTitle } from './index.styles';

export default function OrgBoundariesInfoContent() {
  const { t } = useTranslation();
  return (
    <>
      <CloudTitle
        dangerouslySetInnerHTML={{ __html: t('boundariesInfoCloud.title1') }}
      />
      <CloudText
        dangerouslySetInnerHTML={{ __html: t('boundariesInfoCloud.desc1') }}
      />
      <CloudTitle
        dangerouslySetInnerHTML={{ __html: t('boundariesInfoCloud.title2') }}
      />
      <CloudText
        dangerouslySetInnerHTML={{ __html: t('boundariesInfoCloud.desc2') }}
      />
      <CloudTitle
        dangerouslySetInnerHTML={{ __html: t('boundariesInfoCloud.title3') }}
      />
      <CloudText
        dangerouslySetInnerHTML={{ __html: t('boundariesInfoCloud.desc3') }}
      />
    </>
  );
}
