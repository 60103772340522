import React from 'react';

export default function ElectricityEmissionsIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11_7368)">
        <path
          d="M21 14.5455C21 13.9855 20.55 13.5273 20 13.5273H18V15.5636H20C20.55 15.5636 21 15.1055 21 14.5455Z"
          fill="currentColor"
        />
        <path
          d="M20 17.6H18V19.6364H20C20.55 19.6364 21 19.1782 21 18.6182C21 18.0582 20.55 17.6 20 17.6Z"
          fill="currentColor"
        />
        <path
          d="M12 14.5455H10V18.6182H12C12 19.7382 12.9 20.6545 14 20.6545H17V12.5091H14C12.9 12.5091 12 13.4255 12 14.5455Z"
          fill="currentColor"
        />
        <path
          d="M5 13.5273C5 12.4073 5.9 11.4909 7 11.4909H8.5C10.43 11.4909 12 9.89237 12 7.92728C12 5.96219 10.43 4.36365 8.5 4.36365H5C4.45 4.36365 4 4.82183 4 5.38183C4 5.94183 4.45 6.40001 5 6.40001H8.5C9.33 6.40001 10 7.08219 10 7.92728C10 8.77237 9.33 9.45456 8.5 9.45456H7C4.79 9.45456 3 11.2771 3 13.5273C3 15.7775 4.79 17.6 7 17.6H9V15.5636H7C5.9 15.5636 5 14.6473 5 13.5273Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_7368">
          <rect
            width="24"
            height="24.4364"
            fill="currentColor"
            transform="translate(0 0.290894)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
