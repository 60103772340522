import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';
import BasePage from '../Base';
import { MethodologyDataType } from './types';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  textContiner: {
    position: 'absolute',
    top: 470,
    left: 300,
    width: 630,
    height: 700,
    lineHeight: 1.5,
    flexDirection: 'row',
    letterSpacing: 2,
  },
  text: {
    color: '#2F2F2F',
    fontFamily: 'Poppins',
    fontSize: 30,
    whiteSpace: 'nowrap',
    fontStyle: 'bold',
  },
  textHighlight: {
    color: '#2F2F2F',
    fontFamily: 'Poppins',
    fontSize: 30,
    fontStyle: 'bold',
    whiteSpace: 'nowrap',
    backgroundColor: '#FFB926',
  },
});

type MethodologyProps = {
  data: {
    methodology: MethodologyDataType;
  };
};

const isEvenNumber = (number: number) => number % 2 === 0;

const MethodologyPage = ({ data }: MethodologyProps) => {
  const { page_title: pageTitle, text: _text } = data.methodology;

  return (
    <BasePage
      backgroundImageName="Report_envirly_slide_1aaaaa.png"
      mainHeader={pageTitle}
    >
      <Text style={styles.textContiner}>
        {_text.split('::').map((text, idx) =>
          isEvenNumber(idx) ? (
            <Text key={idx} style={styles.text}>
              {text}
            </Text>
          ) : (
            <Text key={idx} style={styles.textHighlight}>
              &nbsp;&nbsp;{text}&nbsp;&nbsp;
            </Text>
          )
        )}
      </Text>
    </BasePage>
  );
};

export default MethodologyPage;
