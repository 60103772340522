export interface TotalEmissionAdminColorsProps {
  [key: string]: string;
}

export const groupDataColor = ['#388276', '#C9B620', '#823E40'];

export const colors = [
  '#79B52C',
  '#944D29',
  '#81CdFD',
  '#3C88B5',
  '#D7A7A9',
  '#FDC342',
  '#AD7A50',
  '#5C5E6F',
  '#C9B620',
  '#499387',
  '#E6C7AE',
  '#647A91',
  '#91E0D2',
  '#823E40',
  '#9ECA64',
  '#CF6367',
  '#7D6020',
  '#8DA247',
];
export const companiesColors = [
  ...colors,
  ...colors,
  ...colors,
  ...colors,
  ...colors,
  ...colors,
  ...colors,
  ...colors,
  ...colors,
  ...colors,
];
