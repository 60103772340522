import { Button, Typography } from '@mui/material';
import React from 'react';

import { useWasteGasesConsumed, useWasteGasesExported } from 'src/Cbam/hooks';
import Consumed from './Consumed';
import Exported from './Exported';

interface Props {
  id: number;
}

export default function WasteGases(props: Props) {
  const { id } = props;
  const consumed = useWasteGasesConsumed(id);
  const exported = useWasteGasesExported(id);

  const [isNewConsumedVisible, setIsNewConsumedVisible] = React.useState(false);
  const [isNewExportedVisible, setIsNewExportedVisible] = React.useState(false);

  return (
    <>
      <Typography variant="h3">Waste gasses consumed</Typography>
      <br />
      {!consumed.data.length && !isNewConsumedVisible && (
        <Typography>No consumed waste gasses</Typography>
      )}
      {consumed.data.map((c) => (
        <Consumed key={c.id} productionProcessId={id} id={c.id} />
      ))}
      {!isNewConsumedVisible ? (
        <Button
          sx={{ mt: '20px' }}
          onClick={() => setIsNewConsumedVisible(true)}
        >
          Add consumed waste data
        </Button>
      ) : (
        <Consumed
          productionProcessId={id}
          onSaved={() => setIsNewConsumedVisible(false)}
        />
      )}
      <br />
      <br />
      <Typography variant="h3">Waste gasses exported</Typography>
      <br />
      {!exported.data.length && !isNewExportedVisible && (
        <Typography>No exported waste gasses</Typography>
      )}
      {exported.data.map((e) => (
        <Exported key={e.id} productionProcessId={id} id={e.id} />
      ))}
      {!isNewExportedVisible ? (
        <Button
          sx={{ mt: '20px' }}
          onClick={() => setIsNewExportedVisible(true)}
        >
          Add exported waste data
        </Button>
      ) : (
        <Exported
          productionProcessId={id}
          onSaved={() => setIsNewExportedVisible(false)}
        />
      )}
    </>
  );
}
