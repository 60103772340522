import React from 'react';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { StyledAutocomplete, ValueField } from './index.styles';
import { TextFieldAutocompleteProps } from './index.interfaces';
import {
  ValueLabel,
  ValueParagraph,
} from '../../../../../components/TextFieldNumber/index.styles';

export default function NewAutocompleteFreesolo({
  label,
  value,
  setValue,
  options,
  error,
  active,
  index,
  max,
}: TextFieldAutocompleteProps) {
  const filter = createFilterOptions<string>();
  const { t } = useTranslation();
  return (
    <>
      {active && (
        <StyledAutocomplete
          value={
            value !== '' &&
            options.length > 0 &&
            options.filter((option) => option[0] === value).length > 0
              ? options.filter((option) => option[0] === value)[0][1]
              : (value as string)
          }
          filterOptions={(setOfOptions, params) => {
            return filter(setOfOptions as string[], params);
          }}
          options={options}
          getOptionLabel={(option) => t(option as string)}
          renderOption={(props, option) => (
            <li {...props}>{t(option as string)}</li>
          )}
          onChange={(event, newValue: any) => {
            if (newValue && newValue.inputValue) {
              setValue(newValue.inputValue);
            } else {
              setValue(newValue);
            }
          }}
          selectOnFocus
          disableClearable
          handleHomeEndKeys
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(label)}
              error={error}
              InputProps={{
                ...params.InputProps,
              }}
              onChange={(event) => setValue(event.target.value.trim())}
            />
          )}
        />
      )}
      {!active && value !== '' && (
        <ValueField className="value-field" index={index} max={max}>
          <ValueLabel>{t(label)}</ValueLabel>
          <ValueParagraph>
            {options.length > 0 &&
            options.filter((option) => option[0] === value).length > 0
              ? options.filter((option) => option[0] === value)[0][1]
              : (value as string)}
          </ValueParagraph>
        </ValueField>
      )}
    </>
  );
}
