import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function Forbidden() {
  const { t } = useTranslation(undefined, { keyPrefix: 'forbidden' });
  const navigate = useNavigate();
  return (
    <Box sx={{ width: '100%', mt: '20vh', textAlign: 'center' }}>
      <Typography variant="h4">{t('title')}</Typography>
      <br />
      <Typography>{t('subtitle')}</Typography>
      <br />
      <br />
      <Button size="small" variant="contained" onClick={() => navigate('/')}>
        {t('homeButton')}
      </Button>
    </Box>
  );
}
