import { Icon } from './icon';
import React from 'react';
import { FieldValue } from '../types';
import Button from '@mui/material/Button';

interface Props {
  setSelectedFile: Function;
  value: FieldValue;
}

export default function Files({ value, setSelectedFile }: Props) {
  const values: any = Array.isArray(value) ? value : [value];

  if (!value) return <div />;

  return (
    <>
      {values.map((el: any) => (
        <Button className="file" key={el?.id}>
          <a href={el.url} target="_blank" rel="noreferrer">
            {el?.name}
          </a>
          <span
            style={{
              height: 32,
              width: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => setSelectedFile(el?.id)}
          >
            <Icon />
          </span>
        </Button>
      ))}
    </>
  );
}
