import { styled as styledMUI } from '@mui/material/styles';

import { ColumnWrapper } from './index.types';

export const StyledColumnWrapper = styledMUI('div')<ColumnWrapper>`
  min-width: 175px;
  max-width: 350px;
  width: ${(props) => 0.9 * (100 / props.columns)}%;
  min-height: 100px;
  marign: 15px;
`;

export const StyledColumn = styledMUI('div')`
  background-color: #fff;
  border-radius: 10px;
  padding: 0px 10px 10px 10px;
  height: 100%;
`;

export const StyledColumnHeader = styledMUI('div')`
  width: 100%;
  height: 30px;
  text-align: center;
`;

export const StyledColumnTitle = styledMUI('div')`
  width: 100%;
  padding: 10px 5px;
  margin-bottom: 20px;
  color: #281302;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.32px;
  text-transform: uppercase; 
`;

export const StyledCalculation = styledMUI('div')`
  border-radius: 25px;
  background-color: #fff;
  padding: 20px 2px;
  font-size: 20px;
  font-family: Poppins;
  margin-top: 10px;
  margin-bottom: 50px;
  text-align: center;
  word-break: break-word;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
