export const PDF_WIDTH = 1920;
export const PDF_HEIGHT = 1080;

// PDFs backgorund image has logo included. its left margin is 82px.
// this LOGO_MARGIN_LEFT value is used for text/graph containers in PDF reports.
export const LOGO_MARGIN_LEFT = 82;

// default (all) sections for greenhouse gas emissions report
export const GREENHOUSE_GAS_EMISSIONS_SECTIONS = [
  'introduction',
  'carbon_footprint',
  'employee_emissions',
  'about_envirly',
];
