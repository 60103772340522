import { Box, FormControlLabel, Radio, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmissionFactorSource } from 'src/Cbam/types';

interface Props {
  value: EmissionFactorSource;
  onChange: (value: EmissionFactorSource) => void;
}

export default function EmissionFactorSourceField(props: Props) {
  const { value, onChange } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.emissionFactorSource',
  });
  return (
    <>
      <Typography variant="h5">Emission factor source:</Typography>
      {Object.values(EmissionFactorSource).map((factorSource) => (
        <Box key={factorSource}>
          <FormControlLabel
            sx={{ ml: 1 }}
            label={t(factorSource)}
            control={
              <Radio
                checked={value === factorSource}
                onClick={() => onChange(factorSource as EmissionFactorSource)}
              />
            }
          />
        </Box>
      ))}
    </>
  );
}
