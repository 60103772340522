import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddProductButton from 'src/components/AddProductButton';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import UploadExcelButton from 'src/components/UploadExcelButton';
import { useAppSelector } from 'src/redux-file/hooks';

import { timeConverter } from 'src/utils/timeConverter';
import {
  capital_goods_cloud,
  purchased_goods_cloud,
} from 'src/views/Pages/Questionnaire/clouds_data';
import {
  PreviewBox,
  Section,
  StyledHeader,
  StyledTh,
} from 'src/views/Pages/Questionnaire/index.styles';
import MethodBox from 'src/views/components/MethodBox';
import AddButtonHolder from 'src/views/components/Questionnaire/AddButtonHolder';
import GoodsAndServicesForm from 'src/views/components/Questionnaire/forms/goodsAndServicesForm';
import GoodsPreview from 'src/views/components/Questionnaire/preview/goodsPreview';
import QuestionnaireSectionTitle from 'src/views/components/QuestionnaireSectionTitle';
import SupplierMethodBox from './SupplierMethodBox';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from '../../interfaces';
import { useContactUsPrompt, useGhgOrganization } from 'src/common/hooks';
import { useGoodsAndServices } from '../hooks';
import { GoodsAndServicesData } from '../types';
import AverageMethodBox from './AverageMethodBox';

type SectionProps = {
  type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
};

export default function GoodsSection(props: SectionProps) {
  const { navigateMailTo } = useContactUsPrompt();
  const { selectedDate } = useAppSelector((state) => state.questionnaire);

  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const [newForm, setNewForm] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const [active, setActive] = useState<undefined | number>(undefined);

  const pgInit = {
    emission_factor_is_known: false,
    good_data_factor_unknown: {
      spending_pln_per_period: '',
      product_industry: '',
    },
    good_data_factor_known: {
      factor: '',
      amount: '',
      unit_label: '',
    },
    comment: '',
    added_by: '',
    emission_kg_eq_co2: '',
    fraction: '',
    name_label: '',
  };

  const [state, setState] = useState([pgInit]);
  const header_ref = useRef(null);

  const cloud =
    props.type === GOODS_AND_SERVICES_OR_CAPITAL_GOODS.GOODS_AND_SERVICES
      ? purchased_goods_cloud
      : capital_goods_cloud;

  const question =
    props.type === GOODS_AND_SERVICES_OR_CAPITAL_GOODS.GOODS_AND_SERVICES
      ? 'question1'
      : 'question2';

  const keyPrefix = `goods-and-services.${question}`;
  const {
    t,
    i18n: { t: tRoot },
  } = useTranslation(undefined, { keyPrefix });

  const methodBoxVisible = newForm && !showForm;

  const api = useGoodsAndServices({
    organization_id: organization.id,
    period: timeConverter(selectedDate, reportingPeriod),
    type: props.type,
  });

  const data = api.query.data?.data;

  useEffect(() => {
    if (data === undefined) return;

    setNewForm(data.length === 0);
    setShowForm(false);

    setState(data.length !== 0 ? (data as any) : [pgInit]);
  }, [data]);

  const send = async (data: Partial<GoodsAndServicesData>) => {
    const baseData = api.query.data;
    if (baseData === undefined) return;
    return await api.update.mutateAsync({
      ...baseData,
      ...data,
    });
  };

  const combineGoodsData = (goodsData: any, newData: any) => {
    const validGoods =
      goodsData?.length === 1 && goodsData[0].emission_kg_eq_co2 === ''
        ? []
        : goodsData;
    return [...newData, ...validGoods];
  };

  if (api.isError) return <SomethingWentWrong />;
  if (api.isLoading) return <PageLoading />;

  return (
    <Section>
      <QuestionnaireSectionTitle
        level="primary"
        text={t('title')}
        boxContent={cloud}
        active={active}
      />
      <br />
      <SupplierMethodBox type={props.type} />
      <br />
      <AverageMethodBox type={props.type} />
      <br />
      <MethodBox
        active
        title={t('method3.title')}
        point1={t('method3.point1')}
        based={t('method3.based')}
        desc={t('method3.desc')}
        buttonAction={() => navigateMailTo()}
        buttonText={t('method3.button')}
      />
      <br />
      <div ref={header_ref} />
      {!methodBoxVisible && (
        <QuestionnaireSectionTitle
          text={tRoot('goods-and-services.method4.sectionTitle')}
          level="secondary"
          active
        />
      )}
      {newForm && (
        <div>
          {!showForm ? (
            <MethodBox
              active
              title={t('method4.title')}
              point1={t('method4.point1')}
              buttonAction={() => setShowForm(true)}
              buttonText={t('method4.button')}
              based={t('method4.based')}
              desc={t('method4.desc')}
              point2={t('method4.point2')}
            />
          ) : (
            <PreviewBox>
              {state.map((_, id) => (
                <GoodsAndServicesForm
                  setNewForm={setNewForm}
                  variant={props.type}
                  active={active}
                  setActive={setActive}
                  key={id}
                  id={id}
                  state={state}
                  setState={setState}
                  update={(data) => send({ data })}
                />
              ))}
            </PreviewBox>
          )}
        </div>
      )}
      {!newForm && (
        <PreviewBox>
          <StyledHeader>
            <StyledTh width={33}>{t('table.column1')}</StyledTh>
            <StyledTh width={33}>{t('table.column4')}</StyledTh>
            <StyledTh width={33}>
              {tRoot(
                'direct-emission.stationary-combustion.table.emission_shared'
              )}
            </StyledTh>
            <StyledTh width={0}>{tRoot('sold-products.preview.edit')}</StyledTh>
          </StyledHeader>
          <div>
            {state.map((_, id) => {
              if (active === id) {
                return (
                  <GoodsAndServicesForm
                    setNewForm={setNewForm}
                    variant={props.type}
                    active={active}
                    key={id}
                    id={id}
                    state={state}
                    setState={setState}
                    setActive={setActive}
                    update={(data) => send({ data })}
                  />
                );
              } else {
                return (
                  <GoodsPreview
                    setState={setState}
                    key={id}
                    state={state}
                    id={id}
                    setActive={setActive}
                    emissionShares={api.queryEmissions.data?.data}
                  />
                );
              }
            })}
          </div>
        </PreviewBox>
      )}
      {!methodBoxVisible && (
        <AddButtonHolder
          firstChild={
            <AddProductButton
              onClick={() => {
                if (newForm && state.length > 0) return;
                setState([pgInit, ...state]);
                setActive(0);
                // @ts-ignore
                header_ref?.current?.scrollIntoView?.({
                  behavior: 'smooth',
                });
              }}
            />
          }
          secondChild={
            <UploadExcelButton
              name={t('title')}
              slug="GoodData"
              fullData={state}
              combineData={combineGoodsData}
              sendData={(data: any) => send({ data })}
              refresh={api.refetch}
            />
          }
        />
      )}
    </Section>
  );
}
