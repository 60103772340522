import React from 'react';
import { Text, View } from '@react-pdf/renderer';

// eslint-disable-next-line import/prefer-default-export
export const Li = (props: { value: React.JSX.Element | string }) => {
  return (
    <View style={{ display: 'flex', flexDirection: 'row' }}>
      <Text style={{ color: '#E9BC46', marginHorizontal: 8 }}>•</Text>
      {typeof props.value === 'string' ? (
        <Text>{props.value}</Text>
      ) : (
        props.value
      )}
    </View>
  );
};
