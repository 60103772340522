import React from 'react';
import styled from 'styled-components';
import themeColors from '../../../utils/theme';
import { companiesColors } from '../SuperDashboard/data';

export const Container = styled.div`
  width: 100%;
  padding: 0 96px 250px 96px;
  position: relative;
`;

export const StyledHeader = styled.div`
  display: flex;
`;

export const PreviewBox = styled.div`
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #dddddd;
  padding: 24px 45px;
`;

export const StyledTd = styled.div<{ width: number }>`
  border-left: 6px solid ${companiesColors[0]};
  width: ${({ width }) => width}%;
  background-color: #f2f2f2;
  height: 57px;
  font-size: 15px;
  padding-left: 11px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  color: #2f2f2f;
  font-weight: 500;

  &:last-child {
    border-left: 6px solid white;
    background-color: white;
    padding-left: 0;
    width: 48px;

    button {
      background-color: transparent;
    }

    svg {
      display: block;
      width: 48px;
      height: 48px;
    }
  }
`;

export const StyledTh = styled.div<{ width: number }>`
  background-color: #606462;
  border-left: 6px solid #fff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  height: 57px;
  padding-left: 11px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  width: ${({ width }) => width}%;

  &:first-child {
    border-left: 6px solid #606462;
  }
  &:last-child {
    border-left: 6px solid white;
    background-color: white;
    width: 48px;
  }
`;

export const descStyle: React.CSSProperties = {
  fontWeight: 400,
  fontSize: 18,
  lineHeight: '40px',
  color: '#2f2f2f',
  marginBottom: 40,
};

export const warnStyle: React.CSSProperties = {
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '40px',
  color: '#ad5365',
  marginBottom: 40,
};

export const CloudTitle = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  margin-bottom: 10px;
`;

export const CloudText = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: #606462;
  margin-bottom: 34px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const LinkButton = styled.a`
  margin: 0 0 34px;
  background: #388276;
  border: 1px solid #388276;
  border-radius: 24px;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  padding: 0 26px;
  color: #ffffff;

  &:hover {
    background: ${themeColors.greenHover};
  }

  &:active {
    background: ${themeColors.greenPressed};
  }
`;

export const LinksWrapper = styled.div`
  display: flex;

  a {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.03em;
    text-decoration: none;
  }

  a:first-child {
    color: #606462;
    margin-right: 100px;
  }

  a:last-child {
    color: #388276;
  }
`;

export const EmployeeWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: calc(50% - 30px);
  }
`;

export const PointsWrapper = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #2f2f2f;

  div {
    display: flex;
    margin-bottom: 36px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  svg {
    margin-right: 16px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    position: relative;
    top: 4px;
  }
`;
