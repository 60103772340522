import React from 'react';
import { useTranslation } from 'react-i18next';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { StyledPanel } from '../../../index.styles';
import { StyledAvailabelProductTitle } from './AvailableProductTypes';
import { ProductAvailabilitySchema } from '../../../index.types';

interface Props {
  product: ProductAvailabilitySchema;
  updateProduct: (product: ProductAvailabilitySchema) => void;
  isWideScreen: boolean;
}
export default function AvailableProduct(props: Props) {
  const { t } = useTranslation();
  const { product, updateProduct, isWideScreen } = props;

  return (
    <StyledPanel
      sx={{
        mt: '32px',
        p: '32px',
        height: '265px',
        width: isWideScreen ? 'calc(33% - 12px)' : '100%',
        minWidth: '200px',
        opacity: product.is_billed ? 1 : 0.5,
      }}
    >
      <StyledAvailabelProductTitle>
        {t(
          `superadmin.components.panels.unit.availableProducts.${product.product}`
        )}
      </StyledAvailabelProductTitle>

      <ToggleButtonGroup value={product.is_active} exclusive fullWidth>
        <ToggleButton
          value={false}
          onClick={() => updateProduct({ ...product, is_active: false })}
          disabled={!product.is_billed}
        >
          {t(
            'superadmin.components.panels.unit.availableProducts.notAvailable'
          )}
        </ToggleButton>
        <ToggleButton
          value
          onClick={() => updateProduct({ ...product, is_active: true })}
          disabled={!product.is_billed}
        >
          {t('superadmin.components.panels.unit.availableProducts.available')}
        </ToggleButton>
      </ToggleButtonGroup>
    </StyledPanel>
  );
}
