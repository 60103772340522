import React from 'react';

export default function ArrowLeftIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5005 30.885L21.6305 24L28.5005 17.115L26.3855 15L17.3855 24L26.3855 33L28.5005 30.885Z"
        fill="#606462"
      />
    </svg>
  );
}
