import React from 'react';
import { usePlatformNavigation } from 'src/utils/navigation';
import BusinessIcon from '@mui/icons-material/Business';
import { OrganizationSchema } from 'src/utils/api.interfaces';
import { List, ListItemIcon, MenuItem, Typography } from '@mui/material';

interface NestedMenuItemProps {
  organization: OrganizationSchema;
  depth?: number;
}

export default function NestedOrganization(props: NestedMenuItemProps) {
  const { organization, depth = 0 } = props;
  const { navigateToOrganization, productOrganizations, organizationId } =
    usePlatformNavigation();

  const handleClick = () => navigateToOrganization(organization.id);

  const organizations = productOrganizations.filter(
    (org) => org.parent_id === organization.id
  );

  return (
    <List sx={{ p: 0 }}>
      <MenuItem
        sx={{
          p: '10px',
          pl: `${depth * 20}px`,
          borderTop: '1px solid #e0e0e0',
        }}
        selected={organizationId === organization.id}
        onClick={handleClick}
      >
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <Typography sx={{ textWrap: 'wrap' }}>{organization.name}</Typography>
      </MenuItem>

      {organizations.map((org) => (
        <NestedOrganization depth={depth + 1} key={org.id} organization={org} />
      ))}
    </List>
  );
}
