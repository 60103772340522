import React from 'react';

export const Logo = () => (
  <svg
    width="124"
    height="37"
    viewBox="0 0 124 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.7111 8.27588C12.17 8.27588 15.1585 10.3202 16.5648 13.2812C14.3488 18.3663 8.75787 19.6447 4.28336 17.405C8.71092 17.1036 11.8544 16.1468 13.1592 13.2827C12.0894 12.0052 10.4936 11.1938 8.7111 11.1938C5.84364 11.1938 3.45886 13.2922 2.97081 16.0559C4.96275 13.3702 7.4394 12.4983 10.318 13.1292C5.89761 12.9168 3.56719 15.6842 3.56719 18.5193C3.56719 21.7844 6.82971 23.4171 9.47404 23.4171C12.6593 23.4171 16.0991 21.526 17.3793 17.9768C16.9465 22.4416 13.2302 25.9311 8.71092 25.9311C3.90087 25.9311 0 21.9781 0 17.1036C0 12.229 3.90106 8.27588 8.7111 8.27588Z"
      fill="#38414F"
    />
    <path
      d="M39.2434 25.4064H35.1968V16.6956C35.1968 15.3208 34.8459 14.2375 34.1441 13.4456C33.4422 12.6427 32.4937 12.2412 31.2983 12.2412C30.0701 12.2412 29.0832 12.6647 28.3375 13.5116C28.1224 13.7526 27.9384 14.0179 27.7854 14.3074L27.6671 10.2903C28.0574 9.92006 28.4947 9.60803 28.979 9.35416C29.955 8.83723 31.0955 8.57877 32.4004 8.57877C34.5279 8.57877 36.2002 9.22768 37.4175 10.5255C38.6347 11.8233 39.2434 13.616 39.2434 15.9037V25.4064Z"
      fill="#38414F"
    />
    <path
      d="M25.9329 8.90872L26.4188 25.4064H23.1724V8.90872H25.9329Z"
      fill="#38414F"
    />
    <path
      d="M54.417 25.4064L48.5025 8.90872H44.192L50.9691 25.4064H54.417Z"
      fill="#38414F"
    />
    <path
      d="M55.7084 23.4044L61.6776 8.90872H57.3679L53.944 18.4828L55.7084 23.4044Z"
      fill="#38414F"
    />
    <path
      d="M69.5049 4.53685C68.9346 4.53685 68.4521 4.33888 68.0573 3.94293C67.6735 3.53599 67.4816 3.04656 67.4816 2.47465C67.4816 1.91373 67.6735 1.43529 68.0573 1.03935C68.4521 0.632409 68.9346 0.428939 69.5049 0.428939C70.0861 0.428939 70.5741 0.632409 70.9689 1.03935C71.3636 1.43529 71.561 1.91373 71.561 2.47465C71.561 3.04656 71.3636 3.53599 70.9689 3.94293C70.5741 4.33888 70.0861 4.53685 69.5049 4.53685Z"
      fill="#38414F"
    />
    <path
      d="M67.4816 25.4064V8.90872H71.5281V25.4064H67.4816Z"
      fill="#38414F"
    />
    <path
      d="M82.5657 25.4064H78.6645V8.90872H82.0797L82.5657 25.4064Z"
      fill="#38414F"
    />
    <path
      d="M83.9185 13.8427C84.2306 13.4646 84.5957 13.1452 85.014 12.8847C85.7487 12.4337 86.5986 12.2083 87.5636 12.2083C88.1997 12.2083 88.8576 12.3072 89.5375 12.5052L90.031 8.84273C89.6911 8.75474 89.3456 8.68875 88.9947 8.64476C88.6547 8.60077 88.3203 8.57877 87.9913 8.57877C86.7302 8.57877 85.6116 8.87023 84.6356 9.45315C84.341 9.63112 84.0648 9.83114 83.8069 10.0532L83.9185 13.8427Z"
      fill="#38414F"
    />
    <path d="M99.4374 25.4064H95.1112V0H99.4374V25.4064Z" fill="#38414F" />
    <path
      d="M113.647 33.6552H109.421L119.628 8.90872H123.862L113.647 33.6552Z"
      fill="#38414F"
    />
    <path
      d="M109.14 8.90872L113.64 18.5123L111.694 23.2293L104.583 8.90872H109.14Z"
      fill="#38414F"
    />
  </svg>
);

export const Esg = () => (
  <svg
    style={{
      position: 'absolute',
      top: 30,
      left: 0,
    }}
    width="25"
    height="9"
    viewBox="0 0 25 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.62699 2.28648V4.26409H4.35802V5.51847H1.62699V7.60909H4.70666V8.89737H0V0.998199H4.70666V2.28648H1.62699Z"
      fill="#38414F"
    />
    <path
      d="M11.1366 8.97648C10.571 8.97648 10.0596 8.8823 9.60253 8.69396C9.15317 8.50561 8.79678 8.2344 8.53337 7.88031C8.26995 7.52622 8.13436 7.1081 8.12661 6.62594H9.86982C9.89307 6.94989 10.0093 7.20604 10.2185 7.39438C10.4354 7.58273 10.7298 7.6769 11.1017 7.6769C11.4813 7.6769 11.7796 7.59026 11.9965 7.41698C12.2135 7.23617 12.3219 7.00262 12.3219 6.71634C12.3219 6.48279 12.2483 6.29068 12.1011 6.14001C11.9539 5.98933 11.768 5.87256 11.5433 5.78969C11.3264 5.69928 11.0242 5.60134 10.6368 5.49587C10.11 5.34519 9.68001 5.19828 9.34686 5.05514C9.02146 4.90447 8.73868 4.68222 8.4985 4.3884C8.26607 4.08705 8.14986 3.68776 8.14986 3.19053C8.14986 2.72344 8.26994 2.31661 8.51012 1.97006C8.75029 1.6235 9.08731 1.35982 9.52118 1.17901C9.95504 0.990666 10.4509 0.896494 11.0087 0.896494C11.8455 0.896494 12.5234 1.09614 13.0425 1.49543C13.5693 1.88719 13.8598 2.43715 13.9141 3.14533H12.1244C12.1089 2.87411 11.9888 2.65187 11.7641 2.47859C11.5472 2.29778 11.2566 2.20737 10.8925 2.20737C10.5749 2.20737 10.3192 2.28648 10.1255 2.44469C9.93955 2.6029 9.84658 2.83268 9.84658 3.13403C9.84658 3.34497 9.91631 3.52202 10.0558 3.66516C10.203 3.80077 10.3812 3.91377 10.5903 4.00418C10.8073 4.08705 11.1094 4.18499 11.4968 4.298C12.0237 4.44867 12.4536 4.59935 12.7868 4.75002C13.1199 4.9007 13.4066 5.12671 13.6468 5.42806C13.887 5.72942 14.007 6.12494 14.007 6.61463C14.007 7.03653 13.8947 7.42828 13.67 7.78991C13.4453 8.15153 13.1161 8.44158 12.6822 8.66006C12.2483 8.871 11.7331 8.97648 11.1366 8.97648Z"
      fill="#38414F"
    />
    <path
      d="M22.759 3.31396C22.573 2.98247 22.3174 2.73009 21.992 2.55681C21.6666 2.38354 21.2869 2.2969 20.8531 2.2969C20.3727 2.2969 19.9466 2.40237 19.5747 2.61332C19.2028 2.82426 18.9123 3.12561 18.7031 3.51737C18.4939 3.90913 18.3893 4.36116 18.3893 4.87345C18.3893 5.40082 18.4939 5.86038 18.7031 6.25213C18.92 6.64389 19.2183 6.94524 19.598 7.15619C19.9776 7.36713 20.4192 7.47261 20.9228 7.47261C21.5426 7.47261 22.0501 7.3144 22.4452 6.99798C22.8403 6.67403 23.0999 6.22577 23.2238 5.6532H20.4347V4.44403H24.8276V5.82271C24.7191 6.37268 24.4867 6.88121 24.1303 7.3483C23.7739 7.81539 23.3129 8.19208 22.7474 8.47837C22.1895 8.75712 21.562 8.89649 20.8647 8.89649C20.0822 8.89649 19.3733 8.72698 18.738 8.38796C18.1104 8.04141 17.6146 7.56301 17.2504 6.95278C16.8941 6.34254 16.7159 5.64943 16.7159 4.87345C16.7159 4.09747 16.8941 3.40436 17.2504 2.79413C17.6146 2.17636 18.1104 1.69796 18.738 1.35894C19.3733 1.01239 20.0783 0.839111 20.8531 0.839111C21.7673 0.839111 22.5614 1.05759 23.2355 1.49455C23.9095 1.92398 24.3744 2.53045 24.63 3.31396H22.759Z"
      fill="#38414F"
    />
  </svg>
);

export const CalendarIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_564_11465)">
      <path
        d="M13.3335 1.99996H12.6668V0.666626H11.3335V1.99996H4.66683V0.666626H3.3335V1.99996H2.66683C1.9335 1.99996 1.3335 2.59996 1.3335 3.33329V14C1.3335 14.7333 1.9335 15.3333 2.66683 15.3333H13.3335C14.0668 15.3333 14.6668 14.7333 14.6668 14V3.33329C14.6668 2.59996 14.0668 1.99996 13.3335 1.99996ZM13.3335 14H2.66683V5.33329H13.3335V14Z"
        fill="#38414F"
      />
    </g>
    <defs>
      <clipPath id="clip0_564_11465">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
