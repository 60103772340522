import React from 'react';
import dashboardIcon from '../../../images/dashboard.png';
import messageIcon from '../../../images/message.png';
import formatIcon from '../../../images/format.png';
import plusIcon from '../../../images/plus.png';
import calendarIcon from '../../../images/calendar.png';
import copyIcon from '../../../images/copy-white.png';
import linkIcon from '../../../images/link.png';
import wireIcon from '../../../images/electricity-emissions.png';
import houseIcon from '../../../images/indirect-other-emissions.png';
import leafIcon from '../../../images/about-company.png';
import emissionsIcon from '../../../images/emissions.png';
import fridgeIcon from '../../../images/direct-emissions.png';
import carIcon from '../../../images/indirect-emissions.png';
import recommendationsIcon from '../../../images/recommendations.png';
import stationaryCombustionIcon from '../../../images/StationaryCombustion.png';
import mobileCombustionIcon from '../../../images/MobileCombustion.png';
import fugitiveEmissionsIcon from '../../../images/FugitiveEmissions.png';
import electricityIcon from '../../../images/Electricity.png';
import steamIcon from '../../../images/Steam.png';
import heatIcon from '../../../images/Heat.png';
import coolingIcon from '../../../images/Cooling.png';
import purchasedIcon from '../../../images/PurchasedGoodandServices.png';
import capitalGoodsIcon from '../../../images/CapitalGoods.png';
import fuelAndEnergyIcon from '../../../images/FuelAndEnergy.png';
import upstreamTransIcon from '../../../images/UpstreamTransportationandDistribution.png';
import wasteIcon from '../../../images/WasteGeneratedinOperations.png';
import businessTravelIcon from '../../../images/BusinessTravel.png';
import employeeCommutingIcon from '../../../images/EmployeeCommuting.png';
import upstreamLeasedAssetsIcon from '../../../images/UpstreamLeasedAssets.png';
import downstreamTransIcon from '../../../images/DownstreamTransportatioAndDistribution.png';
import processingOfSoldProductsIcon from '../../../images/ProcessingofSoldProducts-1.png';
import useOfSoldProductsIcon from '../../../images/ProcessingofSoldProducts.png';
import endoflifeIcon from '../../../images/End-of-LifeTreatmentofSoldProducts.png';
import downstreamLeasedAssetsIcon from '../../../images/DownstreamLeasedAssets.png';
import franchisesIcon from '../../../images/Franchises.png';
import investmentsIcon from '../../../images/Investments.png';
import scope1Icon from '../../../images/scope1.png';
import scope2Icon from '../../../images/scope2.png';
import scope3Icon from '../../../images/scope3.png';
import overallIcon from '../../../images/overall.png';
import gearIcon from '../../../images/gear.png';
import tergetsReductionIcon from '../../../images/targets_reduction.png';
import downloadIcon from '../../../images/download.png';

export default function Icon({ name, alt }: { name: string; alt: string }) {
  let icon;
  switch (name) {
    case 'dashboard':
      icon = dashboardIcon;
      break;
    case 'message':
      icon = messageIcon;
      break;
    case 'format':
      icon = formatIcon;
      break;
    case 'plus':
      icon = plusIcon;
      break;
    case 'settings':
      icon = gearIcon;
      break;
    case 'calendar':
      icon = calendarIcon;
      break;
    case 'copy':
      icon = copyIcon;
      break;
    case 'link':
      icon = linkIcon;
      break;
    case 'electricity-emissions':
      icon = wireIcon;
      break;
    case 'indirect-other-emissions':
      icon = houseIcon;
      break;
    case 'about-company':
      icon = leafIcon;
      break;
    case 'emissions':
      icon = emissionsIcon;
      break;
    case 'direct-emissions':
      icon = fridgeIcon;
      break;
    case 'indirect-emissions':
      icon = carIcon;
      break;
    case 'recommendations':
      icon = recommendationsIcon;
      break;
    case 'stationary-combustion':
      icon = stationaryCombustionIcon;
      break;
    case 'mobile-combustion':
      icon = mobileCombustionIcon;
      break;
    case 'fugitive-emissions':
      icon = fugitiveEmissionsIcon;
      break;
    case 'electricity':
      icon = electricityIcon;
      break;
    case 'steam':
      icon = steamIcon;
      break;
    case 'heat':
      icon = heatIcon;
      break;
    case 'cooling':
      icon = coolingIcon;
      break;
    case 'purchased-good-services':
      icon = purchasedIcon;
      break;
    case 'capital-goods':
      icon = capitalGoodsIcon;
      break;
    case 'fuel-and-energy':
      icon = fuelAndEnergyIcon;
      break;
    case 'upstream-transportation-and-distribution':
      icon = upstreamTransIcon;
      break;
    case 'waste-generated-in-operations':
      icon = wasteIcon;
      break;
    case 'business-travel':
      icon = businessTravelIcon;
      break;
    case 'employee-commuting':
      icon = employeeCommutingIcon;
      break;
    case 'upstream-leased-assets':
      icon = upstreamLeasedAssetsIcon;
      break;
    case 'downstream-transportation-and-distribution':
      icon = downstreamTransIcon;
      break;
    case 'processing-of-sold-products':
      icon = processingOfSoldProductsIcon;
      break;
    case 'use-of-sold-products':
      icon = useOfSoldProductsIcon;
      break;
    case 'end-of-life-treatment-of-sold-products':
      icon = endoflifeIcon;
      break;
    case 'downstream-leased-assets':
      icon = downstreamLeasedAssetsIcon;
      break;
    case 'franchises':
      icon = franchisesIcon;
      break;
    case 'investments':
      icon = investmentsIcon;
      break;
    case 'scope-1':
      icon = scope1Icon;
      break;
    case 'scope-2':
      icon = scope2Icon;
      break;
    case 'scope-3':
      icon = scope3Icon;
      break;
    case 'overall':
      icon = overallIcon;
      break;
    case 'targets_reduction':
      icon = tergetsReductionIcon;
      break;
    case 'download':
      icon = downloadIcon;
      break;
    default:
      icon = dashboardIcon;
      break;
  }
  return <img src={icon} alt={alt} />;
}
