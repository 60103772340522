import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles/';

export const StyledTitle = styled(Typography)`
  color: #2f2f2f;
  padding: 0px 58px 20px;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 141.667% */
  letter-spacing: 0.48px;
`;

export const StyledText = styled(Typography)`
  color: #606462;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  letter-spacing: 0.48px;
  padding: 0px 70px 10px 70px;
`;
