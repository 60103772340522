import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import app from './slice';
import session from './session/slice';
import charts from './charts/slice';
import questionnaire from './questionnaires/slice';
import platform from './platform/slice';
import ghgSuperdashboard from './ghgSuperdashboard/slice';
import esg from './esg/slice';
import lcaDiagram from './lca/slice';
import { cbam } from 'src/Cbam/redux-slice';

export const reducer = {
  app,
  session,
  charts,
  questionnaire,
  lcaDiagram,

  // new global platform reducer
  // for global actions (loading, menuOpen, activeOrganization etc)
  platform,
  ghgSuperdashboard,
  esg,
  cbam,
};

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type Store = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
