import React from 'react';
import { Dialog, Button, DialogContent } from '@mui/material';
import { StyledFlex } from '../StyledFlex';
import { Trans } from 'react-i18next';
import { StyledText, StyledTitle } from './index.styles';

interface ModalBaseProps {
  titleKey?: string;
  textKey?: string;
  children?: React.ReactNode;
  open: boolean;
  onClose?: () => void;
}

export function ModalBase(props: ModalBaseProps) {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md">
      <DialogContent sx={{ minWidth: '500px' }}>
        <StyledTitle>
          <Trans i18nKey={props.titleKey} />
        </StyledTitle>
        {!!props.textKey && (
          <StyledText>
            <Trans i18nKey={props.textKey} />
          </StyledText>
        )}
        {props.children}
      </DialogContent>
    </Dialog>
  );
}

interface ConfirmationModalProps extends ModalBaseProps {
  trueButtonTextKey?: string;
  falseButtonTextKey?: string;
  // eslint-disable-next-line no-unused-vars
  onFalse?: () => void;
  onTrue?: () => void;
}

export default function ConfirmationModal({
  titleKey = 'common.areYouSure',
  textKey = undefined,
  trueButtonTextKey = 'common.yes',
  falseButtonTextKey = 'common.no',
  open = false,
  onClose,
  onFalse,
  onTrue,
  children,
}: ConfirmationModalProps) {
  return (
    <ModalBase
      titleKey={titleKey}
      textKey={textKey}
      open={open}
      onClose={onClose}
    >
      {children}
      <StyledFlex sx={{ p: '20px 40px' }}>
        <Button
          className="ConfirmationButton ConfirmationTrue"
          color="secondary"
          onClick={onTrue}
          sx={{ width: '48%' }}
        >
          <Trans i18nKey={trueButtonTextKey} />
        </Button>
        <Button
          className="ConfirmationButton ConfirmationFalse"
          variant="contained"
          onClick={onFalse}
          sx={{ width: '48%' }}
        >
          <Trans i18nKey={falseButtonTextKey} />
        </Button>
      </StyledFlex>
    </ModalBase>
  );
}
