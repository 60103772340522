import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { loginWithCustomToken } from '../../../utils';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/Loader';

export default function LoginWithToken() {
  const { token } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const login = async (token: string) => {
    try {
      await loginWithCustomToken(token);
      window.location.href = '/';
    } catch (error: any) {
      const errorMessage =
        error instanceof AxiosError && error.response?.status === 401
          ? t('login.invalid-token')
          : error.message;
      toast.error(errorMessage);
      navigate('/login');
    }
  };

  useEffect(() => {
    if (token) login(token);
  }, [token]);

  return <Loader />;
}
