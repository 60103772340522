import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import { AutocompleteCategories, Select } from '../fields';
import { choices, units } from './data';
import { useAppDispatch, useAppSelector } from '../../../../redux-file/hooks';
import { Overlay } from '../Title/index.styles';
import { Form, FormBox } from './index.styles';
import {
  getWasteAndOtherActivitiesData,
  getWasteAndOtherActivitiesDataEmissionShares,
  putWasteAndOtherActivitiesData,
} from '../../../../redux-file/questionnaires/thunks';
import { timeConverter } from '../../../../utils/timeConverter';
import FormButtons from '../FormButtons';
import { useGhgOrganization } from 'src/common/hooks';

export default function OtherFuelForm({
  state,
  setState,
  id,
  active,
  setActive,
  combinedData,
  setNewForm,
}: {
  setNewForm: Function;
  state: any;
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  combinedData: any;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector(
    (redux_state) => redux_state.questionnaire
  );
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const [type_of_fuel_error_state, set_type_of_fuel_error_state] =
    useState(false);
  const [unit_error_state, set_unit_error_state] = useState(false);
  const [amount_per_period_error_state, set_amount_per_period_error_state] =
    useState(false);
  const [manual_factor_error_state, set_manual_factor_error_state] =
    useState(false);

  let type_of_fuel_error = false;
  let unit_error = false;
  let amount_per_period_error = false;
  let manual_factor_error = false;

  const type_of_fuel_validation = (value: string) => {
    if (value === '') type_of_fuel_error = true;
    return type_of_fuel_error;
  };
  const unit_validation = (value: string) => {
    if (value === '') unit_error = true;
    return unit_error;
  };
  const amount_per_period_validation = (value: string) => {
    if (value === '') amount_per_period_error = true;
    return amount_per_period_error;
  };
  const manual_factor_validation = (value: string) => {
    if (value === '') manual_factor_error = true;
    return manual_factor_error;
  };

  const handleTypeOfFuel = (value: string) => {
    type_of_fuel_validation(value);
    set_type_of_fuel_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      comment: tempForm.comment,
      type_of_fuel: value,
      amount_per_period: tempForm.amount_per_period,
      unit: tempForm.unit,
      manual_factor: tempForm.manual_factor,
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_type_of_fuel_error_state(true);
  };

  const setUnit = (value: string) => {
    unit_validation(value);
    set_unit_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      comment: tempForm.comment,
      type_of_fuel: tempForm.type_of_fuel,
      amount_per_period: tempForm.amount_per_period,
      unit: value,
      manual_factor: tempForm.manual_factor,
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_unit_error_state(true);
  };

  const handleAmount = (value: string) => {
    amount_per_period_validation(value);
    set_amount_per_period_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      comment: tempForm.comment,
      type_of_fuel: tempForm.type_of_fuel,
      amount_per_period: value,
      unit: tempForm.unit,
      manual_factor: tempForm.manual_factor,
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_amount_per_period_error_state(true);
  };
  const handleManualFactor = (value: string) => {
    manual_factor_validation(value);
    set_manual_factor_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      comment: tempForm.comment,
      type_of_fuel: tempForm.type_of_fuel,
      amount_per_period: tempForm.amount_per_period,
      unit: tempForm.unit,
      manual_factor: value === '' ? null : value,
    };
    newForms[id] = tempForm;
    setState(newForms);

    if (newForms[id].type_of_fuel === 'other.manual_factor' && value === '') {
      set_manual_factor_error_state(true);
    }
  };

  const handleSend = (data: any) => {
    if (
      !unit_error &&
      !type_of_fuel_error &&
      !amount_per_period_error &&
      !manual_factor_error
    ) {
      dispatch(
        putWasteAndOtherActivitiesData({
          period: timeConverter(selectedDate, reportingPeriod),
          data,
        })
      ).then(() => {
        dispatch(
          getWasteAndOtherActivitiesData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
        dispatch(
          getWasteAndOtherActivitiesDataEmissionShares({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
      });
      setActive(undefined);
    }
  };

  const defineErrors = async () => {
    if (state[id].amount_per_period === '') {
      set_amount_per_period_error_state(true);
      amount_per_period_error = true;
    }

    if (state[id].unit === '') {
      set_unit_error_state(true);
      unit_error = true;
    }

    if (state[id].type_of_fuel === '') {
      set_type_of_fuel_error_state(true);
      type_of_fuel_error = true;
    }

    if (
      state[id].type_of_fuel === 'other.manual_factor' &&
      !state[id].manual_factor
    ) {
      set_manual_factor_error_state(true);
      manual_factor_error = true;
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    const tempcombinedData = {
      other_fuel_related_activities_data: tempState,
      waste_data: combinedData.waste_data,
      other_energy_related_activities:
        combinedData.other_energy_related_activities,
      other_emissions: combinedData.other_emissions,
    };

    handleSend(tempcombinedData);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              handleSend(combinedData);
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <FormBox variant="50">
            <AutocompleteCategories
              active
              index={1}
              max={1}
              // @ts-ignore
              options={choices}
              label={t('waste-and-other.other-fuel.type_of_fuel')}
              error={type_of_fuel_error_state}
              value={state[id].type_of_fuel}
              setValue={(e: string) => handleTypeOfFuel(e)}
            />
          </FormBox>

          <FormBox variant="50">
            <Select
              active
              index={6}
              max={4}
              options={units}
              label="waste-and-other.other-fuel.unit"
              error={unit_error_state}
              value={state[id].unit}
              setValue={(value: string) => setUnit(value)}
            />
          </FormBox>

          <FormBox variant="100">
            <TextFieldNumber
              label={t('waste-and-other.other-fuel.amount_per_period')}
              active
              error={amount_per_period_error_state}
              index={1}
              value={state[id].amount_per_period}
              setValue={(e: string) => handleAmount(e)}
            />
          </FormBox>

          {state[id].type_of_fuel === 'other.manual_factor' && (
            <FormBox variant="100">
              <TextFieldNumber
                type="factor"
                label={t('waste-and-other.waste.manual_factor')}
                active
                error={manual_factor_error_state}
                index={3}
                // @ts-ignore
                value={
                  state[id].manual_factor === null
                    ? ''
                    : state[id].manual_factor
                }
                setValue={(e: string) => handleManualFactor(e)}
              />
            </FormBox>
          )}
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={
              state[id].amount_per_period !== '' &&
              state[id].unit !== '' &&
              state[id].type_of_fuel !== '' &&
              (state[id].type_of_fuel !== 'other.manual_factor' ||
                !!state[id].manual_factor)
            }
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
