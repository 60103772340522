import React from 'react';
import {
  ButtonGreen,
  ButtonWhite,
  CloseBtn,
  WarningModal,
  WarningModalButtonsBox,
  WarningTitle,
} from './index.styles';
import { useTranslation } from 'react-i18next';

interface Props {
  abortButton: Function;
  goButton: Function;
}

export default function WarningModalCopyForm({ abortButton, goButton }: Props) {
  const { t } = useTranslation();
  return (
    <WarningModal>
      <CloseBtn
        onClick={() => abortButton()}
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31 18.41L29.59 17L24 22.59L18.41 17L17 18.41L22.59 24L17 29.59L18.41 31L24 25.41L29.59 31L31 29.59L25.41 24L31 18.41Z"
          fill="#606462"
        />
      </CloseBtn>

      <WarningTitle>{t('copy_form.warning-title')}</WarningTitle>
      <p dangerouslySetInnerHTML={{ __html: t('copy_form.warning') }} />
      <WarningModalButtonsBox>
        <ButtonWhite type="button" onClick={() => goButton()}>
          {t('targets.yes')}
        </ButtonWhite>
        <ButtonGreen type="button" onClick={() => abortButton()}>
          {t('targets.no')}
        </ButtonGreen>
      </WarningModalButtonsBox>
    </WarningModal>
  );
}
