import { Box, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCell = styled(Box)<{
  w: string | number;
}>`
  width: ${(props) => props.w};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: 0.03em;
  text-align: left;
`;

export const StyledHeader = styled(Box)<{
  w: string | number;
}>`
  width: ${(props) => props.w};
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0.03em;
  text-align: left;
`;

export const StyledMenuItem = styled(MenuItem)`
  font-weight: 600;
  color: #38414f;
  font-size: 14px;
`;
