import React from 'react';

import { Box, SxProps } from '@mui/material';
import YearSelector from 'src/Superadmin/components/Panels/YearSelector';
import { StyledPanel, StyledTitle } from 'src/Superadmin/index.styles';
import GhgUserPermissionsTable from './Table';
import { useTranslation } from 'react-i18next';

interface Props {
  sx?: SxProps;
  userId: number;
}

export default function GhgUserPermissions(props: Props) {
  const { sx, userId } = props;
  const { t } = useTranslation();

  const currYear = new Date().getFullYear();
  const [year, setYear] = React.useState(currYear);

  return (
    <Box sx={sx}>
      <StyledTitle sx={{ fontSize: '28px' }}>
        {t('superadmin.settings.tabs.users.usersTable.permissions.title.ghg')}
      </StyledTitle>

      <YearSelector
        startYear={2000}
        endYear={currYear + 12}
        selectedYear={year}
        onYearChange={setYear}
      />

      <StyledPanel sx={{ mt: '32px' }}>
        <GhgUserPermissionsTable userId={userId} year={year} />
      </StyledPanel>
    </Box>
  );
}
