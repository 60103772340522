import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import {
  Paper,
  Checkbox,
  FormControlLabel,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { client } from '../../../../utils/api-client';
import { paths } from '../helpers';
import { Questionnaire } from '../Questionnaire/index.types';
import { StyledPanel } from './Management.styles';

export default function Management() {
  /* hooks */
  const navigate = useNavigate();
  const { questionnaireAnswerId } = useParams();
  const searchParams = new URLSearchParams(window.location.search);

  /* state */
  const [id, setId] = useState(questionnaireAnswerId || '');

  const [populateFields, setPopulateFields] = useState(
    !!searchParams.get('populate')
  );

  /* requests */
  const { data: questionnaires } = useQuery(['questionnaires'], () =>
    client.get(paths.questionnaires).then((res) => res.data)
  );

  /* onChange handlers */
  const handleIdChange = (e: SelectChangeEvent) => {
    const { value: newId } = e.target;
    setId(newId);
    const populate = !!populateFields ? '?populate=1' : '';
    navigate(`/questionnaire-preview/${newId}${populate}`);
  };

  const handlePopulateFieldsClick = () => {
    const newPopulateFields = !populateFields;
    const populate = newPopulateFields ? '?populate=1' : '';
    setPopulateFields(newPopulateFields);
    navigate(`/questionnaire-preview/${id}${populate}`);
  };

  return (
    <Paper elevation={1} sx={{ p: 1 }}>
      <Typography
        component="h5"
        variant="h5"
        sx={{ p: 1, borderBottom: '1px solid #e0e0e0' }}
      >
        Questionnaire Preview
      </Typography>

      <StyledPanel>
        <FormControl variant="outlined" sx={{ width: 500 }}>
          <InputLabel size="small">Questionnaire ID</InputLabel>

          <Select
            size="small"
            label="Questionnaire ID"
            value={id}
            onChange={handleIdChange}
          >
            {questionnaires?.map((questionnaire: Questionnaire) => (
              <MenuItem key={questionnaire.id} value={questionnaire.id}>
                {questionnaire.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControlLabel
          sx={{ ml: 1 }}
          control={
            <Checkbox
              checked={populateFields}
              onChange={handlePopulateFieldsClick}
            />
          }
          label="Populate fields"
        />
      </StyledPanel>
    </Paper>
  );
}
