import React from 'react';
import { Navigate } from 'react-router-dom';
import { useCodeOfConductSurvey } from './hooks';
import PageLoading from '../../components/PageLoading';
import SomethingWentWrong from '../../components/SomethingWentWrong';

export default function CodeOfConductSurvey() {
  const { query, isAnswerUptoDate } = useCodeOfConductSurvey();

  if (query.isError) return <SomethingWentWrong />;
  if (isAnswerUptoDate === undefined) return <PageLoading />;

  if (isAnswerUptoDate) {
    return <Navigate to="end" />;
  }

  return <Navigate to="form" />;
}
