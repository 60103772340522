export const commutingData = [
  { value: 'car_diesel' },
  { value: 'car_petrol' },
  { value: 'car_lpg' },
  { value: 'car_hybrid' },
  { value: 'car_electric_vehicle' },
  { value: 'car_unknown' },
  { value: 'motorbike' },
  { value: 'taxi' },
  { value: 'bus' },
  { value: 'tram' },
  { value: 'metro' },
  { value: 'train' },
  { value: 'electric_scooter' },
  { value: 'bike_or_walk' },
  { value: 'none' },
];

export const businessTripData = [
  { value: 'car_diesel' },
  { value: 'car_petrol' },
  { value: 'car_lpg' },
  { value: 'car_hybrid' },
  { value: 'car_electric_vehicle' },
  { value: 'car_unknown' },
  { value: 'motorbike' },
  { value: 'taxi' },
  { value: 'bus_coach' },
  { value: 'bus_local' },
  { value: 'train' },
  { value: 'flight_short_haul_up_to_4h_economy_class' },
  { value: 'flight_short_haul_up_to_4h_business_class' },
  { value: 'flight_long_haul_more_than_4h_economy_class' },
  { value: 'flight_long_haul_more_than_4h_business_class' },
  { value: 'flight_long_haul_more_than_4h_first_class' },
  { value: 'ferry_foot_passenger' },
  { value: 'ferry_car_passenger' },
  { value: 'none' },
];

export const days = [
  { value: '0' },
  { value: '1' },
  { value: '2' },
  { value: '3' },
  { value: '4' },
  { value: '5' },
  { value: '6' },
  { value: '7' },
];
