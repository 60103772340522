import React from 'react';
import { Desc, Heading, Title } from './index.styles';
import FormAssumptions from './forms/assumptions';
import ProductCharacteristics from './forms/product-characteristics';
import Materials from './forms/materials';
import { LcaProductMaterial } from 'src/Lca/types';

interface Props {
  values: {
    functional_unit: string;
    data_collection_period: string;
    weight: number | null;
    weight_unit: string;
    dimensions: string;
    dimensions_unit: string;
    volume: number | null;
    volume_unit: string;
    production_process_description: string;
    assumptions_description: string;
    reference_service_life: string;
    cutoff_rules: string;
    additional_information: string;
    materials: Array<LcaProductMaterial>;
  };
  setValues: Function;
}

export default function LcaInfo({ setValues, values }: Props) {
  return (
    <div>
      <Title>Scope: LCA information</Title>
      <Desc>
        According to the ISO norm, functional unit is the quantitative reference
        unit in a life cycle study. In practice, determining the functional unit
        allows you to convert the results of the analysis of inputs and outputs,
        e.g. materials or energy used, to a given, specific unit, e.g. 1 piece
        or 1 kg of product. Thanks to this, we have the opportunity to compare
        the same or similar products with each other. This section should also
        describe the assumptions that have been made to conduct the analysis
        and/or which aspects of the production process have been omitted due to
        the marginal impact on the final result. Provide here all the
        information relevant to the methodology and the calculations made to
        make it easier for the reader to understand your analysis.
      </Desc>
      <Heading>Product characteristics</Heading>
      <ProductCharacteristics values={values} setValues={setValues} />
      <Heading>Material composition</Heading>
      <Materials
        values={values}
        setValues={setValues as (values: any) => void}
      />
      <Heading>Assumptions</Heading>
      <FormAssumptions values={values} setValues={setValues} />
    </div>
  );
}
