import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormBox } from '../../Questionnaire/forms/index.styles';
import { Overlay } from '../../Questionnaire/Title/index.styles';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import { useAppSelector } from '../../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';

export default function EmployeesRevenueForm({
  noOfEmployees,
  setNoOfEmployees,
  incInReportingPeriod,
  setIncInReportingPeriod,
  sendData,
  active,
  setActive,
}: {
  noOfEmployees: number | null;
  setNoOfEmployees: Function;
  incInReportingPeriod: number | null;
  setIncInReportingPeriod: Function;
  sendData: Function;
  active: undefined | boolean;
  setActive: Function;
}) {
  const organization = useGhgOrganization();
  const reportingCurrency = organization.co2.reporting_currency;
  const { t } = useTranslation();

  const setValidateNoOfEmployees = (e: string) => {
    setNoOfEmployees(+e);
  };
  const setValidateIncInReportingPeriod = (e: string) => {
    setIncInReportingPeriod(+e);
  };

  const incomeInReportingPeriodLabel = `${t(
    'about-company.income-in-reporting-period'
  )} [${reportingCurrency}] (${t('optional')})`;

  return (
    <>
      {active === true && <Overlay onClick={() => sendData()} />}
      <Form active={active === true}>
        <div onClick={() => setActive(true)}>
          <FormBox variant="100">
            <TextFieldNumber
              type="integer"
              label={t('about-company.number-of-employees-period')}
              active
              error={false}
              index={3}
              value={noOfEmployees ?? ''}
              setValue={(e: string) => setValidateNoOfEmployees(e)}
            />
          </FormBox>
          <FormBox variant="100">
            <TextFieldNumber
              type="integer"
              label={incomeInReportingPeriodLabel}
              active
              error={false}
              index={3}
              value={incInReportingPeriod ?? ''}
              setValue={(e: string) => setValidateIncInReportingPeriod(e)}
            />
          </FormBox>
        </div>
      </Form>
    </>
  );
}
