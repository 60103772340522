import React from 'react';

export default function AddCommentIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8 16.8V28.653L17.853 27.6H31.2V16.8H16.8ZM16.8 15H31.2C32.19 15 33 15.81 33 16.8V27.6C33 28.59 32.19 29.4 31.2 29.4H18.6L15 33V16.8C15 15.81 15.81 15 16.8 15ZM18.6 24.9C18.6 24.4029 19.0029 24 19.5 24H28.5C28.9971 24 29.4 24.4029 29.4 24.9C29.4 25.3971 28.9971 25.8 28.5 25.8H19.5C19.0029 25.8 18.6 25.3971 18.6 24.9ZM18.6 22.2C18.6 21.7029 19.0029 21.3 19.5 21.3H28.5C28.9971 21.3 29.4 21.7029 29.4 22.2C29.4 22.6971 28.9971 23.1 28.5 23.1H19.5C19.0029 23.1 18.6 22.6971 18.6 22.2ZM18.6 19.5C18.6 19.0029 19.0029 18.6 19.5 18.6H28.5C28.9971 18.6 29.4 19.0029 29.4 19.5C29.4 19.9971 28.9971 20.4 28.5 20.4H19.5C19.0029 20.4 18.6 19.9971 18.6 19.5Z"
        fill="#606462"
      />
    </svg>
  );
}
