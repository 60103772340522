import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';
import { TFunction } from 'react-i18next';
import { CbamMeasurementBasedInSchema } from 'src/Cbam/types';

export type FormErrors = SchemaErrors<CbamMeasurementBasedInSchema>;

export const requiredFields: (keyof CbamMeasurementBasedInSchema)[] = [
  'name',
  'n2o_emissions',
  'co2_emissions',
];

export const validateForm = (
  data: CbamMeasurementBasedInSchema,
  t: TFunction
): FormErrors => {
  const builder = new ErrorBuilder(data);
  return builder.checkTruthy(requiredFields, t('validation.required')).build();
};
