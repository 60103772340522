import React from 'react';
import { Desc, Heading, Title } from './index.styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled as styledMUI } from '@mui/material/styles';
import BasicInfo from './forms/basic-info';
import Location from './forms/location';

export const StyledTextField = styledMUI(TextField)<TextFieldProps>`
& .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #7E4308;
    };
  };
  & label.Mui-focused {
    color: #7E4308;
  }
`;

export interface ProductInformationProps {
  values: {
    name: string;
    description: string;
    certifications_description: string;
    production_location: string;
    production_country: { iso_code: string };
    nace_industries: Array<{
      code: string;
    }>;
    distribution_regions: Array<{
      iso_code: string;
      name: string;
    }>;
    cpc_industry: { code: string };
  };
  setValues: Function;
}

export default function ProductInformation({
  setValues,
  values,
}: ProductInformationProps) {
  const handleCert = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({ ...values, certifications_description: e.target.value });
  };

  return (
    <div>
      <Title>Product information</Title>
      <Desc>
        Before you go to report the Life Cycle Assessment, provide general
        information about the product.
      </Desc>
      <Heading>Basic information</Heading>
      <BasicInfo values={values} setValues={setValues} />
      <Heading>Locations</Heading>
      <Location values={values} setValues={setValues} />
      <Heading>Certifications</Heading>
      <StyledTextField
        fullWidth
        label="Certifications"
        name="certifications_description"
        value={values.certifications_description}
        onChange={handleCert}
        multiline
        rows={5}
      />
    </div>
  );
}
