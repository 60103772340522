import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';
import { TFunction } from 'react-i18next';
import { CbamElectricitySchema, ElectricityFactorSource } from 'src/Cbam/types';

export type FormErrors = SchemaErrors<CbamElectricitySchema>;

export const requiredFields: (keyof CbamElectricitySchema)[] = [
  'electricity_consumption',
  'source',
  'factor_source',
  'emission_factor',
];

export const validateForm = (
  data: CbamElectricitySchema,
  t: TFunction
): FormErrors => {
  const builder = new ErrorBuilder(data);
  return builder
    .checkTruthy(requiredFields, t('validation.required'))
    .checkRule(
      ['other_source_indication', 'factor_source_details'],
      (field, schema) => {
        if (schema.factor_source === ElectricityFactorSource.OTHER) {
          return !!field;
        }
        return true;
      },
      t('validation.required')
    )
    .build();
};
