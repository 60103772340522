import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, ListItemIcon, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';

import {
  ProfileButton,
  StyledCompanyInfo,
  StyledMenuItem,
} from './index.styles';
import { StyledMenu, StyledTypography } from '../index.styles';
import { handleLogout } from '../../../utils';
import { useAppSelector, usePlatform } from '../../../redux-file/hooks';
import DashbordIcon from '../../../images/svg/dashbordIcon.';
import { usePlatformNavigation } from '../../../utils/navigation';
import { ProductPlatform } from '../../../utils/api.interfaces';
import BusinessIcon from '@mui/icons-material/Business';
import OrganizationsTreeModal from './OrganizationsTreeModal';

const Profile = () => {
  const { t } = useTranslation();
  const { activeOrganization } = usePlatform();
  const { productOrganizations, navigate, navigateToOrganization } =
    usePlatformNavigation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [changeOrganizationOpen, setChangeOrganizationOpen] =
    React.useState<boolean>(false);

  const { user, allowAccessToManageOrgStructure, platformSubscriber } =
    useAppSelector((state) => state.platform);

  const companyName =
    activeOrganization?.name || platformSubscriber?.name || 'Unauthorized';

  const handleClose = () => setAnchorEl(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleMyAccountClick = () => {
    handleClose();
    navigate('/superadmin/my-account');
  };

  const handleOrgSettingsClick = () => {
    handleClose();
    navigate('/superadmin');
  };

  const handleLogoutClick = () => {
    handleClose();
    handleLogout();
  };

  // Show GHG summary (superdashboard)
  // if there are at least two organizations with GHG
  const showGhgSummary =
    productOrganizations.filter((x) =>
      x.active_products.includes(ProductPlatform.Co2)
    ).length > 1;

  const handleChangeOrganizationClick = () => {
    handleClose();
    setChangeOrganizationOpen(true);
  };
  return (
    <>
      <ProfileButton onClick={handleOpen}>
        <StyledTypography noWrap>{companyName}</StyledTypography>
      </ProfileButton>

      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ width: '360px' }}
      >
        <StyledCompanyInfo>
          <h1>{companyName}</h1>
          <h5>{user?.email}</h5>
        </StyledCompanyInfo>
        {productOrganizations.length > 1 && (
          <StyledMenuItem onClick={handleChangeOrganizationClick}>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <Typography>{t('header.changeOrganisation')}</Typography>
          </StyledMenuItem>
        )}
        {showGhgSummary && (
          <StyledMenuItem
            onClick={() => {
              navigateToOrganization(null, ProductPlatform.Co2);
            }}
          >
            <ListItemIcon>
              <DashbordIcon />
            </ListItemIcon>
            <Typography>{t('header.ghg-summary')}</Typography>
          </StyledMenuItem>
        )}
        <StyledMenuItem onClick={handleMyAccountClick}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <Typography>{t('superadmin.myAccount.title')}</Typography>
        </StyledMenuItem>
        {allowAccessToManageOrgStructure && (
          <StyledMenuItem onClick={handleOrgSettingsClick}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <Typography>{t('superadmin.myOrganization.title')}</Typography>
          </StyledMenuItem>
        )}
        <StyledMenuItem onClick={handleLogoutClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>

          <Typography>{t('header.logout')}</Typography>
        </StyledMenuItem>
        <Divider />
      </StyledMenu>
      {productOrganizations.length > 1 && (
        <OrganizationsTreeModal
          open={changeOrganizationOpen}
          onClose={() => setChangeOrganizationOpen(false)}
        />
      )}
    </>
  );
};

export default Profile;
