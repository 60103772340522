import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  background-color: #0ca0a0;
  margin-top: -2.5rem;
  margin-left: -1.5rem;
  margin-right: -2.5rem;
  height: calc(100% + 2.5rem);
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  padding-left: 10rem;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 48px;
  line-height: 36px;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-bottom: 2.25rem;
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-bottom: 2.25rem;
`;

export const StyledLink = styled(Link)`
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 28px;
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #0ca0a0;
  text-decoration: none;
  padding-left: 2rem;
  padding-right: 1.125rem;
  width: auto;

  span {
    padding-right: 1.125rem;
  }
`;

export const ImageWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
