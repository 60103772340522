import styled from 'styled-components';
import themeColors from '../../../utils/theme';
import { NextButtonProps } from './index.interfaces';

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
  padding-top: 40px;
  padding-bottom: 150px;
  margin: 0 auto;
`;

export const EntryBox = styled.div`
  margin-top: 40px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #b6bbb9;
  border-radius: 16px;
  overflow: hidden;
  > img {
    margin-top: -5px;
    margin-left: -5px;
    max-width: calc(100% + 10px);
    position: absolute;
    z-index: 0;
  }
  > .text-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    height: 200px;
    color: #ffffff;
    line-height: 44px;
  }
  > .text-title > h1 {
    margin: 0 auto;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-shadow: 1px 3px 10px rgba(0, 0, 0, 0.4);
  }
  > .text-title > p {
    margin: 0 auto;
    font-weight: 500;
    font-size: 24px;
    text-shadow: 1px 3px 10px rgba(0, 0, 0, 0.4);
  }
`;

export const TextBox = styled.div`
  margin: 24px 24px 18px 24px;
  > h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #132c1d;
    text-align: left;
  }
`;

export const ButtonsBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d9dfdd;
  border-radius: 28px;
  padding: 8px 30px 8px 10px;
  margin-left: 0;
  margin-right: auto;
  cursor: pointer;
  > span {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #388276;
  }
  > img {
    height: 12px;
    width: 7.41px;
    margin-right: 20px;
  }
  &:hover {
    transition: background-color 0.3s ease-in-out;
    background-color: #f9f9f9;
  }
`;

export const NextButton = styled.div<NextButtonProps>(
  (props) => `
  cursor: ${props.error ? 'not-allowed' : 'pointer'};
  display: flex;
  align-items: center;
  background-color: ${props.error ? '#D9DFDD' : '#388276'};
  border: 2px solid ${props.error ? '#D9DFDD' : '#388276'};
  border-radius: 28px;
  padding: 8px 10px 8px 30px;
  margin-right: 0;
  margin-left: auto;
  > span {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #ffffff;
  }
  > img {
    height: 12px;
    width: 7.41px;
    margin-left: 20px;
  }
  &:hover {
    transition: background-color, border 0.3s ease-in-out;
    background-color: ${props.error ? '#D9DFDD' : '#499387'};
    border: 2px solid ${props.error ? '#D9DFDD' : '#499387'};
  }
`
);

export const FieldsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% + 192px);
  max-width: 992px;
  &&.company {
    width: 100%;
    max-width: 800px;
  }
`;

export const Box = styled.div`
  padding: 36px 24px 48px;
`;

export const MaintText = styled.h1`
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 24px;
`;

export const Subtext = styled.p`
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 24px;
`;

export const Disclaimer = styled.p`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 32px;
  color: ${themeColors.redParagraphs};
`;

export const Subtitle = styled.h2`
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 24px;
  text-align: left;
`;

export const Wrapper = styled.div`
  margin-bottom: 32px;
`;
