import React from 'react';
import {
  StyledHeader,
  StyledTd,
} from '../../../Pages/Questionnaire/index.styles';
import { companiesColors } from '../../../Pages/SuperDashboard/data';
import CommentBtn from './commentBtn';
import TableButton from '../../TableButton';
import { toLocaleNumber } from '../../../../utils/helpers';

export default function DistributionPreview({
  state,
  id,
  setActive,
  setState,
  language,
}: {
  state: any;
  id: number;
  setActive: Function;
  setState: Function;
  language: string;
}) {
  const handleCopy = () => {
    const tempState = [...state];
    const item = tempState[id];

    tempState.splice(id, 0, item);
    setState(tempState);
    setActive(id);
  };
  return (
    <StyledHeader>
      <StyledTd width={25} color={companiesColors[id]}>
        <p>
          {state[id].distribution_data_factor_unknown
            ? toLocaleNumber(
                state[id].distribution_data_factor_unknown
                  .amount_stored_kg_in_period,
                language
              )
            : ''}
        </p>
        <CommentBtn state={state} id={id} />
      </StyledTd>
      <StyledTd width={35} color={companiesColors[id]}>
        {state[id].distribution_data_factor_unknown
          ? toLocaleNumber(
              state[id].distribution_data_factor_unknown
                .storage_duration_days_in_period,
              language
            )
          : ''}
      </StyledTd>
      <StyledTd width={15} color={companiesColors[id]}>
        {state[id].emission_factor_is_known
          ? state[id].distribution_data_factor_known.unit_label
          : 'kg'}
      </StyledTd>
      <StyledTd width={25} color={companiesColors[id]}>
        {state[id].emission_shared
          ? (state[id].emission_shared * 100).toFixed(2)
          : 0}
      </StyledTd>
      <StyledTd
        width={0}
        color={companiesColors[id]}
        lastCallCustom
        numberOfIcons="two"
      >
        <TableButton hoverText="copy" icon="copy" onClick={handleCopy} />
        <TableButton
          hoverText="edit"
          icon="edit"
          onClick={() => setActive(id)}
        />
      </StyledTd>
    </StyledHeader>
  );
}
