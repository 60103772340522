import { useQuery, useQueryClient } from 'react-query';
import { client } from '../../utils/api-client';

type TopicsTableType = 'financial' | 'nonfinancial';

/* eslint-disable import/prefer-default-export */
export function useTopicsTableData(
  organizationId: number,
  reportId: string,
  table: TopicsTableType,
  preliminary: boolean
) {
  const queryClient = useQueryClient();

  const queryKey = ['dma', organizationId, reportId, 'topics-table', table];
  const baseUrl = `/web/esg_dma/${organizationId!}/${reportId}/topics-tables/${table}`;
  const params = { preliminary };

  const updateTableRow = (row: any) => {
    client
      .put(`${baseUrl}/row`, row, { params })
      .then(() => queryClient.invalidateQueries(queryKey, { exact: true }));
  };

  // If preliminary is false, we need to sync the data before fetching it
  const requireSync = !preliminary;
  const syncQuery = useQuery(
    [...queryKey, 'sync'],
    () => client.post(`${baseUrl}/sync`, {}, { params }),
    { enabled: requireSync }
  );
  const fetchQuery = useQuery(
    queryKey,
    () => client.get(baseUrl, { params }).then((res) => res.data),
    { enabled: syncQuery.isSuccess || !requireSync }
  );

  // propagate errors from syncQuery
  const query = syncQuery.isError ? syncQuery : fetchQuery;

  return { ...query, updateTableRow };
}
