import React from 'react';
import Plotly from 'customplotly';
import createPlotlyComponent from 'react-plotly.js/factory';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import styles from '../../styles.module.css';
import PageLoading from '../../../../../components/PageLoading';

const Plot = createPlotlyComponent(Plotly);

export interface GroupDataProps {
  name: string;
  value:
    | string
    | {
        [key: string]: {
          [key: string]: number;
        };
      };
  isSelected: boolean;
}

export default function CarbonFootprintPerGroup({
  data1,
  data2,
  data3,
  isLoading,
  colors,
}: {
  data1: GroupDataProps[];
  data2: GroupDataProps[];
  data3: GroupDataProps[];
  isLoading: boolean;
  colors: string[];
}) {
  const { t } = useTranslation();

  if (isLoading) {
    return <PageLoading />;
  }

  // 1. filtrujemy elementy by byly tylko ze z isSelected === true
  // 2. mapujemy tak by zostało tylko value ktore w tym momencie jest nam tylko potrzebne
  // 3. z obiektu tworzymy array i mapujemy by przeksztalcic obiekty
  // 4. flatujemy i sortujemy po datach (usuwamy "-" miedzy rokiem a miesiacem i zamieniamy stringa na number)
  const filtrData = (data: GroupDataProps[]) =>
    data
      .filter((el) => el.isSelected === true)
      .map((el) => el.value)
      .map((el) =>
        Object.entries(el).map(([key, value]) => ({
          date: key,
          value,
        }))
      )
      .flat()
      .sort(
        (a, b) => +a.date.replaceAll('-', '') - +b.date.replaceAll('-', '')
      );

  const filteredData1 = filtrData(data1);
  const filteredData2 = filtrData(data2);
  const filteredData3 = filtrData(data3);

  // grupujemy razem te same daty
  const groupedData1 = groupBy(filteredData1, 'date');
  const groupedData2 = groupBy(filteredData2, 'date');
  const groupedData3 = groupBy(filteredData3, 'date');
  // i dodajemy wartosci z jednej daty do siebie

  const total1 = Object.values(groupedData1).map((el) => ({
    date: el[0].date,
    value: el
      .map((item) => item.value)
      .reduce((a: number, b: number) => a + b, 0),
  }));

  const total2 = Object.values(groupedData2).map((el) => ({
    date: el[0].date,
    value: el
      .map((item) => item.value)
      .reduce((a: number, b: number) => a + b, 0),
  }));

  const total3 = Object.values(groupedData3).map((el) => ({
    date: el[0].date,
    value: el
      .map((item) => item.value)
      .reduce((a: number, b: number) => a + b, 0),
  }));

  const sum = [...filteredData1, ...filteredData2, ...filteredData3]
    .map((key) => key.value)
    .reduce((a: number, b: number) => a + b, 0);

  return (
    <>
      <Plot
        data={[
          {
            type: 'scatter',
            x: total1.map((el) => el.date),
            y: total1.map((el) => el.value),
            mode: 'lines+markers',
            line: { shape: 'spline' },
            marker: { color: colors[0] },
            name: 'Group 1',
          },
          {
            type: 'scatter',
            x: total2.map((el) => el.date),
            y: total2.map((el) => el.value),
            mode: 'lines+markers',
            line: { shape: 'spline' },
            marker: { color: colors[1] },
            name: 'Group 2',
          },
          {
            type: 'scatter',
            x: total3.map((el) => el.date),
            y: total3.map((el) => el.value),
            mode: 'lines+markers',
            line: { shape: 'spline' },
            marker: { color: colors[2] },
            name: 'Group 3',
          },
        ]}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
        layout={{
          showlegend: false,
          margin: { l: 40, r: 40, b: 40, t: 40 },
          font: { family: 'Poppins', size: 16, color: '#444' },
          xaxis: {
            dtick: 'M1',
            showline: true,
            linewidth: 2,
            linecolor: '#eee',
            showgrid: false,
            automargin: true,
          },
          yaxis: { griddash: 'dash', automargin: true },
        }}
      />
      {sum === 0 && <p className={styles.noData}>{t('dashboard.no-data')}</p>}
    </>
  );
}
