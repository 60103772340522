export enum Tab {
  ProductionProcess = 'production-process',
  Installation = 'installation',
  Operator = 'operator',
  // Verifier = 'installation-verifier',
}

export type FormProps = {
  onSaved?: () => void;
};
