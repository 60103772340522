import { createTheme } from '@mui/material';
import theme from '../theme';
import themeColors from '../utils/theme';

/* eslint-disable import/prefer-default-export */
const ghg = createTheme({
  palette: {
    primary: {
      main: themeColors.greenBrand,
      contrastText: themeColors.pureWhite,
    },
    secondary: {
      main: themeColors.pureWhite,
      contrastText: themeColors.greenBrand,
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(2px) sepia(5%)',
          '& .MuiPaper-root': {
            borderRadius: '16px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          boxShadow: 'none',
          borderRadius: 28,
          fontSize: 18,
          height: 56,
          padding: '0 24px',
          textTransform: 'none',
          ':hover': {
            boxShadow: 'none',
            backgroundColor: themeColors.greenHover,
          },
          ':active': { backgroundColor: themeColors.greenPressed },
        },
        textSecondary: {
          color: '#38414F',
          border: '1px solid #D9D9D9',
          ':hover': {
            color: themeColors.pureWhite,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            '&.Black': {
              borderColor: 'black',
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '28px',
          letterSpacing: '0.54px',
        },
      },
    },
  },
});

export const ghgTheme = createTheme({}, theme, ghg);
