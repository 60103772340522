import React from 'react';
import { styled } from 'styled-components';

const Text = styled.p`
  color: var(--main-dark-main, #38414f);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.64px;
`;
const Subtitle = styled.p`
  color: var(--main-dark-main, #38414f);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.64px;
`;

interface Props {
  text: string;
}

const FormattedSurvey: React.FC<Props> = ({ text }) => {
  const formatText = (text: string) => {
    const lines = text.split('\n\n'); // Split by double newlines for paragraphs
    if (lines.length === 0) return null;

    return (
      <>
        <Subtitle>{lines[0]}</Subtitle>
        {lines.slice(1).map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </>
    );
  };

  return <div>{formatText(text)}</div>;
};

export default FormattedSurvey;
