/* eslint-disable no-unused-vars */

import { UploadedFileSchema } from 'src/Ghg/CodeOfConductSurvey/types';

export enum AggregatedGoodsCategory {
  CalcinedClay = 'Calcined clay',
  CementClinker = 'Cement clinker',
  Cement = 'Cement',
  AluminousCement = 'Aluminous cement',
  Electricity = 'Electricity',
  NitricAcid = 'Nitric acid',
  Urea = 'Urea',
  Ammonia = 'Ammonia',
  MixedFertilisers = 'Mixed fertilisers',
  SinteredOre = 'Sintered Ore',
  PigIron = 'Pig Iron',
  FeMn = 'FeMn',
  FeCr = 'FeCr',
  FeNi = 'FeNi',
  DRI = 'DRI',
  CrudeSteel = 'Crude steel',
  IronOrSteelProducts = 'Iron or steel products',
  UnwroughtAluminium = 'Unwrought aluminium',
  AluminiumProducts = 'Aluminium products',
  Hydrogen = 'Hydrogen',
}
export interface CbamInstallationInSchema {
  identifier: string;
  name: string;
  name_en: string;
  economic_activity: string;

  country: string;
  sub_division: string;
  city: string;
  street_name: string;
  street_name_additional_line: string;
  street_number: string;
  post_code: string;
  po_box: string;
  plot_parcel_number: string;

  UN_LOCODE: string;
  emission_source_latitude: number | null;
  emission_source_longitude: number | null;

  operator_id: number | null;
}

export interface CbamInstallationOutSchema extends CbamInstallationInSchema {
  id: number;
  country: string;
  created_at: Date;
}

export interface CbamInstallationOperatorInSchema {
  identifier: string;
  name: string;
  name_en: string;

  country: string;
  sub_division: string;
  city: string;
  street_name: string;
  street_name_additional_line: string;
  street_number: string;
  post_code: string;
  po_box: string;

  contact_name: string;
  contact_email: string;
  contact_phone_number: string;
}

export interface CbamInstallationOperatorOutSchema
  extends CbamInstallationOperatorInSchema {
  id: number;
  country: string;
}

export interface CbamProductionProcessSurveyInSchema {
  is_active: boolean;
}

export interface CbamProductionProcessSurveyOutSchema
  extends CbamProductionProcessSurveyInSchema {
  token: string | null;
}

export interface WasteGass {
  id: number;
  name: string;
  volume_of_waste_gas_m3: number;
  net_calorific_value_tco2tj: number;
}

export interface ProcessEmissionsStream {
  id: number;
  name: string;
  activity_data: number;
  emission_factor_tco2_tonne: number;
}
export interface CombustionEmissionsStream extends ProcessEmissionsStream {
  activity_data_unit: string;
  emission_factor_source: EmissionFactorSource;
  fuel_type?: FuelType;
  oxidation_factor: number;
}

export enum EmissionFactorSource {
  CBAM = 'cbam',
  IPCC = 'ipcc',
  EVIDENCE_BASED = 'evidence_based',
}

export enum FuelType {
  CRUDE_OIL = 'Crude oil',
  ORIMULSION = 'Orimulsion',
  NATURAL_GAS_LIQUIDS = 'Natural gas liquids',
  MOTOR_GASOLINE = 'Motor gasoline',
  KEROSENE = 'Kerosene (other than jet kerosene)',
  SHALE_OIL = 'Shale oil',
  GAS_DIESEL_OIL = 'Gas/Diesel oil',
  RESIDUAL_FUEL_OIL = 'Residual fuel oil',
  LIQUEFIED_PETROLEUM_GASES = 'Liquefied petroleum gases',
  ETHANE = 'Ethane',
  NAPHTHA = 'Naphtha',
  BITUMEN = 'Bitumen',
  LUBRICANTS = 'Lubricants',
  PETROLEUM_COKE = 'Petroleum coke',
  REFINERY_FEEDSTOCKS = 'Refinery feedstocks',
  REFINERY_GAS = 'Refinery gas',
  PARAFFIN_WAXES = 'Paraffin waxes',
  WHITE_SPIRIT_AND_SBP = 'White spirit and SBP',
  OTHER_PETROLEUM_PRODUCTS = 'Other petroleum products',
  ANTHRACITE = 'Anthracite',
  COKING_COAL = 'Coking coal',
  OTHER_BITUMINOUS_COAL = 'Other bituminous coal',
  SUB_BITUMINOUS_COAL = 'Sub-bituminous coal',
  LIGNITE = 'Lignite',
  OIL_SHALE_AND_TAR_SANDS = 'Oil shale and tar sands',
  PATENT_FUEL = 'Patent fuel',
  COKE_OVEN_COKE_AND_LIGNITE_COKE = 'Coke oven coke and lignite coke',
  GAS_COKE = 'Gas coke',
  COAL_TAR = 'Coal tar',
  GAS_WORKS_GAS = 'Gas works gas',
  COKE_OVEN_GAS = 'Coke oven gas',
  BLAST_FURNACE_GAS = 'Blast furnace gas',
  OXYGEN_STEEL_FURNACE_GAS = 'Oxygen steel furnace gas',
  NATURAL_GAS = 'Natural gas',
  INDUSTRIAL_WASTES = 'Industrial wastes',
  WASTE_OILS = 'Waste oils',
  PEAT = 'Peat',
  WASTE_TYRES = 'Waste tyres',
  CARBON_MONOXIDE = 'Carbon monoxide',
  METHANE = 'Methane',
}

export interface CbamMeasurementBasedInSchema {
  production_process_id: number;
  name: string;
  co2_emissions: number | null;
  n2o_emissions: number | null;
}

export interface CbamMeasurementBasedOutSchema
  extends CbamMeasurementBasedInSchema {
  id: number;
}
export interface CbamMassBalanceInSchema {
  name: string;
  production_process_id: number;
  activity_data: number | null;
  emission_factor_source: EmissionFactorSource;
  fuel_type: FuelType | null;
  emission_factor: number | null;
  carbon_content: number | null;
}
export interface CbamMassBalanceOutSchema extends CbamMassBalanceInSchema {
  id: number;
}
export interface ProductionProcessGeneralInformation {
  name: string;
  installation_id: number;
  quantity: number;
  cn_code: string;
  aggregated_goods_category: AggregatedGoodsCategory | null;
  supporting_documents: (number | null)[];
  remarks: string;
}

export interface CbamProductionProcessInSchema
  extends ProductionProcessGeneralInformation {}

export interface CbamWasteGasInSchema {
  production_process_id: number;
  name: string;
  volume_of_waste_gas_m3: number | null;
  net_calorific_value_tco2tj: number | null;
}

export interface CbamWasteGasOutSchema extends CbamWasteGasInSchema {
  id: number;
}

export interface CbamElectricitySchema {
  production_process_id: number;
  electricity_unit: ElectricityUnit;
  electricity_consumption: number | null;
  electricity_production: number | null;
  factor_source: ElectricityFactorSource | null;
  emission_factor: number | null;
  other_source_indication: string | null;
  factor_source_details: string | null;
  source: ElectricitySource | null;
}

export enum ElectricityUnit {
  MWh = 'MWh',
  TJ = 'TJ',
}

export enum ElectricityFactorSource {
  IEA_DATA = 'IEA-data',
  OTHER = 'Other',
}

export enum ElectricitySource {
  SOE01 = 'SOE01',
  SOE02 = 'SOE02',
  SOE03 = 'SOE03',
}

export enum HeatSourceOfUtilizedHeat {
  NO_HEAT = 'no-heat',
  ALL_HEAT = 'all-heat',
  C21 = 'c21',
  C22 = 'c22',
  C23 = 'c23',
}

export enum HeatExportMeasurableHeat {
  NO_HEAT_IS_EXPORTED = 'no-heat-is-exported',
  HEAT_IS_EXPORTED = 'heat-is-exported',
}

export enum HeatFactorSource {
  C21_C22 = 'c21/c22',
  REGION_DEFAULT = 'region-default',
}

export interface CbamHeatSchema {
  production_process_id: number;
  source_of_utilized_heat: HeatSourceOfUtilizedHeat | null;
  export_measurable_heat: HeatExportMeasurableHeat | null;
  imported_heat: number | null;
  exported_heat: number | null;
  heat_factor_source: HeatFactorSource;
  heat_emission_factor: number | null;
}

export interface CbamCo2TransferSchema {
  production_process_id: number;
  amount_of_stored_inherent_co2_tonne: number | null;
  amount_of_used_inherent_co2_tonne: number | null;
  amount_of_emitted_inherent_co2_tonne: number | null;
  monitoring_methodology_documentation_for_co2_transfer: string | null;
}

export interface CbamDirecrtEmissionsSchema {
  production_process_id: number;
  monitoring_methodology_documentation: string | null;
}

export interface SectorSpecificSchema {
  calcined_clay: string | null;
  cement_mass_ratio: number | null;
  urea_mass_contained: number | null;
  nitric_acid_concentration: number | null;
  ammonia_concentration: number | null;
  mixed_fertilisers_content_of_n_as_ammonium: number | null;
  mixed_fertilisers_content_of_n_as_nitrate: number | null;
  mixed_fertilisers_content_of_n_as_urea: number | null;
  mixed_fertilisers_content_of_n_in_other_forms: number | null;
  pig_iron_main_reducing_agent: string | null;
  pig_iron_mass_of_alloy_elements: number | null;
  pig_iron_production_route: string | null;
  femn_mass_of_mn_and_carbon: number | null;
  fecr_mass_of_cr_and_carbon: number | null;
  feni_mass_of_ni_and_carbon: number | null;
  dri_main_reducing_agent: string | null;
  dri_mass_of_alloy_elements: number | null;
  crude_steel_main_reducing_agent: string | null;
  crude_steel_mass_of_alloy_elements: number | null;
  crude_steel_tonnes_of_scrap: number | null;
  crude_steel_pct_of_scrap_that_is_preconsumer_scrap: number | null;
  crude_steel_production_route: string | null;
  iron_or_steel_products_main_reducing_agent: string | null;
  iron_or_steel_products_mass_of_alloy_elements: number | null;
  iron_or_steel_products_mass_of_non_iron_steel_materials: number | null;
  iron_or_steel_products_tonnes_of_scrap: number | null;
  iron_or_steel_products_pct_of_pre_consumer_scrap: number | null;
  unwrought_aluminium_tonnes_of_scrap: number | null;
  unwrought_aluminium_pct_of_scrap_that_is_pre_consumer_scrap: number | null;
  unwrought_aluminium_total_pct_of_other_elements: number | null;
  unwrought_aluminium_production_route: string | null;
  aluminium_products_tonnes_of_scrap: number | null;
  aluminium_products_pct_of_scrap_that_is_pre_consumer_scrap: number | null;
  aluminium_products_total_pct_of_other_elements: number | null;
  hydrogen_production_route: string | null;
  amonia_production_route: string | null;
  tonnes_of_scrap: number | null;
  pct_of_pre_consumer_scrap: number | null;
  total_pct_of_other_elements: number | null;
  electricity_fulfillment_of_conditionality: string | null;
}

export interface CbamProductionProcessOutSchema
  extends CbamProductionProcessInSchema {
  id: number;
  survey: CbamProductionProcessSurveyOutSchema;
  created_at: Date;
}

export interface CbamInstallationVerifierSchema {
  company_name: string;
  street: string;
  city: string;
  post_code: string;
  country: string;

  representative_name: string;
  representative_email: string;
  representative_phone_number: string;
  representative_fax: string;

  accreditation_country: string;
  accreditation_body_name: string;
  accreditation_number: string;
}
export interface CbamImportedGoodInSchema {
  name: string;

  amount: number | null;
  import_area: AreaOfImport | null;
  requested_procedure: CustomsRequestedProcedure | null;
  previous_procedure: CustomsPreviousProcedure | null;

  importer_id: number | null;
  representative_id: number | null;
  production_process_id: number | null;
  report_id: number;
  supporting_documents: (number | null)[];
  remarks: string;
}
export interface CbamImportedGoodOutSchema extends CbamImportedGoodInSchema {
  id: number;
  created_at: Date;

  specific_emissions: number;
  specific_attributed_direct_emissions: number;
  specific_attributed_indirect_emissions: number;
  benchmark_specific_emissions: number;
  specific_electricity_consumption: number;
  total_emissions: number;
}

export interface CbamProductionProcessSurveyInfoSchema
  extends CbamProductionProcessSurveyOutSchema {
  organization_name: string;
}

export type FuelTypesOutSchema = {
  [key: string]: {
    ncv: number;
    factor: number;
  };
};

export interface CbamActorInSchema {
  identifier: string;
  name: string;
  country: string;
  sub_division: string;
  city: string;
  street_name: string;
  street_name_additional_line: string;
  street_number: string;
  post_code: string;
  po_box: string;
}

export interface CbamActorOutSchema extends CbamActorInSchema {
  id: number;
  country: string;
  created_at: Date;
}

export enum DirectCombustionActivityUnit {
  TJ = 'tj',
  TONNE = 'tonne',
  M3 = 'm3',
}

export interface CbamDirectCombustionEmissionInSchema {
  production_process_id: number;
  name: string;
  activity_data_unit: DirectCombustionActivityUnit;
  activity_data: number | null;
  emission_factor_source: EmissionFactorSource;
  fuel_type: FuelType | null;
  emission_factor_tco2_tonne: number | null;
  oxidation_factor: number | null;
}

export interface CbamDirectCombustionEmissionOutSchema
  extends CbamDirectCombustionEmissionInSchema {
  id: number;
}

export interface CbamDirectProcessEmissionInSchema {
  production_process_id: number;
  name: string;
  activity_data: number | null;
  emission_factor_tco2_tonne: number | null;
}

export interface CbamDirectProcessEmissionOutSchema
  extends CbamDirectProcessEmissionInSchema {
  id: number;
}

export interface CbamPrecursorInSchema {
  name: string;
  precursor_id: number | null;
  production_process_id: number;
  amount: number | null;
}

export interface CbamPrecursorOutSchema extends CbamPrecursorInSchema {
  id: number;
}

export interface CbamReportInSchema {
  name: string;
  remarks: string;
  period: string;
  declarant_id?: number;
  signature_date: string | null;
  signature_place: string;
  signature_name: string;
  signature_position: string;
  signature_global_data_confirmation: boolean;
  signature_use_of_data_confirmation: boolean;
  signature_other_applicable_methodology_confirmation: boolean;
}

export interface CbamReportOutSchema extends CbamReportInSchema {
  id: number;
  created_at: Date;
}

interface CbamSupportingDocumentInSchema {
  reference_number: string;
  country: string | null;
  line_item_number: number | null;
  issuing_authority_name: string;
  validity_start_date: Date | null;
  validity_end_date: Date | null;
  description: string;
  attachment: UploadedFileSchema | null;
}

export enum SupportingDocumentForImportedGoodType {
  _01 = '01',
  _02 = '02',
  _03 = '03',
  _04 = '04',
}

export enum SupportingDocumentForProductionProcessType {
  TED01 = 'TED01',
  TED02 = 'TED02',
  TED03 = 'TED03',
  TED04 = 'TED04',
  TED05 = 'TED05',
}

export interface CbamSupportingDocumentForImportedGoodInSchema
  extends CbamSupportingDocumentInSchema {
  type: SupportingDocumentForImportedGoodType | null;
}

export interface CbamSupportingDocumentForImportedGoodOutSchema
  extends CbamSupportingDocumentForImportedGoodInSchema {
  id: number;
  created_at: Date;
}

export interface CbamSupportingDocumentForProductionProcessInSchema
  extends CbamSupportingDocumentInSchema {
  type: SupportingDocumentForProductionProcessType | null;
}

export interface CbamSupportingDocumentForProductionProcessOutSchema
  extends CbamSupportingDocumentForProductionProcessInSchema {
  id: number;
  created_at: Date;
}

export enum SupportingDocumentUsage {
  ForImportedGood,
  ForProductionProcess,
}

export enum AreaOfImport {
  EU = 'EU',
  EUOTH = 'EUOTH',
}

export enum CustomsRequestedProcedure {
  _01 = '01',
  _07 = '07',
  _40 = '40',
  _42 = '42',
  _43 = '43',
  _44 = '44',
  _45 = '45',
  _46 = '46',
  _48 = '48',
}

export enum CustomsPreviousProcedure {
  _01 = '01',
  _40 = '40',
  _42 = '42',
  _45 = '45',
  // Inward processing is currently not supported
  // _46 = '46',
  // _48 = '48',
}

export interface CbamReportCreateInSchema {
  name: string;
  period: string;
}
