import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { LcaDiagramSchema } from './types';
import { ProductImpactOutSchema } from 'src/Lca/LCADiagram/Diagram/index.types';
import { useAppSelector } from 'src/redux-file/hooks';

// eslint-disable-next-line import/prefer-default-export
export const useLcaDiagram = (productId?: number) => {
  const query = useQuery(
    ['lca-diagram', { productId }],
    () =>
      client
        .get<LcaDiagramSchema>(`/web/lca/flowchart/${productId}`)
        .then((response) => response.data),
    { enabled: !!productId }
  );
  const stagesAsOptions = (query.data?.stages || []).map(({ key, name }) => ({
    value: key,
    label: name,
  }));

  return { stagesAsOptions, ...query };
};

export const useProductImpact = (
  productId: number,
  areaId: number | undefined
) => {
  const url = `/web/lca/products/${productId}/impact/${areaId}`;
  const query = useQuery(
    ['lca-diagram', 'calculations', { productId, areaId }],
    () =>
      client.get<ProductImpactOutSchema>(url).then((response) => response.data),
    { enabled: !!areaId }
  );
  return query;
};

export const useProductImpacts = (productId: number) => {
  const url = `/web/lca/products/${productId}/impact`;
  const query = useQuery(['lca-diagram', 'calculations', { productId }], () =>
    client.get<ProductImpactOutSchema[]>(url).then((response) => response.data)
  );
  return query;
};

export const useEcoinventProLicenceValid = () => {
  const isEcoinventLicenceValid = useAppSelector(
    (state) => state.platform.user?.ecoinventProLicenceIsValid
  );
  return { isEcoinventLicenceValid };
};
