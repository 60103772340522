import React from 'react';

import {
  StyledColumn,
  StyledColumnWrapper,
  StyledColumnHeader,
  StyledColumnTitle,
  StyledCalculation,
} from './index.styles';
import { Column as ColumnProps } from './index.types';
import Node from '../Node';
import { useProductImpact } from 'src/Lca/hooks';
import { useAppSelector } from 'src/redux-file/hooks';
import EcoinventEmission from 'src/Lca/components/EcoinventEmission';

export default function Column({
  header,
  productId,
  columns,
  stage,
}: ColumnProps) {
  const { name, children: nodes } = stage;
  const { selectedAreaOfImpact } = useAppSelector((state) => state.lcaDiagram);
  const { data } = useProductImpact(productId, selectedAreaOfImpact?.id);
  const result = data?.emission_per_stage[stage.key];

  return (
    <StyledColumnWrapper columns={columns}>
      <StyledColumnHeader>{header}</StyledColumnHeader>
      <StyledColumn>
        <StyledColumnTitle>{name}</StyledColumnTitle>
        {nodes?.map((node) => <Node key={node.id} node={node} />)}
      </StyledColumn>
      <StyledCalculation>
        <EcoinventEmission
          sx={{ fontWeight: '600', mb: '5px' }}
          value={result}
        />
        {data?.area.unit_name || ''}
      </StyledCalculation>
    </StyledColumnWrapper>
  );
}
