import React from 'react';
import ESGSummary from '../../../components/ESGSummary';
import { useEsgOrganization } from 'src/common/hooks';
import { useParams } from 'react-router-dom';

export default function SummaryPage() {
  const { reportId } = useParams();
  const organization = useEsgOrganization();

  return (
    <ESGSummary organizationId={organization.id} reportId={String(reportId)} />
  );
}
