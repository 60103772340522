import React from 'react';
import { LayoutProps } from './index.interface';
import styles from './styles.module.css';
import Footer from '../Footer';
import { Box } from '@mui/material';
import { useSidebar } from '../Sidebar/hooks';
import { Outlet } from 'react-router-dom';

export default function Layout({
  children,
  sidebar,
  topbar,
  noFooter,
  InnerContentProps,
}: LayoutProps) {
  const sidebarState = useSidebar();
  const sidebarProvided = !!sidebar;

  React.useEffect(() => {
    // if sidebar is provided, show it by default
    sidebarState.update({ isShown: sidebarProvided });
    // update sidebar state on unmount
    return () => {
      sidebarState.update({ isShown: false });
    };
  }, [sidebarProvided]);

  return (
    <Box display="flex" className={styles.app}>
      <Box>{sidebarState.isShown && sidebar}</Box>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        component="main"
        sx={{ overflowX: 'auto' }}
      >
        {topbar}
        <Box display="flex" flexDirection="column" flexGrow={1} pt={3}>
          <Box
            {...InnerContentProps}
            display="flex"
            flexDirection="column"
            flexGrow={1}
            px={3}
          >
            {children}
          </Box>
          <Box>{!noFooter && <Footer />}</Box>
        </Box>
      </Box>
    </Box>
  );
}

export function RouteLayout(props: LayoutProps) {
  return (
    <Layout {...props}>
      {props.children}
      <Outlet />
    </Layout>
  );
}
