import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAddPermissionButton = styled('div')`
  color: var(--Placeholder, #6e6e6e);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 257.143% */
  cursor: pointer;
`;

export const StyledActionCell = styled(TableCell)`
  text-align: center;
  padding: 0px;
  padding-left: 8px;
  width: 64px;
  max-width: 64px;
  min-width: 64px;
`;

export const StyledSelectCell = styled(TableCell)`
  width: 230px;
  max-width: 230px;
  min-width: 230px;
  padding: 4px;
`;
