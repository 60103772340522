import * as React from 'react';
import { DrawerProps } from '@mui/material/Drawer';
import { Drawer } from './index.styled';
import { useSidebar } from './hooks';

type SidebarProps = {
  DrawerProps?: Omit<DrawerProps, 'open'>;
  children: React.ReactNode;
  isOpen?: boolean; // specify to override the global state
};

// https://mui.com/material-ui/react-drawer/
export default function Sidebar(props: SidebarProps) {
  const { isOpen, update } = useSidebar();

  React.useEffect(() => {
    // override the state
    if (props.isOpen !== undefined)
      update({ isOpen: props.isOpen, isControllable: false });
    // restore user control after unmount
    return () => {
      update({ isControllable: true });
    };
  }, [props.isOpen]);

  return (
    <Drawer variant="permanent" open={isOpen} {...props.DrawerProps}>
      {props.children}
    </Drawer>
  );
}
