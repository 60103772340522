import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

const Box = styled.div`
  height: 100%;
  flex-grow: 1;
  padding: 0 40px 40px;
`;

export default function LcaLayout({ children }: Props) {
  return <Box>{children}</Box>;
}
