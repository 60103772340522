import { ReportingFreq } from 'src/utils/api.interfaces';
import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';

export interface RegistrationUBNPSchema {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  organization_name: string;
  vat_identifier: string;
  regon: string;
  pkd_code: string | null;
  country: string | null;
  password: string;
  reporting_frequency: ReportingFreq;
  bnp_analytics: boolean;
  bnp_representation: boolean;
}

export type FormErrors = SchemaErrors<RegistrationUBNPSchema>;

const firstPageFieldsRequired: (keyof RegistrationUBNPSchema)[] = [
  'first_name',
  'last_name',
  'email',
  'phone_number',
  'organization_name',
  'vat_identifier',
  'regon',
  'pkd_code',
  'country',
  'bnp_representation',
];

export const firstPageFields: (keyof RegistrationUBNPSchema)[] = [
  ...firstPageFieldsRequired,
  'bnp_analytics',
];

const applyFirstPageValidation = (
  data: RegistrationUBNPSchema,
  builder: ErrorBuilder<RegistrationUBNPSchema>
) => {
  const digitsOnlyRegex = /^\d+$/;

  builder
    .checkEmail(['email'], 'registration_bnp.emailError')
    .checkTruthy(firstPageFieldsRequired, 'validation.required')
    .checkRule(
      ['regon'],
      (value) =>
        (value.length === 9 || value.length === 14) &&
        digitsOnlyRegex.test(value),
      'registration_bnp.regonError'
    )
    .checkRule(
      ['vat_identifier'],
      (value) => value.length === 10 && digitsOnlyRegex.test(value),
      'registration_bnp.vatIdentifierError'
    );
};

const applySecondPageValidation = (
  data: RegistrationUBNPSchema,
  builder: ErrorBuilder<RegistrationUBNPSchema>
) => {
  builder.checkTruthy(
    ['password', 'reporting_frequency'],
    'validation.required'
  );
};

export const validateForm = (data: RegistrationUBNPSchema): FormErrors => {
  const builder = new ErrorBuilder(data);
  applyFirstPageValidation(data, builder);
  applySecondPageValidation(data, builder);
  return builder.build();
};

export const validateFormFirstPage = (
  data: RegistrationUBNPSchema
): FormErrors => {
  const builder = new ErrorBuilder(data);
  applyFirstPageValidation(data, builder);
  return builder.build();
};
