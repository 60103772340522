import React from 'react';

import { Text } from '../styles';
import { hasValue } from 'src/views/components/QuestionnaireV3/Summary/SummaryTable/utils';

export default function SingleDescription({
  label,
  value,
}: {
  label: string;
  value: string | string[];
}) {
  const _value = Array.isArray(value) ? value.join(', ') : value;
  return (
    <div style={{ margin: '5px 0px' }}>
      <li>
        <Text sx={{ width: 1 }}>{label}</Text>
      </li>
      <span style={{ fontWeight: '400' }}>
        {hasValue(_value) ? _value : '-'}
      </span>
    </div>
  );
}
