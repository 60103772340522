import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { appConfig } from '../config';
import { t } from 'i18next';

// translation keys in `httpErrors`
const httpErrorMessages = {
  403: 'forbidden',
  404: 'notFound',
  409: 'conflict',
  500: 'internalServerError',
};

export const handleToastError = async (error: any) => {
  if ([400, 422].includes(error?.response?.status)) {
    const isJsonBlob =
      error?.response?.data instanceof Blob &&
      error?.response?.data.type === 'application/json';
    const data = isJsonBlob
      ? JSON.parse(await error.response.data.text())
      : error?.response?.data;
    const detail = data?.detail;
    if (typeof detail === 'string') {
      toast.error(detail);
      return;
    }

    for (const { msg } of data?.errors || []) toast.error(String(msg));
  }
  const errorMessage = (httpErrorMessages as any)[error?.response?.status];
  if (errorMessage) toast.error(t(`httpErrors.${errorMessage}`));
};

const buildClient = (baseUrl: string): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: baseUrl,
    validateStatus: (status) => status >= 200 && status < 300,
    withCredentials: false,
    paramsSerializer: {
      indexes: null,
    },
  };
  const client = axios.create(config);
  return client;
};

// Axios client that performs authenticated requests
export const client = buildClient(appConfig.backendApiUrl);

// Axios client that performs anonymous requests without auth
export const anonymousClient = buildClient(appConfig.backendApiUrl);
