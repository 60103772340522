import { createTheme } from '@mui/material';
import theme from '../theme';
import themeColors from '../utils/theme';

import React, { CSSProperties } from 'react';

export const h1: CSSProperties = {
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '38px',
  letterSpacing: '0.64px',
};

export const h2: CSSProperties = {
  fontSize: '28px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '36px',
  letterSpacing: '0.56px',
  textAlign: 'left',
};

export const h3: CSSProperties = {
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '30px',
  letterSpacing: '0.48px',
};

export const h4: CSSProperties = {
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px',
  letterSpacing: '0.4px',
};

export const h5: CSSProperties = {
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '28px',
  letterSpacing: '0.36px',
};

export const h6: CSSProperties = {
  fontSize: '15px',
  fontWeight: '600',
  lineHeight: '23px',
  letterSpacing: '0.02em',
};

export const subtitle1: CSSProperties = {
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28px',
  letterSpacing: '0.36px',
};

export const subtitle2: CSSProperties = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: '0.28px',
};

export const body1: CSSProperties = {
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '26px',
  letterSpacing: '0.02em',
};

export const body2: CSSProperties = {
  fontSize: '15px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.3px',
};

export const largeButtonText: CSSProperties = {
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '22px',
  letterSpacing: '0.32px',
};

export const mediumButtonText: CSSProperties = {
  fontSize: '15px',
  fontWeight: '700',
  lineHeight: '20px',
  letterSpacing: '0.3px',
};

export const smallButtonText: CSSProperties = {
  fontSize: '13px',
  fontWeight: '700',
  lineHeight: '18px',
  letterSpacing: '0.26px',
};

export const captionText: CSSProperties = {
  fontSize: '13px',
  fontWeight: '400',
  lineHeight: '19px',
  letterSpacing: '0.02em',
};

export const overlineText: CSSProperties = {
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '15px',
  letterSpacing: '0.36px',
  textTransform: 'uppercase',
  color: '#606462 !important',
};

const fonts = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  subtitle1,
  subtitle2,
  body1,
  body2,
  largeButtonText,
  mediumButtonText,
  smallButtonText,
  captionText,
  overlineText,
};

/* eslint-disable import/prefer-default-export */
const cbam = createTheme({
  typography: {
    fontFamily: 'Poppins',
    ...fonts,
  },
  palette: {
    primary: {
      main: themeColors.pureWhite,
      contrastText: '#2E3342',
      light: themeColors.brightVioletLight,
    },
    secondary: {
      main: themeColors.brightViolet,
      contrastText: '#2E3342',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'secondary',
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: '28px',
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          border: '1px solid #e0e0e0',
          '&:hover': {
            backgroundColor: '#e0e0e0',
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSelect: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: { color: 'secondary' },
    },
    MuiCheckbox: {
      defaultProps: { color: 'secondary' },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: '8px',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          '& .MuiCheckbox-root': {
            paddingTop: '0px',
          },
        },
      },
    },
  },
});

export default cbam;

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    h1?: React.CSSProperties;
    h2?: React.CSSProperties;
    h3?: React.CSSProperties;
    h4?: React.CSSProperties;
    h5?: React.CSSProperties;
    h6?: React.CSSProperties;
    subtitle1?: React.CSSProperties;
    subtitle2?: React.CSSProperties;
    body1?: React.CSSProperties;
    body2?: React.CSSProperties;
    largeButtonText?: React.CSSProperties;
    mediumButtonText?: React.CSSProperties;
    smallButtonText?: React.CSSProperties;
    captionText?: React.CSSProperties;
    overlineText?: React.CSSProperties;
  }
  interface Typography {
    h1: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    h4: React.CSSProperties;
    h5: React.CSSProperties;
    h6: React.CSSProperties;
    subtitle1: React.CSSProperties;
    subtitle2: React.CSSProperties;
    body1: React.CSSProperties;
    body2: React.CSSProperties;
    largeButtonText: React.CSSProperties;
    mediumButtonText: React.CSSProperties;
    smallButtonText: React.CSSProperties;
    captionText: React.CSSProperties;
    overlineText: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    h5: true;
    h6: true;
    subtitle1: true;
    subtitle2: true;
    body1: true;
    body2: true;
    largeButtonText: true;
    mediumButtonText: true;
    smallButtonText: true;
    captionText: true;
    overlineText: true;
  }
}
