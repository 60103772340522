import React from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { FieldAutocompleteProps } from './index.interfaces';
import { StyledAutocomplete, ValueField } from './index.styles';
import {
  ValueLabel,
  ValueParagraph,
} from '../../../../../components/TextFieldNumber/index.styles';

export default function NewFieldAutocomplete({
  label,
  options,
  value,
  setValue,
  error,
  active,
  index,
  max,
}: FieldAutocompleteProps) {
  const { t } = useTranslation();
  const newOptions = options.map((item) => ({
    value: item[0],
    verbose: item[1],
  }));
  return (
    <>
      {active && (
        <StyledAutocomplete
          size="medium"
          options={newOptions}
          getOptionLabel={(option: any) => option.verbose || ''}
          onChange={(_, newValue) => setValue(newValue.value)}
          value={
            value !== ''
              ? newOptions[
                  newOptions.findIndex((option: any) => option.value === value)
                ]
              : ''
          }
          disableClearable
          disablePortal
          renderInput={(params) => (
            <TextField {...params} label={t(label)} error={error} />
          )}
        />
      )}
      {!active && value !== '' && (
        <ValueField className="value-field" index={index} max={max}>
          <ValueLabel>{t(label)}</ValueLabel>
          <ValueParagraph>
            {newOptions[
              newOptions.findIndex((option: any) => option.value === value)
            ]
              ? newOptions[
                  newOptions.findIndex((option: any) => option.value === value)
                ].verbose
              : ''}
          </ValueParagraph>
        </ValueField>
      )}
    </>
  );
}
