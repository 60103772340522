import React from 'react';
import styled from 'styled-components';
import themeColors from '../../../utils/theme';

export const Box = styled.div`
  margin-bottom: 28px;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  position: relative;
`;

export const StyledPrimaryText = styled.h2`
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  margin-bottom: 0;
  text-align: left;
  width: 75%;

  span {
    color: ${themeColors.redParagraphs};
  }
`;
export const StyledSecondaryText = styled.h2`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0;
  text-align: left;
  width: 75%;

  span {
    color: ${themeColors.redParagraphs};
  }
`;

export const Wrapper = styled(
  ({
    active,
    ...props
  }: { active: boolean } & React.HTMLAttributes<HTMLDivElement>) => (
    <div {...props} />
  )
)`
  position: relative;
  z-index: ${({ active }) => (active ? '100' : '2')};
`;

export const Button = styled.button`
  background-color: ${themeColors.pureWhite};
  height: 40px;
  border: 1px solid ${themeColors.strokePressed};
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: ${themeColors.greenBrand};
  padding: 0 18px;
  white-space: nowrap;

  svg {
    margin-right: 10px;
    height: 14px;
    width: 14px;
  }

  &:hover {
    background-color: ${themeColors.hoverButton};
  }
`;

export const Modal = styled.div<{ aboutCompany: boolean }>`
  position: absolute;
  top: 0;
  right: 200px;
  background: ${themeColors.pureWhite};
  border: 1px solid ${themeColors.strokePressed};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  padding: 24px;
  width: ${({ aboutCompany }) => (aboutCompany ? '880' : '649')}px;
  color: ${themeColors.grayIcons};
  z-index: 1;
`;

export const Close = styled.button`
  position: absolute;
  right: 8px;
  top: 11px;
  background-color: transparent;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.02em;
  margin-bottom: 12px;
  color: ${themeColors.dark};
`;

export const DosTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: ${themeColors.dark};
  margin-top: 12px;
`;

export const DosWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > div {
    width: 50%;
  }
`;

export const Dos = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  color: #606462;
  margin-right: 15px;
  svg {
    margin-right: 10px;
    width: 16px;
    min-width: 16px;
    margin-top: 3px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
