import React from 'react';

import { IntroductionDataType } from './types';
import CoverPage from './CoverPage';
import HowWhyPage from './HowWhyPage';
import MethodologyPage from './MethodologyPage';
import ScopesVisualisationPage from './ScopesVisualisationPage';
import ScopeDescription from './ScopeDescription';

const introductionSection = (data: IntroductionDataType) => {
  return [
    <CoverPage data={data} />,
    <HowWhyPage data={data} />,
    <MethodologyPage data={data} />,
    <ScopesVisualisationPage data={data} />,
    <ScopeDescription data={data} />,
  ];
};

export default introductionSection;
