import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTextField } from './index.styles';

export interface Props {
  label: string;
  value: string | number | null | undefined;
  setValue: Function;
  int?: boolean;
  color: string;
  state: any;
  name: string;
}
export const convertValue = (item: string | number | null | undefined) => {
  if (typeof item === 'string') {
    return item.replace('.', ',');
  } else if (item === null || typeof item === 'undefined') {
    return '';
  } else {
    return item.toString().replace('.', ',');
  }
};

export default function NumberInput({
  label,
  value,
  setValue,
  int,
  state,
  color,
  name,
}: Props) {
  const { t } = useTranslation();
  // eslint-disable-next-line prefer-regex-literals
  const regex = new RegExp(/^[+-]?(?=.?\d)\d*((\.|,)\d{0,2})?$/);

  // eslint-disable-next-line prefer-regex-literals
  const int_regex = new RegExp('^[0-9]+$');

  return (
    <StyledTextField
      mainColor={color}
      InputProps={{
        inputProps: { min: 0 },
        inputMode: 'decimal',
      }}
      value={convertValue(value)}
      label={t(label)}
      onChange={(event) => {
        if (int) {
          if (int_regex.test(event.target.value) || event.target.value === '')
            setValue({ ...state, [name]: event.target.value });
        } else if (regex.test(event.target.value) || event.target.value === '')
          setValue({ ...state, [name]: event.target.value });
      }}
    />
  );
}
