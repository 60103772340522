import { AxiosResponse } from 'axios';
import { client } from '../utils/api-client';
import { EsgReportSchemaOut } from './api.types';

const prefix = '/web/esg_v2';

// exceptions should be handled by throwing adequate errors in the interceptors
//  (and maybe catching them using error boundaries?)
const wrap = <TResponse>(response: Promise<AxiosResponse<TResponse, any>>) =>
  response.then((r) => r.data);

// it would be nice if the API urls looked like this:
// export const getReport = (id: number) => client.get<EsgReportSchemaOut>(`${prefix}/reports/${id}`);
// export const getOrganizationReports = (organizationId: number) => client.get<EsgReportSchemaOut[]>(`${prefix}/organizations/${organizationId}/reports`);

type OrganizationIdParams = {
  organizationId: number;
};

type ReportIdParams = OrganizationIdParams & {
  reportId: number;
};
export const getReport = ({ organizationId, reportId }: ReportIdParams) =>
  wrap(
    client.get<EsgReportSchemaOut>(`${prefix}/${organizationId}/${reportId}`)
  );

export const getOrganizationReports = ({
  organizationId,
}: OrganizationIdParams) =>
  wrap(client.get<EsgReportSchemaOut[]>(`${prefix}/${organizationId}`));
