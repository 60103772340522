import React from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { SidebarNavItemBase } from '../NavItem';

export type SidebarExternalNavItemProps = {
  to: string;
  icon: React.ReactNode;
  textKey: string;
};

export default function SidebarExternalNavItem(
  props: SidebarExternalNavItemProps
) {
  const { t } = useTranslation();
  return (
    <SidebarNavItemBase onClick={() => window.open(props.to, '_blank')}>
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={t(props.textKey)} />
    </SidebarNavItemBase>
  );
}
