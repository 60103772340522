import React from 'react';

export const Arrow1 = () => (
  <svg
    style={{ margin: '-1px 100px 0px auto', display: 'block' }}
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 17L0.47372 0.5L19.5263 0.500002L10 17Z" fill="#E9BC46" />
  </svg>
);

export const Arrow2 = () => (
  <svg
    style={{ margin: '-1px auto 0px auto', display: 'block' }}
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 17L0.47372 0.5L19.5263 0.500002L10 17Z" fill="#E9BC46" />
  </svg>
);

export const CopyIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 13H16C14.9 13 14 13.9 14 15V29H16V15H28V13ZM31 17H20C18.9 17 18 17.9 18 19V33C18 34.1 18.9 35 20 35H31C32.1 35 33 34.1 33 33V19C33 17.9 32.1 17 31 17ZM31 33H20V19H31V33Z"
      fill="#6E6E6E"
    />
  </svg>
);

export const RemoveIcon = () => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
      fill="#6E6E6E"
    />
    <rect x="5" y="8" width="1" height="6" fill="#6E6E6E" />
    <rect x="8" y="8" width="1" height="6" fill="#6E6E6E" />
  </svg>
);
