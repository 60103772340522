import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import BasePage from '../Base';
import { MainMetricsDataType, Metric } from './types';
import { LOGO_MARGIN_LEFT, PDF_WIDTH } from '../../config';

const BOX_MARGIN = 10;
// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 240,
    left: LOGO_MARGIN_LEFT - BOX_MARGIN,
    width: PDF_WIDTH - 2 * LOGO_MARGIN_LEFT + 2 * BOX_MARGIN,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  box: {
    width: 420,
    minWidth: 420,
    height: 320,
    padding: 10,
    backgroundColor: '#eee',
    borderTop: '20px solid black',
    margin: BOX_MARGIN,
    marginTop: 30,
  },
  metricNameText: {
    position: 'absolute',
    top: 28,
    left: 36,
    width: 380,
    color: '#606462',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Poppins',
    fontSize: 26,
    fontStyle: 'normal',
  },
  metricValueText: {
    position: 'absolute',
    top: 140,
    left: 36,
    color: '#2F2F2F',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Poppins',
    fontSize: 64,
    fontStyle: 'bold',
    fontWeight: 700,
  },
  metricUnitText: {
    position: 'absolute',
    top: 230,
    left: 36,
    color: '#606462',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Poppins',
    fontSize: 30,
    fontStyle: 'normal',
  },
  helperText: {
    position: 'absolute',
    top: 1000,
    left: LOGO_MARGIN_LEFT,
    color: '#606462',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Poppins',
    fontSize: 20,
    fontStyle: 'normal',
  },
});

type MainMetricsPageProps = {
  data: {
    main_metrics: MainMetricsDataType;
  };
};

const Box = (props: Metric) => {
  const { name, value, color, unit } = props;
  const boxStyle = { ...styles.box, borderTop: `20px solid ${color}` };
  return (
    <View style={boxStyle}>
      <Text style={styles.metricNameText}>{name}</Text>
      <Text style={styles.metricValueText}>
        {value == null ? '- -' : String(value)}
      </Text>
      <Text style={styles.metricUnitText}> {unit}</Text>
    </View>
  );
};

const MainMetricsPage = ({ data }: MainMetricsPageProps) => {
  const {
    page_title: pageTitle,
    top_right: topRight,
    metrics,
    helper_text_1: helperText1,
    helper_text_2: helperText2,
  } = data.main_metrics;
  return (
    <BasePage
      backgroundImageName="Report_envirly_slide_8.png"
      mainHeader={pageTitle}
      topRight={topRight}
    >
      <View>
        <View style={styles.container}>
          {metrics.map((metric, idx) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Box key={`${metric.name}-${idx}`} {...metric} />
          ))}
        </View>
        <View style={styles.helperText}>
          <Text>{helperText1}</Text>
          <Text>{helperText2}</Text>
        </View>
      </View>
    </BasePage>
  );
};

export default MainMetricsPage;
