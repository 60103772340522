import React, { MouseEventHandler } from 'react';
import IconSvg from '../IconSvg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
  hoverText: string;
  icon: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const Box = styled.div`
  top: 0 !important;
  margin-left: 0 !important;
  position: relative;
  width: 48px !important;
  height: 48px !important;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  div {
    display: none;
  }

  &:hover {
    div {
      text-align: center;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      color: #606462;
      height: 32px;
      padding: 0 12px;
      position: absolute;
      width: auto;
      left: 50%;
      transform: translate(-50%);
      top: 56px;
      z-index: 2;
      min-width: 70px;
    }
  }
`;

export default function TableButton({ hoverText, icon, onClick }: Props) {
  const { t } = useTranslation();
  return (
    <Box>
      <button type="button" onClick={onClick}>
        <IconSvg name={icon} />
      </button>
      <div>{t(`sold-products.preview.options.${hoverText}`)}</div>
    </Box>
  );
}
