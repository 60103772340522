import React, { useMemo } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { FieldSelectProps } from './index.interfaces';
import { StyledFormControl, StyledSelect, ValueField } from './index.styles';
import {
  ValueLabel,
  ValueParagraph,
} from '../../../../../components/TextFieldNumber/index.styles';

export default function NewFieldSelect({
  label,
  options,
  value,
  setValue,
  error,
  active,
  index,
  max,
  sortAlphabetically = false,
}: FieldSelectProps) {
  const { t, i18n } = useTranslation();

  const newOptions = useMemo(() => {
    const mappedOptions = options.map((item) => {
      // prefix key with 'dropdowns.' if it isn't an interpolated key
      const key = item[1].startsWith('$') ? item[1] : `dropdowns.${item[1]}`;
      return {
        value: item[0],
        verbose: key,
      };
    });
    if (sortAlphabetically) {
      // Sort options alphabetically based on the translated verbose key
      return mappedOptions.sort((a, b) =>
        new Intl.Collator(i18n.language).compare(t(a.verbose), t(b.verbose))
      );
    } else {
      return mappedOptions;
    }
  }, [options, t, i18n.language]);

  return (
    <>
      {active && (
        <StyledFormControl value={value} fullWidth>
          <InputLabel error={error} id={`${label}-${index}`}>
            {t(label)}
          </InputLabel>
          <StyledSelect
            variant="outlined"
            labelId={`${label}-${index}`}
            value={value}
            label={t(label)}
            error={error}
            placeholder={value}
            onChange={(event) => setValue(event.target.value as string)}
            MenuProps={{ PaperProps: { style: { zIndex: 2 } } }}
          >
            {newOptions.map((el) => (
              <MenuItem key={el.value} value={el.value}>
                {t(el.verbose)}
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledFormControl>
      )}
      {!active && value !== '' && (
        <ValueField className="value-field" index={index} max={max}>
          <ValueLabel>{t(label)}</ValueLabel>
          <ValueParagraph>
            {t(
              newOptions.filter((option) => option.value === value)?.[0]
                ?.verbose ?? ''
            )}
          </ValueParagraph>
        </ValueField>
      )}
    </>
  );
}
