import React from 'react';
import Sidebar from '../../../views/components/Sidebar';
import Logo from '../../../images/svg/logo';
import LogoMin from '../../../images/svg/logoMin';
import { Box, Divider } from '@mui/material';
import HeaderWithLogo from '../../../views/components/Sidebar/HeaderWithLogo';
import CompanyFilter from '../../../views/components/CompanyFilter';
import { ApiDocsNavItem, HelpCenterNavItem } from 'src/common/sidebar';

export default function SuperdashboardSidebar() {
  return (
    <Sidebar isOpen>
      <HeaderWithLogo openLogo={<Logo />} closedLogo={<LogoMin />} />
      <CompanyFilter />
      <Box sx={{ position: 'fixed', left: 0, bottom: 5 }}>
        <Divider sx={{ width: '320px' }} />
        <HelpCenterNavItem />
        <ApiDocsNavItem />
        <Divider sx={{ width: '320px' }} />
      </Box>
    </Sidebar>
  );
}
