import React from 'react';
import LightTooltip from '../LightTooltip';
import { Typography } from '@mui/material';

interface Props {
  minLength: number;
  text: string;
}

export default function TextWithTooltip(props: Props) {
  // injects tooltip if text is longer than length and renders Typography noWrap
  // else renders Typography
  const { minLength, text } = props;
  const isTooltipNeeded = text.length > minLength;
  return !isTooltipNeeded ? (
    <Typography>{text}</Typography>
  ) : (
    <LightTooltip title={text}>
      <Typography noWrap>{text}</Typography>
    </LightTooltip>
  );
}
