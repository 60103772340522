import {
  OrganizationTreeNode,
  OrganizationTreeNodeSchema,
} from '../../../components/StructureTable/index.types';

export const getFlatData = (
  data: OrganizationTreeNodeSchema[],
  depth: number = 0
) => {
  // flattens data structure, injects depth and product boolean flags
  const flatData: OrganizationTreeNode[] = [];
  for (const node of data) {
    const children = node?.children || [];
    const nodeFlatData: OrganizationTreeNode[] = getFlatData(
      children,
      depth + 1
    );
    const nodelessNode: OrganizationTreeNode = {
      ...node,
      depth,
      nodes: children.map((n) => n.id),
      co2: node.active_products?.includes('co2'),
      esg: node.active_products?.includes('esg'),
      lca: node.active_products?.includes('lca'),
      unit_type: node.unit_type?.name || null,
    };
    flatData.push(...[nodelessNode, ...nodeFlatData]);
  }
  return flatData;
};
