import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import {
  StyledNumberField,
  StyledTextField,
} from '../../../styles';
import { useElectricity } from 'src/Cbam/hooks';
import {
  CbamElectricitySchema,
  ElectricityFactorSource,
  ElectricitySource,
  ElectricityUnit,
} from 'src/Cbam/types';
import RadioField from '../components/RadioField';
import { useChangeTracker } from '../components/CounterProvider';
import { useTranslation } from 'react-i18next';
import { FormErrors, requiredFields, validateForm } from './FormValidation';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import { toast } from 'react-toastify';
import InfoPanel from 'src/Cbam/components/InfoPanel';

interface Props {
  id: number;
}

const ELECTRICITY_FACTOR_SOURCES_OPTIONS = [
  {
    label: '(D.4.a) Region default, according to International Energy Agency',
    factor_source: ElectricityFactorSource.IEA_DATA,
    emission_factor: 0.2883, // TODO EN-1847: actual values based on installation country. 0.2883 is EU average value, values for countries are inside cbam portal
  },
  {
    label: '(D.4.b) Evidence based',
    factor_source: ElectricityFactorSource.OTHER,
    emission_factor: null,
  },
];

export default function Electricity(props: Props) {
  const { id } = props;
  const { isModified, setIsModified } = useChangeTracker();
  const electricity = useElectricity(Number(id));
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.productionProcess.electricity',
  });

  const initialForm = {
    production_process_id: id,
    electricity_unit: ElectricityUnit.MWh,
    electricity_consumption: null,
    electricity_production: null,
    factor_source: null,
    emission_factor: null,
    other_source_indication: null,
    factor_source_details: null,
    source: null,
  };

  const [form, setForm] = React.useState<CbamElectricitySchema>(initialForm);

  const [errors, setErrors] = React.useState<FormErrors>({});

  const handleFormChange = (key: string, value: any) => {
    setIsModified(true);
    const newForm = {
      ...form,
      [key]: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
  };

  React.useEffect(() => {
    if (electricity.data) setForm(electricity.data);
  }, [electricity.data]);

  const handleSaveClick = () =>
    electricity.update
      .mutateAsync(form)
      .then(() => {
        setIsModified(false);
      })
      .catch((err: any) => {
        toast.error('Failed to save');
        const extractedErrors = extractSchemaErrors(err, form);
        setErrors(extractedErrors);
      });

  const isError = (fieldName: keyof CbamElectricitySchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof CbamElectricitySchema) =>
    isError(fieldName) && mapError(errors, fieldName, form[fieldName] || '');

  return (
    <>
      <Typography variant="h2">Electricity</Typography>
      <br />
      <InfoPanel>
        <Typography>
          Indirect Emissions are emissions from the production of electricity,
          which is consumed during the Production Process, regardless of the
          location of the production of the consumed electricity.
          <br /> Note that if your Installation produces itself electricity, the
          fuels consumed in the electricity production count as Direct Emissions
          of the Installation. But electricity production is considered a
          separate Production Process, i.e. those Direct Emissions are not
          attributed to Direct Attributed Emissions of any goods produced in
          this Installation.
        </Typography>
      </InfoPanel>
      <br />
      <StyledPanel>
        {/* <StyledFlex sx={{ justifyContent: 'flex-start' }}>
          <Typography variant="h5">Select electricity unit:</Typography>
          <RadioField
            label={ElectricityUnit.TJ}
            checked={form.electricity_unit === ElectricityUnit.TJ}
            onClick={() => handleFormChange('electricity_unit', ElectricityUnit.TJ)}
          />

          <RadioField
            label={ElectricityUnit.MWh}
            checked={form.electricity_unit === ElectricityUnit.MWh}
            onClick={() => handleFormChange('electricity_unit', ElectricityUnit.MWh)}
          />
        </StyledFlex> */}
        <StyledNumberField
          label={`${t('form.electricity_consumption')} [${
            form.electricity_unit
          }]`}
          value={form.electricity_consumption}
          onChange={(e) =>
            handleFormChange('electricity_consumption', e.target.value)
          }
          required={requiredFields.includes('electricity_consumption')}
          error={isError('electricity_consumption')}
          helperText={errorText('electricity_consumption')}
        />
        <Box sx={{ mb: '6px' }}>
          <InfoPanel>
            <Typography>
              If excess heat or waste gasses are utilized in order to produce
              electricity, it can be provided in the field below.
            </Typography>
          </InfoPanel>
        </Box>
        <StyledNumberField
          label={`${t('form.electricity_production')} [${
            form.electricity_unit
          }]`}
          value={form.electricity_production}
          onChange={(e) =>
            handleFormChange('electricity_production', e.target.value)
          }
          required={requiredFields.includes('electricity_production')}
          error={isError('electricity_production')}
          helperText={errorText('electricity_production')}
        />
        <br />
        <Typography variant="h5">{t('form.source')}</Typography>

        {Object.values(ElectricitySource).map((source) => (
          <Box key={source}>
            <RadioField
              label={t(`sources.${source}`)}
              checked={form.source === source}
              onClick={() => handleFormChange('source', source)}
            />
          </Box>
        ))}

        <br />
        <Typography variant="h5">{t('form.factor_source')}</Typography>
        {ELECTRICITY_FACTOR_SOURCES_OPTIONS.map((option) => (
          <Box key={option.factor_source}>
            <RadioField
              label={option.label}
              checked={form.factor_source === option.factor_source}
              onClick={() => {
                setIsModified(true);
                setForm((prevState) => ({
                  ...prevState,
                  factor_source: option.factor_source,
                  emission_factor: option.emission_factor,
                }));
              }}
            />
          </Box>
        ))}

        <br />
        <StyledNumberField
          disabled={form.factor_source !== ElectricityFactorSource.OTHER}
          label={`${t('form.emission_factor')} [tCO2/${form.electricity_unit}]`}
          value={form.emission_factor}
          onChange={(e) => handleFormChange('emission_factor', e.target.value)}
          required={requiredFields.includes('emission_factor')}
          error={isError('emission_factor')}
          helperText={errorText('emission_factor')}
        />
        <br />
        {form.factor_source === ElectricityFactorSource.OTHER && (
          <>
            <StyledTextField
              label={t('form.other_source_indication')}
              value={form.other_source_indication || ''}
              onChange={(e) =>
                handleFormChange('other_source_indication', e.target.value)
              }
              multiline
              minRows={2}
              required
              error={isError('other_source_indication')}
              helperText={errorText('other_source_indication')}
            />
            <StyledTextField
              label={t('form.factor_source_details')}
              value={form.factor_source_details || ''}
              onChange={(e) =>
                handleFormChange('factor_source_details', e.target.value)
              }
              multiline
              minRows={3}
              required
              error={isError('factor_source_details')}
              helperText={errorText('factor_source_details')}
            />
          </>
        )}
        {isModified && (
          <Box sx={{ width: '100%', textAlign: 'right', mt: '12px' }}>
            <Button
              color="primary"
              onClick={handleSaveClick}
              sx={{ minWidth: '200px' }}
              disabled={Object.keys(validateForm(form, t)).length > 0}
            >
              {id ? 'Save' : 'Create'}
            </Button>
          </Box>
        )}
      </StyledPanel>
    </>
  );
}
