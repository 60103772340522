import { Button, Typography } from '@mui/material';
import React from 'react';
import { usePrecursors } from 'src/Cbam/hooks';
import Precursor from './Precursor';
import InfoPanel from 'src/Cbam/components/InfoPanel';

interface Props {
  id: number;
}
export default function Precursors(props: Props) {
  const { id } = props;
  const precursors = usePrecursors(Number(id));
  const [isNewVisible, setIsNewVisible] = React.useState(false);
  return (
    <>
      <Typography variant="h2">Precursors</Typography>
      <br />
      <InfoPanel>
        <Typography>
          Precursors are relevant input materials used in Production Process.
          Relevant Precursors for each good are listed in Section 3 of Annex II
          to Regulation (EU) 2023/1773.
          <br />
          Precursors&apos; emissions count towards both Direct and Indirect
          Emissions of Production Process.
        </Typography>
      </InfoPanel>
      {!precursors.data.length && !isNewVisible && (
        <Typography sx={{ m: '20px 0px' }}>No precursors</Typography>
      )}
      {precursors.data.map((precursor) => (
        <Precursor
          key={precursor.id}
          productionProcessId={id}
          id={precursor.id}
        />
      ))}

      {!isNewVisible ? (
        <Button sx={{ mt: '20px' }} onClick={() => setIsNewVisible(true)}>
          Add precursor data
        </Button>
      ) : (
        <Precursor
          productionProcessId={id}
          onSaved={() => setIsNewVisible(false)}
        />
      )}
    </>
  );
}
