import React from 'react';

export default function CopyIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 13H16C14.9 13 14 13.9 14 15V29H16V15H28V13ZM31 17H20C18.9 17 18 17.9 18 19V33C18 34.1 18.9 35 20 35H31C32.1 35 33 34.1 33 33V19C33 17.9 32.1 17 31 17ZM31 33H20V19H31V33Z"
        fill="#606462"
      />
    </svg>
  );
}
