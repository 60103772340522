import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonGroupField from '../../../components/ButtonGroupField';
import Title from '../../../components/Title';
import PageLoading from '../../../components/PageLoading';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import { RootState } from '../../../redux-file/store';
import BigCalendar from '../../components/BigCalendar';
import {
  getAboutCompanyPeriodData,
  getOrganization,
  putAboutCompanyPeriodData,
} from '../../../redux-file/thunk';
import {
  PageContainer,
  PreviewBox,
  Section,
  SectionGroupContainer,
} from '../Questionnaire/index.styles';
import EmployeesRevenueForm from '../../components/AboutCompany/EmployeesRevenueForm';
import {
  descStyle,
  StyledHeader,
  StyledTd,
  StyledTh,
  warnStyle,
} from './index.styles';
import IconSvg from '../../components/IconSvg';
import { timeConverter } from '../../../utils/timeConverter';
import QuestionnaireTitle from '../../components/Questionnaire/Title';
import QuestionnaireSectionTitle from '../../components/QuestionnaireSectionTitle';
import BasicInfoContent from './basicInfoContent';
import OrgBoundariesInfoContent from './orgBoundriesInfoContent';
import EmployeeInfoContent from './employeeInfoContent';
import InfoTable from '../../../components/InfoTable';

export default function AboutCompany() {
  const { t } = useTranslation();
  const { companyInfo, companyInfoLoading, companyInfoError } = useAppSelector(
    (state: RootState) => state.app
  );
  const { id: organizationId } = useGhgOrganization();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState(new Date());
  const [active, setActive] = useState<undefined | boolean>(
    companyInfo.numberOfEmployees === null &&
      companyInfo.incomeInReportingPeriod === null
      ? undefined
      : false
  );

  const reportingFreqs = {
    M: t('onboarding.monthly'),
    Q: t('onboarding.quarterly'),
    Y: t('onboarding.yearly'),
  };
  const options = [
    ['operational_control', t('organization-boundaries.operational-control')],
    [
      'equity_share_approach',
      t('organization-boundaries.equity-share-approach'),
    ],
    ['financial_control', t('organization-boundaries.financial-control')],
  ];
  const [orgBoundaries, setOrgBoundaries] = useState<string | null>(
    companyInfo.organizationBoundaries
  );
  const [noOfEmployees, setNoOfEmployees] = useState<number | null>(
    companyInfo.numberOfEmployees
  );
  const [incInReportingPeriod, setIncInReportingPeriod] = useState<
    number | null
  >(companyInfo.incomeInReportingPeriod);

  useEffect(() => {
    dispatch(getOrganization({ orgId: organizationId })).then(() =>
      setLoading(false)
    );
  }, [organizationId]);

  useEffect(() => {
    if (!companyInfoLoading && !companyInfoError && !!companyInfo) {
      dispatch(
        getAboutCompanyPeriodData({
          organizationId,
          period: timeConverter(selectedPeriod, companyInfo.reporting_freq),
        })
      ).then(() => {
        setNoOfEmployees(companyInfo.numberOfEmployees);
        setIncInReportingPeriod(companyInfo.incomeInReportingPeriod);
        setOrgBoundaries(companyInfo.organizationBoundaries);
        setActive(
          companyInfo.numberOfEmployees === null &&
            companyInfo.incomeInReportingPeriod === null
            ? undefined
            : false
        );
      });
    }
  }, [
    companyInfo,
    companyInfoLoading,
    companyInfoError,
    selectedPeriod,
    organizationId,
  ]);

  const sendAboutCompanyPeriodData = () => {
    if (noOfEmployees !== null || incInReportingPeriod !== null) {
      dispatch(
        putAboutCompanyPeriodData({
          organizationId,
          period: timeConverter(selectedPeriod, companyInfo.reporting_freq),
          numberOfEmployees: noOfEmployees,
          incomeInReportingPeriod: incInReportingPeriod,
          organizationBoundaries: orgBoundaries,
        })
      );
      setActive(false);
    } else {
      setActive(undefined);
    }
  };

  const handleBoundaries = (boundaries: string) => {
    setOrgBoundaries(boundaries === orgBoundaries ? null : boundaries);

    dispatch(
      putAboutCompanyPeriodData({
        organizationId,
        period: timeConverter(selectedPeriod, companyInfo.reporting_freq),
        numberOfEmployees: noOfEmployees,
        incomeInReportingPeriod: incInReportingPeriod,
        organizationBoundaries: boundaries,
      })
    );
  };

  const incomeInSelectedPeriodLabel = `${t(
    'about-company.income-in-selected-period'
  )} [${companyInfo.reportingCurrency}] (${t('optional')})`;

  if (companyInfoLoading || loading) return <PageLoading />;
  if (companyInfoError) return <SomethingWentWrong />;

  const tableData = [
    {
      firstColumn: t('about-company.company-name'),
      secondColumn: companyInfo.name,
    },
    {
      firstColumn: t('about-company.email-in-charge'),
      secondColumn: companyInfo.adminEmail || '',
    },
    {
      firstColumn: t('about-company.operating-country'),
      secondColumn: companyInfo.country,
    },
    {
      firstColumn: t('about-company.reporting-frequency'),
      secondColumn: reportingFreqs[companyInfo.reporting_freq],
    },
    {
      firstColumn: t('about-company.industry'),
      secondColumn: t(companyInfo.industry ?? ''),
    },
  ];

  return (
    <PageContainer>
      <SectionGroupContainer>
        <QuestionnaireTitle text={t('about-company.page-title')} />

        <div>
          <p style={descStyle}>{t('about-company.page-description')}</p>
        </div>

        <QuestionnaireSectionTitle
          active={false}
          text={t('about-company.basic-info')}
          level="primary"
        >
          <BasicInfoContent />
        </QuestionnaireSectionTitle>
        <PreviewBox>
          <InfoTable
            firstColumnWidth={30}
            secondColumnWidth={70}
            data={tableData}
          />
        </PreviewBox>
      </SectionGroupContainer>

      <SectionGroupContainer>
        <Title title={t('about-company.select-period')} description={null} />
        <BigCalendar
          reportingPeriod={companyInfo.reporting_freq}
          currDate={selectedPeriod ? new Date(selectedPeriod) : new Date()}
          setDate={(selDate: Date) => setSelectedPeriod(selDate)}
        />
      </SectionGroupContainer>
      <Section>
        <SectionGroupContainer>
          <QuestionnaireSectionTitle
            text={t('about-company.employees-and-revenue')}
            level="primary"
            active={active}
          >
            <EmployeeInfoContent />
          </QuestionnaireSectionTitle>
          {(active === true || active === undefined) && (
            <PreviewBox>
              <EmployeesRevenueForm
                noOfEmployees={noOfEmployees}
                setNoOfEmployees={setNoOfEmployees}
                incInReportingPeriod={incInReportingPeriod}
                setIncInReportingPeriod={setIncInReportingPeriod}
                sendData={sendAboutCompanyPeriodData}
                active={active === true}
                setActive={setActive}
              />
            </PreviewBox>
          )}
          {active === false && (
            <PreviewBox>
              <StyledHeader>
                <StyledTh width={35}>
                  {t('about-company.number-of-employees-period')}
                </StyledTh>
                <StyledTh width={65}>{incomeInSelectedPeriodLabel}</StyledTh>
                <StyledTh width={0} />
              </StyledHeader>
              <StyledHeader>
                <StyledTd width={35}>{noOfEmployees ?? ''}</StyledTd>
                <StyledTd width={65}>{incInReportingPeriod ?? ''}</StyledTd>
                <StyledTd width={0}>
                  <button type="button" onClick={() => setActive(true)}>
                    <IconSvg name="edit" />
                  </button>
                </StyledTd>
              </StyledHeader>
            </PreviewBox>
          )}
        </SectionGroupContainer>
      </Section>
      <Section>
        <SectionGroupContainer>
          <QuestionnaireSectionTitle
            text={t('about-company.organization-boundaries-title')}
            level="primary"
            active={false}
          >
            <OrgBoundariesInfoContent />
          </QuestionnaireSectionTitle>
          <div>
            <p style={descStyle}>
              {t('about-company.organization-boundaries-desc')}
            </p>
          </div>
          <div>
            <p style={warnStyle}>
              {t('about-company.organization-boundaries-warning')}
            </p>
          </div>
          <ButtonGroupField
            options={options}
            value={orgBoundaries ?? ''}
            setValue={(boundaries: string) => handleBoundaries(boundaries)}
          />
        </SectionGroupContainer>
      </Section>
      <div style={{ height: '250px' }} />
    </PageContainer>
  );
}
