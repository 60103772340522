import { Button } from '@mui/material';
import React from 'react';
import {
  CbamMassBalanceInSchema,
  CbamMassBalanceOutSchema,
  EmissionFactorSource,
} from 'src/Cbam/types';
import { useFuelTypes, useMassBalance } from 'src/Cbam/hooks';
import { StyledPanel } from 'src/components/StyledPanel';
import { StyledNumberField, StyledTextField } from '../../../styles';
import EmissionFactorSourceField from '../components/EmissionFactorSourceField';
import FuelTypeField from '../components/FuelTypeField';
import { StyledFlex } from 'src/components/StyledFlex';
import { useChangeTracker } from '../components/CounterProvider';

interface Props {
  productionProcessId: number;
  id?: number;
  onSaved?: () => void;
}
export default function MassBalance(props: Props) {
  const { productionProcessId, id, onSaved } = props;
  const { isModified, setIsModified } = useChangeTracker();
  const massBalances = useMassBalance(Number(productionProcessId));
  const massBalance = massBalances.data.find((m) => m.id === Number(id));
  const fuelTypes = useFuelTypes();

  const initialForm = {
    name: '',
    production_process_id: productionProcessId,
    activity_data: null,
    emission_factor_source: EmissionFactorSource.EVIDENCE_BASED,
    fuel_type: null,
    emission_factor: null,
    carbon_content: null,
  };

  const [form, setForm] = React.useState<
    CbamMassBalanceOutSchema | CbamMassBalanceInSchema
  >(initialForm);

  const handleFormChange = (key: string, value: any) => {
    setIsModified(true);
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  React.useEffect(() => {
    if (massBalance) setForm(massBalance);
  }, [massBalance]);

  const onSuccess = () => {
    setIsModified(false);
    onSaved?.();
  };

  const handleSaveClick = () =>
    !id
      ? massBalances.create.mutateAsync(form).then(onSuccess)
      : massBalances.update.mutateAsync({ ...form, id }).then(onSuccess);

  return (
    <StyledPanel sx={{ m: '20px 0px' }}>
      <StyledTextField
        label="Name"
        value={form.name}
        onChange={(e) => handleFormChange('name', e.target.value)}
      />
      <StyledNumberField
        label="Activity data [TJ]"
        value={form.activity_data}
        onChange={(e) => handleFormChange('activity_data', e.target.value)}
      />
      <br />
      <EmissionFactorSourceField
        value={form.emission_factor_source}
        onChange={(value) => handleFormChange('emission_factor_source', value)}
      />

      <br />
      {form.emission_factor_source === EmissionFactorSource.CBAM && (
        <FuelTypeField
          value={form.fuel_type}
          onChange={(value) => {
            setIsModified(true);
            setForm((prevState) => ({
              ...prevState,
              fuel_type: value,
              emission_factor: fuelTypes.data[value]?.factor || 0,
              carbon_content: +(
                (fuelTypes.data[value]?.factor || 0) *
                (fuelTypes.data[value]?.ncv || 0)
              ).toFixed(5),
            }));
          }}
        />
      )}
      <StyledNumberField
        disabled={form.emission_factor_source === EmissionFactorSource.CBAM}
        label="Emission factor [tCO2/unit]"
        value={form.emission_factor}
        onChange={(e) => handleFormChange('emission_factor', e.target.value)}
      />

      <StyledNumberField
        disabled={form.emission_factor_source === EmissionFactorSource.CBAM}
        label="Carbon content"
        value={form.carbon_content}
        onChange={(e) => handleFormChange('carbon_content', e.target.value)}
      />

      <StyledFlex>
        <Button
          onClick={() => (id ? massBalances.delete.mutate(id) : onSaved?.())}
          color="primary"
        >
          Remove Answer
        </Button>
        {isModified && (
          <Button
            color="primary"
            onClick={handleSaveClick}
            sx={{ minWidth: '200px' }}
          >
            {id ? 'Save' : 'Create'}
          </Button>
        )}
      </StyledFlex>
    </StyledPanel>
  );
}
