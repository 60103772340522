import React, { FunctionComponent, SVGAttributes } from 'react';
import DashboardIcon from '../../../images/svg/dashbordIcon.';
import MenuIcon from '../../../images/svg/menuIcon';
import RecommendationsIcon from '../../../images/svg/recommendationsIcon';
import TargetsIcon from '../../../images/svg/targetsIcon';
import AboutCompanyIcon from '../../../images/svg/aboutCompanyIcon';
import DownloadIcon from '../../../images/svg/downloadIcon';
import Co2Icon from '../../../images/svg/co2Icon';
import SurveyIcon from '../../../images/svg/surveyIcon';
import DirectEmissionIcon from '../../../images/svg/directEmissionIcon';
import ElectricityEmissionsIcon from '../../../images/svg/electricityEmissionsIcon';
import IndirectEmissionsIcon from '../../../images/svg/indirectEmissionsIcon';
import IndirectOtherEmissionsIcon from '../../../images/svg/indirectOtherEmissionsIcon';
import ArrowIcon from '../../../images/svg/arrovIcon';
import GoodsAndServicesIcon from '../../../images/svg/goodsAndServicesIcon';
import WasteIcon from '../../../images/svg/wasteIcon';
import AssetsIcon from '../../../images/svg/assetsIcon';
import EditIcon from '../../../images/svg/editIcon';
import ViewIcon from '../../../images/svg/viewIcon';
import AddCommentIcon from '../../../images/svg/addCommentIcon';
import AddedCommentIcon from '../../../images/svg/addedCommentIcon';
import ArrowLeftIcon from '../../../images/svg/arrowLeft';
import ArrowRightIcon from '../../../images/svg/arrowRight';
import GoodIcon from '../../../images/svg/goodIcon';
import SoldPoductsIcon from '../../../images/svg/soldPoductsIcon';
import CopyIcon from '../../../images/svg/copyIcon';
import RemoveIcon from '../../../images/svg/removeIcon';
import CalendarIcon from '../../../images/svg/calendarIcon';
import EsgNevReport from '../../../images/svg/esgNevReport';
import MandatoryCheckIcon from '../../../images/svg/mandatoryCheckIcon';
import ProductESGIcon from '../../../images/svg/productESGIcon';
import ProductSuppliersIcon from '../../../images/svg/productSuppliersIcon';
import ProductCo2Icon from '../../../images/svg/productCo2Icon';
import ProductLCAIcon from '../../../images/svg/productLCAIcon';
import TrashIcon from '../../../images/svg/trashIcon';
import { EsgReportsIcon } from '../../../images/svg/esgReportsIcon';
import ProductCbamIcon from '../../../images/svg/productCbamIcon';

const IconSvg: FunctionComponent<
  {
    name: string;
    className?: string;
  } & SVGAttributes<SVGElement>
> = ({ name, className }) => {
  switch (name) {
    case 'dashboard':
      return <DashboardIcon />;
    case 'menu':
      return <MenuIcon />;
    case 'recommendations':
      return <RecommendationsIcon />;
    case 'targets_reduction':
      return <TargetsIcon />;
    case 'about-company':
      return <AboutCompanyIcon />;
    case 'download':
      return <DownloadIcon />;
    case 'co2':
      return <Co2Icon />;
    case 'direct-emissions':
      return <DirectEmissionIcon />;
    case 'electricity-emissions':
      return <ElectricityEmissionsIcon />;
    case 'indirect-emissions':
      return <IndirectEmissionsIcon />;
    case 'indirect-other-emissions':
      return <IndirectOtherEmissionsIcon />;
    case 'format':
      return <SurveyIcon />;
    case 'arrow':
      return <ArrowIcon className={className} />;
    case 'arrow-left':
      return <ArrowLeftIcon />;
    case 'arrow-right':
      return <ArrowRightIcon />;
    case 'goods-and-services':
      return <GoodsAndServicesIcon />;
    case 'waste-and-other':
      return <WasteIcon />;
    case 'assets':
      return <AssetsIcon />;
    case 'edit':
      return <EditIcon />;
    case 'view':
      return <ViewIcon />;
    case 'addComment':
      return <AddCommentIcon />;
    case 'addedComment':
      return <AddedCommentIcon />;
    case 'good-icon':
      return <GoodIcon />;
    case 'sold-products':
      return <SoldPoductsIcon />;
    case 'copy':
      return <CopyIcon />;
    case 'remove':
      return <RemoveIcon />;
    case 'calendar':
      return <CalendarIcon />;
    case 'esg-reports':
      return <EsgReportsIcon />;
    case 'esg-new-report':
      return <EsgNevReport />;
    case 'check':
      return <MandatoryCheckIcon />;
    case 'product.esg':
      return <ProductESGIcon />;
    case 'product.suppliers':
      return <ProductSuppliersIcon />;
    case 'product.co2':
      return <ProductCo2Icon />;
    case 'product.lca':
      return <ProductLCAIcon />;
    case 'product.cbam':
      return <ProductCbamIcon />;
    case 'trash-icon':
      return <TrashIcon />;
    default:
      return null;
  }
};

export default IconSvg;
