type CustomVariant = { [key: string]: string | number };

export const body1: CustomVariant = {
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '26px',
  letterSpacing: '0.02em',
};

export const heading1: CustomVariant = {
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '38px',
  letterSpacing: '0.64px',
};

export const heading3: CustomVariant = {
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '30px',
  letterSpacing: '0.48px',
};

export const heading5: CustomVariant = {
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '28px',
  letterSpacing: '0.36px',
};

export const heading6: CustomVariant = {
  fontSize: '15px',
  fontWeight: '600',
  lineHeight: '23px',
  letterSpacing: '0.02em',
};

export const captionText: CustomVariant = {
  fontSize: '13px',
  fontWeight: '400',
  lineHeight: '19px',
  letterSpacing: '0.02em',
};

export const overlineText: CustomVariant = {
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '15px',
  letterSpacing: '0.36px',
  textTransform: 'uppercase',
  color: '#606462 !important',
};

export const mediumButton: CustomVariant = {
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '20px',
  letterSpacing: '0.3px',
};

export default {
  body1,
  heading1,
  heading3,
  heading5,
  heading6,
  captionText,
  overlineText,
  mediumButton,
};
