import React from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import {
  FieldAutocompleteCategoriesProps,
  NewOptions,
} from './index.interfaces';
import { StyledAutocomplete, ValueField } from './index.styles';
import {
  ValueLabel,
  ValueParagraph,
} from '../../../../../components/TextFieldNumber/index.styles';

export default function NewFieldAutocompleteCategories({
  label,
  options,
  value,
  setValue,
  error,
  active,
  index,
  max,
}: FieldAutocompleteCategoriesProps) {
  const { t } = useTranslation();
  // TODO: figure out what this does
  const newOptions = options
    .map((el: any) => ({
      ...el[1]
        .map((item: any) => ({
          group: el[0],
          value: item[0],
          verbose: item[1],
        }))
        .flat(),
    }))
    .map((e: any) => Object.values(e).flat(1))
    .flat() as NewOptions[];

  return (
    <>
      {active && (
        <StyledAutocomplete
          disablePortal
          options={newOptions}
          groupBy={(option: any) => t(option.group)}
          getOptionLabel={(option: any) =>
            option ? t(`dropdowns.${option.verbose}`) : ''
          }
          onChange={(_, newValue) => setValue(newValue.value)}
          disableClearable
          value={
            value !== ''
              ? newOptions[
                  newOptions.findIndex((option: any) => option.value === value)
                ]
              : value
          }
          renderInput={(params) => (
            <TextField {...params} label={t(label)} error={error} />
          )}
        />
      )}
      {!active && value !== '' && (
        <ValueField className="value-field" index={index} max={max}>
          <ValueLabel>{t(label)}</ValueLabel>
          <ValueParagraph>
            {newOptions[
              newOptions.findIndex((option: any) => option.value === value)
            ]
              ? t(
                  newOptions[
                    newOptions.findIndex(
                      (option: any) => option.value === value
                    )
                  ].value
                )
              : ''}
          </ValueParagraph>
        </ValueField>
      )}
    </>
  );
}
