import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import shevronLeft from '../../../images/shevron-left-green.png';
import {
  BackButton,
  ButtonContainer,
  Container,
  LoginButton,
  StyledAlert,
} from './index.styles';
import OnboardingHeading from '../../components/OnboardingHeading';
import PageLoading from '../../../components/PageLoading';
import { useAppSelector } from '../../../redux-file/hooks';
import { confirmPasswordReset } from '../../../utils/auth';
import PasswordVerificationForm from '../../../components/PasswordVerificationForm';
import TextField from '../../../components/TextField';

type FormState = {
  token: string;
  email: string;
  new_password: string;
};

export default function PasswordNew() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [queryParams, _] = useSearchParams();
  const [form, setForm] = useState<FormState>({
    token: queryParams.get('token')!,
    email: '',
    new_password: '',
  });

  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');

  const isLoggedIn = useAppSelector((state) => state.platform.isAuthenticated);

  if (!form.token) return <Navigate to="/password" />;

  const handleSubmit = () => {
    // if form is already completed, redirect on click
    if (success) return navigate('/');

    setError('');
    confirmPasswordReset(form)
      .then((result) => {
        if (!result.success) {
          setError(t('resetPassword.formError'));
          return;
        }
        setSuccess(t('resetPassword.formSuccess'));
        setTimeout(() => {
          navigate('/');
        }, 4000);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  if (isLoggedIn) return <Navigate to="/" />;
  if (isLoggedIn === undefined) return <PageLoading />;

  return (
    <Container>
      <OnboardingHeading text={t('login.change-title')}>
        <form onSubmit={handleSubmit}>
          <Box>
            <Box sx={{ mb: '8px' }}>
              <TextField
                fullWidth
                label={t('resetPassword.email.label')}
                placeholder={t('resetPassword.email.placeholder')}
                value={form.email}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                  setForm({ ...form, email: ev.target.value })
                }
                type="email"
                required
              />
            </Box>

            <Box sx={{ mb: '44px' }}>
              <PasswordVerificationForm
                setNewPassword={(value: string) =>
                  setForm({ ...form, new_password: value })
                }
                translationPrefix="resetPassword"
              />
            </Box>

            {error && <StyledAlert severity="error">{error}</StyledAlert>}
            {success && <StyledAlert severity="success">{success}</StyledAlert>}
          </Box>
        </form>
        <ButtonContainer>
          <BackButton to="/">
            <img src={shevronLeft} alt="arrow left back button" />
            <span>{t('login.back')}</span>
          </BackButton>
          <LoginButton
            disabled={!form.new_password}
            type="submit"
            onClick={handleSubmit}
          >
            <span>{t('login.next')}</span>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none">
              <path
                d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z"
                fill="white"
              />
            </svg>
          </LoginButton>
        </ButtonContainer>
      </OnboardingHeading>
    </Container>
  );
}
