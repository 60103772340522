import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledHeader,
  StyledTd,
} from '../../../Pages/Questionnaire/index.styles';
import { companiesColors } from '../../../Pages/SuperDashboard/data';
import CommentBtn from './commentBtn';
import TableButton from '../../TableButton';
import { toLocaleNumber } from '../../../../utils/helpers';

export default function InvestmentsPreview({
  state,
  id,
  setActive,
  setState,
  language,
}: {
  state: {
    emission_shared: number;
    emission_amount_is_known: boolean;
    name: string;
    investment_data_factor_unknown: {
      investment_revenues_in_perdiod_pln: string;
      industry: string;
    };
    investment_data_factor_known: {
      amount_kg_eq_co2_in_period: string;
    };
  }[];
  id: number;
  setActive: Function;
  setState: Function;
  language: string;
}) {
  const { t } = useTranslation();
  const handleCopy = () => {
    const tempState = [...state];
    const item = tempState[id];

    tempState.splice(id, 0, item);
    setState(tempState);
    setActive(id);
  };
  return (
    <StyledHeader>
      <StyledTd width={43} color={companiesColors[id]}>
        <p>{state[id].name || ''}</p>
        <CommentBtn state={state} id={id} />
      </StyledTd>
      <StyledTd width={13} color={companiesColors[id]}>
        {state[id].investment_data_factor_unknown &&
        state[id].investment_data_factor_unknown
          .investment_revenues_in_perdiod_pln
          ? toLocaleNumber(
              state[id].investment_data_factor_unknown
                ?.investment_revenues_in_perdiod_pln,
              language
            )
          : ''}
      </StyledTd>
      <StyledTd width={22} color={companiesColors[id]}>
        {t(
          !!state[id].investment_data_factor_unknown?.industry
            ? `dropdowns.industries.${state[id].investment_data_factor_unknown?.industry}`
            : ''
        )}
      </StyledTd>
      <StyledTd width={22} color={companiesColors[id]}>
        {state[id].emission_shared
          ? (state[id].emission_shared * 100).toFixed(2)
          : 0}
      </StyledTd>
      <StyledTd
        width={0}
        color={companiesColors[id]}
        lastCallCustom
        numberOfIcons="two"
      >
        <TableButton hoverText="copy" icon="copy" onClick={handleCopy} />
        <TableButton
          hoverText="edit"
          icon="edit"
          onClick={() => setActive(id)}
        />
      </StyledTd>
    </StyledHeader>
  );
}
