import React from 'react';

import { DmaPanel as Props } from './index.types';
import { StyledTitle } from './index.styles';

export default function DmaPanel(props: Props) {
  const { title } = props;
  return (
    <>
      <StyledTitle sx={{ m: '10px 0px' }}>{title}</StyledTitle>
      {props.children}
    </>
  );
}
