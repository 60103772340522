import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { injectNewAnswers, paths } from '../helpers';
import { client, handleToastError } from '../../../../utils/api-client';

import { WarningContainer } from '../index.styles';
import QuestionnaireContext from '../Data/QuestionnaireContext';
import Loader from '../../../../components/Loader';
import { useLanguage } from 'src/common/hooks';

type Props = {
  id: string;
};

export default function QuestionnaireAnswerWrapped(props: Props) {
  const { id: questionnaireAnswerId } = props;

  const searchParams = new URLSearchParams(window.location.search);
  const queryString = searchParams.toString();
  const { language: activeLanguage } = useLanguage();

  // TODO: ideally, this should be handled via 'refetch',
  //  but the preview components crash in that case :/
  const questionnaireContextKey = [
    'questionnaire-answer-preview',
    questionnaireAnswerId,
    queryString,
    activeLanguage,
  ];

  const [error, setError] = useState(false);

  const handleError = (err: any) => {
    handleToastError(err);
    setError(true);
  };

  const { data, isLoading } = useQuery(questionnaireContextKey, () =>
    client
      .get(
        `${paths.questionnairePreview}/${questionnaireAnswerId}?${queryString}`
      )
      .then((res) => res.data)
      .then((data) => injectNewAnswers(data))
      .catch(handleError)
  );

  if (!!isLoading) return <Loader />;

  if (!!error) return <WarningContainer>preview unavailable</WarningContainer>;

  return (
    <QuestionnaireContext
      questionnaireAnswerId=""
      refetch={() => new Promise(() => {})}
      tokenAuth={() => new Promise(() => {})}
      data={data}
    />
  );
}
