import { useMutation, useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { Qv3SummaryTableSchema } from 'src/views/components/QuestionnaireV3/Summary/types';
import { useLanguage } from 'src/common/hooks';

// eslint-disable-next-line
export const useEsgStakeholderAnalytics = (
  organizationId: number,
  reportId: string
) => {
  const { language } = useLanguage();

  const endpointUrl = `web/esg_stakeholders/${organizationId}/${reportId}/stakeholders-answers`;
  const queryKey = [
    'esg-stakeholder-analytics',
    organizationId,
    reportId,
    language,
  ];

  const query = useQuery(queryKey, () =>
    client
      .get<Qv3SummaryTableSchema[]>(endpointUrl)
      .then((response) => response.data)
  );

  const deleteAnswer = useMutation({
    mutationFn: (answerId: number) =>
      client.delete(`${endpointUrl}/${answerId}`),
  });

  const dataCount = (query.data || []).length;

  return {
    queryKey,
    endpointUrl,
    dataCount,
    deleteAnswer,
    ...query,
  };
};
