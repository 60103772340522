import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledFlex } from 'src/components/StyledFlex';
import { StyledPanel } from 'src/components/StyledPanel';
import { useActors } from '../hooks';
import InfoPanel from '../components/InfoPanel';

export default function Actors() {
  const navigate = useNavigate();
  const actors = useActors();
  return (
    <Box>
      <StyledFlex>
        <Typography variant="h1">Importers and Representatives</Typography>
        <Button onClick={() => navigate('create')}>Add Actor</Button>
      </StyledFlex>
      <br />
      <br />
      <InfoPanel>
        <Typography>
          Importer means either the person lodging a customs declaration for
          release for free circulation of goods in its own name and on its own
          behalf or, where the customs declaration is lodged by an Indirect
          Customs Representative in accordance with Article 18 of Regulation
          (EU) No 952/2013, the person on whose behalf such a declaration is
          lodged.
        </Typography>
      </InfoPanel>
      <br />
      <br />
      <StyledPanel>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Actor</TableCell>
              <TableCell>Identifier</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {actors.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.identifier}</TableCell>
                <TableCell sx={{ width: '50px', pl: 0, pr: 0 }}>
                  <IconButton onClick={() => navigate(String(row.id))}>
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell sx={{ width: '50px', pl: 0, pr: 0 }}>
                  <IconButton onClick={() => actors.delete.mutate(row.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledPanel>
    </Box>
  );
}
