import React, { useRef, useState } from 'react';
import QuestionnaireTitle from '../../components/Questionnaire/Title';
import {
  SaveProductButtonWrapper,
  Description,
  PageContainer,
  Section,
  SectionGroupContainer,
  SaveButton,
  PreviewBox,
  CommentTextArea,
  AnotherButtonWrapper,
} from './index.styles';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '../Targets/index.styles';
import { Link, useNavigate } from 'react-router-dom';
import QuestionnaireSectionTitle from '../../components/QuestionnaireSectionTitle';
import {
  comment_cloud,
  fuel_and_raw_materials_cloud,
  fuel_and_raw_materials_waste_cloud,
} from './clouds_data';
import TotalAmountForm from '../../components/Questionnaire/forms/fuels-and-raw-materials/total-amount';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import { timeConverter } from '../../../utils/timeConverter';
import {
  createSoldProductsFuelOrFeedstockData,
  getSoldProductsData,
} from '../../../redux-file/questionnaires/thunks';
import { index_url } from './index-sold-products';
import SoldProductsWasteForm from '../../components/Questionnaire/forms/fuels-and-raw-materials/waste-form';
import AddProductButton from '../../../components/AddProductButton';
import UploadExcelButton from '../../../components/UploadExcelButton';

export const fuel_or_feedstock_init = {
  name: '',
  type_of_fuel: '',
  unit: '',
  amount: '',
  manual_factor: '',
  comment: '',
};

export const waste_init = {
  waste_type: '',
  amount_kg: '',
  recycled_or_composed_flag: false,
  manual_factor: '',
  comment: '',
};

export default function FuelsAndRawMaterials() {
  const { selectedDate } = useAppSelector((state) => state.questionnaire);

  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [fuel_or_feedstock, set_fuel_or_feedstock] = useState(
    fuel_or_feedstock_init
  );
  const [waste_state, set_waste_state] = useState([waste_init]);

  const [activeTotal, setActiveTotal] = useState(false);
  const [active1, setActive1] = useState<undefined | number>(undefined);

  const waste_ref = useRef(null);

  const combinedData = {
    fuel_or_feedstock,
    waste:
      waste_state.length === 1 && waste_state[0].waste_type === ''
        ? []
        : waste_state,
  };

  const combineWasteData = (fullData: any, newData: any) => {
    set_waste_state([...newData, ...fullData.waste]);
  };

  return (
    <PageContainer>
      <SectionGroupContainer>
        <Breadcrumbs>
          <Link to={index_url}>{t('menu.sold-products')}</Link>
          <span>{'>'}</span>
          <div>{t(`sold-products.fuels-and-raw-materials.title`)}</div>
        </Breadcrumbs>
        <QuestionnaireTitle
          info
          text={t('sold-products.fuels-and-raw-materials.title')}
        />
        <Description>
          {t('sold-products.fuels-and-raw-materials.description')}
        </Description>

        <Section>
          <QuestionnaireSectionTitle
            level="primary"
            text={t('sold-products.fuels-and-raw-materials.basic')}
            active={false}
          />
        </Section>
        <Section>
          <QuestionnaireSectionTitle
            level="secondary"
            boxContent={fuel_and_raw_materials_cloud}
            text={t('sold-products.fuels-and-raw-materials.total.title')}
            active={activeTotal}
          />

          <PreviewBox>
            <TotalAmountForm
              setActive={setActiveTotal}
              active={activeTotal}
              state={fuel_or_feedstock}
              setState={set_fuel_or_feedstock}
            />
          </PreviewBox>
        </Section>

        <Section>
          <QuestionnaireSectionTitle
            level="secondary"
            boxContent={comment_cloud}
            text={t('sold-products.fuels-and-raw-materials.comment.title')}
            active={false}
          />
          <PreviewBox>
            <CommentTextArea
              placeholder={t(
                'sold-products.fuels-and-raw-materials.comment.placeholder'
              )}
              value={fuel_or_feedstock.comment}
              onChange={(e) => {
                const tempState = {
                  name: fuel_or_feedstock.name,
                  type_of_fuel: fuel_or_feedstock.type_of_fuel,
                  unit: fuel_or_feedstock.unit,
                  amount: fuel_or_feedstock.amount,
                  manual_factor: fuel_or_feedstock.manual_factor,
                  comment: e.target.value,
                };

                set_fuel_or_feedstock(tempState);
              }}
            />
          </PreviewBox>
        </Section>

        <Section>
          <QuestionnaireSectionTitle
            level="primary"
            text={t('sold-products.final-products.direct.waste.main')}
            active={false}
          />
        </Section>
        <Section>
          <div ref={waste_ref} />
          <QuestionnaireSectionTitle
            level="secondary"
            boxContent={fuel_and_raw_materials_waste_cloud}
            text={t('sold-products.fuels-and-raw-materials.waste.title')}
            active={active1}
          />

          {waste_state.map((_, id) => (
            <PreviewBox key={id}>
              <SoldProductsWasteForm
                setNewForm={() => {}}
                id={id}
                setState={set_waste_state}
                state={waste_state}
                active={active1}
                setActive={setActive1}
              />
            </PreviewBox>
          ))}
          <AnotherButtonWrapper>
            {waste_state[waste_state.length - 1].waste_type !== '' &&
              waste_state[waste_state.length - 1].amount_kg !== '' &&
              (waste_state[waste_state.length - 1].waste_type !==
                'other.manual_factor' ||
                !!waste_state[waste_state.length - 1].manual_factor) && (
                <>
                  <AddProductButton
                    onClick={() => {
                      set_waste_state([waste_init, ...waste_state]);
                      setActive1(0);
                      if (waste_ref && waste_ref.current) {
                        // @ts-ignore
                        waste_ref.current.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }
                    }}
                  />
                  <span>{t('popup.excel.or')}</span>
                </>
              )}
            <UploadExcelButton
              name={t('sold-products.fuels-and-raw-materials.waste.title')}
              slug="WasteData"
              fullData={combinedData}
              combineData={combineWasteData}
              sendData={() => {}}
              refresh={() => {}}
            />
          </AnotherButtonWrapper>
        </Section>

        <Section>
          <SaveProductButtonWrapper>
            <SaveButton
              disabled={fuel_or_feedstock.type_of_fuel === ''}
              type="button"
              onClick={() => {
                dispatch(
                  createSoldProductsFuelOrFeedstockData({
                    period: timeConverter(selectedDate, reportingPeriod),
                    data: combinedData,
                  })
                )
                  .then(() =>
                    dispatch(
                      getSoldProductsData({
                        period: timeConverter(selectedDate, reportingPeriod),
                      })
                    )
                  )
                  .finally(() => navigate(index_url));
              }}
            >
              {t('sold-products.buttons.save')}
            </SaveButton>
          </SaveProductButtonWrapper>
        </Section>
      </SectionGroupContainer>
    </PageContainer>
  );
}
