import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../views/Pages/Login';
import Register from '../views/Pages/Register';
import PasswordRecovery from '../views/Pages/PasswordRecovery';
import PasswordNew from '../views/Pages/PasswordNew';
import PasswordSuccess from '../views/Pages/PasswordSuccess';
import EmployeeSurveyForm from '../views/Pages/EmployeeSurvey/employee-survey';
import { client } from '../utils/api-client';
import * as Sentry from '@sentry/react';
import NotFound from 'src/views/components/NotFound';
import { RouteLayout } from '../views/components/Layout';
import TopBar from '../components/TopBar';
import SupplierSurveyIntro from '../Ghg/SupplierSurvey/Intro';
import SupplierSurveyInitialQuestions from '../Ghg/SupplierSurvey/InitialQuestions';
import SupplierSurveyQuestionnaire from '../Ghg/SupplierSurvey/Questionnaire';
import SupplierSurveyEnd from '../Ghg/SupplierSurvey/End';
import { EsgStakeholderSurveyRoute } from 'src/Esg';
import { auth } from '../utils/auth';
import LoginWithToken from 'src/views/Pages/LoginWithToken';
import RegisterUBNP from 'src/views/Pages/RegisterUBNP';
import CodeOfConductSurveyForm from '../Ghg/CodeOfConductSurvey/Form';
import CodeOfConductSurveyEnd from '../Ghg/CodeOfConductSurvey/End';
import CodeOfConductSurvey from '../Ghg/CodeOfConductSurvey';
import OperatorSurvey from 'src/Cbam/OperatorSurvey';
import { useLanguage } from '../common/hooks';
import CbamProductionProcessSurveyRoute from '../Cbam/ProductionProcessSurvey';
import Forbidden from 'src/components/Forbidden';

const Logout = () => {
  useEffect(() => {
    auth.signOut();
  }, []);

  return <Navigate to="/" />;
};
interface Props {
  children: React.ReactNode;
}

export default function UnauthorizedAccessProvider(props: Props) {
  const { i18n } = useLanguage();
  // set language
  React.useEffect(() => {
    const languageInterceptor = client.interceptors.request.use((config) => {
      const lang = i18n.language;
      if (lang) config.headers['Accept-Language'] = lang;
      return config;
    });
    return () => client.interceptors.request.eject(languageInterceptor);
  }, [i18n]);

  // setup sentry
  React.useEffect(() => {
    const sentryInterceptor = client.interceptors.request.use(
      (config) => config,
      (error) => {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
    );
    return () => client.interceptors.request.eject(sentryInterceptor);
  });

  return (
    <Routes>
      <Route element={<RouteLayout topbar={<TopBar />} />}>
        {/* Login, logout */}
        <Route path="/login/:token" element={<LoginWithToken />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />

        {/* password management */}
        <Route path="/password" element={<PasswordRecovery />} />
        <Route path="/password/new" element={<PasswordNew />} />
        <Route path="/password/success" element={<PasswordSuccess />} />

        {/* 404 */}
        <Route path="/404" element={<NotFound />} />
        <Route path="/403" element={<Forbidden />} />
      </Route>

      {/* TODO: use common Layout for these? */}
      {/* Employee forms */}
      <Route path="/employee_form" element={<EmployeeSurveyForm />} />

      {/* Stakeholder survey */}
      <Route
        path="stakeholders/:token/*"
        element={<EsgStakeholderSurveyRoute />}
      />

      {/* Supplier survey */}
      <Route path="suppliers/:token">
        <Route index element={<SupplierSurveyIntro />} />
        <Route
          path="initial-questions"
          element={<SupplierSurveyInitialQuestions />}
        />
        <Route path="questionnaire" element={<SupplierSurveyQuestionnaire />} />
        <Route path="end" element={<SupplierSurveyEnd />} />
      </Route>

      <Route path="code-of-conduct-survey/:token">
        <Route index element={<CodeOfConductSurvey />} />
        <Route path="form" element={<CodeOfConductSurveyForm />} />
        <Route path="end" element={<CodeOfConductSurveyEnd />} />
      </Route>

      {/* CBAM survey */}
      <Route
        path="production-process-survey/:token/*"
        element={<CbamProductionProcessSurveyRoute />}
      />

      {/* Registration form for microBNP */}
      <Route
        path="/register_ubnp"
        element={
          <RouteLayout>
            <RegisterUBNP />
          </RouteLayout>
        }
      />

      <Route path="cbam/operator-survey/*" element={<OperatorSurvey />} />

      <Route path="*" element={props.children} />
    </Routes>
  );
}
