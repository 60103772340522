import React from 'react';
import styled from 'styled-components';

const StyledText = styled.h1`
  color: var(--Dark, #281302);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.8px;
`;

export const Title = ({ text }: { text: string }) => {
  return <StyledText>{text}</StyledText>;
};

const StyledDesc = styled.p`
  color: var(--Dark-grey, #4d4d4d);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.54px;
  margin-bottom: 50px;
`;

export const Description = ({ text }: { text: string }) => {
  return <StyledDesc>{text}</StyledDesc>;
};

const StyledSubtitle = styled.p`
  color: var(--Connection-Dark, #a9a9a9);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.54px;
  text-transform: uppercase;
  margin-top: 54px;
`;

export const Subtitle = ({ text }: { text: string }) => {
  return <StyledSubtitle>{text}</StyledSubtitle>;
};
