import React from 'react';
import { Page, View, Image, StyleSheet, Text } from '@react-pdf/renderer';
import { PDF_HEIGHT, PDF_WIDTH, MARGIN_LEFT, MARGIN_TOP } from '../config';
import { appConfig } from '../../../../../config';

const styles = StyleSheet.create({
  backgroundPage: { backgroundColor: '#f8f8f8' },
  page: {
    backgroundColor: '#f8f8f8',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    padding: `${MARGIN_TOP}px ${MARGIN_LEFT}px`,
  },
  backgroundImage: {
    position: 'absolute',
    height: PDF_HEIGHT,
    width: PDF_WIDTH,
  },
  pageTitle: {
    fontSize: 40,
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontStyle: 'bold',
    textTransform: 'uppercase',
    color: '#888',
    position: 'absolute',
    top: 120,
    left: MARGIN_LEFT,
    width: PDF_WIDTH - 2 * MARGIN_LEFT,
  },
  pageNumber: {
    position: 'absolute',
    top: PDF_HEIGHT - 150,
    left: PDF_WIDTH - 150,
    fontFamily: 'Poppins',
    fontSize: 48,
    color: '#6E6E6E',
    fontStyle: 'bold',
    textTransform: 'uppercase',
  },
});

export type Props = {
  children: any;
  backgroundImageName?: string;
  title?: string;
};

const BasePage = (props: Props) => {
  const { backgroundImageName, title } = props;
  return (
    <Page
      orientation="portrait"
      style={!!backgroundImageName ? styles.backgroundPage : styles.page}
      size={[PDF_WIDTH, PDF_HEIGHT]}
    >
      {!!title && <Text style={styles.pageTitle}>{title}</Text>}
      <View>
        {!!backgroundImageName && (
          <Image
            src={`${appConfig.backendRootUrl}/static-backend/reports/esgsummary/${props.backgroundImageName}`}
            style={styles.backgroundImage}
          />
        )}

        {props.children}
      </View>

      <Text
        style={styles.pageNumber}
        render={({ pageNumber }) => pageNumber}
        fixed
      />
    </Page>
  );
};

export default BasePage;
