import { styled as styledMUI } from '@mui/material/styles';

export const StyledButtonArea = styledMUI('div')`
  position: relative;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
`;

export const StyledButtonAreaHeader = styledMUI('div')`
  padding: 10px 0px;
  color: #4D4D4D;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.48px;
  text-transform: uppercase; 
`;
