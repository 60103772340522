import React from 'react';
import {
  StyledHeader,
  StyledTd,
} from '../../../Pages/Questionnaire/index.styles';
import { companiesColors } from '../../../Pages/SuperDashboard/data';
import CommentBtn from './commentBtn';
import TableButton from '../../TableButton';
import { toLocaleNumber } from '../../../../utils/helpers';

export default function ProcessEmissionsPreview({
  state,
  id,
  setActive,
  setState,
  language,
}: {
  state: any[];
  id: number;
  setActive: Function;
  setState: Function;
  language: string;
}) {
  const handleCopy = () => {
    const tempState = [...state];
    const item = tempState[id];

    tempState.splice(id, 0, item);
    setState(tempState);
    setActive(id);
  };
  return (
    <StyledHeader>
      <StyledTd width={40} color={companiesColors[id]}>
        <p>{state[id].name_of_process}</p>
        <CommentBtn state={state} id={id} />
      </StyledTd>
      <StyledTd width={40} color={companiesColors[id]}>
        {toLocaleNumber(state[id].kg_eq_co2_per_period, language)}
      </StyledTd>
      <StyledTd width={20} color={companiesColors[id]}>
        {state[id].emission_shared
          ? (state[id].emission_shared * 100).toFixed(2)
          : 0}
      </StyledTd>
      <StyledTd width={0} color={companiesColors[id]} numberOfIcons="two">
        <TableButton hoverText="copy" icon="copy" onClick={handleCopy} />
        <TableButton
          hoverText="edit"
          icon="edit"
          onClick={() => setActive(id)}
        />
      </StyledTd>
    </StyledHeader>
  );
}
