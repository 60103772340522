import React from 'react';
import { TextFieldOutlineProps } from './index.interfaces';
import { StyledTextField, ValueField } from './index.styles';

export default function TextFieldOutline({
  label,
  value,
  setValue,
  error,
  active,
  index,
  max,
}: TextFieldOutlineProps) {
  return (
    <>
      {active && (
        <StyledTextField
          {...{ value, error, label }}
          onChange={(event) => setValue(event.target.value)}
          variant="outlined"
        />
      )}
      {!active && value !== '' && (
        <ValueField className="value-field" index={index} max={max}>
          {value}
        </ValueField>
      )}
    </>
  );
}
