import { Button, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ProfileButton = styled(Button)`
  padding: 2px 20px;
  margin: 0px 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 16px;
  text-transform: none;
  min-width: 200px;
  max-width: 320px;
  &:hover {
    background: #f2f2f2;
  }
`;

export const StyledCompanyInfo = styled('div')`
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  padding-top: 30px;
  cursor: default;
  text-align: center;

  > h1 {
    letter-spacing: 0.01em;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #606462;
    margin: 10px 0 0 0;
  }

  > h5 {
    letter-spacing: 0.01em;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #606462;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #e0e0e0;
  color: #606462;
  text-wrap: wrap;

  & .MuiTypography-root {
    font-size: 16px;
  }
`;
