import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '../ReportsIndex/index.styles';
import { useEsgOrganization } from 'src/common/hooks';
import QuestionnaireV3 from '../../../components/QuestionnaireV3';
import { useEsgQuestionnaireAnswerId } from './Hooks/useQuestionnaireAnswerId';
import { createEsgTokenFetcher } from './Hooks/tokenFetcher';

export default function ESGForm() {
  const params = useParams<{
    formKey: string;
    reportId: string;
  }>();

  const organization = useEsgOrganization();
  const reportId = String(params.reportId);
  const questionnaireKey = String(params.formKey);

  const { data: questionnaireAnswerId } = useEsgQuestionnaireAnswerId({
    organizationId: organization.id,
    reportId,
    questionnaireKey,
  });

  const tokenFetcher = useMemo(
    () =>
      createEsgTokenFetcher(organization.id, reportId, questionnaireAnswerId),
    [organization.id, reportId, questionnaireAnswerId]
  );

  return (
    <Container>
      <QuestionnaireV3
        key={questionnaireAnswerId}
        questionnaireAnswerId={questionnaireAnswerId}
        tokenAuth={tokenFetcher}
        withBulkSaveButtons
      />
    </Container>
  );
}
