import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Overlay } from '../../Title/index.styles';
import OneValueCheckboxField from '../../../../../components/CheckboxField';
import { CheckboxContainer, Form, FormBox } from '../index.styles';
import TextFieldNumber from '../../../../../components/TextFieldNumber';
import { AutocompleteFreesolo, Select } from '../../fields';
import TextFieldNumberPercentage from '../../../../../components/TextFieldNumberPercentage';
import FormButtons from '../../FormButtons';
import { autoCompleteOptions } from 'src/utils/helpers';
import { energy_units, energy_units_freesolo } from '../data';

export default function EnergyForm({
  state,
  setState,
  id,
  active,
  setActive,
  setNewForm,
}: {
  state: any;
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  setNewForm: Function;
}) {
  const { t } = useTranslation();

  const [unit_error_state, set_unit_error_state] = useState(false);
  const [
    spending_pln_or_kwh_per_period_error_state,
    set_spending_pln_or_kwh_per_period_error_state,
  ] = useState(false);

  let unit_error = false;
  let spending_pln_or_kwh_per_period_error = false;
  const unit_validation = (value: string) => {
    if (value === '') unit_error = true;
    return unit_error;
  };

  const spending_pln_or_kwh_per_period_validation = (value: string) => {
    if (value === '') spending_pln_or_kwh_per_period_error = true;
    return spending_pln_or_kwh_per_period_error;
  };

  const [factor_error_state, set_factor_error_state] = useState(false);
  let factor_error = false;
  const factor_validation = (value: string) => {
    if (value === '') factor_error = true;
    return factor_error;
  };

  const [amount_per_period_error_state, set_amount_per_period_error_state] =
    useState(false);
  let amount_per_period_error = false;
  const amount_per_period_validation = (value: string) => {
    if (value === '') amount_per_period_error = true;
    return amount_per_period_error;
  };

  const [certified_percentge_error_state, set_certified_percentge_error_state] =
    useState(false);
  let certified_percentge_error = false;
  const certified_percentge_validation = (value: string) => {
    if (value === '') certified_percentge_error = true;
    return certified_percentge_error;
  };

  const [emission_factor_error_state, set_emission_factor_error_state] =
    useState(false);
  let emission_factor_error = false;
  const emission_factor_validation = (value: string) => {
    if (value === '') emission_factor_error = true;
    return emission_factor_error;
  };

  const setKnowEmission = () => {
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: !state[id].emission_factor_is_known,
      energy_usage_data_factor_unknown: state[id].emission_factor_is_known
        ? {
            spending_pln_or_kwh_per_period: '',
            unit: '',
          }
        : commutingType.energy_usage_data_factor_unknown,
      energy_usage_data_factor_known: state[id].emission_factor_is_known
        ? commutingType.energy_usage_data_factor_known
        : {
            factor: '',
            amount_per_period: '',
            unit_label: '',
          },
      certified_sources_are_utilized:
        commutingType.certified_sources_are_utilized,
      certified_energy_usage_data: commutingType.certified_energy_usage_data,
    };
    newForms[id] = commutingType;
    setState(newForms);
  };

  const setCertSource = () => {
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      energy_usage_data_factor_unknown:
        commutingType.energy_usage_data_factor_unknown,
      energy_usage_data_factor_known:
        commutingType.energy_usage_data_factor_known,
      certified_sources_are_utilized: !state[id].certified_sources_are_utilized,
      certified_energy_usage_data: state[id].certified_sources_are_utilized
        ? commutingType.certified_energy_usage_data
        : {
            certified_energy_type_label: '',
            certified_percentge: '',
            emission_factor: '',
          },
    };
    newForms[id] = commutingType;
    setState(newForms);
  };

  const setUnknownAmount = (value: string) => {
    spending_pln_or_kwh_per_period_validation(value);
    set_spending_pln_or_kwh_per_period_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      energy_usage_data_factor_unknown: {
        spending_pln_or_kwh_per_period: value,
        unit: commutingType.energy_usage_data_factor_unknown.unit,
      },
      energy_usage_data_factor_known:
        commutingType.energy_usage_data_factor_known,
      certified_sources_are_utilized:
        commutingType.certified_sources_are_utilized,
      certified_energy_usage_data: commutingType.certified_energy_usage_data,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_spending_pln_or_kwh_per_period_error_state(true);
  };

  const setUnknownUnit = (value: string) => {
    unit_validation(value);
    set_unit_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      energy_usage_data_factor_unknown: {
        spending_pln_or_kwh_per_period:
          commutingType.energy_usage_data_factor_unknown
            .spending_pln_or_kwh_per_period,
        unit: value,
      },
      energy_usage_data_factor_known:
        commutingType.energy_usage_data_factor_known,
      certified_sources_are_utilized:
        commutingType.certified_sources_are_utilized,
      certified_energy_usage_data: commutingType.certified_energy_usage_data,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_unit_error_state(true);
  };

  const setKnowAmount = (value: string) => {
    amount_per_period_validation(value);
    set_amount_per_period_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      energy_usage_data_factor_unknown:
        commutingType.energy_usage_data_factor_unknown,
      energy_usage_data_factor_known: {
        factor: commutingType.energy_usage_data_factor_known.factor,
        amount_per_period: value,
        unit_label: commutingType.energy_usage_data_factor_known.unit_label,
      },
      certified_sources_are_utilized:
        commutingType.certified_sources_are_utilized,
      certified_energy_usage_data: commutingType.certified_energy_usage_data,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_amount_per_period_error_state(true);
  };

  const setKnowUnit = (value: string) => {
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      energy_usage_data_factor_unknown:
        commutingType.energy_usage_data_factor_unknown,
      energy_usage_data_factor_known: {
        factor: commutingType.energy_usage_data_factor_known.factor,
        amount_per_period:
          commutingType.energy_usage_data_factor_known.amount_per_period,
        unit_label: value,
      },
      certified_sources_are_utilized:
        commutingType.certified_sources_are_utilized,
      certified_energy_usage_data: commutingType.certified_energy_usage_data,
    };
    newForms[id] = commutingType;
    setState(newForms);
  };

  const setKnowFactor = (value: string) => {
    factor_validation(value);
    set_factor_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      energy_usage_data_factor_unknown:
        commutingType.energy_usage_data_factor_unknown,
      energy_usage_data_factor_known: {
        factor: value,
        amount_per_period:
          commutingType.energy_usage_data_factor_known.amount_per_period,
        unit_label: commutingType.energy_usage_data_factor_known.unit_label,
      },
      certified_sources_are_utilized:
        commutingType.certified_sources_are_utilized,
      certified_energy_usage_data: commutingType.certified_energy_usage_data,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_factor_error_state(true);
  };

  const setCertPerc = (value: string) => {
    certified_percentge_validation(value);
    set_certified_percentge_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      energy_usage_data_factor_unknown:
        commutingType.energy_usage_data_factor_unknown,
      energy_usage_data_factor_known:
        commutingType.energy_usage_data_factor_known,
      certified_sources_are_utilized:
        commutingType.certified_sources_are_utilized,
      certified_energy_usage_data: {
        certified_energy_type_label:
          commutingType.certified_energy_usage_data.certified_energy_type_label,
        certified_percentge: value,
        emission_factor:
          commutingType.certified_energy_usage_data.emission_factor,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_certified_percentge_error_state(true);
  };

  const setCertLabel = (value: string) => {
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      energy_usage_data_factor_unknown:
        commutingType.energy_usage_data_factor_unknown,
      energy_usage_data_factor_known: {
        factor: commutingType.energy_usage_data_factor_known.factor,
        amount_per_period:
          commutingType.energy_usage_data_factor_known.amount_per_period,
        unit_label: commutingType.energy_usage_data_factor_known.unit_label,
      },
      certified_sources_are_utilized:
        commutingType.certified_sources_are_utilized,
      certified_energy_usage_data: {
        certified_energy_type_label: value,
        certified_percentge:
          commutingType.certified_energy_usage_data.certified_percentge,
        emission_factor:
          commutingType.certified_energy_usage_data.emission_factor,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
  };

  const setCertFactor = (value: string) => {
    emission_factor_validation(value);
    set_emission_factor_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      comment: commutingType.comment,
      emission_factor_is_known: commutingType.emission_factor_is_known,
      energy_usage_data_factor_unknown:
        commutingType.energy_usage_data_factor_unknown,
      energy_usage_data_factor_known:
        commutingType.energy_usage_data_factor_known,
      certified_sources_are_utilized:
        commutingType.certified_sources_are_utilized,
      certified_energy_usage_data: {
        certified_energy_type_label:
          commutingType.certified_energy_usage_data.certified_energy_type_label,
        certified_percentge:
          commutingType.certified_energy_usage_data.certified_percentge,
        emission_factor: value,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_emission_factor_error_state(true);
  };

  const defineErrors = async () => {
    if (state[id].emission_factor_is_known === false) {
      if (state[id].energy_usage_data_factor_unknown.unit === '') {
        set_unit_error_state(true);
        unit_error = true;
      }
      if (
        state[id].energy_usage_data_factor_unknown
          .spending_pln_or_kwh_per_period === ''
      ) {
        set_spending_pln_or_kwh_per_period_error_state(true);
        spending_pln_or_kwh_per_period_error = true;
      }
    }

    if (state[id].emission_factor_is_known === true) {
      if (state[id].energy_usage_data_factor_known.factor === '') {
        set_factor_error_state(true);
        factor_error = true;
      }

      if (state[id].energy_usage_data_factor_known.amount_per_period === '') {
        set_amount_per_period_error_state(true);
        amount_per_period_error = true;
      }
    }

    if (state[id].certified_sources_are_utilized === true) {
      if (state[id].certified_energy_usage_data.certified_percentge === '') {
        set_certified_percentge_error_state(true);
        certified_percentge_error = true;
      }
      if (state[id].certified_energy_usage_data.emission_factor === '') {
        set_emission_factor_error_state(true);
        emission_factor_error = true;
      }
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    if (tempState.length === 0) {
      setState([
        {
          emission_factor_is_known: false,
          energy_usage_data_factor_unknown: {
            spending_pln_or_kwh_per_period: '',
            unit: '',
          },
          energy_usage_data_factor_known: {
            factor: '',
            amount_per_period: '',
            unit_label: '',
          },
          certified_sources_are_utilized: false,
          certified_energy_usage_data: {
            certified_energy_type_label: '',
            certified_percentge: '',
            emission_factor: '',
          },
        },
      ]);

      set_unit_error_state(false);
      unit_error = false;
      set_spending_pln_or_kwh_per_period_error_state(false);
      spending_pln_or_kwh_per_period_error = false;

      set_factor_error_state(false);
      factor_error = false;

      set_amount_per_period_error_state(false);
      amount_per_period_error = false;

      set_certified_percentge_error_state(false);
      certified_percentge_error = false;
      set_emission_factor_error_state(false);
      emission_factor_error = false;
    }

    setActive(undefined);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              if (
                !unit_error &&
                !spending_pln_or_kwh_per_period_error &&
                !factor_error &&
                !amount_per_period_error &&
                !certified_percentge_error &&
                !emission_factor_error
              ) {
                setActive(undefined);
              }
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <CheckboxContainer>
            <OneValueCheckboxField
              label={t('electricity-emission.form.check-factor')}
              value={state[id].emission_factor_is_known}
              setValue={() => setKnowEmission()}
            />

            <OneValueCheckboxField
              label={t('electricity-emission.form.check-cert')}
              value={state[id].certified_sources_are_utilized}
              setValue={() => setCertSource()}
              labelInfoContent="checkbox-infobox"
            />
          </CheckboxContainer>
          {state[id].emission_factor_is_known === false &&
          state[id].certified_sources_are_utilized === false ? (
            <>
              <FormBox variant="50">
                <TextFieldNumber
                  label={t(`electricity-emission.electricity.form.spending`)}
                  active
                  error={spending_pln_or_kwh_per_period_error_state}
                  index={1}
                  value={
                    state[id].energy_usage_data_factor_unknown
                      .spending_pln_or_kwh_per_period
                  }
                  setValue={(e: string) => setUnknownAmount(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <Select
                  active
                  error={unit_error_state}
                  index={1}
                  label={t(`electricity-emission.form.unit`)}
                  value={state[id].energy_usage_data_factor_unknown.unit}
                  setValue={(value: string) => setUnknownUnit(value)}
                  max={123}
                  options={energy_units}
                />
              </FormBox>
            </>
          ) : null}

          {state[id].emission_factor_is_known === true &&
          state[id].certified_sources_are_utilized === false ? (
            <>
              <FormBox variant="50">
                <TextFieldNumber
                  label={t(`electricity-emission.form.amount-factor`)}
                  active
                  error={amount_per_period_error_state}
                  index={1}
                  value={
                    state[id].energy_usage_data_factor_known.amount_per_period
                  }
                  setValue={(e: string) => setKnowAmount(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <AutocompleteFreesolo
                  active
                  error={false}
                  index={1}
                  label={t(`electricity-emission.form.unit-factor`)}
                  value={state[id].energy_usage_data_factor_known.unit_label}
                  setValue={(value: string) =>
                    setKnowUnit(autoCompleteOptions(value))
                  }
                  max={123}
                  options={energy_units_freesolo}
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  type="factor"
                  label={t('electricity-emission.form.emission-factor')}
                  active
                  error={factor_error_state}
                  index={1}
                  value={
                    // knowFactor
                    state[id].energy_usage_data_factor_known.factor
                  }
                  setValue={(e: string) => setKnowFactor(e)}
                />
              </FormBox>
            </>
          ) : null}

          {state[id].emission_factor_is_known === false &&
          state[id].certified_sources_are_utilized === true ? (
            <>
              <FormBox variant="50">
                <TextFieldNumber
                  label={t(`electricity-emission.form.spending`)}
                  active
                  error={spending_pln_or_kwh_per_period_error_state}
                  index={1}
                  value={
                    state[id].energy_usage_data_factor_unknown
                      .spending_pln_or_kwh_per_period
                  }
                  setValue={(e: string) => setUnknownAmount(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <Select
                  active
                  error={unit_error_state}
                  index={1}
                  label={t('electricity-emission.form.unit')}
                  value={state[id].energy_usage_data_factor_unknown.unit}
                  setValue={(value: string) => setUnknownUnit(value)}
                  max={123}
                  options={energy_units}
                />
              </FormBox>
              <FormBox variant="50">
                <AutocompleteFreesolo
                  active
                  error={false}
                  index={1}
                  label={t('electricity-emission.form.cert-energy')}
                  value={
                    state[id].certified_energy_usage_data
                      .certified_energy_type_label
                  }
                  setValue={(value: string) => setCertLabel(value)}
                  max={123}
                  options={[]}
                />
              </FormBox>
              <FormBox variant="50">
                <TextFieldNumberPercentage
                  label={t('electricity-emission.form.cert-percent')}
                  active
                  error={certified_percentge_error_state}
                  index={1}
                  max={99999}
                  value={
                    state[id].certified_energy_usage_data.certified_percentge
                  }
                  setValue={(e: string) => setCertPerc(e)}
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  label={t('electricity-emission.form.cert-emission')}
                  active
                  error={emission_factor_error_state}
                  index={1}
                  value={state[id].certified_energy_usage_data.emission_factor}
                  setValue={(e: string) => setCertFactor(e)}
                />
              </FormBox>
            </>
          ) : null}

          {state[id].emission_factor_is_known === true &&
          state[id].certified_sources_are_utilized === true ? (
            <>
              <FormBox variant="50">
                <TextFieldNumber
                  label={t(`electricity-emission.form.amount-factor`)}
                  active
                  error={amount_per_period_error_state}
                  index={1}
                  value={
                    state[id].energy_usage_data_factor_known.amount_per_period
                  }
                  setValue={(e: string) => setKnowAmount(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <AutocompleteFreesolo
                  active
                  error={false}
                  index={1}
                  label={t('electricity-emission.form.unit-factor')}
                  value={state[id].energy_usage_data_factor_known.unit_label}
                  setValue={(value: string) =>
                    setKnowUnit(autoCompleteOptions(value))
                  }
                  max={123}
                  options={energy_units_freesolo}
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  type="factor"
                  label={t('electricity-emission.form.emission-factor')}
                  active
                  error={factor_error_state}
                  index={1}
                  value={state[id].energy_usage_data_factor_known.factor}
                  setValue={(e: string) => setKnowFactor(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <AutocompleteFreesolo
                  active
                  error={false}
                  index={1}
                  label={t('electricity-emission.form.cert-energy')}
                  value={
                    state[id].certified_energy_usage_data
                      .certified_energy_type_label
                  }
                  setValue={(value: string) => setCertLabel(value)}
                  max={123}
                  options={[]}
                />
              </FormBox>
              <FormBox variant="50">
                <TextFieldNumberPercentage
                  label={t('electricity-emission.form.cert-percent')}
                  active
                  error={certified_percentge_error_state}
                  index={1}
                  max={99999}
                  value={
                    state[id].certified_energy_usage_data.certified_percentge
                  }
                  setValue={(e: string) => setCertPerc(e)}
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  label={t('electricity-emission.form.cert-emission')}
                  active
                  error={emission_factor_error_state}
                  index={1}
                  value={state[id].certified_energy_usage_data.emission_factor}
                  setValue={(e: string) => setCertFactor(e)}
                />
              </FormBox>
            </>
          ) : null}
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={
              (!state[id].emission_factor_is_known &&
                state[id].energy_usage_data_factor_unknown.unit !== '' &&
                state[id].energy_usage_data_factor_unknown
                  .spending_pln_or_kwh_per_period !== '') ||
              (state[id].emission_factor_is_known &&
                state[id].energy_usage_data_factor_known.factor !== '' &&
                state[id].energy_usage_data_factor_known.amount_per_period !==
                  '') ||
              (state[id].certified_sources_are_utilized &&
                state[id].certified_energy_usage_data.certified_percentge !==
                  '' &&
                state[id].certified_energy_usage_data.emission_factor !== '')
            }
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
            variant="sold-products"
          />
        )}
      </Form>
    </>
  );
}
