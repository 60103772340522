import React, { useState } from 'react';
import {
  Row,
  StyledTh,
  Table,
} from '../../../../../components/PreviewTable/index.styles';
import { ContentType, GenDate } from '../index.styles';
import { PreviewBox } from '../../../Questionnaire/index.styles';
import { useTranslation } from 'react-i18next';
import { esgCategoryColors, StakeholdersData } from './index';
import { CopyIcon, RemoveIcon } from './images';
import ColumnMenu from '../../../../../components/esg/ColumnMenu';

const data: StakeholdersData[] = [
  {
    name: 'My stakeholder 1',
    create_date: '2023-06-12',
    stakeholders: 'Clients',
    categories: ['Resources Use and Circular Economy (E5)'],
    response_date: '2023-06-12',
    visited: false,
  },
  {
    name: 'My stakeholder’s name very very long 2',
    create_date: '2023-05-12',
    stakeholders: 'Employees',
    categories: ['Climate Change (E1)', 'Pollution (E2)', 'Own Workforce (S1)'],
    response_date: '2023-07-12',
    visited: true,
  },
  {
    name: 'My stakeholder 3',
    create_date: '2022-01-31',
    stakeholders: 'Clients',
    categories: [],
    response_date: null,
    visited: false,
  },
  {
    name: 'My stakeholder’s name very very long 4',
    create_date: '2023-01-31',
    stakeholders: 'Professional Associations',
    categories: ['Climate Change (E1)', 'Pollution (E2)'],
    response_date: '2023-10-12',
    visited: false,
  },
];
export default function StakeholdersTable() {
  const { t } = useTranslation();

  const [sortField, setSortField] = useState<'create_date' | 'response_date'>(
    'create_date'
  );

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const [tableData, setTableData] = useState(data);

  const handleSortingChange = (
    accessor: 'create_date' | 'response_date',
    sortOrder: 'asc' | 'desc'
  ) => {
    setSortField(accessor);
    setOrder(sortOrder);
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        const a_asc = a[accessor] != null ? a[accessor] : Infinity;
        const b_asc = b[accessor] != null ? b[accessor] : Infinity;
        const a_desc = a[accessor] != null ? a[accessor] : -Infinity;
        const b_desc = b[accessor] != null ? b[accessor] : -Infinity;
        if (sortOrder === 'asc') {
          return (
            // @ts-ignore
            a_asc.toString().localeCompare(b_asc.toString())
          );
        } else {
          return (
            // @ts-ignore
            b_desc.toString().localeCompare(a_desc.toString())
          );
        }
      });
      setTableData(sorted);
    }
  };

  return (
    <PreviewBox style={{ padding: 24 }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button type="button">
          <CopyIcon />
        </button>
        <button type="button">
          <RemoveIcon />
        </button>
      </div>
      <Table
        colors={['#38414F']}
        cellSpacing="0"
        cellPadding="0"
        style={{
          width: '100%',
          borderCollapse: 'collapse',
        }}
      >
        <tbody>
          <Row>
            <StyledTh color="#38414F">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                {t('esg.stakeholders')}
                <ColumnMenu
                  tooltip_name={t('Date the form was generated')}
                  order={order}
                  sortField={sortField}
                  items={[
                    {
                      name: t('esg.sort_form_by_gen_oldest'),
                      onClick: () => handleSortingChange('create_date', 'asc'),
                    },
                    {
                      name: t('esg.sort_form_by_gen_latest'),
                      onClick: () => handleSortingChange('create_date', 'desc'),
                    },
                  ]}
                />
              </div>
            </StyledTh>
            <StyledTh width="220px" color="#38414F">
              {t('esg.categories')}
            </StyledTh>
            <StyledTh width="240px" color="#38414F">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                {t('esg.date_of_response')}
                <ColumnMenu
                  tooltip_name={t('Date of response')}
                  order={order}
                  sortField={sortField}
                  items={[
                    {
                      name: t('esg.sort_form_by_response_oldest'),
                      onClick: () =>
                        handleSortingChange('response_date', 'asc'),
                    },
                    {
                      name: t('esg.sort_form_by_response_latest'),
                      onClick: () =>
                        handleSortingChange('response_date', 'desc'),
                    },
                  ]}
                />
              </div>
            </StyledTh>
            <StyledTh color="#38414F">{t('esg.options')}</StyledTh>
          </Row>

          {tableData.map((el: StakeholdersData) => (
            <Row key={el.name} active visited={el.visited}>
              <td>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '-5px 0',
                    position: 'relative',
                    height: '80px',
                    alignItems: 'center',
                    gap: 20,
                  }}
                >
                  <div>
                    <span style={{ wordBreak: 'break-word' }}>{el.name}</span>
                  </div>
                  <GenDate>
                    {new Date(el.create_date).toLocaleDateString()}
                  </GenDate>
                  <ContentType>{el.stakeholders}</ContentType>
                </div>
              </td>
              <td>
                <div
                  style={{ display: 'flex', gap: 4, flexDirection: 'column' }}
                >
                  {el.categories.map((el) => (
                    <ContentType color={esgCategoryColors(el)} key={el}>
                      {el}
                    </ContentType>
                  ))}
                </div>
              </td>
              <td>
                {el.response_date
                  ? new Date(el.response_date).toLocaleDateString()
                  : t('esg.no_response')}
              </td>
              <td>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {/* TODO: zamienić na działający input */}
                  <input type="checkbox" />
                </div>
              </td>
            </Row>
          ))}
        </tbody>
      </Table>
    </PreviewBox>
  );
}
