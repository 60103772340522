import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';

import { Node } from './Node/index.types';
import { Colors } from 'src/Lca/components/Button/index.types';
import { LcaAreaOfImpactSchema } from 'src/Lca/types';

/* eslint-disable no-unused-vars */

export type Stage = {
  key: LcaDiagramColumns;
  name: string;
  group: string;
  children?: Node[];
};

export enum Boundary {
  cradle_to_gate = 'cradle_to_gate',
  gate_to_gate = 'gate_to_gate',
  cradle_to_grave = 'cradle_to_grave',
  gate_to_grave = 'gate_to_grave',
}

export enum LcaDiagramColumns {
  rawMaterialsExtractionAndProcessing = 'raw-materials-extraction-and-processing',
  transportOfRawMaterials = 'transport-of-raw-materials',
  productionAndPackaging = 'production-and-packaging',
  distributionAndStorage = 'distribution-and-storage',
  installation = 'installation',
  useStage = 'use-stage',
  endOfLife = 'end-of-life',
}

export type Boundaries = {
  [key in Boundary]: string[];
};

export type LCADiagram = {
  boundary: Boundary;
  data: Stage[];
  productId: number;
};

export type CategoryType = {
  colors: Colors;
  icon: React.ComponentType<SvgIconProps>;
};

export type Subcategory = {
  name: string;
  group: string;
  id: string;
};

export type ProductImpactOutSchema = {
  area: LcaAreaOfImpactSchema;
  emission_per_node: { [key: string]: number | null };
  emission_per_stage: { [key in LcaDiagramColumns]: number };
  total_emission: number | null;
};

export const boundaries: Boundaries = {
  [Boundary.cradle_to_gate]: [
    LcaDiagramColumns.rawMaterialsExtractionAndProcessing,
    LcaDiagramColumns.transportOfRawMaterials,
    LcaDiagramColumns.productionAndPackaging,
  ],
  [Boundary.gate_to_gate]: [LcaDiagramColumns.productionAndPackaging],
  [Boundary.cradle_to_grave]: [
    LcaDiagramColumns.rawMaterialsExtractionAndProcessing,
    LcaDiagramColumns.transportOfRawMaterials,
    LcaDiagramColumns.productionAndPackaging,
    LcaDiagramColumns.distributionAndStorage,
    LcaDiagramColumns.installation,
    LcaDiagramColumns.useStage,
    LcaDiagramColumns.endOfLife,
  ],
  [Boundary.gate_to_grave]: [
    LcaDiagramColumns.distributionAndStorage,
    LcaDiagramColumns.installation,
    LcaDiagramColumns.useStage,
    LcaDiagramColumns.endOfLife,
  ],
};
