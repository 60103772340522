import React from 'react';
import { TableCell, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SxProps, TypographyProps } from '@mui/system';

export const StyledTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.02em;
  color: #38414f;
`;
const StyledTypography = styled(
  ({
    // eslint-disable-next-line no-unused-vars
    children,
    // eslint-disable-next-line no-unused-vars
    sx,
    ...props
  }: { children: React.ReactNode; sx?: SxProps } & TypographyProps) => (
    <Typography {...props} sx={sx} component="span">
      {children}
    </Typography>
  )
)``;

export const StyledCellValue = styled(StyledTypography)`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  width: 100%;
  text-align: center;
  color: #38414f;
`;
export const StyledCellSubValue = styled(StyledTypography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  color: #6e6e6e;
`;

export const StyledTableCell = styled(TableCell)`
  padding: 5px;
  min-width: 130px;
  border-bottom: none;
`;

export const StyledCellHeader = styled(StyledTypography)`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`;
