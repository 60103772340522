import React from 'react';

import CoverPage from './CoverPage';
import MainMetricsPage from './MainMetrics';
import CarbonFootprintTimeline from './CarbonFootprintTimeline';
import CarbonFootprintTable from './CarbonFootprintTable';
import TotalEmissionsByScopePieChart from './TotalEmissionsByScopePieChart';
import TotalEmissionByScopeTable from './TotalEmissionByScopeTable';
import EmissionRankingScope3PieChart from './EmissionRankingScope3PieChart';
import GoodsAndServicesEmissionsChart from './GoodsAndServicesEmissionsChart';
import { CarbonFootprintDataType } from './types';

const carbonFootprintSection = (data: CarbonFootprintDataType) => {
  const carbonFootprintTables = data.carbon_footprint_table.table_data.map(
    (tableData) => (
      <CarbonFootprintTable
        data={{
          ...data,
          carbon_footprint_table: {
            ...data.carbon_footprint_table,
            table_data: tableData,
          },
        }}
      />
    )
  );

  const totalEmissionsByScopeTables =
    data.total_emissions_by_scope_table.table_data.map((tableData) => (
      <TotalEmissionByScopeTable
        data={{
          ...data,
          total_emissions_by_scope_table: {
            ...data.total_emissions_by_scope_table,
            table_data: tableData,
          },
        }}
      />
    ));

  return [
    <CoverPage data={data} />,
    <MainMetricsPage data={data} />,
    <CarbonFootprintTimeline data={data} />,
    ...carbonFootprintTables,
    <TotalEmissionsByScopePieChart data={data} />,
    ...totalEmissionsByScopeTables,
    data.emission_ranking_scope_3_pie_chart && (
      <EmissionRankingScope3PieChart
        emission_ranking_scope_3_pie_chart={
          data.emission_ranking_scope_3_pie_chart
        }
      />
    ),
    data.goods_and_services_emissions_chart && (
      <GoodsAndServicesEmissionsChart
        goods_and_services_emissions_chart={
          data.goods_and_services_emissions_chart
        }
      />
    ),
  ];
};

export default carbonFootprintSection;
