import React from 'react';

export default function IndirectOtherEmissionsIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11_7385)">
        <path
          d="M20 4.36365H4V6.40001H20V4.36365ZM21 14.5455V12.5091L20 7.41819H4L3 12.5091V14.5455H4V20.6546H14V14.5455H18V20.6546H20V14.5455H21ZM12 18.6182H6V14.5455H12V18.6182Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_7385">
          <rect
            width="24"
            height="24.4364"
            fill="currentColor"
            transform="translate(0 0.290894)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
