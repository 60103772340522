import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../../views/components/Dialog';
import { StyledButton, StyledButtonGroup } from './index.styles';

export default function ButtonGroupField({
  value,
  setValue,
  options,
  disabled,
}: {
  value: string;
  setValue: Function;
  options: string[][];
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  return (
    <>
      <Dialog
        text={t('about-company.report-contact-us')}
        secondButtonText={t('about-company.close')}
        setClicked={() => setOpened(false)}
        opened={opened}
      />
      <StyledButtonGroup
        disabled={disabled}
        onClick={() => {
          if (disabled) setOpened(true);
        }}
        fullWidth
        size="large"
      >
        {options.map((option) => (
          <StyledButton
            key={option[0]}
            onClick={() => setValue(option[0])}
            selected={option[0] === value}
            disabled={disabled}
          >
            {option[1]}
          </StyledButton>
        ))}
      </StyledButtonGroup>
    </>
  );
}
