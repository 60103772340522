import React, { useEffect } from 'react';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useAppDispatch, useAppSelector } from '../redux-file/hooks';
import { getReport } from './api';
import PageLoading from '../components/PageLoading';
import { setActiveReport } from '../redux-file/esg/slice';
import { useEsgOrganization } from '../common/hooks';
import { parseNumericId } from '../utils/navigation';

export const EsgReportRoute = () => {
  const { reportId: reportIdStr } = useParams();

  const reportId = parseNumericId(reportIdStr);

  const { id: organizationId } = useEsgOrganization();

  const { activeReport } = useAppSelector((state) => state.esg);
  const dispatch = useAppDispatch();

  const { data, isError } = useQuery(['reports', { reportId }], () =>
    getReport({ organizationId, reportId })
  );

  useEffect(() => {
    dispatch(setActiveReport(data));
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(setActiveReport(undefined));
    };
  }, []);

  if (isError) {
    // redirect to index
    return <Navigate to=".." />;
  }

  if (!activeReport) {
    return <PageLoading />;
  }

  return <Outlet />;
};
