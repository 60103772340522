import React from 'react';

import { List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { useSidebar } from '../hooks';
import { DrawerHeader } from '../index.styled';
import { usePlatformNavigation } from '../../../../utils/navigation';

type SidebarHeaderWithLogoProps = {
  openLogo: React.ReactNode;
  closedLogo: React.ReactNode;
};
export default function SidebarHeaderWithLogo(
  props: SidebarHeaderWithLogoProps
) {
  const { isOpen } = useSidebar();
  const { navigate, navigateToProduct, activeProduct } =
    usePlatformNavigation();

  const navigateToHome = () => {
    if (activeProduct) {
      navigateToProduct(activeProduct);
    } else {
      navigate('/');
    }
  };

  return (
    <DrawerHeader>
      <List>
        <ListItem sx={{ height: '70px' }}>
          <ListItemButton onClick={navigateToHome}>
            <ListItemIcon>
              {isOpen ? props.openLogo : props.closedLogo}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </List>
    </DrawerHeader>
  );
}
