import React from 'react';
import { StyledHeader, StyledTdCompany, StyledThCompany } from './index.styles';

interface Props {
  firstColumnWidth: number;
  secondColumnWidth: number;
  data: Array<{
    firstColumn: string;
    secondColumn: string;
  }>;
}

export default function InfoTable({
  firstColumnWidth,
  secondColumnWidth,
  data,
}: Props) {
  return (
    <>
      {data.map((el, i) => (
        <StyledHeader key={i}>
          <StyledThCompany width={firstColumnWidth}>
            {el.firstColumn}
          </StyledThCompany>
          <StyledTdCompany width={secondColumnWidth}>
            {el.secondColumn}
          </StyledTdCompany>
        </StyledHeader>
      ))}
    </>
  );
}
