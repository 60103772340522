import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommutingForm from './forms/commuting';
import BusinessTripsForms from './forms/businessTrips';
import HomeOfficeForm from './forms/homeOffice';
import CountryForm from './forms/country';
import {
  Title,
  ButtonsBox,
  NextButton,
  BackButton,
  NextButtonLink,
} from './index.styles';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { postEmployeeSurveyData } from '../../../redux-file/questionnaires/thunks';
import AddProductButton from '../../../components/AddProductButton';
import FormPreview from './formPreview';
import shevronRight from '../../../images/shevron-right-white.png';
import shevronLeft from '../../../images/shevron-left-green.png';

export default function Formset({
  token,
  page,
  setPage,
  lang,
}: {
  token: string;
  page: number;
  setPage: Function;
  lang: string;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const initCommuteState = {
    type_of_commute_transport: '',
    times_per_week: null,
    average_distance_one_way_km: null,
  };

  const initBusinessTripsState = {
    type_of_trip_transport: '',
    times_in_period: null,
    average_distance_per_trip_km: null,
  };

  const initStaysState = {
    country: '',
    number_of_nights: '',
  };

  const { sentEmployeeData, sentEmployeeDataLoading } = useAppSelector(
    (state) => state.questionnaire
  );

  const [commuteState, setCommuteState] = useState<any>([initCommuteState]);
  const [businessTripsState, setBusinessTripsState] = useState<any>([
    initBusinessTripsState,
  ]);
  const [staysState, setStaysState] = useState<any>([initStaysState]);
  const [homeOfficeState, setHomeOfficeState] = useState<any>('');

  const combinedData = {
    commute: commuteState,
    business_trips: businessTripsState,
    average_number_of_days_on_home_office_per_week: homeOfficeState,
    stays:
      staysState[0].country === '' && staysState[0].number_of_nights === ''
        ? []
        : staysState,
  };

  const [errorHO, setErrorHO] = useState(true);
  const [errorCom, setErrorCom] = useState(true);
  const [errorBT, setErrorBT] = useState(true);
  const [errorST, setErrorST] = useState(false);

  useEffect(() => {
    if (homeOfficeState !== '') {
      setErrorHO(false);
    } else {
      setErrorHO(true);
    }
  }, [homeOfficeState]);

  useEffect(() => {
    if (
      staysState[staysState.length - 1].country !== '' &&
      staysState[staysState.length - 1].number_of_nights === ''
    ) {
      setErrorST(true);
    } else if (
      staysState[staysState.length - 1].country === '' &&
      staysState[staysState.length - 1].number_of_nights !== ''
    ) {
      setErrorST(true);
    } else {
      setErrorST(false);
    }
  }, [staysState]);

  useEffect(() => {
    if (
      commuteState[commuteState.length - 1].type_of_commute_transport === '' ||
      (commuteState[commuteState.length - 1].type_of_commute_transport !==
        'none' &&
        (commuteState[commuteState.length - 1].times_per_week === null ||
          commuteState[commuteState.length - 1].average_distance_one_way_km ===
            null))
    ) {
      setErrorCom(true);
    } else {
      setErrorCom(false);
    }
  }, [commuteState]);

  useEffect(() => {
    if (
      businessTripsState[businessTripsState.length - 1]
        .type_of_trip_transport === '' ||
      (businessTripsState[businessTripsState.length - 1]
        .type_of_trip_transport !== 'none' &&
        (businessTripsState[businessTripsState.length - 1].times_in_period ===
          null ||
          businessTripsState[businessTripsState.length - 1]
            .average_distance_per_trip_km === null))
    ) {
      setErrorBT(true);
    } else {
      setErrorBT(false);
    }
  }, [businessTripsState]);

  return (
    <div style={{ maxWidth: '800px' }}>
      {page === 0 && (
        <>
          <Title>
            {t('employee-survey.question1')}
            <span className="important-title">{' *'}</span>
          </Title>

          {commuteState.map((_: any, idx: number) => (
            <CommutingForm
              forms={commuteState}
              setForms={setCommuteState}
              idx={idx}
              key={idx}
            />
          ))}

          {!!commuteState[commuteState.length - 1].type_of_commute_transport &&
            !!commuteState[commuteState.length - 1].times_per_week &&
            !!commuteState[commuteState.length - 1]
              .average_distance_one_way_km && (
              <AddProductButton
                text="employee-survey.add-another"
                onClick={() =>
                  setCommuteState([
                    ...commuteState,
                    {
                      type_of_commute_transport: '',
                      times_per_week: '',
                      average_distance_one_way_km: '',
                    },
                  ])
                }
              />
            )}

          <Title>
            {t('employee-survey.question2')}
            <span className="important-title">{' *'}</span>
          </Title>
          <HomeOfficeForm
            forms={homeOfficeState}
            setForms={setHomeOfficeState}
          />

          <Title>
            {t('employee-survey.question3')}
            <span className="important-title">{' *'}</span>
          </Title>
          {businessTripsState.map((_: any, idx: number) => (
            <BusinessTripsForms
              forms={businessTripsState}
              setForms={setBusinessTripsState}
              idx={idx}
              key={idx}
            />
          ))}
          {!!businessTripsState[businessTripsState.length - 1]
            .type_of_trip_transport &&
            !!businessTripsState[businessTripsState.length - 1]
              .times_in_period &&
            !!businessTripsState[businessTripsState.length - 1]
              .average_distance_per_trip_km && (
              <AddProductButton
                text="employee-survey.add-another"
                onClick={() =>
                  setBusinessTripsState([
                    ...businessTripsState,
                    {
                      type_of_trip_transport: '',
                      times_in_period: '',
                      average_distance_per_trip_km: '',
                    },
                  ])
                }
              />
            )}

          <Title>{t('employee-survey.question4')}</Title>
          {staysState.map((_: any, idx: number) => (
            <CountryForm
              forms={staysState}
              setForms={setStaysState}
              idx={idx}
              key={idx}
            />
          ))}
          {!!staysState[staysState.length - 1].number_of_nights &&
            !!staysState[staysState.length - 1].country && (
              <AddProductButton
                text="employee-survey.add-another-stay"
                onClick={() =>
                  setStaysState([
                    ...staysState,
                    {
                      country: '',
                      number_of_nights: '',
                    },
                  ])
                }
              />
            )}
        </>
      )}
      {page === 1 && <FormPreview forms={combinedData} />}
      {page === 0 && (
        <ButtonsBox>
          <NextButton
            type="button"
            error={errorHO || errorCom || errorBT || errorST}
            disabled={errorHO || errorCom || errorBT || errorST}
            onClick={() => {
              setPage(1);
            }}
          >
            <span>{t('buttons.next')}</span>
            <img src={shevronRight} alt="arrow right next button" />
          </NextButton>
        </ButtonsBox>
      )}
      {page === 1 && (
        <ButtonsBox>
          <BackButton type="button" onClick={() => setPage(0)}>
            <img src={shevronLeft} alt="arrow left back button" />
            <span>{t('buttons.back')}</span>
          </BackButton>
          <NextButton
            type="button"
            error={errorHO || errorCom || errorBT || errorST}
            disabled={errorHO || errorCom || errorBT || errorST}
            onClick={() => {
              dispatch(postEmployeeSurveyData({ data: combinedData, token }));
              setPage(2);
            }}
          >
            <span>{t('buttons.send')}</span>
            <img src={shevronRight} alt="arrow right send button" />
          </NextButton>
        </ButtonsBox>
      )}
      {page === 2 && (
        <>
          <div />
          {sentEmployeeDataLoading ? null : (
            <ButtonsBox one>
              {sentEmployeeData ? (
                <NextButtonLink
                  href={`https://envirly.${lang === 'pl' ? 'pl' : 'com'}`}
                >
                  <span>{t('buttons.close')}</span>
                  <img src={shevronRight} alt="arrow right next button" />
                </NextButtonLink>
              ) : (
                <NextButton
                  type="button"
                  error={false}
                  disabled={false}
                  onClick={() => setPage(0)}
                >
                  <span>{t('employee-survey.survey.error.button')}</span>
                  <img src={shevronRight} alt="arrow right send button" />
                </NextButton>
              )}
            </ButtonsBox>
          )}
        </>
      )}
      {page === 3 && (
        <ButtonsBox>
          <NextButtonLink
            href={`https://envirly.${lang === 'pl' ? 'pl' : 'com'}`}
          >
            <span>{t('buttons.close')}</span>
            <img src={shevronRight} alt="arrow right next button" />
          </NextButtonLink>
        </ButtonsBox>
      )}
    </div>
  );
}
