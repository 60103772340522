import React from 'react';
import {
  StyledHeader,
  StyledTd,
} from '../../../Pages/Questionnaire/index.styles';
import { companiesColors } from '../../../Pages/SuperDashboard/data';
import { useTranslation } from 'react-i18next';
import CommentBtn from './commentBtn';
import TableButton from '../../TableButton';
import { toLocaleNumber } from '../../../../utils/helpers';

export default function ElecricityPreview({
  state,
  id,
  setActive,
  setState,
  language,
}: {
  state: any[];
  id: number;
  setActive: Function;
  setState: Function;
  language: string;
}) {
  const handleCopy = () => {
    const tempState = [...state];
    const item = tempState[id];

    tempState.splice(id, 0, item);
    setState(tempState);
    setActive(id);
  };
  const { t } = useTranslation();
  return (
    <>
      {state[id].emission_factor_is_known === false &&
        state[id].certified_sources_are_utilized === false && (
          <StyledHeader key={id}>
            <StyledTd width={30} color={companiesColors[id]}>
              <p>
                {toLocaleNumber(
                  state[id].energy_usage_data_factor_unknown
                    .spending_pln_or_kwh_per_period,
                  language
                )}
              </p>
              <CommentBtn state={state} id={id} />
            </StyledTd>
            <StyledTd width={20} color={companiesColors[id]}>
              {t(
                `dropdowns.${state[id].energy_usage_data_factor_unknown.unit}`
              )}
            </StyledTd>
            <StyledTd width={25} color={companiesColors[id]}>
              {state[id].emission_shared
                ? (state[id].emission_shared * 100).toFixed(2)
                : 0}
            </StyledTd>

            <StyledTd width={25} color={companiesColors[id]}>
              0
            </StyledTd>
            <StyledTd
              width={0}
              color={companiesColors[id]}
              lastCallCustom
              numberOfIcons="two"
            >
              <TableButton hoverText="copy" icon="copy" onClick={handleCopy} />
              <TableButton
                hoverText="edit"
                icon="edit"
                onClick={() => setActive(id)}
              />
            </StyledTd>
          </StyledHeader>
        )}
      {state[id].emission_factor_is_known === false &&
        state[id].certified_sources_are_utilized === true && (
          <StyledHeader key={id}>
            <StyledTd width={30} color={companiesColors[id]}>
              <p>
                {toLocaleNumber(
                  state[id].energy_usage_data_factor_unknown
                    .spending_pln_or_kwh_per_period,
                  language
                )}
              </p>
              <CommentBtn state={state} id={id} />
            </StyledTd>
            <StyledTd width={20} color={companiesColors[id]}>
              {t(
                `dropdowns.${state[id].energy_usage_data_factor_unknown.unit}`
              )}
            </StyledTd>
            <StyledTd width={25} color={companiesColors[id]}>
              {state[id].emission_shared
                ? (state[id].emission_shared * 100).toFixed(2)
                : 0}
            </StyledTd>

            <StyledTd width={25} color={companiesColors[id]}>
              {state[id].certified_energy_usage_data.certified_percentge}
            </StyledTd>
            <StyledTd
              width={0}
              color={companiesColors[id]}
              lastCallCustom
              numberOfIcons="two"
            >
              <TableButton hoverText="copy" icon="copy" onClick={handleCopy} />
              <TableButton
                hoverText="edit"
                icon="edit"
                onClick={() => setActive(id)}
              />
            </StyledTd>
          </StyledHeader>
        )}
      {state[id].emission_factor_is_known === true &&
        state[id].certified_sources_are_utilized === true && (
          <StyledHeader key={id}>
            <StyledTd width={30} color={companiesColors[id]}>
              <p>
                {toLocaleNumber(
                  state[id].energy_usage_data_factor_known.amount_per_period,
                  language
                )}
              </p>
              <CommentBtn state={state} id={id} />
            </StyledTd>
            <StyledTd width={20} color={companiesColors[id]}>
              {state[id].energy_usage_data_factor_known.unit_label}
            </StyledTd>
            <StyledTd width={25} color={companiesColors[id]}>
              {state[id].emission_shared
                ? (state[id].emission_shared * 100).toFixed(2)
                : 0}
            </StyledTd>

            <StyledTd width={25} color={companiesColors[id]}>
              {state[id].certified_energy_usage_data.certified_percentge}
            </StyledTd>
            <StyledTd
              width={0}
              color={companiesColors[id]}
              lastCallCustom
              numberOfIcons="two"
            >
              <TableButton hoverText="copy" icon="copy" onClick={handleCopy} />
              <TableButton
                hoverText="edit"
                icon="edit"
                onClick={() => setActive(id)}
              />
            </StyledTd>
          </StyledHeader>
        )}
      {state[id].emission_factor_is_known === true &&
        state[id].certified_sources_are_utilized === false && (
          <StyledHeader key={id}>
            <StyledTd width={30} color={companiesColors[id]}>
              <p>
                {toLocaleNumber(
                  state[id].energy_usage_data_factor_known.amount_per_period,
                  language
                )}
              </p>
              <CommentBtn state={state} id={id} />
            </StyledTd>
            <StyledTd width={20} color={companiesColors[id]}>
              {state[id].energy_usage_data_factor_known.unit_label}
            </StyledTd>
            <StyledTd width={25} color={companiesColors[id]}>
              {state[id].emission_shared
                ? (state[id].emission_shared * 100).toFixed(2)
                : 0}
            </StyledTd>

            <StyledTd width={25} color={companiesColors[id]}>
              0
            </StyledTd>
            <StyledTd width={0} color={companiesColors[id]} numberOfIcons="two">
              <TableButton hoverText="copy" icon="copy" onClick={handleCopy} />
              <TableButton
                hoverText="edit"
                icon="edit"
                onClick={() => setActive(id)}
              />
            </StyledTd>
          </StyledHeader>
        )}
    </>
  );
}
