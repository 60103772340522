import React from 'react';

export default function ArrowRightIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5005 30.885L26.3705 24L19.5005 17.115L21.6155 15L30.6155 24L21.6155 33L19.5005 30.885Z"
        fill="#606462"
      />
    </svg>
  );
}
