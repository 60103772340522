import { Divider, FormControl, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import NumberField from 'src/components/NumberField';

export const StyledTextField = styled(TextField)`
  margin-bottom: 12px;
  margin-top: 6px;
`;

export const StyledNumberField = styled(NumberField)`
  margin-bottom: 12px;
  margin-top: 6px;
`;

export const StyledFormControl = styled(FormControl)`
  margin-bottom: 12px;
  margin-top: 6px;
`;

export const StyledSectionHeader = styled(Typography)`
  margin-bottom: 12px;
`;

export const StyledSectionDivider = styled(Divider)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

StyledTextField.defaultProps = { fullWidth: true };
StyledNumberField.defaultProps = { fullWidth: true };
StyledFormControl.defaultProps = { fullWidth: true };
StyledSectionHeader.defaultProps = { variant: 'h3' };
