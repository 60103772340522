import welcome from 'src/images/surveys/welcome.png';

export default {
  form: {
    image: welcome,
  },
  end: {
    image: welcome,
  },
};
