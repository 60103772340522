export const extractErrors = (error: any) => {
  if (![400, 403, 409, 422].includes(error?.response?.status)) {
    return undefined;
  }
  const _errors =
    error?.response?.data?.error_dict ||
    error?.response?.data?.detail ||
    error?.response?.data?.errors ||
    [];
  if (_errors?.constructor === Object) {
    return Object.fromEntries(
      Object.entries(_errors).map(([key, value]) => [
        key,
        (value as string[])[0],
      ])
    );
  }
  if (!Array.isArray(_errors)) return undefined;
  const newErrors = Object.fromEntries(
    _errors.map((e: any) => [
      e?.loc?.pop() as string,
      (e?.msg as string) || 'error',
    ])
  );
  return newErrors;
};
export type ErrorEntry = {
  message: string;
  error_value: any;
};
export type SchemaErrors<T> = {
  [key in keyof T]?: ErrorEntry;
};
export const extractSchemaErrors = <T>(
  error: any,
  data: T
): SchemaErrors<T> => {
  const errorsDict = extractErrors(error);
  if (!errorsDict) return {};
  const errors = {} as SchemaErrors<T>;
  for (const [key, value] of Object.entries(errorsDict)) {
    errors[key as keyof T] = {
      message: value,
      error_value: data[key as keyof T],
    };
  }
  return errors;
};

export const mapError = <T, TProps>(
  errors: SchemaErrors<T> | null,
  key: keyof T,
  value: any
) => {
  let errorText: string | null | undefined;
  const entry = errors?.[key];
  if (entry !== undefined && entry.error_value === value)
    errorText = entry.message;
  return errorText;
};

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export class ErrorBuilder<T> {
  public errors = {} as SchemaErrors<T>;
  constructor(private schema: T) {}

  public addError(key: keyof T, message: string) {
    if (this.errors[key] === undefined)
      this.errors[key] = { message, error_value: this.schema[key] };
    return this;
  }

  public checkRule<K extends keyof T>(
    keys: K[],
    rule: (value: T[K], schema: T) => boolean,
    messageKey: string
  ) {
    for (const key of keys) {
      if (!rule(this.schema[key], this.schema)) {
        this.addError(key, messageKey);
      }
    }
    return this;
  }

  public checkTruthy(keys: (keyof T)[], messageKey: string) {
    return this.checkRule(keys, (value) => !!value, messageKey);
  }

  public checkNonNull(keys: (keyof T)[], messageKey: string) {
    return this.checkRule(
      keys,
      (value) => value !== null && value !== undefined,
      messageKey
    );
  }

  public checkEmail(keys: (keyof T)[], messageKey: string) {
    return this.checkRule(
      keys,
      (value) => emailRegex.test(value as string),
      messageKey
    );
  }

  public build() {
    return this.errors;
  }

  public buildIfAny() {
    if (Object.keys(this.errors).length === 0) return null;
    return this.errors;
  }
}
