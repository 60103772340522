import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, usePlatform } from 'src/redux-file/hooks';
import { setActiveOrganizationId } from 'src/redux-file/platform/slice';

const useSearch = () => new URLSearchParams(useLocation().search);

const usePreselectedOrganizationId = (): Number =>
  Number(useSearch().get('orgId') || undefined);

interface Props {
  children?: React.ReactElement;
}

export default function ActionParamsProvider(props: Props) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { activeOrganizationId, organizations } = usePlatform();

  const orgId = usePreselectedOrganizationId();
  const org = organizations?.find((o) => o.id === orgId);

  React.useEffect(() => {
    if (!!org) {
      dispatch(setActiveOrganizationId(org.id));
      // delete orgId from the URL
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('orgId');
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [org]);

  const isRenderReady = !org || activeOrganizationId === orgId;
  if (!isRenderReady) return null;
  return props.children as React.ReactElement;
}
