import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import themeColors from '../../../../../../utils/theme';

export const StyledDialogContent = styledMUI(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    > .content-wrapper:not(.file-wrapper) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 55px 0 55px 0;
      cursor: default;
      > img {
        width: 215px;
        margin-bottom: 15px;
      }
      > button {
        background: #38414F;
        border: 1px solid #38414F;
        border-radius: 24px;
        color: #fff;
        padding: 0 26px;
        height: 48px;
        align-items: center;
        margin-bottom: 15px;
        > span {
          font-weight: 700;
          font-size: 18px;
          letter-spacing: 0.01em;
          color: #FFFFFF;
        }
      }
      > div {
        font-weight: 500;
        font-size: 18px;
        letter-spacing: 0.03em;
        color: #606462;
      }
    }
    > div {
      display: flex;
      flex-direction: column;
    }
    > a  {
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.03em;
      color: #388276;
      cursor: pointer;
      text-align: center;
      text-decoration: none;
    }
  }
`;

export const Button = styled.button`
  background: #38414f;
  border: 1px solid #38414f;
  border-radius: 24px;
  padding: 0 26px;
  height: 48px;
  align-items: center;
  margin-bottom: 15px;
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.01em;

  > span {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.01em;
    color: #ffffff;
  }
`;

export const StyledDialog = styledMUI(Dialog)`  
  & .MuiPaper-root {
    width: 600px;
    height: 500px;
    background: #FFFFFF;
    border: 1px solid #D9DFDD;
    border-radius: 16px;
    padding: 10px 10px 25px 10px;
    > .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 20px;
      > span:first-of-type {
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: 0.02em;
        color: #2F2F2F;
      }
    }
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  background-color: white;
  border-radius: 28px;
  margin-left: auto;
  padding: 0;
  height: 48px;
  width: 48px;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transition:
      background-color,
      border 0.3s ease-in-out;
    background-color: #00000010;
  }
`;

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px 0 65px 0;
  > :nth-child(2) {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: #38414f;
    cursor: pointer;
  }
  > :nth-child(3) {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.03em;
    color: #00000060;
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  height: 80px;
  width: 80px;
  margin-bottom: 11px;
`;

export const UploadedTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #38414f;
  margin-top: 35px;
`;

export const ErrorBox = styled.div`
  margin-top: 24px;
`;

export const Error = styled.p`
  text-align: center;
  color: ${themeColors.redParagraphs};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
`;
