import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// eslint-disable-next-line import/prefer-default-export
export const StyledPanel = styled(Box)`
  padding: 36px 32px;
  border-radius: 16px;
  border: 1px solid #ccc;
  background: #fff;
`;
