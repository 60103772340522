import React from 'react';

export default function ShowMoreIcon({ show }: { show: boolean }) {
  return (
    <svg
      style={{
        transform: `rotate(${show ? '180' : '0'}deg)`,
        marginLeft: 8,
        position: 'relative',
        transition: 'transform 300ms',
      }}
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.41 0L6 4.32659L10.59 0L12 1.33198L6 7L0 1.33198L1.41 0Z"
        fill="currentColor"
      />
    </svg>
  );
}
