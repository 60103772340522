import React from 'react';

import { Box, Typography } from '@mui/material';
import Introduction from './Introduction';
import ForImporters from './ForImporters';
import InviteSuppliers from './InviteSuppliers';

export default function Home() {
  return (
    <Box>
      <Typography variant="h1">Welcome to Envirly CBAM</Typography>
      <br />
      <br />
      <Introduction />
      <br />
      <br />
      <ForImporters />
      <br />
      <br />
      <InviteSuppliers />
    </Box>
  );
}
