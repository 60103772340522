import { styled as styledMUI } from '@mui/material/styles';
import { Colors } from 'src/Lca/components/Button/index.types';
import { StyledButton } from 'src/Lca/components/Button/index.style';

export const StyledNode = styledMUI('div')<{ colors?: Colors }>`
  border: 2px solid ${(props) => props?.colors?.default || '#e0e0e0'};
  background-color: ${(props) => props?.colors?.default};
  border-radius: ${(props) => (!!props?.colors ? '24px' : '10px')};
  box-shadow: ${(props) =>
    props?.colors?.shadow ? `0 3px 0px ${props?.colors?.shadow}` : 'none'};
  margin-bottom: 10px;
  margin-top: 20px;
  
`;

export const StyledObjectNode = styledMUI(StyledButton)`
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
  display: block;
  justify-content: left;
  text-align: left;
`;

export const StyledProcessHeader = styledMUI('div')`
  cursor: pointer;
  border: 2px solid #e0e0e0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #e0e0e0;
  color: #4D4D4D;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  text-wrap: wrap;
  word-break: break-word;
`;

export const StyledProcessContent = styledMUI('div')`
  padding: 0px 5px;
`;

export const StyledObjectText = styledMUI('div')`
  font-family: Poppins; 
  font-size: 13px;
  font-weight: 600;
  text-wrap: wrap;
  word-break: break-word;
`;

export const StyledObjectFooter = styledMUI('div')`
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
