import { Paper, Typography, styled } from '@mui/material';

export const StyledTextPopper = styled(Typography)`
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 160% */
  letter-spacing: 0.3px;
  color: '#6E6E6E';
`;

export const StyledTitlePopper = styled(Typography)`
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.4px;
  margin-bottom: 16px;
`;

export const StyledPopperPaper = styled(Paper)`
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2);
  width: 600px;
  border-radius: 16px;
  padding: 32px;
  margin-top: 6px;
`;
