import React from 'react';

export default function wasteIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M6 19.6347C6 20.7547 6.9 21.671 8 21.671H16C17.1 21.671 18 20.7547 18 19.6347V7.41648H6V19.6347ZM19 4.36193H15.5L14.5 3.34375H9.5L8.5 4.36193H5V6.39829H19V4.36193Z"
        fill="currentColor"
      />
    </svg>
  );
}
