import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import add from 'date-fns/add';
import styles from './styles.module.css';
import Types from '../../components/Targets/Types';
import { useAppDispatch } from '../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import Box from '../../components/Box';
import { createTarget, updateTarget } from '../../../redux-file/thunk';
import 'react-calendar/dist/Calendar.css';
import icon from '../../../images/plus-white.png';
import RadioGroupTarget from '../../components/Targets/RadioGroup';
import BigCalendar from '../../components/BigCalendar';
import {
  StyledAlert,
  Cell,
  Row,
  ButtonFooterContainer,
  Breadcrumbs,
  Title,
  TitleHeader,
  BackButton,
} from './index.styles';
import { timeConverter, periodToDate } from '../../../utils/timeConverter';
import {
  scope1Items,
  scope2Items,
  scope3Items,
  generalItems,
} from './emissionsNames';
import {
  PageContainer,
  SectionGroupContainer,
} from '../Questionnaire/index.styles';
import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { OrganizationTag, hasTag } from '../../../utils/tags';

export interface FieldValueProps {
  id: number;
  value: any;
}

export default function AddReductionTarget() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { id: targetId } = useParams<{ id: string }>();

  const organization = useGhgOrganization();
  const hideScope3 = hasTag(organization, OrganizationTag.HideScope3);
  const reportingFrequency = organization.co2.reporting_freq;

  const [selectedTarget, setSelectedTarget] = useState<
    'total' | 'per_income' | 'per_employee'
  >('total');

  const [selectedTypeEmission, setSelectedTypeEmission] = useState<
    string | null
  >(null);

  const [error, setError] = useState(false);
  const [comment, setComment] = useState<string>('');

  const startingDate = () => {
    let date;
    switch (reportingFrequency) {
      case 'M':
        date = add(new Date(), { months: 1 });
        break;
      case 'Y':
        date = add(new Date(), { months: 12 });
        break;
      case 'Q':
        date = add(new Date(), { months: 3 });
        break;
      default:
        return add(new Date(), { months: 1 });
    }

    return date;
  };

  const [endPeriod, setEndPeriod] = useState<Date | null>(startingDate());

  const [targetPercent, setTargetPercent] = useState<{
    [key: string]: { text: string; range: number | null; desc: string | null };
  }>({
    total: {
      text: t('targets.method.total'),
      range: null,
      desc: null,
    },
    per_employee: {
      text: t('targets.method.per_employee'),
      range: null,
      desc: null,
    },
    per_income: {
      text: t('targets.method.per_income'),
      range: null,
      desc: null,
    },
  });

  useQuery(
    ['target', targetId],
    () =>
      client
        .get(`/web/v2/target/${targetId}?organization_id=${organization.id}`)
        .then((res) => {
          const target = res?.data?.organization_reduction_target || {};
          setSelectedTarget(target.method);
          setSelectedTypeEmission(target.domain);
          setEndPeriod(periodToDate(target.end_period, reportingFrequency));
          setComment(target.comment);
          const newTargetPercent = {
            ...targetPercent,
            [selectedTarget]: {
              desc: targetPercent[selectedTarget].desc,
              text: targetPercent[selectedTarget].text,
              range: +target.target_value,
            },
          };
          setTargetPercent(newTargetPercent);
          return res.data;
        }),
    { enabled: !!targetId }
  );

  const handleSubmit = () => {
    if (!!selectedTypeEmission && selectedTypeEmission !== '') {
      setError(false);
      if (!!targetId) {
        dispatch(
          updateTarget({
            commit: true,
            orgId: organization.id,
            targetId,
            method: selectedTarget,
            end_period: timeConverter(endPeriod, reportingFrequency),
            domain: selectedTypeEmission,
            target_value: targetPercent[selectedTarget].range ?? undefined,
            comment: comment === '' ? undefined : comment,
          })
        ).then(() => navigate('/ghg/target'));
      } else {
        dispatch(
          createTarget({
            orgId: organization.id,
            commit: true,
            method: selectedTarget,
            start_period: timeConverter(new Date(), reportingFrequency),
            end_period: timeConverter(endPeriod, reportingFrequency),
            target_value: +(targetPercent[selectedTarget].range || 0),
            comment,
            domain: selectedTypeEmission,
          })
        ).then(() => navigate('/ghg/target'));
      }
    }
  };

  return (
    <PageContainer>
      <SectionGroupContainer>
        {error && (
          <StyledAlert severity="error">{t('targets.error')}</StyledAlert>
        )}

        <Breadcrumbs>
          <Link to="/ghg/target/">{t('menu.reduction-targets')}</Link>
          <span>{'>'}</span>
          <div>{t(`targets.add-target`)}</div>
        </Breadcrumbs>
        <TitleHeader>
          <Title>{t(`targets.add-target`)}</Title>
        </TitleHeader>

        <Row>
          <Cell>
            <Types
              section1Items={generalItems.filter(
                (item) => !(hideScope3 && item.value === 'scope_3')
              )}
              section2Items={scope1Items}
              section3Items={scope2Items}
              section4Items={hideScope3 ? undefined : scope3Items}
              setSelectedValue={setSelectedTypeEmission}
              selectedValue={selectedTypeEmission}
            />
          </Cell>
        </Row>

        <Row>
          <Cell>
            <Box text="targets.end-date">
              <BigCalendar
                startDate
                currDate={endPeriod ?? startingDate()}
                setDate={setEndPeriod}
                reportingPeriod={reportingFrequency}
              />
            </Box>
          </Cell>

          <Cell>
            <Box text="targets.percent">
              <div>
                <div className={styles.percentBox}>
                  <div className={styles.textinputwrap}>
                    <input
                      type="number"
                      value={targetPercent[selectedTarget].range ?? ''}
                      placeholder={t('targets.emission-target-placeholder')}
                      onChange={(e) => {
                        if (
                          e.target.value === '' ||
                          parseInt(e.target.value, 10) >= 0
                        ) {
                          const newTargetPercent = {
                            ...targetPercent,
                            [selectedTarget]: {
                              desc: targetPercent[selectedTarget].desc,
                              text: targetPercent[selectedTarget].text,
                              range:
                                e.target.value === '' ? 0 : +e.target.value,
                            },
                          };
                          setTargetPercent(newTargetPercent);
                        }
                      }}
                    />
                  </div>
                  <RadioGroupTarget
                    {...{ targetPercent, selectedTarget, setSelectedTarget }}
                  />
                </div>
              </div>
            </Box>
          </Cell>
        </Row>

        <Row>
          <Cell>
            <Box text="targets.comment-opt">
              <div>
                <textarea
                  className={styles.textarea}
                  rows={20}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </Box>
          </Cell>
        </Row>
        <ButtonFooterContainer>
          <BackButton
            to={
              targetId === undefined ? '/ghg/target' : `/ghg/target/${targetId}`
            }
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.00016 0L7.41016 1.41L2.83016 6L7.41016 10.59L6.00016 12L0.000156403 6L6.00016 0Z"
                fill="#388276"
              />
            </svg>
            <span>{t('targets.back')}</span>
          </BackButton>
          <button
            className={`${styles.addbutton} ${styles.button}`}
            type="button"
            onClick={handleSubmit}
          >
            <img className={styles.icon} src={icon} alt="plus icon" />
            <span>
              {targetId === undefined
                ? t('targets.add-target')
                : t('targets.save')}
            </span>
          </button>
        </ButtonFooterContainer>
      </SectionGroupContainer>
    </PageContainer>
  );
}
