import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import { AutocompleteFreesolo, Select } from '../fields';
import { type_of_builidng, year_of_construction } from './data';
import { useAppDispatch, useAppSelector } from '../../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import {
  getAssetsFranchisesAndInvestmentsData,
  getAssetsFranchisesAndInvestmentsEmissionSharesData,
  putAssetsFranchisesAndInvestmentsData,
} from '../../../../redux-file/questionnaires/thunks';
import { timeConverter } from '../../../../utils/timeConverter';
import { Overlay } from '../Title/index.styles';
import FormButtons from '../FormButtons';
import { CheckboxContainer, Form, FormBox } from './index.styles';
import OneValueCheckboxField from '../../../../components/CheckboxField';

export default function UpstreamLeasedBuildingsForm({
  state,
  setState,
  id,
  active,
  setActive,
  combinedData,
  variant,
  setNewForm,
}: {
  setNewForm: Function;
  state: {
    name: string;
    comment?: string | null;
    emission_amount_is_known: boolean;
    leased_building_data_emission_unknown: {
      type_of_builidng: string;
      leased_area_m2: string;
      year_of_construction: string;
    };
    leased_building_data_emission_known: {
      amount_kg_eq_co2_in_period: string;
    };
  }[];
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  combinedData: any;
  variant: 'u' | 'd';
}) {
  const { selectedDate } = useAppSelector(
    (redux_state) => redux_state.questionnaire
  );
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const { t } = useTranslation();
  const translationVariant =
    variant === 'u'
      ? 'upstream_leased_buildings'
      : 'downstream_leased_buildings';
  const dispatch = useAppDispatch();
  const [
    amount_kg_eq_co2_in_period_error_state,
    set_amount_kg_eq_co2_in_period_error_state,
  ] = useState(false);
  let amount_kg_eq_co2_in_period_error = false;
  const amount_kg_eq_co2_in_period_validation = (value: string) => {
    if (value === '') amount_kg_eq_co2_in_period_error = true;
    return amount_kg_eq_co2_in_period_error;
  };
  const [type_of_builidng_error_state, set_type_of_builidng_error_state] =
    useState(false);
  let type_of_builidng_error = false;
  const type_of_builidng_validation = (value: string) => {
    if (value === '') type_of_builidng_error = true;
    return type_of_builidng_error;
  };

  const [leased_area_m2_error_state, set_leased_area_m2_error_state] =
    useState(false);
  let leased_area_m2_error = false;
  const leased_area_m2_validation = (value: string) => {
    if (value === '') leased_area_m2_error = true;
    return leased_area_m2_error;
  };

  const [
    year_of_construction_error_state,
    set_year_of_construction_error_state,
  ] = useState(false);
  let year_of_construction_error = false;
  const year_of_construction_validation = (value: string) => {
    if (value === '') year_of_construction_error = true;
    return year_of_construction_error;
  };

  const setKnowEmission = () => {
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: !tempForm.emission_amount_is_known,
      leased_building_data_emission_known: tempForm.emission_amount_is_known
        ? tempForm.leased_building_data_emission_known
        : {
            amount_kg_eq_co2_in_period: '',
          },
      leased_building_data_emission_unknown: tempForm.emission_amount_is_known
        ? {
            leased_area_m2: '',
            type_of_builidng: '',
            year_of_construction: '',
          }
        : tempForm.leased_building_data_emission_unknown,
    };
    newForms[id] = tempForm;
    setState(newForms);
  };
  const handleName = (value: string) => {
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      ...tempForm,
      name: value,
    };
    newForms[id] = tempForm;
    setState(newForms);
  };

  const handleAmount = (value: string) => {
    amount_kg_eq_co2_in_period_validation(value);
    set_amount_kg_eq_co2_in_period_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: tempForm.emission_amount_is_known,
      leased_building_data_emission_known: {
        amount_kg_eq_co2_in_period: value,
      },
      leased_building_data_emission_unknown:
        tempForm.leased_building_data_emission_unknown,
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_amount_kg_eq_co2_in_period_error_state(true);
  };

  const handleArea = (value: string) => {
    leased_area_m2_validation(value);
    set_leased_area_m2_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: tempForm.emission_amount_is_known,
      leased_building_data_emission_known:
        tempForm.leased_building_data_emission_known,
      leased_building_data_emission_unknown: {
        leased_area_m2: value,
        type_of_builidng:
          tempForm.leased_building_data_emission_unknown.type_of_builidng,
        year_of_construction:
          tempForm.leased_building_data_emission_unknown.year_of_construction,
      },
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_leased_area_m2_error_state(true);
  };
  const handleBuildingType = (value: string) => {
    type_of_builidng_validation(value);
    set_type_of_builidng_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: tempForm.emission_amount_is_known,
      leased_building_data_emission_known:
        tempForm.leased_building_data_emission_known,
      leased_building_data_emission_unknown: {
        leased_area_m2:
          tempForm.leased_building_data_emission_unknown.leased_area_m2,
        type_of_builidng: value,
        year_of_construction:
          tempForm.leased_building_data_emission_unknown.year_of_construction,
      },
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_type_of_builidng_error_state(true);
  };

  const handleSend = (data: any) => {
    if (
      !leased_area_m2_error &&
      !type_of_builidng_error &&
      !amount_kg_eq_co2_in_period_error &&
      !year_of_construction_error
    ) {
      dispatch(
        putAssetsFranchisesAndInvestmentsData({
          period: timeConverter(selectedDate, reportingPeriod),
          data,
        })
      ).then(() => {
        dispatch(
          getAssetsFranchisesAndInvestmentsData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
        dispatch(
          getAssetsFranchisesAndInvestmentsEmissionSharesData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
      });
      setActive(undefined);
    }
  };

  const defineErrors = async () => {
    if (!state[id].emission_amount_is_known) {
      if (
        state[id].leased_building_data_emission_unknown.type_of_builidng === ''
      ) {
        set_type_of_builidng_error_state(true);
        type_of_builidng_error = true;
      }
      if (
        state[id].leased_building_data_emission_unknown.leased_area_m2 === ''
      ) {
        set_leased_area_m2_error_state(true);
        leased_area_m2_error = true;
      }
      if (
        state[id].leased_building_data_emission_unknown.year_of_construction ===
        ''
      ) {
        set_year_of_construction_error_state(true);
        year_of_construction_error = true;
      }
    }
    if (state[id].emission_amount_is_known) {
      if (
        state[id].leased_building_data_emission_known
          .amount_kg_eq_co2_in_period === ''
      ) {
        set_amount_kg_eq_co2_in_period_error_state(true);
        amount_kg_eq_co2_in_period_error = true;
      }
    }
  };

  const handleYear = (value: string) => {
    year_of_construction_validation(value);
    set_year_of_construction_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: tempForm.emission_amount_is_known,
      leased_building_data_emission_known:
        tempForm.leased_building_data_emission_known,
      leased_building_data_emission_unknown: {
        leased_area_m2:
          tempForm.leased_building_data_emission_unknown.leased_area_m2,
        type_of_builidng:
          tempForm.leased_building_data_emission_unknown.type_of_builidng,
        year_of_construction: value,
      },
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_year_of_construction_error_state(true);
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    const tempcombinedData = {
      upstream_leased_buildings:
        variant === 'u' ? tempState : combinedData.upstream_leased_buildings,
      upstream_leased_assets: combinedData.upstream_leased_assets,
      downstream_leased_buildings:
        variant === 'd' ? tempState : combinedData.downstream_leased_buildings,
      downstream_leased_assets: combinedData.downstream_leased_assets,
      franchises: combinedData.franchises,
      investments: combinedData.investments,
    };

    handleSend(tempcombinedData);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              handleSend(combinedData);
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <CheckboxContainer>
            <OneValueCheckboxField
              label={t('assets-and-other.upstream_leased_buildings.form.check')}
              setValue={() => setKnowEmission()}
              value={state[id].emission_amount_is_known}
            />
          </CheckboxContainer>
          {state[id].emission_amount_is_known ? (
            <>
              <FormBox variant="100">
                <AutocompleteFreesolo
                  active
                  index={1}
                  max={1}
                  options={[]}
                  label={t(
                    'assets-and-other.upstream_leased_buildings.form.name'
                  )}
                  error={false}
                  value={state[id].name}
                  setValue={(e: string) => handleName(e)}
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  type="factor"
                  label={t(
                    `assets-and-other.${translationVariant}.form.amount_kg_eq_co2_in_period`
                  )}
                  active
                  error={amount_kg_eq_co2_in_period_error_state}
                  index={1}
                  value={
                    state[id].leased_building_data_emission_known
                      .amount_kg_eq_co2_in_period
                  }
                  setValue={(e: string) => handleAmount(e)}
                />
              </FormBox>
            </>
          ) : (
            <>
              <FormBox variant="50">
                <AutocompleteFreesolo
                  active
                  index={1}
                  max={1}
                  options={[]}
                  label={t(
                    'assets-and-other.upstream_leased_buildings.form.name'
                  )}
                  error={false}
                  value={state[id].name}
                  setValue={(e: string) => handleName(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <Select
                  active
                  index={6}
                  max={4}
                  options={type_of_builidng}
                  label={t(
                    'assets-and-other.upstream_leased_buildings.form.type_of_builidng'
                  )}
                  error={type_of_builidng_error_state}
                  value={
                    state[id].leased_building_data_emission_unknown
                      .type_of_builidng
                  }
                  setValue={(value: string) => handleBuildingType(value)}
                />
              </FormBox>
              <FormBox variant="50">
                <TextFieldNumber
                  label={t(`assets-and-other.${translationVariant}.form.area`)}
                  active
                  error={leased_area_m2_error_state}
                  index={1}
                  value={
                    state[id].leased_building_data_emission_unknown
                      .leased_area_m2
                  }
                  setValue={(e: string) => handleArea(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <Select
                  active
                  index={6}
                  max={4}
                  options={year_of_construction}
                  label={t(
                    'assets-and-other.upstream_leased_buildings.form.year'
                  )}
                  error={year_of_construction_error_state}
                  value={
                    state[id].leased_building_data_emission_unknown
                      .year_of_construction
                  }
                  setValue={(value: string) => handleYear(value)}
                />
              </FormBox>
            </>
          )}
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={
              (!state[id].emission_amount_is_known &&
                state[id].leased_building_data_emission_unknown
                  .type_of_builidng !== '' &&
                state[id].leased_building_data_emission_unknown
                  .leased_area_m2 !== '' &&
                state[id].leased_building_data_emission_unknown
                  .year_of_construction !== '') ||
              (state[id].emission_amount_is_known &&
                state[id].leased_building_data_emission_known
                  .amount_kg_eq_co2_in_period !== '')
            }
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
