import { useQuery } from 'react-query';
import { useGhgOrganization } from 'src/common/hooks';
import { timeConverter } from 'src/utils/timeConverter';
import { TransportationSummarySchema } from './types';
import { client } from 'src/utils/api-client';
import { saveBlob } from 'src/utils/helpers';

const summaryUrl = 'web/v2/questionnaires_summaries/transportation_summary';

export type TransportationSummaryHookProps = {
  startTime: Date | null;
  endTime: Date | null;
};

export const useTransportationSummary = ({
  startTime,
  endTime,
}: TransportationSummaryHookProps) => {
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const periodParams =
    startTime && endTime
      ? {
          start_period: timeConverter(startTime, reportingPeriod),
          end_period: timeConverter(endTime, reportingPeriod),
        }
      : {};

  const queryParams = { ...periodParams, organization_id: organization.id };

  const query = useQuery(['transportationSummary', queryParams], () =>
    client
      .get<TransportationSummarySchema>(summaryUrl, {
        params: queryParams,
      })
      .then((r) => r.data)
  );

  const downloadCsv = () => {
    client
      .get(summaryUrl, {
        params: { ...queryParams, view: 'csv' },
        responseType: 'blob',
      })
      .then((response) => {
        saveBlob(response.data, 'transportation_summary.csv');
      });
  };

  return {
    queryParams,
    query,
    downloadCsv,
  };
};
