import React, { useEffect, useState } from 'react';
import { GroupChecklist } from './GroupChecklist';
import { groupDataColor } from '../../../Pages/SuperDashboard/data';
import CarbonFootprintPerGroup from './CarbonFootprintPerGroup';
import PageLoading from '../../../../components/PageLoading';
import NoData from '../NoData';
import SomethingWentWrong from '../../../../components/SomethingWentWrong';

interface Props {
  loading: boolean;

  errorType?: string;
  errorMsg?: string;
  data: {
    name: string;
    value: {
      [key: string]: {
        [key: string]: number;
      };
    };
    error_msg?: string;
    error_type?: string;
  };
}

export default function GroupChart({
  data,
  loading,
  errorType,
  errorMsg,
}: Props) {
  const companies = Object.entries(data).map((el) => ({
    name: el[0],
    value: el[1],
    isSelected: true,
  }));

  const [selectedCompaniesG1, setSelectedCompaniesG1] = useState(
    companies.filter((el) => el.isSelected === true)
  );

  const [selectedCompaniesG2, setSelectedCompaniesG2] = useState(
    companies.filter((el) => el.isSelected === true)
  );

  const [selectedCompaniesG3, setSelectedCompaniesG3] = useState(
    companies.filter((el) => el.isSelected === true)
  );

  useEffect(() => {
    const companiesTemp = Object.entries(data).map((el) => ({
      name: el[0],
      value: el[1],
      isSelected: true,
    }));

    const companiesTemp2 = Object.entries(data).map((el) => ({
      name: el[0],
      value: el[1],
      isSelected: true,
    }));

    const companiesTemp3 = Object.entries(data).map((el) => ({
      name: el[0],
      value: el[1],
      isSelected: true,
    }));

    setSelectedCompaniesG1(
      companiesTemp.filter((el) => el.isSelected === true)
    );
    setSelectedCompaniesG2(
      companiesTemp2.filter((el) => el.isSelected === true)
    );
    setSelectedCompaniesG3(
      companiesTemp3.filter((el) => el.isSelected === true)
    );
  }, [data]);

  if (loading) return <PageLoading />;

  if (!!errorType) {
    if (errorType === 'no-data') return <NoData errorMessage={errorMsg} />;
    else return <SomethingWentWrong errorMessege={errorMsg} />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <GroupChecklist
        colors={groupDataColor}
        selectedCompaniesG1={selectedCompaniesG1}
        selectedCompaniesG2={selectedCompaniesG2}
        selectedCompaniesG3={selectedCompaniesG3}
        setSelectedCompaniesG1={setSelectedCompaniesG1}
        setSelectedCompaniesG2={setSelectedCompaniesG2}
        setSelectedCompaniesG3={setSelectedCompaniesG3}
      />
      <CarbonFootprintPerGroup
        data1={selectedCompaniesG1}
        data2={selectedCompaniesG2}
        data3={selectedCompaniesG3}
        isLoading={loading}
        colors={groupDataColor}
      />
    </div>
  );
}
