import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  EntryBox,
  Title,
  Box,
  BoxBar,
  StyledTextField,
  StyledEntryTextField,
  Button,
  CommentContent,
  EntryButton,
} from './index.styles';

export default function FormComment({
  state,
  setState,
  id,
  setActiveFormComment,
  commentBtnRef,
}: {
  state: any;
  setState: Function;
  id: number;
  setActiveFormComment: Function;
  commentBtnRef: React.RefObject<any>;
}) {
  const { t } = useTranslation();
  const [tmpComment, setTmpComment] = useState(state[id].comment);
  const [editing, setEditing] = useState(false);
  const modalRef = useRef<any>(null);
  const editBoxRef = useRef<any>(null);
  const deleteBtnRef = useRef<any>(null);
  const closeBtnRef = useRef<any>(null);

  const changeComment = (comment: string) => {
    const tmpForms = [...state];
    const newForm = { ...tmpForms[id], comment };
    tmpForms[id] = newForm;
    setState(tmpForms);
  };

  const handleRemoveComment = () => {
    setTmpComment('');
    setEditing(false);
    changeComment('');
  };
  const handleCloseFormComment = () => {
    setActiveFormComment(false);
  };

  useEffect(() => {
    if (editing) setTmpComment(state[id].comment);
  }, [editing]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !commentBtnRef.current.contains(event.target)
      ) {
        if (editing) {
          changeComment(tmpComment);
        }
        setActiveFormComment(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef, editing, tmpComment]);

  return (
    <Modal ref={modalRef}>
      {!state[id].comment && (
        <EntryBox>
          <StyledEntryTextField
            id="newCommentText"
            placeholder="Add a comment"
            onChange={(event) => setTmpComment(event.target.value)}
          />
          <EntryButton type="button" onClick={() => changeComment(tmpComment)}>
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.01 33L37 24L16.01 15L16 22L31 24L16 26L16.01 33Z"
                fill={tmpComment ? '#388276' : '#B6BBB9'}
              />
            </svg>
          </EntryButton>
        </EntryBox>
      )}
      {state[id].comment && (
        <Box
          onClick={(e) => {
            if (
              editing &&
              editBoxRef.current &&
              !editBoxRef.current.contains(e.target) &&
              deleteBtnRef.current &&
              !deleteBtnRef.current.contains(e.target) &&
              closeBtnRef.current &&
              !closeBtnRef.current.contains(e.target)
            ) {
              changeComment(tmpComment);
              setEditing(false);
            }
          }}
        >
          <BoxBar>
            <Title>{t('comment')}</Title>
            <Button
              type="button"
              ref={deleteBtnRef}
              onClick={() => handleRemoveComment()}
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 31C18 32.1 18.9 33 20 33H28C29.1 33 30 32.1 30 31V19H18V31ZM20 21H28V31H20V21ZM27.5 16L26.5 15H21.5L20.5 16H17V18H31V16H27.5Z"
                  fill="#606462"
                />
                <rect x="22" y="23" width="1" height="6" fill="#606462" />
                <rect x="25" y="23" width="1" height="6" fill="#606462" />
              </svg>
            </Button>
            <Button
              type="button"
              ref={closeBtnRef}
              onClick={() => handleCloseFormComment()}
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31 18.41L29.59 17L24 22.59L18.41 17L17 18.41L22.59 24L17 29.59L18.41 31L24 25.41L29.59 31L31 29.59L25.41 24L31 18.41Z"
                  fill="#606462"
                />
              </svg>
            </Button>
          </BoxBar>
          {!editing && (
            <div onClick={() => setEditing(true)}>
              <CommentContent>{state[id].comment}</CommentContent>
            </div>
          )}
          {editing && (
            <StyledTextField
              id="editCommentText"
              multiline
              value={tmpComment}
              ref={editBoxRef}
              onChange={(event) => setTmpComment(event.target.value)}
            />
          )}
        </Box>
      )}
    </Modal>
  );
}
