const dialogTitleTypography = {
  // Figma's <h3> as of 2024-06-06
  fontSize: '24px',
  fontWeight: 600, // semibold
  lineHeight: '34px',
  letterSpacing: '0.48px',
};

const dialogContentTypography = {
  // Figma's <p> as of 2024-06-06
  fontSize: '16px',
  fontWeight: 400, // normal
  lineHeight: '26px',
  letterSpacing: '0.48px',
};

const muiDialogPadding = '24px';

const dialogTheme = {
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        align: 'left',
        ...dialogTitleTypography,
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          // apply dialogContent typography for deafult variant of Typography when it is used inside DialogContent
          '& .MuiTypography-body1': dialogContentTypography,
          paddingLeft: muiDialogPadding,
          paddingRight: muiDialogPadding,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: muiDialogPadding,
        },
      },
    },
  },
};

export default dialogTheme;
