import { useMutation, useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import * as types from 'src/Ghg/CodeOfConductSurvey/types';

export const useCodeOfConductSurveys = (organizationId: number) => {
  const orgEndpointUrl = `/web/ghg-suppliers/${organizationId}/code-of-conduct-surveys`;
  const endpointUrl = '/web/ghg-suppliers/code-of-conduct-surveys';
  const query = useQuery(
    ['ghg-supplier', 'code-of-conduct', { organizationId }],
    () =>
      client
        .get<types.GhgSupplierCodeOfConductSurveyOutSchema[]>(orgEndpointUrl)
        .then((response) => response.data)
  );

  const create = useMutation({
    mutationFn: (data: types.GhgSupplierCodeOfConductSurveyInSchema) =>
      client.post<types.GhgSupplierCodeOfConductSurveyOutSchema>(
        orgEndpointUrl,
        data
      ),
    onSuccess: () => query.refetch(),
  });

  const sendEmail = useMutation({
    mutationFn: (id: number) =>
      client
        .post(`${endpointUrl}/${id}/send-email`)
        .then((response) => response.data),
    onSuccess: () => query.refetch(),
  });

  const remove = useMutation({
    mutationFn: (id: number) => client.delete(`${endpointUrl}/${id}`),
    onSuccess: () => query.refetch(),
  });

  return {
    query,
    create,
    sendEmail,
    remove,
  };
};
