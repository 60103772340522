/*
TODO:
- https://lodash.com/docs/4.17.15#set for state updates?
- installation interface
- reafctor numberfield
- precursors linking to another production processess
- "contract for components to set calculated values in the data"
- inputs validation and required inputs

- production process input

- [ ] lepsze ostylowanie elementów które prezentowałem żeby wyglądały na "skończone"
  - Ula mogłaby zasugerować

- [ ] osadzenie całości "w aplikacji", tj. logowanie, sidebar, etc. żeby całość wyglądała "produkcyjnie"
- [ ] zapisywanie danych, żeby nie ginęły po odświeżeniu
  - backend i prosty store jsonów per user/org?

- [ ] wyliczanie metryk które trzeba wyliczyć (wpisywanie wszystkich danych wejściowych jest w większości wdrożone)
  - dobrze by było
- [ ] sprawienie wrażenia możliwości wygenerowania raportu xsd
  - można w postaci tekstowej

- [ ] "ankiety"
    - [ ] podstawowa wersja "wysyłania ankiet" w których operator podaje te same dane co importer
      - pod linkiem
      - otwórz/zamknij
      - importuj
    - [ ] podstawowa wersja "wysyłania ankiet" w których operator podaje uproszczony zakres danych, które potem importer musi zweryfikować i uzupełnić
      - importuj tylko że z transformacją wartości 

- [ ] uwzględnienie sektorowych wytycznych, w szczególności tych które nakazują wykorzystać inne metody niż najprostsze


- [ ] ?? specjalne traktowanie elektryczności
- [ ] podgląd w panelu bocznym szczegółowych zapisów wytycznych


- [ ] rozszerzenie zestawu metod poza najprostsze
- [ ] interfejs dla operatora do zarządzania wieloma procesami produkcyjnymi (to już dotyczy schematu w którym operator ma konto i raczej trzeba by wejść w taki schemat w dużej skali)
- [ ] coś odnośnie regulacji dotyczących raportowania dóbr przetwarzanych/wypuszczanych na rynek eu przez importera?
*/

import { ThemeProvider } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { RouteLayout } from 'src/views/components/Layout';

import ProductionProcess from './ProductionProcess';
import ImportedGoods from './ImportedGoods';
import Installations from './Installations';
import ProductionProcesses from './ProductionProcesses';
import React from 'react';
import TopBar from 'src/components/TopBar';
import cbamTheme from './theme';
import Home from './Home';
import HelpCenter from './HelpCenter';
import Installation from './Installation';
import Glossary from './Glossary';
import Regulations from './Regulations';
import Summary from './Summary';
import Actors from './Actors';
import Actor from './Actor';
import CreateProductionProcess from './ProductionProcess/Create';
import Report from './Report';
import ReportAbout from './ReportAbout';
import Reports from './Reports';
import SupportingDocuments from './SupportingDocuments';
import SupportingDocument from './SupportingDocument';
import Operators from './Operators';
import Operator from './Operator';
import ImportedGood from './ImportedGood';
import { ProductPlatform } from '../utils/api.interfaces';
import CbamSidebar from './components/Sidebar';
import { ProductHomeRoute, ProductOrganizationRoute } from '../common/routes';
import CreateCbamReport from './Report/create';

const CbamHomeRoute = () => {
  return <ProductHomeRoute chooseFirstOrganization />;
};

const CbamOrganizationRoute = () => {
  return <ProductOrganizationRoute product={ProductPlatform.Cbam} />;
};

const CbamLayoutRoute = () => {
  return (
    <RouteLayout
      sidebar={<CbamSidebar />}
      topbar={<TopBar />}
      InnerContentProps={{ sx: { m: '22px 48px' } }}
    />
  );
};

export default function Cbam() {
  return (
    <ThemeProvider theme={cbamTheme}>
      <Routes>
        <Route index element={<CbamHomeRoute />} />
        <Route element={<CbamOrganizationRoute />}>
          <Route element={<CbamLayoutRoute />}>
            <Route path="dashboard" element={<Home />} />
            <Route path="/production-process">
              <Route index element={<ProductionProcesses />} />
              <Route path="create" element={<CreateProductionProcess />} />
              <Route path=":id" element={<ProductionProcess />} />
            </Route>
            <Route path="/actors">
              <Route index element={<Actors />} />
              <Route path=":id" element={<Actor />} />
            </Route>
            <Route path="/operator">
              <Route index element={<Operators />} />
              <Route path=":id" element={<Operator />} />
            </Route>
            <Route path="/installation">
              <Route index element={<Installations />} />
              <Route path=":id" element={<Installation />} />
            </Route>

            <Route path="/glossary" element={<Glossary />} />
            <Route path="/help-center" element={<HelpCenter />} />
            <Route path="/regulations" element={<Regulations />} />
            <Route path="/reports">
              <Route path="create" element={<CreateCbamReport />} />
              <Route index element={<Reports />} />
              <Route path=":reportId" element={<Report />}>
                <Route path="about" element={<ReportAbout />} />
                <Route path="imported-goods" element={<ImportedGoods />} />
                <Route path="summary" element={<Summary />} />
              </Route>
            </Route>
            <Route path="/supporting-documents">
              <Route index element={<SupportingDocuments />} />
              <Route path=":id" element={<SupportingDocument />} />
            </Route>
            <Route path="/imported-goods">
              <Route path="create" element={<ImportedGood />} />
              <Route path=":id" element={<ImportedGood />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  );
}
