import React, { useEffect } from 'react';
import { useAppDispatch } from '../redux-file/hooks';
import { auth } from '../utils';
import { setIsAuthenticated } from '../redux-file/platform/slice';

export default function AuthenticationProvider() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    auth.init();
    auth.onAuthStateChanged((isAuthenticated) => {
      dispatch(setIsAuthenticated(isAuthenticated));
    });
  }, []);

  return null;
}
