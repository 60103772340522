import React from 'react';
import { PreviewBox } from '../../Questionnaire/index.styles';
import {
  Row,
  StyledTh,
  Table,
  TableButtons,
} from '../../../../components/PreviewTable/index.styles';
import { FormType, TruncatedTextRows } from './index.styles';
import { useTranslation } from 'react-i18next';
import ESGSummary from '../../../Report/Pdf/ESGSummary';
import { useEsgOrganization } from 'src/common/hooks';
import Checkmark from '../../../components/ESGSummary/Tables/YesOrNoTable/Checkmark';
import { Item } from './indicators';
import PageLoading from '../../../../components/PageLoading';
import SomethingWentWrong from '../../../../components/SomethingWentWrong';
import { getIndicatorsData } from './query';
import { useRequiredSelector } from '../../../../common/hooks';

export default function DownloadPage() {
  const organization = useEsgOrganization();
  const report = useRequiredSelector((state) => state.esg.activeReport);

  const { data, isLoading, isError } = getIndicatorsData(
    report.period,
    String(report.id)
  );

  const { t } = useTranslation();

  if (isError) {
    return <SomethingWentWrong />;
  }
  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <PreviewBox style={{ marginBottom: '80px', paddingBottom: '24px' }}>
      <Table
        colors={['#E9BC46']}
        cellSpacing="0"
        cellPadding="0"
        style={{
          width: '100%',
          borderCollapse: 'collapse',
        }}
      >
        <tbody>
          <Row>
            <StyledTh color="#38414f" width="80%">
              {t('esg.indicator')}
            </StyledTh>
            <StyledTh color="#38414f" width="20%">
              {t('esg.include')}
            </StyledTh>
          </Row>

          {data?.data.map((el: Item) => (
            <Row key={el.esg_questionnaire.title}>
              <td>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '-5px 0',
                    position: 'relative',
                    minHeight: '80px',
                    maxHeight: '400px',
                    alignItems: 'center',
                    gap: 20,
                  }}
                >
                  <div style={{ flex: 2 }}>
                    <TruncatedTextRows lines={10}>
                      {el.esg_questionnaire.title}
                    </TruncatedTextRows>
                  </div>
                  <FormType bgc={el.esg_indicator_category.color}>
                    {el.esg_indicator_category.name}
                  </FormType>
                </div>
              </td>

              <td>
                <TableButtons
                  style={{ marginLeft: '0', justifyContent: 'center' }}
                >
                  <Checkmark />
                  {/*<CheckboxIcon />*/}
                </TableButtons>
              </td>
            </Row>
          ))}
        </tbody>
      </Table>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '24px',
        }}
      >
        <ESGSummary organizationId={organization.id} reportId={report.id} />
      </div>
    </PreviewBox>
  );
}
