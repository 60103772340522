import React from 'react';
import { StyledStep, StyledStepLabel } from './styles';

export default function Step({
  pctLabel,
  pct,
  height,
  color,
}: {
  pctLabel: number;
  pct: number;
  height: number;
  color: string;
}) {
  const labelColor: string = pct >= pctLabel ? '#fff' : 'inherit';
  return (
    <StyledStep pct={pct} pctLabel={pctLabel} height={height} color={color}>
      <StyledStepLabel sx={{ color: labelColor }}>{pctLabel}%</StyledStepLabel>
    </StyledStep>
  );
}
