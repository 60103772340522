import React, { HTMLAttributes, ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import themeColors from '../../../utils/theme';
import { ReportingFreq } from '../../../redux-file/interfaces';
import { CalendarPaneButtonsWrapperProps } from './index.interfaces';

/* eslint-disable no-unused-vars */

export const CalendarWrapper = styled.div`
  display: flex;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 16px;
  /* max-width: 1000px; */
  width: 100%;
`;

export const CalendarPaneWrapper = styled.div<{ width: number }>(
  ({ width }) => `
  width: ${width}%;
  padding: 0 24px 24px 24px;
`
);

export const CalendarOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const getCalendarButtonFlexBasis = (
  reportingPeriod: ReportingFreq | undefined
) => {
  switch (reportingPeriod) {
    case 'M':
      return '30%';
    case 'Q':
      return '55px';
    case 'Y':
      return '17%';
    default:
      return '11%';
  }
};

function getCalendarButtonWidth(reportingPeriod: ReportingFreq | undefined) {
  switch (reportingPeriod) {
    case 'M':
      return '139px';
    case 'Q':
      return '100%';
    default:
      return '81px';
  }
}

export const CalendarPaneButtonsWrapper = styled(
  ({
    reportingPeriod,
    ...props
  }: CalendarPaneButtonsWrapperProps & HTMLAttributes<HTMLDivElement>) => (
    <div {...props} />
  )
)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ reportingPeriod }) =>
    reportingPeriod === 'Q' ? 'column' : 'row'};
  justify-content: ${({ reportingPeriod }) =>
    !!reportingPeriod ? 'center' : 'start'};
  align-items: center;
  width: 100%;

  && > * {
    flex-grow: ${({ reportingPeriod }) => (!!reportingPeriod ? '1' : '0')};
    flex-shrink: 0;
    flex-basis: ${({ reportingPeriod }) =>
      getCalendarButtonFlexBasis(reportingPeriod)};
  }
`;

export const CalendarWeekDayLabel = styled.div`
  flex: 0 0 ${getCalendarButtonFlexBasis(undefined)};
  width: ${getCalendarButtonWidth(undefined)};
  height: 10px;
  margin: 5px;

  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: ${themeColors.dark};
`;

export const CalendarWeekDayLabelWrapper = styled.div`
  display: flex;
  height: 32px;
  margin-top: 3px;
  margin-bottom: 12px;
  align-items: center;
  justify-content: start;
  width: 100%;
`;

export const CalendarPaneHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 73px;
  border-bottom: 1px solid ${themeColors.strokePressed};
`;

export const CalendarPaneButton = styled.div`
  width: 10%;
  min-width: 53px;
  flex-grow: 0;
  text-align: center;

  && > button {
    border: none;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
`;

const getCalendarButtonBackground = (
  active: 'full' | 'half' | 'disabled' | null
) => {
  switch (active) {
    case 'full':
      return '#BDBDBD'; // TODO change to proper colors
    case 'half':
      return '#EEEEEE';
    default:
      return 'none';
  }
};

export const CalendarButtonWrapper = styled(
  ({
    active,
    reportingPeriod,
    ...props
  }: {
    active: 'full' | 'half' | 'disabled' | null;
    reportingPeriod: ReportingFreq;
  } & HTMLAttributes<HTMLDivElement>) => <div {...props} />
)`
  height: 55px;
  width: ${({ reportingPeriod }) => getCalendarButtonWidth(reportingPeriod)};
  margin: 5px;

  && > button {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: ${({ active }) => getCalendarButtonBackground(active)};
    cursor: pointer;

    font-weight: ${({ active }) => (active === 'disabled' ? 400 : 700)};
    font-size: 16px;
    line-height: 28px;
    color: ${({ active }) =>
      active === 'disabled' ? themeColors.grayIcons : themeColors.dark};
  }
`;

export const CalendarPaneTitle = styled.div`
  width: 80%;
  flex-grow: 0;

  font-weight: 600;
  font-size: 18px;
  color: #2f2f2f;
  letter-spacing: 0.03em;
  text-align: center;
`;

export const IntuitiveCalendarCollapsableButton = styled(
  ({
    active,
    width,
    ...props
  }: {
    active: boolean;
    width?: number;
  } & ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button type="button" {...props} />
  )
)`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.width ? `${props.width}%` : 'auto')};

  border-radius: 24px;
  padding: 9px 12px 9px 24px;

  font-weight: 700;
  font-size: 16px;
  border: ${(props) =>
    props.active
      ? `2px solid ${themeColors.greenPressed}`
      : `1px solid ${themeColors.grayInactive}`};

  ${(props) =>
    !props.active &&
    `
      &:hover {
        border: 1px solid ${themeColors.dark};
      }
    `}
  svg {
    margin-left: 12px;
  }
`;

export const IntuitiveCalendarModal = styled.div`
  position: absolute;
  right: 0px;
  top: 64px;
  z-index: 9999;
  width: 100%;
  min-width: 600px;
`;

export const IntuitiveCalendarCollapsableWrapper = styled.div<{
  width?: number;
}>(
  ({ width }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: ${!!width ? `${width}` : '70'}%;
`
);
