import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';
import { TFunction } from 'react-i18next';
import { ProductionProcessGeneralInformation } from 'src/Cbam/types';

export type FormErrors = SchemaErrors<ProductionProcessGeneralInformation>;

export const requiredFields: (keyof ProductionProcessGeneralInformation)[] = [
  'name',
  'installation_id',
  'cn_code',
  'quantity',
];

export const validateForm = (
  data: ProductionProcessGeneralInformation,
  t: TFunction
): FormErrors => {
  const builder = new ErrorBuilder(data);
  return builder
    .checkTruthy(requiredFields, t('validation.required'))
    .checkRule(
      ['supporting_documents'],
      (documents) => documents.every((item) => item !== null),
      t('validation.requiredList')
    )
    .checkRule(
      ['supporting_documents'],
      (documents) => new Set(documents).size === documents.length,
      t('validation.duplicateList')
    )
    .build();
};
