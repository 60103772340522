import styled from 'styled-components';
import { FormContainerProps, IconProps } from './index.interfaces';

export const SectionGroupContainer = styled.div`
  width: 100%;
  padding: 0;
  margin-top: 48px;
  & > div {
    margin-bottom: 10px;
  }
`;

export const FormContainer = styled.div<FormContainerProps>(
  ({ selected }) => `
  position: relative;
  z-index: ${selected ? 5 : 0};
  display: flex;
  flex-direction: column;
  padding: 28px 24px 0 30px;
  background: #ffffff;
  border: 1px solid #b6bbb9;
  box-sizing: border-box;
  box-shadow: ${selected ? '1px 2px 3px rgba(0, 0, 0, 0.1)' : ''};
  border-radius: 16px;
`
);

export const Icon = styled.img<IconProps>(
  ({ active }) => `
  margin: 8px 2px;
  height: 48px;
  cursor: ${active ? 'pointer' : 'cursor'};
  border-radius: 50%;
  opacity: ${active ? 1 : 0.6};

  &:hover {
    background-color: ${active ? '#eee' : 'transparent'} ;
  }
`
);

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 22px;
  background: #ffffff;
  width: 100%;
  & > *:not(.side-line):not(.radio-button-group) {
    width: 100%;
    margin: 5px 0;
  }
  & > *.radio-button-group {
    width: 100%;
    margin: 5px 0;
  }
`;
