import TextField, { TextFieldProps } from '@mui/material/TextField';
import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import { FieldValueProps } from './index.interfaces';

export const StyledTextField = styledMUI(TextField)<TextFieldProps>(
  (props) => `
  width: 100%;
  border-color: #B6BBB9;
  border-radius: 2px;
  background-color: ${props.value ? '#F2F2F2' : 'white'};
  & :hover {
    border-color: #2F2F2F;
  },
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #388276;
    },
  },
  & label.Mui-focused {
    color: #388276;
  },
  & label {
    background-color: ${props.value ? 'white' : 'transparent'};
    padding-right: 7px;
    padding-left: 7px;
    margin-left: -4px;
  },
  input {
    color: #132C1D;
    font-size: 18px;
    font-weight: bold;
  },
`
);

export const ValueField = styled.div<FieldValueProps>(
  (props) => `  
    width: ${props.max === 1 ? '100%' : 'calc(50% - 10px)'};
    margin: 5px 0;
    color: #132c1d;
    font-size: 18px;
    font-weight: bold;`
);
