import { Helmet } from 'react-helmet';
import React from 'react';
import { useLanguage } from '../common/hooks';

/**
 * Provider for setting the <head> metadata using `react-helmet`.
 */
export default function HelmetProvider() {
  const { language: lang } = useLanguage();
  return <Helmet htmlAttributes={{ lang, translate: 'no' }} />;
}
