import React from 'react';
import NoData from '../NoData';
import SomethingWentWrong from '../../../../components/SomethingWentWrong';
import PageLoading from '../../../../components/PageLoading';

interface Props {
  loading: boolean;
  data:
    | string
    | {
        error_msg?: string;
        error_type?: string;
      };
}

export default function TableChart({ data, loading }: Props) {
  if (loading) return <PageLoading />;
  if (typeof data !== 'string') {
    if (data.error_type) {
      if (data.error_type === 'no-data')
        return <NoData errorMessage={data.error_msg} />;
      else return <SomethingWentWrong errorMessege={data.error_msg} />;
    }
  }

  return (
    <div
      style={{ width: '100%' }}
      dangerouslySetInnerHTML={{ __html: data as string }}
    />
  );
}
