import React from 'react';
import {
  IconHolder,
  StyledCell,
  StyledProductCellContainer,
  StyledRow,
} from '../../../components/StructureTable/index.styles';
import { Checkbox, IconButton } from '@mui/material';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import {
  CellType,
  OrganizationTreeNode,
} from '../../../components/StructureTable/index.types';
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { usePlatform } from 'src/redux-file/hooks';

interface Props {
  organization: OrganizationTreeNode;
  depth?: number;
}

export default function Row(props: Props) {
  const { organization } = props;
  const { name, depth, can_edit } = organization;
  const cellType: CellType = 'unit';

  const { organizations, platformSubscriber } = usePlatform();
  const isMaxOrgReached =
    (organizations || []).length >= platformSubscriber.maxNumOrganizations;

  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/superadmin/org-structure/unit/${organization.id}`);
  };

  return (
    <StyledRow>
      <StyledCell
        type={cellType}
        w="50%"
        depth={depth}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {name}

        {can_edit && (
          <div style={{ minWidth: '80px' }}>
            <IconButton onClick={handleEditClick}>
              <EditSharpIcon sx={{ color: '#6E6E6E' }} />
            </IconButton>
            {!isMaxOrgReached && (
              <IconButton
                onClick={() =>
                  navigate('/superadmin/org-structure/create-unit', {
                    state: { parentId: organization.id },
                  })
                }
              >
                <AddCircleIcon />
              </IconButton>
            )}
          </div>
        )}
      </StyledCell>
      <StyledCell type={cellType} w="20%">
        {organization.unit_type}
      </StyledCell>
      <StyledCell type={cellType} w="20%">
        <StyledProductCellContainer sx={{ color: '#5A9531' }}>
          <span>
            {organization.co2 ? <CheckCircleSharpIcon /> : <IconHolder />}
          </span>
          <span>
            {organization.esg ? <CheckCircleSharpIcon /> : <IconHolder />}
          </span>
          <span>
            {organization.lca ? <CheckCircleSharpIcon /> : <IconHolder />}
          </span>
        </StyledProductCellContainer>
      </StyledCell>
      <StyledCell
        type={cellType}
        w="10%"
        sx={{ textAlign: 'center', p: '5px' }}
      >
        <Checkbox />
      </StyledCell>
    </StyledRow>
  );
}
