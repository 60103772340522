import React from 'react';

import {
  Box,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useLcaUserPermissions } from '../hooks';
import { StyledTableCell } from '../styles';
import { useOrganizationMembers } from 'src/Superadmin/hooks';
import { ProductPlatform } from 'src/utils/api.interfaces';
import PageLoading from 'src/components/PageLoading';
import { useTranslation } from 'react-i18next';

interface Props {
  userId: number;
}

export default function LcaUserPermissionsTable(props: Props) {
  const { userId } = props;
  const { t } = useTranslation();

  const { isLoading, lcaUserPermissons, options, handleChange } =
    useLcaUserPermissions(userId);

  const { userOrganizationMap } = useOrganizationMembers();

  if (isLoading) return <PageLoading />;

  if (options.products.length === 0)
    return <Typography>There are no products available.</Typography>;

  return (
    <Box sx={{ overflowX: 'scroll' }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              {t(
                'superadmin.settings.tabs.users.usersTable.permissions.headers.product'
              )}
            </StyledTableCell>
            <StyledTableCell>
              {t(
                'superadmin.settings.tabs.users.usersTable.permissions.headers.organizationUnit'
              )}
            </StyledTableCell>
            <StyledTableCell>
              {t(
                'superadmin.settings.tabs.users.usersTable.permissions.headers.permissions'
              )}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {options.products.map((product) => {
            const {
              id: key,
              name,
              organization_base_org_name: organizationName,
            } = product;

            const perm = lcaUserPermissons.find(
              (perm) =>
                perm.product_id === product.id &&
                perm.organization_id === product.organization_id
            );

            const organizationMember =
              userOrganizationMap[userId]?.[product.organization_id];
            const disabled = !organizationMember?.products?.includes(
              ProductPlatform.Lca
            );
            const memberRole = options.roles.find(
              (r) => r[0] === organizationMember?.role
            )?.[0];

            const tKey =
              'superadmin.settings.tabs.users.usersTable.permissions.options';

            const nullValue = memberRole ? (
              <>
                {t(`${tKey}.${memberRole}`)}&nbsp;
                {t(`${tKey}.fromGeneralPermissions`)}
              </>
            ) : (
              t(`${tKey}.accessDenied`)
            );

            return (
              <TableRow key={key}>
                <StyledTableCell>{name}</StyledTableCell>
                <StyledTableCell>{organizationName}</StyledTableCell>
                <StyledTableCell>
                  <Select
                    fullWidth
                    disabled={disabled}
                    size="small"
                    value={perm?.role || '-'}
                    onChange={(e) =>
                      handleChange({
                        id: perm?.id,
                        organization_id: product.organization_id,
                        product_id: product.id,
                        role: e.target.value,
                        app_user_id: userId,
                      })
                    }
                  >
                    <MenuItem value="-">{nullValue}</MenuItem>
                    {options.roles.map((role) => (
                      <MenuItem key={role[0]} value={role[0]}>
                        {t(
                          `superadmin.settings.tabs.users.usersTable.permissions.options.${role[0]}`
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}
