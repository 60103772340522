import React from 'react';

export default function EditIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 29.2505V33.0005H18.75L29.81 21.9405L26.06 18.1905L15 29.2505ZM32.71 19.0405C33.1 18.6505 33.1 18.0205 32.71 17.6305L30.37 15.2905C29.98 14.9005 29.35 14.9005 28.96 15.2905L27.13 17.1205L30.88 20.8705L32.71 19.0405Z"
        fill="#606462"
      />
    </svg>
  );
}
