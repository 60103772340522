import { StyleSheet } from '@react-pdf/renderer';
import { LOGO_MARGIN_LEFT } from '../config';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  sectionTitle: {
    position: 'absolute',
    margin: `137px 0px 0px ${LOGO_MARGIN_LEFT}px`,
    fontStyle: 'bold',
    fontWeight: 700,
    fontSize: 60,
    color: '#165d5c',
    lineHeight: 76,
    letterSpacing: 2,
    fontFamily: 'Poppins',
  },
  topRightHeader: {
    position: 'absolute',
    width: 1920,
    padding: '48px',
    textAlign: 'right',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Poppins',
    fontSize: 26,
    fontStyle: 'bold',
    fontWeight: 700,
    lineHeight: 120,
    letteSspacing: 1,
    color: '#C8C8C8',
  },
  bottomRightFooter: {
    position: 'absolute',
    top: 1000,
    right: 90,
    fontFamily: 'Poppins',
    fontSize: 32,
    fontStyle: 'bold',
    fontWeight: 700,
    lineHeight: 20,
    letterSpacing: 2,
    color: '#C8C8C8',
  },
  mainHeader: {
    position: 'absolute',
    top: 161,
    left: LOGO_MARGIN_LEFT,
    fontFamily: 'Poppins',
    fontSize: 60,
    fontWeight: 700,
    fontStyle: 'bold',
    letterSpacing: 2,
    leadingTrim: 'both',
    textEdge: 'cap',
    color: '#165D5C',
  },
  subheader: {
    position: 'absolute',
    top: 253,
    left: LOGO_MARGIN_LEFT,
    fontFamily: 'Poppins',
    fontSize: 36,
    fontWeight: 700,
    fontStyle: 'bold',
    letterSpacing: 1,
    leadingTrim: 'both',
    textEdge: 'cap',
    color: '#B3B3B3',
  },
});

export default styles;
