import { styled as styledMUI } from '@mui/material/styles';
import { colors } from './helpers';
import { LCAButton as Props } from './index.types';

export const StyledButton = styledMUI('button')<Props>`
  white-space: nowrap;

  background-color: ${(props) => colors[props.color || ''].default || '#fff'};

  font-family: Poppins;

  font-size: ${(props) => props.fontSize || '16px'};

  padding: 10px 20px;

  font-weight: 600; 

  border-radius: 24px; 

  cursor: pointer;

  color: ${(props) => (props.color === 'process' ? '#fff' : 'inherit')};

  display: flex;

  justify-content: center;

  align-items: center;

  box-shadow: 0 3px 0px ${(props) => colors[props.color || ''].shadow};

  border: ${(props) =>
    props.color === 'processLight' ? '2px solid #BABABA' : 'none'};

  :hover {
    background-color: ${(props) => colors[props.color || ''].hover || '#fff'};
  }

  :active {
    background-color: ${(props) => colors[props.color || ''].default || '#fff'};
    box-shadow: none;
    transform: translateY(4px);
  }
`;

export const StyledSecondaryButton = styledMUI('button')<Props>`
    
  white-space: nowrap;
  
  background-color: #FFF7E8;

  font-family: Poppins;

  font-size: ${(props) => props.fontSize || '16px'};

  padding: 10px 20px;

  font-weight: 600; 

  border-radius: 24px; 

  border: 2px solid #6D3206;

  cursor: pointer;

  color: #7E4308;

  display: flex;

  justify-content: center;

  align-items: center;

  box-shadow: 0 3px 0px #6D3206;

  :hover {
    background-color: #fff;
  }

  :active {
    background-color: #FFF7E8;
    box-shadow: none;
    transform: translateY(4px);
  }
`;
