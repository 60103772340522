import { format } from 'date-fns';
import en from 'date-fns/locale/en-GB';
import pl from 'date-fns/locale/pl';
import { ReportingFreq } from '../redux-file/interfaces';

export const timeConverter = (
  date: Date | null | undefined,
  reportingPeriod: ReportingFreq | undefined
) => {
  const formats = {
    M: 'yyyy-MM',
    Q: 'yyyy-QQQ',
    Y: 'yyyy',
  };
  if (!reportingPeriod || !formats[reportingPeriod])
    throw new Error(`Invalid reporting period: ${reportingPeriod}`);
  return format(date || Date.now(), formats[reportingPeriod]);
};

export const dateFormat = (
  date: Date | null | undefined,
  reportingPeriod: ReportingFreq | undefined,
  locale: string
) => {
  let time = date?.toLocaleDateString();
  if (reportingPeriod === 'M') {
    time = format(date || Date.now(), 'LLLL yyyy', {
      locale: locale === 'pl' ? pl : en,
    });
  }
  if (reportingPeriod === 'Q') {
    time = format(date || Date.now(), `QQQ yyyy`);
  }
  if (reportingPeriod === 'Y') {
    time = format(date || Date.now(), 'yyyy');
  }
  return time;
};

export const periodToDate = (
  period: string,
  reportingPeriod: ReportingFreq | undefined
) => {
  const yearMonthRegex = /^(\d{4})-(\d{2})$/;
  const yearQuarterRegex = /^(\d{4})-Q([1-4])$/;
  const yearRegex = /^(\d{4})$/;

  let date: Date | null = null;

  if (yearMonthRegex.test(period) && reportingPeriod === 'M') {
    // eslint-disable-next-line no-unused-vars
    const [_, year, month] = period.match(yearMonthRegex) || [];
    date = new Date(Number(year), Number(month) - 1);
  } else if (yearQuarterRegex.test(period) && reportingPeriod === 'Q') {
    // eslint-disable-next-line no-unused-vars
    const [_, year, quarter] = period.match(yearQuarterRegex) || [];
    const month = (Number(quarter) - 1) * 3;
    date = new Date(Number(year), month);
  } else if (yearRegex.test(period) && reportingPeriod === 'Y') {
    // eslint-disable-next-line no-unused-vars
    const [_, year] = period.match(yearRegex) || [];
    date = new Date(Number(year), 0);
  }

  return date;
};

export function getFirstDateOfPeriod(
  date: Date,
  reportingPeriod: ReportingFreq
): Date {
  const year = date.getFullYear();
  const month = date.getMonth();
  let periodStart: Date;

  switch (reportingPeriod) {
    case 'M': // Monthly
      periodStart = new Date(year, month, 1);
      break;
    case 'Q': // Quarterly
      periodStart = new Date(year, Math.floor(month / 3) * 3, 1);
      break;
    case 'Y': // Yearly
      periodStart = new Date(year, 0, 1);
      break;
    default:
      throw new Error(`Invalid reporting period: ${reportingPeriod}`);
  }

  return periodStart;
}

export function getFilenameDateSuffix(date?: Date): string {
  const dateFormat = 'yyyy_MM_dd_hh_mm';
  return format(date ?? new Date(), dateFormat);
}
