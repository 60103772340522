import styled from 'styled-components';
import themeColors from '../../../utils/theme';

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid #d9dfdd;
  border-radius: 16px;
  padding: 36px 24px;
  margin-bottom: 24px;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.01em;
  color: #2f2f2f;
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  span {
    border-radius: 50%;
    min-width: 32px;
    min-height: 32px;
    background: #fdc342;
    color: #2f2f2f;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 32px;
    max-height: 32px;
    font-weight: 700;
    font-size: 20px;
    margin-right: 14px;
  }
`;

export const ShortDesc = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: ${themeColors.grayIcons};
  margin-bottom: 30px;
`;

export const MainImage = styled.img`
  width: calc(50% - 12px);
  margin-bottom: 24px;
  border-radius: 8px;
  margin-right: 24px;
  float: left;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  p {
    color: ${themeColors.grayIcons};
    font-size: 18px;
    line-height: 28px;
  }

  b {
    font-weight: 600;
  }
`;

export const Tag1 = styled.div`
  margin-right: 8px;
  background: #e7e4b8;
  border: 1px solid #e0d969;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 8px 16px 9px;
  line-height: 1;
`;

export const Tag2 = styled.div`
  background: #ffe8b7;
  border: 1px solid #ffd06b;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 8px 16px 9px;
  line-height: 1;
`;

export const Author = styled.div`
  margin-top: 35px;
`;

export const AuthorName = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #2f2f2f;
  margin-bottom: 8px;
`;

export const AuthorAvatar = styled.img`
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
  margin-right: 14px;
`;

export const AuthorLink = styled.a`
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.03em;
  color: #388276;
  text-decoration: none;
  display: block;
  margin-bottom: 12px;
`;

export const AuthorDesc = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.03em;
  color: #2f2f2f;
`;

export const AuthorCompany = styled.img`
  width: 180px;
  margin-bottom: 40px;
`;

export const Button = styled.button<{ open: boolean }>`
  border: 1px solid #d9dfdd;
  border-radius: 24px;
  background: #fff;
  padding: 0 14px 0 20px;
  display: flex;
  height: 40px;
  font-weight: 700;
  font-size: 16px;
  align-items: center;
  letter-spacing: 0.01em;
  color: #388276;

  svg {
    margin-left: 8px;
    transform: rotate(${({ open }) => (open ? '0' : '180')}deg);
  }
`;

export const Clear = styled.div`
  &::after {
    content: '';
    clear: both;
    display: table;
  }
`;
