import TextField from '@mui/material/TextField';
import { styled as styledMUI } from '@mui/material/styles';

export const StyledTextField = styledMUI(TextField)<{ mainColor: string }>(
  (props) => `
    width: 100%;
    border: 1px solid  #CCC;
    border-radius: 8px;
    background-color: ${props.value ? '#F2F2F2' : 'white'};
    color:  #5A636F;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    letter-spacing: 0.32px; 
    
    & :hover {
        border-color: #bbb;
    },
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${props.mainColor};
    },
  },
  & label.Mui-focused {
    color: #49525F;
    
  },
  & label {
    background-color: white;
    padding-right: 7px;
    padding-left: 7px;
    margin-left: -4px;
    color: #49525F;
  },
`
);
