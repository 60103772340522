import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';
import { TFunction } from 'react-i18next';
import { CbamPrecursorInSchema } from 'src/Cbam/types';

export type FormErrors = SchemaErrors<CbamPrecursorInSchema>;

export const requiredFields: (keyof CbamPrecursorInSchema)[] = [
  'name',
  'amount',
  'precursor_id',
];

export const validateForm = (
  data: CbamPrecursorInSchema,
  t: TFunction
): FormErrors => {
  const builder = new ErrorBuilder(data);
  return builder.checkTruthy(requiredFields, t('validation.required')).build();
};
