import React from 'react';
import styled from 'styled-components';
import { TextProps } from './index.interfaces';

export const SectionGroupTitle = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const Text = styled(
  ({
    margin,
    size,
    ...props
  }: TextProps & React.HTMLAttributes<HTMLDivElement>) => <div {...props} />
)`
  display: flex;
  align-items: center;
  color: #2f2f2f;
  font-weight: 600;
  font-size: ${({ size }) => size || 22}px;
  line-height: 36px;
  margin-bottom: 2px;
  margin-top: ${({ margin, size }) => (size && margin ? 50 : 5)}px;
  opacity: ${({ margin }) => (margin ? 1 : 0.6)};
  margin-right: ${({ margin }) => (margin ? 5 : 0)}px;
`;

export const Important = styled.div`
  color: red;
  margin-left: 5px;
`;

export const InfoContainer = styled.div<{ titleposition?: boolean }>`
  position: absolute;
  z-index: 10;
  background: #ffffff;
  border: 1px solid #388276;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 17px 23px;
  width: 260px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #606462;
  right: ${({ titleposition }) => (titleposition ? '0' : 'auto')};
`;

export const DescriptionContainer = styled.div`
  position: relative;
`;

export const Icon = styled.img`
  height: 16.5px;
  margin-left: 6px;
`;

export const IconTitle = styled.img`
  height: 32px;
  margin-right: 16px;
`;
