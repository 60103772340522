import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';

import { client } from '../../../../utils/api-client';
import { GREENHOUSE_GAS_EMISSIONS_SECTIONS } from './config';
import { timeConverter } from '../../../../utils/timeConverter';

import DownloadCountDown from '../components/DownloadCountDown';
import PDFReport from './PDFDownloadLink';
import initialReportData from './initialData';
import { ReportingFreq } from 'src/redux-file/interfaces';

type PDFReportPropsType = {
  organizationIds: number[];
  reportStartTime: Date | null;
  reportEndTime: Date | null;
  reportingFreq: ReportingFreq | undefined;
};

const GreenhouseGasEmissions = (props: PDFReportPropsType) => {
  const { organizationIds, reportingFreq, reportStartTime, reportEndTime } =
    props;
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [requestError, setRequestError] = useState(undefined);
  const [reportData, setReportData] = useState(initialReportData);

  const handleResponse = (response: AxiosResponse) => {
    setReportData(response.data);
    setRequestSuccess(true);
  };

  useEffect(() => {
    if (reportingFreq === undefined) return;
    client
      .post('/web/request-reports-data/greenhouse-gas-emissions', {
        sections: GREENHOUSE_GAS_EMISSIONS_SECTIONS,
        organization_ids: organizationIds,
        report_start_time: timeConverter(reportStartTime, reportingFreq),
        report_end_time: timeConverter(reportEndTime, reportingFreq),
      })
      .then(handleResponse)
      .catch((err) => setRequestError(err.response.data));
  }, [reportingFreq]);

  return (
    <div style={{ width: 440, height: 500 }}>
      {!!requestSuccess ? (
        <>
          <DownloadCountDown key="fourced-counter" startCount={90} />
          <PDFReport data={reportData} requestError={requestError} />
        </>
      ) : (
        <DownloadCountDown key="counter" />
      )}
    </div>
  );
};

export default GreenhouseGasEmissions;
