import { AggregatedGoodsCategory } from './types';

export const CNCodeMap: { [key: string]: string } = {
  _2507_00_80: '2507 00 80 - Other kaolinic clays',
  _2523_10_00: '2523 10 00 - Cement clinkers',
  _2523_21_00:
    '2523 21 00 - White Portland cement, whether or not artificially coloured',
  _2523_29_00: '2523 29 00 - Other Portland cement',
  _2523_90_00: '2523 90 00 - Other hydraulic cements',
  _2523_30_00: '2523 30 00 - Aluminous cement',
  _2716_00_00: '2716 00 00 - Electrical energy',
  _2808_00_00: '2808 00 00 - Nitric acid; sulphonitric acids',
  _3102_10: '3102 10 - Urea, whether or not in aqueous solution',
  _2814: '2814 - Ammonia, anhydrous or in aqueous solution',
  _2834_21_00: '2834 21 00 - Nitrates of potassium',
  _3102:
    '3102 - Mineral or chemical fertilisers, nitrogenous except 3102 10 (Urea)',
  _3105:
    '3105 - Mineral or chemical fertilisers containing two or three of the fertilising elements nitrogen, phosphorus, and potassium; other fertilisers — Except: 3105 60 00 - Mineral or chemical fertilisers containing the two fertilising elements phosphorus and potassium',
  _2601_12_00:
    '2601 12 00 - Agglomerated iron ores and concentrates, other than roasted iron pyrites',
  _7201:
    '7201 - Pig iron and spiegeleisen in pigs, blocks, or other primary forms Some products under 7205 (Granules and powders, of pig iron, spiegeleisen, iron, or steel) may be covered here',
  _7202_1: '7202 1 - Ferro-manganese',
  _7202_4: '7202 4 - Ferro-chromium',
  _7202_6: '7202 6 - Ferro-nickel',
  _7203:
    '7203 - Ferrous products obtained by direct reduction of iron ore and other spongy ferrous products',
  _7206:
    '7206 - Iron and non-alloy steel in ingots or other primary forms (excluding iron of heading 7203 )',
  _7207: '7207 - Semi-finished products of iron or non-alloy steel',
  _7218:
    '7218 - Stainless steel in ingots or other primary forms; semi-finished products of stainless steel',
  _7224:
    '7224 - Other alloy steel in ingots or other primary forms; semi-finished products of other alloy steel',
  _7205:
    '7205 - Granules and powders, of pig iron, spiegeleisen, iron or steel (if not covered under category pig iron)',
  _7208:
    '7208 - Flat-rolled products of iron or non-alloy steel, of a width of 600 mm or more, hot-rolled, not clad, plated or coated',
  _7209:
    '7209 - Flat-rolled products of iron or non-alloy steel, of a width of 600 mm or more, cold-rolled (cold-reduced), not clad, plated or coated',
  _7210:
    '7210 - Flat-rolled products of iron or non-alloy steel, of a width of 600 mm or more, clad, plated or coated',
  _7211:
    '7211 - Flat-rolled products of iron or non-alloy steel, of a width of less than 600 mm, not clad, plated or coated',
  _7212:
    '7212 - Flat-rolled products of iron or non-alloy steel, of a width of less than 600 mm, clad, plated or coated',
  _7213:
    '7213 - Bars and rods, hot-rolled, in irregularly wound coils, of iron or non-alloy steel',
  _7214:
    '7214 - Other bars and rods of iron or non-alloy steel, not further worked than forged, hot-rolled, hot-drawn or hot-extruded, but including those twisted after rolling',
  _7215: '7215 - Other bars and rods of iron or non-alloy steel',
  _7216: '7216 - Angles, shapes and sections of iron or non-alloy steel',
  _7217: '7217 - Wire of iron or non-alloy steel',
  _7219:
    '7219 - Flat-rolled products of stainless steel, of a width of 600 mm or more',
  _7220:
    '7220 - Flat-rolled products of stainless steel, of a width of less than 600 mm',
  _7221:
    '7221 - Bars and rods, hot-rolled, in irregularly wound coils, of stainless steel',
  _7222:
    '7222 - Other bars and rods of stainless steel; angles, shapes and sections of stainless steel',
  _7223: '7223 - Wire of stainless steel',
  _7225:
    '7225 - Flat-rolled products of other alloy steel, of a width of 600 mm or more',
  _7226:
    '7226 - Flat-rolled products of other alloy steel, of a width of less than 600 mm',
  _7227:
    '7227 - Bars and rods, hot-rolled, in irregularly wound coils, of other alloy steel',
  _7228:
    '7228 - Other bars and rods of other alloy steel; angles, shapes and sections, of other alloy steel; hollow drill bars and rods, of alloy or non-alloy steel',
  _7229: '7229 - Wire of other alloy steel',
  _7301:
    '7301 - Sheet piling of iron or steel, whether or not drilled, punched or made from assembled elements; welded angles, shapes and sections, of iron or steel',
  _7302:
    '7302 - Railway or tramway track construction material of iron or steel, the following: rails, check-rails and rack rails, switch blades, crossing frogs, point rods and other crossing pieces, sleepers (cross-ties), fish- plates, chairs, chair wedges, sole plates (base plates), rail clips, bedplates, ties and other material specialised for jointing or fixing rails',
  _7303: '7303 - Tubes, pipes and hollow profiles, of cast iron',
  _7304:
    '7304 - Tubes, pipes and hollow profiles, seamless, of iron (other than cast iron) or steel',
  _7305:
    '7305 - Other tubes and pipes (for example, welded, riveted or similarly closed), having circular cross-sections, the external diameter of which exceeds 406,4 mm, of iron or steel',
  _7306:
    '7306 - Other tubes, pipes and hollow profiles (for example, open seam or welded, riveted or similarly closed), of iron or steel',
  _7307:
    '7307 - Tube or pipe fittings (for example, couplings, elbows, sleeves), of iron or steel',
  _7308:
    '7308 - Structures (excluding prefabricated buildings of heading 9406 ) and parts of structures (for example, bridges and bridge-sections, lock- gates, towers, lattice masts, roofs, roofing frameworks, doors and windows and their frames and thresholds for doors, shutters, balustrades, pillars and columns), of iron or steel; plates, rods, angles, shapes, sections, tubes and the like, prepared for use in structures, of iron or steel',
  _7309:
    '7309 - Reservoirs, tanks, vats and similar containers for any material (other than compressed or liquefied gas), of iron or steel, of a capacity exceeding 300 l, whether or not lined or heat-insulated, but not fitted with mechanical or thermal equipment',
  _7310:
    '7310 - Tanks, casks, drums, cans, boxes and similar containers, for any material (other than compressed or liquefied gas), of iron or steel, of a capacity not exceeding 300 l, whether or not lined or heat-insulated, but not fitted with mechanical or thermal equipment',
  _7311: '7311 - Containers for compressed or liquefied gas, of iron or steel',
  _7318:
    '7318 - Screws, bolts, nuts, coach screws, screw hooks, rivets, cotters, cotter pins, washers (including spring washers) and similar articles, of iron or steel',
  _7326: '7326 - Other articles of iron or steel',
  _7601: '7601 - Unwrought aluminium',
  _7603: '7603 - Aluminium powders and flakes',
  _7604: '7604 - Aluminium bars, rods and profiles',
  _7605: '7605 - Aluminium wire',
  _7606:
    '7606 - Aluminium plates, sheets and strip, of a thickness exceeding 0,2 mm',
  _7607:
    '7607 - Aluminium foil (whether or not printed or backed with paper, paper-board, plastics or similar backing materials) of a thickness (excluding any backing) not exceeding 0,2 mm',
  _7608: '7608 - Aluminium tubes and pipes',
  _7609_00_00:
    '7609 00 00 - Aluminium tube or pipe fittings (for example, couplings, elbows, sleeves)',
  _7610:
    '7610 - Aluminium structures (excluding prefabricated buildings of heading 9406 ) and parts of structures (for example, bridges and bridge-sections, towers, lattice masts, roofs, roofing frameworks, doors and windows and their frames and thresholds for doors, balustrades, pillars and columns); aluminium plates, rods, profiles, tubes and the like, prepared for use in structures',
  _7611_00_00:
    '7611 00 00 - Aluminium reservoirs, tanks, vats and similar containers, for any material (other than compressed or liquefied gas), of a capacity exceeding 300 litres, whether or not lined or heat-insulated, but not fitted with mechanical or thermal equipment',
  _7612:
    '7612 - Aluminium casks, drums, cans, boxes and similar containers (including rigid or collapsible tubular containers), for any material (other than compressed or liquefied gas), of a capacity not exceeding 300 litres, whether or not lined or heat-insulated, but not fitted with mechanical or thermal equipment',
  _7613_00_00:
    '7613 00 00 - Aluminium containers for compressed or liquefied gas',
  _7614:
    '7614 - Stranded wire, cables, plaited bands and the like, of aluminium, not electrically insulated',
  _7616: '7616 - Other articles of aluminium',
  _2804_10_000: '2804 10 000 - Hydrogen',
};

export enum CNCode {
  _2507_00_80 = '2507 00 80 - Other kaolinic clays',
  _2523_10_00 = '2523 10 00 - Cement clinkers',
  _2523_21_00 = '2523 21 00 - White Portland cement, whether or not artificially coloured',
  _2523_29_00 = '2523 29 00 - Other Portland cement',
  _2523_90_00 = '2523 90 00 - Other hydraulic cements',
  _2523_30_00 = '2523 30 00 - Aluminous cement',
  _2716_00_00 = '2716 00 00 - Electrical energy',
  _2808_00_00 = '2808 00 00 - Nitric acid; sulphonitric acids',
  _3102_10 = '3102 10 - Urea, whether or not in aqueous solution',
  _2814 = '2814 - Ammonia, anhydrous or in aqueous solution',
  _2834_21_00 = '2834 21 00 - Nitrates of potassium',
  _3102 = '3102 - Mineral or chemical fertilisers, nitrogenous except 3102 10 (Urea)',
  _3105 = '3105 - Mineral or chemical fertilisers containing two or three of the fertilising elements nitrogen, phosphorus, and potassium; other fertilisers — Except: 3105 60 00 - Mineral or chemical fertilisers containing the two fertilising elements phosphorus and potassium',
  _2601_12_00 = '2601 12 00 - Agglomerated iron ores and concentrates, other than roasted iron pyrites',
  _7201 = '7201 - Pig iron and spiegeleisen in pigs, blocks, or other primary forms Some products under 7205 (Granules and powders, of pig iron, spiegeleisen, iron, or steel) may be covered here',
  _7202_1 = '7202 1 - Ferro-manganese',
  _7202_4 = '7202 4 - Ferro-chromium',
  _7202_6 = '7202 6 - Ferro-nickel',
  _7203 = '7203 - Ferrous products obtained by direct reduction of iron ore and other spongy ferrous products',
  _7206 = '7206 - Iron and non-alloy steel in ingots or other primary forms (excluding iron of heading 7203 )',
  _7207 = '7207 - Semi-finished products of iron or non-alloy steel',
  _7218 = '7218 - Stainless steel in ingots or other primary forms; semi-finished products of stainless steel',
  _7224 = '7224 - Other alloy steel in ingots or other primary forms; semi-finished products of other alloy steel',
  _7205 = '7205 - Granules and powders, of pig iron, spiegeleisen, iron or steel (if not covered under category pig iron)',
  _7208 = '7208 - Flat-rolled products of iron or non-alloy steel, of a width of 600 mm or more, hot-rolled, not clad, plated or coated',
  _7209 = '7209 - Flat-rolled products of iron or non-alloy steel, of a width of 600 mm or more, cold-rolled (cold-reduced), not clad, plated or coated',
  _7210 = '7210 - Flat-rolled products of iron or non-alloy steel, of a width of 600 mm or more, clad, plated or coated',
  _7211 = '7211 - Flat-rolled products of iron or non-alloy steel, of a width of less than 600 mm, not clad, plated or coated',
  _7212 = '7212 - Flat-rolled products of iron or non-alloy steel, of a width of less than 600 mm, clad, plated or coated',
  _7213 = '7213 - Bars and rods, hot-rolled, in irregularly wound coils, of iron or non-alloy steel',
  _7214 = '7214 - Other bars and rods of iron or non-alloy steel, not further worked than forged, hot-rolled, hot-drawn or hot-extruded, but including those twisted after rolling',
  _7215 = '7215 - Other bars and rods of iron or non-alloy steel',
  _7216 = '7216 - Angles, shapes and sections of iron or non-alloy steel',
  _7217 = '7217 - Wire of iron or non-alloy steel',
  _7219 = '7219 - Flat-rolled products of stainless steel, of a width of 600 mm or more',
  _7220 = '7220 - Flat-rolled products of stainless steel, of a width of less than 600 mm',
  _7221 = '7221 - Bars and rods, hot-rolled, in irregularly wound coils, of stainless steel',
  _7222 = '7222 - Other bars and rods of stainless steel; angles, shapes and sections of stainless steel',
  _7223 = '7223 - Wire of stainless steel',
  _7225 = '7225 - Flat-rolled products of other alloy steel, of a width of 600 mm or more',
  _7226 = '7226 - Flat-rolled products of other alloy steel, of a width of less than 600 mm',
  _7227 = '7227 - Bars and rods, hot-rolled, in irregularly wound coils, of other alloy steel',
  _7228 = '7228 - Other bars and rods of other alloy steel; angles, shapes and sections, of other alloy steel; hollow drill bars and rods, of alloy or non-alloy steel',
  _7229 = '7229 - Wire of other alloy steel',
  _7301 = '7301 - Sheet piling of iron or steel, whether or not drilled, punched or made from assembled elements; welded angles, shapes and sections, of iron or steel',
  _7302 = '7302 - Railway or tramway track construction material of iron or steel, the following: rails, check-rails and rack rails, switch blades, crossing frogs, point rods and other crossing pieces, sleepers (cross-ties), fish- plates, chairs, chair wedges, sole plates (base plates), rail clips, bedplates, ties and other material specialised for jointing or fixing rails',
  _7303 = '7303 - Tubes, pipes and hollow profiles, of cast iron',
  _7304 = '7304 - Tubes, pipes and hollow profiles, seamless, of iron (other than cast iron) or steel',
  _7305 = '7305 - Other tubes and pipes (for example, welded, riveted or similarly closed), having circular cross-sections, the external diameter of which exceeds 406,4 mm, of iron or steel',
  _7306 = '7306 - Other tubes, pipes and hollow profiles (for example, open seam or welded, riveted or similarly closed), of iron or steel',
  _7307 = '7307 - Tube or pipe fittings (for example, couplings, elbows, sleeves), of iron or steel',
  _7308 = '7308 - Structures (excluding prefabricated buildings of heading 9406 ) and parts of structures (for example, bridges and bridge-sections, lock- gates, towers, lattice masts, roofs, roofing frameworks, doors and windows and their frames and thresholds for doors, shutters, balustrades, pillars and columns), of iron or steel; plates, rods, angles, shapes, sections, tubes and the like, prepared for use in structures, of iron or steel',
  _7309 = '7309 - Reservoirs, tanks, vats and similar containers for any material (other than compressed or liquefied gas), of iron or steel, of a capacity exceeding 300 l, whether or not lined or heat-insulated, but not fitted with mechanical or thermal equipment',
  _7310 = '7310 - Tanks, casks, drums, cans, boxes and similar containers, for any material (other than compressed or liquefied gas), of iron or steel, of a capacity not exceeding 300 l, whether or not lined or heat-insulated, but not fitted with mechanical or thermal equipment',
  _7311 = '7311 - Containers for compressed or liquefied gas, of iron or steel',
  _7318 = '7318 - Screws, bolts, nuts, coach screws, screw hooks, rivets, cotters, cotter pins, washers (including spring washers) and similar articles, of iron or steel',
  _7326 = '7326 - Other articles of iron or steel',
  _7601 = '7601 - Unwrought aluminium',
  _7603 = '7603 - Aluminium powders and flakes',
  _7604 = '7604 - Aluminium bars, rods and profiles',
  _7605 = '7605 - Aluminium wire',
  _7606 = '7606 - Aluminium plates, sheets and strip, of a thickness exceeding 0,2 mm',
  _7607 = '7607 - Aluminium foil (whether or not printed or backed with paper, paper-board, plastics or similar backing materials) of a thickness (excluding any backing) not exceeding 0,2 mm',
  _7608 = '7608 - Aluminium tubes and pipes',
  _7609_00_00 = '7609 00 00 - Aluminium tube or pipe fittings (for example, couplings, elbows, sleeves)',
  _7610 = '7610 - Aluminium structures (excluding prefabricated buildings of heading 9406 ) and parts of structures (for example, bridges and bridge-sections, towers, lattice masts, roofs, roofing frameworks, doors and windows and their frames and thresholds for doors, balustrades, pillars and columns); aluminium plates, rods, profiles, tubes and the like, prepared for use in structures',
  _7611_00_00 = '7611 00 00 - Aluminium reservoirs, tanks, vats and similar containers, for any material (other than compressed or liquefied gas), of a capacity exceeding 300 litres, whether or not lined or heat-insulated, but not fitted with mechanical or thermal equipment',
  _7612 = '7612 - Aluminium casks, drums, cans, boxes and similar containers (including rigid or collapsible tubular containers), for any material (other than compressed or liquefied gas), of a capacity not exceeding 300 litres, whether or not lined or heat-insulated, but not fitted with mechanical or thermal equipment',
  _7613_00_00 = '7613 00 00 - Aluminium containers for compressed or liquefied gas',
  _7614 = '7614 - Stranded wire, cables, plaited bands and the like, of aluminium, not electrically insulated',
  _7616 = '7616 - Other articles of aluminium',
  _2804_10_000 = '2804 10 000 - Hydrogen',
}

export const AggregatedGoodsCategoryToCNCodes = {
  [AggregatedGoodsCategory.CalcinedClay]: ['_2507_00_80'],
  [AggregatedGoodsCategory.CementClinker]: ['_2523_10_00'],
  [AggregatedGoodsCategory.Cement]: [
    '_2523_21_00',
    '_2523_29_00',
    '_2523_90_00',
  ],
  [AggregatedGoodsCategory.AluminousCement]: ['_2523_30_00'],
  [AggregatedGoodsCategory.Electricity]: ['_2716_00_00'],
  [AggregatedGoodsCategory.NitricAcid]: ['_2808_00_00'],
  [AggregatedGoodsCategory.Urea]: ['_3102_10'],
  [AggregatedGoodsCategory.Ammonia]: ['_2814'],
  [AggregatedGoodsCategory.MixedFertilisers]: ['_2834_21_00', '_3102', '_3105'],
  [AggregatedGoodsCategory.SinteredOre]: ['_2601_12_00'],
  [AggregatedGoodsCategory.PigIron]: ['_7201'],
  [AggregatedGoodsCategory.FeMn]: ['_7202_1'],
  [AggregatedGoodsCategory.FeCr]: ['_7202_4'],
  [AggregatedGoodsCategory.FeNi]: ['_7202_6'],
  [AggregatedGoodsCategory.DRI]: ['_7203'],
  [AggregatedGoodsCategory.CrudeSteel]: ['_7206', '_7207', '_7218', '_7224'],
  [AggregatedGoodsCategory.IronOrSteelProducts]: [
    '_7205',
    '_7208',
    '_7209',
    '_7210',
    '_7211',
    '_7212',
    '_7213',
    '_7214',
    '_7215',
    '_7216',
    '_7217',
    '_7219',
    '_7220',
    '_7221',
    '_7222',
    '_7223',
    '_7225',
    '_7226',
    '_7227',
    '_7228',
    '_7229',
    '_7301',
    '_7302',
    '_7303',
    '_7304',
    '_7305',
    '_7306',
    '_7307',
    '_7308',
    '_7309',
    '_7310',
    '_7311',
    '_7318',
    '_7326',
  ],
  [AggregatedGoodsCategory.UnwroughtAluminium]: ['_7601'],
  [AggregatedGoodsCategory.AluminiumProducts]: [
    '_7603',
    '_7604',
    '_7605',
    '_7606',
    '_7607',
    '_7608',
    '_7609_00_00',
    '_7610',
    '_7611_00_00',
    '_7612',
    '_7613_00_00',
    '_7614',
    '_7616',
  ],
  [AggregatedGoodsCategory.Hydrogen]: ['_2804_10_000'],
};
