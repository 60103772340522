import React from 'react';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from '../../interfaces';
import GoodsSection from './GoodsSection';

export default function Reported() {
  return (
    <>
      <GoodsSection
        type={GOODS_AND_SERVICES_OR_CAPITAL_GOODS.GOODS_AND_SERVICES}
      />
      <GoodsSection type={GOODS_AND_SERVICES_OR_CAPITAL_GOODS.CAPITAL_GOODS} />
    </>
  );
}
