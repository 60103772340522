import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const ReportingPeriodHeader = styled(Typography)`
  font-size: 24px;
  font-weight: Bold;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
`;

export const ReportingPeriodInfo = styled(Typography)`
  font-size: 18px;
  color: #606462;
  line-height: 28px;
  margin-bottom: 40px;
`;
