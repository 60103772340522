import { useQuery } from 'react-query';
import { client } from '../../../../../utils/api-client';

interface Props {
  reportId: string;
  organizationId: number | string;
  questionnaireKey: string;
}
// eslint-disable-next-line import/prefer-default-export
export const useEsgQuestionnaireAnswerId = (props: Props) =>
  useQuery(
    [
      'esg-questionnaire-answer-id',
      props.organizationId,
      props.reportId,
      props.questionnaireKey,
    ],
    () =>
      client
        .put(
          `/web/esg_v2/${props.organizationId}/${props.reportId}/indicators?esg_questionnaire_key=${props.questionnaireKey}`
        )
        .then((res) => {
          const questionnaireAnswerId = res?.data?.esg_questionnaire_answer_id;
          return questionnaireAnswerId;
        }),
    {
      enabled: !!props.organizationId,
    }
  );
