import { Colors } from './index.types';

// eslint-disable-next-line import/prefer-default-export
export const colors: { [key: string]: Colors } = {
  'forestry-and-wood': {
    default: '#8ACCBF',
    hover: '#79BBAF',
    shadow: '#46887C',
  },
  'water-supply': { default: '#C9ECF6', hover: '#B8DBE5', shadow: '#85A8B2' },
  'transport-and-machines': {
    default: '#B3D4F3',
    hover: '#A2C4E2',
    shadow: '#7091B0',
  },
  'land-plants-and-animals': {
    default: '#FFB885',
    hover: '#EEA774',
    shadow: '#BC7541',
  },
  energy: { default: '#FFF299', hover: '#FFE188', shadow: '#CCB055' },
  'tourism-services': {
    default: '#C5ECBE',
    hover: '#B4DBAD',
    shadow: '#81A87A',
  },
  'chemicals-and-plastics': {
    default: '#D5B8F1',
    hover: '#C4A7E0',
    shadow: '#9073AC',
  },
  fuels: { default: '#FFE3B9', hover: '#FFD698', shadow: '#FFD698' },
  'waste-management-and-recycling': {
    default: '#D7BCAB',
    hover: '#C6AB9A',
    shadow: '#C6AB9A',
  },
  textiles: { default: '#E4AFB7', hover: '#D39EA6', shadow: '#D39EA6' },

  'pulp-and-paper': { default: '#F2EBEF', hover: '#F1DADE', shadow: '#C0A7AB' },
  metals: { default: '#FFCB63', hover: '#FFBA52', shadow: '#CC8720' },
  'building-and-construction': {
    default: '#CAD2FE',
    hover: '#B0C1ED',
    shadow: '#8090BA',
  },

  process: { default: '#8F5409', hover: '#7E4308', shadow: '#6D3206' },

  secondary: { default: '#FFF7E8', hover: '#FFFFFF', shadow: '#6D3206' },

  default: { default: '3fff', hover: '#fff', shadow: '#000' },

  uncategorized: { default: '#E5E5E5', hover: '#D4D4D4', shadow: '#A1A1A1' },
};
