import React from 'react';
import Sidebar from '../../../views/components/Sidebar';
import LogoMin from '../../../images/svg/logoMin';
import HeaderWithLogo from '../../../views/components/Sidebar/HeaderWithLogo';
import ControllerItem from '../../../views/components/Sidebar/ControllerItem';
import { EsgLogo } from '../../assets';
import { ApiDocsNavItem, HelpCenterNavItem } from 'src/common/sidebar';

export type EsgSidebarProps = {
  children?: React.ReactNode;
};

export default function EsgSidebar({ children }: EsgSidebarProps) {
  return (
    <Sidebar>
      <HeaderWithLogo openLogo={<EsgLogo />} closedLogo={<LogoMin />} />
      {children}
      <HelpCenterNavItem />
      <ApiDocsNavItem />
      <ControllerItem />
    </Sidebar>
  );
}
