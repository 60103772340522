import { IconButton, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { StyledMenu, StyledTypography } from '../index.styles';
import { useAppDispatch } from '../../../redux-file/hooks';
import { putLanguage } from '../../../redux-file/thunk';
import { useLanguage } from 'src/common/hooks';

enum Languages {
  en = 'en',
  pl = 'pl',
}
const languages = Object.values(Languages);

type Props = {
  localOnly?: boolean;
};

export default function ChangeLanguage(props: Props) {
  const { language, setLanguage } = useLanguage();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const changeLanguage = (newLang: string) => {
    if (!props.localOnly) {
      dispatch(putLanguage(newLang));
    }
    setLanguage(newLang);
    handleClose();
  };

  return (
    <>
      <IconButton sx={{ m: '5px', width: '40px' }} onClick={handleOpen}>
        <StyledTypography>{language.toUpperCase()}</StyledTypography>
      </IconButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {languages.map((lang) => (
          <MenuItem
            key={lang}
            selected={lang === language}
            onClick={() => {
              changeLanguage(lang);
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>
              {lang.toUpperCase()}
            </Typography>
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}
