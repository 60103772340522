import React from 'react';
import Container from '../components/Container';
import assets from '../assets';
import Title from '../components/Title';
import { useNavigate, useParams } from 'react-router-dom';
import { client } from '../../../../../utils/api-client';
import { EsgStakeholderSurveyMetadataSchema } from '../types';
import PageLoading from '../../../../../components/PageLoading';
import SomethingWentWrong from '../../../../../components/SomethingWentWrong';
import { Trans, useTranslation } from 'react-i18next';
import { useBasicSurveyMeta } from '../hooks';
import FormattedSurvey from '../components/FormattedSurvey';
import OrganizationalLogo from 'src/components/OrganizationalLogo';

export default function StakeholderSurveyIntro() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useParams();
  const { data: meta, isError, endpointUrl } = useBasicSurveyMeta(token);

  if (isError) return <SomethingWentWrong />;
  if (!meta) return <PageLoading />;

  const nextButtonHandler = () => {
    client
      .put<EsgStakeholderSurveyMetadataSchema>(endpointUrl)
      .then(({ data }) => {
        navigate(`/stakeholders/${data.token}/questionnaire/0`);
      });
  };

  return (
    <Container
      header={<img src={assets.intro.image} />}
      nextLink={{
        onClick: nextButtonHandler,
        label: 'esg.stakeholderSurvey.next',
      }}
    >
      <div>
        <Title
          title={t('esg.stakeholderSurvey.title')}
          period={meta.report_period}
        />
        <FormattedSurvey
          text={
            meta.intro_text ||
            t('esg.stakeholderSurvey.intro.message', {
              organization_name: meta.organization_name,
            })
          }
        />
        <OrganizationalLogo logo={meta.organization_logo} />
      </div>
    </Container>
  );
}
