import React from 'react';

export default function GoodIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#388276" />
      <rect
        x="13.6758"
        y="21.8711"
        width="15.1579"
        height="5.05263"
        rx="2.52632"
        transform="rotate(45 13.6758 21.8711)"
        fill="white"
      />
      <rect
        x="35.1094"
        y="14.7246"
        width="5.05263"
        height="25.2632"
        rx="2.52632"
        transform="rotate(45 35.1094 14.7246)"
        fill="white"
      />
    </svg>
  );
}
