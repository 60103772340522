import React, { ChangeEvent } from 'react';
import { useAreasQuery } from '../hooks';
import { LcaObjectCustomFactor } from '../index.types';
import { TextField } from '@mui/material';
import PageLoading from 'src/components/PageLoading';

interface CustomFactorsFieldsProps {
  values: LcaObjectCustomFactor[];
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number
  ) => void;
  errors: { [key: string]: string };
  isLoading: boolean;
}

export default function CustomFactorsFields({
  values,
  handleChange,
  errors,
  isLoading,
}: CustomFactorsFieldsProps) {
  const impactAreas = useAreasQuery();

  if (!impactAreas) {
    return <PageLoading />;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '20px',
      }}
    >
      {impactAreas.map((area, index) => {
        const factorValue =
          values.find((value) => value.area_id === area.id)?.factor ?? '';
        return (
          <TextField
            key={area.id}
            fullWidth
            name={area.name}
            type="number"
            value={factorValue}
            onChange={(e) => handleChange(e, area.id)}
            disabled={isLoading}
            error={!!errors[area.name]}
            helperText={errors[area.name]}
            label={`${area.name} (${area.unit_name})`}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          />
        );
      })}
    </div>
  );
}
