import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { client } from '../../../utils/api-client';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation } from 'react-query';
import PasswordVerificationForm from 'src/components/PasswordVerificationForm';
import { auth } from 'src/utils/auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function PasswordSetModal() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [password, setPassword] = useState<string>('');

  const mutation = useMutation({
    mutationFn: (password: String) =>
      client.post('/web/settings_v2/my-account/set-password', {
        new_password: password,
        confirm_password: password,
      }),
    onSuccess: () => {
      toast.success(t('passwordSet.success') as string);
      auth.signOut();
      navigate('/login');
    },
    onError: () => {
      toast.error(t('passwordSet.error') as string);
    },
  });

  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle>{t('passwordSet.title')}</DialogTitle>
      <DialogContent style={{ paddingTop: '8px' }}>
        <PasswordVerificationForm
          setNewPassword={setPassword}
          translationPrefix="passwordSet"
        />
        <DialogActions>
          <Button
            variant="contained"
            endIcon={<KeyboardArrowRightIcon />}
            disabled={mutation.isLoading || !password}
            onClick={() => mutation.mutate(password)}
          >
            {t('passwordSet.button')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
