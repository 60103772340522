import React, { useRef, useState } from 'react';
import useFileUpload from 'react-use-file-upload';
import cloud from '../../images/cloud.png';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { client } from 'src/utils/api-client';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { CheckCircle, Close } from '@mui/icons-material';
import { UploadedFileSchema } from '../../Ghg/CodeOfConductSurvey/types';
import { StyledErrorMessage } from '../../views/components/QuestionnaireV3/Field/index.styles';
import { StyledDialogContent } from './styles';
import { AxiosError } from 'axios';
import { FileUploadError, fileTypes } from './types';

interface Props {
  url: string;
  open: boolean;
  onClose?: () => void;
  onSuccess: (file: UploadedFileSchema) => Promise<unknown> | unknown;
  tryExtractErrorMessage?: (uploadError: FileUploadError) => string | undefined;
}

export default function FileUploadModal(props: Props) {
  const {
    files,
    fileNames,
    handleDragDropEvent,
    setFiles,
    removeFile,
    clearAllFiles,
  } = useFileUpload();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [error, setError] = useState<string | undefined>();
  const [uploadSuccess, setUploadSuccess] = useState(false);

  React.useEffect(() => clearAllFiles(), [props.open]);

  const extractErrorMessage = (err: AxiosError<any>): string | undefined => {
    const detail = err.response?.data?.detail;
    if (!(detail in FileUploadError)) return undefined;

    return (
      props.tryExtractErrorMessage?.(detail as FileUploadError) ||
      t(`esg.${detail}`)
    );
  };

  const upload = useMutation({
    mutationFn: () => {
      const formData = new FormData();
      files.map((file) => formData.append('file', file, file.name));
      return client.post<UploadedFileSchema>(props.url, formData);
    },
    onSuccess: ({ data }) => {
      // handle external logic on success

      const handler = async () => props.onSuccess?.(data);
      handler()
        .then(() => {
          setUploadSuccess(true);
          setError(undefined);
        })
        .catch((err) => {
          // display any thrown errors
          if (err.message) setError(err.message);
        });
    },
    onError: (err) => {
      if (err instanceof AxiosError) {
        const msg = extractErrorMessage(err);
        setError(msg);
      }
    },
  });

  const handleClose = () => {
    setError('');
    clearAllFiles();
    setUploadSuccess(false);
    props.onClose?.();
  };

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={handleClose}>
      <DialogTitle display="flex" justifyContent="space-between" width="100%">
        <span>{t('esg.import')}</span>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <StyledDialogContent>
        {!fileNames.length && (
          <Box
            onDragEnter={(e: any) => handleDragDropEvent(e)}
            onDragOver={(e: any) => handleDragDropEvent(e)}
            onDrop={(e: any) => {
              if (fileTypes.includes(e?.dataTransfer.files[0].type)) {
                handleDragDropEvent(e);
                setFiles(e);
              } else {
                setError(t('esg.INVALID_CONTENT_TYPE'));
              }
            }}
          >
            <img src={cloud} alt="cloud upload" width="215px" />
            <Button
              variant="contained"
              onClick={() => inputRef.current?.click()}
            >
              {t('popup.excel.search')}
            </Button>
            <Typography color="GrayText">{t('popup.excel.drop')}</Typography>
          </Box>
        )}

        {!!fileNames.length && (
          <>
            {uploadSuccess && (
              <div>
                <CheckCircle sx={{ width: '64px', height: '64px' }} />
                <Typography>{t('popup.excel.success')}</Typography>
              </div>
            )}

            {!uploadSuccess && (
              <div>
                {fileNames.map((name) => (
                  <Box
                    key={name}
                    sx={{
                      flexDirection: 'row',
                      gap: '12px',
                      alignItems: 'center',
                    }}
                  >
                    <Typography display="inline" fontWeight={600}>
                      {name}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        removeFile(name);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  variant="contained"
                  disabled={!files.length || upload.isLoading}
                  onClick={() => upload.mutate()}
                  type="button"
                >
                  {t('esg.upload')}
                </Button>
              </div>
            )}
          </>
        )}
        <StyledErrorMessage>{error}</StyledErrorMessage>
      </StyledDialogContent>
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={(e: any) => {
          setFiles(e);
        }}
        multiple={false}
        type="file"
        accept={fileTypes.join(', ')}
      />
    </Dialog>
  );
}
