import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Collapse, Tab, Tabs, ThemeProvider } from '@mui/material';

import Title from 'src/components/Title';
import { setSelectedDate } from 'src/redux-file/questionnaires/thunks';
import { getFirstDateOfPeriod } from 'src/utils/timeConverter';
import { IntuitiveCalendarPeriod } from 'src/views/components/IntuitiveCalendar';
import QuestionnaireTitle from 'src/views/components/Questionnaire/Title';
import {
  Description,
  PageContainer,
  PointNumber,
  PointsElement,
  PointsTitle,
  PointsWrapper,
  PointText,
  Reminder,
  SectionGroupContainer,
} from 'src/views/Pages/Questionnaire/index.styles';
import ExpandMoreButton from 'src/components/ExpandMoreButton';
import { StyledFlex } from 'src/components/StyledFlex';
import { useAppDispatch, useAppSelector } from 'src/redux-file/hooks';
import { ghgTheme } from 'src/Ghg/theme';
import { useGhgOrganization } from '../../common/hooks';

export default function Scope3GoodsAndServices() {
  const { t } = useTranslation();
  const { selectedDate } = useAppSelector((state) => state.questionnaire);
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  const location = useLocation();
  const tabs = ['reported-goods', 'products-from-suppliers', 'code-of-conduct'];
  const tabValue =
    tabs.find((tab) => location.pathname.includes(tab)) || tabs[0];

  return (
    <ThemeProvider theme={ghgTheme}>
      <PageContainer>
        <SectionGroupContainer>
          <StyledFlex>
            <QuestionnaireTitle info text={t('goods-and-services.title')} />
            <ExpandMoreButton expand={expanded} onClick={handleExpandClick}>
              <ExpandMoreIcon />
            </ExpandMoreButton>
          </StyledFlex>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Description>{t('goods-and-services.description')}</Description>
            <PointsTitle>{t('goods-and-services.points.title')}</PointsTitle>
            <PointsWrapper>
              <PointsElement>
                <PointNumber>1</PointNumber>
                <PointText
                  dangerouslySetInnerHTML={{
                    __html: t('goods-and-services.points.1'),
                  }}
                />
              </PointsElement>
              <PointsElement>
                <PointNumber>2</PointNumber>
                <PointText
                  dangerouslySetInnerHTML={{
                    __html: t('goods-and-services.points.2'),
                  }}
                />
              </PointsElement>
              <PointsElement>
                <PointNumber>3</PointNumber>
                <PointText
                  dangerouslySetInnerHTML={{
                    __html: t('goods-and-services.points.3'),
                  }}
                />
              </PointsElement>
              <PointsElement>
                <PointNumber>4</PointNumber>
                <PointText
                  dangerouslySetInnerHTML={{
                    __html: t('goods-and-services.points.4'),
                  }}
                />
              </PointsElement>
            </PointsWrapper>
          </Collapse>
          <Reminder>{t('goods-and-services.reminder')}</Reminder>

          <Title title={t('forms.select-period')} description={null} />
          <IntuitiveCalendarPeriod
            reportingPeriod={reportingPeriod}
            selectedDate={getFirstDateOfPeriod(
              selectedDate ? new Date(selectedDate) : new Date(),
              reportingPeriod
            )}
            setSelectedDate={(selDate: Date) =>
              dispatch(setSelectedDate(selDate))
            }
          />
          <Tabs value={tabValue}>
            <Tab
              value="reported-goods"
              label={t('ghg.scope3GoodsAndServices.tabs.goods')}
              onClick={() =>
                navigate('/ghg/emissions/questionnairepage-goods-and-services')
              }
            />
            <Tab
              value="products-from-suppliers"
              label={t('ghg.scope3GoodsAndServices.tabs.products')}
              onClick={() =>
                navigate(
                  '/ghg/emissions/questionnairepage-goods-and-services/products-from-suppliers'
                )
              }
            />
            <Tab
              value="code-of-conduct"
              label={t('ghg.scope3GoodsAndServices.tabs.codeOfConduct')}
              onClick={() =>
                navigate(
                  '/ghg/emissions/questionnairepage-goods-and-services/code-of-conduct'
                )
              }
            />
          </Tabs>
          <Outlet />
        </SectionGroupContainer>
      </PageContainer>
    </ThemeProvider>
  );
}
