import React from 'react';
import { Container, Item } from './index.styles';
import { Link } from 'react-router-dom';

interface BreadcrumbsItem {
  name: string;
  url?: string;
}

interface Props {
  data: BreadcrumbsItem[];
}

export default function Breadcrumbs({ data }: Props) {
  return (
    <Container>
      {data.map((el) => (
        <Item key={el.name}>
          {el.url ? <Link to={el.url}>{el.name}</Link> : <div>{el.name}</div>}
        </Item>
      ))}
    </Container>
  );
}
