import React from 'react';
import Dialog from '@mui/material/Dialog';
import { styled as styledMUI } from '@mui/material/styles';
import styled from 'styled-components';
import CloseIcon from '../../../views/Pages/ESG/create/closeIcon';
import {
  Close,
  CloseButton,
} from '../../../views/Pages/ESG/create/index.styles';
import { TextField, Select, InputLabel } from '@mui/material';
import { stakeholdersTypes } from '../../../views/Pages/ESG/Report/stakeholders';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: Function;
}

const StyledDialog = styledMUI(Dialog)`
    & .MuiPaper-root {
        padding: 24px;
        border-radius: 16px;
        border: 1px solid var(--Light-grey, #CCC); 
        width: 984px;
        max-width: unset;
    }
`;

const Title = styled.p`
  color: var(--main-dark-main, #38414f);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.48px;
  margin-bottom: 16px;
`;

const Desc = styled.p`
  color: var(--main-dark-pressed, #49525f);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.32px;
  margin-bottom: 24px;
`;

export default function StakeholdersModal({ onClose, open }: Props) {
  const { t } = useTranslation();
  return (
    <StyledDialog open={open} onClose={() => onClose()}>
      <Close type="button" onClick={() => onClose()}>
        <CloseIcon />
      </Close>
      <Title>{t('esg.stakeholders_new_form_modal.title')}</Title>
      <Desc>{t('esg.stakeholders_new_form_modal.desc')}</Desc>
      <TextField
        label={t('esg.stakeholders_new_form_modal.label_text')}
        value=""
        onChange={() => {}}
      />
      <FormControl fullWidth style={{ marginTop: 24 }}>
        <InputLabel id="stakeholdersTypesl">
          {t('esg.stakeholders_new_form_modal.label_select')}
        </InputLabel>
        <Select
          labelId="stakeholdersTypes"
          label={t('esg.stakeholders_new_form_modal.label_select')}
        >
          {stakeholdersTypes.map((el) => (
            <MenuItem key={el} value={el}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ display: 'flex', gap: 24, marginTop: 24 }}>
        <TextField
          fullWidth
          label=""
          value="https://envirly.com/stakeholder/client"
          onChange={() => {}}
        />

        <CloseButton
          style={{ width: 'auto', padding: '0 24px', whiteSpace: 'nowrap' }}
          type="button"
          onClick={() => {}}
        >
          <svg
            style={{ marginRight: 8 }}
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.0625 2.4375H7.3125C6.41875 2.4375 5.6875 3.29062 5.6875 4.33333V17.6042H7.3125V4.33333H17.0625V2.4375ZM19.5 6.22917H10.5625C9.66875 6.22917 8.9375 7.08229 8.9375 8.125V21.3958C8.9375 22.4385 9.66875 23.2917 10.5625 23.2917H19.5C20.3938 23.2917 21.125 22.4385 21.125 21.3958V8.125C21.125 7.08229 20.3938 6.22917 19.5 6.22917ZM19.5 21.3958H10.5625V8.125H19.5V21.3958Z"
              fill="white"
            />
          </svg>
          {t('esg.stakeholders_new_form_modal.button_copy')}
        </CloseButton>
      </div>
    </StyledDialog>
  );
}
