import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteFreesolo, Select } from '../fields';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import { Overlay } from '../Title/index.styles';
import { product_industry } from './data';
import { CheckboxContainer, Form, FormBox } from './index.styles';
import { useGhgOrganization } from 'src/common/hooks';
import FormButtons from '../FormButtons';
import OneValueCheckboxField from '../../../../components/CheckboxField';
import { GoodData } from 'src/Ghg/Scope3GoodsAndServices/types';
import { asNumber } from '../../../../redux-file/questionnaires/thunks';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from '../../../../Ghg/interfaces';

type FormData = any[]; // it is what it is

export default function GoodsAndServicesForm({
  state,
  setState,
  id,
  active,
  setActive,
  variant,
  setNewForm,
  update,
}: {
  state: FormData;
  setState: (data: FormData) => unknown;
  id: number;
  setActive: Function;
  active: undefined | number;
  setNewForm: Function;
  update: (data: GoodData[]) => Promise<unknown>;
  variant: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
}) {
  const organization = useGhgOrganization();
  const reportingCurrency = organization.co2.reporting_currency;

  const { t } = useTranslation();
  const questionVariant =
    variant === GOODS_AND_SERVICES_OR_CAPITAL_GOODS.GOODS_AND_SERVICES
      ? 'question1'
      : 'question2';

  const [
    spending_pln_per_period_error_state,
    set_spending_pln_per_period_error_state,
  ] = useState(false);
  let spending_pln_per_period_error = false;
  const spending_pln_per_period_validation = (value: string) => {
    if (value === '') spending_pln_per_period_error = true;
    return spending_pln_per_period_error;
  };

  const [product_industry_error_state, set_product_industry_error_state] =
    useState(false);
  let product_industry_error = false;
  const product_industry_validation = (value: string) => {
    if (value === '') product_industry_error = true;
    return product_industry_error;
  };

  const [factor_error_state, set_factor_error_state] = useState(false);
  let factor_error = false;
  const factor_validation = (value: string) => {
    if (value === '') factor_error = true;
    return factor_error;
  };

  const [amount_error_state, set_amount_error_state] = useState(false);
  let amount_error = false;
  const amount_validation = (value: string) => {
    if (value === '') amount_error = true;
    return amount_error;
  };

  const handleEmissionFactor = () => {
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name_label: tempForm.name_label,
      emission_factor_is_known: !state[id].emission_factor_is_known,
      comment: tempForm.comment,
      good_data_factor_known: state[id].emission_factor_is_known
        ? tempForm.good_data_factor_known
        : {
            factor: '',
            amount: '',
            unit_label: '',
          },
      good_data_factor_unknown: state[id].emission_factor_is_known
        ? {
            spending_pln_per_period: '',
            product_industry: '',
          }
        : tempForm.good_data_factor_unknown,
    };

    newForms[id] = tempForm;
    setState(newForms);
  };

  const handleName = (value: string) => {
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      ...tempForm,
      name_label: value,
    };

    newForms[id] = tempForm;
    setState(newForms);
  };

  const handleUnknownIndustry = (value: string) => {
    product_industry_validation(value);
    set_product_industry_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name_label: tempForm.name_label,
      emission_factor_is_known: tempForm.emission_factor_is_known,
      comment: tempForm.comment,
      good_data_factor_known: tempForm.good_data_factor_known,
      good_data_factor_unknown: {
        spending_pln_per_period:
          tempForm.good_data_factor_unknown.spending_pln_per_period,
        product_industry: value,
      },
    };

    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_product_industry_error_state(true);
  };

  const handleUnknownSpending = (value: string) => {
    spending_pln_per_period_validation(value);
    set_spending_pln_per_period_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name_label: tempForm.name_label,
      emission_factor_is_known: tempForm.emission_factor_is_known,
      comment: tempForm.comment,
      good_data_factor_known: tempForm.good_data_factor_known,
      good_data_factor_unknown: {
        spending_pln_per_period: value,
        product_industry: tempForm.good_data_factor_unknown.product_industry,
      },
    };

    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_spending_pln_per_period_error_state(true);
  };

  const handleKnownUnitLabel = (value: string) => {
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name_label: tempForm.name_label,
      emission_factor_is_known: tempForm.emission_factor_is_known,
      comment: tempForm.comment,
      good_data_factor_known: {
        factor: tempForm.good_data_factor_known.factor,
        amount: tempForm.good_data_factor_known.amount,
        unit_label: value,
      },
      good_data_factor_unknown: tempForm.good_data_factor_unknown,
    };

    newForms[id] = tempForm;
    setState(newForms);
  };

  const handleKnownAmount = (value: string) => {
    amount_validation(value);
    set_amount_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name_label: tempForm.name_label,
      emission_factor_is_known: tempForm.emission_factor_is_known,
      comment: tempForm.comment,
      good_data_factor_known: {
        factor: tempForm.good_data_factor_known.factor,
        amount: value,
        unit_label: tempForm.good_data_factor_known.unit_label,
      },
      good_data_factor_unknown: tempForm.good_data_factor_unknown,
    };

    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_amount_error_state(true);
  };
  const handleKnownFactor = (value: string) => {
    factor_validation(value);
    set_factor_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name_label: tempForm.name_label,
      emission_factor_is_known: tempForm.emission_factor_is_known,
      comment: tempForm.comment,
      good_data_factor_known: {
        factor: value,
        amount: tempForm.good_data_factor_known.amount,
        unit_label: tempForm.good_data_factor_known.unit_label,
      },
      good_data_factor_unknown: tempForm.good_data_factor_unknown,
    };

    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_factor_error_state(true);
  };

  const handleSend = (data: FormData) => {
    if (
      !spending_pln_per_period_error &&
      !product_industry_error &&
      !factor_error &&
      !amount_error
    ) {
      // cleanup models before sending them
      const items = data.map((x: any) => {
        const known = x.good_data_factor_known;
        const unknown = x.good_data_factor_unknown;
        const result: GoodData = {
          comment: x.comment || '',
          emission_factor_is_known: x.emission_factor_is_known,
          name_label: x.name_label || '',
          good_data_factor_known: x.emission_factor_is_known
            ? {
                factor: asNumber(known.factor)!,
                amount: asNumber(known.amount)!,
                unit_label: known.unit_label || '',
              }
            : null,
          good_data_factor_unknown: x.emission_factor_is_known
            ? null
            : {
                spending_pln_per_period: asNumber(
                  unknown.spending_pln_per_period
                )!,
                product_industry: unknown.product_industry || '',
              },
        };
        return result;
      });
      update(items);
      setActive(undefined);
    }
  };

  const defineErrors = () => {
    if (state[id].emission_factor_is_known === false) {
      if (state[id].good_data_factor_unknown.spending_pln_per_period === '') {
        set_spending_pln_per_period_error_state(true);
        spending_pln_per_period_error = true;
      }
      if (state[id].good_data_factor_unknown.product_industry === '') {
        set_product_industry_error_state(true);
        product_industry_error = true;
      }
    }
    if (state[id].emission_factor_is_known === true) {
      if (state[id].good_data_factor_known.factor === '') {
        set_factor_error_state(true);
        factor_error = true;
      }
      if (state[id].good_data_factor_known.amount === '') {
        set_amount_error_state(true);
        amount_error = true;
      }
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    handleSend(tempState);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() => {
            defineErrors();
            handleSend(state);
          }}
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <CheckboxContainer>
            <OneValueCheckboxField
              label={t(
                `goods-and-services.${questionVariant}.form.i-know-factor`
              )}
              setValue={() => handleEmissionFactor()}
              value={state[id].emission_factor_is_known}
            />
          </CheckboxContainer>
          {state[id].emission_factor_is_known === false ? (
            <>
              <FormBox variant="100">
                <AutocompleteFreesolo
                  active
                  error={false}
                  index={1}
                  label={t(`goods-and-services.${questionVariant}.form.name`)}
                  value={state[id].name_label}
                  setValue={(value: string) => handleName(value)}
                  max={123}
                  options={[]}
                />
              </FormBox>
              <FormBox variant="100">
                <Select
                  active
                  error={product_industry_error_state}
                  index={2}
                  label={t(
                    `goods-and-services.${questionVariant}.form.product-industry`
                  )}
                  value={state[id].good_data_factor_unknown.product_industry}
                  setValue={(value: string) => handleUnknownIndustry(value)}
                  max={123}
                  options={product_industry}
                  sortAlphabetically
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  type="currency"
                  label={t(
                    `goods-and-services.${questionVariant}.form.spendings`,
                    { currency: reportingCurrency }
                  )}
                  active
                  error={spending_pln_per_period_error_state}
                  index={1}
                  value={
                    state[id].good_data_factor_unknown.spending_pln_per_period
                  }
                  setValue={(e: string) => handleUnknownSpending(e)}
                />
              </FormBox>
            </>
          ) : (
            <>
              <FormBox variant="100">
                <AutocompleteFreesolo
                  active
                  error={false}
                  index={1}
                  label={t(`goods-and-services.${questionVariant}.form.name`)}
                  value={state[id].name_label}
                  setValue={(value: string) => handleName(value)}
                  max={123}
                  options={[]}
                />
              </FormBox>
              <FormBox variant="50">
                <TextFieldNumber
                  label={t(
                    `goods-and-services.${questionVariant}.form.amount-of-emission`
                  )}
                  active
                  error={amount_error_state}
                  index={1}
                  value={state[id].good_data_factor_known.amount}
                  setValue={(e: string) => handleKnownAmount(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <AutocompleteFreesolo
                  active
                  error={false}
                  index={1}
                  label={t(
                    `goods-and-services.${questionVariant}.form.unit-factor`
                  )}
                  value={state[id].good_data_factor_known.unit_label}
                  setValue={(value: string) => handleKnownUnitLabel(value)}
                  max={123}
                  options={[]}
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  type="factor"
                  label={t(`goods-and-services.${questionVariant}.form.factor`)}
                  active
                  error={factor_error_state}
                  index={1}
                  value={state[id].good_data_factor_known.factor}
                  setValue={(e: string) => handleKnownFactor(e)}
                />
              </FormBox>
            </>
          )}
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={
              (!state[id].emission_factor_is_known &&
                state[id].good_data_factor_unknown.spending_pln_per_period !==
                  '' &&
                state[id].good_data_factor_unknown.product_industry !== '') ||
              (state[id].emission_factor_is_known &&
                state[id].good_data_factor_known.factor !== '' &&
                state[id].good_data_factor_known.amount !== '')
            }
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
