import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { Cell, CellType } from '../types';
import StyledLi from 'src/components/StyledLi';
import { FieldValue } from '../../Field/Fields/types';

interface Props {
  columns: string[];
  rows: Cell[][];
}

const renderValue = (value: FieldValue, type: CellType) => {
  switch (type) {
    case CellType.Question:
      return <Typography sx={{ fontWeight: 600 }}>{value}</Typography>;
    case CellType.Value:
      return <StyledLi value={value} sx={{ pl: '14px' }} />;
    default:
      return <Typography>{value}</Typography>;
  }
};
const renderCell = (cell: Cell) => {
  if (!cell) {
    // can happend if there are differences in questionnaire translations
    return <Typography>-</Typography>;
  }
  return Array.isArray(cell.value)
    ? cell.value.map((v, vIdx) => (
        <Box key={v + vIdx}>{renderValue(v, cell.type)}</Box>
      ))
    : renderValue(cell.value, cell.type);
};

export default function Page(props: Props) {
  const { columns, rows } = props;
  const colWidth = `${100 / columns.length}%`;
  return (
    <div style={{ overflowX: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell
                sx={{ verticalAlign: 'top', width: colWidth }}
                key={col}
              >
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rIdx) => (
            <TableRow key={`row-${rIdx}`}>
              {columns.map((_, cIdx) => (
                <TableCell
                  sx={{ verticalAlign: 'top' }}
                  key={`cell-${rIdx}-${cIdx}`}
                >
                  {renderCell(row[cIdx])}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
