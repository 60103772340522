import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';
import BasePage from '../Base';
import { OurMissionDataType } from './types';
import { LOGO_MARGIN_LEFT } from '../../config';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  text: {
    color: '#606462',
    fontFamily: 'Poppins',
    fontSize: 26,
    fontStyle: 'normal',
    fontWeight: 500,
    position: 'absolute',
    top: 330,
    left: LOGO_MARGIN_LEFT,
    width: 700,
    height: 700,
    lineHeight: 1.8,
  },
});

type OurMissionProps = {
  data: {
    our_mission: OurMissionDataType;
  };
};

const OurMissionPage = ({ data }: OurMissionProps) => {
  const {
    top_right: topRight,
    main_header: mainHeader,
    sub_header: subHeader,
    text,
  } = data.our_mission;

  return (
    <BasePage
      backgroundImageName="Report_envirly_slide_33.png"
      topRight={topRight}
      mainHeader={mainHeader}
      subHeader={subHeader}
    >
      <Text style={styles.text}>{text}</Text>
    </BasePage>
  );
};

export default OurMissionPage;
