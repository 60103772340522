import React, { useEffect, useRef, useState } from 'react';
import AddButtonHolder from '../../components/Questionnaire/AddButtonHolder';
import {
  Description,
  PageContainer,
  Reminder,
  SectionGroupContainer,
  Section,
  StyledHeader,
  StyledTh,
  PreviewBox,
  CopyWrapper,
} from './index.styles';
import QuestionnaireTitle from '../../components/Questionnaire/Title';
import Title from '../../../components/Title';
import {
  getTransportationAndDistributionData,
  getTransportationAndDistributionDataEmissionShares,
  putTransportationAndDistributionData,
  setSelectedDate,
} from '../../../redux-file/questionnaires/thunks';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { useGhgOrganization, useLanguage } from 'src/common/hooks';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import PageLoading from '../../../components/PageLoading';
import {
  timeConverter,
  getFirstDateOfPeriod,
} from '../../../utils/timeConverter';
import QuestionnaireSectionTitle from '../../components/QuestionnaireSectionTitle';
import TransportationPreview from '../../components/Questionnaire/preview/transportationPreview';
import TransportUpstreamForm from '../../components/Questionnaire/forms/transportUpstreamForm';
import DistributionForm from '../../components/Questionnaire/forms/distributionForm';
import DistributionPreview from '../../components/Questionnaire/preview/distributionPreview';
import AddProductButton from '../../../components/AddProductButton';
import {
  downstream_distribution_cloud,
  downstream_transportation_cloud,
  upstream_distribution_cloud,
  upstream_transportation_cloud,
} from './clouds_data';
import UploadExcelButton from '../../../components/UploadExcelButton';
import { IntuitiveCalendarPeriod } from '../../components/IntuitiveCalendar';
import CopyForm from '../../components/CopyForm';

export default function TransportationAndDistribution() {
  const { t, language } = useLanguage();

  const dispatch = useAppDispatch();
  const {
    transportationAndDistribution,
    transportationAndDistributionError,
    transportationAndDistributionLoading,
    transportationAndDistributionEmissionShares,
    selectedDate,
  } = useAppSelector((state) => state.questionnaire);

  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const [newFormTU, setNewFormTU] = useState(true);
  const [newFormTD, setNewFormTD] = useState(true);
  const [newFormDD, setNewFormDD] = useState(true);
  const [newFormDU, setNewFormDU] = useState(true);

  const [active1, setActive1] = useState<undefined | number>(undefined);
  const [active2, setActive2] = useState<undefined | number>(undefined);
  const [active3, setActive3] = useState<undefined | number>(undefined);
  const [active4, setActive4] = useState<undefined | number>(undefined);

  const transStateInit = {
    emission_factor_is_known: false,
    transportation_data_factor_unknown: {
      type_of_transportation: '',
      distance_km: '',
      weight: '',
      weight_unit: '',
    },
    transportation_data_factor_known: {
      factor: '',
      amount: '',
      unit_label: '',
    },
    comment: '',
  };

  const distributionStateInit = {
    emission_factor_is_known: false,
    distribution_data_factor_unknown: {
      amount_stored_kg_in_period: '',
      storage_duration_days_in_period: '',
    },
    distribution_data_factor_known: {
      factor: '',
      amount: '',
      unit_label: '',
    },
    comment: '',
  };

  const [transUpstreamState, setTransUpstreamState] = useState([
    transStateInit,
  ]);
  const [transDownstreamState, setTransDownstreamState] = useState([
    transStateInit,
  ]);

  const [distUpstreamState, setDistUpstreamState] = useState([
    distributionStateInit,
  ]);

  const [distDownstreamState, setDistDownstreamState] = useState([
    distributionStateInit,
  ]);

  const combinedData = {
    transportation: {
      upstream:
        transUpstreamState.length === 1 &&
        transUpstreamState[0].emission_factor_is_known === false &&
        transUpstreamState[0].transportation_data_factor_unknown
          ?.type_of_transportation === ''
          ? []
          : transUpstreamState,
      downstream:
        transDownstreamState.length === 1 &&
        transDownstreamState[0].emission_factor_is_known === false &&
        transDownstreamState[0].transportation_data_factor_unknown
          .type_of_transportation === ''
          ? []
          : transDownstreamState,
    },
    distribution: {
      upstream:
        distUpstreamState.length === 1 &&
        distUpstreamState[0].emission_factor_is_known === false &&
        distUpstreamState[0].distribution_data_factor_unknown
          .storage_duration_days_in_period === ''
          ? []
          : distUpstreamState,
      downstream:
        distDownstreamState.length === 1 &&
        distDownstreamState[0].emission_factor_is_known === false &&
        distDownstreamState[0].distribution_data_factor_unknown
          .storage_duration_days_in_period === ''
          ? []
          : distDownstreamState,
    },
  };

  const transportation_upstream_ref = useRef(null);
  const transportation_downstream_ref = useRef(null);
  const distribution_upstream_ref = useRef(null);
  const distribution_downstream_ref = useRef(null);

  useEffect(() => {
    if (transportationAndDistribution.transportation.upstream.length === 0) {
      setNewFormTU(true);
    } else {
      setNewFormTU(false);
    }

    setTransUpstreamState(
      transportationAndDistribution.transportation.upstream.length !== 0 &&
        transportationAndDistributionEmissionShares.transportation_upstream
          .length !== 0
        ? transportationAndDistribution.transportation.upstream.map(
            (el: any, i: number) => ({
              ...el,
              emission_shared:
                transportationAndDistributionEmissionShares
                  .transportation_upstream[i]?.share,
            })
          )
        : [transStateInit]
    );

    if (transportationAndDistribution.transportation.downstream.length === 0) {
      setNewFormTD(true);
    } else {
      setNewFormTD(false);
    }

    setTransDownstreamState(
      transportationAndDistribution.transportation.downstream.length !== 0 &&
        transportationAndDistributionEmissionShares.transportation_downstream
          .length !== 0
        ? transportationAndDistribution.transportation.downstream.map(
            (el: any, i: number) => ({
              ...el,
              emission_shared:
                transportationAndDistributionEmissionShares
                  .transportation_downstream[i]?.share,
            })
          )
        : [transStateInit]
    );

    if (transportationAndDistribution.distribution.upstream.length === 0) {
      setNewFormDU(true);
    } else {
      setNewFormDU(false);
    }

    setDistUpstreamState(
      transportationAndDistribution.distribution.upstream.length !== 0 &&
        transportationAndDistributionEmissionShares.distribution_upstream
          .length !== 0
        ? transportationAndDistribution.distribution.upstream.map(
            (el: any, i: number) => ({
              ...el,
              emission_shared:
                transportationAndDistributionEmissionShares
                  .distribution_upstream[i]?.share,
            })
          )
        : [distributionStateInit]
    );

    if (transportationAndDistribution.distribution.downstream.length === 0) {
      setNewFormDD(true);
    } else {
      setNewFormDD(false);
    }

    setDistDownstreamState(
      transportationAndDistribution.distribution.downstream.length !== 0 &&
        transportationAndDistributionEmissionShares.distribution_downstream
          .length !== 0
        ? transportationAndDistribution.distribution.downstream.map(
            (el: any, i: number) => ({
              ...el,
              emission_shared:
                transportationAndDistributionEmissionShares
                  .distribution_downstream[i]?.share,
            })
          )
        : [distributionStateInit]
    );
  }, [
    selectedDate,
    transportationAndDistribution,
    transportationAndDistributionEmissionShares,
  ]);

  const refreshForm = () => {
    dispatch(
      getTransportationAndDistributionData({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
    dispatch(
      getTransportationAndDistributionDataEmissionShares({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
  };

  const sendData = (fullData: any) => {
    dispatch(
      putTransportationAndDistributionData({
        period: timeConverter(selectedDate, reportingPeriod),
        data: fullData,
      })
    );
  };

  useEffect(() => {
    if (!!reportingPeriod) {
      refreshForm();
    }
  }, [selectedDate, organization.id, reportingPeriod]);

  const combineUpstreamTransportationData = (
    fullData: any,
    transportationData: any
  ) => {
    return {
      transportation: {
        upstream: [...transportationData, ...fullData.transportation.upstream],
        downstream: fullData.transportation.downstream,
      },
      distribution: fullData.distribution,
    };
  };
  const combineDownstreamTransportationData = (
    fullData: any,
    transportationData: any
  ) => {
    return {
      transportation: {
        upstream: fullData.transportation.upstream,
        downstream: [
          ...transportationData,
          ...fullData.transportation.downstream,
        ],
      },
      distribution: fullData.distribution,
    };
  };

  const combineUpstreamDistributionData = (
    fullData: any,
    distributionData: any
  ) => {
    return {
      transportation: fullData.transportation,
      distribution: {
        upstream: [...distributionData, ...fullData.distribution.upstream],
        downstream: fullData.distribution.downstream,
      },
    };
  };
  const combineDownstreamDistributionData = (
    fullData: any,
    distributionData: any
  ) => {
    return {
      transportation: fullData.transportation,
      distribution: {
        upstream: fullData.distribution.upstream,
        downstream: [...distributionData, ...fullData.distribution.downstream],
      },
    };
  };

  return (
    <PageContainer>
      <SectionGroupContainer>
        <QuestionnaireTitle info text={t('transportation.title')} />
        <Description>{t('transportation.description1')}</Description>
        <Description
          dangerouslySetInnerHTML={{ __html: t('transportation.description2') }}
        />
        <Description
          dangerouslySetInnerHTML={{ __html: t('transportation.description3') }}
        />
        <Reminder>{t('transportation.reminder')}</Reminder>

        <CopyWrapper>
          <Title title={t('forms.select-period')} description={null} />
          <CopyForm
            form="TransportationAndDistributionData"
            orgId={organization.id as number}
            refresh={() => refreshForm()}
          />
        </CopyWrapper>
        <IntuitiveCalendarPeriod
          reportingPeriod={reportingPeriod}
          selectedDate={getFirstDateOfPeriod(
            selectedDate ? new Date(selectedDate) : new Date(),
            reportingPeriod ?? 'M'
          )}
          setSelectedDate={(selDate: Date) =>
            dispatch(setSelectedDate(selDate))
          }
        />
        {transportationAndDistributionError && <SomethingWentWrong />}
        {transportationAndDistributionLoading && <PageLoading />}
        {!transportationAndDistributionError &&
          !transportationAndDistributionLoading && (
            <>
              <Section>
                <div ref={transportation_upstream_ref} />
                <QuestionnaireSectionTitle
                  level="primary"
                  text={t('transportation.upstream-transportation.title')}
                  boxContent={upstream_transportation_cloud}
                  active={active1}
                />
                {newFormTU && (
                  <>
                    <PreviewBox>
                      {transUpstreamState.map((el, id) => (
                        <TransportUpstreamForm
                          setNewForm={setNewFormTU}
                          combinedData={combinedData}
                          active={active1}
                          setActive={setActive1}
                          variant="u"
                          id={id}
                          key={id}
                          setState={setTransUpstreamState}
                          state={transUpstreamState}
                        />
                      ))}
                    </PreviewBox>
                    <UploadExcelButton
                      name={t('transportation.upstream-transportation.title')}
                      slug="TransportationData"
                      fullData={combinedData}
                      sendData={sendData}
                      combineData={combineUpstreamTransportationData}
                      refresh={refreshForm}
                    />
                  </>
                )}
                {!newFormTU && (
                  <>
                    <PreviewBox>
                      <StyledHeader>
                        <StyledTh width={25}>
                          {t(
                            'transportation.upstream-transportation.table.col1'
                          )}
                        </StyledTh>
                        <StyledTh width={25}>
                          {t(
                            'transportation.upstream-transportation.table.col2'
                          )}
                        </StyledTh>
                        <StyledTh width={12}>
                          {t(
                            'transportation.upstream-transportation.table.col3'
                          )}
                        </StyledTh>
                        <StyledTh width={13}>
                          {t(
                            'transportation.upstream-transportation.table.col4'
                          )}
                        </StyledTh>
                        <StyledTh width={25}>
                          {t(
                            'direct-emission.stationary-combustion.table.emission_shared'
                          )}
                        </StyledTh>
                        <StyledTh width={0}>
                          {t('sold-products.preview.edit')}
                        </StyledTh>
                      </StyledHeader>
                      <div>
                        {transUpstreamState.map((el, id) => {
                          if (active1 === id) {
                            return (
                              <TransportUpstreamForm
                                setNewForm={setNewFormTU}
                                combinedData={combinedData}
                                active={active1}
                                setActive={setActive1}
                                variant="u"
                                id={id}
                                key={id}
                                setState={setTransUpstreamState}
                                state={transUpstreamState}
                              />
                            );
                          } else {
                            return (
                              <TransportationPreview
                                language={language}
                                setState={setTransUpstreamState}
                                id={id}
                                setActive={setActive1}
                                key={id}
                                state={transUpstreamState}
                              />
                            );
                          }
                        })}
                      </div>
                    </PreviewBox>
                    <AddButtonHolder
                      firstChild={
                        <AddProductButton
                          onClick={() => {
                            setTransUpstreamState([
                              transStateInit,
                              ...transUpstreamState,
                            ]);
                            setActive1(0);
                            if (
                              transportation_upstream_ref &&
                              transportation_upstream_ref.current
                            ) {
                              // @ts-ignore
                              transportation_upstream_ref.current.scrollIntoView(
                                {
                                  behavior: 'smooth',
                                }
                              );
                            }
                          }}
                        />
                      }
                      secondChild={
                        <UploadExcelButton
                          name={t(
                            'transportation.upstream-transportation.title'
                          )}
                          slug="TransportationData"
                          fullData={combinedData}
                          sendData={sendData}
                          combineData={combineUpstreamTransportationData}
                          refresh={refreshForm}
                        />
                      }
                    />
                  </>
                )}
              </Section>

              <Section>
                <div ref={transportation_downstream_ref} />
                <QuestionnaireSectionTitle
                  level="primary"
                  text={t('transportation.downstream-transportation.title')}
                  boxContent={downstream_transportation_cloud}
                  active={active2}
                />
                {newFormTD && (
                  <>
                    <PreviewBox>
                      {transDownstreamState.map((el, id) => (
                        <TransportUpstreamForm
                          setNewForm={setNewFormTD}
                          combinedData={combinedData}
                          active={active2}
                          setActive={setActive2}
                          variant="d"
                          id={id}
                          key={id}
                          setState={setTransDownstreamState}
                          state={transDownstreamState}
                        />
                      ))}
                    </PreviewBox>
                    <UploadExcelButton
                      name={t('transportation.downstream-transportation.title')}
                      slug="TransportationData"
                      fullData={combinedData}
                      sendData={sendData}
                      combineData={combineDownstreamTransportationData}
                      refresh={refreshForm}
                    />
                  </>
                )}
                {!newFormTD && (
                  <>
                    <PreviewBox>
                      <StyledHeader>
                        <StyledTh width={25}>
                          {t(
                            'transportation.upstream-transportation.table.col1'
                          )}
                        </StyledTh>
                        <StyledTh width={25}>
                          {t(
                            'transportation.upstream-transportation.table.col2'
                          )}
                        </StyledTh>
                        <StyledTh width={12}>
                          {t(
                            'transportation.upstream-transportation.table.col3'
                          )}
                        </StyledTh>
                        <StyledTh width={13}>
                          {t(
                            'transportation.upstream-transportation.table.col4'
                          )}
                        </StyledTh>
                        <StyledTh width={25}>
                          {t(
                            'direct-emission.stationary-combustion.table.emission_shared'
                          )}
                        </StyledTh>

                        <StyledTh width={0}>
                          {t('sold-products.preview.edit')}
                        </StyledTh>
                      </StyledHeader>
                      <div>
                        {transDownstreamState.map((el, id) => {
                          if (active2 === id) {
                            return (
                              <TransportUpstreamForm
                                setNewForm={setNewFormTD}
                                combinedData={combinedData}
                                active={active2}
                                setActive={setActive2}
                                variant="d"
                                id={id}
                                key={id}
                                setState={setTransDownstreamState}
                                state={transDownstreamState}
                              />
                            );
                          } else {
                            return (
                              <TransportationPreview
                                language={language}
                                setState={setTransDownstreamState}
                                id={id}
                                setActive={setActive2}
                                key={id}
                                state={transDownstreamState}
                              />
                            );
                          }
                        })}
                      </div>
                    </PreviewBox>
                    <AddButtonHolder
                      firstChild={
                        <AddProductButton
                          onClick={() => {
                            setTransDownstreamState([
                              transStateInit,
                              ...transDownstreamState,
                            ]);
                            setActive2(0);
                            if (
                              transportation_downstream_ref &&
                              transportation_downstream_ref.current
                            ) {
                              // @ts-ignore
                              transportation_downstream_ref.current.scrollIntoView(
                                {
                                  behavior: 'smooth',
                                }
                              );
                            }
                          }}
                        />
                      }
                      secondChild={
                        <UploadExcelButton
                          name={t(
                            'transportation.downstream-transportation.title'
                          )}
                          slug="TransportationData"
                          fullData={combinedData}
                          sendData={sendData}
                          combineData={combineDownstreamTransportationData}
                          refresh={refreshForm}
                        />
                      }
                    />
                  </>
                )}
              </Section>

              <Section>
                <div ref={distribution_upstream_ref} />
                <QuestionnaireSectionTitle
                  level="primary"
                  text={t('transportation.upstream-distribution.title')}
                  boxContent={upstream_distribution_cloud}
                  active={active3}
                />
                {newFormDU && (
                  <>
                    <PreviewBox>
                      {distUpstreamState.map((el, id) => (
                        <DistributionForm
                          setNewForm={setNewFormDU}
                          setActive={setActive3}
                          active={active3}
                          variant="u"
                          combinedData={combinedData}
                          id={id}
                          key={id}
                          setState={setDistUpstreamState}
                          state={distUpstreamState}
                        />
                      ))}
                    </PreviewBox>
                    <UploadExcelButton
                      name={t('transportation.upstream-distribution.title')}
                      slug="DistributionData"
                      fullData={combinedData}
                      sendData={sendData}
                      combineData={combineUpstreamDistributionData}
                      refresh={refreshForm}
                    />
                  </>
                )}
                {!newFormDU && (
                  <>
                    <PreviewBox>
                      <StyledHeader>
                        <StyledTh width={25}>
                          {t('transportation.upstream-distribution.table.col1')}
                        </StyledTh>
                        <StyledTh width={35}>
                          {t('transportation.upstream-distribution.table.col2')}
                        </StyledTh>
                        <StyledTh width={15}>
                          {t('transportation.upstream-distribution.table.col3')}
                        </StyledTh>
                        <StyledTh width={25}>
                          {t(
                            'direct-emission.stationary-combustion.table.emission_shared'
                          )}
                        </StyledTh>

                        <StyledTh width={0}>
                          {t('sold-products.preview.edit')}
                        </StyledTh>
                      </StyledHeader>
                      <div>
                        {distUpstreamState.map((el, id) => {
                          if (active3 === id) {
                            return (
                              <DistributionForm
                                setNewForm={setNewFormDU}
                                setActive={setActive3}
                                active={active3}
                                variant="u"
                                combinedData={combinedData}
                                id={id}
                                key={id}
                                setState={setDistUpstreamState}
                                state={distUpstreamState}
                              />
                            );
                          } else {
                            return (
                              <DistributionPreview
                                language={language}
                                setState={setDistUpstreamState}
                                id={id}
                                setActive={setActive3}
                                key={id}
                                state={distUpstreamState}
                              />
                            );
                          }
                        })}
                      </div>
                    </PreviewBox>
                    <AddButtonHolder
                      firstChild={
                        <AddProductButton
                          onClick={() => {
                            setDistUpstreamState([
                              distributionStateInit,
                              ...distUpstreamState,
                            ]);
                            setActive3(0);
                            if (
                              distribution_upstream_ref &&
                              distribution_upstream_ref.current
                            ) {
                              // @ts-ignore
                              distribution_upstream_ref.current.scrollIntoView({
                                behavior: 'smooth',
                              });
                            }
                          }}
                        />
                      }
                      secondChild={
                        <UploadExcelButton
                          name={t('transportation.upstream-distribution.title')}
                          slug="DistributionData"
                          fullData={combinedData}
                          sendData={sendData}
                          combineData={combineUpstreamDistributionData}
                          refresh={refreshForm}
                        />
                      }
                    />
                  </>
                )}
              </Section>

              <Section>
                <div ref={distribution_downstream_ref} />
                <QuestionnaireSectionTitle
                  level="primary"
                  text={t('transportation.downstream-distribution.title')}
                  boxContent={downstream_distribution_cloud}
                  active={active4}
                />
                {newFormDD && (
                  <>
                    <PreviewBox>
                      {distDownstreamState.map((el, id) => (
                        <DistributionForm
                          setNewForm={setNewFormDD}
                          setActive={setActive4}
                          active={active4}
                          variant="d"
                          combinedData={combinedData}
                          id={id}
                          key={id}
                          setState={setDistDownstreamState}
                          state={distDownstreamState}
                        />
                      ))}
                    </PreviewBox>
                    <UploadExcelButton
                      name={t('transportation.downstream-distribution.title')}
                      slug="DistributionData"
                      fullData={combinedData}
                      sendData={sendData}
                      combineData={combineDownstreamDistributionData}
                      refresh={refreshForm}
                    />
                  </>
                )}
                {!newFormDD && (
                  <>
                    <PreviewBox>
                      <StyledHeader>
                        <StyledTh width={25}>
                          {t('transportation.upstream-distribution.table.col1')}
                        </StyledTh>
                        <StyledTh width={35}>
                          {t('transportation.upstream-distribution.table.col2')}
                        </StyledTh>
                        <StyledTh width={15}>
                          {t('transportation.upstream-distribution.table.col3')}
                        </StyledTh>
                        <StyledTh width={25}>
                          {t(
                            'direct-emission.stationary-combustion.table.emission_shared'
                          )}
                        </StyledTh>
                        <StyledTh width={0}>
                          {t('sold-products.preview.edit')}
                        </StyledTh>
                      </StyledHeader>
                      <div>
                        {distDownstreamState.map((el, id) => {
                          if (active4 === id) {
                            return (
                              <DistributionForm
                                setNewForm={setNewFormDD}
                                setActive={setActive4}
                                active={active4}
                                variant="d"
                                combinedData={combinedData}
                                id={id}
                                key={id}
                                setState={setDistDownstreamState}
                                state={distDownstreamState}
                              />
                            );
                          } else {
                            return (
                              <DistributionPreview
                                language={language}
                                setState={setDistDownstreamState}
                                id={id}
                                setActive={setActive4}
                                key={id}
                                state={distDownstreamState}
                              />
                            );
                          }
                        })}
                      </div>
                    </PreviewBox>
                    <AddButtonHolder
                      firstChild={
                        <AddProductButton
                          onClick={() => {
                            setDistDownstreamState([
                              distributionStateInit,
                              ...distDownstreamState,
                            ]);
                            setActive4(0);
                            if (
                              distribution_downstream_ref &&
                              distribution_downstream_ref.current
                            ) {
                              // @ts-ignore
                              distribution_downstream_ref.current.scrollIntoView(
                                {
                                  behavior: 'smooth',
                                }
                              );
                            }
                          }}
                        />
                      }
                      secondChild={
                        <UploadExcelButton
                          name={t(
                            'transportation.downstream-distribution.title'
                          )}
                          slug="DistributionData"
                          fullData={combinedData}
                          sendData={sendData}
                          combineData={combineDownstreamDistributionData}
                          refresh={refreshForm}
                        />
                      }
                    />
                  </>
                )}
              </Section>
            </>
          )}
      </SectionGroupContainer>
    </PageContainer>
  );
}
