import React from 'react';
import { CalendarIcon } from './icons';
import styled from 'styled-components';

const Text = styled.p`
  color: var(--main-dark-main, #38414f);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.64px;
`;

const Period = styled.p`
  color: var(--main-dark-main, #38414f);
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 0;
  letter-spacing: 0.36px;
`;

export default function Title({
  title,
  period,
}: {
  title?: string;
  period: string;
}) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 25,
      }}
    >
      <Text>{title}</Text>
      <div
        style={{
          display: 'flex',
          gap: 8,
          alignItems: 'center',
        }}
      >
        <CalendarIcon />
        <Period>{period}</Period>
      </div>
    </div>
  );
}
