import React from 'react';
import { useTranslation } from 'react-i18next';
import { RowType } from '../../types';
import { Container, Header, HeaderColumn } from '../styles';
import { mapRowsByField, mapRowsByQuestion } from './answers';

/* eslint-disable no-unused-vars */

export enum AggregationMode {
  ByQuestion,
  ByField,
}

export type MultiAnswerTableProps = {
  data: RowType[];
  aggregation: AggregationMode;
};

export default function MultiAnswerTable({
  data,
  aggregation,
}: MultiAnswerTableProps) {
  const { t } = useTranslation();

  const mappedRows =
    aggregation === AggregationMode.ByQuestion
      ? mapRowsByQuestion(data)
      : mapRowsByField(data);

  return (
    <Container>
      <Header>
        <HeaderColumn>{t('esgSummary.tables.no-lp')}</HeaderColumn>
        <HeaderColumn style={{ width: '50%' }}>
          {t('esgSummary.tables.question')}
        </HeaderColumn>
        <HeaderColumn style={{ width: '35%' }}>
          {t('esgSummary.tables.answer')}
        </HeaderColumn>
      </Header>
      {mappedRows}
    </Container>
  );
}
