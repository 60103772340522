import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import themeColors from '../../../../utils/theme';

/* eslint-disable no-unused-vars */

export const Form = styled(
  ({
    active,
    ...props
  }: { active: boolean } & HTMLAttributes<HTMLDivElement>) => <div {...props} />
)`
  z-index: ${({ active }) => (active ? '99' : 1)};
  position: relative;
  border-radius: 16px;
  margin: -24px -24px -4px;
  padding: 24px 24px 4px;
  background: white;

  & > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const FormBox = styled(
  ({
    variant,
    ...props
  }: { variant: '50' | '100' } & HTMLAttributes<HTMLDivElement>) => (
    <div {...props} />
  )
)`
  width: ${({ variant }) => (variant === '100' ? '100%' : 'calc(50% - 10px)')};
  padding-bottom: 24px;
`;

export const CheckboxContainer = styled.div`
  width: 100%;
`;

export const Box = styled.div`
  border-top: 1px solid ${themeColors.strokePressed};
  display: flex;
  justify-content: flex-end !important;
  width: 100%;
`;

export const Button = styled.button`
  z-index: 100;
  position: relative;
  background: transparent;
  margin-top: 16px;
`;

export const SalesRegionBox = styled.div`
  display: grid !important;
  grid-template-columns: 1fr 1fr;
`;
