/* eslint-disable no-unused-vars */

export enum SETTINGS_TABS {
  STRUCTURE_TAB = 'structure',
  USERS_TAB = 'users',
  CO2_PERMISSIONS_TAB = 'co2-permissions',
  ESG_PERMISSIONS_TAB = 'esg-permissions',
  LCA_PERMISSIONS_TAB = 'lca-permissions',
}

// Type guard function
export function isTabKey(value: any): value is SETTINGS_TABS {
  return (
    value === SETTINGS_TABS.STRUCTURE_TAB ||
    value === SETTINGS_TABS.USERS_TAB ||
    value === SETTINGS_TABS.CO2_PERMISSIONS_TAB ||
    value === SETTINGS_TABS.ESG_PERMISSIONS_TAB ||
    value === SETTINGS_TABS.LCA_PERMISSIONS_TAB
  );
}
