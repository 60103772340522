import React, { useState } from 'react';
import styled from 'styled-components';
import {
  RawMaterialsIcon,
  ProductionIcon,
  DistributionIcon,
  UseStageIcon,
  EndOfLifeIcon,
} from './images';
import Dialog from '@mui/material/Dialog';
import {
  RawMaterialsContent,
  ProductionContent,
  DistributionContent,
  UseStageIconContent,
  EndOfLifeIconContent,
} from './modalContent';
import { useTranslation } from 'react-i18next';
import { styled as styledMUI } from '@mui/material/styles';
import { BigButton, BigButtonWhite } from '../index.styles';

const ButtonsWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'boxes boxes boxes boxes boxes'
    'button1 button1 . . .'
    '. button2 . . .'
    'button3 button3 button3 button3 button3'
    '. . button4 button4 button4';

  & > .boxes {
    display: flex;
    column-gap: 24px;
    grid-area: boxes;
    margin-bottom: 18px;
    text-align: center;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: var(--Dark, #281302);

    svg {
      margin-bottom: 16px;
    }

    p {
      font-size: 16px;
      letter-spacing: 0.32px;
      text-align: center;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: var(--Dark, #281302);
    }

    span {
      font-size: 10px;
      letter-spacing: 0.2px;
      text-align: center;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: var(--Dark, #281302);
    }
  }

  & > .button1 {
    grid-area: button1;
    margin-bottom: 16px;
  }
  & > .button2 {
    grid-area: button2;
    margin-bottom: 16px;
  }
  & > .button3 {
    grid-area: button3;
    margin-bottom: 16px;
    z-index: 2;
  }
  & > .button4 {
    grid-area: button4;
  }

  &:before {
    display: block;
    position: absolute;
    content: '';
    width: 1px;
    height: 100%;
    border-left: 1px dashed #a9a9a9;
    left: calc(40% - 2px);
  }
`;

const Box = styled.button`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 24px;
  background: #f6f5f3;
  border: 1px solid #6d3206;
  border-radius: 16px;
`;

const StyledDialog = styledMUI(Dialog)`  
  & .MuiPaper-root {
    min-width:760px;
    border-radius: 24px;
  }
`;

const TitlesWrapper = styled.div`
  display: flex;
  text-align: center;
  column-gap: 24px;
  color: var(--Dark-grey, #4d4d4d);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.54px;
  text-transform: uppercase;
  margin-bottom: 18px;
  margin-top: 48px;
`;

interface Props {
  values: any;
  setValues: Function;
}

export default function BoundariesLCA({ setValues, values }: Props) {
  const { t } = useTranslation();
  const buttonsData = [
    { name: 'cradle_to_gate' },
    { name: 'gate_to_gate' },
    { name: 'cradle_to_grave' },
    { name: 'gate_to_grave' },
  ];

  const handleClick = (name: string) => {
    setValues({ ...values, lifecycle_boundaries: name });
  };

  const [open, setOpen] = useState<
    'raw' | 'production' | 'distribution' | 'stage' | 'end' | null
  >(null);

  return (
    <div>
      <TitlesWrapper>
        <p style={{ width: '20%' }}>CRADLE</p>
        <p style={{ width: '40%' }}>gate</p>
        <p style={{ width: '20%' }} />
        <p style={{ width: '20%' }}>grave</p>
      </TitlesWrapper>
      <ButtonsWrapper>
        <div className="boxes">
          <Box onClick={() => setOpen('raw')}>
            <RawMaterialsIcon />
            <p>RAW MATERIALS</p>
            <span>(extraction and processing)</span>
          </Box>
          <Box onClick={() => setOpen('production')}>
            <ProductionIcon />
            <p>production and packaging</p>
          </Box>
          <Box onClick={() => setOpen('distribution')}>
            <DistributionIcon />
            <p>distribution and storage</p>
          </Box>
          <Box onClick={() => setOpen('stage')}>
            <UseStageIcon />
            <p>use stage</p>
          </Box>
          <Box onClick={() => setOpen('end')}>
            <EndOfLifeIcon />
            <p>end-of-life</p>
          </Box>
        </div>
        {buttonsData.map((el, i) => {
          if (values.lifecycle_boundaries === el.name) {
            return (
              <BigButton
                onClick={() => handleClick(el.name)}
                className={`button${i + 1}`}
                type="button"
                key={el.name}
              >
                {t(`lca.boundaries.${el.name}`)}
              </BigButton>
            );
          } else {
            return (
              <BigButtonWhite
                onClick={() => handleClick(el.name)}
                className={`button${i + 1}`}
                type="button"
                key={el.name}
              >
                {t(`lca.boundaries.${el.name}`)}
              </BigButtonWhite>
            );
          }
        })}
      </ButtonsWrapper>
      <StyledDialog
        open={!!open}
        onClose={() => setOpen(null)}
        style={{ minWidth: '760px' }}
      >
        {open === 'raw' && <RawMaterialsContent />}
        {open === 'production' && <ProductionContent />}
        {open === 'distribution' && <DistributionContent />}
        {open === 'stage' && <UseStageIconContent />}
        {open === 'end' && <EndOfLifeIconContent />}
      </StyledDialog>
    </div>
  );
}
