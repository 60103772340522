import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextFieldNumberProps, TextFieldNumberType } from './index.interfaces';
import {
  StyledTextField,
  ValueField,
  ValueLabel,
  ValueParagraph,
} from './index.styles';

// eslint-disable-next-line prefer-regex-literals
const intRegex = new RegExp('^[+-]?[0-9]+$');

const fieldDefaults: {
  [key in TextFieldNumberType]: Partial<TextFieldNumberProps>;
} = {
  factor: {
    fractionalDigits: 5,
  },
  mass: {
    fractionalDigits: 5,
  },
  integer: {
    fractionalDigits: 0,
  },
  currency: {
    fractionalDigits: 2,
  },
  default: {
    fractionalDigits: 5,
  },
};

export default function TextFieldNumber(baseProps: TextFieldNumberProps) {
  const props: typeof baseProps = {
    ...fieldDefaults[baseProps.type ?? 'default'],
    ...baseProps,
  };

  /* eslint-disable react/prop-types */
  const {
    active,
    allowNegative,
    error,
    index,
    max,
    label,
    setValue,
    value,
    fractionalDigits,
  } = props;
  /* eslint-enable react/prop-types */

  const { t } = useTranslation();

  // eslint-disable-next-line prefer-regex-literals
  const fractionalRegex = new RegExp(
    `^[+-]?(?=.?\\d)\\d*((\\.|,)\\d{0,${fractionalDigits}})?$`
  );
  const regex = fractionalDigits === 0 ? intRegex : fractionalRegex;
  const convertValue = (item: string | number) => {
    if (typeof item === 'string') {
      return item.replace('.', ',');
    } else {
      return item.toString().replace('.', ',');
    }
  };

  return (
    <>
      {active && (
        <StyledTextField
          type="text"
          InputProps={{
            inputMode: 'decimal',
            inputProps: !allowNegative ? { min: 0 } : {},
          }}
          value={Number.isNaN(value) ? '' : convertValue(value)}
          label={t(label)}
          error={error}
          onChange={(event) => {
            const val = event.target.value;
            const isValid =
              regex.test(val) && (allowNegative || !val.startsWith('-'));
            if (isValid || val === '') {
              setValue(val || '');
            }
          }}
        />
      )}
      {!active && !Number.isNaN(value) && (
        <ValueField className="value-field" index={index} max={max}>
          <ValueLabel>{t(label)}</ValueLabel>
          <ValueParagraph>
            {Number.isNaN(value) ? '' : convertValue(value)}
          </ValueParagraph>
        </ValueField>
      )}
    </>
  );
}
