import React from 'react';

export default function EsgNevReport() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="note-add-twotone-24px 1" clipPath="url(#clip0_839_18202)">
        <path
          id="Vector"
          opacity="0.3"
          d="M34.6667 10.667H16V53.3337H48V24.0003H34.6667V10.667ZM42.6667 37.3337V42.667H34.6667V50.667H29.3333V42.667H21.3333V37.3337H29.3333V29.3337H34.6667V37.3337H42.6667Z"
          fill="#5A636F"
        />
        <path
          id="Vector_2"
          d="M34.6641 29.333H29.3307V37.333H21.3307V42.6663H29.3307V50.6663H34.6641V42.6663H42.6641V37.333H34.6641V29.333ZM37.3307 5.33301H15.9974C13.0641 5.33301 10.6641 7.73301 10.6641 10.6663V53.333C10.6641 56.2663 13.0374 58.6663 15.9707 58.6663H47.9974C50.9307 58.6663 53.3307 56.2663 53.3307 53.333V21.333L37.3307 5.33301ZM47.9974 53.333H15.9974V10.6663H34.6641V23.9997H47.9974V53.333Z"
          fill="#5A636F"
        />
      </g>
      <defs>
        <clipPath id="clip0_839_18202">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
