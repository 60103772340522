import React from 'react';
import SurveyContainer, {
  NavButtonProps,
} from '../../../components/Survey/Container';
import Title from '../../../components/Survey/Title';
import { Trans, useTranslation } from 'react-i18next';
import { Logo } from '../../../components/Survey/icons';
import ImageHeader from '../../../components/Survey/ImageHeader';
import { useNavigate } from 'react-router-dom';
import { Subtitle } from '../styles';
import assets from '../assets';
import { useLanguage } from 'src/common/hooks';

const keyPrefix = 'cbam.productionProcessSurvey';

export default function ProductionProcessSurveyIntro() {
  const { t } = useTranslation(undefined, { keyPrefix });
  const { setLanguage } = useLanguage();
  const navigate = useNavigate();
  // const { data } = useProductionProcesses();

  // TODO EN-1837: Get the organization name from the API
  // const orgName = data.length > 0 ? data[0].id : '';

  const nextButton: NavButtonProps = {
    label: `${keyPrefix}.next`,
    onClick: () => navigate('questionnaire'),
  };

  React.useEffect(() => {
    setLanguage('en');
  }, []);

  return (
    <SurveyContainer
      logo={<Logo />}
      nextLink={nextButton}
      header={
        <ImageHeader imageSrc={assets.intro.image} text={t('welcomeHeader')} />
      }
    >
      <div>
        <Title title={t('intro.title')} />
        <Subtitle>{t('intro.subtitle')}</Subtitle>
        <Trans t={t} i18nKey="intro.message" />
        {/* <Typography>{orgName}</Typography> */}
      </div>
    </SurveyContainer>
  );
}
