import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useFuelTypes } from 'src/Cbam/hooks';
import { FuelType } from 'src/Cbam/types';

interface Props {
  value: FuelType | null;
  onChange: (value: FuelType) => void;
}

export default function FuelTypeField(props: Props) {
  const { value, onChange } = props;
  const fuelTypes = useFuelTypes();
  return (
    <FormControl fullWidth sx={{ mb: '12px', mt: '6px' }}>
      <InputLabel id="fuel-type-id-label">Fuel type</InputLabel>
      <Select
        key={Object.keys(fuelTypes.data).length}
        labelId="fuel-type-id-label"
        label="Fuel type"
        value={value || ''}
      >
        {Object.entries(fuelTypes.data).map(([fuelType, _factors]) => (
          <MenuItem
            key={fuelType}
            value={fuelType}
            onClick={() => onChange(fuelType as FuelType)}
          >
            {fuelType}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
