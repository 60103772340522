import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CellType } from './index.types';

export const StyledRow = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4px 0px;
`;

export const StyledTable = styled(Box)`
  width: 100%;
`;

const CELL_MARGIN = 20; // px

export const StyledCell = styled(Box)<{
  w: string | number;
  type: CellType;
  depth?: number;
}>`
  width: calc(
    ${(props) => props.w} - ${(props) => CELL_MARGIN * (props.depth || 0)}px
  );
  background-color: ${(props) => {
    if (props.type === 'header') return '#38414f';
    return '#f2f2f2';
  }};
  border-left: 5px solid
    ${(props) => {
      if (props.type === 'unit') return '#91D165';
      return '#fff';
    }};
  color: ${(props) => {
    if (props.type === 'header') return '#fff';
    return '#38414F';
  }};
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.45px;
  padding: 15px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.depth || 0) * CELL_MARGIN}px;
`;

export const StyledProductCellContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px;
  font-family: Poppins;
`;

export const IconHolder = styled(Box)`
  width: 24px;
  height: 24px;
`;
