import { Typography } from '@mui/material';
import React from 'react';
import InfoPanel from 'src/Cbam/components/InfoPanel';

export default function ProductionProcessInfoPanel() {
  return (
    <InfoPanel>
      <Typography>
        Production Process means the chemical and physical processes carried out
        to produce goods in an Installation. If an Installation produces
        multiple goods, each good should have a separate Production Process and
        Installation emissions should be split and attributed to each Production
        Process.
      </Typography>
    </InfoPanel>
  );
}
