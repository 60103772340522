import React from 'react';

import {
  TextField as MuiTextField,
  TextFieldVariants,
  TextFieldProps as MuiTextFieldProps,
  InputBaseComponentProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export type TextFieldProps<Variant> = {
  variant?: Variant;
} & Omit<MuiTextFieldProps, 'variant'> & {
    errorText?: React.ReactNode | null;
  };

export default function TextField<Variant extends TextFieldVariants>(
  props: TextFieldProps<Variant>
) {
  const { t } = useTranslation();
  const isError = props.errorText !== undefined;
  const helperText =
    typeof props.errorText === 'string' ? t(props.errorText) : undefined;
  // disable autocomplete by default
  // https://stackoverflow.com/a/56224685
  const inputProps: InputBaseComponentProps = {
    autoComplete: 'new-password',
    ...props.inputProps,
    form: {
      autoComplete: 'off',
      ...props.inputProps?.form,
    },
  };

  return (
    <MuiTextField
      error={isError}
      helperText={helperText}
      {...props}
      inputProps={inputProps}
    />
  );
}
