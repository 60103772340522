import React from 'react';

export default function CalendarIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_737_78745)">
        <path
          d="M18.7273 3.63636H17.9091V2H16.2727V3.63636H8.09091V2H6.45455V3.63636H5.63636C4.73636 3.63636 4 4.37273 4 5.27273V18.3636C4 19.2636 4.73636 20 5.63636 20H18.7273C19.6273 20 20.3636 19.2636 20.3636 18.3636V5.27273C20.3636 4.37273 19.6273 3.63636 18.7273 3.63636ZM18.7273 18.3636H5.63636V7.72727H18.7273V18.3636Z"
          fill="#2F2F2F"
        />
      </g>
      <defs>
        <clipPath id="clip0_737_78745">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
