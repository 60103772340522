import React, { useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  ChecklistWrapper,
  Column,
  Checkbox,
  Input,
  InputWrapper,
} from './index.styles';
import editIcon from '../../../../../images/edit.png';

export interface GroupDataProps {
  name: string;
  value: any;
  isSelected: boolean;
}

function ColumnItem({
  selectedCompanies,
  setSelectedCompanies,
  colors,
  id,
}: {
  colors: string[];
  selectedCompanies: GroupDataProps[];
  id: number;
  setSelectedCompanies: Function;
}) {
  const [groupName, setGroupName] = useState(
    localStorage.getItem(`groupname${id}`) || `Group ${id + 1}`
  );

  return (
    <Column>
      <InputWrapper>
        <Input
          color={colors[id]}
          type="text"
          value={groupName}
          onChange={(e) => {
            setGroupName(e.target.value);
            localStorage.setItem(`groupname${id}`, e.target.value);
          }}
        />
        <img src={editIcon} alt="edit icon" />
      </InputWrapper>
      {selectedCompanies.map((key) => (
        <Checkbox
          key={key.name}
          onClick={() => {
            const newCollectedCompanies = [...selectedCompanies];
            const item1 = newCollectedCompanies.find(
              (el) => el.name === key.name
            );
            if (item1) {
              item1.isSelected = !key.isSelected;
            }
            setSelectedCompanies(newCollectedCompanies);
          }}
        >
          {key.isSelected ? (
            <CheckBoxIcon htmlColor={colors[id]} />
          ) : (
            <CheckBoxOutlineBlankIcon htmlColor={colors[id]} />
          )}
          <span>{key.name}</span>
        </Checkbox>
      ))}
    </Column>
  );
}

export function GroupChecklist({
  colors,
  selectedCompaniesG1,
  setSelectedCompaniesG1,
  selectedCompaniesG2,
  setSelectedCompaniesG2,
  selectedCompaniesG3,
  setSelectedCompaniesG3,
}: {
  colors: string[];
  selectedCompaniesG1: GroupDataProps[];
  setSelectedCompaniesG1: Function;
  selectedCompaniesG2: GroupDataProps[];
  setSelectedCompaniesG2: Function;
  selectedCompaniesG3: GroupDataProps[];
  setSelectedCompaniesG3: Function;
}) {
  return (
    <ChecklistWrapper>
      <ColumnItem
        id={0}
        colors={colors}
        selectedCompanies={selectedCompaniesG1}
        setSelectedCompanies={setSelectedCompaniesG1}
      />
      <ColumnItem
        id={1}
        colors={colors}
        selectedCompanies={selectedCompaniesG2}
        setSelectedCompanies={setSelectedCompaniesG2}
      />
      <ColumnItem
        id={2}
        colors={colors}
        selectedCompanies={selectedCompaniesG3}
        setSelectedCompanies={setSelectedCompaniesG3}
      />
    </ChecklistWrapper>
  );
}
