import { createTheme } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const lcaTheme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#38414F',
    },
    secondary: {
      main: '#FFF',
      contrastText: '#38414F',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '28px',
          letterSpacing: '0.36px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: '8px',
          },
          '&.OrganizationUnitSearchTextField': {
            '& .MuiInputBase-root': {
              borderRadius: '32px',
            },
            '& input::placeholder': {
              color: '#38414F',
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontStyle: 'italic',
              fontWeight: 400,
              lineHeight: '18px',
              letterSpacing: '0.32px',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '28px',
          minWidth: '192px',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '15px',
          textTransform: 'none',
          padding: '10px 30px',
          height: '48px',
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
          },
          '&.ConfirmationButton': {
            whiteSpace: 'nowrap',
            backgroundColor: '#7E4308',
            fontFamily: 'Poppins',
            fontSize: '16px',
            padding: '10px 20px',
            fontWeight: 600,
            borderRadius: '24px',
            border: '2px solid #6D3206',
            cursor: 'pointer',
            color: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 3px 0px #6D3206',
            '&:hover': {
              backgroundColor: '#8F5409',
            },
            '&:active': {
              boxShadow: 'none',
              transform: 'translateY(4px)',
            },
          },
          '&.ConfirmationTrue': {
            backgroundColor: '#fff',
            color: '#A03137',
            border: '2px solid #A03137',
            boxShadow: '0 3px 0px #A03137',
            '&:hover': {
              backgroundColor: '#FFF7E8',
            },
          },
        },
        textSecondary: {
          color: '#38414F',
          border: '1px solid #D9D9D9',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '32px',
          border: '2px solid #7E4308',
          minWidth: '165px',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
          textTransform: 'none',
          letterSpacing: '0.32px',
          color: '#7E4308',
          height: '56px',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          transform: 'translateY(-2px)',
          boxShadow: '0 2px 0px rgba(139, 0, 0, 1)',

          '&.Mui-selected, &.Mui-selected:hover': {
            color: 'white',
            backgroundColor: '#7E4308',
            cursor: 'default',
            transform: 'none',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
          },
        },
      },
    },
  },
});
