import React from 'react';
import { useTranslation } from 'react-i18next';
import { To } from 'react-router-dom';
import SidebarNavItem from '../NavItem';
import { ListItemIcon, ListItemText } from '@mui/material';
import IconSvg from '../../IconSvg';

type SidebarLegacyNavItemProps = {
  name: string;
  path: To;
  icon?: string;
};
export default function SidebarLegacyNavItem(props: SidebarLegacyNavItemProps) {
  const { t } = useTranslation();
  return (
    <SidebarNavItem to={props.path}>
      <ListItemIcon>
        <IconSvg name={props.icon || props.name} />
      </ListItemIcon>
      <ListItemText primary={<span>{t(`menu.${props.name}`)}</span>} />
    </SidebarNavItem>
  );
}
