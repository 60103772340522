import { useQuery } from 'react-query';
import { Member, OrganizationMember } from './index.types';
import { client } from 'src/utils/api-client';

// eslint-disable-next-line import/prefer-default-export
export const useOrganizationMembers = () => {
  const query = useQuery<Member[]>('organization-members', () =>
    client.get('web/settings_v2/organization-members').then((res) => res.data)
  );
  const data = query.data || [];
  const userOrganizationMap: {
    [key: string]: { [key: string]: OrganizationMember };
  } = {};
  data.forEach((user) => {
    userOrganizationMap[user.id] = Object.fromEntries(
      user.organizations.map((org) => [org.organization_id, org])
    );
  });

  return { ...query, organizationMembers: data, userOrganizationMap };
};
