import React from 'react';

import { StyledGate } from './index.styles';

export default function Gate() {
  return (
    <StyledGate>
      <div style={{ position: 'absolute', top: -30, left: -20 }}>GATE</div>
    </StyledGate>
  );
}
