import React from 'react';
import SurveyContainer, {
  NavButtonProps,
} from '../../../components/Survey/Container';
import Title from '../../../components/Survey/Title';
import { Trans, useTranslation } from 'react-i18next';
import { Logo } from '../../../components/Survey/icons';
import ImageHeader from '../../../components/Survey/ImageHeader';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSupplierSurvey } from '../hooks';
import PageLoading from '../../../components/PageLoading';
import NotFound from '../../../views/components/NotFound';
import { Subtitle } from '../styles';
import assets from '../assets';
import { Typography } from '@mui/material';
import OrganizationalLogo from 'src/components/OrganizationalLogo';

export default function SupplierSurveyIntro() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const survey = useSupplierSurvey();

  if (survey.isError) return <NotFound />;
  if (!survey.data) return <PageLoading />;
  if (survey.data.answer?.is_submitted) return <Navigate to="end" />;

  const nextButton: NavButtonProps = {
    label: 'esg.stakeholderSurvey.next',
    onClick: () => navigate('initial-questions'),
  };

  return (
    <SurveyContainer
      logo={<Logo />}
      nextLink={nextButton}
      header={
        <ImageHeader
          imageSrc={assets.intro.image}
          text={t('ghg.supplierSurvey.welcomeHeader')}
        />
      }
    >
      <div>
        <Title title={t('ghg.supplierSurvey.intro.title')} />
        <Subtitle>{t('ghg.supplierSurvey.intro.subtitle')}</Subtitle>
        <Trans i18nKey="ghg.supplierSurvey.intro.message" />
        <Typography>{survey.data.organization_name}</Typography>
        <OrganizationalLogo logo={survey.data.organization_logo} />
      </div>
    </SurveyContainer>
  );
}
