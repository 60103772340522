import React from 'react';
import { useAppSelector } from 'src/redux-file/hooks';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import { NavigateToProduct } from '../../../components/Navigation';
import { getLastVisitedProduct } from '../../../utils/navigation';

export const Home = () => {
  const products = useAppSelector((x) => x.platform.availableProducts);
  const lastProduct = getLastVisitedProduct();

  if (products.length === 0) {
    return <SomethingWentWrong />;
  }

  if (lastProduct && products.includes(lastProduct)) {
    return <NavigateToProduct product={lastProduct} />;
  }

  return <NavigateToProduct product={products[0]} />;
};

export default Home;
