/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { ChartProps } from './interface';
import {
  getAllCharts,
  getBusinessTravelEmissions,
  getCarbonFootprint,
  getEmissionPerScope,
  getEmissionScope3,
  getOrgStats,
  getEmployeesMeansOfTransport,
  getEmployeesStats,
  getIndustryBenchmarks,
  getPerEmployeeEmissionDecrease,
  getPerIncomeEmissionDecrease,
  getTotalEmission,
  getTotalEmissionDecrease,
  getDashboardStats,
  getAllEmployeeSurveyCharts,
  getEmployeeSurveyChart,
  getSuperadminStatsAndCharts,
} from './thunk';
import { getTarget } from '../thunk';

const emptyChart = { name: '', value: { data: [], layout: {} } };
const emptyStats = {
  emission_per_employee: null,
  emission_per_income: null,
  emission_per_income_currency: null,
  emission_scope_1: null,
  emission_scope_2_location_based_method: null,
  emission_scope_2_market_based_method: null,
  emission_scope_3: null,
  emission_total: null,
  energy_consumption: null,
};
const emptyChanges = {
  emission_per_employee_change: null,
  emission_per_income_change: null,
  emission_scope_1_change: null,
  emission_scope_2_location_based_method_change: null,
  emission_scope_2_market_based_method_change: null,
  emission_scope_3_change: null,
  emission_total_change: null,
  energy_consumption_change: null,
};

const initialState: ChartProps = {
  orgStats: [],
  orgStatsLoading: true,
  orgStatsError: false,

  dashboardStats: emptyStats,
  dashboardStatsChanges: emptyChanges,
  dashboardStatsError: false,
  dashboardStatsLoading: true,

  carbonFootprint: emptyChart,
  carbonFootprintLoading: true,
  carbonFootprintError: false,

  carbonFootprintTable: '',
  carbonFootprintTableLoading: true,
  carbonFootprintTableError: false,

  emissionRanking: emptyChart,
  emissionRankingLoading: true,
  emissionRankingError: false,

  totalEmission: emptyChart,
  totalEmissionLoading: true,
  totalEmissionError: false,

  totalEmissionTable: '',
  totalEmissionTableLoading: true,
  totalEmissionTableError: false,

  industryBenchmarks: emptyChart,
  industryBenchmarksLoading: true,
  industryBenchmarksError: false,

  employeesStats: emptyChart,
  employeesStatsLoading: true,
  employeesStatsError: false,

  businessTravelEmissions: emptyChart,
  businessTravelEmissionsError: false,
  businessTravelEmissionsLoading: true,

  goodsAndServicesEmissions: emptyChart,
  goodsAndServicesEmissionsError: false,
  goodsAndServicesEmissionsLoading: true,

  employeesMeansOfTransport: emptyChart,
  employeesMeansOfTransportError: false,
  employeesMeansOfTransportLoading: true,

  totalEmissionDecrease: emptyChart,
  totalEmissionDecreaseError: false,
  totalEmissionDecreaseLoading: true,

  perEmployeeEmission: emptyChart,
  perEmployeeEmissionError: false,
  perEmployeeEmissionLoading: true,

  ESPercentageOfCompleted: emptyChart,
  ESPercentageOfCompletedError: false,
  ESPercentageOfCompletedLoading: false,

  perIncomeEmission: emptyChart,
  perIncomeEmissionError: false,
  perIncomeEmissionLoading: true,

  emissionPerScope: emptyChart,
  emissionPerScopeError: false,
  emissionPerScopeLoading: true,

  targetChart: emptyChart,
  targetChartError: false,
  targetChartLoading: true,

  SAError: false,
  SALoading: true,
  SAScope1: emptyChart,
  SAScope2: emptyChart,
  SAScope3: emptyChart,
  SATheMostEmissiveCompanies: emptyChart,
  SACarbonFootprint: emptyChart,
  SATotalEmissionByScope: emptyChart,
  SAEmissionRankingScope3: emptyChart,
  SAEmissionsPerScopeBar: emptyChart,
  SAEmissionsPerScopeTable: '',
  SAGroupsCarbonFootprint: { name: '', value: { '': { '': 0 } } },
};

export const chartsSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrgStats.fulfilled, (state, action) => {
        state.orgStats = action.payload;
        state.orgStatsLoading = false;
        state.orgStatsError = false;
      })
      .addCase(getOrgStats.rejected, (state) => {
        state.orgStatsLoading = false;
        state.orgStatsError = true;
      })
      .addCase(getOrgStats.pending, (state) => {
        state.orgStatsLoading = true;
        state.orgStatsError = false;
      })
      .addCase(getDashboardStats.fulfilled, (state, action) => {
        state.dashboardStats = action.payload.stats;
        state.dashboardStatsChanges = action.payload.changes;
        state.dashboardStatsLoading = false;
        state.dashboardStatsError = false;
      })
      .addCase(getDashboardStats.rejected, (state) => {
        state.dashboardStatsLoading = false;
        state.dashboardStatsError = true;
      })
      .addCase(getDashboardStats.pending, (state) => {
        state.dashboardStatsLoading = true;
        state.dashboardStatsError = false;
      })
      .addCase(getCarbonFootprint.fulfilled, (state, action) => {
        state.carbonFootprint = action.payload;
        state.carbonFootprintLoading = false;
        state.carbonFootprintError = false;
      })
      .addCase(getCarbonFootprint.rejected, (state) => {
        state.carbonFootprintLoading = false;
        state.carbonFootprintError = true;
      })
      .addCase(getCarbonFootprint.pending, (state) => {
        state.carbonFootprintLoading = true;
        state.carbonFootprintError = false;
      })
      .addCase(getEmissionScope3.fulfilled, (state, action) => {
        state.emissionRanking = action.payload;
        state.emissionRankingError = false;
        state.emissionRankingLoading = false;
      })
      .addCase(getEmissionScope3.rejected, (state) => {
        state.emissionRankingError = true;
        state.emissionRankingLoading = false;
      })
      .addCase(getEmissionScope3.pending, (state) => {
        state.emissionRankingError = false;
        state.emissionRankingLoading = true;
      })
      .addCase(getTotalEmission.fulfilled, (state, action) => {
        state.totalEmission = action.payload;
        state.totalEmissionLoading = false;
        state.totalEmissionError = false;
      })
      .addCase(getTotalEmission.rejected, (state) => {
        state.totalEmissionLoading = false;
        state.totalEmissionError = true;
      })
      .addCase(getTotalEmission.pending, (state) => {
        state.totalEmissionLoading = true;
        state.totalEmissionError = false;
      })
      .addCase(getIndustryBenchmarks.fulfilled, (state, action) => {
        state.industryBenchmarksLoading = false;
        state.industryBenchmarks = action.payload;
        state.industryBenchmarksError = false;
      })
      .addCase(getIndustryBenchmarks.pending, (state) => {
        state.industryBenchmarksLoading = true;
        state.industryBenchmarksError = false;
      })
      .addCase(getIndustryBenchmarks.rejected, (state) => {
        state.industryBenchmarksLoading = false;
        state.industryBenchmarksError = true;
      })

      .addCase(getEmployeesStats.fulfilled, (state, action) => {
        state.employeesStats = action.payload;
        state.employeesStatsLoading = false;
        state.employeesStatsError = false;
      })
      .addCase(getEmployeesStats.rejected, (state) => {
        state.employeesStatsLoading = false;
        state.employeesStatsError = true;
      })
      .addCase(getEmployeesStats.pending, (state) => {
        state.employeesStatsLoading = true;
        state.employeesStatsError = false;
      })
      .addCase(getBusinessTravelEmissions.fulfilled, (state, action) => {
        state.businessTravelEmissions = action.payload;
        state.businessTravelEmissionsLoading = false;
        state.businessTravelEmissionsError = false;
      })
      .addCase(getBusinessTravelEmissions.rejected, (state) => {
        state.businessTravelEmissionsLoading = false;
        state.businessTravelEmissionsError = true;
      })
      .addCase(getBusinessTravelEmissions.pending, (state) => {
        state.businessTravelEmissionsLoading = true;
        state.businessTravelEmissionsError = false;
      })
      .addCase(getEmployeesMeansOfTransport.fulfilled, (state, action) => {
        state.employeesMeansOfTransport = action.payload;
        state.employeesMeansOfTransportLoading = false;
        state.employeesMeansOfTransportError = false;
      })
      .addCase(getEmployeesMeansOfTransport.rejected, (state) => {
        state.employeesMeansOfTransportLoading = false;
        state.employeesMeansOfTransportError = true;
      })
      .addCase(getEmployeesMeansOfTransport.pending, (state) => {
        state.employeesMeansOfTransportLoading = true;
        state.employeesMeansOfTransportError = false;
      })

      .addCase(getTarget.fulfilled, (state, action) => {
        state.targetChart = action.payload.plotly_figure;
        state.targetChartLoading = false;
        state.targetChartError = false;
      })
      .addCase(getTarget.rejected, (state) => {
        state.targetChartLoading = false;
        state.targetChartError = true;
      })
      .addCase(getTarget.pending, (state) => {
        state.targetChartLoading = true;
        state.targetChartError = false;
      })

      .addCase(getTotalEmissionDecrease.fulfilled, (state, action) => {
        state.totalEmissionDecrease = action.payload;
        state.totalEmissionDecreaseLoading = false;
        state.totalEmissionDecreaseError = false;
      })
      .addCase(getTotalEmissionDecrease.rejected, (state) => {
        state.totalEmissionDecreaseLoading = false;
        state.totalEmissionDecreaseError = true;
      })
      .addCase(getTotalEmissionDecrease.pending, (state) => {
        state.totalEmissionDecreaseLoading = true;
        state.totalEmissionDecreaseError = false;
      })

      .addCase(getPerEmployeeEmissionDecrease.fulfilled, (state, action) => {
        state.perEmployeeEmission = action.payload;
        state.perEmployeeEmissionLoading = false;
        state.perEmployeeEmissionError = false;
      })
      .addCase(getPerEmployeeEmissionDecrease.rejected, (state) => {
        state.perEmployeeEmissionLoading = false;
        state.perEmployeeEmissionError = true;
      })
      .addCase(getPerEmployeeEmissionDecrease.pending, (state) => {
        state.perEmployeeEmissionLoading = true;
        state.perEmployeeEmissionError = false;
      })

      .addCase(getPerIncomeEmissionDecrease.fulfilled, (state, action) => {
        state.perIncomeEmission = action.payload;
        state.perIncomeEmissionLoading = false;
        state.perIncomeEmissionError = false;
      })
      .addCase(getPerIncomeEmissionDecrease.rejected, (state) => {
        state.perIncomeEmissionLoading = false;
        state.perIncomeEmissionError = true;
      })
      .addCase(getPerIncomeEmissionDecrease.pending, (state) => {
        state.perIncomeEmissionLoading = true;
        state.perIncomeEmissionError = false;
      })

      .addCase(getEmissionPerScope.fulfilled, (state, action) => {
        state.emissionPerScope = action.payload;
        state.emissionPerScopeLoading = false;
        state.emissionPerScopeError = false;
      })
      .addCase(getEmissionPerScope.rejected, (state) => {
        state.emissionPerScopeLoading = false;
        state.emissionPerScopeError = true;
      })
      .addCase(getEmissionPerScope.pending, (state) => {
        state.emissionPerScopeLoading = true;
        state.emissionPerScopeError = false;
      })
      .addCase(getAllCharts.fulfilled, (state, action) => {
        state.carbonFootprint = action.payload.carbon_footprint;
        state.carbonFootprintLoading = false;
        state.carbonFootprintError = false;

        state.carbonFootprintTable = action.payload.carbon_footprint_tables;
        state.carbonFootprintTableLoading = false;
        state.carbonFootprintTableError = false;

        state.totalEmission = action.payload.total_emissions_by_scope;
        state.totalEmissionLoading = false;
        state.totalEmissionError = false;

        state.totalEmissionTable =
          action.payload.total_emissions_by_scope_tables;
        state.totalEmissionTableLoading = false;
        state.totalEmissionTableError = false;

        state.emissionRanking = action.payload.emission_ranking_scope_3;
        state.emissionRankingError = false;
        state.emissionRankingLoading = false;

        state.goodsAndServicesEmissions =
          action.payload.goods_and_services_emissions;
        state.goodsAndServicesEmissionsError = false;
        state.goodsAndServicesEmissionsLoading = false;

        state.employeesMeansOfTransport =
          action.payload.employees_means_of_transport;
        state.employeesMeansOfTransportLoading = false;
        state.employeesMeansOfTransportError = false;

        state.employeesStats = action.payload.employee_emissions;
        state.employeesStatsLoading = false;
        state.employeesStatsError = false;

        state.businessTravelEmissions =
          action.payload.business_travel_emissions;
        state.businessTravelEmissionsLoading = false;
        state.businessTravelEmissionsError = false;

        state.emissionPerScope = action.payload.emissions_by_scope_detail;
        state.emissionPerScopeLoading = false;
        state.emissionPerScopeError = false;
      })
      .addCase(getAllCharts.pending, (state) => {
        state.carbonFootprintLoading = true;
        state.carbonFootprintError = false;
        state.carbonFootprint = emptyChart;

        state.carbonFootprintTableLoading = true;
        state.carbonFootprintTableError = false;
        state.carbonFootprintTable = '';

        state.businessTravelEmissionsLoading = true;
        state.businessTravelEmissionsError = false;
        state.businessTravelEmissions = emptyChart;

        state.industryBenchmarksLoading = true;
        state.industryBenchmarksError = false;
        state.industryBenchmarks = emptyChart;

        state.emissionPerScopeLoading = true;
        state.emissionPerScopeError = false;
        state.emissionPerScope = emptyChart;

        state.employeesMeansOfTransportLoading = true;
        state.employeesMeansOfTransportError = false;
        state.employeesMeansOfTransport = emptyChart;

        state.totalEmissionLoading = true;
        state.totalEmissionError = false;
        state.totalEmission = emptyChart;

        state.totalEmissionTableLoading = true;
        state.totalEmissionTableError = false;
        state.totalEmissionTable = '';

        state.employeesStatsLoading = true;
        state.employeesStatsError = false;
        state.employeesStats = emptyChart;

        state.emissionRankingError = false;
        state.emissionRankingLoading = true;
        state.emissionRanking = emptyChart;

        state.goodsAndServicesEmissionsError = false;
        state.goodsAndServicesEmissionsLoading = true;
        state.goodsAndServicesEmissions = emptyChart;
      })
      .addCase(getAllCharts.rejected, (state) => {
        state.carbonFootprintLoading = false;
        state.carbonFootprintError = true;

        state.carbonFootprintTableLoading = false;
        state.carbonFootprintTableError = true;

        state.businessTravelEmissionsLoading = false;
        state.businessTravelEmissionsError = true;

        state.carbonFootprintLoading = false;
        state.carbonFootprintError = true;

        state.industryBenchmarksLoading = false;
        state.industryBenchmarksError = true;

        state.emissionPerScopeLoading = false;
        state.emissionPerScopeError = true;

        state.employeesMeansOfTransportLoading = false;
        state.employeesMeansOfTransportError = true;

        state.totalEmissionLoading = false;
        state.totalEmissionError = true;

        state.totalEmissionTableLoading = false;
        state.totalEmissionTableError = true;

        state.employeesStatsLoading = false;
        state.employeesStatsError = true;

        state.emissionRankingError = true;
        state.emissionRankingLoading = false;

        state.goodsAndServicesEmissionsError = true;
        state.goodsAndServicesEmissionsLoading = false;
      })
      .addCase(getSuperadminStatsAndCharts.fulfilled, (state, action) => {
        if (!action.payload) return;
        const { stats, figures } = action.payload;
        state.dashboardStats = stats.stats;
        state.dashboardStatsChanges = stats.changes;
        state.dashboardStatsLoading = false;
        state.dashboardStatsError = false;
        //charts
        state.SALoading = false;
        state.SAError = false;

        state.SACarbonFootprint = figures.carbon_footprint;
        state.SAEmissionRankingScope3 = figures.emission_ranking_scope_3;
        state.SAEmissionsPerScopeBar = figures.scope_footprint_by_org;
        state.SAEmissionsPerScopeTable = figures.scope_footprint_by_org_table;
        state.SAGroupsCarbonFootprint = figures.carbon_footprint_by_org;
        state.SAScope1 = figures.scope_1_by_org;
        state.SAScope2 = figures.scope_2_by_org;
        state.SAScope3 = figures.scope_3_by_org;
        state.SATheMostEmissiveCompanies = figures.emission_ranking_by_org;
        state.SATotalEmissionByScope = figures.total_emissions_by_scope;
      })
      .addCase(getSuperadminStatsAndCharts.rejected, (state) => {
        state.dashboardStatsLoading = false;
        state.dashboardStatsError = true;
      })
      .addCase(getSuperadminStatsAndCharts.pending, (state) => {
        state.dashboardStatsLoading = true;
        state.dashboardStatsError = false;
      })
      .addCase(getAllEmployeeSurveyCharts.fulfilled, (state, action) => {
        state.ESPercentageOfCompleted =
          action.payload.surveys_percentage_filled;
        state.ESPercentageOfCompletedError =
          action.payload.surveys_percentage_filled.err_type ?? false;
        state.ESPercentageOfCompletedLoading = false;

        state.employeesMeansOfTransport =
          action.payload.employees_means_of_transport;
        state.employeesMeansOfTransportError =
          action.payload.employees_means_of_transport.err_type ?? false;
        state.employeesMeansOfTransportLoading = false;

        state.employeesStats = action.payload.employee_emissions;
        state.employeesStatsError =
          action.payload.employee_emissions.err_type ?? false;
        state.employeesStatsLoading = false;

        state.businessTravelEmissions =
          action.payload.business_travel_emissions;
        state.businessTravelEmissionsLoading = false;
        state.businessTravelEmissionsError =
          action.payload.business_travel_emissions.err_type ?? false;
      })
      .addCase(getAllEmployeeSurveyCharts.pending, (state) => {
        state.ESPercentageOfCompleted = emptyChart;
        state.ESPercentageOfCompletedError = false;
        state.ESPercentageOfCompletedLoading = true;

        state.employeesMeansOfTransport = emptyChart;
        state.employeesMeansOfTransportError = false;
        state.employeesMeansOfTransportLoading = true;

        state.employeesStats = emptyChart;
        state.employeesStatsError = false;
        state.employeesStatsLoading = true;

        state.businessTravelEmissions = emptyChart;
        state.businessTravelEmissionsLoading = true;
        state.businessTravelEmissionsError = false;
      })
      .addCase(getAllEmployeeSurveyCharts.rejected, (state) => {
        state.ESPercentageOfCompletedError = true;
        state.ESPercentageOfCompletedLoading = false;

        state.employeesMeansOfTransportError = true;
        state.employeesMeansOfTransportLoading = false;

        state.employeesStatsError = true;
        state.employeesStatsLoading = false;

        state.businessTravelEmissionsLoading = false;
        state.businessTravelEmissionsError = true;
      })
      .addCase(getEmployeeSurveyChart.fulfilled, (state, action) => {
        // TODO: to remove?
        if (action.payload.name === 'employee-emissions') {
          state.employeesStats = action.payload;
        }
        if (action.payload.name === 'percentage-of-completed') {
          state.ESPercentageOfCompleted = action.payload;
        }
        if (action.payload.name === 'employees-means-of-transport') {
          state.employeesMeansOfTransport = action.payload;
        }
        if (action.payload.name === 'business-travel-emissions') {
          state.businessTravelEmissions = action.payload;
        }
      });
  },
});

export default chartsSlice.reducer;
