export const stationary_combustion_cloud = {
  title: 'stationary_combustion_cloud.title',
  desc: 'stationary_combustion_cloud.desc',
  'dos-title': 'stationary_combustion_cloud.dos-title',
  dos: [
    'stationary_combustion_cloud.dos1',
    'stationary_combustion_cloud.dos2',
    'stationary_combustion_cloud.dos3',
    'stationary_combustion_cloud.dos4',
    'stationary_combustion_cloud.dos5',
  ],
  'donts-title': 'stationary_combustion_cloud.donts-title',
  donts: [
    'stationary_combustion_cloud.donts1',
    'stationary_combustion_cloud.donts2',
    'stationary_combustion_cloud.donts3',
  ],
};

export const fuel_consumption_cloud = {
  title: 'fuel_consumption_cloud.title',
  desc: 'fuel_consumption_cloud.desc',
  'dos-title': 'fuel_consumption_cloud.dos-title',
  dos: [
    'fuel_consumption_cloud.dos1',
    'fuel_consumption_cloud.dos2',
    'fuel_consumption_cloud.dos3',
    'fuel_consumption_cloud.dos4',
  ],
  'donts-title': 'fuel_consumption_cloud.donts-title',
  donts: [
    'fuel_consumption_cloud.donts1',
    'fuel_consumption_cloud.donts2',
    'fuel_consumption_cloud.donts3',
  ],
};

export const distance_traveled_cloud = {
  title: 'distance_traveled_cloud.title',
  desc: 'distance_traveled_cloud.desc',
  'dos-title': 'distance_traveled_cloud.dos-title',
  dos: [
    'distance_traveled_cloud.dos1',
    'distance_traveled_cloud.dos2',
    'distance_traveled_cloud.dos3',
    'distance_traveled_cloud.dos4',
  ],
  'donts-title': 'distance_traveled_cloud.donts-title',
  donts: [
    'distance_traveled_cloud.donts1',
    'distance_traveled_cloud.donts2',
    'distance_traveled_cloud.donts3',
  ],
};

export const fugitive_emission_cloud = {
  title: 'fugitive_emission_cloud.title',
  desc: 'fugitive_emission_cloud.desc',
  'dos-title': 'fugitive_emission_cloud.dos-title',
  dos: [
    'fugitive_emission_cloud.dos1',
    'fugitive_emission_cloud.dos2',
    'fugitive_emission_cloud.dos3',
    'fugitive_emission_cloud.dos4',
    'fugitive_emission_cloud.dos5',
    'fugitive_emission_cloud.dos6',
    'fugitive_emission_cloud.dos7',
  ],
  'donts-title': 'fugitive_emission_cloud.donts-title',
  donts: [
    'fugitive_emission_cloud.donts1',
    'fugitive_emission_cloud.donts2',
    'fugitive_emission_cloud.donts3',
    'fugitive_emission_cloud.donts4',
  ],
};

export const process_emission_cloud = {
  title: 'process_emission_cloud.title',
  desc: 'process_emission_cloud.desc',
  'dos-title': 'process_emission_cloud.dos-title',
  dos: ['process_emission_cloud.dos1', 'process_emission_cloud.dos2'],
  'donts-title': 'process_emission_cloud.donts-title',
  donts: ['process_emission_cloud.donts1'],
};

export const electricity_cloud = {
  title: 'electricity_cloud.title',
  desc: 'electricity_cloud.desc',
  'dos-title': 'electricity_cloud.dos-title',
  dos: ['electricity_cloud.dos1'],
  'donts-title': 'electricity_cloud.donts-title',
  donts: ['electricity_cloud.donts1'],
};

export const steam_cloud = {
  title: 'steam_cloud.title',
  desc: 'steam_cloud.desc',
  'dos-title': 'steam_cloud.dos-title',
  dos: ['steam_cloud.dos1'],
  'donts-title': 'steam_cloud.donts-title',
  donts: ['steam_cloud.donts1'],
};

export const heat_cloud = {
  title: 'heat_cloud.title',
  desc: 'heat_cloud.desc',
  'dos-title': 'heat_cloud.dos-title',
  dos: ['heat_cloud.dos1'],
  'donts-title': 'heat_cloud.donts-title',
  donts: ['heat_cloud.donts1'],
};

export const cooling_cloud = {
  title: 'cooling_cloud.title',
  desc: 'cooling_cloud.desc',
  'dos-title': 'cooling_cloud.dos-title',
  dos: ['cooling_cloud.dos1'],
  'donts-title': 'cooling_cloud.donts-title',
  donts: ['cooling_cloud.donts1'],
};

export const purchased_goods_cloud = {
  title: 'purchased_goods_cloud.title',
  desc: 'purchased_goods_cloud.desc',
  'dos-title': 'purchased_goods_cloud.dos-title',
  dos: [
    'purchased_goods_cloud.dos1',
    'purchased_goods_cloud.dos2',
    'purchased_goods_cloud.dos3',
  ],
  'donts-title': 'purchased_goods_cloud.donts-title',
  donts: ['purchased_goods_cloud.donts1', 'purchased_goods_cloud.donts2'],
};

export const capital_goods_cloud = {
  title: 'capital_goods_cloud.title',
  desc: 'capital_goods_cloud.desc',
  'dos-title': 'capital_goods_cloud.dos-title',
  dos: [
    'capital_goods_cloud.dos1',
    'capital_goods_cloud.dos2',
    'capital_goods_cloud.dos3',
    'capital_goods_cloud.dos4',
  ],
  'donts-title': 'capital_goods_cloud.donts-title',
  donts: ['capital_goods_cloud.donts1'],
};

export const upstream_transportation_cloud = {
  title: 'upstream_transportation_cloud.title',
  desc: 'upstream_transportation_cloud.desc',
  'dos-title': 'upstream_transportation_cloud.dos-title',
  dos: ['upstream_transportation_cloud.dos1'],
  'donts-title': 'upstream_transportation_cloud.donts-title',
  donts: ['upstream_transportation_cloud.donts1'],
};

export const downstream_transportation_cloud = {
  title: 'downstream_transportation_cloud.title',
  desc: 'downstream_transportation_cloud.desc',
  'dos-title': 'downstream_transportation_cloud.dos-title',
  dos: ['downstream_transportation_cloud.dos1'],
  'donts-title': 'downstream_transportation_cloud.donts-title',
  donts: ['downstream_transportation_cloud.donts1'],
};

export const upstream_distribution_cloud = {
  title: 'upstream_distribution_cloud.title',
  desc: 'upstream_distribution_cloud.desc',
};
export const downstream_distribution_cloud = {
  title: 'downstream_distribution_cloud.title',
  desc: 'downstream_distribution_cloud.desc',
};

export const upstream_leased_buildings_cloud = {
  title: 'upstream_leased_buildings_cloud.title',
  desc: 'upstream_leased_buildings_cloud.desc',
};

export const upstream_leased_assets_cloud = {
  title: 'upstream_leased_assets_cloud.title',
  desc: 'upstream_leased_assets_cloud.desc',
};

export const downstream_leased_buildings_cloud = {
  title: 'downstream_leased_buildings_cloud.title',
  desc: 'downstream_leased_buildings_cloud.desc',
};

export const downstream_leased_assets_cloud = {
  title: 'downstream_leased_assets_cloud.title',
  desc: 'downstream_leased_assets_cloud.desc',
};

export const franchise_cloud = {
  title: 'franchise_cloud.title',
  desc: 'franchise_cloud.desc',
};

export const investments_cloud = {
  title: 'investments_cloud.title',
  desc: 'investments_cloud.desc',
  'dos-title': 'investments_cloud.dos-title',
  dos: [
    'investments_cloud.dos1',
    'investments_cloud.dos2',
    'investments_cloud.dos3',
    'investments_cloud.dos4',
  ],
};

export const waste_cloud = {
  title: 'waste_cloud.title',
  desc: 'waste_cloud.desc',
  'dos-title': 'waste_cloud.dos-title',
  dos: [
    'waste_cloud.dos1',
    'waste_cloud.dos2',
    'waste_cloud.dos3',
    'waste_cloud.dos4',
    'waste_cloud.dos5',
  ],
  'donts-title': 'waste_cloud.donts-title',
  donts: ['waste_cloud.donts1', 'waste_cloud.donts2'],
};

export const other_fuel_cloud = {
  title: 'other_fuel_cloud.title',
  desc: 'other_fuel_cloud.desc',
  'dos-title': 'other_fuel_cloud.dos-title',
  dos: ['other_fuel_cloud.dos1'],
  'donts-title': 'other_fuel_cloud.donts-title',
  donts: ['other_fuel_cloud.donts1'],
};

export const other_energy_cloud = {
  title: 'other_energy_cloud.title',
  desc: 'other_energy_cloud.desc',
  'dos-title': 'other_energy_cloud.dos-title',
  dos: ['other_energy_cloud.dos1'],
  'donts-title': 'other_energy_cloud.donts-title',
  donts: ['other_energy_cloud.donts1'],
};

export const other_emissions_cloud = {
  title: 'other_emissions_cloud.title',
  desc: 'other_emissions_cloud.desc',
  'dos-title': 'other_emissions_cloud.dos-title',
  dos: ['other_emissions_cloud.dos1'],
  'donts-title': 'other_emissions_cloud.donts-title',
  donts: ['other_emissions_cloud.donts1'],
};

export const ev_consumption_cloud = {
  title: 'ev_consumption_cloud.title',
  desc: 'ev_consumption_cloud.desc',
  'dos-title': 'ev_consumption_cloud.dos-title',
  dos: [
    'ev_consumption_cloud.dos1',
    'ev_consumption_cloud.dos2',
    'ev_consumption_cloud.dos3',
    'ev_consumption_cloud.dos4',
  ],
  'donts-title': 'ev_consumption_cloud.donts-title',
  donts: [
    'ev_consumption_cloud.donts1',
    'ev_consumption_cloud.donts2',
    'ev_consumption_cloud.donts3',
    'ev_consumption_cloud.donts4',
  ],
};

export const ev_distance_cloud = {
  title: 'ev_distance_cloud.title',
  desc: 'ev_distance_cloud.desc',
  'dos-title': 'ev_distance_cloud.dos-title',
  dos: [
    'ev_distance_cloud.dos1',
    'ev_distance_cloud.dos2',
    'ev_distance_cloud.dos3',
    'ev_distance_cloud.dos4',
  ],
  'donts-title': 'ev_distance_cloud.donts-title',
  donts: [
    'ev_distance_cloud.donts1',
    'ev_distance_cloud.donts2',
    'ev_distance_cloud.donts3',
  ],
};
export const fuel_and_raw_materials_cloud = {
  title: 'fuels_and_raw_materials_cloud.title',
  desc: 'fuels_and_raw_materials_cloud.desc',
  'dos-title': 'fuels_and_raw_materials_cloud.dos-title',
  dos: ['fuels_and_raw_materials_cloud.dos1'],
  'donts-title': 'fuels_and_raw_materials_cloud.donts-title',
  donts: ['fuels_and_raw_materials_cloud.donts1'],
};

export const comment_cloud = {
  title: 'comment_cloud.title',
  desc: 'comment_cloud.desc',
  'dos-title': 'comment_cloud.dos-title',
  dos: ['comment_cloud.dos1'],
  'donts-title': 'comment_cloud.donts-title',
  donts: ['comment_cloud.donts1'],
};

export const fuel_and_raw_materials_waste_cloud = {
  title: 'fuel_and_raw_materials_waste_cloud.title',
  desc: 'fuel_and_raw_materials_waste_cloud.desc',
  'dos-title': 'fuel_and_raw_materials_waste_cloud.dos-title',
  dos: ['fuel_and_raw_materials_waste_cloud.dos1'],
  'donts-title': 'fuel_and_raw_materials_waste_cloud.donts-title',
  donts: ['fuel_and_raw_materials_waste_cloud.donts1'],
};

export const final_product_direct_product_life_cycle_cloud = {
  title: 'final_product_direct_product_life_cycle_cloud.title',
  desc: 'final_product_direct_product_life_cycle_cloud.desc',
  'dos-title': 'final_product_direct_product_life_cycle_cloud.dos-title',
  dos: ['final_product_direct_product_life_cycle_cloud.dos1'],
  'donts-title': 'final_product_direct_product_life_cycle_cloud.donts-title',
  donts: ['final_product_direct_product_life_cycle_cloud.donts1'],
};

export const final_product_direct_sales_region_cloud = {
  title: 'final_product_direct_sales_region_cloud.title',
  desc: 'final_product_direct_sales_region_cloud.desc',
  'dos-title': 'final_product_direct_sales_region_cloud.dos-title',
  dos: ['final_product_direct_sales_region_cloud.dos1'],
  'donts-title': 'final_product_direct_sales_region_cloud.donts-title',
  donts: ['final_product_direct_sales_region_cloud.donts1'],
};

export const final_product_direct_fuel_cloud = {
  title: 'final_product_direct_fuel_cloud.title',
  desc: 'final_product_direct_fuel_cloud.desc',
  'dos-title': 'final_product_direct_fuel_cloud.dos-title',
  dos: ['final_product_direct_fuel_cloud.dos1'],
  'donts-title': 'final_product_direct_fuel_cloud.donts-title',
  donts: ['final_product_direct_fuel_cloud.donts1'],
};

export const final_product_direct_energy_cloud = {
  title: 'final_product_direct_energy_cloud.title',
  desc: 'final_product_direct_energy_cloud.desc',
  'dos-title': 'final_product_direct_energy_cloud.dos-title',
  dos: ['final_product_direct_energy_cloud.dos1'],
  'donts-title': 'final_product_direct_energy_cloud.donts-title',
  donts: ['final_product_direct_energy_cloud.donts1'],
};

export const final_product_direct_leakage_cloud = {
  title: 'final_product_direct_leakage_cloud.title',
  desc: 'final_product_direct_leakage_cloud.desc',
  'dos-title': 'final_product_direct_leakage_cloud.dos-title',
  dos: ['final_product_direct_leakage_cloud.dos1'],
  'donts-title': 'final_product_direct_leakage_cloud.donts-title',
  donts: ['final_product_direct_leakage_cloud.donts1'],
};

export const final_product_direct_other_cloud = {
  title: 'final_product_direct_other_cloud.title',
  desc: 'final_product_direct_other_cloud.desc',
  'dos-title': 'final_product_direct_other_cloud.dos-title',
  dos: ['final_product_direct_other_cloud.dos1'],
  'donts-title': 'final_product_direct_other_cloud.donts-title',
  donts: ['final_product_direct_other_cloud.donts1'],
};

export const overview_sold_products_cloud = {
  title: 'overview_sold_products_cloud.title',
  desc: 'overview_sold_products_cloud.desc',
};
