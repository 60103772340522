import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
`;

export const Image = styled.img`
  width: 50px;
  height: 50px;
`;

export const Title = styled.div`
  margin-top: 20px;
  font-size: 27px;
  font-weight: 700;
  opacity: 0.65;
  text-alight: center;
`;

export const Text = styled.div`
  margin-top: 25px;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.65;
  text-align: center;
  max-width: 400px;
`;
