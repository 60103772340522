import React from 'react';
import { StyledTextField } from '../productInformation';

interface Props {
  values: {
    goals: {
      intended_applications: string;
      study_reasons: string;
      target_audience: string;
      comissioner_name: string;
    };
  };
  setValues: Function;
}
export default function GoalsBasic({ setValues, values }: Props) {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name:
      | 'intended_applications'
      | 'comissioner_name'
      | 'study_reasons'
      | 'target_audience'
  ) => {
    setValues({
      ...values,
      goals: { ...values.goals, [name]: e.target.value },
    });
  };
  return (
    <div
      style={{
        display: 'flex',
        gap: '24px',
        flexDirection: 'column',
      }}
    >
      <StyledTextField
        fullWidth
        label="What specific applications do you expect for the results of the LCA study?"
        name="intended_applications"
        value={values.goals.intended_applications}
        onChange={(e) => handleChange(e, 'intended_applications')}
      />

      <StyledTextField
        fullWidth
        label="What are the reasons for carrying out the study and decision context?"
        name="study_reasons"
        value={values.goals.study_reasons}
        onChange={(e) => handleChange(e, 'study_reasons')}
      />

      <StyledTextField
        fullWidth
        label="Who is your target audience?"
        name="target_audience"
        value={values.goals.target_audience}
        onChange={(e) => handleChange(e, 'target_audience')}
      />

      <StyledTextField
        fullWidth
        label="Name of commissioner of the study"
        name="comissioner_name"
        value={values.goals.comissioner_name}
        onChange={(e) => handleChange(e, 'comissioner_name')}
      />
    </div>
  );
}
