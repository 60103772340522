import React from 'react';
import LcaLayout from 'src/Lca/components/layout';
import Breadcrumbs from 'src/Lca/components/Breadcrumbs';
import { Description, Subtitle, Title } from 'src/Lca/components/Texts';
import { useTranslation } from 'react-i18next';
import IntroductionsGrid from 'src/Lca/components/IntroductionsGrid';
import CreateLcaProductInformation from './CreateProductInformation';

export default function LcaCreateProduct() {
  const data = [
    { name: 'Products List', url: '/lca/products' },
    { name: 'New product', url: '' },
    { name: 'Introduction', url: '' },
  ];
  const { t } = useTranslation();

  const [renderForm, setRenderForm] = React.useState(false);

  if (renderForm) return <CreateLcaProductInformation />;

  return (
    <LcaLayout>
      <Breadcrumbs data={data} />
      <Subtitle text="INTRODUCTION" />
      <div style={{ marginTop: '44px', marginBottom: '40px' }}>
        <Title text={t('lca.products')} />
      </div>
      <Description text="A Life Cycle Assessment (LCA) consists of 4 parts: Determination of the purpose and scope of performing an LCA (1), Inventarisation analysis (2), Impact assessment (3) and interpretation (4)." />
      <IntroductionsGrid variant="big" onClick={() => setRenderForm(true)} />
      <div style={{ paddingBottom: '80px' }} />
    </LcaLayout>
  );
}
