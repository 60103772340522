import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ThemeProvider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import LoginIcon from '@mui/icons-material/Login';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { client } from '../../../utils/api-client';
import { StyledContainer } from './index.styles';
import { ReportingFreq } from '../../../utils/api.interfaces';
import PasswordVerificationForm from 'src/components/PasswordVerificationForm';
import OnboardingHeading from '../../components/OnboardingHeading';
import ButtonGroupField from '../../../components/ButtonGroupField';
import { toast } from 'react-toastify';
import { registerTheme } from './theme';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import { FormErrors, RegistrationSchema, validateForm } from './form';
import { useLanguage, useSupportedCountries } from 'src/common/hooks';

type Country = {
  name: string;
  alpha3: string;
};

export default function Register() {
  const [searchParams, _] = useSearchParams();
  const token = searchParams.get('token') ?? '';

  const [form, setForm] = useState<RegistrationSchema>({
    token,
    email: '',
    password: '',
    organization_name: '',
    country: '',
    reporting_frequency: ReportingFreq.M,
  });
  const [errors, setErrors] = useState<FormErrors>({});

  const navigate = useNavigate();

  const { t } = useLanguage();

  const { data: countries } = useSupportedCountries();

  const options = [
    [ReportingFreq.M, t('registration.reportingFrequency.monthly')],
    [ReportingFreq.Q, t('registration.reportingFrequency.quarterly')],
    [ReportingFreq.Y, t('registration.reportingFrequency.yearly')],
  ];

  const mutation = useMutation({
    mutationFn: () => client.post('/web/register', form),
    onError: (err: any) => {
      if (err.response.status === 403) {
        const error = String(t('registration.authError'));
        toast.error(error);
        setErrors({ token: { message: error, error_value: form.token } });
      } else {
        toast.error(String(t('registration.formError')));
        setErrors(extractSchemaErrors(err, form));
      }
    },
    onSuccess: () => {
      toast.success(String(t('registration.success')));
      navigate('/login');
    },
  });

  const isError = (fieldName: keyof RegistrationSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof RegistrationSchema) =>
    !!form[fieldName] &&
    String(t(mapError(errors, fieldName, form[fieldName]) || ''));

  return (
    <ThemeProvider theme={registerTheme}>
      <StyledContainer>
        <OnboardingHeading text={t('registration.registerText')}>
          <form>
            <Box sx={{ mb: '44px' }}>
              <TextField
                sx={{ mb: '44px' }}
                id="email"
                label={t('registration.email')}
                type="email"
                value={form.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const newForm = { ...form, email: e.currentTarget.value };
                  setForm(newForm);
                  setErrors(validateForm(newForm));
                }}
                autoFocus
                variant="outlined"
                fullWidth
                required
                error={isError('email')}
                helperText={errorText('email')}
              />

              <Box sx={{ mb: '44px' }}>
                <PasswordVerificationForm
                  setNewPassword={(value: string) =>
                    setForm({ ...form, password: value })
                  }
                  translationPrefix="registration"
                />
              </Box>

              {isError('password') && (
                <Alert sx={{ mb: '44px' }} severity="error">
                  {errorText('password')}
                </Alert>
              )}

              <TextField
                sx={{ mb: '44px' }}
                id="organizationName"
                label={t('registration.organizationName')}
                type="text"
                value={form.organization_name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setForm({ ...form, organization_name: e.currentTarget.value })
                }
                variant="outlined"
                fullWidth
                required
                error={isError('organization_name')}
                helperText={errorText('organization_name')}
              />

              <FormControl sx={{ mb: '44px' }} required fullWidth>
                <InputLabel id="countryInputLabel">
                  {t('registration.country')}
                </InputLabel>
                <Select
                  labelId="countryInputLabel"
                  label={t('registration.country')}
                  id="countrySelect"
                  value={form.country}
                  onChange={(e: SelectChangeEvent<string | null>) =>
                    setForm({ ...form, country: e.target.value! })
                  }
                  error={isError('country')}
                >
                  {countries?.map((country: Country) => (
                    <MenuItem key={country.alpha3} value={country.alpha3}>
                      {country.name} ({country.alpha3})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Typography variant="h5" sx={{ mb: '8px' }}>
                {t('registration.reportingFrequency.reportingPeriod')}
              </Typography>
              <ButtonGroupField
                options={options}
                value={form.reporting_frequency as string}
                setValue={(period: string) =>
                  setForm({
                    ...form,
                    reporting_frequency: period as ReportingFreq,
                  })
                }
              />
            </Box>

            {isError('token') && (
              <Alert sx={{ mb: '44px' }} severity="error">
                {errorText('token')}
              </Alert>
            )}
          </form>

          <Button
            variant="contained"
            size="large"
            style={{ height: '48px' }}
            onClick={() => mutation.mutate()}
            disabled={
              Object.keys(validateForm(form)).length > 0 || mutation.isLoading
            }
            endIcon={<LoginIcon sx={{ marginRight: '24px' }} />}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                letterSpacing: '1px',
                marginLeft: '24px',
              }}
            >
              {t('registration.register')}
            </Typography>
          </Button>
        </OnboardingHeading>
      </StyledContainer>
    </ThemeProvider>
  );
}
