import { Button } from '@mui/material';
import { styled as styledMUI } from '@mui/material/styles';
import { StyleSheet } from '@react-pdf/renderer';

export const ESGReportButton = styledMUI(Button)`
  height: 48px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  border-radius: 24px;
  width: 244px;
  color: var(--white, #fff);
  text-align: center;
  border: 1px solid var(--main-dark-main, #38414f);
  background: var(--main-dark-main, #38414f);

  &:hover {
    border: 1px solid var(--main-dark-hover, #49525f);
    background: var(--main-dark-hover, #49525f);
  }

  &:active {
    border: 1px solid var(--main-dark-pressed, #5a636f);
    background: var(--main-dark-pressed, #5a636f);
  }

  &:disabled {
    cursor: auto;
    opacity: 0.5
  }
`;

export const linkStyles = StyleSheet.create({
  link: {
    fontFamily: 'Poppins',
    textDecorationColor: '#38414F',
    fontWeight: 'bold',
    color: '#38414F',
    lineHeight: '1.5',
  },
});
