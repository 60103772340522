import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = 320;
const iconHeight = 40;
// Opening animations:
// 1) animate width of the drawer itself
// 2) after animation is complete, animate item height and opacity

const openWidthMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const openHeightMixin = (theme: Theme): CSSObject => ({
  maxHeight: 200,
  overflow: 'hidden',
  transition: theme.transitions.create('max-height', {
    easing: theme.transitions.easing.sharp,
    delay: theme.transitions.duration.enteringScreen,
    duration: theme.transitions.duration.short,
  }),
});

const openOpacityHeightMixin = (theme: Theme): CSSObject => ({
  opacity: 1,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
    delay: theme.transitions.duration.enteringScreen,
  }),
});

// Closing animations:
// 1) animate item height
// 2) after animation is complete, animate width of the drawer

const closedWidthMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
    delay: theme.transitions.duration.short,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const closedHeightMixin = (theme: Theme): CSSObject => ({
  maxHeight: iconHeight,
  overflow: 'hidden',
  transition: theme.transitions.create('max-height', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
  }),
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  // necessary for content to be below top bar
  ...theme.mixins.toolbar,
  '& svg': {
    maxWidth: '100%',
  },
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  overflowX: 'hidden',
  flexShrink: 0,
  boxSizing: 'border-box',
  ...(open && openWidthMixin(theme)),
  ...(!open && closedWidthMixin(theme)),
  '& .MuiDrawer-paper': {
    ...(open && openWidthMixin(theme)),
    ...(!open && closedWidthMixin(theme)),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0, '8px'),
  },
  '& .MuiListItem-root': {
    padding: 0,
  },
  '& .MuiListItemButton-root': {
    minHeight: iconHeight,
    ...(open && openHeightMixin(theme)),
    ...(!open && closedHeightMixin(theme)),
    justifyContent: open ? 'initial' : 'center',
    borderRadius: '24px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.active': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  '& .MuiListItemIcon-root': {
    color: 'inherit',
    minWidth: 0,
    marginRight: open ? 6 : 'auto',
    justifyContent: 'center',
  },
  '& .MuiListItemText-root': {
    opacity: 0,
    ...(open && openOpacityHeightMixin(theme)),
    '& .MuiTypography-root': {
      lineHeight: 1.25,
      fontSize: '14px',
      fontWeight: 500,
    },
  },
}));
