import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const StyledSection = styled(Box)`
  padding: 36px 32px;
  border-radius: 16px;
  border: 1px solid #ccc;
  background: #fff;
`;

export const StyledTitle = styled(Typography)`
  padding: 20px 0px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0.48px;
`;

export const StyledHeader = styled(Box)`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.45px;

  border-left: 6px solid #fff;
`;

export const StyledCell = styled(Box)`
  width: 100%;
  padding: 10px;
  margin: 8px 0px 0px;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.45px;

  border-left: 6px solid #fff;
  background-color: #f2f2f2;
`;

export const StyledCellHeader = styled(Typography)`
  color: #606462;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.12px;
  margin-top: -5px;
`;

export const StyledCellText = styled(Typography)`
  margin-top: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.45px;
`;

export const StyledColumnName = styled(Typography)`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.45px;
`;
