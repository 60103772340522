import { FormControlLabel, Radio, RadioProps } from '@mui/material';
import React from 'react';

interface Props extends RadioProps {
  label: string;
}

export default function RadioField(props: Props) {
  const { label, checked, onClick } = props;
  return (
    <FormControlLabel
      sx={{ ml: 1 }}
      label={label}
      control={
        <Radio
          checked={checked}
          onClick={onClick}
          disabled={!!props.disabled}
        />
      }
    />
  );
}
