import React, { useMemo, useReducer, createContext, useContext } from 'react';

interface ModalState {
  open: boolean;
  conf: Record<string, any>;
}

// Define the action type for each action
interface OpenModalAction {
  type: 'OPEN_MODAL';
  payload: ModalState['conf'];
}

interface CloseModalAction {
  type: 'CLOSE_MODAL';
}

interface PreCloseModal {
  type: 'PRE_CLOSE_MODAL';
}

// Define the initial state
const initialState = {
  open: false,
  conf: {},
};

// Define the reducer function
const reducer = (
  state: ModalState,
  action: OpenModalAction | CloseModalAction | PreCloseModal
): ModalState => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        open: true,
        conf: action.payload,
      };
    case 'PRE_CLOSE_MODAL':
      return {
        ...state,
        open: false,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        open: false,
        conf: {},
      };
    default:
      return state;
  }
};

const ModalContext = createContext<{
  modalState: ModalState;
  openModal: (conf: ModalState['conf']) => void;
  closeModal: () => void;
}>({ modalState: initialState, openModal: () => {}, closeModal: () => {} });

// Custom hook to access the modal state and dispatch
export const useModal = () => useContext(ModalContext);

// Modal provider component
export const ModalProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openModal = (conf: any) => {
    dispatch({ type: 'OPEN_MODAL', payload: conf });
  };

  const closeModal = () => {
    dispatch({ type: 'PRE_CLOSE_MODAL' });
    setTimeout(() => {
      dispatch({ type: 'CLOSE_MODAL' });
    }, 300);
  };

  const value = useMemo(
    () => ({ modalState: state, openModal, closeModal }),
    [state]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
