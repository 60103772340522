import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  BackButton,
  NextButton,
  StyledDialog,
  StyledDialogContentText,
} from './index.styles';

export default function ResponsiveDialog({
  opened,
  setClicked,
  text,
  secondButtonText,
}: {
  opened: boolean;
  setClicked: Function;
  text: string;
  secondButtonText: string;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div>
      <StyledDialog
        fullScreen={fullScreen}
        open={opened || false}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <StyledDialogContentText>{text}</StyledDialogContentText>
        </DialogContent>
        <DialogActions>
          <BackButton onClick={() => {}}>
            <a href="mailto:contact@envirly.com">
              {t('about-company.contact-us')}
            </a>
          </BackButton>
          <NextButton onClick={() => setClicked()}>
            <span>{secondButtonText}</span>
          </NextButton>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}
