import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';
import {
  CbamSupportingDocumentForImportedGoodInSchema,
  CbamSupportingDocumentForProductionProcessInSchema,
} from '../types';
import { TFunction } from 'react-i18next';

export type FormErrors = SchemaErrors<
  | CbamSupportingDocumentForImportedGoodInSchema
  | CbamSupportingDocumentForProductionProcessInSchema
>;

export const requiredFields: (
  | keyof CbamSupportingDocumentForImportedGoodInSchema
  | keyof CbamSupportingDocumentForProductionProcessInSchema
)[] = ['reference_number', 'type'];

export const validateForm = (
  data:
    | CbamSupportingDocumentForImportedGoodInSchema
    | CbamSupportingDocumentForProductionProcessInSchema,
  t: TFunction
): FormErrors => {
  const builder = new ErrorBuilder(data);
  return builder
    .checkTruthy(requiredFields, t('validation.required'))
    .checkRule(
      ['validity_end_date'],
      (end_date) =>
        !end_date ||
        !data.validity_start_date ||
        end_date > data.validity_start_date,
      t('validation.invalidDateRange')
    )
    .build();
};
