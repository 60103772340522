import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import emissionPerEmployeeIcon from '../../../../images/emission_per_employee.png';
import emissionTotalIcon from '../../../../images/emission_total.png';
import scope1Icon from '../../../../images/scope1.png';
import scope2Icon from '../../../../images/scope2.png';
import scope3Icon from '../../../../images/scope3.png';
import styles from './styles.module.css';
import InfoModal from '../../InfoModal/infoModal';
import Percent from './Percent';
import {
  DashboardStats,
  DashboardStatsChanges,
} from '../../../../redux-file/charts/interface';
import { Block, Container, Main, Units, Wrapper } from './index.styles';
import SomethingWentWrong from '../../../../components/SomethingWentWrong';
import { IStatsProps } from './index.interface';
import QuestionnaireTitle from '../../Questionnaire/Title';
import { IntuitiveCalendarCollapsable } from '../../IntuitiveCalendar';
import { ReportingFreq } from '../../../../redux-file/interfaces';
import { StyledFlex } from 'src/components/StyledFlex';
import { useLanguage } from 'src/common/hooks';

export type TimeRange = {
  start: Date;
  end: Date;
};
// Keeps track of last selected time range
export const useValidTimeRange = (
  startTime: Date | null,
  endTime: Date | null
) => {
  const [timeRange, setTimeRange] = useState<TimeRange | null>(null);
  useEffect(() => {
    if (startTime && endTime) {
      setTimeRange({ start: startTime, end: endTime });
    }
  }, [startTime, endTime]);
  return timeRange;
};

export default function Stats({
  data,
  dataChanges,
  calendar,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  reportingPeriod,
  hideScope3,
}: {
  data: DashboardStats;
  dataChanges: DashboardStatsChanges;
  calendar: boolean;
  startTime: Date | null;
  setStartTime: Dispatch<SetStateAction<Date | null>>;
  endTime: Date | null;
  setEndTime: Dispatch<SetStateAction<Date | null>>;
  reportingPeriod: ReportingFreq;
  hideScope3?: boolean;
}) {
  if (!data) return <SomethingWentWrong />;

  const dataCopy = { ...data } as DashboardStats & {
    [key: string]: number | null;
  };
  const {
    emissionPerEmployee,
    emissionTotal,
    emissionScope1,
    emissionScope3,
    emissionScope2LocationBasedMethod,
    emissionScope2MarketBasedMethod,
    emissionPerIncome,
    emissionPerIncomeCurrency,
    energyConsumption,
  } = Object.keys(dataCopy).reduce((acc, key) => {
    const transformedKey = key.replace(/(_\w)/g, (m) => m[1].toUpperCase());
    return { ...acc, [transformedKey]: dataCopy[key] };
  }, {} as IStatsProps);

  const { t, language } = useLanguage();

  const formatNumber = (num: number) =>
    parseFloat(num.toFixed(3)).toLocaleString(language);

  return (
    <Container>
      <StyledFlex sx={{ p: '10px 20px' }}>
        <QuestionnaireTitle text={t('stats.title')} />

        {calendar && (
          <IntuitiveCalendarCollapsable
            reportingPeriod={reportingPeriod}
            startDate={startTime}
            setStartDate={setStartTime}
            endDate={endTime}
            setEndDate={setEndTime}
          />
        )}
      </StyledFlex>

      <Wrapper>
        <Block>
          <p className={styles.title}>
            <img src={emissionTotalIcon} alt="" />
            {t('stats.total-emission')}
          </p>
          <Main>
            {Number.isFinite(emissionTotal)
              ? formatNumber(emissionTotal!)
              : '--'}
            <Units>
              {'  '}t CO<sub>2</sub>eq
            </Units>
          </Main>
          <Percent percent={dataChanges.emission_total_change} />
        </Block>

        <Block>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>
              <img src={emissionPerEmployeeIcon} alt="" />
              {t('stats.emission-per-employee')}
            </p>
            <InfoModal>
              <p>{t('stats.emission-per-employee-info')}</p>
            </InfoModal>
          </div>
          <Main>
            {Number.isFinite(emissionPerEmployee)
              ? formatNumber(emissionPerEmployee!)
              : '--'}
            <Units>
              {'  '}t CO<sub>2</sub>eq
            </Units>
          </Main>
          <Percent percent={dataChanges.emission_per_employee_change} />
        </Block>

        <Block>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>{t('stats.emission-per-income')}</p>
            <InfoModal>
              <p>{t('stats.emission-per-income-info')}</p>
            </InfoModal>
          </div>

          <Main>
            {Number.isFinite(emissionPerIncome)
              ? formatNumber(emissionPerIncome!)
              : '--'}
            <Units>
              {'  '}
              {emissionPerIncomeCurrency ? (
                <>
                  t CO<sub>2</sub>eq/
                  {t(`currency.${emissionPerIncomeCurrency}`)}
                </>
              ) : (
                ''
              )}
            </Units>
          </Main>
          <Percent percent={dataChanges.emission_per_income_change} />
        </Block>

        <Block>
          <p className={styles.title}>
            <img className={styles.titleImageBig} src={scope2Icon} alt="" />
            {t('stats.energy-consumption')}
          </p>

          <Main>
            {Number.isFinite(energyConsumption)
              ? formatNumber(energyConsumption!)
              : '--'}
            <Units>{'  '}MWh</Units>
          </Main>
          <Percent percent={dataChanges.energy_consumption_change} />
        </Block>

        <Block>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>
              <img src={scope1Icon} alt="" />
              {t('stats.scope1')}
            </p>
            <InfoModal>
              <p>{t('stats.scope1-info')}</p>
            </InfoModal>
          </div>
          <Main>
            {Number.isFinite(emissionScope1)
              ? formatNumber(emissionScope1!)
              : '--'}
            <Units>
              {'  '}t CO<sub>2</sub>eq
            </Units>
          </Main>
          <Percent percent={dataChanges.emission_scope_1_change} />
        </Block>

        <Block>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>{t('stats.market-method')}</p>
            <InfoModal>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('stats.market-method-info'),
                }}
              />
            </InfoModal>
          </div>

          <Main>
            {Number.isFinite(emissionScope2MarketBasedMethod)
              ? formatNumber(emissionScope2MarketBasedMethod!)
              : '--'}
            <Units>
              {'  '}t CO<sub>2</sub>eq
            </Units>
          </Main>
          <Percent
            percent={dataChanges.emission_scope_2_market_based_method_change}
          />
        </Block>
        <Block>
          <div className={styles.titleWrapper}>
            <p className={styles.title}>{t('stats.local-method')}</p>
            <InfoModal>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('stats.local-method-info'),
                }}
              />
            </InfoModal>
          </div>

          <Main>
            {Number.isFinite(emissionScope2LocationBasedMethod)
              ? formatNumber(emissionScope2LocationBasedMethod!)
              : '--'}
            <Units>
              {'  '}t CO<sub>2</sub>eq
            </Units>
          </Main>
          <Percent
            percent={dataChanges.emission_scope_2_location_based_method_change}
          />
        </Block>
        <Block>
          {!hideScope3 && (
            <>
              <div className={styles.titleWrapper}>
                <p className={styles.title}>
                  <img src={scope3Icon} alt="" />
                  {t('stats.scope3')}
                </p>
                <InfoModal>
                  <p>{t('stats.scope3-info')}</p>
                </InfoModal>
              </div>
              <Main>
                {Number.isFinite(emissionScope3)
                  ? formatNumber(emissionScope3!)
                  : '--'}
                <Units>
                  {'  '}t CO<sub>2</sub>eq
                </Units>
              </Main>
              <Percent percent={dataChanges.emission_scope_3_change} />
            </>
          )}
        </Block>
      </Wrapper>
    </Container>
  );
}
