import React from 'react';
import { Text, Page, View, Image, StyleSheet } from '@react-pdf/renderer';
import { PDF_HEIGHT, PDF_WIDTH } from '../config';
import stylesCommon from './stylesCommon';
import { appConfig } from '../../../../../config';

const styles = StyleSheet.create({
  backgroundImage: {
    position: 'absolute',
    height: PDF_HEIGHT,
    width: PDF_WIDTH,
  },
});

export type Props = {
  children: string | React.ReactElement;
  backgroundImageName?: string;
  topRight?: String | React.ReactElement;
  mainHeader?: String | React.ReactElement;
  subHeader?: String | React.ReactElement;
  pageNumber?: String | React.ReactElement;
};

const BasePage = (props: Props) => {
  const { backgroundImageName, topRight, mainHeader, subHeader, pageNumber } =
    props;
  return (
    <Page orientation="landscape" size={[PDF_HEIGHT, PDF_WIDTH]}>
      <View>
        {!!backgroundImageName && (
          <Image
            src={`${appConfig.backendRootUrl}/static-backend/reports/${props.backgroundImageName}`}
            style={styles.backgroundImage}
          />
        )}

        {props.children}

        {!!topRight && (
          <Text style={stylesCommon.topRightHeader}>{topRight}</Text>
        )}
        {!!pageNumber && (
          <Text style={stylesCommon.bottomRightFooter}>{pageNumber}</Text>
        )}
        {!!mainHeader && (
          <Text style={stylesCommon.mainHeader}>{mainHeader}</Text>
        )}
        {!!subHeader && <Text style={stylesCommon.subheader}>{subHeader}</Text>}
      </View>
    </Page>
  );
};

export default BasePage;
