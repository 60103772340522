import { FieldValue } from './Field/Fields/types';
import { Field } from './Field/index.types';
import { Question } from './Question/index.types';
import { ModifiedQuestionAnswers, QuestionAnswer } from './types';
import * as jwt from 'src/utils/jwt';
import _ from 'lodash';

export const TEXT_COLOR = '#38414F'; // headlines, values
export const TEXT_COLOR_SECONDARY = '#49525F'; // paragraphs, labels

export const ERROR_COLOR = '#A03137';
export const HOVER_COLOR = '#F2F2F2';
export const DISABLED_COLOR = '#CCC';

const baseUrl = '/api-questionnaires';
const questionnaireAnswers = `${baseUrl}/questionnaire-answers`;
const questionnairePreview = `${baseUrl}/questionnaire-preview`;
const questionnaireAnswerRecords = `${baseUrl}/questionnaire-answer-records`;

const questionnaires = `${baseUrl}/questionnaires`;

export const paths = {
  baseUrl,
  questionnaireAnswers,
  questionnairePreview,
  questionnaireAnswerRecords,
  questionnaires,
};

export const lightenColor = (hexColor: string, amount: number) => {
  // Remove the '#' symbol and split the color code into individual hex digits
  const colorDigits = hexColor.slice(1).split('');

  // Function to increment a single hex digit by the specified amount
  function incrementHexDigit(hexDigit: string, amount: number) {
    const newValue = parseInt(hexDigit, 16) + amount;
    return Math.min(Math.max(newValue, 0), 15).toString(16);
  }

  // Increment each hex digit by the specified amount
  const newColorDigits = colorDigits.map((digit) =>
    incrementHexDigit(digit, amount)
  );

  // Combine the updated digits and add the '#' symbol to get the new color
  const newHexColor = `#${newColorDigits.join('')}`;

  return newHexColor;
};

function getContrastText(hexColor: string) {
  // Remove the '#' symbol and split the color code into individual hex digits
  const colorDigits = hexColor.slice(1).match(/.{2}/g);

  if (!colorDigits) return hexColor;

  // Convert hex digits to decimal
  const [r, g, b] = colorDigits.map((hex) => parseInt(hex, 16) / 255);

  // Calculate the relative luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Choose black or white as the contrast text color based on luminance
  return luminance > 0.5 ? TEXT_COLOR : '#FFFFFF';
}

export type ThemeColors = {
  main: string;
  light: string;
  lighter: string;
  dark: string;
  darker: string;
  contrastText: string;
  textColor: string;
  textColorSecondary: string;
  error: string;
  hover: string;
  disabled: string;
};

export const getThemeColors = (mainColor: string = '#E9BC46') => ({
  main: mainColor,
  light: lightenColor(mainColor, 1),
  lighter: lightenColor(mainColor, 2),
  dark: lightenColor(mainColor, -3),
  darker: lightenColor(mainColor, -5),
  contrastText: getContrastText(mainColor),
  textColor: TEXT_COLOR,
  textColorSecondary: TEXT_COLOR_SECONDARY,
  error: ERROR_COLOR,
  hover: HOVER_COLOR,
  disabled: DISABLED_COLOR,
});

export const themeColors = {
  'co2.standard': '#388276',
  'lca.standard': '#7E4308',
  'esg.standard': '#38414F',
  'esg.esrs2': '#E9BC46',
  'esg.climate-change-e1': '#A1DF76',
  'esg.pollution-e2': '#CEDF9D',
  'esg.eater-and-marine-resources-e3': '#B6D9FC',
  'esg.biodiversity-and-Ecosystem-e4': '#A1E4C8',
  'esg.resources-use-and-circular-economy-e5': '#97E3FB',
  'esg.own-workforce-s1': '#FAAD94',
  'esg.workers-in-the-value-chain-s2': '#F5CF96',
  'esg.affected-communities-s3': '#E4B78D',
  'esg.consumers-and-end-users-s4': '#FFC28B',
  'esg.business-conduct-g1': '#E0BFE3',
};

export const parseValueToInteger = (value: FieldValue) => {
  const parsedValue = parseInt(String(value), 10);
  return Number.isNaN(parsedValue) ? 0 : parsedValue;
};

export const textStyle = `
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.32px;
`;

export const isDependencyMet = (
  dependencyField: Field,
  dependencyFieldValue: FieldValue,
  dependencyValue: string
) => {
  // returning false if dependencyFieldValue is not set
  // by default (before user input) dependencyFieldValues are null
  if (dependencyFieldValue === null) return false;

  // returning simple value checking for not multiselect fields
  if (dependencyField?.type !== 'multiselect') {
    return String(dependencyFieldValue) === dependencyValue;
  }
  // multiselect type dependency checking
  if (dependencyField?.type === 'multiselect') {
    // ensure any option is clicked
    // (otherwise dependencyFieldValue is null)
    if (Array.isArray(dependencyFieldValue)) {
      // splitting dependency values by comma
      const dependencyValues = dependencyValue.replaceAll(' ', '').split(',');
      // checking if all each dependency values are met
      // return false if at least one is not met
      for (const optionValue of dependencyValues) {
        if (!dependencyFieldValue.includes(optionValue)) return false;
      }
    }
  }

  return true;
};

// unsuring keys are in record
// for rare case of adding new fields (in django-admin) after quesionanswer was created
// used as post-data and for calculating dependencies
export const buildFieldsValuesMap = (
  questionAnswer: QuestionAnswer,
  fields: Field[]
) =>
  Object.fromEntries(
    fields.map((field) => {
      const _recordValue = questionAnswer.record?.[field.key];
      const recordValue = _recordValue === undefined ? null : _recordValue;
      return [field.key, recordValue];
    })
  );

// resolving field dependencies
// assuming fields are ordered
// dependencies are checked only for upper/previous fields
export const resolvedDependencyFieldsVisibility = (
  fields: Field[],
  questionAnswer: QuestionAnswer
) => {
  // init visibility true on all fields
  const fieldsVisibility = Object.fromEntries(
    fields.map((field) => [field?.key, true])
  );

  // {field.key: value} object
  const fieldsValuesMap = buildFieldsValuesMap(questionAnswer, fields);

  for (const field of fields) {
    // checking each dependency
    for (const dependency of field?.dependencies || []) {
      const {
        action,
        value: dependencyValue,
        from_field_key: fromKey,
      } = dependency;

      // localizing from Field and its value
      const dependencyField = fields?.find((field) => field.key === fromKey);
      const dependencyFieldValue = fieldsValuesMap[fromKey];

      // if dependency field does not exist show field
      if (!dependencyField) continue;

      // if fromKey does not exist in fieldsVisibility
      // falsly defined dependency
      if (fieldsVisibility[fromKey] === undefined) {
        fieldsVisibility[field?.key] = false;
        continue;
      }
      // dependency field is already hidden, inherit
      if (fieldsVisibility[fromKey] === false) {
        fieldsVisibility[field?.key] = false;
        continue;
      }
      // checking if dependnecy is met
      const dependencyMet = isDependencyMet(
        dependencyField,
        dependencyFieldValue,
        dependencyValue
      );
      // hiding or showing field (based on isDependencyMet)
      if (dependencyMet && action === 'hide')
        fieldsVisibility[field?.key] = false;
      if (!dependencyMet && action === 'show')
        fieldsVisibility[field?.key] = false;
    }
  }

  return fieldsVisibility;
};

// fields filtered by dependency met true
export const resolvedDependencyFields = (
  fields: Field[],
  questionAnswer: QuestionAnswer
) => {
  // resolving dependencies (visibility)
  const fieldsVisibility = resolvedDependencyFieldsVisibility(
    fields,
    questionAnswer
  );
  // returning filtered fields
  return fields.filter((field) => fieldsVisibility[field?.key]);
};

// clears fields values if its dependencies are not met (sets to null) before sending post/put request
export const preparefieldsValues = (
  questionAnswer: QuestionAnswer,
  fields: Field[]
) => {
  // builds initial fields key-value map
  const fieldsValuesMap = buildFieldsValuesMap(questionAnswer, fields);
  // resolving dependencies (visibility)
  const visibleFields = resolvedDependencyFieldsVisibility(
    fields,
    questionAnswer
  );
  for (const field of fields) {
    // if field is a boolean type and is none, change to False
    if (field?.type === 'boolean' && fieldsValuesMap[field?.key] === null)
      fieldsValuesMap[field?.key] = false;

    // Check the visibility for the current key
    if (visibleFields[field?.key] === false) {
      // If visibility is false, set the value to null in resolvedFields
      fieldsValuesMap[field?.key] = null;
    }
  }
  return fieldsValuesMap;
};

export const genEmptyAnswer = (
  question: Question,
  questionnaireAnswerId: string | undefined,
  order: number = 0
) => ({
  id: `temp-id-${Date.now()}`,
  updated_at: new Date().toISOString(),
  question: question.id,
  questionnaire_answer: String(questionnaireAnswerId),
  record: Object.fromEntries(question.field_set.map((f) => [f.key, null])),
  order,
});

export const injectNewAnswers = (
  data: any,
  modifiedQuestionAnswers: ModifiedQuestionAnswers = {}
) => {
  const newData = _.cloneDeep(data);

  const questions: Question[] = Object.values(
    newData?.questionnaire?.questions || []
  );

  for (const question of questions) {
    const questionanswers = question?.questionanswers || {};
    const hasNoAnswers = Object.keys(questionanswers).length === 0;
    const isBeingModified = !!modifiedQuestionAnswers[question.id];
    if (hasNoAnswers && !isBeingModified) {
      const emptyAnswer = genEmptyAnswer(
        question,
        data.questionnaire_answer_id
      );
      questionanswers[emptyAnswer.id] = emptyAnswer;
    }
    const modifiedAnswers = modifiedQuestionAnswers[question.id] || {};
    newData.questionnaire.questions[question.key].questionanswers = {
      ...questionanswers,
      ...modifiedAnswers,
    };
  }
  return newData;
};

export const createTokenFetcher = (
  tokenProvider: () => Promise<string>,
  initialToken?: string
) => {
  let token: string | undefined = initialToken;

  const fetchtoken = async () => {
    const payload = jwt.decodeToken(token);

    if (!payload) {
      try {
        token = await tokenProvider();
        jwt.tryUpdateTimestampSkewFromFreshToken(token);
      } catch (err) {
        console.error(err);
      }
    }
    return token;
  };
  return fetchtoken;
};
