import { Button } from '@mui/material';
import React from 'react';
import {
  CbamMeasurementBasedInSchema,
  CbamMeasurementBasedOutSchema,
} from 'src/Cbam/types';
import { useMeasurementBased } from 'src/Cbam/hooks';
import { StyledPanel } from 'src/components/StyledPanel';
import { StyledNumberField, StyledTextField } from '../../../styles';

import { StyledFlex } from 'src/components/StyledFlex';
import { useChangeTracker } from '../components/CounterProvider';
import { useTranslation } from 'react-i18next';
import { FormErrors, requiredFields, validateForm } from './FormValidation';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import { toast } from 'react-toastify';

interface Props {
  productionProcessId: number;
  id?: number;
  onSaved?: () => void;
}
export default function MeasurementBased(props: Props) {
  const { productionProcessId, id, onSaved } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.productionProcess.measurementBased',
  });
  const { isModified, setIsModified } = useChangeTracker();
  const measurementsBased = useMeasurementBased(Number(productionProcessId));
  const measurementBased = measurementsBased.data.find(
    (m) => m.id === Number(id)
  );

  const initialForm = {
    name: '',
    production_process_id: productionProcessId,
    co2_emissions: null,
    n2o_emissions: null,
  };

  const [form, setForm] = React.useState<
    CbamMeasurementBasedOutSchema | CbamMeasurementBasedInSchema
  >(initialForm);

  const [errors, setErrors] = React.useState<FormErrors>({});

  const handleFormChange = (key: string, value: any) => {
    setIsModified(true);
    const newForm = {
      ...form,
      [key]: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
  };

  const isError = (fieldName: keyof CbamMeasurementBasedInSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof CbamMeasurementBasedInSchema) =>
    isError(fieldName) && mapError(errors, fieldName, form[fieldName] || '');

  React.useEffect(() => {
    if (measurementBased) setForm(measurementBased);
  }, [measurementBased]);

  const onSuccess = () => {
    setIsModified(false);
    onSaved?.();
  };

  const onError = (err: any) => {
    toast.error('Failed to save');
    const extractedErrors = extractSchemaErrors(err, form);
    setErrors(extractedErrors);
  };

  const handleSaveClick = () =>
    !id
      ? measurementsBased.create
          .mutateAsync(form)
          .then(onSuccess)
          .catch(onError)
      : measurementsBased.update
          .mutateAsync({ ...form, id })
          .then(onSuccess)
          .catch(onError);

  return (
    <StyledPanel sx={{ m: '20px 0px' }}>
      <StyledTextField
        label={t('form.name')}
        value={form.name}
        onChange={(e) => handleFormChange('name', e.target.value)}
        required={requiredFields.includes('name')}
        error={isError('name')}
        helperText={errorText('name')}
      />

      <StyledNumberField
        label={t('form.co2_emissions')}
        value={form.co2_emissions}
        onChange={(e) => handleFormChange('co2_emissions', e.target.value)}
        required={requiredFields.includes('co2_emissions')}
        error={isError('co2_emissions')}
        helperText={errorText('co2_emissions')}
      />
      <br />
      <StyledNumberField
        label={t('form.n2o_emissions')}
        value={form.n2o_emissions}
        onChange={(e) => handleFormChange('n2o_emissions', e.target.value)}
        required={requiredFields.includes('n2o_emissions')}
        error={isError('n2o_emissions')}
        helperText={errorText('n2o_emissions')}
      />

      <StyledFlex>
        <Button
          onClick={() =>
            id ? measurementsBased.delete.mutate(id) : onSaved?.()
          }
          color="primary"
        >
          Remove Answer
        </Button>
        {isModified && (
          <Button
            color="primary"
            onClick={handleSaveClick}
            sx={{ minWidth: '200px' }}
            disabled={Object.keys(validateForm(form, t)).length > 0}
          >
            {id ? 'Save' : 'Create'}
          </Button>
        )}
      </StyledFlex>
    </StyledPanel>
  );
}
