import React from 'react';

import { Button, Typography } from '@mui/material';
import { StyledPanel } from 'src/components/StyledPanel';
import { useLanguage } from 'src/common/hooks';

export default function ForImporters() {
  const { language } = useLanguage();
  return (
    <StyledPanel>
      <Typography variant="h3">Envirly CBAM for EU Importers</Typography>
      <br />
      <Typography variant="body1">
        Guide for suppliers of goods covered by CBAM. Find out how to report the
        carbon footprint of goods exported to the EU.
      </Typography>
      <br />
      <Button
        href={`https://assets.envirly.pl/intro/${language}/CBAM.pdf`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Download manual
      </Button>
    </StyledPanel>
  );
}
