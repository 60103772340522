import { InfoOutlined } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { StyledPanel } from 'src/Esg/styles';

export default function InfoPanel(content: { children: React.ReactNode }) {
  return (
    <StyledPanel sx={{ display: 'flex', alignItems: 'center' }}>
      <Box>
        <InfoOutlined color="secondary" fontSize="large" />
      </Box>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ ml: '12px', mr: '12px' }}
      />
      <Box>{content.children}</Box>
    </StyledPanel>
  );
}
