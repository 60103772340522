import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import BasePage from '../Base';
import { BusinessTravelEmissionsMapDataType } from './types';
import { LOGO_MARGIN_LEFT } from '../../config';
import PDFChart from '../../../components/PDFChart';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  chart: {
    position: 'absolute',
    top: 200,
    left: LOGO_MARGIN_LEFT,
    width: 1760,
    height: 858,
  },
});

type BusinessTravelEmissionsMapProps = {
  data: {
    business_travel_emissions_map: BusinessTravelEmissionsMapDataType;
  };
};

const BusinessTravelEmissionsMap = ({
  data,
}: BusinessTravelEmissionsMapProps) => {
  const {
    page_title: pageTitle,
    top_right: topRight,
    chart,
  } = data.business_travel_emissions_map;

  return (
    <BasePage
      backgroundImageName="Report_envirly_slide_8.png"
      mainHeader={pageTitle}
      topRight={topRight}
    >
      <View>
        <PDFChart base64={chart} style={styles.chart} />
      </View>
    </BasePage>
  );
};

export default BusinessTravelEmissionsMap;
