import React from 'react';
import Xarrow, { anchorType } from 'react-xarrows';

import { RegisterArrowsProps as Props } from './index.types';
import { getFlatNodes } from '../../helpers';
import { Node } from '../Node/index.types';

export default function RegisterArrows({ data }: Props) {
  let nodes: Node[] = [];
  for (const stage of data) {
    nodes = [...nodes, ...getFlatNodes(stage?.children || [], stage.key)];
  }
  const nodesMap = Object.fromEntries(nodes.map((node) => [node.id, node]));
  return (
    <>
      {nodes
        .filter((node) => node.id && node.links_to)
        .map((node) => {
          const linkedNode = nodesMap[String(node.links_to)];

          const anchor: anchorType =
            linkedNode?.stage !== node.stage ? ['left', 'right'] : 'auto';

          return (
            <Xarrow
              key={`xarrow-${node.id}`}
              start={String(node.id)}
              end={String(node.links_to)}
              strokeWidth={2}
              lineColor="#A9A9A9"
              headColor="#A9A9A9"
              tailColor="#A9A9A9"
              showTail
              tailShape="circle"
              tailSize={3}
              startAnchor={anchor}
              endAnchor={anchor}
            />
          );
        })}
    </>
  );
}
