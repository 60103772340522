import React from 'react';

import { Svg, Circle, Rect } from '@react-pdf/renderer';

export default function SVGCheckbox() {
  return (
    <Svg viewBox="0 0 100 100">
      <Circle cx="20" cy="20" r="19" fill="#E9BC46" />
      <Rect
        x="21"
        y="-13"
        width="10"
        height="22"
        fill="#38414F"
        transform="rotate(45)"
      />
      <Rect
        x="18"
        y="-16"
        width="10"
        height="22"
        fill="#E9BC46"
        transform="rotate(45)"
      />
    </Svg>
  );
}
