import { Typography, styled } from '@mui/material';

export const StyledContainer = styled('div')`
  padding: 32px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 16px;
  margin-bottom: 24px;
`;

const esgTitle = `
  font-size: 18px;
  font-weight: 700;
  line-height: 34px;
  color: #2f2f2f;
`;
// const lcaTitle = `
//   font-size: 18px;
//   font-weight: 700;
//   line-height: 32px;
//   color: #281302;
// `;

// const co2Title = `
//   font-size: 24px;
//   font-weight: 600;
//   line-height: 34px;
//   color: #38414f;
// `;

const esgDescription = `
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #49525F;
`;
// const lcaDescription = `
//   font-size: 18px;
//   font-weight: 500;
//   line-height: 28px;
//   color: #4d4d4d;
// `;

// const co2Description = `
//   font-size: 18px;
//   font-weight: 400;
//   line-height: 28px;
//   color: #606462;
// `;

export const StyledQuestionTitle = styled(Typography)`
  margin-bottom: 16px;
  ${esgTitle}
`;

export const StyledQuestionDescription = styled(Typography)`
  ${esgDescription};
  margin-bottom: 24px;
`;

export const StyledMoreInformation = styled(Typography)`
  color: #38414f;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.36px;
  background-color: #e9bc46;
  padding: 4px 0 4px 32px;
  max-width: 230px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 28px;
  margin-left: -32px;
`;

export const StyledQuestionSubtitle = styled(Typography)`
  color: #49525f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.48px;
  margin-bottom: 16px;
`;

export const StyledQuestionTitleWrapper = styled('div')`
  padding-bottom: 16px;
`;

export const iconOutlineSizePx = 60;
export const questionnairePaddingLeftRightPx = 54;

export const StyledSectionIcon = styled('div')`
  position: absolute;
  top: -12px;
  left: ${-(iconOutlineSizePx / 2 + questionnairePaddingLeftRightPx)}px;
  height: ${iconOutlineSizePx}px;
  width: ${iconOutlineSizePx}px;
  border: 2px solid #bbb;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
