import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import { StyledTitle } from '../index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import BackButton from '../../components/BackButton';
import OrganizationalStructure, {
  useOrganizationalStructure,
} from '../components/Panels/OrganizationalStructure';
import BasicInformation, {
  useBasicInformation,
} from '../components/Panels/BasicInformation';
import AvailableProducts, {
  useAvailableProducts,
} from '../components/Panels/AvailableProducts';
import Location, { useLocation } from '../components/Panels/Location';
import { client } from 'src/utils/api-client';
import {
  OrganizationInFormSchema,
  OrganizationTemplateFormSchema,
} from '../index.types';
import {
  UnitFormErrors,
  UnitFormValidationContext,
  getSchemaFromStates,
  setStatesFromTemplate,
  validateForm,
} from '../components/Panels/unit-utils';
import { toast } from 'react-toastify';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { extractSchemaErrors } from '../../utils/validation';
import { withEmptyStringsAsNull } from '../../utils/helpers';
import { UploadedFileSchema } from '../../Ghg/CodeOfConductSurvey/types';
import { useOrganizationalLogo } from '../components/Panels/UploadOrganizationalLogo';

export default function NewUnit() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { state } = useRouterLocation();
  const initParentId = state?.parentId;

  const organizationalStructureState = useOrganizationalStructure();
  const basicInformationState = useBasicInformation();
  const locationState = useLocation();
  const availableProductsState = useAvailableProducts();
  const organizationalLogoState = useOrganizationalLogo();
  const formState = {
    availableProductsState,
    basicInformationState,
    locationState,
    organizationalStructureState,
    organizationalLogoState,
  };
  const [formStateErrors, setFormStateErrors] = React.useState<UnitFormErrors>(
    {}
  );

  const tryFillFromTemplate = (template: OrganizationTemplateFormSchema) => {
    // check if the form has data, by checking if any of the fields is filled,
    // except the available products
    const formHasAnyData = Object.values(formState)
      .filter((state) => state !== availableProductsState)
      .some((state) =>
        Object.entries(state)
          // check if value is truthy, and is not a state setter
          .some(([k, v]) => !!v && k.indexOf('set') !== 0)
      );

    if (
      formHasAnyData &&
      !window.confirm(
        'Changing the parent group will reset this form. Are you sure?'
      )
    )
      return;

    setStatesFromTemplate(formState, template);
  };

  const handleSetGroupId = (group: number | undefined) => {
    if (!group) return;
    client
      .get<OrganizationTemplateFormSchema>(
        `/web/settings_v2/organizations/${group}/new-organization-template`
      )
      .then((response) => {
        tryFillFromTemplate(response.data);
      });
  };

  React.useEffect(() => {
    handleSetGroupId(initParentId);
  }, []);

  const handleCreate = () => {
    const schema = getSchemaFromStates(formState);
    const formErrors = validateForm(schema, true);
    setFormStateErrors(formErrors);
    if (Object.keys(formErrors).length > 0) {
      return;
    }
    client
      .post(`/web/settings_v2/organizations`, withEmptyStringsAsNull(schema))
      .then((response) => {
        toast.success(String(t('superadmin.toast.unit-created')));
        navigate('/superadmin');
        return response.data;
      })
      .catch((err) => {
        toast.error(String(t('superadmin.toast.validation-error')));
        setFormStateErrors(extractSchemaErrors(err, schema));
      });
  };

  return (
    <>
      <StyledFlex>
        <StyledTitle>
          <BackButton navigateTo="/superadmin" />
          {t('superadmin.unit.newUnit')}
        </StyledTitle>
        <Button variant="contained" onClick={handleCreate}>
          {t('superadmin.unit.buttons.create')}
        </Button>
      </StyledFlex>

      <UnitFormValidationContext.Provider value={formStateErrors}>
        <OrganizationalStructure
          {...organizationalStructureState}
          setGroupId={handleSetGroupId}
        />
        <BasicInformation {...basicInformationState} />
        <Location {...locationState} />
        <AvailableProducts {...availableProductsState} />
      </UnitFormValidationContext.Provider>
      <StyledFlex sx={{ mt: '50px' }}>
        <div />
        <Button variant="contained" onClick={handleCreate}>
          {t('superadmin.unit.buttons.create')}
        </Button>
      </StyledFlex>
    </>
  );
}
