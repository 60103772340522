import React, { useState } from 'react';
import { SoldProductsInterface } from '../../../../redux-file/questionnaires/interfaces';
import {
  StyledHeader,
  StyledTd,
} from '../../../Pages/Questionnaire/index.styles';
import { companiesColors } from '../../../Pages/SuperDashboard/data';
import { useNavigate } from 'react-router-dom';
import TableButton from '../../TableButton';
import { useAppDispatch, useAppSelector } from '../../../../redux-file/hooks';
import { timeConverter } from '../../../../utils/timeConverter';
import {
  createSoldProductsFinalProductDirectData,
  createSoldProductsFinalProductIndirectData,
  createSoldProductsFuelOrFeedstockData,
  deleteFuelProduct,
  deleteSoldProductsFinalProductDirect,
  deleteSoldProductsFinalProductIndirect,
  getSoldProductsData,
} from '../../../../redux-file/questionnaires/thunks';
import {
  getFuelProductAPI,
  getSoldProductsFinalProductDirectAPI,
  getSoldProductsFinalProductIndirectAPI,
} from '../../../../utils';
import styles from '../../../Pages/Questionnaire/styles.module.css';
import { useGhgOrganization, useLanguage } from 'src/common/hooks';

export default function SoldProductPreview({
  state,
  id,
}: {
  state: SoldProductsInterface[];
  id: number;
}) {
  const { t, language } = useLanguage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector(
    (redux_state) => redux_state.questionnaire
  );

  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <StyledHeader>
        <StyledTd width={34} color={companiesColors[id]}>
          <p>{state[id].name}</p>
        </StyledTd>
        <StyledTd width={22} color={companiesColors[id]}>
          {t(`sold-products.${state[id].product_type}`)}
        </StyledTd>
        <StyledTd width={22} color={companiesColors[id]}>
          {state[id].emission_intensity === null
            ? t('not-available')
            : (+state[id].emission_intensity).toFixed(3)}
        </StyledTd>
        <StyledTd width={22} color={companiesColors[id]}>
          {state[id].emission_kg_eq_co2.toFixed(3)}
        </StyledTd>
        <StyledTd width={10} color={companiesColors[id]} numberOfIcons="three">
          <TableButton
            hoverText="remove"
            icon="remove"
            onClick={() => setShowModal(true)}
          />
          <TableButton
            hoverText="copy"
            icon="copy"
            onClick={() => {
              if (state[id].product_type === 'fuel_or_feedstock') {
                if (!!reportingPeriod && !!selectedDate) {
                  getFuelProductAPI(
                    organization.id,
                    timeConverter(selectedDate, reportingPeriod),
                    state[id].participant_id
                  ).then((value) => {
                    const { fuel_or_feedstock, waste } = value;

                    const temp = {
                      ...fuel_or_feedstock,
                      name: `${fuel_or_feedstock.name} ${
                        language === 'en' ? 'copy' : 'kopia'
                      }`,
                    };

                    dispatch(
                      createSoldProductsFuelOrFeedstockData({
                        period: timeConverter(selectedDate, reportingPeriod),
                        data: { fuel_or_feedstock: temp, waste },
                      })
                    ).then(() =>
                      dispatch(
                        getSoldProductsData({
                          period: timeConverter(selectedDate, reportingPeriod),
                        })
                      )
                    );
                  });
                }
              }

              if (state[id].product_type === 'final_direct') {
                if (!!reportingPeriod && !!selectedDate) {
                  getSoldProductsFinalProductDirectAPI(
                    organization.id,
                    timeConverter(selectedDate, reportingPeriod),
                    state[id].participant_id
                  ).then((value) => {
                    const { name } = value;

                    const temp = `${name} ${
                      language === 'en' ? 'copy' : 'kopia'
                    }`;

                    dispatch(
                      createSoldProductsFinalProductDirectData({
                        period: timeConverter(selectedDate, reportingPeriod),
                        data: { ...value, name: temp },
                      })
                    ).then(() =>
                      dispatch(
                        getSoldProductsData({
                          period: timeConverter(selectedDate, reportingPeriod),
                        })
                      )
                    );
                  });
                }
              }

              if (state[id].product_type === 'final_indirect') {
                if (!!reportingPeriod && !!selectedDate) {
                  getSoldProductsFinalProductIndirectAPI(
                    organization.id,
                    timeConverter(selectedDate, reportingPeriod),
                    state[id].participant_id
                  ).then((value) => {
                    const { name } = value;

                    const temp = `${name} ${
                      language === 'en' ? 'copy' : 'kopia'
                    }`;

                    dispatch(
                      createSoldProductsFinalProductIndirectData({
                        period: timeConverter(selectedDate, reportingPeriod),
                        data: { ...value, name: temp },
                      })
                    ).then(() =>
                      dispatch(
                        getSoldProductsData({
                          period: timeConverter(selectedDate, reportingPeriod),
                        })
                      )
                    );
                  });
                }
              }
            }}
          />
          <TableButton
            hoverText="edit"
            icon="edit"
            onClick={() => {
              if (state[id].product_type === 'fuel_or_feedstock') {
                navigate(
                  `/ghg/emissions/questionnairepage-sold-products/fuels-and-raw-materials/${state[id].participant_id}`
                );
              }
              if (state[id].product_type === 'final_direct') {
                navigate(
                  `/ghg/emissions/questionnairepage-sold-products/final-products-direct/${state[id].participant_id}`
                );
              }

              if (state[id].product_type === 'final_indirect') {
                navigate(
                  `/ghg/emissions/questionnairepage-sold-products/final-products-indirect/${state[id].participant_id}`
                );
              }
            }}
          />
        </StyledTd>
      </StyledHeader>
      {showModal && (
        <div className={styles.deleteModal}>
          <div className={styles.deleteModalOverlay} />
          <div className={styles.deleteModalBox}>
            <button
              type="button"
              className={styles.deleteModalClose}
              onClick={() => setShowModal(false)}
            >
              x
            </button>
            <h2 className={styles.deleteModalText}>
              {t('sold-products.delete-modal')}
            </h2>
            <div className={styles.deleteModalButtonsBox}>
              <button
                className={styles.deleteModalButtonPrimary}
                type="button"
                onClick={() => {
                  if (state[id].product_type === 'fuel_or_feedstock') {
                    dispatch(
                      deleteFuelProduct({
                        period: timeConverter(selectedDate, reportingPeriod),
                        participant_id: state[id].participant_id,
                      })
                    ).then(() =>
                      dispatch(
                        getSoldProductsData({
                          period: timeConverter(selectedDate, reportingPeriod),
                        })
                      )
                    );
                  }

                  if (state[id].product_type === 'final_direct') {
                    dispatch(
                      deleteSoldProductsFinalProductDirect({
                        period: timeConverter(selectedDate, reportingPeriod),
                        participant_id: state[id].participant_id,
                      })
                    ).then(() =>
                      dispatch(
                        getSoldProductsData({
                          period: timeConverter(selectedDate, reportingPeriod),
                        })
                      )
                    );
                  }

                  if (state[id].product_type === 'final_indirect') {
                    dispatch(
                      deleteSoldProductsFinalProductIndirect({
                        period: timeConverter(selectedDate, reportingPeriod),
                        participant_id: state[id].participant_id,
                      })
                    ).then(() =>
                      dispatch(
                        getSoldProductsData({
                          period: timeConverter(selectedDate, reportingPeriod),
                        })
                      )
                    );
                  }

                  setShowModal(false);
                }}
              >
                {t('targets.yes')}
              </button>
              <button
                className={styles.deleteModalButtonSecondary}
                type="button"
                onClick={() => setShowModal(false)}
              >
                {t('targets.no')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
