import { client } from '../../../../../utils/api-client';
import { createTokenFetcher } from '../../../../components/QuestionnaireV3/helpers';

// eslint-disable-next-line import/prefer-default-export
export const createEsgTokenFetcher = (
  organizationId: number,
  reportId: string,
  questionnaireAnswerId: string
) => {
  return createTokenFetcher(async () => {
    const res = await client.post(
      `web/esg_v2/${organizationId}/${reportId}/indicators/${questionnaireAnswerId}/token`
    );
    return res.data;
  });
};
