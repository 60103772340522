import React from 'react';

export default function RecommendationsIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 21.6727C9 22.2327 9.45 22.6909 10 22.6909H14C14.55 22.6909 15 22.2327 15 21.6727V20.6545H9V21.6727ZM12 2.32727C8.14 2.32727 5 5.52436 5 9.45454C5 11.8778 6.19 14.0058 8 15.2989V17.6C8 18.16 8.45 18.6182 9 18.6182H15C15.55 18.6182 16 18.16 16 17.6V15.2989C17.81 14.0058 19 11.8778 19 9.45454C19 5.52436 15.86 2.32727 12 2.32727ZM14 14.24V16.5818H10V14.24C8.48 13.1505 7 12.0305 7 9.45454C7 6.64436 9.24 4.36363 12 4.36363C14.76 4.36363 17 6.64436 17 9.45454C17 11.9898 15.49 13.1709 14 14.24Z"
        fill="currentColor"
      />
    </svg>
  );
}
