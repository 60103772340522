import React, { useState } from 'react';
import { getMonth, getYear } from 'date-fns';
import { useQuery } from 'react-query';

import { useGhgOrganization, useLanguage } from 'src/common/hooks';
import { client } from 'src/utils/api-client';

import Stats, { useValidTimeRange } from '../../components/Dashboard/Stats';
import Box from '../../components/Box';
import GraphComponent from '../../components/Dashboard/GraphComponent';
import { timeConverter } from '../../../utils/timeConverter';
import TableChart from '../../components/Dashboard/TableChart';
import { Row } from './index.styles';
import { OrganizationTag, hasTag } from '../../../utils/tags';

const year = getYear(new Date());
const month = getMonth(new Date());
export const start = new Date(year, month, 1);

export default function Dashboard() {
  const { language } = useLanguage();
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;
  const [dashboardStatsStartTime, setEmissionsStatsStartTime] =
    useState<Date | null>(start);
  const [dashboardStatsEndTime, setEmissionsStatsEndTime] =
    useState<Date | null>(new Date());
  const timeRange = useValidTimeRange(
    dashboardStatsStartTime,
    dashboardStatsEndTime
  );
  const queryEnabled = !!timeRange;

  const { data: dashboardStats } = useQuery(
    ['dashboard-stats', organization.id, timeRange],
    async () => {
      const start = timeConverter(
        timeRange?.start,
        organization.co2.reporting_freq
      );
      const end = timeConverter(
        timeRange?.end,
        organization.co2.reporting_freq
      );

      const res = await client.get(
        `/web/v2/dashboardstats?organization_id=${organization.id}&start=${start}&end=${end}`
      );
      return res.data;
    },
    {
      enabled: queryEnabled,
      cacheTime: 0,
    }
  );

  const { data: dashboardFigures } = useQuery(
    ['dashboard-figures', organization.id, timeRange, language],
    async () => {
      const start = timeConverter(
        timeRange?.start,
        organization.co2.reporting_freq
      );
      const end = timeConverter(
        timeRange?.end,
        organization.co2.reporting_freq
      );

      const res = await client.get(
        `/web/v2/dashboard_figures?start=${start}&end=${end}&organization_id=${organization.id}`
      );
      return res.data;
    },
    {
      enabled: queryEnabled,
      cacheTime: 0,
    }
  );

  const isFiguresLoading = !dashboardFigures;
  const hideScope3 = hasTag(organization, OrganizationTag.HideScope3);

  const {
    carbon_footprint: carbonFootprint,
    carbon_footprint_tables: carbonFootprintTables,
    total_emissions_by_scope: totalEmissionsByScope,
    total_emissions_by_scope_tables: totalEmissionsByScopeTables,
    emission_ranking_scope_3: SAEmissionRankingScope3,
    employees_means_of_transport: employeesMeansOfTransport,
    employee_emissions: employeesStats,
    business_travel_emissions: businessTravelEmissions,
    goods_and_services_emissions: goodsAndServicesEmissions,
  } = dashboardFigures || {};

  return (
    <div>
      {/* TODO: update and re-enable tutorial once sidebar is complete */}
      {/* {consents.privacy_policy && consents.terms_of_service && (
        <CloudTutorial />
      )} */}
      <Stats
        reportingPeriod={reportingPeriod || 'M'}
        calendar
        startTime={dashboardStatsStartTime}
        setStartTime={setEmissionsStatsStartTime}
        endTime={dashboardStatsEndTime}
        setEndTime={setEmissionsStatsEndTime}
        data={dashboardStats?.stats || {}}
        dataChanges={dashboardStats?.changes || {}}
        hideScope3={hideScope3}
      />

      <Row firstEl={102}>
        <Box text="dashboard.carbon-footprint">
          <GraphComponent
            plotData={carbonFootprint?.value}
            error={!!carbonFootprint?.error_msg}
            errorType={carbonFootprint?.error_type}
            errorMsg={carbonFootprint?.error_msg}
            loading={isFiguresLoading}
          />
        </Box>
      </Row>

      <Row firstEl={102}>
        <Box text="dashboard.carbon-footprint">
          <TableChart data={carbonFootprintTables} loading={isFiguresLoading} />
        </Box>
      </Row>

      <Row firstEl={102}>
        <Box text="dashboard.total-emission">
          <GraphComponent
            plotData={totalEmissionsByScope?.value}
            error={!!totalEmissionsByScope?.error_msg}
            errorType={totalEmissionsByScope?.error_type}
            errorMsg={totalEmissionsByScope?.error_msg}
            loading={isFiguresLoading}
          />
        </Box>
      </Row>
      <Row firstEl={102}>
        <Box text="dashboard.total-emission">
          <TableChart
            data={totalEmissionsByScopeTables}
            loading={isFiguresLoading}
          />
        </Box>
      </Row>
      {!hideScope3 && (
        <>
          <Row firstEl={102}>
            <Box text="dashboard.emission-ranking">
              <GraphComponent
                plotData={SAEmissionRankingScope3?.value}
                error={!!SAEmissionRankingScope3?.error_msg}
                errorType={SAEmissionRankingScope3?.error_type}
                errorMsg={SAEmissionRankingScope3?.error_msg}
                loading={isFiguresLoading}
              />
            </Box>
          </Row>

          <Row firstEl={50} secondEl={50}>
            <Box text="dashboard.means">
              <GraphComponent
                plotData={employeesMeansOfTransport?.value}
                error={!!employeesMeansOfTransport?.error_msg}
                errorType={employeesMeansOfTransport?.error_type}
                errorMsg={employeesMeansOfTransport?.error_msg}
                loading={isFiguresLoading}
              />
            </Box>

            <Box text="dashboard.employee-emissions">
              <GraphComponent
                plotData={employeesStats?.value}
                error={!!employeesStats?.error_msg}
                errorType={employeesStats?.error_type}
                errorMsg={employeesStats?.error_msg}
                loading={isFiguresLoading}
              />
            </Box>
          </Row>

          <Row firstEl={102}>
            <Box text="dashboard.business-travel">
              <GraphComponent
                plotData={businessTravelEmissions?.value}
                error={!!businessTravelEmissions?.error_msg}
                errorType={businessTravelEmissions?.error_type}
                errorMsg={businessTravelEmissions?.error_msg}
                loading={isFiguresLoading}
              />
            </Box>
          </Row>

          <Row firstEl={102}>
            <Box text="dashboard.goods-and-services">
              <GraphComponent
                plotData={goodsAndServicesEmissions?.value}
                error={!!goodsAndServicesEmissions?.error_msg}
                errorType={goodsAndServicesEmissions?.error_type}
                errorMsg={goodsAndServicesEmissions?.error_msg}
                loading={isFiguresLoading}
              />
            </Box>
          </Row>
        </>
      )}
    </div>
  );
}
