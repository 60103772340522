import { Typography } from '@mui/material';
import InfoPanel from '../components/InfoPanel';
import React from 'react';

export default function ReportInfoPanel() {
  return (
    <InfoPanel>
      <Typography>
        CBAM Report contains information about Goods imported during specified
        quarter, along with their Emissions. Completed report can be submitted
        to CBAM Registry. <br />
        If during given quarter no goods were imported, there is no need to fill
        and submit report.
      </Typography>
    </InfoPanel>
  );
}
