import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const StyledContainer = styled(Box)`
  width: 844px;
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #b6bbb9;
  padding: 50px 22px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: 25px;
  margin-bottom: 100px;
`;

export const StyledButtonBox = styled(Box)`
  display: flex;
  font-weight: Bold;
  margin-top: 20px;
`;

export const StyledButton = styled(Button)`
  text-transform: none;
`;
