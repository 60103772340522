import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteCategories,
  AutocompleteFreesolo,
  Select,
} from '../../fields';
import { Form, FormBox, Box, Button } from '../index.styles';
import TextFieldNumber from '../../../../../components/TextFieldNumber';
import { choices, units } from '../data';
import { Overlay } from '../../Title/index.styles';
import { fuel_or_feedstock_init } from '../../../../Pages/Questionnaire/fuels-and-raw-materials';

export default function TotalAmountForm({
  state,
  setState,
  active,
  setActive,
}: {
  state: {
    name: string;
    type_of_fuel: string;
    unit: string;
    amount: string;
    manual_factor: string;
    comment: string;
  };
  setState: Function;
  active: boolean;
  setActive: Function;
}) {
  const { t } = useTranslation();

  const [type_of_fuel_error_state, set_type_of_fuel_error_state] =
    useState(false);
  const [unit_error_state, set_unit_error_state] = useState(false);
  const [amount_error_state, set_amount_error_state] = useState(false);
  const [manual_factor_error_state, set_manual_factor_error_state] =
    useState(false);

  let type_of_fuel_error = false;
  let unit_error = false;
  let amount_error = false;
  let manual_factor_error = false;

  const type_of_fuel_validation = (value: string) => {
    if (value === '') type_of_fuel_error = true;
    return type_of_fuel_error;
  };
  const unit_validation = (value: string) => {
    if (value === '') unit_error = true;
    return unit_error;
  };
  const amount_validation = (value: string) => {
    if (value === '') amount_error = true;
    return amount_error;
  };
  const manual_factor_validation = (value: string) => {
    if (value === '') manual_factor_error = true;
    return manual_factor_error;
  };

  const handleName = (value: string) => {
    const tempState = {
      name: value,
      type_of_fuel: state.type_of_fuel,
      unit: state.unit,
      amount: state.amount,
      manual_factor: state.manual_factor,
      comment: state.comment,
    };

    setState(tempState);
  };

  const handleAmount = (value: string) => {
    amount_validation(value);
    set_amount_error_state(false);
    const tempState = {
      name: state.name,
      type_of_fuel: state.type_of_fuel,
      unit: state.unit,
      amount: value,
      manual_factor: state.manual_factor,
      comment: state.comment,
    };

    setState(tempState);
    if (value === '') set_amount_error_state(true);
  };

  const handleUnit = (value: string) => {
    unit_validation(value);
    set_unit_error_state(false);
    const tempState = {
      name: state.name,
      type_of_fuel: state.type_of_fuel,
      unit: value,
      amount: state.amount,
      manual_factor: state.manual_factor,
      comment: state.comment,
    };

    setState(tempState);
    if (value === '') set_unit_error_state(true);
  };

  const handleTypeOfFuel = (value: string) => {
    type_of_fuel_validation(value);
    set_type_of_fuel_error_state(false);
    const tempState = {
      name: state.name,
      type_of_fuel: value,
      unit: state.unit,
      amount: state.amount,
      manual_factor: state.manual_factor,
      comment: state.comment,
    };

    setState(tempState);
    if (value === '') set_type_of_fuel_error_state(true);
  };

  const handleManualFactor = (value: string) => {
    manual_factor_validation(value);
    set_manual_factor_error_state(false);
    const tempState = {
      name: state.name,
      type_of_fuel: state.type_of_fuel,
      unit: state.unit,
      amount: state.amount,
      manual_factor: value,
      comment: state.comment,
    };

    setState(tempState);
    if (value === '' && state.type_of_fuel === 'other.manual_factor')
      set_manual_factor_error_state(true);
  };

  const defineErrors = async () => {
    if (state.type_of_fuel === '') {
      set_type_of_fuel_error_state(true);
      type_of_fuel_error = true;
    }

    if (state.amount === '') {
      set_amount_error_state(true);
      amount_error = true;
    }

    if (state.unit === '') {
      set_unit_error_state(true);
      unit_error = true;
    }

    if (state.type_of_fuel === 'other.manual_factor' && !state.manual_factor) {
      set_manual_factor_error_state(true);
      manual_factor_error = true;
    }
  };

  return (
    <>
      {active && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              if (
                !type_of_fuel_error &&
                !amount_error &&
                !unit_error &&
                !manual_factor_error
              ) {
                setActive(false);
              }
            })
          }
        />
      )}
      <Form active={active}>
        <div onClick={() => setActive(true)}>
          <FormBox variant="100">
            <AutocompleteFreesolo
              active
              error={false}
              index={1}
              label={t('sold-products.fuels-and-raw-materials.total.name')}
              value={state.name}
              setValue={(value: string) => handleName(value)}
              max={123}
              options={[]}
            />
          </FormBox>

          <FormBox variant="50">
            <Select
              active
              error={unit_error_state}
              index={1}
              label={t(`sold-products.fuels-and-raw-materials.total.unit`)}
              value={state.unit}
              setValue={(value: string) => handleUnit(value)}
              max={123}
              options={units}
            />
          </FormBox>

          <FormBox variant="50">
            <AutocompleteCategories
              active
              index={1}
              max={12}
              // @ts-ignore
              options={choices}
              label={t(
                `sold-products.fuels-and-raw-materials.total.type_of_fuel`
              )}
              error={type_of_fuel_error_state}
              value={state.type_of_fuel}
              setValue={(e: string) => handleTypeOfFuel(e)}
            />
          </FormBox>

          <FormBox variant="100">
            <TextFieldNumber
              label={t('sold-products.fuels-and-raw-materials.total.amount')}
              active
              error={amount_error_state}
              index={1}
              value={state.amount}
              setValue={(e: string) => handleAmount(e)}
            />
          </FormBox>
          {state.type_of_fuel === 'other.manual_factor' && (
            <FormBox variant="100">
              <TextFieldNumber
                type="factor"
                label={t(
                  'sold-products.fuels-and-raw-materials.total.manual_factor'
                )}
                active
                error={manual_factor_error_state}
                index={1}
                value={state.manual_factor}
                setValue={(e: string) => handleManualFactor(e)}
              />
            </FormBox>
          )}
        </div>
        <Box>
          <Button
            type="button"
            onClick={() => {
              setState(fuel_or_feedstock_init);
              set_type_of_fuel_error_state(false);
              type_of_fuel_error = false;
              set_amount_error_state(false);
              amount_error = false;
              set_unit_error_state(false);
              unit_error = false;
              set_manual_factor_error_state(false);
              manual_factor_error = false;

              setActive(false);
            }}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 31C18 32.1 18.9 33 20 33H28C29.1 33 30 32.1 30 31V19H18V31ZM20 21H28V31H20V21ZM27.5 16L26.5 15H21.5L20.5 16H17V18H31V16H27.5Z"
                fill="#606462"
              />
              <rect x="22" y="23" width="1" height="6" fill="#606462" />
              <rect x="25" y="23" width="1" height="6" fill="#606462" />
            </svg>
          </Button>
        </Box>
      </Form>
    </>
  );
}
