import React from 'react';
import Dialog from '@mui/material/Dialog';
import { styled as styledMUI } from '@mui/material/styles';
import CloseIcon from '../../../../../Pages/ESG/create/closeIcon';
import {
  Close,
  ModalSubTitle,
  ModalTitle,
  ButtonsWrapper,
  AddButton,
  CloseButton,
} from '../../../../../Pages/ESG/create/index.styles';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: Function;
  mutation: Function;
}

const StyledDialog = styledMUI(Dialog)`
  & .MuiPaper-root {
    background: #FFFFFF;
    border: 1px solid #D9DFDD;
    border-radius: 16px;
    padding: 24px;  
  }`;

export default function RemoveModal({ open, onClose, mutation }: Props) {
  const { t } = useTranslation();
  return (
    <StyledDialog open={open} onClose={() => onClose()}>
      <Close type="button" onClick={() => onClose()}>
        <CloseIcon />
      </Close>
      <ModalTitle>{t('esg.remove_modal.title')}</ModalTitle>
      <ModalSubTitle>{t('esg.remove_modal.subtitle')}</ModalSubTitle>
      <ButtonsWrapper>
        <AddButton type="button" onClick={() => onClose()}>
          {t('esg.remove_modal.buttons.cancel')}
        </AddButton>
        <CloseButton
          type="button"
          onClick={() => {
            mutation();
            onClose();
          }}
        >
          {t('esg.remove_modal.buttons.remove')}
        </CloseButton>
      </ButtonsWrapper>
    </StyledDialog>
  );
}
