import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useProductionProcesses } from '../hooks';
import { StyledFlex } from '../../components/StyledFlex';
import { Box, IconButton, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import GeneralInformation from './Form/GeneralInformation';
import {
  CounterProvider,
  NavigationBlockerWithCounter,
} from './Form/components/CounterProvider';
import ProductionProcessInfoPanel from './Form/components/ProductionProcessInfoPanel';

export default function ProductionProcessCreate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const productionProcesses = useProductionProcesses();
  const productionProcess = productionProcesses.data.find(
    (p) => p.id === Number(id)
  );

  return (
    <CounterProvider>
      <NavigationBlockerWithCounter />
      <Box>
        <StyledFlex sx={{ justifyContent: 'flex-start' }}>
          <IconButton onClick={() => navigate('..')}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h2">
            Production Process: {productionProcess?.name || 'New'}
          </Typography>
        </StyledFlex>
        <br />
        <Box sx={{ mb: '12px' }}>
          <ProductionProcessInfoPanel />
        </Box>
        <GeneralInformation />
        <br />
        <br />
      </Box>
    </CounterProvider>
  );
}
