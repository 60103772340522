import {
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import React from 'react';
import { EsgReportSchemaOut } from 'src/Esg/api.types';
import { useIndicatorsResponsibilites } from 'src/Esg/hooks';
import { IndicatorSchema } from 'src/Esg/types';
import { useOrganizationMembers } from 'src/Superadmin/hooks';

interface Props extends Omit<SelectProps, 'onChange'> {
  report: EsgReportSchemaOut;
  indicator: IndicatorSchema;
  // eslint-disable-next-line no-unused-vars
  onChange: (appUserId: number) => void;
}

export default function ResponsilityUserSelect(props: Props) {
  const { onChange, report, indicator } = props;

  const { organizationMembers } = useOrganizationMembers();
  const responsibilities = useIndicatorsResponsibilites();

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    const appUserId = parseInt(value as string, 10);
    if (Number.isFinite(appUserId)) onChange(appUserId);
  };

  // Exclude users that are already responsible for the indicator
  const excludeUsers = responsibilities.data
    .filter(
      (responsibility) =>
        responsibility.report_id === report.id &&
        responsibility.esg_questionnaire_id === indicator.id
    )
    .map((responsibility) => responsibility.app_user_id);

  return (
    <Select
      size="small"
      fullWidth
      {...props}
      value={props.value || ''}
      onChange={handleChange}
      sx={{ maxWidth: '300px' }}
    >
      {organizationMembers.map((member) => (
        <MenuItem
          key={member.id}
          value={member.id}
          disabled={excludeUsers.includes(member.id)}
        >
          {member.email}
        </MenuItem>
      ))}
    </Select>
  );
}
