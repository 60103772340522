import { styled as styledMUI } from '@mui/material/styles';

export const StyledColumns = styledMUI('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const StyledGate = styledMUI('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
