import React from 'react';
import { Document } from '@react-pdf/renderer';

import CoverPage from './Pages/Cover';
import YesOrNoTable from './Tables/YesOrNoTable';
import MultiAnswerTable from './Tables/MultiAnswerTable';
import BasePage from './Pages/Base';
import { EsgSummary, RowType } from '../../../components/ESGSummary/types';
import { categorizeData } from '../../../components/ESGSummary/Tables/utils';
import QuestionnaireAttachmentsTable from './Tables/QuestionnaireAttachmentsTable';

type Props = {
  esgSummary: EsgSummary;
};

type Questionnaire = {
  [key: string]: string;
};

export default function _Document({ esgSummary }: Props) {
  const getIndicatorPages = () => {
    const indicatorPages: React.ReactElement[] = [];
    const [yesOrNoData, multianswerData, attachmentsData] = categorizeData(
      esgSummary.data
    );
    const uniqueQuestionnaires: Questionnaire[] = esgSummary.data.reduce(
      (keys: Questionnaire[], item: RowType) => {
        if (
          !keys.some(
            (keyObject) => keyObject.questionnaireKey === item.questionnaire_key
          )
        ) {
          keys.push({
            questionnaireKey: item.questionnaire_key,
            questionnaireId: item.questionnaire_id,
            questionnaireTitle: item.questionnaire_title,
          });
        }
        return keys;
      },
      []
    );

    for (const questionnaire of uniqueQuestionnaires) {
      const { questionnaireId, questionnaireKey, questionnaireTitle } =
        questionnaire;

      // select data for this questionnaire only
      const [yesOrNoQuestions, multianswerQuestions, attachmentsQuestions] = [
        yesOrNoData,
        multianswerData,
        attachmentsData,
      ].map((rows) =>
        rows.filter((q) => q.questionnaire_id === questionnaireId)
      );

      if (!!yesOrNoQuestions.length || !!multianswerQuestions.length)
        indicatorPages.push(
          <BasePage key={questionnaireKey} title={questionnaireTitle}>
            {!!yesOrNoQuestions.length && (
              <YesOrNoTable
                questions={yesOrNoQuestions}
                questionnaireKey={questionnaireKey}
              />
            )}
            {!!multianswerQuestions.length && (
              <MultiAnswerTable
                questions={multianswerQuestions}
                questionnaireKey={questionnaireKey}
              />
            )}
            {!!attachmentsQuestions.length && (
              <QuestionnaireAttachmentsTable questions={attachmentsQuestions} />
            )}
          </BasePage>
        );
    }
    return indicatorPages;
  };

  return (
    <Document>
      {[
        <CoverPage esgSummary={esgSummary} key="esg-pdf-cover-page" />,
        ...getIndicatorPages(),
      ]}
    </Document>
  );
}
