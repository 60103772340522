import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { RowType } from '../../../../components/ESGSummary/types';
import { MARGIN_LEFT, PDF_WIDTH } from '../config';
import SVGCheckbox from '../../components/SVGCheckbox';
import { DetailField } from '../Tables/Fields';
import { useTranslation } from 'react-i18next';

interface Props {
  questions: RowType[];
  questionnaireKey: string;
}

export default function YesOrNoTable(props: Props) {
  const { questions, questionnaireKey } = props;
  const { t } = useTranslation();
  return (
    <View
      style={{
        width: PDF_WIDTH - 2 * MARGIN_LEFT,
        fontSize: 40,
        border: '5px solid #ccc',
        borderRadius: '20px',
        backgroundColor: '#fff',
        marginBottom: '50px',
        fontFamily: 'Poppins',
        color: '#5A636F',
      }}
    >
      {/* columns */}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '35px',
          color: 'grey',
          fontStyle: 'bold',
          textTransform: 'uppercase',
          borderBottom: '2px solid grey',
        }}
      >
        <View style={{ width: '15%' }}>
          <Text>{t('esgSummary.tables.no-lp')}</Text>
        </View>
        <View style={{ width: '65%' }}>
          <Text>{t('esgSummary.tables.question')}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text>{t('esgSummary.tables.yes')}</Text>
        </View>
        <View style={{ width: '10%' }}>
          <Text>{t('esgSummary.tables.no')}</Text>
        </View>
      </View>

      {/* rows */}
      {questions?.map((question) => {
        const { fields } = question;
        const value = Object.values(fields[0]?.answer)[0];
        const isYes = !!value;
        const detailFields = [...fields].splice(1);

        // skipping unanswered questions
        if (![true, false].includes(value)) return <View />;

        return (
          <View
            key={question.question_id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              padding: '35px',
            }}
          >
            <View style={{ width: '15%' }}>
              <Text>{questionnaireKey}</Text>
            </View>
            <View style={{ width: '65%', paddingRight: '50px' }}>
              <Text>{question.question_title}</Text>

              <View style={{ marginTop: '10px' }}>
                {detailFields.map((field) => (
                  <DetailField key={field.id} field={field} />
                ))}
              </View>
            </View>
            <View style={{ width: '10%' }}>{!!isYes && <SVGCheckbox />}</View>
            <View style={{ width: '10%' }}>{!isYes && <SVGCheckbox />}</View>
          </View>
        );
      })}
    </View>
  );
}
