import React from 'react';
import { Desc, Title } from './index.styles';

import { useTranslation } from 'react-i18next';
import BoundariesLCA from './BoundariesContent';

interface Props {
  values: any;
  setValues: Function;
}

export default function Boundaries({ setValues, values }: Props) {
  const { t } = useTranslation();
  return (
    <div>
      <Title>{t('lca.boundaries.title')}</Title>
      <Desc dangerouslySetInnerHTML={{ __html: t('lca.boundaries.desc') }} />
      <BoundariesLCA values={values} setValues={setValues} />
    </div>
  );
}
