import { RowQuestionField, RowType } from '../types';

const isYesOrNoQuestion = (field: RowQuestionField) =>
  field?.visual_type === 'toggle-button-group' && field?.type === 'boolean';

const isAttachment = (field: RowQuestionField) =>
  field?.type === 'file' || field?.type === 'multifile';

// categorizes data on yesOrNoData and multianswerData
// based on field.type and field.visual_type
// eslint-disable-next-line
export const categorizeData = (data: RowType[]) => {
  const yesOrNoData = [];
  const multianswerData = [];
  const attachmentsData = [];

  for (const row of data) {
    if (isYesOrNoQuestion(row.fields[0])) yesOrNoData.push(row);
    else if (isAttachment(row.fields[0])) attachmentsData.push(row);
    else multianswerData.push(row);
  }
  return [yesOrNoData, multianswerData, attachmentsData];
};
