import { styled as styledMUI } from '@mui/material/styles';
import { DialogContent } from '@mui/material';

export const StyledDialogContent = styledMUI(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  width: 600,
  height: 500,
  alignItems: 'center',
  justifyContent: 'center',
  '& >div': {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
