import styled from 'styled-components';

export const LegendWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  cursor: not-allowed;
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
  width: 250px;
`;

export const ColorBox = styled.div<{ color: string }>`
  height 12px;
  min-width: 12px;
  background-color: ${({ color }) => color};
  margin-right: 12px;
`;
