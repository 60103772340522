import React from 'react';
import { Indicator, Question } from '../types';
import TableCollapsable from './TableCollapsable';
import { TableRow, Typography } from '@mui/material';
import { StyledTableCell, StyledTableStickyCell } from '../styles';
import { useEsgUserPermissions } from '../hooks';
import PermissionSelect from './PermissionSelect';
import { useTranslation } from 'react-i18next';

interface Props {
  isExpanded: boolean;
  indicator: Indicator & { questions: Question[] };
  userId: number;
  year: number;
}
export default function QuestionCollapse(props: Props) {
  const { indicator, userId, year, isExpanded } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'superadmin.settings.tabs.users.usersTable.permissions',
  });
  const { options, userOrganizations } = useEsgUserPermissions(userId);
  const reports = options.reports.filter((report) => report.period === year);

  return (
    <TableCollapsable isExpanded={isExpanded}>
      {indicator.questions.map((question) => {
        const key = question.id;
        return (
          <TableRow key={key}>
            <StyledTableStickyCell>
              <Typography sx={{ pl: '50px' }}>{question.title}</Typography>
            </StyledTableStickyCell>
            {userOrganizations.map((organization) => {
              const report = reports.find(
                (r) => r.esg_organization_id === organization.id
              );
              if (!report)
                return (
                  <StyledTableCell key={key + organization.id}>
                    <Typography>{t('other.noReport')}</Typography>
                  </StyledTableCell>
                );
              return (
                <StyledTableCell key={key + organization.id} sx={{ p: '8px' }}>
                  <PermissionSelect
                    userId={userId}
                    organizationId={organization.id}
                    questionId={question.id}
                    indicatorId={indicator.questionnaire_id}
                    indicatorCategoryId={indicator.esg_indicator_category_id}
                    reportId={report.id}
                  />
                </StyledTableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableCollapsable>
  );
}
