import styled from 'styled-components';

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 10fr 10fr 10fr;
  width: 100%;
  height: 42px;
`;

export const GridHeader = styled.div`
  display: grid;
  height: 64px;
  grid-template-columns: 3fr 10fr 10fr 10fr;
  width: 100%;
`;

export const GridItem = styled.div`
  border: 1px solid #b6bbb9;
  border-left: none;
  border-right: none;
  text-align: center;
  font-size: 11px;
  font-family: 'Poppins';
  font-weight: 600;
  line-height: 16px;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const StyledText = styled.div`
  color: #38414f;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 153.846% */
  letter-spacing: 0.26px;
`;

export const StyledTitle = styled.div`
  color: #38414f;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 170% */
  letter-spacing: 0.4px;
`;

export const FunctionalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px 0 0 0;
  align-items: center;
`;

export const SubTitleText = styled.div`
  color: #38414f;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.26px;
`;
