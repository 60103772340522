import { Button, Typography } from '@mui/material';
import React from 'react';
import { useMeasurementBased } from 'src/Cbam/hooks';
import MeasurementBased from './MeasurementBased';
import InfoPanel from 'src/Cbam/components/InfoPanel';

interface Props {
  id: number;
}
export default function MeasurementsBased(props: Props) {
  const { id } = props;
  const measurementsBased = useMeasurementBased(Number(id));
  const [isNewVisible, setIsNewVisible] = React.useState(false);
  return (
    <>
      <Typography variant="h3">Measurement based method (B.6)</Typography>
      <br />
      <InfoPanel>
        <Typography>
          A measurement-based methodology requires the use of a Continuous
          Emission Measurement System (CEMS) installed at a suitable measurement
          point.
          <br />
          For the monitoring of N2O emissions, the use of the measurement-based
          methodology, is mandatory. For CO2 it shall be used only if there is
          evidence that it leads to more accurate data than the
          calculation-based methodology.
          <br />
          CO emitted to the atmosphere shall be treated as the molar equivalent
          amount of CO2.
        </Typography>
      </InfoPanel>
      {!measurementsBased.data.length && !isNewVisible && (
        <Typography sx={{ m: '20px 0px' }}>
          No measurement based data
        </Typography>
      )}
      {measurementsBased.data.map((massBalance) => (
        <MeasurementBased
          key={massBalance.id}
          productionProcessId={id}
          id={massBalance.id}
        />
      ))}

      {!isNewVisible ? (
        <Button sx={{ mt: '20px' }} onClick={() => setIsNewVisible(true)}>
          Add measurement based data
        </Button>
      ) : (
        <MeasurementBased
          productionProcessId={id}
          onSaved={() => setIsNewVisible(false)}
        />
      )}
    </>
  );
}
