import React from 'react';
import { Title, Subtitle } from './index.styles';
import { BackIcon } from '../../../Pages/ESG/forms/icons';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';

export default function QuestionnaireTitle({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  const nav = useNavigate();
  return (
    <div style={{ marginBottom: 44 }}>
      <div
        style={{
          display: 'flex',
          gap: 8,
          alignItems: 'center',
          marginBottom: 32,
        }}
      >
        <IconButton type="button" onClick={() => nav(-1)}>
          <BackIcon />
        </IconButton>
        <Title>{title}</Title>
      </div>
      <Subtitle>{subtitle}</Subtitle>
    </div>
  );
}
