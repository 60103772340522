import { useTranslation } from 'react-i18next';
import { useAppSelector, usePlatform } from '../redux-file/hooks';
import { RootState } from '../redux-file/store';
import {
  OrganizationDataOutSchema,
  ProductPlatform,
} from '../utils/api.interfaces';
import { RequiredStateError } from './exceptions';
import { useQuery } from 'react-query';
import { Country, IsoCountry } from './types';
import { client } from 'src/utils/api-client';
import pl from 'date-fns/locale/pl';
import en from 'date-fns/locale/en-US';

export const useRequiredSelector = <TProp>(
  // eslint-disable-next-line no-unused-vars
  selector: (state: RootState) => TProp | undefined
) =>
  useAppSelector((state) => {
    const result = selector(state);
    if (result === undefined) {
      throw new RequiredStateError();
    }
    return result;
  });

function assertPropertyRequired<T, K extends keyof T>(
  obj: T,
  key: K
): asserts obj is T & Record<K, NonNullable<T[K]>> {
  if (obj[key] === undefined || obj[key] === null) {
    throw new RequiredStateError();
  }
}

export const useOrganization = () => {
  const { activeOrganization } = usePlatform();
  if (activeOrganization === null) throw new RequiredStateError();
  return activeOrganization;
};

export const useProductOrganization = <T extends ProductPlatform>(
  product: T
): OrganizationDataOutSchema &
  Record<T, NonNullable<OrganizationDataOutSchema[T]>> => {
  const organization = useOrganization();
  assertPropertyRequired(organization, product);
  return organization;
};

export const useGhgOrganization = () =>
  useProductOrganization(ProductPlatform.Co2);
export const useEsgOrganization = () =>
  useProductOrganization(ProductPlatform.Esg);
export const useLcaOrganization = () =>
  useProductOrganization(ProductPlatform.Lca);

const getLocale = (language: string) => {
  switch (language) {
    case 'pl':
      return pl;
    case 'en':
      return en;
    default:
      return en;
  }
};

export type UseLanguageOptions = {
  keyPrefix?: string;
};

export const useLanguage = (options?: UseLanguageOptions) => {
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: options?.keyPrefix,
  });
  const locale = getLocale(i18n.language);
  return {
    t,
    i18n,
    language: i18n.language,
    setLanguage: i18n.changeLanguage,
    locale,
  };
};

export const useContactUsPrompt = () => {
  return {
    navigateMailTo: () => window.open('mailto:contact@envirly.com', '_blank'),
  };
};

export const useSupportedCountries = () => {
  const { language } = useLanguage();
  const query = useQuery<Country[]>(['supportedCountries', { language }], () =>
    client
      .get('web/countries/supported-countries')
      .then((res) => res?.data || [])
  );
  return { ...query, data: query.data || [] };
};

export const useAllCountries = () => {
  const { language } = useLanguage();
  const query = useQuery<IsoCountry[]>(['allCountries', { language }], () =>
    client.get('web/countries/all').then((res) => res?.data || [])
  );
  return { ...query, data: query.data || [] };
};

export const useEUCountries = () => {
  const { language } = useLanguage();
  const query = useQuery<IsoCountry[]>(['EuCountries', { language }], () =>
    client.get('web/countries/eu').then((res) => res?.data || [])
  );
  return { ...query, data: query.data || [] };
};

export const useNonEUCountries = () => {
  const { language } = useLanguage();
  const query = useQuery<IsoCountry[]>(['NonEuCountries', { language }], () =>
    client.get('web/countries/non-eu').then((res) => res?.data || [])
  );
  return { ...query, data: query.data || [] };
};
