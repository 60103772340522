import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const Title = styled(Typography)`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
`;

export const Content = styled(Typography)`
  font-size: '16px';
  line-height: 26px;
  color: #606462;
  text-align: center;
`;
