import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

interface Props {
  maxDigits?: number;
  decimalPlaces?: number;
}
// This is basic number field - uses TextField from MUI, but it has some additional features:
// - it only allows numbers, dots and commas (will block any other text characters)
// - it removes leading zeros
// - it sets the value to 0 when the input is cleared
// - it sets the step to 0.00001
// - it sets the pattern to allow only numbers with up to 10 decimal places
export default function NumberField(props: TextFieldProps & Props) {
  const { maxDigits = 10, decimalPlaces = 5 } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { validity } = event.target;
    if (validity.valueMissing) {
      event.target.value = '0';
    }
    const isDecimal = event.target.value.includes('.');
    // Do not update the value if it is not valid
    if (!validity.valid) return;
    // when clearing the input, set the value to 0 - this is required for MUI reasons
    // (otherwise the input could be potentially filled with text without calling onChange)
    if (!isDecimal) {
      if (validity.valid && !event.target.valueAsNumber)
        event.target.value = '0';
      // remove leading zeros, when typing a number
      event.target.value = event.target.value.replace(/^0+/, '') || '0';
    }
    // call the onChange function
    if (validity.valid && props.onChange) props.onChange(event);
  };
  return (
    <TextField
      {...props}
      type="number"
      inputProps={{
        max: 10 ** maxDigits,
        step: 0.00001,
        pattern: `^\\d+(\\.\\d{0,${decimalPlaces}})?$`,
      }}
      value={props.value ?? ''}
      onChange={handleChange}
      // this prevents from value change on scroll (safari)
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
    />
  );
}
