import React from 'react';

import { styled } from '@mui/material/styles';
import { IconButton, IconButtonProps } from '@mui/material';

export interface Props extends IconButtonProps {
  expand: boolean;
}

const ExpandMoreButton = styled((props: Props) => {
  // eslint-disable-next-line no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default ExpandMoreButton;
