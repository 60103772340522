import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledFlex } from 'src/components/StyledFlex';
import { StyledPanel } from 'src/components/StyledPanel';
import { useInstallations } from '../hooks';
import { useTranslation } from 'react-i18next';
import InstallationInfoPanel from './InstallationInfoPanel';

export default function Installations() {
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.installations',
  });
  const installations = useInstallations();
  return (
    <Box>
      <StyledFlex>
        <Typography variant="h1">{t('title')}</Typography>
        <Button onClick={() => navigate('create')}>{t('add')}</Button>
      </StyledFlex>
      <br />
      <br />
      <InstallationInfoPanel />
      <br />
      <br />
      <StyledPanel>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('installation')}</TableCell>
              <TableCell>{t('identifier')}</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {installations.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.identifier}</TableCell>
                <TableCell sx={{ width: '50px', pl: 0, pr: 0 }}>
                  <IconButton onClick={() => navigate(String(row.id))}>
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell sx={{ width: '50px', pl: 0, pr: 0 }}>
                  <IconButton
                    onClick={() => installations.delete.mutate(row.id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledPanel>
    </Box>
  );
}
