import React from 'react';

export const Icon = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.998698 10.6667C0.998698 11.4 1.5987 12 2.33203 12H7.66536C8.3987 12 8.9987 11.4 8.9987 10.6667V2.66667H0.998698V10.6667ZM2.33203 4H7.66536V10.6667H2.33203V4ZM7.33203 0.666667L6.66536 0H3.33203L2.66536 0.666667H0.332031V2H9.66536V0.666667H7.33203Z"
      fill="#888888"
    />
    <rect x="3.66406" y="5.3335" width="0.666667" height="4" fill="#888888" />
    <rect x="5.66406" y="5.3335" width="0.666667" height="4" fill="#888888" />
  </svg>
);

export const SuccessIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#38414F" />
    <rect
      x="13.6758"
      y="21.8711"
      width="15.1579"
      height="5.05263"
      rx="2.52632"
      transform="rotate(45 13.6758 21.8711)"
      fill="white"
    />
    <rect
      x="35.1094"
      y="14.7246"
      width="5.05263"
      height="25.2632"
      rx="2.52632"
      transform="rotate(45 35.1094 14.7246)"
      fill="white"
    />
  </svg>
);
