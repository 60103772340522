import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImportedGoods } from '../hooks';
import { Box, IconButton, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { StyledFlex } from '../../components/StyledFlex';
import { useTranslation } from 'react-i18next';
import ImportedGoodForm from './Form';

export default function ImportedGood() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.importedGoods',
  });
  const reportId = location.state.reportId as string;
  const { data } = useImportedGoods(reportId);
  const good = data.find((good) => good.id === Number(id));
  return (
    <Box>
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <IconButton onClick={() => navigate(-1)}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="h2">
          {t('importedGood')}: {good?.name} ({good?.id || t('new')})
        </Typography>
      </StyledFlex>
      <br />
      <ImportedGoodForm
        id={Number(id)}
        reportId={reportId}
        onSaved={() => navigate(-1)}
      />
    </Box>
  );
}
