import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import { AutocompleteFreesolo, Select } from '../fields';
import { product_industry } from './data';
import { useAppDispatch, useAppSelector } from '../../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import { Overlay } from '../Title/index.styles';
import { CheckboxContainer, Form, FormBox } from './index.styles';
import {
  getAssetsFranchisesAndInvestmentsData,
  getAssetsFranchisesAndInvestmentsEmissionSharesData,
  putAssetsFranchisesAndInvestmentsData,
} from '../../../../redux-file/questionnaires/thunks';
import { timeConverter } from '../../../../utils/timeConverter';
import FormButtons from '../FormButtons';
import OneValueCheckboxField from '../../../../components/CheckboxField';

export default function InvestmentsForm({
  state,
  setState,
  id,
  active,
  setActive,
  combinedData,
  setNewForm,
}: {
  setNewForm: Function;
  state: {
    comment?: string | null;
    name: string;
    emission_amount_is_known: boolean;
    investment_data_factor_unknown: {
      investment_revenues_in_perdiod_pln: string;
      industry: string;
    };
    investment_data_factor_known: {
      amount_kg_eq_co2_in_period: string;
    };
  }[];
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  combinedData: any;
}) {
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector(
    (redux_state) => redux_state.questionnaire
  );
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;
  const reportingCurrency = organization.co2.reporting_currency;

  const { t } = useTranslation();

  const [
    investment_revenues_in_perdiod_pln_error_state,
    set_investment_revenues_in_perdiod_pln_error_state,
  ] = useState(false);
  let investment_revenues_in_perdiod_pln_error = false;
  const investment_revenues_in_perdiod_pln_validation = (value: string) => {
    if (value === '') investment_revenues_in_perdiod_pln_error = true;
    return investment_revenues_in_perdiod_pln_error;
  };

  const [industry_error_state, set_industry_error_state] = useState(false);
  let industry_error = false;
  const industry_validation = (value: string) => {
    if (value === '') industry_error = true;
    return industry_error;
  };

  const [
    amount_kg_eq_co2_in_period_error_state,
    set_amount_kg_eq_co2_in_period_error_state,
  ] = useState(false);
  let amount_kg_eq_co2_in_period_error = false;
  const amount_kg_eq_co2_in_period_validation = (value: string) => {
    if (value === '') amount_kg_eq_co2_in_period_error = true;
    return amount_kg_eq_co2_in_period_error;
  };

  const setKnowEmission = () => {
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: !tempForm.emission_amount_is_known,
      investment_data_factor_unknown: tempForm.emission_amount_is_known
        ? { investment_revenues_in_perdiod_pln: '', industry: '' }
        : tempForm.investment_data_factor_unknown,
      investment_data_factor_known: tempForm.emission_amount_is_known
        ? tempForm.investment_data_factor_known
        : {
            amount_kg_eq_co2_in_period: '',
          },
    };
    newForms[id] = tempForm;
    setState(newForms);
  };
  const handleName = (value: string) => {
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      ...tempForm,
      name: value,
    };
    newForms[id] = tempForm;
    setState(newForms);
  };

  const handleAmount = (value: string) => {
    amount_kg_eq_co2_in_period_validation(value);
    set_amount_kg_eq_co2_in_period_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: tempForm.emission_amount_is_known,
      investment_data_factor_known: {
        amount_kg_eq_co2_in_period: value,
      },
      investment_data_factor_unknown: tempForm.investment_data_factor_unknown,
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_amount_kg_eq_co2_in_period_error_state(true);
  };

  const handleRevenue = (value: string) => {
    investment_revenues_in_perdiod_pln_validation(value);
    set_investment_revenues_in_perdiod_pln_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: tempForm.emission_amount_is_known,
      investment_data_factor_known: tempForm.investment_data_factor_known,
      investment_data_factor_unknown: {
        investment_revenues_in_perdiod_pln: value,
        industry: tempForm.investment_data_factor_unknown.industry,
      },
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_investment_revenues_in_perdiod_pln_error_state(true);
  };

  const handleIndustry = (value: string) => {
    industry_validation(value);
    set_industry_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      name: tempForm.name,
      comment: tempForm.comment,
      emission_amount_is_known: tempForm.emission_amount_is_known,
      investment_data_factor_known: tempForm.investment_data_factor_known,
      investment_data_factor_unknown: {
        investment_revenues_in_perdiod_pln:
          tempForm.investment_data_factor_unknown
            .investment_revenues_in_perdiod_pln,
        industry: value,
      },
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_industry_error_state(true);
  };

  const handleSend = (data: any) => {
    if (
      !investment_revenues_in_perdiod_pln_error &&
      !industry_error &&
      !amount_kg_eq_co2_in_period_error
    ) {
      dispatch(
        putAssetsFranchisesAndInvestmentsData({
          period: timeConverter(selectedDate, reportingPeriod),
          data,
        })
      ).then(() => {
        dispatch(
          getAssetsFranchisesAndInvestmentsData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
        dispatch(
          getAssetsFranchisesAndInvestmentsEmissionSharesData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
      });
      setActive(undefined);
    }
  };

  const defineErrors = async () => {
    if (!state[id].emission_amount_is_known) {
      if (
        state[id].investment_data_factor_unknown
          .investment_revenues_in_perdiod_pln === ''
      ) {
        set_investment_revenues_in_perdiod_pln_error_state(true);
        investment_revenues_in_perdiod_pln_error = true;
      }
      if (state[id].investment_data_factor_unknown.industry === '') {
        set_industry_error_state(true);
        industry_error = true;
      }
    }
    if (state[id].emission_amount_is_known) {
      if (
        state[id].investment_data_factor_known.amount_kg_eq_co2_in_period === ''
      ) {
        set_amount_kg_eq_co2_in_period_error_state(true);
        amount_kg_eq_co2_in_period_error = true;
      }
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    const tempcombinedData = {
      upstream_leased_buildings: combinedData.upstream_leased_buildings,
      upstream_leased_assets: combinedData.upstream_leased_assets,
      downstream_leased_buildings: combinedData.downstream_leased_buildings,
      downstream_leased_assets: combinedData.downstream_leased_assets,
      franchises: combinedData.franchises,
      investments: tempState,
    };

    handleSend(tempcombinedData);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              handleSend(combinedData);
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <CheckboxContainer>
            <OneValueCheckboxField
              label={t('assets-and-other.investments.form.check')}
              value={state[id].emission_amount_is_known}
              setValue={() => setKnowEmission()}
            />
          </CheckboxContainer>
          {state[id].emission_amount_is_known ? (
            <>
              <FormBox variant="100">
                <AutocompleteFreesolo
                  active
                  index={1}
                  max={1}
                  options={[]}
                  label={t('assets-and-other.investments.form.name')}
                  error={false}
                  value={state[id].name}
                  setValue={(e: string) => handleName(e)}
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  type="factor"
                  label={t('assets-and-other.investments.form.amount')}
                  active
                  error={amount_kg_eq_co2_in_period_error_state}
                  index={1}
                  value={
                    state[id].investment_data_factor_known
                      .amount_kg_eq_co2_in_period
                  }
                  setValue={(e: string) => handleAmount(e)}
                />
              </FormBox>
            </>
          ) : (
            <>
              <FormBox variant="100">
                <AutocompleteFreesolo
                  active
                  index={1}
                  max={1}
                  options={[]}
                  label={t('assets-and-other.investments.form.name')}
                  error={false}
                  value={state[id].name}
                  setValue={(e: string) => handleName(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <TextFieldNumber
                  type="currency"
                  label={t('assets-and-other.investments.form.revenue', {
                    currency: reportingCurrency,
                  })}
                  active
                  error={investment_revenues_in_perdiod_pln_error_state}
                  index={1}
                  value={
                    state[id].investment_data_factor_unknown
                      .investment_revenues_in_perdiod_pln
                  }
                  setValue={(e: string) => handleRevenue(e)}
                />
              </FormBox>{' '}
              <FormBox variant="50">
                <Select
                  label={t('assets-and-other.investments.form.industry')}
                  // @ts-ignore
                  options={product_industry}
                  value={state[id].investment_data_factor_unknown.industry}
                  setValue={(e: string) => handleIndustry(e)}
                  error={industry_error_state}
                  active
                  index={1}
                  max={99}
                />
              </FormBox>
            </>
          )}
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={
              (!state[id].emission_amount_is_known &&
                state[id].investment_data_factor_unknown
                  .investment_revenues_in_perdiod_pln !== '' &&
                state[id].investment_data_factor_unknown.industry !== '') ||
              (state[id].emission_amount_is_known &&
                state[id].investment_data_factor_known
                  .amount_kg_eq_co2_in_period !== '')
            }
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
