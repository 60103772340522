import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const RegisterText = styled(Typography)`
  font-size: 18px;
  margin-bottom: 20px;
`;

export const RegisterQuestion = styled(Typography)`
  font-size: '18px';
  font-weight: 600;
  margin-bottom: 20px;
`;
