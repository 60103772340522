import { View, Text, Link, Svg, Circle, Path, Rect } from '@react-pdf/renderer';
import React from 'react';

import { ColumnsProps, DataProps, PDFTableProps } from './PDFTableTypes';
import SVGCheckbox from './SVGCheckbox';

const Columns = ({ columns = [] }: ColumnsProps) => {
  const style = { padding: '5px 10px', borderWidth: 1, borderColor: 'grey' };
  return (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        fontStyle: 'bold',
        fontWeight: 700,
      }}
    >
      {columns.map((column, idx) => (
        <View
          wrap={false}
          key={`${column.name}-${idx}`}
          style={{ ...style, ...column.style }}
        >
          <Text>{column.name}</Text>
        </View>
      ))}
    </View>
  );
};

const renderValue = (value: any) => {
  if (value === '::checkbox::') {
    return <SVGCheckbox />;
  }
  if (Array.isArray(value)) {
    return value.map((x, idx) => {
      return (
        <View style={{ flexDirection: 'row', width: ' 90%' }} key={idx}>
          <Text style={{ color: '#E9BC46', marginHorizontal: 8 }}>•</Text>
          <Text>{x}</Text>
        </View>
      );
    });
  }
  const regex = /([^<]+)(?:<a href='([^']+)'[^>]+>([^<]+)<\/a>)?/;
  const matches = value.match(regex);
  const question = !!matches && matches[1] ? matches[1].trim() : value;
  const questionHref = !!matches && matches[2] ? matches[2] : null;
  const questionUrlText = !!matches && matches[3] ? matches[3].trim() : null;
  const questionUrl = questionHref ? (
    <Link src={questionHref}>{questionUrlText}?</Link>
  ) : null;

  return (
    <View>
      <Text>
        {question} {questionUrl}
      </Text>
    </View>
  );
};

const Data = ({ data = [], columns = [] }: DataProps) => {
  return (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      {data.map((row, idx) =>
        columns.map((column, jdx) => {
          const cell = row[jdx];
          const cellStyle = { ...(cell?.style || {}) };

          const extraStyles = {} as any;
          const { borderLeftColor } = cellStyle;
          if (borderLeftColor) {
            extraStyles.borderLeft = `5px solid ${borderLeftColor}`;
            delete cellStyle.borderLeftColor;
          }

          return (
            <View
              key={`${column.name}-${idx}-${jdx}`}
              style={{
                border: '1px solid grey',
                padding: '5px 10px',
                backgroundColor: '#fff',
                width: column.style.width,
                ...extraStyles,
                ...cellStyle,
              }}
            >
              {renderValue(cell.value)}
            </View>
          );
        })
      )}
    </View>
  );
};

const PDFTable = ({ columns, data, containerStyle = {} }: PDFTableProps) => {
  const defaultContainerStyle = {
    fontSize: 20,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    width: 500,
  };
  const style = { ...defaultContainerStyle, ...containerStyle };
  return (
    <View style={style}>
      <Columns columns={columns} />
      <Data data={data} columns={columns} />;
    </View>
  );
};

export default PDFTable;
