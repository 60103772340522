import React from 'react';
import { toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
} from '@mui/material';
import { StyledSubtitle, StyledTitle } from './index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import { useSupplierSurvey } from '../../hooks';
import { GhgSupplierSurvey } from '../../../interfaces';
import { useTranslation } from 'react-i18next';
import { useGhgOrganization } from '../../../../common/hooks';

interface Props {
  open: boolean;
  onClose: () => void;
  invitation?: GhgSupplierSurvey | null;
}

export default function InviteSupplierModal(props: Props) {
  const { t } = useTranslation();
  const { open, onClose, invitation } = props;

  const _productName = invitation?.product_name || '';
  const _supplierName = invitation?.supplier_name || '';
  const _token = invitation?.access_token || '';

  const [productName, setProductName] = React.useState<string>(_productName);
  const [supplierName, setSupplierName] = React.useState<string>(_supplierName);
  const [token, setAccessToken] = React.useState<string>(_token);

  const _onClose = () => {
    setProductName('');
    setSupplierName('');
    setAccessToken('');
    onClose();
  };

  const handleProductNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setProductName(e.target.value);
  const handleSupplierNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSupplierName(e.target.value);

  const organization = useGhgOrganization();

  const { create } = useSupplierSurvey(organization.id);

  const invitationLink = !!token
    ? `${window.location.origin}/suppliers/${token}`
    : null;

  const postInivitationLink = () =>
    create
      .mutateAsync({
        product_name: productName,
        supplier_name: supplierName,
        organization_id: organization.id,
      })
      .then((res) => setAccessToken(res.data.token));

  const copyToClipboard = () => {
    navigator.clipboard.writeText(invitationLink as string);
    toast.success(t('ghg.scope3GoodsAndServices.toasts.linkCopied') as string);
    if (!invitation)
      toast.success(
        t('ghg.scope3GoodsAndServices.toasts.productRegistered') as string
      );
  };

  return (
    <Dialog open={open} onClose={_onClose} maxWidth="lg">
      <IconButton
        sx={{ position: 'absolute', right: '10px', top: '10px' }}
        onClick={_onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: '900px' }}>
        <StyledTitle>
          {t('ghg.scope3GoodsAndServices.invitationModal.title')}
        </StyledTitle>
        <StyledSubtitle>
          {t('ghg.scope3GoodsAndServices.invitationModal.subtitle')}
        </StyledSubtitle>

        <br />

        <TextField
          fullWidth
          value={productName}
          onChange={handleProductNameChange}
          label={t(
            'ghg.scope3GoodsAndServices.invitationModal.inputs.name.label'
          )}
          sx={{
            m: '10px 0px',
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
            },
          }}
          disabled={create.isLoading || !!invitation?.product_name}
          InputProps={{
            classes: {
              notchedOutline: 'Black MuiOutlinedInput-notchedOutline',
            },
          }}
          InputLabelProps={{
            style: { color: 'black' },
          }}
        />
        <TextField
          fullWidth
          value={supplierName}
          onChange={handleSupplierNameChange}
          label={t(
            'ghg.scope3GoodsAndServices.invitationModal.inputs.company.label'
          )}
          sx={{
            m: '20px 0px',
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
            },
          }}
          disabled={create.isLoading || !!invitation?.supplier_name}
          InputProps={{
            classes: {
              notchedOutline: 'Black MuiOutlinedInput-notchedOutline',
            },
          }}
          InputLabelProps={{
            style: { color: 'black' },
          }}
        />

        <br />

        <StyledFlex sx={{ m: '10px 0px' }}>
          <TextField
            fullWidth
            value={invitationLink}
            disabled
            placeholder={t(
              'ghg.scope3GoodsAndServices.invitationModal.inputs.link.placeholder'
            )}
            label={t(
              'ghg.scope3GoodsAndServices.invitationModal.inputs.link.label'
            )}
            sx={{
              mr: '10px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '25px',
              },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#000000',
              },
            }}
            InputLabelProps={{
              shrink: true,
              style: { color: 'black' },
            }}
            InputProps={{
              classes: {
                notchedOutline: 'Black MuiOutlinedInput-notchedOutline',
              },
            }}
          />
          <Button
            onClick={!!invitationLink ? copyToClipboard : postInivitationLink}
            sx={{ minWidth: '200px' }}
            variant="contained"
            color="primary"
            disabled={!productName || !supplierName || create.isLoading}
          >
            {!invitationLink
              ? t('ghg.scope3GoodsAndServices.invitationModal.buttons.generate')
              : t('ghg.scope3GoodsAndServices.invitationModal.buttons.copy')}
          </Button>
        </StyledFlex>
      </DialogContent>
    </Dialog>
  );
}
