import React, { useState } from 'react';
import { CloseButton } from '../../create/index.styles';
import StakeholdersModal from '../../../../../components/esg/stakeholdersModal';
import { InfoBox } from '../index.styles';
import { useTranslation } from 'react-i18next';
import StakeholdersTable from './table';
import { ToggleButtonGroup } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import StakeholdersCharts from './charts';
import { Arrow1, Arrow2 } from './images';

export interface StakeholdersData {
  name: string;
  create_date: string;
  stakeholders: string;
  categories: Array<string>;
  response_date: string | null;
  visited: boolean;
}

export const stakeholdersTypes = [
  'Client',
  'Employee',
  'Shareholder/Investor',
  'Supplier/Vendor',
  'Community',
  'Partner',
  'NGO',
  'Donor/Sponsor',
  'Board of Directors',
  'Academia/Researcher',
  'Environmental Group',
  'Financial Institution',
  'Government',
  'Local Authorities',
  'Media',
  'Professional Associations',
  'Regulatory Authorities',
  'Trade Union',
  'Legal Advisor',
];

// TODO: wsumie nie wiem czy to jest potrzebne i jak te kolory beda ogarniane, czy w ten czy jakis podobny sposob czy może beda przychodzić z backendu
export const esgCategoryColors = (category: string) => {
  switch (category) {
    case 'Climate Change (E1)':
      return '#A1DF76';
    case 'ESRS 2':
      return '#E9BC46';
    case 'Pollution (E2)':
      return '#CEDF9D';
    case 'Water and Marine Resources (E3)':
      return '#B6D9FC';
    case 'Biodiversity and Ecosystem (E4)':
      return '#A1E4C8';
    case 'Resources Use and Circular Economy (E5)':
      return '#97E3FB';
    case 'Own Workforce (S1)':
      return '#FAAD94';
    case 'Workers in the Value Chain (S2)':
      return '#F5CF96';
    case 'Affected Communities (S3)':
      return '#E4B78D';
    case 'Consumers and End-users (S4)':
      return '#FFC28B';
    case 'Business Conduct (G1)':
      return '#E0BFE3';
    case 'Social':
      return '#F2A084';
    case 'Environmental':
      return '#95D16A';
    case 'Governance':
      return '#CDABD6';
    case 'General':
      return '#FFC062';
    default:
      return '#A1DF76';
  }
};

export default function Stakeholders() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const esg_stakeholders = localStorage.getItem('esg_stakeholders');

  const [view, setView] = useState<'table' | 'charts'>('table');

  const handleCloseModal = () => setOpen(false);

  return (
    <>
      {esg_stakeholders !== 'true' && (
        <div>
          <InfoBox style={{ marginBottom: 24 }}>
            {t('esg.stakeholders_box.1')}
          </InfoBox>
          <InfoBox style={{ margin: '0 0 0 auto', width: 'fit-content' }}>
            {t('esg.stakeholders_box.2')}
          </InfoBox>
          <Arrow1 />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 24,
        }}
      >
        {/* TODO: Pasuje to ostylować, ale to raczej pasuje zrobić razem z nowymi komponentami ktore się tworzą */}
        <ToggleButtonGroup
          exclusive
          value={view}
          onChange={(_, newValue) => {
            if (newValue !== null) {
              setView(newValue);
            }
          }}
        >
          <ToggleButton value="table">Table</ToggleButton>
          <ToggleButton value="charts">Chart</ToggleButton>
        </ToggleButtonGroup>
        <CloseButton
          style={{ width: 'auto', padding: '0 24px' }}
          type="button"
          onClick={() => {
            setOpen(true);
            localStorage.setItem('esg_stakeholders', 'true');
          }}
        >
          {t('esg.stakeholders_new_form_modal.button')}
        </CloseButton>
      </div>
      {esg_stakeholders !== 'true' && (
        <div>
          <InfoBox style={{ margin: '0 auto', width: 'fit-content' }}>
            {t('esg.stakeholders_box.3')}
          </InfoBox>
          <Arrow2 />
        </div>
      )}
      {view === 'table' && <StakeholdersTable />}
      {view === 'charts' && <StakeholdersCharts />}
      <StakeholdersModal open={open} onClose={handleCloseModal} />
    </>
  );
}
