import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled('div')`
  width: 90%;
  margin: auto;
  margin-top: 44px;
  padding-bottom: 250px;
`;

export const StyledTitle = styled(Typography)`
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.64px;
  color: #38414f;
  display: flex;
  align-items: center;
`;

export const StyledPanel = styled(Box)`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 16px;
  padding: 24px;
`;
