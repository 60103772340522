import React, { ReactNode } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Typography } from '@mui/material';
import LightTooltip from 'src/components/LightTooltip';

export default function InfoModal({ children }: { children: ReactNode }) {
  return (
    <LightTooltip
      title={
        <Typography sx={{ fontSize: '14px', p: '10px' }}>{children}</Typography>
      }
    >
      <InfoIcon sx={{ cursor: 'pointer', color: '#388276' }} />
    </LightTooltip>
  );
}
