import React from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ShowMoreIcon from './icon';

interface Props {
  show: boolean;
  setShow: Function;
}

export default function ShowMoreButton({ setShow, show }: Props) {
  const { t } = useTranslation();
  return (
    <Button
      type="button"
      onClick={() => setShow(!show)}
      style={{ marginBottom: 24 }}
    >
      {t(show ? 'less-info' : 'more-info')}
      <ShowMoreIcon show={show} />
    </Button>
  );
}
