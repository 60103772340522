import React from 'react';
import CollectConsentsFormModal, {
  useConsentsQuery,
} from 'src/views/components/CollectConsentsFormModal';

interface Props {
  children: React.ReactNode;
}

export default function ConsentProvider(props: Props) {
  const { areConsentsValid } = useConsentsQuery();
  if (!areConsentsValid) {
    return <CollectConsentsFormModal />;
  }
  return <div>{props.children}</div>;
}
