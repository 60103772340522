import React from 'react';

export default function MenuIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 18.6182H16V16.5818H3V18.6182ZM3 13.5273H13V11.4909H3V13.5273ZM3 6.39999V8.43636H16V6.39999H3ZM21 16.1644L17.42 12.5091L21 8.85381L19.59 7.41818L14.59 12.5091L19.59 17.6L21 16.1644Z"
        fill="currentColor"
      />
    </svg>
  );
}
