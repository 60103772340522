import React from 'react';
import Logo from '../../Logo';
import companyIcon from '../../../../images/company.png';
import { CompanyBox, VerticalLine, Wrapper } from './index.styles';

export default function SurveyHeader({ companyName }: { companyName: string }) {
  return (
    <Wrapper>
      <Logo />
      <VerticalLine />
      <CompanyBox>
        <img src={companyIcon} alt="company logo" />
        <span>{companyName}</span>
      </CompanyBox>
    </Wrapper>
  );
}
