import React from 'react';
import { usePlatformNavigation } from 'src/utils/navigation';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import NestedOrganization from './NestedOrganization';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function OrganizationsTreeModal({ open, onClose }: Props) {
  const { t } = useTranslation();
  const { productOrganizations } = usePlatformNavigation();
  const orgIds = productOrganizations.map((org) => org.id);
  const topOrganizations = productOrganizations.filter(
    (org) => !orgIds.includes(org.parent_id)
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <IconButton
        sx={{
          position: 'absolute',
          right: '10px',
          top: '10px',
        }}
        onClick={onClose}
      >
        <CloseIcon sx={{ fontSize: '24px' }} />
      </IconButton>
      <Typography
        sx={{
          p: '20px',
          pb: '0px',
          fontSize: '24px',
          fontWeight: '700',
        }}
      >
        {t('topBar.organizations')}
      </Typography>

      <DialogContent sx={{ minHeight: '300px', minWidth: '600px' }}>
        {topOrganizations.map((org) => (
          <NestedOrganization key={org.id} organization={org} />
        ))}
        <Divider />
      </DialogContent>
    </Dialog>
  );
}
