import React, { useState } from 'react';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import { AutocompleteFreesolo, Select } from '../fields';
import { Overlay } from '../Title/index.styles';
import {
  getDirectEmissionsData,
  getDirectEmissionsDataEmissionShares,
  putDirectEmissionsData,
} from '../../../../redux-file/questionnaires/thunks';
import { timeConverter } from '../../../../utils/timeConverter';
import { useAppDispatch, useAppSelector } from '../../../../redux-file/hooks';
import { useGhgOrganization, useLanguage } from 'src/common/hooks';
import { Form, FormBox } from './index.styles';
import FormButtons from '../FormButtons';
import {
  type_of_vehicle,
  type_of_fuel,
  unit_of_distance,
  vehicle_names_pl,
  vehicle_names_en,
} from './data';
import { autoCompleteOptions } from '../../../../utils/helpers';

export default function MobileCombustionDistanceForm({
  state,
  setState,
  id,
  active,
  setActive,
  combinedData,
  setNewForm,
}: {
  state: any;
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  combinedData: any;
  setNewForm: Function;
}) {
  const { t, language } = useLanguage();
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector(
    (redux_state) => redux_state.questionnaire
  );
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const [amount_per_period_error_state, set_amount_per_period_error_state] =
    useState(false);
  const [type_of_fuel_error_state, set_type_of_fuel_error_state] =
    useState(false);
  const [unit_error_state, set_unit_error_state] = useState(false);
  const [manual_factor_error_state, set_manual_factor_error_state] =
    useState(false);
  const [type_of_vehicle_error_state, set_type_of_vehicle_error_state] =
    useState(false);

  let amount_per_period_error = false;
  let type_of_fuel_error = false;
  let unit_error = false;
  let manual_factor_error = false;
  let type_of_vehicle_error = false;
  const amount_per_period_validation = (value: string) => {
    if (value === '') amount_per_period_error = true;
    return amount_per_period_error;
  };

  const type_of_fuel_validation = (value: string) => {
    if (value === '') type_of_fuel_error = true;
    return type_of_fuel_error;
  };
  const unit_validation = (value: string) => {
    if (value === '') unit_error = true;
    return unit_error;
  };
  const manual_factor_validation = (value: string) => {
    if (value === '') manual_factor_error = true;
    return manual_factor_error;
  };
  const type_of_vehicle_validation = (value: string) => {
    if (value === '') type_of_vehicle_error = true;
    return type_of_vehicle_error;
  };

  const setAmountPerPeriod = (value: string) => {
    amount_per_period_validation(value);
    set_amount_per_period_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];
    commutingType = {
      name_of_equipement: commutingType.name_of_equipement,
      unit_of_distance: commutingType.unit_of_distance,
      manual_factor: commutingType.manual_factor,
      type_of_fuel: commutingType.type_of_fuel,
      amount_per_period: value,
      comment: commutingType.comment,
      type_of_vehicle: commutingType.type_of_vehicle,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') {
      set_amount_per_period_error_state(true);
    }
  };

  const setNameOfEquipment = (value: string) => {
    const newForms = [...state];
    let commutingType = newForms[id];
    commutingType = {
      name_of_equipement: value,
      unit_of_distance: commutingType.unit_of_distance,
      manual_factor: commutingType.manual_factor,
      type_of_fuel: commutingType.type_of_fuel,
      amount_per_period: commutingType.amount_per_period,
      comment: commutingType.comment,
      type_of_vehicle: commutingType.type_of_vehicle,
    };
    newForms[id] = commutingType;
    setState(newForms);
  };

  const setTypeOfVehicle = (value: string) => {
    type_of_vehicle_validation(value);
    set_type_of_vehicle_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];
    commutingType = {
      name_of_equipement: commutingType.name_of_equipement,
      unit_of_distance: commutingType.unit_of_distance,
      manual_factor: commutingType.manual_factor,
      type_of_fuel: commutingType.type_of_fuel,
      type_of_vehicle: value,
      amount_per_period: commutingType.amount_per_period,
      comment: commutingType.comment,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') {
      set_type_of_vehicle_error_state(true);
    }
  };

  const setTypeOfFuel = (value: string) => {
    type_of_fuel_validation(value);
    set_type_of_fuel_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];
    commutingType = {
      name_of_equipement: commutingType.name_of_equipement,
      unit_of_distance: commutingType.unit_of_distance,
      manual_factor: commutingType.manual_factor,
      type_of_fuel: value,
      amount_per_period: commutingType.amount_per_period,
      comment: commutingType.comment,
      type_of_vehicle: commutingType.type_of_vehicle,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') {
      set_type_of_fuel_error_state(true);
    }
  };

  const setManualFactor = (value: string) => {
    manual_factor_validation(value);
    set_manual_factor_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];
    commutingType = {
      name_of_equipement: commutingType.name_of_equipement,
      unit_of_distance: commutingType.unit_of_distance,
      manual_factor: value === '' ? null : value,
      type_of_fuel: commutingType.type_of_fuel,
      amount_per_period: commutingType.amount_per_period,
      comment: commutingType.comment,
      type_of_vehicle: commutingType.type_of_vehicle,
    };
    newForms[id] = commutingType;
    setState(newForms);

    if (
      (newForms[id].type_of_fuel === 'other.manual_factor' ||
        newForms[id].type_of_vehicle === 'other.manual_factor') &&
      !value
    ) {
      set_manual_factor_error_state(true);
    }
  };

  const setUnit = (value: string) => {
    unit_validation(value);
    set_unit_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];
    commutingType = {
      name_of_equipement: commutingType.name_of_equipement,
      unit_of_distance: value,
      manual_factor: commutingType.manual_factor,
      type_of_fuel: commutingType.type_of_fuel,
      amount_per_period: commutingType.amount_per_period,
      comment: commutingType.comment,
      type_of_vehicle: commutingType.type_of_vehicle,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_unit_error_state(true);
  };

  const handleSend = (data: any) => {
    if (
      !amount_per_period_error &&
      !unit_error &&
      !type_of_fuel_error &&
      !type_of_vehicle_error &&
      !manual_factor_error
    ) {
      dispatch(
        putDirectEmissionsData({
          orgId: organization.id,
          period: timeConverter(selectedDate, reportingPeriod),
          data,
        })
      ).then(() => {
        dispatch(
          getDirectEmissionsData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
        dispatch(
          getDirectEmissionsDataEmissionShares({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
      });
      setActive(undefined);
    }
  };

  const defineErrors = async () => {
    if (state[id].amount_per_period === '') {
      set_amount_per_period_error_state(true);
      amount_per_period_error = true;
    }

    if (state[id].type_of_fuel === '') {
      set_type_of_fuel_error_state(true);
      type_of_fuel_error = true;
    }

    if (state[id].unit_of_distance === '') {
      set_unit_error_state(true);
      unit_error = true;
    }

    if (state[id].type_of_vehicle === '') {
      set_type_of_vehicle_error_state(true);
      type_of_vehicle_error = true;
    }

    if (
      (state[id].type_of_fuel === 'other.manual_factor' ||
        state[id].type_of_vehicle === 'other.manual_factor') &&
      !state[id].manual_factor
    ) {
      set_manual_factor_error_state(true);
      manual_factor_error = true;
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    const tempcombinedData = {
      fugutive_emissions: combinedData.fugutive_emissions,
      mobile_combustion_consumptionwise:
        combinedData.mobile_combustion_consumptionwise,
      process_emissions: combinedData.process_emissions,
      stationary_combustion: combinedData.stationary_combustion,
      mobile_combustion_distancewise: tempState,
    };

    handleSend(tempcombinedData);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              handleSend(combinedData);
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <FormBox variant="100">
            <AutocompleteFreesolo
              active
              index={2}
              max={4}
              options={language === 'en' ? vehicle_names_en : vehicle_names_pl}
              error={false}
              label={t('direct-emission.mobile-combustion.name_of_equipement')}
              value={
                state[id].name_of_equipement === null
                  ? ''
                  : state[id].name_of_equipement
              }
              setValue={(e: string) =>
                setNameOfEquipment(autoCompleteOptions(e))
              }
            />
          </FormBox>
          <FormBox variant="100">
            <Select
              active
              index={1}
              max={12}
              options={type_of_vehicle}
              label={t(`direct-emission.mobile-combustion.type_of_vehicle`)}
              error={type_of_vehicle_error_state}
              value={state[id].type_of_vehicle}
              setValue={(e: string) => setTypeOfVehicle(e)}
            />
          </FormBox>

          <FormBox variant="50">
            <Select
              active
              index={1}
              max={12}
              options={type_of_fuel}
              label={t(`direct-emission.mobile-combustion.type_of_fuel`)}
              error={type_of_fuel_error_state}
              value={state[id].type_of_fuel}
              setValue={(e: string) => setTypeOfFuel(e)}
            />
          </FormBox>
          <FormBox variant="50">
            <Select
              active
              index={6}
              max={4}
              options={unit_of_distance}
              label={t('direct-emission.mobile-combustion.unit-distance')}
              error={unit_error_state}
              value={state[id].unit_of_distance}
              setValue={(value: string) => setUnit(value)}
            />
          </FormBox>
          <FormBox variant="100">
            <TextFieldNumber
              label={t(
                'direct-emission.mobile-combustion.amount_per_period-distance'
              )}
              active
              error={amount_per_period_error_state}
              index={1}
              value={
                state[id].amount_per_period === null
                  ? ''
                  : state[id].amount_per_period
              }
              setValue={(e: string) => setAmountPerPeriod(e)}
            />
          </FormBox>
          {(state[id].type_of_fuel === 'other.manual_factor' ||
            state[id].type_of_vehicle === 'other.manual_factor') && (
            <FormBox variant="100">
              <TextFieldNumber
                type="factor"
                label={t('direct-emission.mobile-combustion.manual_factor')}
                active
                error={manual_factor_error_state}
                index={3}
                // @ts-ignore
                value={
                  state[id].manual_factor === null
                    ? ''
                    : state[id].manual_factor
                }
                setValue={(e: string) => setManualFactor(e)}
              />
            </FormBox>
          )}
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={
              state[id].amount_per_period !== '' &&
              state[id].type_of_vehicle !== '' &&
              state[id].type_of_fuel !== '' &&
              state[id].unit_of_distance !== '' &&
              (state[id].type_of_fuel !== 'other_manual_factor' ||
                state[id].type_of_vehicle !== 'other_manual_factor' ||
                !!state[id].manual_factor)
            }
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
