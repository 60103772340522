import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProductionProcessSurveyIntro from './Intro';
import ProductionProcessSurveyQuestionnaire from './Questionnaire';
import ProductionProcessSurveyEnd from './End';
import cbam from '../theme';
import { ThemeProvider } from '@mui/material';
import Auth from './auth';

export default function CbamProductionProcessSurveyRoute() {
  return (
    <ThemeProvider theme={cbam}>
      <Auth>
        <Routes>
          <Route index element={<ProductionProcessSurveyIntro />} />
          <Route path="questionnaire">
            <Route
              path=":tab?"
              element={<ProductionProcessSurveyQuestionnaire />}
            />
          </Route>
          <Route path="end" element={<ProductionProcessSurveyEnd />} />
        </Routes>
      </Auth>
    </ThemeProvider>
  );
}
