import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const StyledContainer = styled(Box)`
  padding: 30px 40px 20px 40px;
  border-radius: 26px;
  max-width: 952px;
`;

export const StyledTitle = styled(Typography)`
  color: #6e6e6e;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.45px;
  text-transform: uppercase;
  padding-bottom: 10px;
`;

export const StyledImage = styled('img')`
  display: block;
  max-width: 100%;
  max-height: 550px;
  width: auto;
  height: auto;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
`;

export const ImagePlaceholder = styled('div')`
  max-width: 888px;
  max-height: 550px;
  background-color: #fff;
`;
