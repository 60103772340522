import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAvailabelProductTitle = styled(Typography)`
  color: #38414f;
  height: 132px;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.48px;
  text-align: center;
`;
