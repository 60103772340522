import React from 'react';

import Questionnaire from '../Questionnaire';
import { QuestionnaireAnswer as QuestionnaireAnswerType } from './index.types';

type Props = {
  payload: QuestionnaireAnswerType;
  withBulkSaveButtons?: boolean;
};

export default function QuestionnaireAnswer(props: Props) {
  const { questionnaire } = props.payload;
  return (
    <Questionnaire
      payload={questionnaire}
      withBulkSaveButtons={props.withBulkSaveButtons}
    />
  );
}
