import { Button } from '@mui/material';
import React from 'react';
import { useDirectProcessEmissions } from 'src/Cbam/hooks';
import { StyledPanel } from 'src/components/StyledPanel';
import { StyledNumberField, StyledTextField } from '../../../styles';
import {
  CbamDirectProcessEmissionInSchema,
  CbamDirectProcessEmissionOutSchema,
} from 'src/Cbam/types';
import { StyledFlex } from 'src/components/StyledFlex';
import { useChangeTracker } from '../components/CounterProvider';

interface Props {
  productionProcessId: number;
  id?: number;
  onSaved?: () => void;
}
export default function DirectProcessEmission(props: Props) {
  const { productionProcessId, id, onSaved } = props;
  const { isModified, setIsModified } = useChangeTracker();
  const processEmissions = useDirectProcessEmissions(productionProcessId);
  const processEmission = processEmissions.data.find(
    (c) => c.id === Number(id)
  );

  const initialForm = {
    production_process_id: productionProcessId,
    name: '',
    activity_data: null,
    emission_factor_tco2_tonne: null,
  };

  const handleFormChange = (key: string, value: any) => {
    setIsModified(true);
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  const [form, setForm] = React.useState<
    CbamDirectProcessEmissionInSchema | CbamDirectProcessEmissionOutSchema
  >(initialForm);

  React.useEffect(() => {
    if (processEmission) setForm(processEmission);
  }, [processEmission]);

  const onSuccess = () => {
    setIsModified(false);
    onSaved?.();
  };

  const handleSaveClick = () =>
    id
      ? processEmissions.update.mutateAsync({ ...form, id }).then(onSuccess)
      : processEmissions.create.mutateAsync(form).then(onSuccess);

  return (
    <StyledPanel sx={{ m: '20px 0px' }}>
      <StyledTextField
        label="Name"
        value={form.name}
        onChange={(e) => handleFormChange('name', e.target.value)}
      />
      <StyledNumberField
        label="Activity data [tonne]"
        value={form.activity_data || 0}
        onChange={(e) => handleFormChange('activity_data', e.target.value)}
      />

      <StyledNumberField
        label="Emission factor [tCO2/tonne]"
        value={form.emission_factor_tco2_tonne}
        onChange={(e) =>
          handleFormChange('emission_factor_tco2_tonne', e.target.value)
        }
      />

      <StyledFlex>
        <Button
          onClick={() =>
            id ? processEmissions.delete.mutate(id) : onSaved?.()
          }
          color="primary"
        >
          Remove Answer
        </Button>
        {isModified && (
          <Button
            color="primary"
            onClick={handleSaveClick}
            sx={{ minWidth: '200px' }}
          >
            {id ? 'Save' : 'Create'}
          </Button>
        )}
      </StyledFlex>
    </StyledPanel>
  );
}
