import React from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: '#505050',
    boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.30)',
    borderRadius: '4px',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
}));
