import { Collapse, Table, TableBody, TableRow } from '@mui/material';
import React from 'react';
import { StyledTableCell } from '../styles';

export default function TableCollapsable({
  isExpanded,
  children,
}: {
  isExpanded: boolean;
  children: React.ReactNode;
}) {
  return (
    <TableRow sx={{ p: 0 }}>
      <StyledTableCell sx={{ p: 0, borderBottom: 'none' }}>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Table>
            <TableBody>{children}</TableBody>
          </Table>
        </Collapse>
      </StyledTableCell>
    </TableRow>
  );
}
