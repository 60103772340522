import React from 'react';

export default function LogoMinCbam() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8417 0C16.5435 0 20.6061 2.77898 22.5178 6.80406C19.5054 13.7166 11.9052 15.4545 5.82269 12.4099C11.8414 12.0001 16.1146 10.6995 17.8883 6.8061C16.434 5.06949 14.2647 3.96651 11.8417 3.96651C7.94369 3.96651 4.70188 6.81909 4.03844 10.576C6.74623 6.92503 10.1129 5.73979 14.026 6.59752C8.01707 6.30873 4.84915 10.0707 4.84915 13.9247C4.84915 18.3631 9.28414 20.5826 12.8788 20.5826C17.2087 20.5826 21.8847 18.0119 23.625 13.1872C23.0367 19.2565 17.9848 24 11.8414 24C5.30275 24 0 18.6264 0 12.0001C0 5.37383 5.303 0 11.8417 0Z"
        fill="#2E3342"
      />
    </svg>
  );
}
