import { useMutation, useQuery, useQueryClient } from 'react-query';
import { client } from 'src/utils/api-client';
import {
  AnsweredQuestionsSchema,
  IndicatorResponsibility,
  IndicatorSchema,
} from './types';
import { getOrganizationReports } from './api';
import { toast } from 'react-toastify';
import { useLanguage } from 'src/common/hooks';
import { useTranslation } from 'react-i18next';

export const useIndicators = () => {
  const { language } = useLanguage();
  const queryKey = ['indicators', language];

  const { data: indicators } = useQuery(queryKey, () =>
    client
      .get<IndicatorSchema[]>('/web/esg-administration-panel/indicators')
      .then((res) => res.data)
  );

  const { data: answeredQuestions } = useQuery('answered-esg-questions', () =>
    client
      .get<AnsweredQuestionsSchema>(
        '/web/esg-administration-panel/answered-esg-questions'
      )
      .then((res) => res.data)
  );

  return {
    indicators: indicators || [],
    answeredQuestions: answeredQuestions || {},
  };
};

export const useReports = (organizationId: number) => {
  const queryKey = ['esg-reports', { organizationId }];

  const query = useQuery(queryKey, () =>
    getOrganizationReports({ organizationId })
  );

  return { reports: query.data || [], ...query };
};

export const useIndicatorsResponsibilites = () => {
  const queryKey = ['indicators-responsibilities'];
  const url = '/web/esg-administration-panel/indicators-responsibilities';
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries(queryKey);
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg.administrationPanel.toasts',
  });

  const { data } = useQuery(queryKey, () =>
    client.get<IndicatorResponsibility[]>(url).then((res) => res.data)
  );

  const createIndicatorResponsibility = useMutation({
    mutationFn: (data: Omit<IndicatorResponsibility, 'id'>) =>
      client.post(url, data),
    onSuccess: () => {
      invalidateQuery();
      toast.success(t('responsibilityCreated') as string);
    },
  });

  const updateIndicatorResponsibility = useMutation({
    mutationFn: (data: IndicatorResponsibility) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      invalidateQuery();
      toast.success(t('responsibilityUpdated') as string);
    },
  });

  const deleteIndicatorResponsibility = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      invalidateQuery();
      toast.success(t('responsibilityDeleted') as string);
    },
  });

  const notify = useMutation({
    mutationFn: (id: number) => client.post(`${url}/${id}/notify`),
  });

  return {
    data: data || [],
    create: createIndicatorResponsibility,
    update: updateIndicatorResponsibility,
    delete: deleteIndicatorResponsibility,
    notify,
  };
};
