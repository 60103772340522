import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { client } from 'src/utils/api-client';
import {
  StyledHeader,
  StyledCell,
  StyledRow,
  StyledDataCell,
} from './index.styles';
import { TableRow } from './index.types';
import CustomSelect from 'src/Esg/Dma/components/CustomSelect';
import SendFormModal from './SendFormModal';
import { useEsgOrganization, useLanguage } from 'src/common/hooks';

import {
  getBooleanOptions,
  getNumericOptions,
  getStakeholderNatureOptions,
} from 'src/Esg/Dma/utils';
import EditModal from './EditModal';

export default function SelectingStakeholdersTable() {
  const { t, language } = useLanguage();

  const queryClient = useQueryClient();
  const organization = useEsgOrganization();

  const { reportId } = useParams();

  const queryKey = [
    'dma-stakeholders-table',
    organization.id,
    reportId,
    language,
  ];
  const getUrl = `/web/esg_stakeholders/${organization.id}/${reportId}/stakeholders_table`;
  const putUrl = `/web/esg_stakeholders/${organization.id}/${reportId}/stakeholders_table_row`;

  const { data } = useQuery(queryKey, () =>
    client.get(getUrl).then((res) => res.data)
  );
  const updateTableRow = (row: TableRow) => {
    client.put(putUrl, row).then(() => queryClient.invalidateQueries(queryKey));
  };

  const translatedColumn = (key: string) =>
    t(`esg.dma.selectingStakeholders.table.columns.${key}`);

  const stakeholderTypesCellStyle = {
    width: '20%',
    minWidth: '200px',
    backgroundColor: '#F6F6F6',
  };

  const NUMERIC_OPTIONS = getNumericOptions(t);
  const BOOLEAN_OPTIONS = getBooleanOptions(t);
  const STAKEHOLDER_NATURE_OPTIONS = getStakeholderNatureOptions(t);
  return (
    <div style={{ width: '100%' }}>
      <StyledRow sx={{ borderWidth: '2px' }}>
        <StyledHeader style={stakeholderTypesCellStyle}>
          {translatedColumn('stakeholderTypes')}
        </StyledHeader>
        <StyledHeader style={{ minWidth: '260px' }}>
          {translatedColumn('stakeholders_nature')}
        </StyledHeader>
        <StyledHeader>{translatedColumn('severity')}</StyledHeader>
        <StyledHeader>{translatedColumn('selected_for_survey')}</StyledHeader>
        <StyledHeader>{translatedColumn('introText')}</StyledHeader>
        <StyledHeader>{translatedColumn('outroText')}</StyledHeader>
        <StyledHeader>{translatedColumn('reasoning')}</StyledHeader>
        <StyledHeader sx={{ backgroundColor: '#F6F6F6' }}>
          {translatedColumn('send')}
        </StyledHeader>
      </StyledRow>

      {(data || []).map((row: TableRow) => {
        const {
          stakeholder_key: key,
          stakeholder_name: stakeholderName,
          stakeholder_nature,
          severity,
          selected_for_survey: selectedForSurvey,
          reasoning,
          intro_text,
          outro_text,
        } = row;
        return (
          <StyledRow key={key}>
            <StyledCell style={stakeholderTypesCellStyle}>
              {stakeholderName}
            </StyledCell>
            <StyledDataCell style={{ minWidth: '260px' }}>
              <CustomSelect
                key={key}
                fullWidth
                value={stakeholder_nature}
                options={STAKEHOLDER_NATURE_OPTIONS}
                onChange={(value) =>
                  updateTableRow({ ...row, stakeholder_nature: value })
                }
              />
            </StyledDataCell>
            <StyledDataCell>
              <CustomSelect
                key={key}
                value={severity}
                options={NUMERIC_OPTIONS}
                onChange={(value) =>
                  updateTableRow({ ...row, severity: value })
                }
              />
            </StyledDataCell>
            <StyledDataCell>
              <CustomSelect
                key={key}
                value={selectedForSurvey}
                options={BOOLEAN_OPTIONS}
                onChange={(value) =>
                  updateTableRow({ ...row, selected_for_survey: value })
                }
              />
            </StyledDataCell>
            <StyledDataCell>
              <EditModal
                title={t(
                  'esg.dma.selectingStakeholders.table.columns.introText'
                )}
                subtitleTranslationKey="esg.dma.selectingStakeholders.table.modals.intro.subtitle"
                value={intro_text}
                defaultValue={t('esg.stakeholderSurvey.intro.message', {
                  organization_name: organization.name,
                })}
                onChange={(value) =>
                  updateTableRow({ ...row, intro_text: value })
                }
              />
            </StyledDataCell>
            <StyledDataCell>
              <EditModal
                title={t(
                  'esg.dma.selectingStakeholders.table.columns.outroText'
                )}
                subtitleTranslationKey="esg.dma.selectingStakeholders.table.modals.outro.subtitle"
                value={outro_text}
                defaultValue={t('esg.stakeholderSurvey.outro.message')}
                onChange={(value) =>
                  updateTableRow({ ...row, outro_text: value })
                }
              />
            </StyledDataCell>
            <StyledDataCell>
              <EditModal
                title={t(
                  'esg.dma.selectingStakeholders.table.modals.reasoning.title'
                )}
                subtitleTranslationKey="esg.dma.selectingStakeholders.table.modals.reasoning.subtitle"
                value={reasoning}
                attention={
                  (selectedForSurvey !== null && reasoning === null) ||
                  reasoning === ''
                }
                onChange={(value) =>
                  updateTableRow({ ...row, reasoning: value })
                }
              />
            </StyledDataCell>
            <StyledDataCell sx={{ backgroundColor: '#F6F6F6' }}>
              <SendFormModal
                organizationId={String(organization.id)}
                reportId={reportId}
                stakeholderKey={key}
                stakeholderName={stakeholderName}
                disabled={
                  reasoning === null ||
                  reasoning === '' ||
                  selectedForSurvey !== true
                }
              />
            </StyledDataCell>
          </StyledRow>
        );
      })}
    </div>
  );
}
