import styled from 'styled-components';

export const VerticalLine = styled.div`
  height: 100%;
  width: 1px;
  background-color: #b6bbb9;
  margin: 0 12px;
`;

export const CompanyBox = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #606462;
  filter: drop-shadow(0px 2px 5px #dadada);
  > img {
    width: 30px;
    height: 28px;
    margin-right: 18px;
  }
  > span {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto;
`;
