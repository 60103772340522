import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  color: #ffffff;
  border-radius: 28px;
  background-color: #38414f;
  text-decoration: none;
  transition: background-color 300ms ease-in-out;
  padding: 0 24px;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.36px;

  &:hover {
    background-color: #49525f;
    transition: background-color 300ms ease-in-out;
  }

  &:active {
    background-color: #5a636f;
    transition: background-color 300ms ease-in-out;
  }
`;
