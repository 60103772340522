import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import themeColors from '../../../../utils/theme';
import FormComment from '../FormComment';
import IconSvg from '../../IconSvg';

interface FormButtonsProps {
  onClickRemove: Function;
  state: any;
  setState: Function;
  setActive: Function;
  id: number;
  canCopy: boolean;
  setTable: Function;
  variant?: 'normal' | 'sold-products';
}

const Box = styled.div`
  border-top: 1px solid ${themeColors.strokePressed};
  display: flex;
  justify-content: flex-end !important;
  width: 100%;
`;

const Button = styled.button`
  z-index: 100;
  position: relative;
  background: transparent;
  margin-top: 16px;
`;

export default function FormButtons({
  onClickRemove,
  state,
  id,
  setState,
  setActive,
  canCopy,
  setTable,
  variant,
}: FormButtonsProps) {
  const [showComment, setShowComment] = useState(false);
  const commentBtnRef = useRef<any>(null);

  const handleCopy = () => {
    const tempState = [...state];
    const item = tempState[id];

    tempState.splice(id, 0, item);
    setState(tempState);
    setActive(id);
    setTable(false);
  };
  const handleComment = () => {
    setShowComment(!showComment);
  };

  return (
    <>
      {showComment && (
        <FormComment
          state={state}
          setState={setState}
          setActiveFormComment={setShowComment}
          commentBtnRef={commentBtnRef}
          id={id}
        />
      )}
      <Box>
        {variant === 'sold-products' ? null : (
          <Button
            ref={commentBtnRef}
            type="button"
            onClick={() => handleComment()}
          >
            {state[id].comment && !showComment && (
              <IconSvg name="addedComment" />
            )}
            {(!state[id].comment || showComment) && (
              <IconSvg name="addComment" />
            )}
          </Button>
        )}

        <Button disabled={!canCopy} type="button" onClick={() => handleCopy()}>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28 13H16C14.9 13 14 13.9 14 15V29H16V15H28V13ZM31 17H20C18.9 17 18 17.9 18 19V33C18 34.1 18.9 35 20 35H31C32.1 35 33 34.1 33 33V19C33 17.9 32.1 17 31 17ZM31 33H20V19H31V33Z"
              fill={!canCopy ? '#B6BBB9' : '#606462'}
            />
          </svg>
        </Button>

        <Button type="button" onClick={() => onClickRemove()}>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 31C18 32.1 18.9 33 20 33H28C29.1 33 30 32.1 30 31V19H18V31ZM20 21H28V31H20V21ZM27.5 16L26.5 15H21.5L20.5 16H17V18H31V16H27.5Z"
              fill="#606462"
            />
            <rect x="22" y="23" width="1" height="6" fill="#606462" />
            <rect x="25" y="23" width="1" height="6" fill="#606462" />
          </svg>
        </Button>
      </Box>
    </>
  );
}
