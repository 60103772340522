import React, { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Navigate, Outlet, RouteProps } from 'react-router-dom';

import { useAppDispatch, usePlatform } from 'src/redux-file/hooks';
import { getOrganization } from './api';
import {
  OrganizationDataOutSchema,
  ProductPlatform,
} from 'src/utils/api.interfaces';
import { setActiveOrganization } from 'src/redux-file/platform/slice';
import PageLoading from 'src/components/PageLoading';
import {
  setLastVisitedProduct,
  usePlatformNavigation,
} from '../utils/navigation';
import { NavigateToOrganization } from '../components/Navigation';
import { useOrganization } from './hooks';

type ProductOrganizationRouteProps = {
  product: ProductPlatform;
};

export const ProductOrganizationRoute = ({
  product,
}: ProductOrganizationRouteProps) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { activeOrganization } = usePlatform();
  const { organizationId, navigateToOrganization } = usePlatformNavigation();

  const invalidatePlatformState = () =>
    queryClient.invalidateQueries(['platform']);

  const { data, isError } = useQuery(
    ['organizations', { organizationId }],
    () => getOrganization({ organizationId: organizationId as number }),
    {
      enabled: typeof organizationId === 'number',
      // the organization/product list is likely outdated, so refetch on error
      onError: invalidatePlatformState,
    }
  );

  useEffect(() => {
    const isValid = data && !!data[product];
    dispatch(setActiveOrganization(isValid ? data : null));
    if (data && !isValid) {
      // the retrieved org does not have access to the product, so reset selection
      navigateToOrganization(null);
      // and invalidate the organization list, to make sure the org list is up-to-date
      invalidatePlatformState();
    }
  }, [data]);

  // cleanup on unmount
  useEffect(() => {
    return () => {
      dispatch(setActiveOrganization(null));
    };
  }, []);

  // if no organizationId, redirect to product home
  if (typeof organizationId !== 'number' || isError)
    return <NavigateToOrganization organizationId={null} />;
  if (activeOrganization === null) return <PageLoading />;

  return <Outlet />;
};

type ProductHomeRouteProps = RouteProps & {
  chooseFirstOrganization?: boolean;
};

export const ProductHomeRoute = (props: ProductHomeRouteProps) => {
  const { productOrganizations, organizationId, navigate } =
    usePlatformNavigation();

  const numOrgs = productOrganizations.length;

  useEffect(() => {
    // TODO: replace this with an exception,
    //       catch it in an error boundary, and reset lastVisitedProduct
    if (numOrgs === 0) {
      setLastVisitedProduct(null);
      navigate('/');
    }
  }, []);

  if (numOrgs === 1 || (props.chooseFirstOrganization && numOrgs >= 1)) {
    // if only one organization, set it as active
    return (
      <NavigateToOrganization organizationId={productOrganizations[0]?.id} />
    );
  }

  if (organizationId !== null) {
    return <NavigateToOrganization organizationId={organizationId} />;
  }

  return (
    <>
      <Outlet />
      {props.children}
    </>
  );
};

type PermissionRouteProps = {
  readData?: boolean;
  writeData?: boolean;
  predicate?: (organization: OrganizationDataOutSchema) => boolean;
};

export const PermissionRoute = (props: PermissionRouteProps) => {
  const org = useOrganization();

  const result =
    (!props.readData || org.can_read_data) &&
    (!props.writeData || org.can_write_data) &&
    (!props.predicate || props.predicate(org));

  if (!result) {
    // TODO: in the future, throw an error to be caught by the nearest error boundary
    return <Navigate to=".." />;
  }
  return <Outlet />;
};
