import React from 'react';

export default function Logo() {
  return (
    <svg
      width="133"
      height="36"
      viewBox="0 0 133 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.31802 8.85254C13.0179 8.85254 16.2146 11.0393 17.7189 14.2066C15.3485 19.6459 9.36805 21.0135 4.58179 18.6177C9.31783 18.2953 12.6803 17.2718 14.076 14.2082C12.9316 12.8416 11.2247 11.9737 9.31802 11.9737C6.25077 11.9737 3.69984 14.2184 3.17779 17.1746C5.30851 14.3017 7.95772 13.3691 11.0368 14.044C6.30851 13.8168 3.81572 16.7771 3.81572 19.8097C3.81572 23.3022 7.30555 25.0486 10.1341 25.0486C13.5413 25.0486 17.2208 23.0258 18.5902 19.2293C18.1272 24.0052 14.1519 27.7378 9.31783 27.7378C4.17265 27.7378 0 23.5094 0 18.2953C0 13.0811 4.17285 8.85254 9.31802 8.85254Z"
        fill="white"
      />
      <path
        d="M41.9776 27.1765H37.6491V17.8588C37.6491 16.3882 37.2737 15.2294 36.523 14.3824C35.7723 13.5235 34.7576 13.0941 33.479 13.0941C32.1652 13.0941 31.1095 13.5471 30.3118 14.4529C30.0818 14.7108 29.885 14.9945 29.7213 15.3042L29.5947 11.0072C30.0123 10.6112 30.4801 10.2774 30.998 10.0059C32.042 9.45294 33.262 9.17647 34.6579 9.17647C36.9336 9.17647 38.7224 9.87059 40.0245 11.2588C41.3265 12.6471 41.9776 14.5647 41.9776 17.0118V27.1765Z"
        fill="white"
      />
      <path
        d="M27.7397 9.52941L28.2595 27.1765H24.7869V9.52941H27.7397Z"
        fill="white"
      />
      <path
        d="M58.2084 27.1765L51.8818 9.52941H47.271L54.5203 27.1765H58.2084Z"
        fill="white"
      />
      <path
        d="M59.5898 25.035L65.9749 9.52941H61.3649L57.7025 19.7706L59.5898 25.035Z"
        fill="white"
      />
      <path
        d="M74.3475 4.85294C73.7375 4.85294 73.2214 4.64118 72.7991 4.21765C72.3885 3.78235 72.1832 3.25882 72.1832 2.64706C72.1832 2.04706 72.3885 1.53529 72.7991 1.11176C73.2214 0.676471 73.7375 0.458824 74.3475 0.458824C74.9692 0.458824 75.4912 0.676471 75.9134 1.11176C76.3357 1.53529 76.5469 2.04706 76.5469 2.64706C76.5469 3.25882 76.3357 3.78235 75.9134 4.21765C75.4912 4.64118 74.9692 4.85294 74.3475 4.85294Z"
        fill="white"
      />
      <path
        d="M72.1832 27.1765V9.52941H76.5117V27.1765H72.1832Z"
        fill="white"
      />
      <path
        d="M88.3182 27.1765H84.1453V9.52941H87.7984L88.3182 27.1765Z"
        fill="white"
      />
      <path
        d="M89.7654 14.8071C90.0991 14.4027 90.4897 14.0611 90.9371 13.7824C91.723 13.3 92.6321 13.0588 93.6644 13.0588C94.3447 13.0588 95.0486 13.1647 95.7758 13.3765L96.3037 9.45882C95.9401 9.36471 95.5705 9.29412 95.1952 9.24706C94.8315 9.2 94.4738 9.17647 94.1219 9.17647C92.7729 9.17647 91.5764 9.48824 90.5324 10.1118C90.2172 10.3021 89.9218 10.5161 89.646 10.7536L89.7654 14.8071Z"
        fill="white"
      />
      <path d="M106.365 27.1765H101.738V0H106.365V27.1765Z" fill="white" />
      <path
        d="M121.565 36H117.045L127.963 9.52941H132.492L121.565 36Z"
        fill="white"
      />
      <path
        d="M116.744 9.52941L121.557 19.802L119.476 24.8478L111.87 9.52941H116.744Z"
        fill="white"
      />
    </svg>
  );
}
