import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import BasePage from '../Base';
import { EmployeeEmissionsAndMeansOfTransportDataType } from './types';
import { LOGO_MARGIN_LEFT } from '../../config';
import PDFChart from '../../../components/PDFChart';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  titleText: {
    color: '#165D5C',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Poppins',
    fontSize: 36,
    fontStyle: 'bold',
    letterSpacing: 1,
  },
  title1: { position: 'absolute', top: 150, left: LOGO_MARGIN_LEFT },
  title2: { position: 'absolute', top: 150, left: 1000 },
  chart1: {
    position: 'absolute',
    top: 200,
    left: LOGO_MARGIN_LEFT,
    width: 630,
    height: 700,
  },
  chart2: {
    position: 'absolute',
    top: 200,
    left: 1000,
    width: 800,
    height: 700,
  },
});

type EmployeeEmissionsAndMeansOfTransportProps = {
  data: {
    employee_emissions_and_means_of_transport: EmployeeEmissionsAndMeansOfTransportDataType;
  };
};

const GoodsAndServicesEmissionsChart = ({
  data,
}: EmployeeEmissionsAndMeansOfTransportProps) => {
  const {
    top_right: topRight,
    employee_emissions_chart_title: employeeEmissionsChartTitle,
    employee_emissions_chart: employeeEmissionsChart,
    employees_means_of_transport_chart_title:
      employeesMeansOfTransportChartTitle,
    employees_means_of_transport_chart: employeesMeansOfTransportChart,
  } = data.employee_emissions_and_means_of_transport;

  const title1Style = { ...styles.titleText, ...styles.title1 };
  const title2Style = { ...styles.titleText, ...styles.title2 };

  return (
    <BasePage
      backgroundImageName="Report_envirly_slide_8.png"
      topRight={topRight}
    >
      <View>
        <Text style={title1Style}>{employeeEmissionsChartTitle}</Text>
        <PDFChart base64={employeeEmissionsChart} style={styles.chart1} />

        <Text style={title2Style}>{employeesMeansOfTransportChartTitle}</Text>
        <PDFChart
          base64={employeesMeansOfTransportChart}
          style={styles.chart2}
        />
      </View>
    </BasePage>
  );
};

export default GoodsAndServicesEmissionsChart;
