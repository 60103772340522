import React from 'react';

export default function AboutCompanyIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.05001 8.48725C3.32001 11.2669 3.32001 15.7673 6.03001 18.5469C7.50001 15.0851 10.12 12.1934 13.39 10.4727C10.62 12.8553 8.68001 16.1847 8.00001 19.9622C10.6 21.2145 13.8 20.7563 15.95 18.5673C19.43 15.024 20 4.36362 20 4.36362C20 4.36362 9.53001 4.94398 6.05001 8.48725Z"
        fill="currentColor"
      />
    </svg>
  );
}
