import React from 'react';

import BasePage from '../Base';
import PDFTable from '../../../components/PDFTable';
import { LOGO_MARGIN_LEFT, PDF_WIDTH } from '../../config';
import { CarbonFootprintTableDataType } from './types';

type TotalEmissionByScopeProps = {
  data: {
    total_emissions_by_scope_table: CarbonFootprintTableDataType;
  };
};

const TotalEmissionByScopeTable = ({ data }: TotalEmissionByScopeProps) => {
  const {
    table_data: tableData,
    top_right: topRight,
    page_title: pageTitle,
  } = data.total_emissions_by_scope_table;
  return (
    <BasePage
      backgroundImageName="Report_envirly_slide_8.png"
      mainHeader={pageTitle}
      topRight={topRight}
    >
      <PDFTable
        columns={tableData.columns}
        data={tableData.data}
        containerStyle={{
          marginTop: 300,
          marginLeft: LOGO_MARGIN_LEFT,
          width: PDF_WIDTH - 2 * LOGO_MARGIN_LEFT,
        }}
      />
    </BasePage>
  );
};

export default TotalEmissionByScopeTable;
