import { ProductPlatform } from '../utils/api.interfaces';

export class RoutingError extends Error {
  constructor(message?: string) {
    super(['Routing error', message].filter(Boolean).join(': '));
  }
}

export class RequiredStateError extends Error {
  constructor() {
    super('Required state is undefined');
  }
}

export class ProductPermissionError extends Error {
  constructor(public readonly product: ProductPlatform) {
    super(`Organization has no access to product ${product}`);
  }
}
