import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValueLabel, ValueParagraph } from '../TextFieldNumber/index.styles';
import { TextFieldNumberPercentageProps } from './index.interfaces';
import { StyledTextField, ValueField } from './index.styles';

export default function TextFieldNumberPercentage({
  label,
  value,
  setValue,
  error,
  active,
  index,
  max,
}: TextFieldNumberPercentageProps) {
  const { t } = useTranslation();
  // eslint-disable-next-line prefer-regex-literals
  const int_regex = new RegExp(/^[+-]?(?=.?\d)\d*((\.|,)\d{0,2})?$/);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setValue('');
    } else if (
      parseFloat(event.target.value) >= 0 &&
      parseFloat(event.target.value) <= 100
    ) {
      if (int_regex.test(event.target.value)) {
        setValue(parseFloat(event.target.value));
      }
    }
  };

  return (
    <>
      {active && (
        <StyledTextField
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          value={Number.isNaN(value) ? '' : value}
          label={t(label)}
          error={error}
          onChange={handleChange}
        />
      )}
      {!active && !Number.isNaN(value) && (
        <ValueField className="value-field" index={index} max={max}>
          <ValueLabel>{t(label)}</ValueLabel>
          <ValueParagraph>{Number.isNaN(value) ? '' : value}</ValueParagraph>
        </ValueField>
      )}
    </>
  );
}
