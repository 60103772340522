import React from 'react';
import { stationaryCombustion } from '../../../Pages/Questionnaire/directEmission';
import {
  StyledHeader,
  StyledTd,
} from '../../../Pages/Questionnaire/index.styles';
import { companiesColors } from '../../../Pages/SuperDashboard/data';
import { useTranslation } from 'react-i18next';
import CommentBtn from './commentBtn';
import TableButton from '../../TableButton';
import { toLocaleNumber } from '../../../../utils/helpers';

// TODO: przerobić tabelki na reużywalny komponent

interface Props extends stationaryCombustion {
  emission_shared: number;
}

export default function StationaryCombustionPreview({
  state,
  id,
  setActive,
  setState,
  language,
}: {
  state: Props[];
  id: number;
  setActive: Function;
  setState: Function;
  language: string;
}) {
  const handleCopy = () => {
    const tempState = [...state];
    const item = tempState[id];

    tempState.splice(id, 0, item);
    setState(tempState);
    setActive(id);
  };
  const { t } = useTranslation();
  return (
    <StyledHeader>
      <StyledTd width={30} color={companiesColors[id]}>
        <p>{t(`dropdowns.${state[id].type_of_fuel}`)}</p>
        <CommentBtn state={state} id={id} />
      </StyledTd>
      <StyledTd width={20} color={companiesColors[id]}>
        {state[id].name_of_equipement}
      </StyledTd>
      <StyledTd width={15} color={companiesColors[id]}>
        {toLocaleNumber(state[id].amount_per_period, language)}
      </StyledTd>
      <StyledTd width={20} color={companiesColors[id]}>
        {t(`dropdowns.${state[id].unit}`)}
      </StyledTd>
      <StyledTd width={15} color={companiesColors[id]}>
        {state[id].emission_shared
          ? (state[id].emission_shared * 100).toFixed(2)
          : 0}
      </StyledTd>
      <StyledTd
        width={0}
        color={companiesColors[id]}
        lastCallCustom
        numberOfIcons="two"
      >
        <TableButton hoverText="copy" icon="copy" onClick={handleCopy} />
        <TableButton
          hoverText="edit"
          icon="edit"
          onClick={() => setActive(id)}
        />
      </StyledTd>
    </StyledHeader>
  );
}
