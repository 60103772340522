import React from 'react';
import { TextField } from '@mui/material';

import { UniversalField } from '../types';
import { useTranslation } from 'react-i18next';
import { HOVER_COLOR } from '../../../helpers';
import { StyledTitle } from '../../index.styles';
import SampleAnswer from './SampleAnswer';

export default function SimpleTextField(props: UniversalField) {
  const { t } = useTranslation();

  const { field, value, onChange, errors, disabled } = props;
  const {
    title,
    label,
    placeholder,
    required,
    theme_color: themeColor,
  } = field;

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  /* backend side validation */
  React.useEffect(() => {
    const newMessage = errors?.[field.key];
    if (!!newMessage) setErrorMessage(newMessage);
  }, [errors]);

  /* Frontend side validation */
  const _validate = (_value: string) => {
    // required field
    if (required && !_value) {
      setErrorMessage(t('questionnaireV3.field.fieldRequired'));
    }
    // cleaning error messages if none
    else if (!!errorMessage) {
      setErrorMessage(undefined);
    }
  };

  // local handle change injects frontend validation
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: _value } = e.target;
    _validate(_value);
    onChange(!!_value ? _value : null);
  };

  // not showing the label when placeholder is set
  // except when the value is set (placeholder is covered by value anyways)
  const _label = !value && !!placeholder ? null : label;

  return (
    <>
      {!!title && <StyledTitle>{title}</StyledTitle>}
      <TextField
        fullWidth
        multiline
        value={value}
        label={_label}
        onChange={handleChange}
        error={!!errorMessage}
        helperText={errorMessage}
        disabled={disabled}
        sx={{
          mb: placeholder ? '12px' : '32px',
          '& .MuiOutlinedInput-root': {
            backgroundColor: !!value ? HOVER_COLOR : 'inherit',
          },
        }}
      />
      <SampleAnswer placeholder={placeholder} />
    </>
  );
}
