import React from 'react';

import { styled } from '@mui/material/styles';
import { ThemeColors } from '../../../helpers';
import { Checkbox } from '@mui/material';

export const StyledCheckboxBackground = styled('div')`
  position: absolute;
  width: 12px;
  height: 12px;
  margin: 10px 15px;
`;

interface Props {
  checked: boolean;
  error?: boolean;
  disabled?: boolean;
  themeColors: ThemeColors;
  handleChange:
    | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
}

export default function ImprovedCheckbox(props: Props) {
  const { checked, error, disabled, themeColors, handleChange } = props;
  return (
    <>
      {checked && (
        <StyledCheckboxBackground
          sx={{
            backgroundColor:
              !error && !disabled ? themeColors.contrastText : 'default',
          }}
        />
      )}
      <Checkbox
        disabled={!!disabled}
        checked={checked}
        onChange={handleChange}
      />
    </>
  );
}
