import { Typography } from '@mui/material';
import InfoPanel from '../components/InfoPanel';
import React from 'react';

export default function InstallationInfoPanel() {
  return (
    <InfoPanel>
      <Typography>
        Installation means a stationary technical unit where a production
        process is carried out.
      </Typography>
    </InfoPanel>
  );
}
