import React from 'react';

import { OrganizationOutFormSchema } from 'src/Superadmin/index.types';
import { Select } from '@mui/material';
import { StyledMenuItem } from 'src/Superadmin/components/UsersTable/index.styles';

interface Props {
  disabled?: boolean;
  value?: any;
  onChange?: any;
  organizations: OrganizationOutFormSchema[];
}

export default function OrganizationSelect(props: Props) {
  const { value, onChange, disabled, organizations } = props;

  return (
    <Select
      fullWidth
      disabled={disabled}
      value={value}
      onChange={onChange}
      size="small"
    >
      {(organizations || []).map((org) => (
        <StyledMenuItem key={org.id} value={org.id.toString()}>
          {org.name}
        </StyledMenuItem>
      ))}
    </Select>
  );
}
