import React from 'react';

export const EsgReportsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1448_12135)">
      <path
        d="M21 11L19 11L19 13L21 13L21 11ZM21 7L19 7L19 9L21 9L21 7ZM21 15L19 15L19 17L21 17L21 15ZM17 11L3 11L3 13L17 13L17 11ZM17 7L3 7L3 9L17 9L17 7ZM17 17L17 15L3 15L3 17L17 17Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1448_12135">
        <rect
          width="24"
          height="24"
          fill="currentColor"
          transform="translate(24 24) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
);
