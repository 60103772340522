import React from 'react';

import { SVG, animationStyle, Check, Circle } from './index.styles';

type Props = {
  size?: number;
};

export default function Checkmark({ size = 24 }: Props) {
  const style = { width: size, height: size };

  return (
    <div>
      <style>{animationStyle}</style>
      <SVG style={style} viewBox="0 0 52 52">
        <Circle cx="26" cy="26" r="25" fill="none" />
        <Check fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </SVG>
    </div>
  );
}
