import React from 'react';
import { useTranslation } from 'react-i18next';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { CircularProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import Document from './Document';
import { ESGReportButton } from './index.styles';
import { EsgSummary } from '../../../components/ESGSummary/types';

type Props = {
  esgSummary?: EsgSummary;
  isLoading?: boolean;
};

export default function PDFDownloadButton({ esgSummary, isLoading }: Props) {
  const { t } = useTranslation();
  const { company_name: companyName, context: year } =
    esgSummary?.pdf?.cover || {};

  if (isLoading)
    return (
      <ESGReportButton variant="contained">
        {t('raport.loading')}
      </ESGReportButton>
    );

  if (!esgSummary)
    return <ESGReportButton variant="contained">Error</ESGReportButton>;

  return (
    <PDFDownloadLink
      document={<Document esgSummary={esgSummary} />}
      fileName={`${companyName?.replaceAll(' ', '')}-${year}-esg-report.pdf`}
    >
      {({ url, error }) => {
        if (error) return String(error);
        const startIcon =
          !!url && !isLoading ? (
            <DownloadIcon />
          ) : (
            <CircularProgress size={15} style={{ color: 'grey' }} />
          );
        return (
          <ESGReportButton
            disabled={!url || isLoading}
            variant="contained"
            startIcon={startIcon}
          >
            {t('raport.download')}
          </ESGReportButton>
        );
      }}
    </PDFDownloadLink>
  );
}
