import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import {
  iconOutlineSizePx,
  questionnairePaddingLeftRightPx,
} from '../Question/index.styles';

export const StyledContainer = styled.div`
  padding: 10px ${questionnairePaddingLeftRightPx}px;
  margin-left: ${iconOutlineSizePx / 2}px;
  border-left: 2px solid #bbb;
`;

export const Title = styled.div`
  color: #38414f;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.64px;
`;

export const Descriptions = styledMUI('div', {
  name: 'EsgDescriptions',
  slot: 'root',
})(() => ({
  '& > p': {
    borderRadius: ' 0 16px 16px 16px',
    padding: 24,
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '0.36px',
    marginBottom: 16,
  },
  marginTop: 32,
}));

export const Subtitle = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: #49525f;
  margin-bottom: 32px;
`;
