/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { AppState, ReportingFreq, Target } from './interfaces';
import {
  getOrganization,
  getRecommendations,
  getTarget,
  getTargetEmission,
  getTargets,
  getTaskList,
  postTaskList,
  patchTaskList,
  deleteTaskList,
  setSelectedDate,
  getStatsTargetTotal,
  getStatsTargetCompleted,
  putAboutCompanyPeriodData,
  getAboutCompanyPeriodData,
  setHaveAccess,
} from './thunk';

const initialTarget: Target = {
  id: 0,
  method: 'total',
  target_value: 0,
  start_period: '',
  end_period: '',
  comment: '',
  domain: '',
};

export const initialState: AppState = {
  recommendations: [],
  recommendationsLoading: true,
  recommendationsError: false,
  targets: [],
  targetsError: false,
  targetsLoading: true,
  target: initialTarget,
  targetLoading: true,
  targetError: false,
  targetStats: { number_of_targets_total: 0, number_of_targets_completed: 0 },
  targetStatsLoading: true,
  targetStatsError: false,

  formsLoading: true,
  formsError: false,
  selectedSubsection: { id: null, position: null, subsection: null },
  formError: false,
  selectedDate: null,

  companyInfo: {
    annual_income: 0,
    reporting_freq: 'M',
    country: 'PL',
    name: 'Your Company Name',
    number_of_employees: 0,
    parent: null,

    reportingCurrency: 'PLN',
    industry: undefined,
    adminEmail: undefined,
    numberOfEmployees: null,
    incomeInReportingPeriod: null,
    organizationBoundaries: null,
    companyPeriodDataError: false,
  },
  companyInfoLoading: true,
  companyInfoError: false,

  employeeSurveyLink: null,

  targetEmission: [],
  targetEmissionLoading: true,
  targetEmissionError: false,
  taskList: [],
  taskListLoading: true,
  taskListError: false,

  tags: [],
  tagsLoading: true,
  tagsError: false,

  haveAccess: undefined,
};

export const createState = (state: Partial<AppState>) => ({
  app: { ...initialState, ...state },
});

export const targetsSlice = createSlice({
  name: 'target',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTargets.fulfilled, (state, action) => {
        state.targets = action.payload;
        state.targetsLoading = false;
        state.targetsError = false;
      })
      .addCase(getTargets.rejected, (state) => {
        state.targetsLoading = false;
        state.targetsError = true;
      })
      .addCase(getTargets.pending, (state) => {
        state.targetsLoading = true;
        state.targetsError = false;
      })
      .addCase(getTarget.rejected, (state, action) => {
        state.target = initialTarget;
        state.targetLoading = false;
        state.targetError = action.error;
      })
      .addCase(getTarget.fulfilled, (state, action) => {
        state.target = action.payload.organization_reduction_target;
        state.targetLoading = false;
        state.targetError = false;
      })

      .addCase(getTarget.pending, (state) => {
        state.targetLoading = true;
        state.targetError = false;
      })

      .addCase(setSelectedDate.fulfilled, (state, action) => {
        state.selectedDate = action.payload;
      })

      .addCase(getOrganization.fulfilled, (state, action) => {
        state.companyInfoLoading = false;
        state.companyInfoError = false;
        const data = action.payload;

        if (data && data.co2) {
          state.companyInfo.name = data.name;
          state.companyInfo.adminEmail = data.admin_email;
          state.companyInfo.industry = data.co2!.industry;
          state.companyInfo.reporting_freq = data.co2!
            .reporting_freq as ReportingFreq;
          state.companyInfo.reportingCurrency = data.co2!.reporting_currency;
          state.companyInfo.country = data.country;
        }
        if (!!data.co2 || !!data.lca || !!data.esg) {
          state.haveAccess = true;
        } else {
          state.haveAccess = false;
        }
      })
      .addCase(getOrganization.rejected, (state) => {
        state.companyInfo = initialState.companyInfo;
        state.companyInfoLoading = false;
        state.companyInfoError = true;
        state.employeeSurveyLink = initialState.employeeSurveyLink;
      })
      .addCase(getOrganization.pending, (state) => {
        state.companyInfoLoading = true;
        state.companyInfoError = false;
      })
      .addCase(setHaveAccess.fulfilled, (state, action) => {
        state.haveAccess = action.payload;
      })
      .addCase(getRecommendations.fulfilled, (state, action) => {
        state.recommendations = action.payload;
        state.recommendationsLoading = false;
        state.recommendationsError = false;
      })
      .addCase(getRecommendations.rejected, (state) => {
        state.recommendationsLoading = false;
        state.recommendationsError = true;
      })
      .addCase(getRecommendations.pending, (state) => {
        state.recommendationsLoading = true;
        state.recommendationsError = false;
      })
      .addCase(getStatsTargetTotal.fulfilled, (state, action) => {
        state.targetStats.number_of_targets_total = action.payload;
        state.targetStatsLoading = false;
        state.targetStatsError = false;
      })
      .addCase(getStatsTargetTotal.rejected, (state) => {
        state.targetStatsLoading = false;
        state.targetStatsError = true;
      })
      .addCase(getStatsTargetTotal.pending, (state) => {
        state.targetStatsLoading = true;
        state.targetStatsError = false;
      })
      .addCase(getStatsTargetCompleted.fulfilled, (state, action) => {
        state.targetStats.number_of_targets_completed = action.payload;
        state.targetStatsLoading = false;
        state.targetStatsError = false;
      })
      .addCase(getStatsTargetCompleted.rejected, (state) => {
        state.targetStatsLoading = false;
        state.targetStatsError = true;
      })
      .addCase(getStatsTargetCompleted.pending, (state) => {
        state.targetStatsLoading = true;
        state.targetStatsError = false;
      })
      .addCase(getTargetEmission.fulfilled, (state, action) => {
        state.targetEmission = action.payload;
        state.targetEmissionLoading = false;
        state.targetEmissionError = false;
      })
      .addCase(getTargetEmission.rejected, (state) => {
        state.targetEmissionLoading = false;
        state.targetEmissionError = true;
      })
      .addCase(getTargetEmission.pending, (state) => {
        state.targetEmissionLoading = true;
        state.targetEmissionError = false;
      })
      .addCase(getTaskList.fulfilled, (state, action) => {
        state.taskList = action.payload;
        state.taskListLoading = false;
        state.taskListError = false;
      })
      .addCase(getTaskList.pending, (state) => {
        state.taskListError = false;
        state.taskListLoading = true;
      })
      .addCase(getTaskList.rejected, (state) => {
        state.taskListError = true;
        state.taskListLoading = false;
      })
      .addCase(postTaskList.fulfilled, (state, action) => {
        state.taskList = [...state.taskList, action.payload];
        state.taskListLoading = false;
        state.taskListError = false;
      })
      .addCase(postTaskList.rejected, (state) => {
        state.taskListError = true;
        state.taskListLoading = false;
      })
      .addCase(patchTaskList.fulfilled, (state, action) => {
        const changedTask = action.payload;
        state.taskList = state.taskList.map((task) =>
          task.id === changedTask.id ? changedTask : task
        );
        state.taskListLoading = false;
        state.taskListError = false;
      })
      .addCase(patchTaskList.rejected, (state) => {
        state.taskListError = true;
        state.taskListLoading = false;
      })
      .addCase(deleteTaskList.fulfilled, (state, action) => {
        const deletedTaskId = action.payload.id;
        state.taskList = state.taskList.filter(
          (task) => task.id !== deletedTaskId
        );
        state.taskListLoading = false;
        state.taskListError = false;
      })
      .addCase(deleteTaskList.rejected, (state) => {
        state.taskListError = true;
        state.taskListLoading = false;
      })

      .addCase(getAboutCompanyPeriodData.fulfilled, (state, action) => {
        state.companyInfo.companyPeriodDataError = false;
        state.companyInfo.numberOfEmployees =
          action.payload.number_of_employees;
        state.companyInfo.incomeInReportingPeriod =
          action.payload.income_in_reporting_period;
        state.companyInfo.organizationBoundaries =
          action.payload.organization_boundaries;
      })
      .addCase(getAboutCompanyPeriodData.rejected, (state) => {
        state.companyInfo.companyPeriodDataError = true;
        state.companyInfo.numberOfEmployees = null;
        state.companyInfo.incomeInReportingPeriod = null;
        state.companyInfo.organizationBoundaries = null;
      })
      .addCase(putAboutCompanyPeriodData.rejected, (state) => {
        state.companyInfo.companyPeriodDataError = true;
      });
  },
});

export default targetsSlice.reducer;
