import React from 'react';
import PageLoading from '../../../../components/PageLoading';
import SomethingWentWrong from '../../../../components/SomethingWentWrong';
import { PreviewBox } from '../../Questionnaire/index.styles';
import {
  Row,
  StyledTh,
  Table,
} from '../../../../components/PreviewTable/index.styles';
import { FormType, LastEdit } from './index.styles';
import IconSvg from '../../../components/IconSvg';
import { CompleteIcon } from './icon';
import { getIndicatorsData } from './query';
import { Checkbox, IconButton } from '@mui/material';
import { StyledTooltip } from '../../../components/StyledTooltip';
import {
  useEsgOrganization,
  useLanguage,
  useRequiredSelector,
} from 'src/common/hooks';
import { useNavigate } from 'react-router-dom';
import { client } from 'src/utils/api-client';
import { useQueryClient } from 'react-query';

export interface Item {
  esg_report: {
    id: number;
    name: string;
    period: string;
    last_update: string;
    categories: [];
  };
  esg_questionnaire: {
    key: string;
    title: string;
    mandatory: boolean;
    impact: boolean;
    selected: boolean;
  };
  esg_indicator_category: {
    key: string;
    name: string;
    color: string;
  };
  esg_questionnaire_answer?: {
    id: number;
    last_update: string;
    completed: boolean;
    is_selected: boolean;
  };
}

export default function Indicators() {
  const { t, language } = useLanguage();
  const report = useRequiredSelector((state) => state.esg.activeReport);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = getIndicatorsData(
    report.period,
    String(report.id)
  );

  const organization = useEsgOrganization();
  const canEdit = organization.can_write_data;

  const handleIsSelectedClick = (el: Item) =>
    client
      .put(
        `/web/esg_v2/${organization.id}/${report.id}/indicators?esg_questionnaire_key=${el.esg_questionnaire.key}`,
        {
          is_selected: !el.esg_questionnaire_answer?.is_selected,
        }
      )
      .then(() =>
        queryClient.invalidateQueries([
          'indicators',
          organization.id,
          report.period,
          language,
        ])
      );

  if (isLoading) {
    return <PageLoading />;
  }

  if (isError) {
    return <SomethingWentWrong />;
  }

  const colors = data?.data.map((el: Item) => el.esg_indicator_category.color);

  return (
    <PreviewBox
      style={{
        marginBottom: '80px',
        paddingBottom: '24px',
        overflowY: 'scroll',
      }}
    >
      <Table
        colors={colors}
        cellSpacing="0"
        cellPadding="0"
        style={{
          width: '100%',
          borderCollapse: 'collapse',
        }}
      >
        <tbody>
          <Row>
            <StyledTh color="#38414F" style={{ minWidth: 520 }}>
              {t('esg.indicator')}
            </StyledTh>
            <StyledTh color="#38414F">{t('esg.mandatory')}</StyledTh>
            <StyledTh color="#38414F">{t('esg.impact')}</StyledTh>
            <StyledTh color="#38414F">{t('esg.select')}</StyledTh>
            <StyledTh color="#38414F">{t('esg.edit')}</StyledTh>
          </Row>

          {data?.data.map((el: Item) => (
            <Row key={el.esg_questionnaire.key}>
              <td>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '-5px 0',
                    position: 'relative',
                    minHeight: '80px',
                    alignItems: 'center',
                    gap: 20,
                  }}
                >
                  <div style={{ display: 'flex', flexGrow: 1 }}>
                    {!!el.esg_questionnaire_answer?.last_update ? (
                      <LastEdit>
                        {t('esg.last-update')}{' '}
                        {new Date(
                          el.esg_questionnaire_answer.last_update
                        ).toLocaleDateString()}
                      </LastEdit>
                    ) : (
                      <LastEdit>{t('esg.no-data')}</LastEdit>
                    )}
                    <span
                      style={{
                        padding: '12px 0',
                        wordBreak: 'break-word',
                        fontWeight: !!el.esg_questionnaire_answer?.last_update
                          ? 400
                          : 700,
                      }}
                    >
                      {el.esg_questionnaire.title}
                    </span>
                  </div>
                  <FormType bgc={el.esg_indicator_category.color}>
                    {el.esg_indicator_category.name}
                  </FormType>
                </div>
              </td>
              <td>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {el.esg_questionnaire.mandatory ? (
                    <IconSvg name="check" />
                  ) : null}
                </div>
              </td>

              {/* impact */}
              <td>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {el.esg_questionnaire.impact ? (
                    <IconSvg name="check" />
                  ) : null}
                </div>
              </td>

              {/* select */}
              <td>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Checkbox
                    checked={el.esg_questionnaire_answer?.is_selected}
                    disabled={!canEdit}
                    onClick={() => handleIsSelectedClick(el)}
                  />
                </div>
              </td>

              <td>
                <div style={{ position: 'relative' }}>
                  {el.esg_questionnaire_answer &&
                    el.esg_questionnaire_answer.completed && (
                      <span
                        style={{
                          width: 16,
                          height: 16,
                          position: 'absolute',
                          top: -8,
                          right: -4,
                        }}
                      >
                        <CompleteIcon />
                      </span>
                    )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <StyledTooltip
                      title={t('sold-products.preview.options.edit')}
                    >
                      <IconButton
                        disabled={canEdit !== true}
                        onClick={() =>
                          navigate(
                            `../questionnaires/${el.esg_questionnaire.key}`
                          )
                        }
                      >
                        <IconSvg name="edit" />
                      </IconButton>
                    </StyledTooltip>
                  </div>
                </div>
              </td>
            </Row>
          ))}
        </tbody>
      </Table>
    </PreviewBox>
  );
}
