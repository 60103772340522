import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { updateSidebarState } from '../../../redux-file/platform/slice';
import { SidebarState } from './index.types';

// eslint-disable-next-line import/prefer-default-export
export function useSidebar() {
  const state = useAppSelector((state) => state.platform.sidebarState);
  const dispatch = useAppDispatch();

  const update = React.useCallback((newState: Partial<SidebarState>) => {
    dispatch(updateSidebarState(newState));
  }, []);

  const toggleByUser = React.useCallback(() => {
    if (state.isControllable) {
      update({ isOpen: !state.isOpen });
    }
  }, [state]);

  return { isOpen: state.isOpen, isShown: state.isShown, update, toggleByUser };
}
