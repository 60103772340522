import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DmaStepNavigateTo as Props } from './index.types';
import { IconButton } from '@mui/material';
import LightTooltip from 'src/components/LightTooltip';
import { useTranslation } from 'react-i18next';

export default function DmaStepNavigateTo({ icon, navigateTo }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <LightTooltip title={t('esg.dma.step.navigateToButton.toggle')}>
      <IconButton onClick={() => navigate(navigateTo)}>{icon}</IconButton>
    </LightTooltip>
  );
}
