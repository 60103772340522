import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LcaDiagramState } from './interfaces';
import { LcaAreaOfImpactSchema } from 'src/Lca/types';

export const initialState: LcaDiagramState = {};

export const lcaDiagramSlice = createSlice({
  name: 'lca',
  initialState,
  reducers: {
    setSelectedAreaOfImpact: (
      state,
      action: PayloadAction<LcaAreaOfImpactSchema>
    ) => {
      state.selectedAreaOfImpact = action.payload;
    },
  },
});

export const { setSelectedAreaOfImpact } = lcaDiagramSlice.actions;

export default lcaDiagramSlice.reducer;
