import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

export default function NoData({ errorMessage }: { errorMessage?: string }) {
  const { t } = useTranslation();
  return (
    <Typography sx={{ width: '100%', textAlign: 'center', p: '50px 0px' }}>
      {errorMessage ?? t('common.noData')}
    </Typography>
  );
}
