import { ReportingFreq } from 'src/utils/api.interfaces';
import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';

export interface RegistrationSchema {
  token: string;
  email: string;
  password: string;
  organization_name: string;
  country: string;
  reporting_frequency: ReportingFreq;
}

export type FormErrors = SchemaErrors<RegistrationSchema>;

export const validateForm = (data: RegistrationSchema): FormErrors => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const builder = new ErrorBuilder(data)
    .checkRule(
      ['email'],
      (value) => emailRegex.test(value),
      'registration.emailError'
    )
    .checkTruthy(
      ['password', 'organization_name', 'country', 'reporting_frequency'],
      'validation.required'
    );
  return builder.build();
};
