import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EsgState } from './slice.types';
import { EsgReportSchemaOut } from '../../Esg/api.types';

const initialState: EsgState = {};

const esgSlice = createSlice({
  name: 'esg',
  initialState,
  reducers: {
    setActiveReport: (
      state,
      action: PayloadAction<EsgReportSchemaOut | undefined>
    ) => {
      state.activeReport = action.payload;
    },
  },
});

export const { setActiveReport } = esgSlice.actions;
export default esgSlice.reducer;
