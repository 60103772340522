import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  margin-right: 16px;
  color: var(--Dark, #2f2f2f);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.12px;

  a {
    color: var(--Dark, #2f2f2f);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.12px;
    text-decoration: none;
  }

  &:after {
    right: -13px;
    display: block;
    content: '>';
    position: absolute;
  }

  &:last-child {
    color: var(--Dark-grey, #4d4d4d);
    a {
      color: var(--Dark-grey, #4d4d4d);
    }
    &:after {
      display: none;
    }
  }
`;
