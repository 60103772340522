import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { IconButton } from '@mui/material';

export default function Contact() {
  return (
    <IconButton
      onClick={() => {
        window.location.href = 'mailto:contact@envirly.com';
      }}
    >
      <MailOutlineIcon sx={{ fontSize: '24px' }} />
    </IconButton>
  );
}
