import React, { useContext } from 'react';
import { StyledPanel, StyledTitle } from '../../index.styles';
import { useQuery } from 'react-query';
import { client } from '../../../utils/api-client';
import { UnitFormValidationContext } from './unit-utils';
import { mapError } from '../../../utils/validation';
import TextField from 'src/components/TextField';
import Autocomplete from 'src/components/Autocomplete';
import { useLanguage, useSupportedCountries } from 'src/common/hooks';

type Country = {
  name: string;
  alpha3: string;
};

export type LocationState = {
  city: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  country: string | null;
  setCountry: (country: string | null) => void;
};

export const useLocation = (): LocationState => {
  const [country, setCountry] = React.useState<string | null>(null);
  const [city, setCity] = React.useState<string>('');
  return { country, setCountry, city, setCity };
};

type Props = LocationState & {};

export default function Location(props: Props) {
  const { t } = useLanguage();
  const { country, setCountry, city, setCity } = props;

  const errors = useContext(UnitFormValidationContext);

  const { data: countries } = useSupportedCountries();

  return (
    <div>
      <StyledTitle sx={{ fontSize: '28px', mt: '40px' }}>
        {t('superadmin.components.panels.unit.location.title')}
      </StyledTitle>

      <StyledPanel sx={{ mt: '32px', p: '32px' }}>
        <Autocomplete
          label={t('superadmin.components.panels.unit.location.country.label')}
          placeholder={t(
            'superadmin.components.panels.unit.location.country.placeholder'
          )}
          options={countries?.map((country: Country) => ({
            value: country.alpha3,
            label: `${country.name} (${country.alpha3})`,
          }))}
          value={country}
          onChange={setCountry}
          sx={{ mb: '24px' }}
          errorText={mapError(errors, 'country', country)}
        />
        <TextField
          fullWidth
          value={city}
          onChange={(e) => setCity(e.target.value)}
          label={t('superadmin.components.panels.unit.location.city.label')}
          placeholder={t(
            'superadmin.components.panels.unit.location.city.placeholder'
          )}
          errorText={mapError(errors, 'city', city)}
        />
      </StyledPanel>
    </div>
  );
}
