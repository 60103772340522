import React, { ReactNode } from 'react';
import { styled as styledMUI } from '@mui/material/styles';
import { IconButton } from '@mui/material';

export const Container = styledMUI('div')`
  border-radius: 16px;
  border: 1px solid var(--light-grey, #CCC);
  background: var(--white, #FFF); 
`;

export const HeaderColumn = styledMUI('div')`
  color: var(--grey, #888);
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.45px;
  text-transform: uppercase; 
  width: 5%;
  min-width: 100px;
text-align: left;
`;

export const Header = styledMUI('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  border-bottom: 1px solid #e0e0e0;
`;

export const YesNoHeader = styledMUI(HeaderColumn)`
  text-align: right;
`;

export const Row = styledMUI('div')`
  position: relative;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding: 10px 30px;
`;

export const Text = styledMUI('span')`
  color: var(--main-dark-main, #38414f);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.3px;
  width: 5%;
  min-width: 100px;
  text-align: left;
`;

export const YesNoText = styledMUI(Text)`
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

export const ExpandButton = styledMUI(IconButton)`
  position:absolute;
  top: 2px;
  left: 0px;
`;

export const MultiAnswer = styledMUI(Text)`
padding-left: 20px;
font-weight: 400;
letter-spacing: 0.3px; 
`;

export const StyledLi = styledMUI('li')`
color: #E9BC46;
`;

interface LiProps {
  children: ReactNode | ReactNode[];
}

export const Li = (props: LiProps) => (
  <StyledLi>
    <span style={{ color: '#38414f' }}>{props.children}</span>
  </StyledLi>
);

export const CollapseContainer = styledMUI('div')`
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
`;
