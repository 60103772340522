import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import shevronLeft from '../../../images/shevron-left-green.png';
import {
  BackButton,
  ButtonContainer,
  Container,
  Description,
  LoginButton,
  StyledAlert,
} from './index.styles';
import OnboardingHeading from '../../components/OnboardingHeading';
import PageLoading from '../../../components/PageLoading';
import { InputPassword } from '../Login/index.styles';
import { useAppSelector } from '../../../redux-file/hooks';
import { sendPasswordResetEmail } from '../../../utils/auth';

export default function PasswordRecovery() {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const isLoggedIn = useAppSelector((state) => state.platform.isAuthenticated);
  const { t } = useTranslation();

  const handleSubmit = () => {
    setError('');

    sendPasswordResetEmail({ email })
      .then(() => {
        navigate('/password/success', { state: { email } });
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  if (isLoggedIn) return <Navigate to="/" />;
  if (isLoggedIn === undefined) return <PageLoading />;

  return (
    <Container>
      <OnboardingHeading text={t('login.password-recovery-link')}>
        <Description>{t('login.description')}</Description>
        <form onSubmit={handleSubmit}>
          <div>
            <InputPassword
              id="email"
              label={t('login.email')}
              type="email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.currentTarget.value)
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleSubmit();
              }}
              variant="outlined"
              autoFocus
              fullWidth
              required
            />
          </div>

          {error && <StyledAlert severity="error">{error}</StyledAlert>}
        </form>
        <ButtonContainer>
          <BackButton to="/">
            <img src={shevronLeft} alt="arrow left back button" />
            <span>{t('login.back')}</span>
          </BackButton>
          <LoginButton type="submit" onClick={handleSubmit}>
            <span>{t('login.next')}</span>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none">
              <path
                d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z"
                fill="white"
              />
            </svg>
          </LoginButton>
        </ButtonContainer>
      </OnboardingHeading>
    </Container>
  );
}
