import React from 'react';
import {
  Based,
  Button,
  Desc,
  PointText,
  PointWrapper,
  StyledMethodBox,
  Title,
} from './index.styled';
import { StyledFlex } from 'src/components/StyledFlex';

interface MethodBoxIP {
  active: boolean;
  title: string;
  based: string;
  desc: string;
  point1?: string;
  point2?: string;
  buttonText?: string;
  buttonAction?: Function;
}

export default function MethodBox({
  active,
  based,
  title,
  buttonAction,
  buttonText,
  desc,
  point1,
  point2,
}: MethodBoxIP) {
  return (
    <StyledMethodBox>
      <StyledFlex>
        <div>
          <Title>{title}</Title>
          <Based>{based}</Based>
        </div>
      </StyledFlex>
      <Desc active={active}>{desc}</Desc>
      {point1 && (
        <PointWrapper active={active}>
          <svg
            width="24"
            height="19"
            viewBox="0 0 24 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.85492 17.2674C6.31412 18.7117 8.6713 18.7117 10.1305 17.2859L23.226 4.4534C24.2549 3.45347 24.2549 1.82395 23.2447 0.824019L23.1698 0.74995C22.1596 -0.249983 20.532 -0.249983 19.5218 0.74995L7.49271 12.6566L4.40593 9.6012C3.43313 8.63831 1.82426 8.60127 0.814039 9.56417C-0.252304 10.5456 -0.271012 12.1936 0.757915 13.2121L4.85492 17.2674Z"
              fill="currentColor"
            />
          </svg>

          <PointText active={active}>{point1}</PointText>
        </PointWrapper>
      )}
      {point2 && (
        <PointWrapper active={active}>
          <svg
            width="24"
            height="19"
            viewBox="0 0 24 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.85492 17.2674C6.31412 18.7117 8.6713 18.7117 10.1305 17.2859L23.226 4.4534C24.2549 3.45347 24.2549 1.82395 23.2447 0.824019L23.1698 0.74995C22.1596 -0.249983 20.532 -0.249983 19.5218 0.74995L7.49271 12.6566L4.40593 9.6012C3.43313 8.63831 1.82426 8.60127 0.814039 9.56417C-0.252304 10.5456 -0.271012 12.1936 0.757915 13.2121L4.85492 17.2674Z"
              fill="currentColor"
            />
          </svg>

          <PointText active={active}>{point2}</PointText>
        </PointWrapper>
      )}

      <br />
      {buttonAction && (
        <Button active={active} type="button" onClick={() => buttonAction()}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1009_16834)">
              <path
                d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_1009_16834">
                <rect width="24" height="24" fill="currentColor" />
              </clipPath>
            </defs>
          </svg>
          {buttonText}
        </Button>
      )}
    </StyledMethodBox>
  );
}
