import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledFlex } from 'src/components/StyledFlex';

export const StyledCell = styled(Typography)`
  color: #38414f;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  font-family: Poppins;
  padding: 17px;
  width: 20%;
  height: 70px;
`;

export const StyledHeader = styled(StyledCell)`
  font-weight: 700;
`;

export const StyledRow = styled(StyledFlex)`
  width: 100%;
  border-bottom: 1px solid #b6bbb9;
  height: 72px;
`;

export const StyledRowSection = styled(Typography)`
  color: #38414f;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;
