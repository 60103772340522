import jwtDecode from 'jwt-decode';

const JWT_SKEW_KEY = 'jwtTimestampSkew';

export class AuthError extends Error {
  constructor(
    message: string,
    public isTransient: boolean = false // whether the error is transient and can be retried
  ) {
    super(message);
  }
}

export type TokenPayloadBase = {
  iat: number;
  exp: number;
};

export const getLocalTimestamp = () => {
  return Date.now() / 1000;
};

const getTimestampSkew = () => {
  return parseInt(localStorage.getItem(JWT_SKEW_KEY) ?? '0', 10);
};

const updateTimestampSkew = (
  remoteTimestamp: number,
  localTimestamp?: number
) => {
  const skew = remoteTimestamp - (localTimestamp ?? getLocalTimestamp());
  console.info('Updated timestamp skew:', skew, 'seconds');
  localStorage.setItem(JWT_SKEW_KEY, skew.toString());
  return skew;
};

/**
 * Get the current remote timestamp in seconds
 */
export const getRemoteTimestamp = () => {
  return getLocalTimestamp() + getTimestampSkew();
};

export const checkTokenExp = (token: TokenPayloadBase) => {
  return token.exp > getRemoteTimestamp();
};

type TokenCheckOptions = {
  ignoreExp?: boolean;
};

export const decodeToken = <T extends TokenPayloadBase>(
  token: string | null | undefined,
  options: TokenCheckOptions = {}
): T | null => {
  if (!token) return null;
  try {
    const payload = jwtDecode<T>(token);
    // make sure iat is present, we need it to calculate skew
    if (payload.iat && (options.ignoreExp || checkTokenExp(payload)))
      return payload;
  } catch (e) {
    // ignore errors
  }
  return null;
};

export const assertTokenValid = (token: string | null | undefined) => {
  if (!decodeToken(token)) throw new AuthError('Token is invalid');
};

/**
 * Try to update timestamp skew from a freshly issued JWT token
 * @param token a fresh token issued by the server
 * @returns whether the skew was updated successfully
 * @see updateTimestampSkew
 */
export const tryUpdateTimestampSkewFromFreshToken = (token: string) => {
  try {
    const payload = decodeToken(token, {
      ignoreExp: true,
    });
    if (payload) {
      updateTimestampSkew(payload.iat);
      return true;
    }
  } catch (e) {
    // ignore errors
    console.warn('Failed to update timestamp skew', e);
  }
  return false;
};
