import React from 'react';

import { ButtonArea as Props } from './index.types';
import { StyledButtonArea, StyledButtonAreaHeader } from './index.styles';

export default function ButtonArea({
  header,
  sx = {},
  contentWrapperStyles,
  ...props
}: Props) {
  return (
    <StyledButtonArea sx={sx}>
      {header && (
        <StyledButtonAreaHeader>{header.toUpperCase()}</StyledButtonAreaHeader>
      )}
      <div style={{ display: 'flex', ...contentWrapperStyles }}>
        {props.children}
      </div>
    </StyledButtonArea>
  );
}
