import React, { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import shevronLeft from '../../../images/shevron-left-green.png';
import {
  BackButton,
  ButtonContainer,
  Container,
  Description,
  LoginButton,
  SendAgain,
} from './index.styles';
import OnboardingHeading from '../../components/OnboardingHeading';
import { useAppSelector } from '../../../redux-file/hooks';
import PageLoading from '../../../components/PageLoading';

export interface LocationParams {
  pathname: string;
  state: { email: string };
  search: string;
  hash: string;
  key: string;
}

export default function PasswordSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = (location as LocationParams).state;

  const isLoggedIn = useAppSelector((state) => state.platform.isAuthenticated);
  const { t } = useTranslation();

  const handleSubmit = () => {
    navigate('/password/');
  };

  const handleRedirect = () => navigate('/');

  if (isLoggedIn) return <Navigate to="/" />;
  if (isLoggedIn === undefined) return <PageLoading />;

  return (
    <Container>
      <OnboardingHeading text={t('login.success')}>
        <Description
          dangerouslySetInnerHTML={{
            __html: t('login.success-description', {
              email: email || '',
            }),
          }}
        />

        <SendAgain>
          <p>{t('login.didnt-arrive')}</p>
          <button type="button" onClick={handleSubmit}>
            {t('login.send-again')}
          </button>
        </SendAgain>
        <ButtonContainer>
          <BackButton to="/">
            <img src={shevronLeft} alt="arrow left back button" />
            <span>{t('login.back')}</span>
          </BackButton>
          <LoginButton type="submit" onClick={handleRedirect}>
            <span>{t('login.go-to-login')}</span>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none">
              <path
                d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z"
                fill="white"
              />
            </svg>
          </LoginButton>
        </ButtonContainer>
      </OnboardingHeading>
    </Container>
  );
}
