import { Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledFlex } from 'src/components/StyledFlex';
import { StyledPanel } from 'src/components/StyledPanel';
import { useProductionProcesses } from '../hooks';
import { toast } from 'react-toastify';
import ProductionProcessInfoPanel from '../ProductionProcess/Form/components/ProductionProcessInfoPanel';

export default function ProductionProcesses() {
  const navigate = useNavigate();
  const productionProcesses = useProductionProcesses();

  const toggleSurvey = (id: number, enabled: boolean) => {
    productionProcesses.updateSurvey.mutateAsync({ id, is_active: enabled });
  };

  const getSurveyUrl = (token: string | null) => {
    if (!token) return '';
    return `${window.location.origin}/production-process-survey/${token}`;
  };

  const copySurveyUrl = (token: string | null) => {
    if (!token) return;
    navigator.clipboard.writeText(getSurveyUrl(token));
    toast.success('Survey link copied to clipboard');
  };

  return (
    <Box>
      <StyledFlex>
        <Typography variant="h1">Production Processes</Typography>
        <Button onClick={() => navigate('create')}>Add process</Button>
      </StyledFlex>
      <br />
      <br />
      <ProductionProcessInfoPanel />
      <br />
      <br />
      <StyledPanel>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Production process</TableCell>
              <TableCell>Good type</TableCell>
              <TableCell>Survey active</TableCell>
              <TableCell>Survey link</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productionProcesses.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.aggregated_goods_category}</TableCell>
                <TableCell>
                  <Switch
                    color="secondary"
                    onChange={(_, checked) => toggleSurvey(row.id, checked)}
                    checked={row.survey.is_active}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    disabled={!row.survey.is_active}
                    onClick={() => copySurveyUrl(row.survey.token)}
                  >
                    Copy link
                  </Button>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => navigate(String(row.id))}>
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledPanel>
    </Box>
  );
}
