import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled as styledMUI } from '@mui/material/styles';
import styled from 'styled-components';
import themeColors from '../../../../utils/theme';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end !important;
  background: ${themeColors.pureWhite};
  border: 1px solid ${themeColors.strokePressed};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  padding: 24px;
  width: 456px;
  color: ${themeColors.grayIcons};
`;

export const BoxBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 48px;
  margin: 0;
`;

export const EntryBox = styled.div`
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
  margin: 0;
  height: 56px;
  width: 404px;
  width: 100%;
  background: #f2f2f2;
`;

export const EntryButton = styled.button`
  display: flex;
  justiify-content: center;
  align-items: center;
  margin: 0;
  position: relative;
  background: transparent;
  margin-left: -57px;
  height: 100%;
  z-index: 113;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  width: 85%;
  letter-spacing: 0.02em;
  height: 100%;
  color: ${themeColors.dark};
`;

export const CommentContent = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
`;

export const Modal = styled.div`
  position: absolute;
  bottom: 72px;
  right: 188px;
  z-index: 112;
`;

export const Button = styled.button`
  z-index: 112;
  position: relative;
  background: transparent;
  height: 48px;
  width: 48px;
  padding: 0;
  border-radius: 50%;

  :hover {
    background-color: ${themeColors.hoverButton};
  }
`;

export const StyledTextField = styledMUI(TextField)<TextFieldProps>(
  (props) => `
  width: 100%;
  border-color: #B6BBB9;
  border-radius: 1px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  background-color: ${props.value ? '#F2F2F2' : 'white'};
  & :hover {
    border-color: #2F2F2F;
  },
  & .MuiOutlinedInput-root {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    &.Mui-focused fieldset {
      border-color: #2F2F2F;
      font-size: 16px;
      font-weight: 700;
    },
  },
  & label.Mui-focused {
    color: #2F2F2F;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
  },
  & label {
    background-color: ${props.value ? 'white' : 'transparent'};
    padding-right: 7px;
    padding-left: 7px;
    margin-left: -4px;
    font-family: 'Poppins', sans-serif;
  },
  input {
    color: #132C1D;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
  },
`
);

export const StyledEntryTextField = styledMUI(TextField)<TextFieldProps>(
  (props) => `
  width: 100%;
  border-color: #B6BBB9;
  border-radius: 1px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  background-color: ${props.value ? '#F2F2F2' : 'white'};

  & :hover {
    border-color: #2F2F2F;
  },
  & .MuiOutlinedInput-root {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    &.Mui-focused fieldset {
      border-color: #2F2F2F;
      font-size: 16px;
      font-weight: 700;
    },
  },
  & label.Mui-focused {
    color: #2F2F2F;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
  },
  & label {
    background-color: ${props.value ? 'white' : 'transparent'};
    padding-right: 7px;
    padding-left: 7px;
    margin-left: -4px;
    font-family: 'Poppins', sans-serif;
  },
  input {
    padding-right: 50px;
    color: #132C1D;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
  },
`
);
