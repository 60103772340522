import React from 'react';

export default function TargetsIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6.39996C4.45 6.39996 5.26 7.86615 4.93 8.9556L8.48 12.5803C8.78 12.4887 9.22 12.4887 9.52 12.5803L12.07 9.98396C11.73 8.89451 12.54 7.41815 14 7.41815C15.45 7.41815 16.27 8.88433 15.93 9.98396L20.49 14.6167C21.56 14.2807 23 15.1054 23 16.5818C23 17.7018 22.1 18.6181 21 18.6181C19.55 18.6181 18.74 17.152 19.07 16.0625L14.52 11.4196C14.22 11.5112 13.78 11.5112 13.48 11.4196L10.93 14.016C11.27 15.1054 10.46 16.5818 9 16.5818C7.55 16.5818 6.73 15.1156 7.07 14.016L3.51 10.4014C2.44 10.7374 1 9.91269 1 8.43633C1 7.31633 1.9 6.39996 3 6.39996Z"
        fill="currentColor"
      />
    </svg>
  );
}
