import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

/* eslint-disable no-unused-vars */

export const Text = styled.p`
  color: #388276;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 40px;

  span {
    font-size: 54px;
    font-weight: 700;
    margin: 0 4px;
  }
`;

export const ProgressBar = styled(
  ({
    length,
    ...props
  }: { length: number } & HTMLAttributes<HTMLDivElement>) => <div {...props} />
)`
  background: #d9dfdd;
  border-radius: 4px;
  height: 8px;
  width: 100%;
  overflow: hidden;
  position: relative;

  div {
    background-color: #388276;
    height: 8px;
    width: ${({ length }) => length}%;
    position: absolute;
  }
`;

export const FloatedNumber = styled(
  ({
    length,
    ...props
  }: { length: number } & HTMLAttributes<HTMLDivElement>) => <div {...props} />
)`
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 18px;

  div {
    width: ${({ length }) => length}%;
    position: absolute;
    height: 18px;
  }

  p {
    font-size: 18px;
    color: #388276;
    font-weight: 900;
    text-align: right;
    position: absolute;
    width: ${({ length }) => (length > 95 ? length : 103)}%;
  }
`;
