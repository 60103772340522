import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const EmptyIcon = () => (
  <svg
    width="84"
    height="107"
    viewBox="0 0 84 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.4766 7.83015V103.362C77.4766 105.366 75.8422 107 73.8313 107H4.09264C2.08168 107 0.447266 105.366 0.447266 103.362V7.83015C0.447266 6.15316 1.79322 4.81729 3.45741 4.81729H74.4665C76.1307 4.81729 77.4766 6.15316 77.4766 7.83015Z"
      fill="#9D5724"
    />
    <path
      d="M83.0175 3.01295V98.104C83.0175 100.364 81.1956 102.183 78.9373 102.183H10.0685C7.81165 102.183 5.98828 100.364 5.98828 98.104V3.01295C5.98828 1.35018 7.33409 3.05176e-05 8.99829 3.05176e-05H80.0089C81.6731 3.05176e-05 83.0175 1.35018 83.0175 3.01295Z"
      fill="#EEA774"
    />
    <path
      d="M76.956 16.1455V92.278C76.956 94.5519 75.1184 96.3994 72.846 96.3994H16.1736C13.8869 96.3994 12.0508 94.5519 12.0508 92.278V16.1597C12.0508 14.4969 13.3966 13.1468 15.0594 13.1468H73.9602C75.6244 13.1468 76.956 14.4969 76.956 16.1455Z"
      fill="white"
    />
    <path
      d="M73.0723 8.14252C73.0723 6.93452 74.0473 5.96808 75.2482 5.96808C76.4491 5.96808 77.4239 6.93452 77.4239 8.14252C77.4239 9.33631 76.4491 10.3169 75.2482 10.3169C74.0473 10.3169 73.0723 9.33631 73.0723 8.14252Z"
      fill="#FFF299"
    />
    <path
      d="M66.2031 8.14252C66.2031 6.93452 67.1767 5.96808 68.379 5.96808C69.5799 5.96808 70.5534 6.93452 70.5534 8.14252C70.5534 9.33631 69.5799 10.3169 68.379 10.3169C67.1767 10.3169 66.2031 9.33631 66.2031 8.14252Z"
      fill="#FFF299"
    />
    <path
      d="M59.334 8.14252C59.334 6.93452 60.3074 5.96808 61.5097 5.96808C62.7106 5.96808 63.6843 6.93452 63.6843 8.14252C63.6843 9.33631 62.7106 10.3169 61.5097 10.3169C60.3074 10.3169 59.334 9.33631 59.334 8.14252Z"
      fill="#FFF299"
    />
    <path
      d="M44.5 37C35.944 37 29 43.944 29 52.5C29 61.056 35.944 68 44.5 68C53.056 68 60 61.056 60 52.5C60 43.944 53.056 37 44.5 37ZM50.7 54.05H46.05V58.7C46.05 59.5525 45.3525 60.25 44.5 60.25C43.6475 60.25 42.95 59.5525 42.95 58.7V54.05H38.3C37.4475 54.05 36.75 53.3525 36.75 52.5C36.75 51.6475 37.4475 50.95 38.3 50.95H42.95V46.3C42.95 45.4475 43.6475 44.75 44.5 44.75C45.3525 44.75 46.05 45.4475 46.05 46.3V50.95H50.7C51.5525 50.95 52.25 51.6475 52.25 52.5C52.25 53.3525 51.5525 54.05 50.7 54.05Z"
      fill="#9D5724"
    />
  </svg>
);
