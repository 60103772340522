import React from 'react';
import { useTranslation } from 'react-i18next';
import notFoundImage from '../../../images/404.png';
import {
  Container,
  ImageWrapper,
  StyledLink,
  Text,
  TextWrapper,
  Title,
} from './index.styles';

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <Container>
      <TextWrapper>
        <Title>{t('not-found.title')}</Title>
        <Text>{t('not-found.text')}</Text>
        <StyledLink to="/">
          <span>{t('not-found.button')}</span>
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.52227 0L0 1.41L4.94467 6L0 10.59L1.52227 12L8 6L1.52227 0Z"
              fill="#0CA0A0"
            />
          </svg>
        </StyledLink>
      </TextWrapper>
      <ImageWrapper>
        <img src={notFoundImage} alt="404" />
      </ImageWrapper>
    </Container>
  );
}
