import React from 'react';
import NavigationBlockerModal from 'src/components/NavigationBlockerModal';

export const CounterContext = React.createContext<{
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
}>({
  counter: 0,
  setCounter: () => {},
});

interface Props {
  children: React.ReactNode;
}

export function CounterProvider({ children }: Props) {
  const [counter, setCounter] = React.useState<number>(0);

  const value = React.useMemo(() => ({ counter, setCounter }), [counter]);

  return (
    <CounterContext.Provider value={value}>{children}</CounterContext.Provider>
  );
}

export function NavigationBlockerWithCounter() {
  const { counter } = React.useContext(CounterContext);
  return <NavigationBlockerModal shouldBlock={counter > 0} />;
}

function countChange(
  setModifiedCounter: React.Dispatch<React.SetStateAction<number>>,
  value: boolean | undefined
) {
  if (value === true) {
    setModifiedCounter((prevCounter) => prevCounter + 1);
  } else if (value === false) {
    setModifiedCounter((prevCounter) => prevCounter - 1);
  }
}

export function useChangeTracker() {
  const { counter, setCounter } = React.useContext(CounterContext);
  const [isModified, setIsModified] = React.useState<boolean | undefined>(
    undefined
  );
  const isModifiedRef = React.useRef<boolean | undefined>(isModified);

  React.useEffect(() => {
    isModifiedRef.current = isModified;
    countChange(setCounter, isModified);
  }, [isModified]);

  React.useEffect(() => {
    // Reset change when component unmounts
    return () => {
      if (isModifiedRef.current) countChange(setCounter, false);
      setIsModified(undefined);
    };
  }, []);

  return {
    globalCounter: counter,
    isModified,
    setIsModified,
  };
}
