import {
  LcaDiagramColumns,
  ProductImpactOutSchema,
} from 'src/Lca/LCADiagram/Diagram/index.types';
import { LcaDiagramSchema } from '../types';
import { Column, Row } from './types';

export const getCellSpan = (key: LcaDiagramColumns) => {
  const _default = { rowSpan: 1, colSpan: 1 };
  switch (key) {
    case LcaDiagramColumns.useStage:
      return { rowSpan: 2, colSpan: 1 };
    case LcaDiagramColumns.endOfLife:
      return { rowSpan: 2, colSpan: 1 };
    default:
      return _default;
  }
};

export const prepData = (
  diagramData?: LcaDiagramSchema,
  impactData?: ProductImpactOutSchema[]
) => {
  // Prepares data for the impact grid

  // If no data is available, return empty columns and rows
  if (!diagramData || !impactData) {
    return { columns: [], rows: [] };
  }
  // Create columns from stages (impact-category and summary columns will be added in next steps)
  const columns: Column[] = diagramData.stages.map((stage) => ({
    key: stage.key,
    name: stage.name,
  }));

  // initialize rows
  const rows: Row[] = [];

  for (const impact of impactData) {
    // initialize row with impact category and summary
    const summaryValue = Object.values(impact.emission_per_stage).reduce(
      (acc, num) => acc + num,
      0
    );
    const summaries = getSummaries(impact.emission_per_stage);

    const _row: Row = {
      rawMaterialSummary: summaries.rawMaterialsSummary,
      distributionSummary: summaries.distributionSummary,
      impactCategory: {
        value: impact.area.name,
        subvalue: impact.area.unit_name,
      },
      color: impact.area.color,
      summary: summaryValue,
      columns: {},
    };

    // Add values for each stage (column)
    for (const column of columns) {
      const value = impact.emission_per_stage[column.key];
      _row.columns[column.key] = {
        value: value || 0,
        color: impact.area.color,
        summary: summaries[column.key],
        ...getCellSpan(column.key),
      };
    }
    rows.push(_row);
  }

  return { columns, rows };
};

export const getSummaries = (emissionPerStage: {
  // eslint-disable-next-line no-unused-vars
  [key in LcaDiagramColumns]: number;
}) => {
  const rawMaterialsSummary =
    emissionPerStage[LcaDiagramColumns.rawMaterialsExtractionAndProcessing] +
    emissionPerStage[LcaDiagramColumns.transportOfRawMaterials] +
    emissionPerStage[LcaDiagramColumns.productionAndPackaging];
  const distributionSummary =
    emissionPerStage[LcaDiagramColumns.distributionAndStorage] +
    emissionPerStage[LcaDiagramColumns.installation];

  return {
    [LcaDiagramColumns.rawMaterialsExtractionAndProcessing]:
      rawMaterialsSummary,
    [LcaDiagramColumns.transportOfRawMaterials]: rawMaterialsSummary,
    [LcaDiagramColumns.productionAndPackaging]: rawMaterialsSummary,
    [LcaDiagramColumns.distributionAndStorage]: distributionSummary,
    [LcaDiagramColumns.installation]: distributionSummary,
    [LcaDiagramColumns.useStage]: emissionPerStage[LcaDiagramColumns.useStage],
    [LcaDiagramColumns.endOfLife]:
      emissionPerStage[LcaDiagramColumns.endOfLife],
    rawMaterialsSummary,
    distributionSummary,
  };
};

export const withAlpha = (hex: string, alpha: number = 1) =>
  hex + Math.round(alpha * 255).toString(16);
