import React from 'react';
import Sidebar from 'src/views/components/Sidebar';
import SidebarHeaderWithLogo from 'src/views/components/Sidebar/HeaderWithLogo';
import CbamLogo from 'src/images/svg/cbamLogo';
import LogoMinCbam from 'src/images/svg/logoMinCbam';
import SidebarNavItem from 'src/views/components/Sidebar/NavItem';
import {
  ArticleOutlined,
  BusinessOutlined,
  FactoryOutlined,
  Help,
  HomeOutlined,
  ImportExportOutlined,
  ListOutlined,
  MenuBook,
  TextSnippet,
  ViewAgendaOutlined,
} from '@mui/icons-material';
import { Divider, ListItemIcon, ListItemText } from '@mui/material';
import SidebarControllerItem from 'src/views/components/Sidebar/ControllerItem';

export default function CbamSidebar() {
  return (
    <Sidebar>
      <SidebarHeaderWithLogo
        openLogo={<CbamLogo />}
        closedLogo={<LogoMinCbam />}
      />
      <SidebarNavItem to="/cbam/dashboard">
        <ListItemIcon>
          <HomeOutlined />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </SidebarNavItem>

      <SidebarNavItem to="/cbam/operator" matchTo="/cbam/operator/*">
        <ListItemIcon>
          <BusinessOutlined />
        </ListItemIcon>
        <ListItemText primary="Operators" />
      </SidebarNavItem>

      <SidebarNavItem to="/cbam/installation" matchTo="/cbam/installation/*">
        <ListItemIcon>
          <FactoryOutlined />
        </ListItemIcon>
        <ListItemText primary="Installations" />
      </SidebarNavItem>

      <SidebarNavItem
        to="/cbam/production-process"
        matchTo="/cbam/production-process/*"
      >
        <ListItemIcon>
          <ViewAgendaOutlined />
        </ListItemIcon>
        <ListItemText primary="Production Processes" />
      </SidebarNavItem>

      <SidebarNavItem to="/cbam/actors" matchTo="/cbam/actors/*">
        <ListItemIcon>
          <ImportExportOutlined />
        </ListItemIcon>
        <ListItemText primary="Importers and Representatives" />
      </SidebarNavItem>

      <SidebarNavItem
        to="/cbam/supporting-documents"
        matchTo="/cbam/supporting-documents/*"
      >
        <ListItemIcon>
          <ArticleOutlined />
        </ListItemIcon>
        <ListItemText primary="Supporting Documents" />
      </SidebarNavItem>

      <SidebarNavItem to="/cbam/reports">
        <ListItemIcon>
          <ListOutlined />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </SidebarNavItem>

      <Divider sx={{ mb: '5px' }} />

      <SidebarNavItem to="/cbam/glossary">
        <ListItemIcon>
          <MenuBook />
        </ListItemIcon>
        <ListItemText primary="Glossary" />
      </SidebarNavItem>

      <SidebarNavItem to="/cbam/help-center">
        <ListItemIcon>
          <Help />
        </ListItemIcon>
        <ListItemText primary="Help Center" />
      </SidebarNavItem>

      <SidebarNavItem to="/cbam/regulations">
        <ListItemIcon>
          <TextSnippet />
        </ListItemIcon>
        <ListItemText primary="Regulations & Guidelines" />
      </SidebarNavItem>

      <SidebarControllerItem />
    </Sidebar>
  );
}
