import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Overlay } from '../../Title/index.styles';
import { FormBox, Form } from '../index.styles';
import FormButtons from '../../FormButtons';
import AutocompleteCategories from '../../fields/AutocompleteCategories';
import { fugutive_emissions_choices } from '../data';
import TextFieldNumber from '../../../../../components/TextFieldNumber';

export default function FugutiveFormSoldProducts({
  state,
  setState,
  id,
  active,
  setActive,
  setNewForm,
}: {
  state: any[];
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  setNewForm: Function;
}) {
  const { t } = useTranslation();

  const [
    amount_kg_per_period_error_state,
    set_amount_kg_per_period_error_state,
  ] = useState(false);
  const [emission_type_error_state, set_emission_type_error_state] =
    useState(false);
  const [manual_factor_error_state, set_manual_factor_error_state] =
    useState(false);

  let amount_kg_per_period_error = false;
  let emission_type_error = false;
  let manual_factor_error = false;

  const amount_kg_per_period_validation = (value: string) => {
    if (value === '') amount_kg_per_period_error = true;
    return amount_kg_per_period_error;
  };

  const emission_type_validation = (value: string) => {
    if (value === '') emission_type_error = true;
    return emission_type_error;
  };

  const manual_factor_validation = (value: string) => {
    if (value === '') manual_factor_error = true;
    return manual_factor_error;
  };

  const setAmountPerPeriod = (value: string) => {
    amount_kg_per_period_validation(value);
    set_amount_kg_per_period_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];
    commutingType = {
      manual_factor: commutingType.manual_factor,
      emission_type: commutingType.emission_type,
      amount_kg_per_period: value,
      comment: commutingType.comment,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') {
      set_amount_kg_per_period_error_state(true);
    }
  };

  const setTypeOfFuel = (value: string) => {
    emission_type_validation(value);
    set_emission_type_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      manual_factor: commutingType.manual_factor,
      emission_type: value,
      amount_kg_per_period: commutingType.amount_kg_per_period,
      comment: commutingType.comment,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') {
      set_emission_type_error_state(true);
    }
  };

  const setManualFactor = (value: string) => {
    manual_factor_validation(value);
    set_manual_factor_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      manual_factor: value === '' ? null : value,
      emission_type: commutingType.emission_type,
      amount_kg_per_period: commutingType.amount_kg_per_period,
      comment: commutingType.comment,
    };
    newForms[id] = commutingType;
    setState(newForms);

    if (newForms[id].emission_type === 'other.manual_factor' && value === '') {
      set_manual_factor_error_state(true);
    }
  };

  const defineErrors = async () => {
    if (state[id].amount_kg_per_period === '') {
      set_amount_kg_per_period_error_state(true);
      amount_kg_per_period_error = true;
    }

    if (state[id].emission_type === '') {
      set_emission_type_error_state(true);
      emission_type_error = true;
    }

    if (
      state[id].emission_type === 'other.manual_factor' &&
      !state[id].manual_factor
    ) {
      set_manual_factor_error_state(true);
      manual_factor_error = true;
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    if (tempState.length === 0) {
      setState([
        {
          manual_factor: '',
          emission_type: '',
          amount_kg_per_period: '',
          comment: '',
        },
      ]);

      set_amount_kg_per_period_error_state(false);
      amount_kg_per_period_error = false;

      set_emission_type_error_state(false);
      emission_type_error = false;

      set_manual_factor_error_state(false);
      manual_factor_error = false;
    }

    setActive(undefined);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              if (
                !emission_type_error &&
                !amount_kg_per_period_error &&
                !manual_factor_error
              ) {
                setActive(undefined);
              }
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <FormBox variant="50">
            <TextFieldNumber
              type="mass"
              label={t(
                'direct-emission.fugutive_emissions.amount_kg_per_period'
              )}
              active
              error={amount_kg_per_period_error_state}
              index={1}
              value={
                state[id].amount_kg_per_period === null
                  ? ''
                  : state[id].amount_kg_per_period
              }
              setValue={(e: string) => setAmountPerPeriod(e)}
            />
          </FormBox>
          <FormBox variant="50">
            <AutocompleteCategories
              active
              index={1}
              max={1}
              // @ts-ignore
              options={fugutive_emissions_choices}
              label={t('direct-emission.fugutive_emissions.emission_type')}
              error={emission_type_error_state}
              value={state[id].emission_type}
              setValue={(e: string) => setTypeOfFuel(e)}
            />
          </FormBox>
          {state[id].emission_type === 'other.manual_factor' && (
            <FormBox variant="100">
              <TextFieldNumber
                type="factor"
                label={t('direct-emission.fugutive_emissions.manual_factor')}
                active
                error={manual_factor_error_state}
                index={3}
                // @ts-ignore
                value={
                  state[id].manual_factor === null
                    ? ''
                    : state[id].manual_factor
                }
                setValue={(e: string) => setManualFactor(e)}
              />
            </FormBox>
          )}
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={
              state[id].amount_kg_per_period !== '' &&
              state[id].emission_type !== '' &&
              (state[id].emission_type !== 'other.manual_factor' ||
                !!state[id].manual_factor)
            }
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
            variant="sold-products"
          />
        )}
      </Form>
    </>
  );
}
