import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';

export default function FilterField(props: SelectProps) {
  return (
    <FormControl size="small" fullWidth sx={{ maxWidth: '200px', mr: '20px' }}>
      <InputLabel shrink={false} id="placeholder" sx={{ pl: '8px' }}>
        <Typography variant="mediumButton">{props.placeholder}</Typography>
      </InputLabel>
      <Select
        size="small"
        value="blocked"
        fullWidth
        labelId="placeholder"
        sx={{ borderRadius: '20px' }}
        {...props}
      >
        {props.children}
      </Select>
    </FormControl>
  );
}
