import React from 'react';

import { Box, Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Button from 'src/Lca/components/Button';
import { SubcategoryModal as Props } from './index.types';
import ButtonArea from '../ButtonArea';

import { getUniqueSubcategoryGroups } from '../../helpers';

export default function SubcategoryModal({
  category,
  onSubcategoryClick,
  onClose,
}: Props) {
  const { subcategories } = category;
  const groups = getUniqueSubcategoryGroups(subcategories);

  return (
    <Dialog open={!!category} onClose={onClose} maxWidth="lg">
      <IconButton
        sx={{ position: 'absolute', top: 12, right: 10, zIndex: 99 }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ m: '10px', mb: '20px', width: '900px' }}>
        {groups?.map((group) => {
          const _group = group ? `: ${group}` : '';
          const header = `${category.name}${_group}`;
          const _subcategories = subcategories.filter(
            (sc) => sc.group === group
          );
          return (
            <ButtonArea
              key={header}
              header={header}
              contentWrapperStyles={{ flexWrap: 'wrap', gap: 20 }}
            >
              {_subcategories.map((subcategory) => (
                <Button
                  onClick={() => onSubcategoryClick(subcategory)}
                  key={`${header}-${subcategory.id}`}
                  color={category.key}
                >
                  {subcategory.name}
                </Button>
              ))}
            </ButtonArea>
          );
        })}
      </Box>
    </Dialog>
  );
}
