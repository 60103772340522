import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToLocation({ children }: any) {
  const location = useLocation();
  useLayoutEffect(() => {
    if (!location.pathname.includes('questionnairepage-goods-and-services'))
      document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
}
