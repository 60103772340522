import React from 'react';
import {
  StyledHeader,
  StyledTd,
} from '../../../Pages/Questionnaire/index.styles';
import { companiesColors } from '../../../Pages/SuperDashboard/data';
import { useTranslation } from 'react-i18next';
import CommentBtn from './commentBtn';
import TableButton from '../../TableButton';
import { toLocaleNumber } from '../../../../utils/helpers';

export default function WastePreview({
  state,
  id,
  setActive,
  setState,
  language,
}: {
  state: any[];
  id: number;
  setActive: Function;
  setState: Function;
  language: string;
}) {
  const { t } = useTranslation();
  const handleCopy = () => {
    const tempState = [...state];
    const item = tempState[id];

    tempState.splice(id, 0, item);
    setState(tempState);
    setActive(id);
  };
  return (
    <StyledHeader>
      <StyledTd width={30} color={companiesColors[id]}>
        <p>{toLocaleNumber(state[id].amount_kg, language)}</p>
        <CommentBtn state={state} id={id} />
      </StyledTd>
      <StyledTd width={23} color={companiesColors[id]}>
        {t(`dropdowns.${state[id].waste_type}`)}
      </StyledTd>
      <StyledTd width={23} color={companiesColors[id]}>
        {state[id].recycled_or_composed_flag ? t('yes') : t('no')}
      </StyledTd>
      <StyledTd width={23} color={companiesColors[id]}>
        {state[id].emission_shared
          ? (state[id].emission_shared * 100).toFixed(2)
          : 0}
      </StyledTd>
      <StyledTd width={0} color={companiesColors[id]} numberOfIcons="two">
        <TableButton hoverText="copy" icon="copy" onClick={handleCopy} />
        <TableButton
          hoverText="edit"
          icon="edit"
          onClick={() => setActive(id)}
        />
      </StyledTd>
    </StyledHeader>
  );
}
