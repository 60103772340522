import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';
import { TFunction } from 'react-i18next';
import { CbamCo2TransferSchema } from 'src/Cbam/types';

export type FormErrors = SchemaErrors<CbamCo2TransferSchema>;

export const isMonitoringMethodologyDocumentationRequired = (
  schema: CbamCo2TransferSchema
) => {
  const stored = schema.amount_of_stored_inherent_co2_tonne || 0;
  const used = schema.amount_of_used_inherent_co2_tonne || 0;
  return stored > 0 || used > 0;
};

export const validateForm = (
  data: CbamCo2TransferSchema,
  t: TFunction
): FormErrors => {
  const builder = new ErrorBuilder(data);
  return builder
    .checkRule(
      ['monitoring_methodology_documentation_for_co2_transfer'],
      (field, schema) => {
        if (isMonitoringMethodologyDocumentationRequired(schema)) {
          return !!field;
        }
        return true;
      },
      t('validation.required')
    )
    .build();
};
