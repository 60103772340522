import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledHeader,
  StyledTd,
} from '../../../Pages/Questionnaire/index.styles';
import { companiesColors } from '../../../Pages/SuperDashboard/data';
import CommentBtn from './commentBtn';
import TableButton from '../../TableButton';
import { toLocaleNumber } from '../../../../utils/helpers';
import { translateTypeOfTransportation } from '../forms/data';

export default function TransportationPreview({
  state,
  id,
  setActive,
  setState,
  language,
}: {
  state: any;
  id: number;
  setActive: Function;
  setState: Function;
  language: string;
}) {
  const { t } = useTranslation();
  const handleCopy = () => {
    const tempState = [...state];
    const item = tempState[id];

    tempState.splice(id, 0, item);
    setState(tempState);
    setActive(id);
  };
  const typeOfTransportation =
    state[id].transportation_data_factor_unknown?.type_of_transportation;

  return (
    <StyledHeader>
      <StyledTd width={25} color={companiesColors[id]}>
        <p>
          {typeOfTransportation &&
            t([
              `dropdowns.transport.${typeOfTransportation}`,
              translateTypeOfTransportation(typeOfTransportation),
            ])}
        </p>
        <CommentBtn state={state} id={id} />
      </StyledTd>
      <StyledTd width={25} color={companiesColors[id]}>
        {state[id].transportation_data_factor_unknown
          ? toLocaleNumber(
              state[id].transportation_data_factor_unknown.distance_km,
              language
            )
          : ''}
      </StyledTd>
      <StyledTd width={12} color={companiesColors[id]}>
        {state[id].transportation_data_factor_unknown
          ? toLocaleNumber(
              state[id].transportation_data_factor_unknown.weight,
              language
            )
          : ''}
      </StyledTd>
      <StyledTd width={13} color={companiesColors[id]}>
        {state[id].emission_factor_is_known
          ? state[id].transportation_data_factor_known.unit_label
          : t(
              `dropdowns.${
                state[id].transportation_data_factor_unknown?.weight_unit || ''
              }`
            )}
      </StyledTd>
      <StyledTd width={25} color={companiesColors[id]}>
        {state[id].emission_shared
          ? (state[id].emission_shared * 100).toFixed(2)
          : 0}
      </StyledTd>
      <StyledTd width={0} color={companiesColors[id]} numberOfIcons="two">
        <TableButton hoverText="copy" icon="copy" onClick={handleCopy} />
        <TableButton
          hoverText="edit"
          icon="edit"
          onClick={() => setActive(id)}
        />
      </StyledTd>
    </StyledHeader>
  );
}
