import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { RowType } from '../../../../components/ESGSummary/types';
import { MARGIN_LEFT, PDF_WIDTH } from '../config';
import { MultianswerField } from '../Tables/Fields';
import { useTranslation } from 'react-i18next';
import { parseAllowMany } from 'src/views/components/ESGSummary/Tables/MultiAnswerTable/answers';

interface Props {
  questions: RowType[];
  questionnaireKey: string;
}

export default function MultiAnswerTable(props: Props) {
  const { questions, questionnaireKey } = props;
  const { t } = useTranslation();
  return (
    <View
      style={{
        width: PDF_WIDTH - 2 * MARGIN_LEFT,
        fontSize: 40,
        border: '5px solid #ccc',
        borderRadius: '20px',
        backgroundColor: '#fff',
        marginBottom: '50px',
        fontFamily: 'Poppins',
        color: '#5A636F',
      }}
    >
      {/* columns */}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '35px',
          color: 'grey',
          fontStyle: 'bold',
          textTransform: 'uppercase',
          borderBottom: '2px solid grey',
        }}
      >
        <View style={{ width: '15%' }}>
          <Text>{t('esgSummary.tables.no-lp')}</Text>
        </View>
        <View style={{ width: '45%' }}>
          <Text>{t('esgSummary.tables.question')}</Text>
        </View>
        <View style={{ width: '40%' }}>
          <Text>{t('esgSummary.tables.answer')}</Text>
        </View>
      </View>

      {/* rows */}
      {questions?.map((question) => {
        const fields = parseAllowMany(question.fields);
        return (
          <View
            key={question.question_id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              padding: '35px',
            }}
          >
            <View style={{ width: '15%' }}>
              <Text>{questionnaireKey}</Text>
            </View>
            <View style={{ width: '45%', paddingRight: '50px' }}>
              <Text>{question.question_title}</Text>
            </View>
            <View style={{ width: '40%' }}>
              {fields.map((field) => (
                <MultianswerField key={field.key} field={field} />
              ))}
            </View>
          </View>
        );
      })}
    </View>
  );
}
