import Radio, { RadioProps } from '@mui/material/Radio';
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { StyledFormControlProps } from './index.interfaces';

export const StyledRadio = styled(Radio)<RadioProps>`
  &.Mui-checked {
    color: #388276;
  }
`;

export const StyledFormControlLabel = styled(
  FormControlLabel
)<FormControlLabelProps>`
  color: black;
  & .MuiRadio-root {
    padding: 4px;
  }
`;

export const StyledFormLabel = styled(FormLabel)<FormLabelProps>`
  font-weight: bold;
  margin-bottom: 10px;
  &.Mui-focused {
    color: #388276;
  }
`;

export const StyledFormControl = styled(FormControl)<StyledFormControlProps>(
  ({ isfirst, islast }) => `
  border-top: ${islast ? '1px' : '0px'} solid #b6bbb9;
  border-bottom: ${isfirst ? '1px' : '0px'} solid #b6bbb9;
  padding-bottom: ${isfirst ? '20px' : '0px'};
  padding-top ${islast ? '20px' : '0px'};
  &&.radio-button-group {
    margin-bottom: ${isfirst ? '15px' : '0px'};
    margin-top: ${islast ? '15px' : '0px'};
  }
  label {
    margin-left: 0;
  }
  `
);
