import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { StyledFlex } from 'src/components/StyledFlex';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import {
  passwordValidators,
  validatePasswords,
} from 'src/utils/passwordValidation';

interface Props {
  setNewPassword: (newPassword: string) => void;
  translationPrefix: string;
}

const Check = ({
  validationFunction,
  password,
  text,
}: {
  validationFunction: (password: string) => boolean;
  password: string;
  text: string;
}) => {
  const error = !validationFunction(password) && !!password;
  const success = validationFunction(password) && !!password;
  const color = (() => {
    if (error) return '#A03137';
    if (success) return '#5A9531';
    return '#inherit';
  })();
  return (
    <StyledFlex
      sx={{
        justifyContent: 'flex-start',
        pt: '5px',
        color,
      }}
    >
      {error ? <CancelRoundedIcon /> : <CheckCircleIcon />}
      &nbsp;
      <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>{text}</Typography>
    </StyledFlex>
  );
};

export default function PasswordVerificationForm({
  setNewPassword,
  translationPrefix,
}: Props) {
  const { t } = useTranslation();
  const [password, setPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const passwordFieldType = showPassword ? 'text' : 'password';

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (validatePasswords(e.target.value, confirmPassword)) {
      setNewPassword(e.target.value);
    } else {
      setNewPassword('');
    }
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
    if (validatePasswords(password, e.target.value)) {
      setNewPassword(password);
    } else {
      setNewPassword('');
    }
  };

  return (
    <Box>
      <StyledFlex>
        <TextField
          fullWidth
          label={t(`${translationPrefix}.newPassword.label`)}
          placeholder={t(`${translationPrefix}.newPassword.placeholder`)}
          value={password}
          onChange={handlePasswordChange}
          type={passwordFieldType}
          error={!!password && !validatePasswords(password, confirmPassword)}
          helperText={
            password !== confirmPassword && t('validation.passwordsDoNotMatch')
          }
          required
        />
        &nbsp;
        <TextField
          fullWidth
          label={t(`${translationPrefix}.confirmPassword.label`)}
          placeholder={t(`${translationPrefix}.confirmPassword.placeholder`)}
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          type={passwordFieldType}
          error={
            !!confirmPassword && !validatePasswords(password, confirmPassword)
          }
          helperText={password !== confirmPassword && ' '}
          required
        />
      </StyledFlex>

      <Box
        sx={{
          pt: '20px',
          color: '#606462',
          mb: '8px',
        }}
      >
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          {t('passwordValidation.title')}
        </Typography>

        {passwordValidators.map((validator) => (
          <Check
            key={validator.message}
            validationFunction={validator.validator}
            password={password}
            text={t(validator.message)}
          />
        ))}
      </Box>

      <FormControlLabel
        control={
          <Checkbox
            onChange={() => setShowPassword((x) => !x)}
            checked={showPassword}
          />
        }
        label={t('passwordValidation.showPassword')}
      />
    </Box>
  );
}
