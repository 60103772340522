import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface Props {
  data: Array<{
    url: string;
    name: string;
  }>;
}

const Wrapper = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
`;

const Item = styled.div`
  display: flex;
  justify-content: start;
  align-items: end;
`;

const Link = styled(NavLink)`
  color: var(--grey, #888);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.36px;
  background: transparent;
  text-decoration: none;
  position: relative;

  &.active {
    color: var(--main-dark-main, #38414f);

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: var(--main-dark-main, #38414f);
      bottom: -10px;
      left: 0;
      border-radius: 4px;
    }
  }
`;

export default function ReportTabs({ data }: Props) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {data.map((el) => (
        <Item key={el.name}>
          <Link
            className={({ isActive }) => (isActive ? 'active' : '')}
            type="button"
            to={el.url}
            relative="route"
          >
            {t(`${el.name}`)}
          </Link>
        </Item>
      ))}
    </Wrapper>
  );
}
