import React from 'react';
import { Text, Row as StyledRow, MultiAnswer } from '../styles';

export type RowProps = {
  keyText: string;
  title: string;
  answer: React.ReactNode;
};

export default function Row(props: RowProps) {
  return (
    <div>
      <StyledRow>
        <Text>{props.keyText}</Text>
        <Text style={{ width: '50%' }}>{props.title}</Text>

        <MultiAnswer style={{ width: '35%' }}>{props.answer}</MultiAnswer>
      </StyledRow>
    </div>
  );
}
