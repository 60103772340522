import styled from 'styled-components';

export const ChecklistWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
  min-width: 390px;
  max-width: 600px;
  flex-wrap: wrap;
  margin-right: 15px;
`;

export const Column = styled.div`
  width: 33%;
  min-width: 120px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > h2 {
    font-weight: 900;
    font-size: 18px;
    line-height: 14px;
    letter-spacing: 0.01em;
    margin-bottom: 17px;
  }
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 8px 0;

  > span {
    margin-left: 5px;
  }
`;

export const Input = styled.input<{ color: string }>`
  border: 0;
  font-weight: 700;
  font-size: 18px;
  width: 100%;
  color: ${({ color }) => color};
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  & > img {
    width: 16px;
    height: 16px;
    mix-blend-mode: difference;
  }
`;
