import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import BasePage from '../Base';
import { IntroductionDataType } from './types';

const style = StyleSheet.create({
  textContainer: {
    position: 'absolute',
    width: 200,
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    color: '#FFF',
    fontFamily: 'Lato',
    fontSize: 30,
    fontStyle: 'normal',
    fontWeight: 900,
    letterSpacing: 2,
    textTransform: 'uppercase',
  },
  scope3a: { top: 350, left: 200 },
  scope2a: { top: 420, left: 540 },
  scope1: { top: 450, left: 825, width: 250 },
  scope2b: { top: 420, left: 1180 },
  scope3b: { top: 350, left: 1500 },
  mainHeaderText: {
    fontSize: 30,
  },
  subHeaderText: {
    fontSize: 16,
  },
});

type ScopesVisualisationProps = {
  data: IntroductionDataType;
};

const ScopesVisualisationPage = ({ data }: ScopesVisualisationProps) => {
  const {
    scope_1_main_header: scope1MainHeader,
    scope_1_subheader: scope1SubHeader,
    scope_2_main_header: scope2MainHeader,
    scope_2_subheader: scope2SubHeader,
    scope_3_main_header: scope3MainHeader,
    scope_3_subheader_upstream: scope3SubHeaderUpstream,
    scope_3_subheader_downstream: scope3SubHeaderDownstream,
  } = data.scope_visualisation;

  const scope1Style = { ...style.textContainer, ...style.scope1 };
  const scope2aStyle = { ...style.textContainer, ...style.scope2a };
  const scope2bStyle = { ...style.textContainer, ...style.scope2b };
  const scope3aStyle = { ...style.textContainer, ...style.scope3a };
  const scope3bStyle = { ...style.textContainer, ...style.scope3b };

  return (
    <BasePage backgroundImageName="Report_envirly_slide_5.png">
      <View>
        <View style={scope1Style}>
          <Text style={style.mainHeaderText}>{scope1MainHeader}</Text>
          <Text style={style.subHeaderText}>{scope1SubHeader}</Text>
        </View>
        <View style={scope2aStyle}>
          <Text style={style.mainHeaderText}>{scope2MainHeader}</Text>
          <Text style={style.subHeaderText}>{scope2SubHeader}</Text>
        </View>
        <View style={scope3aStyle}>
          <Text style={style.mainHeaderText}>{scope3MainHeader}</Text>
          <Text style={style.subHeaderText}>{scope3SubHeaderUpstream}</Text>
        </View>
        <View style={scope2bStyle}>
          <Text style={style.mainHeaderText}>{scope2MainHeader}</Text>
          <Text style={style.subHeaderText}>{scope2SubHeader}</Text>
        </View>
        <View style={scope3bStyle}>
          <Text style={style.mainHeaderText}>{scope3MainHeader}</Text>
          <Text style={style.subHeaderText}>{scope3SubHeaderDownstream}</Text>
        </View>
      </View>
    </BasePage>
  );
};

export default ScopesVisualisationPage;
