import React from 'react';
import { Button } from './index.styles';

interface ButtonLinkProps {
  url: string;
  text: string;
}

export default function ButtonLink({ url, text }: ButtonLinkProps) {
  return <Button to={url}>{text}</Button>;
}
