import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CheckboxFieldProps } from './index.interfaces';
import {
  ContentBox,
  InfoBox,
  StyledCheckbox,
  StyledFormGroup,
} from './index.styles';
import { useTranslation } from 'react-i18next';

export default function OneValueCheckboxField({
  value,
  setValue,
  label,
  labelInfoContent,
  error,
}: CheckboxFieldProps) {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  return (
    <StyledFormGroup>
      <FormControlLabel
        control={
          <StyledCheckbox
            error={error}
            checked={value}
            onChange={() => {
              setValue(!value);
            }}
          />
        }
        label={label}
      />
      {!!labelInfoContent && (
        <InfoBox>
          <svg
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_139_19739)">
              <path
                d="M16 6C10.48 6 6 10.48 6 16C6 21.52 10.48 26 16 26C21.52 26 26 21.52 26 16C26 10.48 21.52 6 16 6ZM17 20C17 20.5523 16.5523 21 16 21C15.4477 21 15 20.5523 15 20V16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16V20ZM17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z"
                fill="#388276"
              />
            </g>
            <defs>
              <clipPath id="clip0_139_19739">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {visible && <ContentBox>{t(labelInfoContent)}</ContentBox>}
        </InfoBox>
      )}
    </StyledFormGroup>
  );
}
