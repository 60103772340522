export const titleData = [
  {
    title: 'onboarding.welcome.title',
    subtitle: 'onboarding.welcome.subtitle',
    text: 'onboarding.welcome.text',
    next: 'onboarding.next',
    back: 'onboarding.back',
    backAction: null,
    nextAction: '/onboarding/1',
  },
  {
    title: 'onboarding.step-1.title',
    subtitle: null,
    text: 'onboarding.step-1.text',
    next: 'onboarding.next',
    back: 'onboarding.back',
    backAction: '/onboarding/0',
    nextAction: '/onboarding/2',
  },
  {
    title: 'onboarding.step-2.title',
    subtitle: null,
    text: 'onboarding.step-2.text',
    next: 'onboarding.next',
    back: 'onboarding.back',
    backAction: '/onboarding/1',
    nextAction: '/onboarding/3',
  },
  {
    title: 'onboarding.step-3.title',
    subtitle: null,
    text: 'onboarding.step-3.text',
    next: 'onboarding.next',
    back: 'onboarding.back',
    backAction: '/onboarding/2',
    nextAction: '/onboarding/4',
  },
  {
    title: 'onboarding.step-4.title',
    subtitle: null,
    text: 'onboarding.step-4.text',
    next: 'onboarding.send',
    back: 'onboarding.back',
    backAction: '/onboarding/3',
    nextAction: '/dashboard',
  },
];
