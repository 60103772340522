import React from 'react';
import OrganizationSelect from '../components/OrganizationSelect';
import { IconButton, SelectChangeEvent, TableRow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useOrganizationMember } from './hooks';
import { Member, MemberRole } from 'src/Superadmin/index.types';
import PermissionSelect from '../components/PermissionSelect';
import {
  StyledActionCell,
  StyledAddPermissionButton,
  StyledSelectCell,
} from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  member: Member;
}
export default function NewPermissionRow(props: Props) {
  const { member } = props;
  const { t } = useTranslation();

  const [newPermissionFormOpen, setNewPermissionFormOpen] =
    React.useState<boolean>(false);

  const [role, setRole] = React.useState<MemberRole>(MemberRole.edit);
  const [organization, setOrganization] = React.useState<number | null>(null);

  const { organizations, createOrganizationMember } = useOrganizationMember(
    member.id
  );

  const handleRoleChange = (e: SelectChangeEvent<any>) =>
    setRole(e.target.value);

  const handleOrganizationChange = (e: SelectChangeEvent<any>) => {
    const newOrganization = parseInt(e.target.value, 10);
    setOrganization(newOrganization);
    createOrganizationMember
      .mutateAsync({
        role,
        organizationId: newOrganization,
        products: [],
      })
      .then(() => setNewPermissionFormOpen(false));
    setOrganization(null);
  };

  const organizationMembers = member.organizations || [];

  const getOrganizationOptions = () =>
    organizations.filter(
      (org) =>
        !organizationMembers.map((om) => om.organization_id).includes(org.id)
    );

  return (
    <TableRow sx={{ p: 0 }}>
      <StyledSelectCell>
        {!newPermissionFormOpen && (
          <StyledAddPermissionButton
            onClick={() => setNewPermissionFormOpen(true)}
          >
            {`+ ${t(
              'superadmin.settings.tabs.users.usersTable.permissions.addPermission'
            )}`}
          </StyledAddPermissionButton>
        )}
        {newPermissionFormOpen && (
          <PermissionSelect value={role} onChange={handleRoleChange} />
        )}
      </StyledSelectCell>
      <StyledSelectCell>
        {newPermissionFormOpen && (
          <OrganizationSelect
            organizations={getOrganizationOptions()}
            value={organization}
            onChange={handleOrganizationChange}
          />
        )}
      </StyledSelectCell>
      <StyledActionCell>&nbsp;</StyledActionCell>
      <StyledActionCell>&nbsp;</StyledActionCell>
      <StyledActionCell>&nbsp;</StyledActionCell>
      <StyledActionCell>
        {newPermissionFormOpen && (
          <IconButton onClick={() => setNewPermissionFormOpen(false)}>
            <CloseIcon />
          </IconButton>
        )}
      </StyledActionCell>
    </TableRow>
  );
}
