import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandLessIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';

import {
  CollapseContainer,
  ExpandButton,
  Text,
  Row as StyledRow,
  YesNoText,
} from '../styles';
import Checkmark from './Checkmark';
import SingleDescription from './SingleDescription';
import { RowType } from '../../types';
import { hasValue } from 'src/views/components/QuestionnaireV3/Summary/SummaryTable/utils';

export default function Row(props: RowType) {
  const {
    fields,
    questionnaire_key: questionnaireKey,
    question_title: questionTitle,
  } = props;

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded((prevState) => !prevState);

  const value = Object.values(fields[0]?.answer)[0];
  const isYes = !!value;

  const hasDescription = fields.length > 1;

  // not displaying row for unanswered questions
  if (![true, false].includes(value)) return null;

  return (
    <div>
      <StyledRow>
        {!!hasDescription && (
          <ExpandButton size="small" onClick={handleExpandClick}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ExpandButton>
        )}
        <Text>{questionnaireKey}</Text>
        <Text style={{ width: '70%' }}>
          {questionTitle}
          <br />
          {!!hasDescription && (
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CollapseContainer>
                {fields.map((field, idx) => {
                  // skiping first (yes-or-no-toggle)
                  if (idx === 0) return;
                  const value = Object.values(field.answer)[0];
                  if (!hasValue(value)) return null;
                  return (
                    <SingleDescription
                      key={field.key}
                      label={field.label}
                      value={value}
                    />
                  );
                })}
              </CollapseContainer>
            </Collapse>
          )}
        </Text>
        <YesNoText>{isYes && <Checkmark />}</YesNoText>
        <YesNoText>{!isYes && <Checkmark />}</YesNoText>
      </StyledRow>
    </div>
  );
}
