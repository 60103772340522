import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './index.styles';
import styles from '../styles.module.css';

export default function NoData({
  errorMessage,
}: {
  errorMessage?: string;
}) {
  const { t } = useTranslation();
  return (
    <Container>
      <p className={styles.noData}>{errorMessage ?? t('dashboard.no-data')}</p>
    </Container>
  );
}

