import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteFreesolo } from '../fields';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import { useAppDispatch, useAppSelector } from '../../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import {
  getTransportationAndDistributionData,
  getTransportationAndDistributionDataEmissionShares,
  putTransportationAndDistributionData,
} from '../../../../redux-file/questionnaires/thunks';
import { timeConverter } from '../../../../utils/timeConverter';
import { Overlay } from '../Title/index.styles';
import { CheckboxContainer, Form, FormBox } from './index.styles';
import FormButtons from '../FormButtons';
import OneValueCheckboxField from '../../../../components/CheckboxField';

export default function DistributionForm({
  state,
  setState,
  id,
  active,
  setActive,
  combinedData,
  variant,
  setNewForm,
}: {
  setNewForm: Function;
  state: any;
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  combinedData: any;
  variant: 'u' | 'd';
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector(
    (redux_state) => redux_state.questionnaire
  );
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const [factor_error_state, set_factor_error_state] = useState(false);
  let factor_error = false;
  const factor_validation = (value: string) => {
    if (value === '') factor_error = true;
    return factor_error;
  };

  const [amount_error_state, set_amount_error_state] = useState(false);
  let amount_error = false;
  const amount_validation = (value: string) => {
    if (value === '') amount_error = true;
    return amount_error;
  };

  const [
    amount_stored_kg_in_period_error_state,
    set_amount_stored_kg_in_period_error_state,
  ] = useState(false);
  let amount_stored_kg_in_period_error = false;
  const amount_stored_kg_in_period_validation = (value: string) => {
    if (value === '') amount_stored_kg_in_period_error = true;
    return amount_stored_kg_in_period_error;
  };

  const [
    storage_duration_days_in_period_error_state,
    set_storage_duration_days_in_period_error_state,
  ] = useState(false);
  let storage_duration_days_in_period_error = false;
  const storage_duration_days_in_period_validation = (value: string) => {
    if (value === '') storage_duration_days_in_period_error = true;
    return storage_duration_days_in_period_error;
  };

  const setKnowEmission = () => {
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      emission_factor_is_known: !state[id].emission_factor_is_known,
      comment: commutingType.comment,
      distribution_data_factor_unknown: state[id].emission_factor_is_known
        ? {
            amount_stored_kg_in_period: '',
            storage_duration_days_in_period: '',
          }
        : commutingType.distribution_data_factor_unknown,
      distribution_data_factor_known: state[id].emission_factor_is_known
        ? commutingType.distribution_data_factor_known
        : {
            factor: '',
            amount: '',
            unit_label: '',
          },
    };
    newForms[id] = commutingType;
    setState(newForms);
  };

  const handleAmountStored = (value: string) => {
    amount_stored_kg_in_period_validation(value);
    set_amount_stored_kg_in_period_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      emission_factor_is_known: commutingType.emission_factor_is_known,
      comment: commutingType.comment,
      distribution_data_factor_unknown: {
        amount_stored_kg_in_period: value,
        storage_duration_days_in_period:
          commutingType.distribution_data_factor_unknown
            .storage_duration_days_in_period,
      },
      distribution_data_factor_known:
        commutingType.distribution_data_factor_known,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_amount_stored_kg_in_period_error_state(true);
  };

  const handleStorage = (value: string) => {
    storage_duration_days_in_period_validation(value);
    set_storage_duration_days_in_period_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      emission_factor_is_known: commutingType.emission_factor_is_known,
      comment: commutingType.comment,
      distribution_data_factor_unknown: {
        amount_stored_kg_in_period:
          commutingType.distribution_data_factor_unknown
            .amount_stored_kg_in_period,
        storage_duration_days_in_period: value,
      },
      distribution_data_factor_known:
        commutingType.distribution_data_factor_known,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_storage_duration_days_in_period_error_state(true);
  };

  const handleKnownUnitLabel = (value: string) => {
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      emission_factor_is_known: commutingType.emission_factor_is_known,
      comment: commutingType.comment,
      distribution_data_factor_unknown:
        commutingType.distribution_data_factor_unknown,
      distribution_data_factor_known: {
        factor: commutingType.distribution_data_factor_known.factor,
        amount: commutingType.distribution_data_factor_known.amount,
        unit_label: value,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
  };
  const handleFactor = (value: string) => {
    factor_validation(value);
    set_factor_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      emission_factor_is_known: commutingType.emission_factor_is_known,
      comment: commutingType.comment,
      distribution_data_factor_unknown:
        commutingType.distribution_data_factor_unknown,
      distribution_data_factor_known: {
        factor: value,
        amount: commutingType.distribution_data_factor_known.amount,
        unit_label: commutingType.distribution_data_factor_known.unit_label,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_factor_error_state(true);
  };
  const handleAmount = (value: string) => {
    amount_validation(value);
    set_amount_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      emission_factor_is_known: commutingType.emission_factor_is_known,
      comment: commutingType.comment,
      distribution_data_factor_unknown:
        commutingType.distribution_data_factor_unknown,
      distribution_data_factor_known: {
        factor: commutingType.distribution_data_factor_known.factor,
        amount: value,
        unit_label: commutingType.distribution_data_factor_known.unit_label,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_amount_error_state(true);
  };

  const handleSend = (data: any) => {
    if (
      !amount_stored_kg_in_period_error &&
      !storage_duration_days_in_period_error &&
      !factor_error &&
      !amount_error
    ) {
      dispatch(
        putTransportationAndDistributionData({
          data,
          period: timeConverter(selectedDate, reportingPeriod),
        })
      ).then(() => {
        dispatch(
          getTransportationAndDistributionData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
        dispatch(
          getTransportationAndDistributionDataEmissionShares({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
      });
      setActive(undefined);
    }
  };

  const defineErrors = async () => {
    if (state[id].emission_factor_is_known === false) {
      if (
        state[id].distribution_data_factor_unknown
          .storage_duration_days_in_period === ''
      ) {
        set_storage_duration_days_in_period_error_state(true);
        storage_duration_days_in_period_error = true;
      }

      if (
        state[id].distribution_data_factor_unknown
          .amount_stored_kg_in_period === ''
      ) {
        set_amount_stored_kg_in_period_error_state(true);
        amount_stored_kg_in_period_error = true;
      }
    }

    if (state[id].emission_factor_is_known === true) {
      if (state[id].distribution_data_factor_known.factor === '') {
        set_factor_error_state(true);
        factor_error = true;
      }

      if (state[id].distribution_data_factor_known.amount === '') {
        set_amount_error_state(true);
        amount_error = true;
      }
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    const tempcombinedData = {
      transportation: combinedData.transportation,
      distribution: {
        upstream:
          variant === 'u' ? tempState : combinedData.distribution.upstream,
        downstream:
          variant === 'u' ? combinedData.distribution.downstream : tempState,
      },
    };

    handleSend(tempcombinedData);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              handleSend(combinedData);
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <CheckboxContainer>
            <OneValueCheckboxField
              label={t('transportation.upstream-transportation.check')}
              setValue={() => setKnowEmission()}
              value={state[id].emission_factor_is_known}
            />
          </CheckboxContainer>
          {state[id].emission_factor_is_known === false ? (
            <>
              <FormBox variant="50">
                <TextFieldNumber
                  type="mass"
                  label={t('transportation.upstream-distribution.amount')}
                  active
                  error={amount_stored_kg_in_period_error_state}
                  index={1}
                  value={
                    state[id].distribution_data_factor_unknown
                      .amount_stored_kg_in_period
                  }
                  setValue={(e: string) => handleAmountStored(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <TextFieldNumber
                  type="integer"
                  label={t('transportation.upstream-distribution.storage')}
                  active
                  error={storage_duration_days_in_period_error_state}
                  index={1}
                  value={
                    state[id].distribution_data_factor_unknown
                      .storage_duration_days_in_period
                  }
                  setValue={(e: string) => handleStorage(e)}
                />
              </FormBox>
            </>
          ) : (
            <>
              <FormBox variant="50">
                <TextFieldNumber
                  label={t(
                    'transportation.upstream-distribution.amountFactorKnown'
                  )}
                  active
                  error={amount_error_state}
                  index={1}
                  value={state[id].distribution_data_factor_known.amount}
                  setValue={(e: string) => handleAmount(e)}
                />
              </FormBox>

              <FormBox variant="50">
                <AutocompleteFreesolo
                  active
                  error={false}
                  index={1}
                  label={t('transportation.upstream-transportation.unit')}
                  value={state[id].distribution_data_factor_known.unit_label}
                  setValue={(value: string) => handleKnownUnitLabel(value)}
                  max={123}
                  options={[]}
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  type="factor"
                  label={t('transportation.upstream-transportation.factor')}
                  active
                  error={factor_error_state}
                  index={1}
                  value={state[id].distribution_data_factor_known.factor}
                  setValue={(e: string) => handleFactor(e)}
                />
              </FormBox>
            </>
          )}
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            canCopy={
              (!state[id].emission_factor_is_known &&
                state[id].distribution_data_factor_unknown
                  .storage_duration_days_in_period !== '' &&
                state[id].distribution_data_factor_unknown
                  .amount_stored_kg_in_period !== '') ||
              (state[id].emission_factor_is_known &&
                state[id].distribution_data_factor_known.factor !== '' &&
                state[id].distribution_data_factor_known.amount !== '')
            }
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
