import React from 'react';

import {
  Box,
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  styled,
} from '@mui/material';
import { StyledErrorMessage } from '../../../views/components/QuestionnaireV3/Field/index.styles';
import { useTranslation } from 'react-i18next';

type YesNoToggleProps = {
  value?: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  errorText?: React.ReactNode | null;
};

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButton-root.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '& .MuiToggleButton-root.Mui-selected:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function YesNoToggle(props: YesNoToggleProps) {
  const { t } = useTranslation();
  const helperText =
    typeof props.errorText === 'string' ? t(props.errorText) : undefined;
  const isError = props.errorText !== undefined;

  return (
    <Box>
      <ToggleButtonGroup
        fullWidth
        exclusive
        disabled={props.disabled}
        value={props.value}
        onChange={(_, v) => props.onChange(!!v)}
      >
        <ToggleButton value={false} className={isError ? 'Mui-error' : ''}>
          {t('no')}
        </ToggleButton>
        <ToggleButton value className={isError ? 'Mui-error' : ''}>
          {t('yes')}
        </ToggleButton>
      </ToggleButtonGroup>
      <Box height="5px" />
      <StyledErrorMessage>{helperText}</StyledErrorMessage>
    </Box>
  );
}
