import { styled as styledMUI } from '@mui/material/styles';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import { Alert } from '@mui/material';

export const Container = styled.div`
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InputPassword = styledMUI(TextField)`
    margin-bottom: 2.75rem;
    
     &:last-child {
        margin-bottom: 0;
    }
    
    & label.Mui-focused {
      color: #388276;
    }
      
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #388276;
      }
    }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LoginButton = styled.button`
  background-color: #388276;
  border: 2px solid #388276;
  border-radius: 28px;
  display: flex;
  flex-direction: row;
  transition:
    background-color,
    border 0.3s ease-in-out;
  padding-right: 20px;
  padding-left: 30px;
  height: 56px;
  align-items: center;
  justify-content: flex-end;

  &:hover {
    transition:
      background-color,
      border 0.3s ease-in-out;
    background-color: #499387;
    border: 2px solid #499387;
  }

  span {
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.03em;
    padding-right: 20px;
  }
`;

export const StyledAlert = styledMUI(Alert)`
  margin-bottom: 2.75rem;
`;

export const PasswordLink = styled(Link)`
  color: #388276;
  text-decoration: none;
  background-color: transparent;
  border-radius: 16px;
  padding: 4px 8px;

  &:hover {
    color: #499387;
    background-color: #f2f2f2;
  }
`;

export const FormControlLabelWrapper = styled.div`
  margin-top: 18px;
`;
