import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';

import { StyledTitle, StyledDiscription } from './index.styles';
import {
  GhgSupplierGoods,
  GOODS_AND_SERVICES_OR_CAPITAL_GOODS as SelectOptions,
} from 'src/Ghg/interfaces';
import { toast } from 'react-toastify';
import BigCalendar from 'src/views/components/BigCalendar';
import { periodToDate, timeConverter } from 'src/utils/timeConverter';
import { useSupplierGoods } from '../../hooks';
import { t } from 'i18next';
import { useGhgOrganization } from '../../../../common/hooks';
import { extractErrors } from 'src/utils/validation';

interface Props {
  surveyAnswerId: number;
  open: boolean;
  onClose: () => void;
  instance?: GhgSupplierGoods;
}

const tKey =
  'ghg.scope3GoodsAndServices.productsFromSuppliers.reportingPeriodModal';

const selectOptions = [
  {
    value: SelectOptions.GOODS_AND_SERVICES,
    label: `${tKey}.inputs.typeSelect.choices.goodsAndServices`,
  },
  {
    value: SelectOptions.CAPITAL_GOODS,
    label: `${tKey}.inputs.typeSelect.choices.capitalGoods`,
  },
];

export default function ProductPeriodModal(props: Props) {
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const { open, onClose, surveyAnswerId, instance } = props;

  const initialPeriodDate =
    (instance?.reporting_period &&
      periodToDate(instance.reporting_period, reportingPeriod)) ||
    new Date();
  const initialNumberOfProducts = instance?.amount || '';
  const initialType = instance?.type || SelectOptions.GOODS_AND_SERVICES;

  const [periodDate, setPeriodDate] = React.useState<Date>(
    new Date(initialPeriodDate)
  );
  const [numberOfProducts, setNumberOfProducts] = React.useState<string>(
    String(initialNumberOfProducts)
  );
  const [type, setType] = React.useState<SelectOptions>(initialType);
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  const _onClose = () => {
    setPeriodDate(new Date());
    setNumberOfProducts('');
    setType(SelectOptions.GOODS_AND_SERVICES);
    onClose();
    setErrors({});
  };

  const period = timeConverter(periodDate, reportingPeriod);

  const handleNumberOfProductsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    // accept only positive integers as input
    if (value === '' || /^[0-9\b]+$/.test(value))
      setNumberOfProducts(value.replace(/^0+/, ''));
  };

  const { create: createReportPeriod, update: updateReportPeriod } =
    useSupplierGoods(organization.id);

  const handleCreateReportPeriod = () =>
    createReportPeriod
      .mutateAsync({
        survey_answer_id: surveyAnswerId,
        amount: parseInt(numberOfProducts, 10),
        type,
        reporting_period: period,
      })
      .then(() => {
        toast.success(t('ghg.scope3GoodsAndServices.toasts.success') as string);
        _onClose();
      })
      .catch((err) => setErrors(extractErrors(err) || {}));

  const handleUpdateReportPeriod = () =>
    updateReportPeriod
      .mutateAsync({
        id: instance?.id as number,
        amount: parseInt(numberOfProducts, 10),
        type,
        reporting_period: period,
      })
      .then(() => {
        toast.success(t('ghg.scope3GoodsAndServices.toasts.success') as string);
        _onClose();
      })
      .catch((err) => setErrors(extractErrors(err) || {}));

  return (
    <Dialog open={open} onClose={_onClose} maxWidth="lg">
      <IconButton
        sx={{ position: 'absolute', right: '10px', top: '10px' }}
        onClick={_onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ minWidth: '900px' }}>
        <StyledTitle>{t(`${tKey}.title`)}</StyledTitle>
        <br />
        <StyledDiscription>
          1. {t(`${tKey}.subtitle.1`)}
          <br />
          2. {t(`${tKey}.subtitle.2`)}
          <br />
          3. {t(`${tKey}.subtitle.3`)}
          <br />
        </StyledDiscription>

        <br />
        <BigCalendar
          reportingPeriod={reportingPeriod}
          currDate={periodDate}
          setDate={setPeriodDate}
        />
        {errors.reporting_period && (
          <Typography variant="caption" color="error">
            {errors.reporting_period}
          </Typography>
        )}
        <br />
        <br />

        <TextField
          fullWidth
          label={t(`${tKey}.inputs.numberOfProducts.label`)}
          value={numberOfProducts}
          onChange={handleNumberOfProductsChange}
        />
        <br />
        <br />
        <FormControl fullWidth>
          <InputLabel id="goods-or-service-label">
            {t(`${tKey}.inputs.typeSelect.label`)}
          </InputLabel>
          <Select
            labelId="goods-or-service-label"
            id="goods-or-service-select"
            value={type}
            label={t(`${tKey}.inputs.typeSelect.label`)}
            onChange={(e) => setType(e.target.value as SelectOptions)}
          >
            {selectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ m: '0px 20px 20px' }}>
        <Button
          color="primary"
          variant="contained"
          onClick={
            !!instance ? handleUpdateReportPeriod : handleCreateReportPeriod
          }
          sx={{ minWidth: '100px' }}
          disabled={numberOfProducts === '' || createReportPeriod.isLoading}
        >
          {t(`${tKey}.buttons.${!!instance ? 'save' : 'add'}`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
