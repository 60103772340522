import React from 'react';
import styled from 'styled-components';
import { BigArrows, Icons, SmallArrows } from './icons';

const data = [
  {
    name: '1. Product, goal and scope',
    active: true,
    desc: 'Determine how much of the product life cycle will be considered and what purpose the assessment will serve.',
  },
  {
    name: '2. Inventory analysis (LCI)',
    active: false,
    desc: 'List all resources taken from the environment and pollution and waste associated with the product or service.',
  },
  {
    name: '3. Impact assessment (LCIA)',
    active: false,
    desc: 'You classify the environmental impacts of all the processes collected and modelled in the LCI.',
  },
  {
    name: '4. Interpretation',
    active: false,
    desc: 'You check that applications are well-founded in accordance with ISO 14044',
  },
];

interface Props {
  variant: 'small' | 'big';
  onClick?: () => void;
}

const Grid = styled.div<{ variant: 'small' | 'big' }>`
  width: ${({ variant }) => (variant === 'big' ? '1416px' : '1024px')};
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: ${({ variant }) => (variant === 'big' ? '96px' : '56px')};
  grid-row-gap: ${({ variant }) => (variant === 'big' ? '78px' : '56px')};
  grid-template-areas:
    'el-1 el-2 el-3'
    'el-4 el-4 el-4';
`;

const Element = styled('div')<{ active: boolean; variant: 'small' | 'big' }>`
  text-decoration: none;
  &.el {
    border-radius: 24px;
    border: 2px solid #dadada;
    background: #fff;
    padding: ${({ variant }) =>
      variant === 'big' ? '24px 24px 44px' : '24px 20px 24px 24px'};

    &:hover {
      border: 2px solid ${({ active }) => (active ? '#8f5409' : '#dadada')};
      background: ${({ active }) => (active ? '#fff7e8' : '#fff')};
      cursor: ${({ active }) => (active ? 'pointer' : 'auto')};
    }

    &:active {
      box-shadow: none;
      border: 2px solid ${({ active }) => (active ? '#8f5409' : '#dadada')};
      background: ${({ active }) => (active ? '#fff7e8' : '#fff')};
      cursor: ${({ active }) => (active ? 'pointer' : 'auto')};
    }
  }

  &.el-1 {
    grid-area: el-1;
  }
  &.el-2 {
    grid-area: el-2;
  }
  &.el-3 {
    grid-area: el-3;
  }
  &.el-4 {
    grid-area: el-4;
  }

  &.el-1,
  &.el-2,
  &.el-3 {
    img {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 24px;
      display: block;
      width: ${({ variant }) => (variant === 'big' ? '140px' : '80px')};
      height: ${({ variant }) => (variant === 'big' ? '140px' : '80px')};
    }
  }

  &.el-4 {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 97px;
    padding-top: 50px;
    padding-bottom: 54px;
    gap: 54px;

    img {
      width: ${({ variant }) => (variant === 'big' ? '140px' : '80px')};
      height: ${({ variant }) => (variant === 'big' ? '140px' : '80px')};
    }
  }
`;

const MainText = styled.p<{ variant: 'small' | 'big' }>`
  color: #281302;
  font-size: ${({ variant }) => (variant === 'big' ? '20px' : '16px')};
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: ${({ variant }) => (variant === 'big' ? '22px' : '18px')};
`;

const Desc = styled.p`
  color: #4d4d4d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.48px;
  margin-bottom: 0;
`;

const Arrows = styled.div<{ variant: 'small' | 'big' }>`
  position: absolute;
  display: flex;
  flex-direction: column;

  &.arrow-1 {
    top: ${({ variant }) => (variant === 'big' ? '145px' : '105px')};
    left: ${({ variant }) => (variant === 'big' ? '432px' : '313px')};
  }

  &.arrow-2 {
    top: ${({ variant }) => (variant === 'big' ? '145px' : '105px')};
    left: ${({ variant }) => (variant === 'big' ? '935px' : '673px')};
  }

  &.arrow-3 {
    top: ${({ variant }) => (variant === 'big' ? '365px' : '292px')};
    left: ${({ variant }) => (variant === 'big' ? '182px' : '135px')};
    transform: rotate(90deg);
  }

  &.arrow-4 {
    top: ${({ variant }) => (variant === 'big' ? '365px' : '292px')};
    left: ${({ variant }) => (variant === 'big' ? '680px' : '500px')};
    transform: rotate(90deg);
  }

  &.arrow-5 {
    top: ${({ variant }) => (variant === 'big' ? '365px' : '292px')};
    left: ${({ variant }) => (variant === 'big' ? '1182px' : '856px')};
    transform: rotate(90deg);
  }
`;

export default function IntroductionsGrid({
  onClick = () => {},
  variant,
}: Props) {
  return (
    <Grid variant={variant}>
      {data.map((el, idx) => {
        return (
          <Element
            variant={variant}
            active={el.active}
            onClick={onClick} // quick fix not distinguishing between elements, by only one click is available anyway
            key={el.name}
            className={`el el-${idx + 1}`}
          >
            <Icons idx={idx + 1} />
            <div>
              <MainText variant={variant}>{el.name}</MainText>
              <Desc>{el.desc}</Desc>
            </div>
          </Element>
        );
      })}
      {Array(5)
        .fill('')
        .map((_, idx) => (
          <Arrows variant={variant} key={idx} className={`arrow-${idx + 1}`}>
            {variant === 'big' && <BigArrows />}
            {variant === 'small' && <SmallArrows />}
          </Arrows>
        ))}
    </Grid>
  );
}
