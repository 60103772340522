import React from 'react';

import { Button, Typography } from '@mui/material';
import { StyledPanel } from 'src/components/StyledPanel';
import { Link } from 'react-router-dom';

export default function InviteSuppliers() {
  return (
    <StyledPanel>
      <Typography variant="h3">Start reporting with Envirly CBAM</Typography>
      <br />
      <Typography variant="body1">
        Create a production process for your goods and send an invitation to
        your supplier to complete it. You can create as many production
        processes as you like.
      </Typography>
      <br />
      <Link to="../production-process">
        <Button>Add production process</Button>
      </Link>
    </StyledPanel>
  );
}
