import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import BasePage from '../Base';
import { CoverDataType } from './types';
import { LOGO_MARGIN_LEFT } from '../../config';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  sectionTitle: {
    position: 'absolute',
    top: 450,
    left: LOGO_MARGIN_LEFT,
    width: 1440,
  },
  sectionTitleText: {
    textShadow: '1px 4px 10px rgba(0, 0, 0, 0.60)',
    fontStyle: 'bold',
    fontSize: 84,
    letterSpacing: 2,
    fontFamily: 'Poppins',
    color: '#fff',
  },
});

type CoverPageProps = {
  data: {
    cover: CoverDataType;
  };
};

const CoverPage = ({ data }: CoverPageProps) => {
  const { section_title: sectionTitle } = data.cover;
  return (
    <BasePage backgroundImageName="Report_envirly_slide_32.png">
      <View style={styles.sectionTitle}>
        <Text style={styles.sectionTitleText}>{sectionTitle}</Text>
      </View>
    </BasePage>
  );
};

export default CoverPage;
