/* eslint-disable no-unused-vars */

export class AppConfig {
  constructor(
    public backendApiUrl: string,
    public backendRootUrl: string,
    public sentryIngestUrl: string,
    public environment: string,
    public smartlookApiKey: string,
    public sentryRelease: string,
    public stage?: string
  ) {}
}

// Helper methods to compare class/env keys
const keySanitizer = (key: string) => key?.toLowerCase()?.replaceAll('_', '');
const keyComparer = (a: string, b: string) =>
  keySanitizer(a) === keySanitizer(b);

function loadConfig<T>(
  type: { new (...args: any[]): T },
  keyPrefix: string = ''
): T {
  // import all env variables prefixed with `prefix`
  const prefix = `REACT_APP_${keyPrefix}`;
  const config: any = {};
  // TODO fix types
  // @ts-ignore
  const keys = Object.keys(new type());

  for (const key of Object.keys(process.env)) {
    if (key.indexOf(prefix) !== 0) continue;
    const name = key.substring(prefix.length);
    for (const instanceKey of keys) {
      if (!keyComparer(instanceKey, name)) continue;
      config[instanceKey] = process.env[key];
    }
  }
  // @ts-ignore
  return Object.assign(new type(), config);
}

export const appConfig = loadConfig(AppConfig);
