import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { EmptyIcon } from './icons';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';
import { StyledFlex } from 'src/components/StyledFlex';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { useProductActions } from './hooks';
import { CopyProductModal } from './modals';
import {
  BoxDate,
  HiddenIconButton,
} from 'src/views/Pages/ESG/ReportsIndex/index.styles';
import { toast } from 'react-toastify';
import { LcaProductMaterial } from 'src/Lca/types';

const Box = css`
  border-radius: 16px;
  border: 3px solid var(--Stroke, #dadada);
  background: var(--White, #fff);
  text-decoration: none;
  padding: 34px 30px 0;
  display: flex;
  flex-direction: column;
  width: 456px;
  height: 307px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

  &:hover {
    border: 3px solid var(--brand-color-hover, #8f5409);
    background: var(--brand-color-light, #fff7e8);
    //  TODO: Ten dziwny cień na dole
  }

  &:active {
    box-shadow: none;
    border: 3px solid var(--brand-color-hover, #8f5409);
    background: var(--brand-color-light, #fff7e8);
  }
`;

interface BoxItemInnerProps extends React.HTMLProps<HTMLDivElement> {
  buttonVisible: boolean;
}

const BoxLink = styled(Link)`
  ${Box}
`;

const BoxDiv = styled.div<BoxItemInnerProps>`
  ${Box}

  a {
    text-decoration: none;
  }
    .MuiIconButton-root {
    visibility: ${(props) =>
      props.buttonVisible ? 'visible !important' : 'hidden'};
  }
      &:hover {
      & .MuiIconButton-root {
    visibility: visible;
  }
`;

const Text = styled.p`
  color: var(--Dark, #281302);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.48px;
  margin-bottom: 0;
`;

type ProductsItemsEmptyProps = {
  canCreate: boolean;
};

export function ProductsItemsEmpty(props: ProductsItemsEmptyProps) {
  const link = '/lca/products/create';
  if (!props.canCreate) return <Text>No products available</Text>;
  return (
    <BoxLink to={link}>
      <Text>Create new product {'>'}</Text>
      <div
        style={{
          marginTop: '56px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <EmptyIcon />
      </div>
    </BoxLink>
  );
}

interface Props {
  id: number;
  name: string;
  url: string;
  period: string;
  materials: LcaProductMaterial[];
  last_edited: string;
  canDelete: boolean;
}

const Materials = styled.div<{ color: string }>`
  color: var(--Dark, #281302);
  text-align: center;
  height: 32px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.28px;
  background-color: ${({ color }) => color};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 18px 12px;
  width: fit-content;
`;

const Period = styled.p`
  color: var(--Dark-grey, #4d4d4d);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.54px;
  margin-top: 24px;
  margin-bottom: 0;
`;

type ModalState = 'closed' | 'delete' | 'confirm_delete' | 'copy';

export function ProductsItemsCreated({
  name,
  url,
  materials,
  period,
  last_edited,
  id,
  canDelete,
}: Props) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [modalState, setModalState] = useState<ModalState>('closed');

  const actions = useProductActions(id);

  const handleDeleteModal1ButtonBClick = () => {
    actions.deleteProduct
      .mutateAsync()
      .then(() =>
        toast.success(t('lca.product.modals.delete.toasts.success') as string)
      );
    setModalState('closed');
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setButtonVisible(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setButtonVisible(false);
  };
  return (
    <>
      <BoxDiv buttonVisible={buttonVisible}>
        <Link to={url}>
          <Text>{name}</Text>
        </Link>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Link to={url} style={{ flexGrow: 1 }}>
            <Period>{period}</Period>
            <div style={{ flexGrow: 1 }} />
            <div style={{ display: 'flex', gap: '8px', marginBottom: '32px' }}>
              {materials.map((el) => (
                <Materials key={el.id} color="#79BBAF">
                  {el.material_name}
                </Materials>
              ))}
            </div>
          </Link>
          <StyledFlex
            style={{
              width: '100%',
              borderTop: '1px solid #dadada',
              marginTop: '10px',
            }}
          >
            <BoxDate>{`${t('esg.last-update')} ${new Date(
              last_edited
            ).toLocaleDateString()}`}</BoxDate>

            {canDelete && (
              <HiddenIconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </HiddenIconButton>
            )}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                onClick={() => {
                  setModalState('copy');
                  handleClose();
                }}
              >
                {t('lca.product.copy')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setModalState('delete');
                  handleClose();
                }}
              >
                {t('lca.product.delete')}
              </MenuItem>
            </Menu>
          </StyledFlex>
        </div>
      </BoxDiv>

      <ConfirmationModal
        open={modalState === 'delete'}
        titleKey={t('lca.product.modals.delete.title')}
        textKey={t('lca.product.modals.delete.desc')}
        trueButtonTextKey={t('lca.product.modals.delete.buttons.yes')}
        falseButtonTextKey={t('lca.product.modals.delete.buttons.no')}
        onTrue={() => setModalState('confirm_delete')}
        onFalse={() => setModalState('closed')}
        onClose={() => setModalState('closed')}
      />
      <ConfirmationModal
        open={modalState === 'confirm_delete'}
        titleKey={t('lca.product.modals.confirmDelete.title')}
        textKey={t('lca.product.modals.confirmDelete.desc')}
        trueButtonTextKey={t('lca.product.modals.confirmDelete.buttons.yes')}
        falseButtonTextKey={t('lca.product.modals.confirmDelete.buttons.no')}
        onTrue={handleDeleteModal1ButtonBClick}
        onFalse={() => setModalState('closed')}
        onClose={() => setModalState('closed')}
      />
      <CopyProductModal
        open={modalState === 'copy'}
        onClose={() => setModalState('closed')}
        productId={id}
        productName={name}
      />
    </>
  );
}
