import { useMutation, useQuery, useQueryClient } from 'react-query';
import { client } from 'src/utils/api-client';
import {
  GOODS_AND_SERVICES_OR_CAPITAL_GOODS,
  GhgSupplierSurvey,
  GhgSupplierGoods,
} from '../interfaces';
import {
  GoodsAndServicesData,
  GoodsAndServicesEmissionSharesData,
} from './types';

export const useSupplierSurvey = (organizationId: number) => {
  const endpointUrl = `/web/ghg-suppliers/${organizationId}/survey`;
  const query = useQuery(['ghg-supplier', 'surveys', { organizationId }], () =>
    client
      .get<GhgSupplierSurvey[]>(`${endpointUrl}/all`)
      .then((response) => response.data)
  );

  const create = useMutation({
    mutationFn: (data: {
      product_name: string;
      supplier_name: string;
      organization_id: number;
    }) => client.post(endpointUrl, data),
    onSuccess: () => query.refetch(),
  });

  const remove = useMutation({
    mutationFn: (surveyId: number) =>
      client.delete(`${endpointUrl}/${surveyId}`),
    onSuccess: () => query.refetch(),
  });

  return {
    ...query,
    create,
    remove,
  };
};

export const useSupplierGoods = (
  organizationId: number,
  filter: string = ''
) => {
  const queryClient = useQueryClient();
  const endpointUrl = `/web/ghg-suppliers/${organizationId}/goods`;
  const query = useQuery(
    ['ghg-supplier', 'goods', { organizationId, filter }],
    () =>
      client
        .get<GhgSupplierGoods[]>(`${endpointUrl}/all${filter}`)
        .then((response) => response.data)
  );
  const create = useMutation({
    mutationFn: (data: {
      survey_answer_id: number;
      amount: number;
      type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
      reporting_period: string;
    }) => client.post(endpointUrl, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['ghg-supplier']);
      query.refetch();
    },
  });

  const update = useMutation({
    mutationFn: (data: {
      id: number;
      amount: number;
      type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
      reporting_period: string;
    }) => client.put(`${endpointUrl}/${data.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['ghg-supplier']);
      query.refetch();
    },
  });

  const remove = useMutation({
    mutationFn: (reportingPeriodId: number) =>
      client.delete(`${endpointUrl}/${reportingPeriodId}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['ghg-supplier']);
      query.refetch();
    },
  });

  return { query, create, update, remove };
};

type UseGoodsAndServicesProps = {
  type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
  period: string;
  organization_id: number;
};

export const useGoodsAndServices = (props: UseGoodsAndServicesProps) => {
  const queryClient = useQueryClient();

  const qtype =
    props.type === GOODS_AND_SERVICES_OR_CAPITAL_GOODS.GOODS_AND_SERVICES
      ? 'PurchasedGoodsAndSerivesData'
      : 'CapitalGoodsData';
  const baseUrl = `/web/v2/questionnaires/${qtype}`;
  const queryKey = ['ghg', 'goods-and-services', props];

  const params = {
    period: props.period,
    organization_id: props.organization_id,
  };

  const query = useQuery(queryKey, () =>
    client.get<GoodsAndServicesData>(baseUrl, { params }).then((r) => r.data)
  );

  const queryEmissions = useQuery([...queryKey, 'emissions'], () =>
    client
      .get<GoodsAndServicesEmissionSharesData>(`${baseUrl}/emission_shares`, {
        params,
      })
      .then((r) => r.data)
  );

  const refetch = () =>
    queryClient.invalidateQueries(['ghg', 'goods-and-services']);

  const update = useMutation({
    mutationFn: (data: GoodsAndServicesData) =>
      client.put(baseUrl, data, { params }),
    onSuccess: refetch,
  });

  const isLoading = query.isLoading || queryEmissions.isLoading;
  const isError = query.isError || queryEmissions.isError;

  return {
    query,
    queryEmissions,
    update,
    isLoading,
    isError,
    refetch,
  };
};
