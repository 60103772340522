import React from 'react';
import TopBar from '../../../components/TopBar';
import VideoTutorial, { VideoType } from '../VideoTutorial';
import { IconButton } from '@mui/material';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { useAppSelector } from '../../../redux-file/hooks';
import { OrganizationTag, hasTag } from '../../../utils/tags';

const TUTORIAL_KEY = 'tutorial';
const TUTORIAL_COMPLETED = 'completed';
// TODO: someday, move this to a persisted redux state
//       (with an initial migration)
const isTutorialCompleted = () => {
  return localStorage.getItem(TUTORIAL_KEY) === TUTORIAL_COMPLETED;
};

const setTutorialCompleted = () => {
  localStorage.setItem(TUTORIAL_KEY, TUTORIAL_COMPLETED);
};

export default function GhgTopBar() {
  const [videoTutorialOpen, setVideoTutorialOpen] = React.useState(
    !isTutorialCompleted()
  );
  const organization = useAppSelector(
    (state) => state.platform.activeOrganization
  );
  const isMicroBnp =
    organization &&
    (hasTag(organization, OrganizationTag.mBNP) ||
      hasTag(organization, OrganizationTag.HideScope3));

  const videoType: VideoType = isMicroBnp ? 'mbnp' : 'general';

  const onCompleted = () => {
    setTutorialCompleted();
    setVideoTutorialOpen(false);
  };

  return (
    <TopBar>
      <VideoTutorial
        videoType={videoType}
        open={videoTutorialOpen}
        onClose={onCompleted}
      />
      <IconButton onClick={() => setVideoTutorialOpen(true)}>
        <OndemandVideoIcon />
      </IconButton>
    </TopBar>
  );
}
