import { Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux-file/hooks';
import { setSelectedDate } from 'src/redux-file/thunk';

export default function GhgSelectedYear() {
  const { selectedDate } = useAppSelector((state) => state.questionnaire);
  const dispatch = useAppDispatch();

  const selectedYear = selectedDate.getFullYear();

  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const start = 2000;
  const end = 2036;
  const range = Array.from({ length: end - start + 1 }, (_, i) => start + i);

  return (
    <>
      <Typography
        sx={{ ml: '20px', cursor: 'pointer' }}
        onClick={(e) => {
          setAnchor(e.currentTarget);
          e.stopPropagation();
        }}
      >
        {selectedDate.getFullYear()}
      </Typography>
      <Menu
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        sx={{ maxHeight: '400px' }}
      >
        {range.map((year) => (
          <MenuItem
            key={year}
            sx={{ minWidth: '200px' }}
            selected={year === selectedYear}
            onClick={() => {
              dispatch(setSelectedDate(new Date(year, 0, 1)));
              setAnchor(null);
            }}
          >
            {year}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
