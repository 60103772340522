import React from 'react';
import { Desc, Heading, Title } from './index.styles';
import { StyledTextField } from './productInformation';
import GoalsBasic from './forms/goals-basic';
import Assertions from './forms/assertions';
import ToggleButtonLCA from './ToggleButton';

interface Props {
  values: {
    goals: {
      intended_applications: string;
      study_reasons: string;
      target_audience: string;
      comissioner_name: string;
      external_experts_involved: boolean;
      external_experts_description: string;
      results_disclosed: boolean;
      assertions_disclosed: boolean;
      results_confidential: boolean;
      assertions_confidential: boolean;
      disclosure_description: string;
      comment: string;
    };
  };
  setValues: Function;
}

export default function Goals({ setValues, values }: Props) {
  const handle_external_experts_involved = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({
      ...values,
      goals: {
        ...values.goals,
        external_experts_involved: e.target.value === 'true',
      },
    });
  };
  const handle_external_experts_description = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({
      ...values,
      goals: {
        ...values.goals,
        external_experts_description: e.target.value,
      },
    });
  };

  const handle_comment = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({
      ...values,
      goals: {
        ...values.goals,
        comment: e.target.value,
      },
    });
  };

  const options = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];

  return (
    <div>
      <Title>Goal(s)</Title>
      <Desc>
        Defining the objective is the first step of a PEF (Product Environmental
        Footprint) study and sets its overall context, clearly stating the
        purpose of conducting the LCA. It could be, for example, “to compare the
        environmental impacts of two different products” or “to identify
        opportunities for improving the sustainability of a process”. <br />
        The reason for clearly defining the objectives is to make sure that the
        analytical objectives, methods, results and intended applications are
        optimally aligned and that there is a common vision to guide you.
      </Desc>
      <Heading>Basic information</Heading>
      <GoalsBasic values={values} setValues={setValues} />
      <Heading>
        Are there any external experts or stakeholders who need to be involved
        in the review?
      </Heading>
      <ToggleButtonLCA
        options={options}
        setState={handle_external_experts_involved}
        state={values.goals.external_experts_involved}
      />
      {values.goals.external_experts_involved && (
        <StyledTextField
          style={{ marginTop: 24 }}
          fullWidth
          label="Please name them"
          name="external_experts_description"
          value={values.goals.external_experts_description}
          onChange={handle_external_experts_description}
        />
      )}
      <Heading>
        Should the results and/or comparative assertions to be disclosed to the
        public or kept confidential?
      </Heading>
      <Assertions values={values} setValues={setValues} />
      <Heading>Additional information</Heading>
      <StyledTextField
        fullWidth
        label="Is there anything else you find important to mention in this section? (optional)"
        name="comment"
        value={values.goals.comment}
        onChange={handle_comment}
      />
    </div>
  );
}
