import {
  EsgUserPermissionSubtopics,
  GhgUserPermissionSchema,
  Indicator,
  IndicatorCategory,
  Question,
} from './types';

interface ByOrganization<T> {
  [key: string]: T;
}

interface ByYear {
  [key: string]: ByOrganization<GhgUserPermissionSchema>;
}

interface GhgUserPermissonsObject {
  [key: string]: ByYear;
}

// eslint-disable-next-line import/prefer-default-export
export const parseGhgUserPermissonsObject = (
  ghgUserPermissons: GhgUserPermissionSchema[]
) => {
  const permissions: GhgUserPermissonsObject = {};

  ghgUserPermissons.forEach((perm: GhgUserPermissionSchema) => {
    const { scope, section, year, organization_id: organizationId } = perm;

    // initialize year
    if (!permissions[year]) permissions[year] = {};

    if (!!organizationId) {
      // if organization is defined initialize organization
      if (!permissions[year][organizationId])
        permissions[year][organizationId] = {};

      // add if either scope or section is defined
      // assign permission to the key of the form scope::section
      if (scope || section) {
        const key = `${scope}::${section}`;
        permissions[year][organizationId][key] = perm;
      }
    }
  });
  return permissions;
};

export const parseEsgUserPermissonsSubtopics = (
  indicators: Indicator[],
  indicatorCategories: IndicatorCategory[],
  questions: Question[]
) => {
  // create a map of indicator categories
  const indicatorCategoriesMap: { [key: string]: IndicatorCategory } =
    Object.fromEntries(indicatorCategories.map((cat) => [cat.id, cat]));

  // initialize subtopics
  const subtopics: EsgUserPermissionSubtopics = {};

  // iterate over permissions and group them by indicator category
  indicators.forEach((indicator) => {
    // register indicator category if not already registered
    if (!subtopics[indicator.esg_indicator_category_id])
      subtopics[indicator.esg_indicator_category_id] = {
        indicators: [],
        indicatorCategory:
          indicatorCategoriesMap[indicator.esg_indicator_category_id],
      };

    // add permission to the subtopic
    subtopics[indicator.esg_indicator_category_id].indicators.push({
      ...indicator,
      questions: questions.filter(
        (q) => q.questionnaire_id === indicator.questionnaire_id
      ),
    });
  });

  return subtopics;
};
