import React from 'react';
import { useParams } from 'react-router-dom';

import { useEsgOrganization } from 'src/common/hooks';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';

import { StyledPanel } from 'src/Esg/styles';
import { useEsgStakeholderAnalytics } from '../hooks';
import NoData from 'src/components/NoData';
import Qv3SummaryTable from 'src/views/components/QuestionnaireV3/Summary/SummaryTable';
import { Qv3SummaryTableType } from 'src/views/components/QuestionnaireV3/Summary/types';
import { useTranslation } from 'react-i18next';

const T_KEY = 'esg.dma.stakeholderAnalytics.tables';
const COLUMNS = ['form', 'question', 'answer'];

export default function Individual() {
  const { t } = useTranslation();
  const { reportId } = useParams();
  const { id: organizationId } = useEsgOrganization();

  const { data, isError } = useEsgStakeholderAnalytics(
    organizationId,
    reportId as string
  );

  if (isError) return <SomethingWentWrong />;
  if (data === undefined) return <PageLoading />;
  if (Object.keys(data).length === 0) return <NoData />;

  return (
    <StyledPanel sx={{ minWidth: '940px' }}>
      <Qv3SummaryTable
        data={data}
        tableType={Qv3SummaryTableType.Subtopic}
        customColumns={COLUMNS.map((col) => t(`${T_KEY}.columns.${col}`))}
      />
    </StyledPanel>
  );
}
