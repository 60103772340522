import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import entries from './entries';

export default function Glossary() {
  return (
    <Box>
      <Typography variant="h1">Glossary</Typography>
      <br />
      <br />
      <StyledPanel>
        <Table>
          <TableBody>
            {entries.map((entry, idx) => (
              <TableRow key={`${idx}-${entry.term}`}>
                <TableCell>
                  <Typography variant="h6">{entry.term}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{entry.definition}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledPanel>
    </Box>
  );
}
