import { unstable_useBlocker } from 'react-router-dom';
import ConfirmationModal from '../ConfirmationModal';
import React from 'react';

type NavigationBlockerModalProps = {
  shouldBlock: boolean;
};

export default function NavigationBlockerModal(
  props: NavigationBlockerModalProps
) {
  const blocker = unstable_useBlocker((args) => {
    console.log(args, props.shouldBlock);
    return (
      args.currentLocation.pathname !== args.nextLocation.pathname &&
      props.shouldBlock
    );
  }); // this is no longer unstable in newer versions

  return (
    <ConfirmationModal
      open={blocker.state === 'blocked' && props.shouldBlock}
      titleKey="navigationBlockerModal.title"
      textKey="navigationBlockerModal.text"
      falseButtonTextKey="navigationBlockerModal.stay"
      trueButtonTextKey="navigationBlockerModal.leave"
      onFalse={() => blocker.reset?.()}
      onClose={() => blocker.reset?.()}
      onTrue={() => blocker.proceed?.()}
    />
  );
}
