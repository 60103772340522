import { useMutation, useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import * as types from 'src/Ghg/CodeOfConductSurvey/types';
import { useParams } from 'react-router-dom';

export const useCodeOfConductSurvey = () => {
  const { token } = useParams();

  const baseUrl = '/web/ghg-suppliers/code-of-conduct-surveys';

  const query = useQuery(
    ['ghg-supplier', 'code-of-conduct-survey', { token }],
    () =>
      client
        .get<types.GhgSupplierCodeOfConductSurveyByTokenOutSchema>(
          `${baseUrl}/by-token`,
          { params: { token } }
        )
        .then((response) => response.data)
  );

  const submit = useMutation({
    mutationFn: (data: types.GhgSupplierCodeOfConductSurveyAnswerInSchema) =>
      client.post<types.GhgSupplierCodeOfConductSurveyAnswerSchema>(
        '/web/ghg-suppliers/code-of-conduct-survey-answers',
        data,
        { params: { token } }
      ),
    onSuccess: () => query.refetch(),
  });

  const attachmentUploadUrl = `/web/ghg-suppliers/code-of-conduct-survey-attachments?token=${token}`;
  const survey = query.data;
  const isAnswerUptoDate =
    survey && survey.answer?.shown_file.id === survey.code_of_conduct.id;

  return {
    query,
    submit,
    attachmentUploadUrl,
    isAnswerUptoDate,
  };
};
