import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import BasePage from '../Base';
import { CarbonFootprintTimelineDataType } from './types';
import PDFChart from '../../../components/PDFChart';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  chart: { margin: '221px 0px 0px 0px' },
});

type CarbonFootprintTimelineProps = {
  data: {
    carbon_footprint_timeline: CarbonFootprintTimelineDataType;
  };
};

const CarbonFootprintTimeline = ({ data }: CarbonFootprintTimelineProps) => {
  const {
    chart,
    page_title: pageTitle,
    top_right: topRight,
  } = data.carbon_footprint_timeline;
  return (
    <BasePage
      backgroundImageName="Report_envirly_slide_8.png"
      mainHeader={pageTitle}
      topRight={topRight}
    >
      <View>
        <PDFChart base64={chart} style={styles.chart} />
      </View>
    </BasePage>
  );
};

export default CarbonFootprintTimeline;
