import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTitle = styled(Typography)`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0.48px;
`;

export const StyledSubtitle = styled(Typography)`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.54px;
`;
