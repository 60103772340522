import React from 'react';

export default function GoodsAndServicesIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11_7382)">
        <path
          d="M7 18.6182C5.9 18.6182 5.01 19.5345 5.01 20.6545C5.01 21.7745 5.9 22.6909 7 22.6909C8.1 22.6909 9 21.7745 9 20.6545C9 19.5345 8.1 18.6182 7 18.6182ZM1 2.32727V4.36363H3L6.6 12.0916L5.25 14.5862C5.09 14.8713 5 15.2073 5 15.5636C5 16.6836 5.9 17.6 7 17.6H19V15.5636H7.42C7.28 15.5636 7.17 15.4516 7.17 15.3091L7.2 15.1869L8.1 13.5273H15.55C16.3 13.5273 16.96 13.1098 17.3 12.4785L20.88 5.87054C20.96 5.728 21 5.55491 21 5.38182C21 4.82182 20.55 4.36363 20 4.36363H5.21L4.27 2.32727H1ZM17 18.6182C15.9 18.6182 15.01 19.5345 15.01 20.6545C15.01 21.7745 15.9 22.6909 17 22.6909C18.1 22.6909 19 21.7745 19 20.6545C19 19.5345 18.1 18.6182 17 18.6182Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_7382">
          <rect
            width="24"
            height="24.4364"
            fill="currentColor"
            transform="translate(0 0.290894)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
