import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<App />} />)
);
root.render(<RouterProvider router={router} />);
