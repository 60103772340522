import React from 'react';
import { useAppSelector } from 'src/redux-file/hooks';
import PasswordSetModal from 'src/views/components/PasswordSetModal';

interface Props {
  children: React.ReactNode;
}

export default function PasswordActionProvider(props: Props) {
  const { user } = useAppSelector((state) => state.platform);

  if (user?.isPasswordSet === false) {
    return <PasswordSetModal />;
  }

  // TODO EN-1686: PasswordChangeModal and SuggestPasswordChangeModal

  return <div>{props.children}</div>;
}
