import React from 'react';
import { useQuery } from 'react-query';

import { client } from 'src/utils/api-client';
import ProcessObjectPanel from './ProcessObjectPanel';
import { ModalProvider } from './Modals/Provider';
import FilterPanel from './FilterPanel';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import { LcaManagementTab } from './index.types';

export default function LcaDriagramManagement({
  productId,
  tab,
  setTab,
}: {
  productId: string | number;
  tab: LcaManagementTab;
  // eslint-disable-next-line no-unused-vars
  setTab: (tab: LcaManagementTab) => void;
}) {
  const { error, data: categories } = useQuery(`lca-categories`, () =>
    client.get('/web/lca/categories').then((response) => response.data)
  );

  if (error) return <SomethingWentWrong />;
  if (categories === undefined) return <PageLoading />;

  return (
    <ModalProvider>
      <ProcessObjectPanel categories={categories} productId={productId} />
      <FilterPanel productId={productId} tab={tab} setTab={setTab} />
    </ModalProvider>
  );
}
