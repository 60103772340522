import React from 'react';
import { useTranslation } from 'react-i18next';
import createPlotlyComponent from 'react-plotly.js/factory';
import localeFunction from './plotly-locale';
import Plotly from 'customplotly';
import styles from '../styles.module.css';
import NoData from '../NoData';
import PageLoading from '../../../../components/PageLoading';
import SomethingWentWrong from '../../../../components/SomethingWentWrong';
import { ChartDataProps } from '../../../../redux-file/charts/interface';
import { ColorBox, Legend, LegendWrapper } from './index.styles';

const Plot = createPlotlyComponent(Plotly);

export default function GraphComponent({
  plotData,
  error,
  errorType,
  errorMsg,
  loading,
  showCustomLegend,
}: {
  plotData: ChartDataProps | null | undefined;
  error: boolean;
  errorType?: string;
  errorMsg?: string;
  loading: boolean;
  showCustomLegend?: boolean;
}) {
  const { t } = useTranslation();
  const localeDictionary = localeFunction();

  if (loading) return <PageLoading />;
  if (error || errorType || !plotData) {
    if (errorType === 'no-data') return <NoData errorMessage={errorMsg} />;
    else return <SomethingWentWrong errorMessege={errorMsg} />;
  }

  const data = JSON.parse(JSON.stringify(plotData?.data));
  const layout = JSON.parse(JSON.stringify(plotData?.layout));

  const legend = data.map((el: any) => ({
    name: el?.name,
    color: el?.marker?.color,
  }));

  return (
    <div>
      {plotData ? (
        <Plot
          layout={layout}
          data={data}
          config={{
            displayModeBar: true,
            displaylogo: false,
            locales: { lang: localeDictionary },
            locale: 'lang',
            modeBarButtonsToRemove: [
              'zoom2d',
              'pan2d',
              'select2d',
              'lasso2d',
              'zoomIn2d',
              'zoomOut2d',
              'autoScale2d',
              'resetScale2d',
              'zoomInGeo',
              'zoomOutGeo',
              'resetGeo',
            ],
          }}
          useResizeHandler
          style={{ width: '100%', height: '100%', minHeight: '500px' }}
        />
      ) : null}
      {showCustomLegend ? (
        <LegendWrapper>
          {legend.map((el: { color: string; name: string }) => (
            <Legend key={el.name}>
              <ColorBox color={el.color} />
              <span>{el.name}</span>
            </Legend>
          ))}
        </LegendWrapper>
      ) : null}
      {plotData.data.length === 0 && (
        <p className={styles.noData}>{t('dashboard.no-data')}</p>
      )}
    </div>
  );
}
