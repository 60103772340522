import React from 'react';
import { FieldValue, UniversalField } from '../types';
import { parseValueToInteger } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { Rating as MuiRating, FormControl, FormLabel } from '@mui/material';
import { StyledErrorMessage, StyledTitle } from '../../index.styles';

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';

export default function Rating(props: UniversalField) {
  const { t } = useTranslation();

  const { field, value, onChange, errors, disabled } = props;
  const { title, label, required } = field;

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  /* backend side validation */
  React.useEffect(() => {
    const newMessage = errors?.[field.key];
    if (!!newMessage) setErrorMessage(newMessage);
  }, [errors]);

  /* Frontend side validation */
  const _validate = (_value: FieldValue) => {
    // required field - validation error on false
    if (required && !_value) {
      setErrorMessage(t('questionnaireV3.field.fieldRequired'));
    }
    // cleaning error messages if none
    else if (!!errorMessage) {
      setErrorMessage(undefined);
    }
  };

  // local handle change injects frontend validation
  const handleChange = (e: any) => {
    const { value: _value } = e.target;
    const parsedValue = parseValueToInteger(_value);
    _validate(parsedValue);
    onChange(parsedValue);
  };

  const _value = parseValueToInteger(value);

  return (
    <div style={{ marginBottom: '16px' }}>
      {!!title && <StyledTitle>{title}</StyledTitle>}
      <FormControl error={!!errorMessage}>
        <FormLabel sx={{ p: '5px' }}>{label}</FormLabel>
        <MuiRating
          max={10}
          value={_value}
          icon={<CircleIcon sx={{ width: '48px' }} />}
          emptyIcon={<CircleOutlinedIcon sx={{ width: '48px' }} />}
          disabled={disabled}
          onChange={handleChange}
          className={!!errorMessage ? 'Mui-error' : ''}
        />
      </FormControl>
      <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
    </div>
  );
}
