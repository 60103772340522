import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// eslint-disable-next-line import/prefer-default-export
export const StyledPanel = styled(Box)`
  border-radius: 25px;
  padding: 20px;
  background-color: #fff;
  margin-top: 30px;
`;
