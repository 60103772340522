import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  Column2Grid,
  FlexContainer,
  Grid,
  GridItem,
  GridRow,
  GridWrapper,
  Row2Grid,
  Row3Grid,
} from './index.styles';
import { DmaMatrixDatapoint } from '../DmaMatrix';
import { IconButton } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { StyledFlex } from '../../../../components/StyledFlex';

const displayValueOrFallback = (
  value: number | null | undefined,
  fallback: string = '-'
) => {
  if (value !== null && value !== undefined) {
    return (value * 10).toFixed(2);
  }
  return fallback;
};

const getBackgroundColor = (value: number | null): string => {
  const startAlpha = 0.02;
  const endAlpha = 0.5;

  if (value === null) {
    return 'rgba(0, 0, 0, 0)';
  }

  const alpha = startAlpha + (endAlpha - startAlpha) * value;

  return `rgba(233, 188, 70, ${alpha})`;
};

interface ScoreGridItemProps {
  score: number | null;
}

function ScoreGridItem({ score }: ScoreGridItemProps) {
  return (
    <GridItem
      style={{
        font: 'Poppins',
        fontSize: '18px',
        fontWeight: 400,
        backgroundColor: getBackgroundColor(score),
      }}
    >
      {displayValueOrFallback(score)}
    </GridItem>
  );
}

interface DmaMatrixTableProps {
  data: Record<string, DmaMatrixDatapoint>;
  onButtonClick: (key: string) => void;
  visibleDataPoints: Record<string, boolean>;
}

export default function DmaMatrixTable(props: DmaMatrixTableProps) {
  const { t } = useTranslation();

  return (
    <GridWrapper>
      <Grid>
        <Row2Grid>
          <GridItem style={{ backgroundColor: '#E8E8E8' }}>
            {t('esg.dmaGrid.horizon')}
          </GridItem>
          <Column2Grid style={{ backgroundColor: '#F6F6F6' }}>
            <GridItem
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#F6F6F6',
                borderRight: 'none',
                marginRight: '-1px',
              }}
            >
              {t('esg.dmaGrid.symbol')}
            </GridItem>
            <GridItem
              style={{
                display: 'flex',
                backgroundColor: '#F6F6F6',
                borderLeft: 'none',
                justifyContent: 'start',
              }}
            >
              {t('esg.dmaGrid.subTopic')}
            </GridItem>
          </Column2Grid>
        </Row2Grid>
        <Row3Grid>
          <GridItem style={{ backgroundColor: '#E8E8E8' }}>
            {t('esg.dmaGrid.shortTerm')}
          </GridItem>
          <GridItem style={{ backgroundColor: '#F6F6F6' }}>
            {t('esg.dmaGrid.materiality')}:
          </GridItem>
          <FlexContainer style={{ backgroundColor: '#F6F6F6' }}>
            <GridItem>{t('esg.dmaGrid.impact')}</GridItem>
            <GridItem>{t('esg.dmaGrid.financial')}</GridItem>
          </FlexContainer>
        </Row3Grid>
        <Row3Grid>
          <GridItem style={{ backgroundColor: '#E8E8E8' }}>
            {t('esg.dmaGrid.mediumTerm')}
          </GridItem>
          <GridItem style={{ backgroundColor: '#F6F6F6' }}>
            {t('esg.dmaGrid.materiality')}:
          </GridItem>
          <FlexContainer style={{ backgroundColor: '#F6F6F6' }}>
            <GridItem>{t('esg.dmaGrid.impact')}</GridItem>
            <GridItem>{t('esg.dmaGrid.financial')}</GridItem>
          </FlexContainer>
        </Row3Grid>
        <Row3Grid>
          <GridItem style={{ backgroundColor: '#E8E8E8' }}>
            {t('esg.dmaGrid.longTerm')}
          </GridItem>
          <GridItem style={{ backgroundColor: '#F6F6F6' }}>
            {t('esg.dmaGrid.materiality')}:
          </GridItem>
          <FlexContainer style={{ backgroundColor: '#F6F6F6' }}>
            <GridItem>{t('esg.dmaGrid.impact')}</GridItem>
            <GridItem>{t('esg.dmaGrid.financial')}</GridItem>
          </FlexContainer>
        </Row3Grid>
        <GridItem style={{ backgroundColor: '#E8E8E8' }}>
          {t('esg.dmaGrid.impact')}
        </GridItem>
        <GridItem>{t('esg.dmaGrid.showOnGraph')}</GridItem>
      </Grid>
      {Object.entries(props.data).map(([key, value]) => (
        <GridRow key={key}>
          <Column2Grid>
            <GridItem
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <StyledFlex
                sx={{
                  width: '32px',
                  height: '32px',
                  backgroundColor: value.esg_topic_color,
                  borderRadius: '50%',
                  justifyContent: 'center',
                }}
              >
                {/*hide borders*/}
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                  }}
                />
                {key}
              </StyledFlex>
            </GridItem>
            <GridItem style={{ textAlign: 'left' }}>
              {value.esg_topic_title}
            </GridItem>
          </Column2Grid>
          <ScoreGridItem
            score={value.scores.short.nonfinancial as number | null}
          />
          <ScoreGridItem
            score={value.scores.short.financial as number | null}
          />
          <ScoreGridItem
            score={value.scores.medium.nonfinancial as number | null}
          />
          <ScoreGridItem
            score={value.scores.medium.financial as number | null}
          />
          <ScoreGridItem
            score={value.scores.long.nonfinancial as number | null}
          />
          <ScoreGridItem score={value.scores.long.financial as number | null} />

          <GridItem style={{ backgroundColor: '#E8E8E8', fontSize: '12px' }}>
            {t(`esg.dmaGrid.impactLevel.${value.impact_level}`)}
          </GridItem>
          <GridItem>
            <IconButton
              sx={{ color: '#38414F', zIndex: 999 }}
              onClick={() => {
                props.onButtonClick(key);
              }}
            >
              {key !== undefined && props.visibleDataPoints[key] === true ? (
                <CheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
            </IconButton>
          </GridItem>
        </GridRow>
      ))}
    </GridWrapper>
  );
}
