import React from 'react';

import { Box, SxProps } from '@mui/material';
import YearSelector from 'src/Superadmin/components/Panels/YearSelector';
import { StyledPanel, StyledTitle } from 'src/Superadmin/index.styles';
import { useEsgUserPermissions } from '../hooks';
import { Report } from '../types';
import EsgUserPermissionsTable from './Table';
import { t } from 'i18next';

interface Props {
  sx?: SxProps;
  userId: number;
}

export default function EsgUserPermissions(props: Props) {
  const { sx, userId } = props;

  const [year, setYear] = React.useState<number | null>(null);

  const { options } = useEsgUserPermissions(userId);

  React.useEffect(() => {
    if (year === null && options.reports.length > 0)
      setYear(options.reports[0].period);
  }, [options.reports]);

  const availableYears = options.reports.map((r: Report) => r.period);
  const minYear = Math.min(...availableYears);
  const maxYear = Math.max(...availableYears);

  return (
    <Box sx={sx}>
      <StyledTitle sx={{ fontSize: '28px' }}>
        {t('superadmin.settings.tabs.users.usersTable.permissions.title.esg')}
      </StyledTitle>

      {year && (
        <YearSelector
          startYear={minYear}
          endYear={maxYear}
          selectedYear={year}
          availableYears={availableYears}
          onYearChange={setYear}
        />
      )}

      <StyledPanel sx={{ mt: '32px' }}>
        <EsgUserPermissionsTable userId={userId} year={year} />
      </StyledPanel>
    </Box>
  );
}
