import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import BasePage from '../Base';
import { CoverDataType } from './types';
import { LOGO_MARGIN_LEFT } from '../../config';
import PDFChart from '../../../components/PDFChart';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  containerStyle: {
    position: 'absolute',
    top: 380,
    left: LOGO_MARGIN_LEFT,
    width: 700,
  },
  sectionTitle: {
    color: '#165D5C',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Poppins',
    fontSize: 60,
    fontStyle: 'bold',
    fontWeight: 700,
    letterSpacing: 2,
  },
  reportRange: {
    color: '#B3B3B3',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Poppins',
    fontSize: 36,
    fontStyle: 'bold',
    letterSpacing: 1,
    marginBottom: 20,
  },
  description: {
    color: '#606462',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Poppins',
    fontSize: 26,
    fontStyle: 'normal',
  },
  chartText: {
    position: 'absolute',
    top: 930,
    left: 920,
    width: 500,
    color: '#FFF',
    leadingTrim: 'both',
    textEdge: 'cap',
    textShadow: '2px 2px black',
    fontFamily: 'Poppins',
    fontSize: 36,
    fontStyle: 'normal',
    fontWeight: 500,
  },
  chart: {
    position: 'absolute',
    top: 150,
    left: 1000,
    width: 800,
    height: 800,
  },
});

type CoverPageProps = {
  data: {
    cover: CoverDataType;
  };
};

const CoverPage = ({ data }: CoverPageProps) => {
  const {
    section_title: sectionTitle,
    report_range: reportRange,
    chart_text: chartText,
    employee_surveys_pct_chart: employeeSurveyPctChart,
    description,
  } = data.cover;
  return (
    <BasePage backgroundImageName="Report_envirly_slide_13.png">
      <View>
        <View style={styles.containerStyle}>
          <Text style={styles.sectionTitle}>{sectionTitle}</Text>
          <Text style={styles.reportRange}>{reportRange}</Text>
          <Text style={styles.description}>{description}</Text>
        </View>
        <Text style={styles.chartText}>{chartText}</Text>
        <PDFChart base64={employeeSurveyPctChart} style={styles.chart} />
      </View>
    </BasePage>
  );
};

export default CoverPage;
