import React, { useState } from 'react';
import styled from 'styled-components';
import UploadModal from '../UploadModal';
import { Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UploadedFileSchema } from '../../Ghg/CodeOfConductSurvey/types';
import { StyledFileButton } from './styles';
import { Close } from '@mui/icons-material';
import { FileUploadError } from '../UploadModal/types';

const FilesWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

type SingleFileUploadProps = {
  url: string;
  file: UploadedFileSchema | null;
  attachButtonText?: string;
  onSuccess: (file: UploadedFileSchema) => Promise<unknown> | unknown;
  onRemoved?: (file: UploadedFileSchema) => Promise<unknown> | unknown;
  tryExtractErrorMessage?: (uploadError: FileUploadError) => string | undefined;
};

export default function SingleFileUpload(props: SingleFileUploadProps) {
  const { t } = useTranslation();
  const [uploadOpen, setUploadOpen] = useState(false);

  const removeFile = () => {
    if (!props.file) return;
    props.onRemoved?.(props.file);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      {props.file && (
        <FilesWrapper>
          <StyledFileButton>
            <a href={props.file.url} target="_blank" rel="noreferrer">
              {props.file.name}
            </a>
            {props.onRemoved && (
              <IconButton onClick={removeFile}>
                <Close />
              </IconButton>
            )}
          </StyledFileButton>
        </FilesWrapper>
      )}

      <Button
        sx={{ marginTop: '20px' }}
        variant="contained"
        color="secondary"
        onClick={() => setUploadOpen(true)}
        type="button"
      >
        {props.attachButtonText || t('esg.attach')}
      </Button>

      <UploadModal
        url={props.url}
        open={uploadOpen}
        onClose={() => setUploadOpen(false)}
        onSuccess={props.onSuccess}
        tryExtractErrorMessage={props.tryExtractErrorMessage}
      />
    </div>
  );
}
