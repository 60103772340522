import React from 'react';

export default function SurveyIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 15.5455H3V17.5818H15V15.5455ZM15 7.4H3V9.43636H15V7.4ZM3 13.5091H21V11.4727H3V13.5091ZM3 21.6545H21V19.6182H3V21.6545ZM3 3.32727V5.36363H21V3.32727H3Z"
        fill="currentColor"
      />
    </svg>
  );
}
