import React, { useState } from 'react';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { ArrowIcon } from '../../../views/Pages/ESG/Report/icon';

interface Props {
  tooltip_name: string;
  sortField: 'create_date' | 'response_date';
  order: 'asc' | 'desc';
  items: Array<{
    name: string;
    onClick: () => void;
  }>;
}

export default function ColumnMenu({
  tooltip_name,
  items,
  sortField,
  order,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openSortModal = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title={tooltip_name}>
        <button
          style={{
            background: 'transparent',
            padding: 0,
          }}
          type="button"
          id="create_date"
          aria-controls={openSortModal ? 'create_date' : undefined}
          aria-haspopup="true"
          aria-expanded={openSortModal ? 'true' : undefined}
          onClick={handleClick}
        >
          {sortField === 'create_date' && order === 'asc' ? (
            <ArrowIcon order="asc" />
          ) : (
            <ArrowIcon order="desc" />
          )}
        </button>
      </Tooltip>
      <Menu
        id="create_date-menu"
        aria-labelledby="create_date"
        anchorEl={anchorEl}
        open={anchorEl?.id === 'create_date'}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.map((el) => (
          <MenuItem
            onClick={() => {
              el.onClick();
              handleClose();
            }}
          >
            {el.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
