import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

import ProductionProcessEdit from './Edit';
import { useProductionProcesses } from '../hooks';
import { StyledFlex } from 'src/components/StyledFlex';

export default function ProductionProcess() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, delete: _delete } = useProductionProcesses();
  const productionProcess = data.find((p) => p.id === Number(id));
  if (!productionProcess) return null;
  return (
    <Box>
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <IconButton onClick={() => navigate('..')}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="h2">
          Production Process: {productionProcess?.name || 'New'}
        </Typography>
      </StyledFlex>
      <br />
      <ProductionProcessEdit id={Number(id)} />
    </Box>
  );
}
