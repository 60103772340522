import React, { ReactNode, useState } from 'react';
import {
  Box,
  Button,
  Wrapper,
  Title,
  DosTitle,
  DosWrapper,
  Dos,
  StyledSecondaryText,
  StyledPrimaryText,
} from './index.styled';
import { useTranslation } from 'react-i18next';
import { activeHelper } from '../../../utils/helpers';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface QuestionnaireSectionTitleIP {
  text: string;
  level: 'primary' | 'secondary';
  boxContent?: {
    title: string;
    desc: string;
    dos?: string[];
    'dos-title'?: string;
    'donts-title'?: string;
    donts?: string[];
  };
  children?: ReactNode;
  active: boolean | number | undefined;
}
export default function QuestionnaireSectionTitle({
  text,
  boxContent,
  children,
  level,
  active,
}: QuestionnaireSectionTitleIP) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Box>
      {level === 'primary' && (
        <StyledPrimaryText dangerouslySetInnerHTML={{ __html: text }} />
      )}
      {level === 'secondary' && (
        <StyledSecondaryText dangerouslySetInnerHTML={{ __html: text }} />
      )}
      {(!!boxContent || !!children) && (
        <Wrapper active={activeHelper(active)}>
          <Button type="button" onClick={() => setOpen(true)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14V10C9 9.44771 9.44772 9 10 9C10.5523 9 11 9.44772 11 10V14ZM11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6Z"
                fill="#388276"
              />
            </svg>

            {t('find-out-more.button-text')}
          </Button>
          <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
            <IconButton
              sx={{
                position: 'absolute',
                right: '10px',
                top: '10px',
              }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon sx={{ fontSize: '24px' }} />
            </IconButton>
            <DialogContent>
              {boxContent && (
                <>
                  <Title>{t(boxContent?.title || '')}</Title>
                  <p
                    style={{ color: '#606462' }}
                    dangerouslySetInnerHTML={{
                      __html:
                        boxContent && boxContent.desc
                          ? t(boxContent?.desc)
                          : '',
                    }}
                  />
                  <DosWrapper>
                    {boxContent?.dos && boxContent?.dos?.length > 0 && (
                      <div>
                        <DosTitle>
                          {t(boxContent?.['dos-title'] || '')}
                        </DosTitle>
                        <div>
                          {boxContent?.dos.map((el) => (
                            <Dos key={el}>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="8" cy="8" r="8" fill="#388276" />
                                <rect
                                  x="4.55859"
                                  y="7.29004"
                                  width="5.05263"
                                  height="1.68421"
                                  rx="0.842105"
                                  transform="rotate(45 4.55859 7.29004)"
                                  fill="white"
                                />
                                <rect
                                  x="11.7031"
                                  y="4.9082"
                                  width="1.68421"
                                  height="8.42105"
                                  rx="0.842105"
                                  transform="rotate(45 11.7031 4.9082)"
                                  fill="white"
                                />
                              </svg>
                              {t(el)}
                            </Dos>
                          ))}
                        </div>
                      </div>
                    )}
                    {boxContent?.donts && boxContent?.donts?.length > 0 && (
                      <div>
                        <DosTitle>
                          {t(boxContent?.['donts-title'] || '')}
                        </DosTitle>
                        <div>
                          {boxContent?.donts.map((el) => (
                            <Dos key={el}>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 3C8.55229 3 9 3.44772 9 4V9C9 9.55228 8.55229 10 8 10C7.44772 10 7 9.55229 7 9V4C7 3.44772 7.44772 3 8 3ZM8 13C8.55229 13 9 12.5523 9 12C9 11.4477 8.55229 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z"
                                  fill="#CF6367"
                                />
                              </svg>
                              {t(el)}
                            </Dos>
                          ))}
                        </div>
                      </div>
                    )}
                  </DosWrapper>
                </>
              )}
              {children}
            </DialogContent>
          </Dialog>
        </Wrapper>
      )}
    </Box>
  );
}
