import React from 'react';
import { useLcaDiagram, useProductImpacts } from '../hooks';
import { StyledPanel } from '../styles';
import {
  StyledCellSubValue,
  StyledCellHeader,
  StyledCellValue,
  StyledTableCell,
  StyledTitle,
} from './styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { prepData } from './utils';
import { Column, Row } from './types';
import Label from './Label';
import { useParams } from 'react-router-dom';
import { parseNumericId } from 'src/utils/navigation';

export default function ImpactGrid() {
  const { id } = useParams();
  const productId = parseNumericId(id);
  const { data: diagramData } = useLcaDiagram(productId);
  const { data: impactData } = useProductImpacts(productId);
  const data = prepData(diagramData, impactData);

  return (
    <StyledPanel>
      <StyledTitle>Impact Assessment</StyledTitle>
      <br />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ textAlign: 'center' }}>
              <StyledCellHeader>IMPACT CATEGORY</StyledCellHeader>
            </TableCell>
            {data.columns.map((col: { key: string; name: string }) => (
              <TableCell key={col.key} sx={{ textAlign: 'center' }}>
                <StyledCellHeader>{col.name}</StyledCellHeader>
              </TableCell>
            ))}
            <TableCell sx={{ textAlign: 'center', backgroundColor: '#f5f5f5' }}>
              <StyledCellHeader>SUMMARY</StyledCellHeader>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.rows.map((row: Row) => {
            return (
              <React.Fragment key={row.impactCategory.value}>
                {/* rendering two rows (value row, summary row underneath) */}
                <TableRow>
                  <TableCell rowSpan={2} colSpan={1}>
                    <StyledCellValue sx={{ textAlign: 'left' }}>
                      {row.impactCategory.value}
                    </StyledCellValue>
                    <br />
                    <StyledCellSubValue>
                      {row.impactCategory.subvalue}
                    </StyledCellSubValue>
                  </TableCell>
                  {data.columns.map((col: Column) => {
                    const {
                      value,
                      rowSpan,
                      colSpan,
                      summary,
                      color = undefined,
                    } = row.columns[col.key] || {};
                    return (
                      <StyledTableCell
                        key={`${row.impactCategory}-${col.key}-summary`}
                        rowSpan={rowSpan}
                        colSpan={colSpan}
                      >
                        <Label
                          color={color}
                          max={summary || 0}
                          value={value || 0}
                        />
                      </StyledTableCell>
                    );
                  })}
                  <TableCell
                    sx={{ backgroundColor: '#f5f5f5' }}
                    rowSpan={2}
                    colSpan={1}
                  >
                    <StyledCellValue>
                      <Label
                        value={row.summary}
                        color={row.color}
                        max={row.summary}
                      />
                    </StyledCellValue>
                  </TableCell>
                </TableRow>

                {/* rendering summary row */}
                <TableRow
                  sx={{ borderBottom: '1.25px solid rgb(224, 224, 224)' }}
                >
                  <StyledTableCell colSpan={3}>
                    <Label
                      color={row.color}
                      max={row.rawMaterialSummary}
                      value={row.rawMaterialSummary}
                    />
                  </StyledTableCell>
                  <StyledTableCell colSpan={2}>
                    <Label
                      color={row.color}
                      max={row.distributionSummary}
                      value={row.distributionSummary}
                    />
                  </StyledTableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </StyledPanel>
  );
}
