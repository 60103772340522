import React, { useState } from 'react';
import { MobileStepper, Button } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
import {
  ImagePlaceholder,
  StyledContainer,
  StyledImage,
  StyledTitle,
} from './index.styles';

interface ImageSliderProps {
  images: string[]; // Array of image URLs
  handleClose?: () => void; // Optional prop for handling close
}

export const ImageSlider: React.FC<ImageSliderProps> = ({
  images,
  handleClose,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;
  const { t } = useTranslation();
  const tBase = `esg.dma.stages.informationOverview.step1Presentation.`;
  const _t = (key: string) => t(`${tBase}${key}`);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <StyledContainer>
      <StyledTitle>{_t('title')}</StyledTitle>
      <SwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <ImagePlaceholder key={step}>
            {Math.abs(activeStep - index) <= 2 ? (
              <StyledImage src={step} alt={`image-${index}`} />
            ) : null}
          </ImagePlaceholder>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          activeStep === maxSteps - 1 ? (
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={handleClose}
            >
              {t('esg.finish')}
            </Button>
          ) : (
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {t('esg.stakeholderSurvey.next')}
            </Button>
          )
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{
              border:
                activeStep === 0
                  ? '1px solid var(--main-dark-main, #D9D9D9)'
                  : '1px solid var(--main-dark-main, #38414f)',
            }}
          >
            {t('esg.backwards')}
          </Button>
        }
      />
    </StyledContainer>
  );
};
