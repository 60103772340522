import React from 'react';

import { EmployeeEmissionsDataType } from './types';
import CoverPage from './CoverPage';
import EmployeeEmissionsAndMeansOfTransport from './EmployeeEmissionsAndMeansOfTransport';
import BusinessTravelEmissionsMap from './BusinessTravelEmissionsMap';
import BusinessTravelEmissionsTable from './BusinessTravelEmissionsTable';

const employeeEmissions = (data: EmployeeEmissionsDataType) => {
  const businessTravelEmissionsTables =
    data.business_travel_emissions_table.table_data.map((tableData) => (
      <BusinessTravelEmissionsTable
        data={{
          ...data,
          business_travel_emissions_table: {
            ...data.business_travel_emissions_table,
            table_data: tableData,
          },
        }}
      />
    ));

  return [
    <CoverPage data={data} />,
    <EmployeeEmissionsAndMeansOfTransport data={data} />,
    <BusinessTravelEmissionsMap data={data} />,
    ...businessTravelEmissionsTables,
  ];
};

export default employeeEmissions;
