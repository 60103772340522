import React from 'react';
import { useTranslation } from 'react-i18next';
import worseIcon from '../../../../images/worse.png';
import betterIcon from '../../../../images/better.png';
import styles from './styles.module.css';

export default function Percent({ percent }: { percent: number | null }) {
  const { t } = useTranslation();
  return (
    <div className={styles.percent}>
      {percent ? (
        <p>
          <img src={percent > 0 ? worseIcon : betterIcon} alt="" />
          {percent > 0 ? '+' : ''}
          {percent.toFixed()}%{' '}
          {percent > 0 ? t('percent.worse') : t('percent.better')}
        </p>
      ) : null}
    </div>
  );
}
