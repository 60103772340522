import styled, { css } from 'styled-components';
import themeColors from '../../../../utils/theme';
import { Link } from 'react-router-dom';
import { styled as styledMUI } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

export const Title = styled.h1`
  color: #38414f;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.64px;
  margin-bottom: 30px;
`;

export const Box = styled.div`
  border-radius: 16px;
  border: 1px solid var(--stroke-pressed-button, #ddd);
  background: var(--pure-white, #fff);
  padding: 24px;
  margin-bottom: 24px;
`;

export const StyledDialog = styledMUI(Dialog)`
  & .MuiPaper-root {
    border: 1px solid ${themeColors.strokePressed};
    background: ${themeColors.pureWhite};
    padding: 50px;
    z-index: 1;
    width: 620px;
    border-radius: 16px;
  }
`;

export const Close = styled.button`
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
`;

export const ModalTitle = styled.p`
  color: #38414f;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.48px;
  margin-bottom: 30px;
`;

export const ModalSubTitle = styled.p`
  color: #38414f;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.36px;
  margin-bottom: 35px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const button = css`
  height: 48px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.36px;
  text-decoration: none;
  border-radius: 24px;
  width: 244px;
`;

export const OpenButton = styled(Link)`
  ${button};
  color: var(--main-dark-main, #38414f);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--light-grey, #ccc);
  background: var(--white, #fff);

  &:hover {
    background: var(--icon-button-hover-dark, rgba(0, 0, 0, 0.04));
  }

  &:active {
    border: 1px solid var(--icon-button-pressed-dark, rgba(0, 0, 0, 0.08));
    background: var(--icon-button-pressed-dark, rgba(0, 0, 0, 0.08));
  }
`;

export const AddButton = styled.button`
  ${button};
  color: var(--main-dark-main, #38414f);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--light-grey, #ccc);
  background: var(--white, #fff);

  &:hover {
    background: var(--icon-button-hover-dark, rgba(0, 0, 0, 0.04));
  }

  &:active {
    border: 1px solid var(--icon-button-pressed-dark, rgba(0, 0, 0, 0.08));
    background: var(--icon-button-pressed-dark, rgba(0, 0, 0, 0.08));
  }
`;

export const CloseButton = styled.button`
  ${button};
  color: var(--white, #fff);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--main-dark-main, #38414f);
  background: var(--main-dark-main, #38414f);

  &:hover {
    border: 1px solid var(--main-dark-hover, #49525f);
    background: var(--main-dark-hover, #49525f);
  }

  &:active {
    border: 1px solid var(--main-dark-pressed, #5a636f);
    background: var(--main-dark-pressed, #5a636f);
  }

  &:disabled {
    cursor: auto;
    color: var(--inactive-letters, #bbb);
    border: 1px solid var(--icon-button-hover-dark, rgba(0, 0, 0, 0.04));
    background: var(--icon-button-hover-dark, rgba(0, 0, 0, 0.04));
  }
`;
