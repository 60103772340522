import React from 'react';

import { LCAButton as Props } from './index.types';
import { StyledButton, StyledSecondaryButton } from './index.style';

export default function Button({
  style = {},
  color,
  fontSize,
  children,
  onClick,
  isSecondary = false,
}: Props) {
  const Component = isSecondary ? StyledSecondaryButton : StyledButton;
  return (
    <Component
      color={color || 'default'}
      fontSize={fontSize}
      style={style}
      onClick={onClick}
    >
      {children}
    </Component>
  );
}
