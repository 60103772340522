import { Typography, styled } from '@mui/material';
import { ERROR_COLOR, textStyle } from '../helpers';

export const StyledErrorMessage = styled(Typography)`
  color: ${ERROR_COLOR};
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: 24px;
  color: #38414f;
  ${textStyle}
`;
