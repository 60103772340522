import { useMutation, useQuery, useQueryClient } from 'react-query';
import { client } from 'src/utils/api-client';
import {
  GhgUserPermissionBaseSchema,
  GhgUserPermissionMetadataSchema,
  GhgUserPermissionSchema,
  EsgUserPermissionSchema,
  EsgUserPermissionMetadataSchema,
  EsgUserPermissionBaseSchema,
  LcaUserPermissionSchema,
  LcaUserPermissionMetadataSchema,
  LcaUserPermissionBaseSchema,
} from './types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const useGhgUserPermissions = (userId: number) => {
  const url = `/web/user-organization-permissions/ghg`;
  const queryClient = useQueryClient();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'superadmin.settings.tabs.users.usersTable.toasts.success',
  });

  const invalidateForUser = () => {
    queryClient.invalidateQueries({ queryKey: [url, userId] });
  };

  const { data: ghgUserPermissons, isLoading } = useQuery(
    [url, userId],
    () =>
      client
        .get<GhgUserPermissionSchema[]>(url, {
          params: { app_user_id: userId },
        })
        .then((response) => response.data),
    { enabled: !!userId }
  );

  const { data: metadata } = useQuery([url, 'metadata'], () =>
    client
      .get<GhgUserPermissionMetadataSchema>(`${url}-metadata`, {
        params: { app_user_id: userId },
      })
      .then((response) => response.data)
  );

  const createGhgUserPermission = useMutation({
    mutationFn: (data: GhgUserPermissionBaseSchema) => client.post(url, data),
    onSuccess: invalidateForUser,
  });

  const updateGhgUserPermission = useMutation({
    mutationFn: (data: GhgUserPermissionSchema) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: invalidateForUser,
  });

  const deleteGhgUserPermission = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: invalidateForUser,
  });

  const handleChange = (
    perm: GhgUserPermissionBaseSchema & { id?: number }
  ) => {
    if (!perm.id)
      createGhgUserPermission
        .mutateAsync(perm)
        .then(() => toast.success(t('post') as string));
    else if (perm.role === '-')
      deleteGhgUserPermission
        .mutateAsync(perm.id)
        .then(() => toast.success(t('delete') as string));
    else
      updateGhgUserPermission
        .mutateAsync(perm as GhgUserPermissionSchema)
        .then(() => toast.success(t('update') as string));
  };

  return {
    isLoading,
    ghgUserPermissons: ghgUserPermissons || [],
    options: metadata?.options || { scopes: [], sections: [], roles: [] },
    scopeSections: metadata?.scope_sections || [],
    userOrganizations: metadata?.user_organizations || [],
    createGhgUserPermission,
    updateGhgUserPermission,
    deleteGhgUserPermission,
    handleChange,
  };
};

export const useEsgUserPermissions = (userId: number) => {
  const url = `/web/user-organization-permissions/esg`;
  const queryClient = useQueryClient();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'superadmin.settings.tabs.users.usersTable.toasts.success',
  });

  const invalidateForUser = () => {
    queryClient.invalidateQueries({ queryKey: [url, userId] });
  };
  const { data, isLoading } = useQuery(
    [url, userId],
    () =>
      client
        .get<EsgUserPermissionSchema[]>(url, {
          params: { app_user_id: userId },
        })
        .then((response) => response.data),
    { enabled: !!userId }
  );

  const { data: metadata } = useQuery([url, 'metadata'], () =>
    client
      .get<EsgUserPermissionMetadataSchema>(`${url}-metadata`, {
        params: { app_user_id: userId },
      })
      .then((response) => response.data)
  );

  const createEsgUserPermission = useMutation({
    mutationFn: (data: EsgUserPermissionBaseSchema) => client.post(url, data),
    onSuccess: invalidateForUser,
  });

  const updateEsgUserPermission = useMutation({
    mutationFn: (data: EsgUserPermissionSchema) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: invalidateForUser,
  });

  const deleteEsgUserPermission = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: invalidateForUser,
  });

  const handleChange = (
    perm: EsgUserPermissionBaseSchema & { id?: number }
  ) => {
    if (!perm.id)
      createEsgUserPermission
        .mutateAsync(perm)
        .then(() => toast.success(t('post') as string));
    else if (perm.role === '-')
      deleteEsgUserPermission
        .mutateAsync(perm.id)
        .then(() => toast.success(t('delete') as string));
    else
      updateEsgUserPermission
        .mutateAsync(perm as EsgUserPermissionSchema)
        .then(() => toast.success(t('update') as string));
  };

  return {
    isLoading,
    esgUserPermissons: data || [],
    options: metadata?.options || {
      reports: [],
      roles: [],
      indicators: [],
      indicator_categories: [],
      questions: [],
    },
    userOrganizations: metadata?.user_organizations || [],
    createEsgUserPermission,
    updateEsgUserPermission,
    deleteEsgUserPermission,
    handleChange,
  };
};

export const useLcaUserPermissions = (userId: number) => {
  const url = `/web/user-organization-permissions/lca`;
  const queryClient = useQueryClient();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'superadmin.settings.tabs.users.usersTable.toasts.success',
  });

  const invalidateForUser = () => {
    queryClient.invalidateQueries({ queryKey: [url, userId] });
  };
  const { data, isLoading } = useQuery(
    [url, userId],
    () =>
      client
        .get<LcaUserPermissionSchema[]>(url, {
          params: { app_user_id: userId },
        })
        .then((response) => response.data),
    { enabled: !!userId }
  );

  const { data: metadata } = useQuery([url, 'metadata'], () =>
    client
      .get<LcaUserPermissionMetadataSchema>(`${url}-metadata`, {
        params: { app_user_id: userId },
      })
      .then((response) => response.data)
  );

  const createLcaUserPermission = useMutation({
    mutationFn: (data: LcaUserPermissionBaseSchema) => client.post(url, data),
    onSuccess: invalidateForUser,
  });

  const updateLcaUserPermission = useMutation({
    mutationFn: (data: LcaUserPermissionSchema) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: invalidateForUser,
  });

  const deleteLcaUserPermission = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: invalidateForUser,
  });

  const handleChange = (
    perm: LcaUserPermissionBaseSchema & { id?: number }
  ) => {
    if (!perm.id)
      createLcaUserPermission
        .mutateAsync(perm)
        .then(() => toast.success(t('post') as string));
    else if (perm.role === '-')
      deleteLcaUserPermission
        .mutateAsync(perm.id)
        .then(() => toast.success(t('delete') as string));
    else
      updateLcaUserPermission
        .mutateAsync(perm as LcaUserPermissionSchema)
        .then(() => toast.success(t('update') as string));
  };

  return {
    isLoading,
    lcaUserPermissons: data || [],
    options: metadata?.options || { products: [], roles: [] },
    userOrganizations: metadata?.user_organizations || [],
    createLcaUserPermission,
    updateLcaUserPermission,
    deleteLcaUserPermission,
    handleChange,
  };
};
