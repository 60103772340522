import styled from 'styled-components';
import Radio from '@mui/material/Radio';
import { Alert } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { styled as styledMUI } from '@mui/material/styles';
import { NextButtonProps } from './index.interfaces';
import themeColors from '../../../utils/theme';

export const FormWrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 28px 24px 42px 30px;
  background: #ffffff;
  border: 1px solid #b6bbb9;
  box-sizing: border-box;
  border-radius: 16px;
  margin-bottom: 14px;
`;

export const StyledRadio = styledMUI(Radio)`
font-family: 'Poppins', sans-serif;
  &.Mui-checked {
    color: #388276;
  }
`;

export const StyledFormControlLabel = styledMUI(
  FormControlLabel
)<FormControlLabelProps>`
  color: black;
  font-family: 'Poppins', sans-serif;
  & .MuiRadio-root {
    padding: 4px;
    font-family: 'Poppins', sans-serif;
  }
  & .MuiTypography-root {
    font-family: 'Poppins', sans-serif;
  }
`;

export const StyledFormLabel = styledMUI(FormLabel)<FormLabelProps>`
font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size:24px;
  margin-bottom: 14px;
  &.Mui-focused {
    color: #388276;
  }
`;

export const StyledFormControl = styledMUI(FormControl)`
margin-bottom: 14px;
`;

export const StyledAlert = styledMUI(Alert)`
  margin-bottom: 2.75rem;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
  padding-top: 10px;
  padding-bottom: 150px;
`;

export const EntryBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 800px;
  background: #ffffff;
  border: 1px solid #b6bbb9;
  border-radius: 16px;
  overflow: hidden;
  > img {
    margin-top: -5px;
    max-width: calc(100% + 10px);
    position: absolute;
    z-index: 0;
  }
  > span {
    z-index: 2;
    margin: 75px auto;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: #ffffff;
    text-shadow: 1px 3px 10px rgba(0, 0, 0, 0.4);
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 14px;
`;

export const BottomHorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #b6bbb9;
`;

export const Icon = styled.img`
  margin: 8px 2px -28px 2px;
  height: 48px;
  cursor: pointer;
  align-self: end;
`;

export const StyledTh = styled.div<{ width: number }>`
  background-color: #606462;
  border-left: 6px solid #fff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  height: 57px;
  padding-left: 11px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  width: ${({ width }) => width}%;

  &:first-child {
    border-left: 6px solid #606462;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
`;

export const PreviewBox = styled.div`
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #dddddd;
  padding: 24px 45px;
`;

export const StyledTd = styled.div<{ color: string; width: number }>`
  border-left: 6px solid ${({ color }) => color};
  width: ${({ width }) => width}%;
  background-color: #f2f2f2;
  height: 57px;
  font-size: 15px;
  padding-left: 11px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  color: #2f2f2f;
  font-weight: 500;
`;

export const TextBox = styled.div`
  margin: 36px 60px;
  > h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #132c1d;
  }
  > h5 {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: #2f2f2f;
    white-space: pre-wrap;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0 25px 0;

  button {
    border: 0;
    background-color: #fff;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    color: #606462;
    font-weight: 700;
    font-size: 18px;
    line-height: 48px;
    padding: 0;
  }
`;

export const CalendarBox = styled.div<{ error: boolean }>`
  display: flex;
  align-items: center;

  > span {
    color: ${({ error }) => (error ? themeColors.redParagraphs : '#388276')};
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
  }
`;

export const ButtonsBox = styled.div<{ one?: boolean }>`
  max-width: 800px;
  display: flex;
  justify-content: ${({ one }) => (one ? 'flex-end' : 'space-between')};
  margin-top: 25px;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d9dfdd;
  border-radius: 28px;
  padding: 8px 30px 8px 10px;
  margin-left: 0;
  margin-right: auto;
  cursor: pointer;
  > span {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #388276;
  }
  > img {
    height: 12px;
    width: 7.41px;
    margin-right: 20px;
  }
  &:hover {
    transition: background-color 0.3s ease-in-out;
    background-color: #f9f9f9;
  }
`;

export const NextButton = styled.button<NextButtonProps>(
  (props) => `
  cursor: ${props.error ? 'not-allowed' : 'pointer'};
  display: flex;
  align-items: center;
  background-color: ${props.error ? '#D9DFDD' : '#388276'};
  border: 2px solid ${props.error ? '#D9DFDD' : '#388276'};
  border-radius: 28px;
  padding: 8px 10px 8px 30px;
  margin-right: 0;
  margin-left: auto;
  > span {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #ffffff;
  }
  > img {
    height: 12px;
    width: 7.41px;
    margin-left: 20px;
  }
  &:hover {
    transition: background-color, border 0.3s ease-in-out;
    background-color: ${props.error ? '#D9DFDD' : '#499387'};
    border: 2px solid ${props.error ? '#D9DFDD' : '#499387'};
  }
`
);

export const NextButtonLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #388276;
  border: 2px solid #388276;
  border-radius: 28px;
  padding: 8px 10px 8px 30px;
  margin-right: 0;
  margin-left: auto;
  > span {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #ffffff;
  }
  > img {
    height: 12px;
    width: 7.41px;
    margin-left: 20px;
  }
  &:hover {
    transition: background-color, border 0.3s ease-in-out;
    background-color: #499387;
    border: 2px solid #499387;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.03em;
  color: #132c1d;
  margin: 40px 0;
  & > .important-title {
    color: red;
  }
`;

export const StyledInput = styled.input`
  color: #132c1d;
  font-weight: 400;
  font-size: 18px;
  background: #ffffff;
  display: flex;
  align-items: center;
  border: 1px solid #b6bbb9;
  border-radius: 28px;
  padding: 0 30px;
  height: 56px;
  flex-grow: 1;
  margin-right: 1.5rem;
`;

export const ErrorTitle = styled.h1`
  color: ${themeColors.redParagraphs} !important;
`;

export const ParticipationTable = styled.div`
  width: 504px;
`;

export const ParticipationHead = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

export const ParticipationHeadCell = styled.div`
  background: #fdc342;
  margin-right: 6px;
  width: 50%;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  padding: 2px 9px;
  color: #2f2f2f;
  height: 24px;

  &:last-child {
    margin-right: 0;
  }
`;

export const ParticipationBodyCell = styled.div`
  margin-bottom: 4px;
  display: flex;

  div {
    width: 50%;
    margin-right: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #2f2f2f;
    padding: 2px 9px;

    &:last-child {
      margin-right: 0;
    }
  }

  &:nth-child(odd) {
    div {
      background: #dddddd;
    }
  }

  &:nth-child(even) {
    div {
      background: #f2f2f2;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
