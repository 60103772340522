export const hint_data_upstream = {
  main: 'assets-and-other.upstream.main',
  items: [
    'assets-and-other.upstream.item1',
    'assets-and-other.upstream.item2',
    'assets-and-other.upstream.item3',
  ],
};

export const hint_data_downstream = {
  main: 'assets-and-other.downstream.main',
  items: [
    'assets-and-other.downstream.item1',
    'assets-and-other.downstream.item2',
  ],
};
