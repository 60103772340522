import { ListItem, ListItemButton, ListItemButtonProps } from '@mui/material';
import React from 'react';
import {
  NavigateOptions,
  To,
  useMatch,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom';

type SidebarNavItemBaseProps = {
  ButtonProps?: ListItemButtonProps;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
};

export function SidebarNavItemBase(props: SidebarNavItemBaseProps) {
  return (
    <ListItem sx={{ mb: '5px' }}>
      <ListItemButton {...props.ButtonProps} onClick={props.onClick}>
        {props.children}
      </ListItemButton>
    </ListItem>
  );
}

type SidebarNavigatorProps = {
  ButtonProps?: ListItemButtonProps;
  children: React.ReactNode;
  to: To;
  // different 'To' target for purposes of matching active path
  matchTo?: To;
  options?: NavigateOptions;
};

export default function SidebarNavItem(props: SidebarNavigatorProps) {
  const navigate = useNavigate();
  const resolvedPath = useResolvedPath(
    props.matchTo || props.to,
    props.options
  );
  const match = useMatch(resolvedPath.pathname);
  const isActive = match !== null;

  const handleNavigate = (evt: any) => {
    navigate(props.to, props.options);
    props.ButtonProps?.onClick?.(evt);
  };

  return (
    <SidebarNavItemBase
      ButtonProps={{
        className: isActive ? 'active' : undefined,
        ...props.ButtonProps,
      }}
      onClick={handleNavigate}
    >
      {props.children}
    </SidebarNavItemBase>
  );
}
