import React from 'react';
import { Icon } from './index.styles';

export const HeadingIcon = () => (
  <Icon>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Short description">
        <path
          id="Vector"
          d="M27.266 7.28L25.386 5.4L22.9994 7.8L24.8794 9.68L27.266 7.28ZM17.3327 2.06667L14.666 2.06667L14.666 6L17.3327 6L17.3327 2.06667ZM26.666 18L30.666 18L30.666 15.3333L26.666 15.3333L26.666 18ZM11.9994 23.5867L11.9994 30L19.9994 30L19.9994 23.5867C22.386 22.2 23.9994 19.6267 23.9994 16.6667C23.9994 12.2533 20.4127 8.66667 15.9994 8.66667C11.586 8.66667 7.99935 12.2533 7.99935 16.6667C7.99935 19.6267 9.61269 22.2 11.9994 23.5867ZM5.33269 18L5.33269 15.3333L1.33269 15.3333L1.33269 18L5.33269 18ZM9.01269 7.78667L6.62602 5.38667L4.74602 7.26667L7.14602 9.65333L9.01269 7.78667Z"
          fill="#38414F"
        />
      </g>
    </svg>
  </Icon>
);
export const QuestionsIcon = () => (
  <Icon>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Questions" clipPath="url(#clip0_438_8611)">
        <path
          id="Vector"
          d="M5.33398 14C4.22732 14 3.33398 14.8933 3.33398 16C3.33398 17.1067 4.22732 18 5.33398 18C6.44065 18 7.33398 17.1067 7.33398 16C7.33398 14.8933 6.44065 14 5.33398 14ZM5.33398 6C4.22732 6 3.33398 6.89333 3.33398 8C3.33398 9.10667 4.22732 10 5.33398 10C6.44065 10 7.33398 9.10667 7.33398 8C7.33398 6.89333 6.44065 6 5.33398 6ZM5.33398 22C4.22732 22 3.33398 22.9067 3.33398 24C3.33398 25.0933 4.24065 26 5.33398 26C6.42732 26 7.33398 25.0933 7.33398 24C7.33398 22.9067 6.44065 22 5.33398 22ZM9.33398 25.3333H28.0007V22.6667H9.33398V25.3333ZM9.33398 17.3333H28.0007V14.6667H9.33398V17.3333ZM9.33398 6.66667V9.33333H28.0007V6.66667H9.33398Z"
          fill="#38414F"
        />
      </g>
      <defs>
        <clipPath id="clip0_438_8611">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);

export const RiskIcon = () => (
  <Icon>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Risks" clipPath="url(#clip0_438_8621)">
        <path
          id="Vector"
          d="M21.2792 3.19995L28.7992 10.72V21.28L21.2792 28.8H10.7192L3.19922 21.28V10.72L10.7192 3.19995H21.2792ZM22.3992 11.2L20.7992 9.59995L15.9992 14.4L11.1992 9.59995L9.59922 11.2L14.3992 16L9.59922 20.8L11.1992 22.4L15.9992 17.6L20.7992 22.4L22.3992 20.8L17.5992 16L22.3992 11.2Z"
          fill="#38414F"
        />
      </g>
      <defs>
        <clipPath id="clip0_438_8621">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);

export const BackIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Arrow_BACK">
      <path
        id="Vector"
        d="M30.0859 15.585L21.6893 24L30.0859 32.415L27.5009 35L16.5009 24L27.5009 13L30.0859 15.585Z"
        fill="#38414F"
      />
    </g>
  </svg>
);

export const FileIcon = () => (
  <Icon>
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9987 8.00016V23.3335C21.9987 26.2802 19.612 28.6668 16.6654 28.6668C13.7187 28.6668 11.332 26.2802 11.332 23.3335V6.66683C11.332 4.82683 12.8254 3.3335 14.6654 3.3335C16.5054 3.3335 17.9987 4.82683 17.9987 6.66683V20.6668C17.9987 21.4002 17.3987 22.0002 16.6654 22.0002C15.932 22.0002 15.332 21.4002 15.332 20.6668V8.00016H13.332V20.6668C13.332 22.5068 14.8254 24.0002 16.6654 24.0002C18.5054 24.0002 19.9987 22.5068 19.9987 20.6668V6.66683C19.9987 3.72016 17.612 1.3335 14.6654 1.3335C11.7187 1.3335 9.33203 3.72016 9.33203 6.66683V23.3335C9.33203 27.3868 12.612 30.6668 16.6654 30.6668C20.7187 30.6668 23.9987 27.3868 23.9987 23.3335V8.00016H21.9987Z"
        fill="#38414F"
      />
    </svg>
  </Icon>
);
