import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFileButton = styled(Button)({
  fontWeight: 600,
  backgroundColor: '#E9BC46',
  '&:hover': {
    backgroundColor: '#E9BC46',
  },
  '& a': {
    color: '#38414f',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});
