import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GhgSuperdashboardState } from './slice.types';

const initialState: GhgSuperdashboardState = {
  selectedCompanies: [],
  selectedTimeRange: {
    start: null,
    end: null,
  },
};

const ghgSuperdashboardSlice = createSlice({
  name: 'ghg-superdashboard',
  initialState,
  reducers: {
    setSelectedCompanies: (state, action: PayloadAction<number[]>) => {
      state.selectedCompanies = action.payload;
    },
  },
});

export const { setSelectedCompanies } = ghgSuperdashboardSlice.actions;

export default ghgSuperdashboardSlice.reducer;
