import { Box, SxProps, Typography } from '@mui/material';
import React from 'react';

interface Props {
  value: any;
  textColor?: string;
  color?: string;
  sx?: SxProps;
}

export default function StyledLi({
  value,
  color = '#E9BC46',
  textColor = '#38414F',
  sx,
}: Props) {
  return (
    <Box sx={sx}>
      <li style={{ color }}>
        <Typography component="span" sx={{ color: textColor }}>
          {value}
        </Typography>
      </li>
    </Box>
  );
}
