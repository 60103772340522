import mainImage from 'src/images/surveys/main.png';
import environmental from 'src/images/surveys/Photo_E.png';
import social from 'src/images/surveys/Maskgroup.png';
import governance from 'src/images/surveys/Maskgroup1.png';
import overallImpact from 'src/images/surveys/Maskgroup2.png';
import summary from 'src/images/surveys/Photo_3.png';
import end from 'src/images/surveys/Photo_2.png';

export default {
  intro: {
    image: mainImage,
  },
  summary: { image: summary },
  outro: {
    image: end,
  },
};
