import React from 'react';
import { useTranslation } from 'react-i18next';

import { FieldValue, UniversalField } from '../types';
import { getThemeColors } from '../../../helpers';
import { StyledErrorMessage, StyledTitle } from '../../index.styles';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import ImprovedCheckbox from '../shared/ImprovedCheckbox';

export default function SimpleCheckbox(props: UniversalField) {
  const { t } = useTranslation();

  const { field, value, onChange, errors, disabled } = props;
  const { title, label, required, theme_color: themeColor } = field;

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  /* backend side validation */
  React.useEffect(() => {
    const newMessage = errors?.[field.key];
    if (!!newMessage) setErrorMessage(newMessage);
  }, [errors]);

  /* Frontend side validation */
  const _validate = (_value: FieldValue) => {
    // required field - validation error on false
    if (required && !_value) {
      setErrorMessage(t('questionnaireV3.field.fieldRequired'));
    }
    // cleaning error messages if none
    else if (!!errorMessage) {
      setErrorMessage(undefined);
    }
  };

  // local handle change injects frontend validation
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedBooleanValue: boolean = !JSON.parse(String(value || false));
    _validate(parsedBooleanValue);
    onChange(parsedBooleanValue);
  };

  const themeColors = getThemeColors(themeColor);

  return (
    <>
      {!!title && <StyledTitle>{title}</StyledTitle>}
      <FormGroup sx={{ position: 'relative', mb: '16px' }}>
        <FormControl error={!!errorMessage}>
          <FormControlLabel
            disabled={!!disabled}
            control={
              <ImprovedCheckbox
                checked={!!value}
                themeColors={themeColors}
                handleChange={handleChange}
                disabled={disabled}
                error={!!errorMessage}
              />
            }
            label={<Typography>{label}</Typography>}
          />
          <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
        </FormControl>
      </FormGroup>
    </>
  );
}
