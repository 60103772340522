import React from 'react';

export default function ProductCbamIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_287_1916)">
        <path
          d="M19.8333 4.66602L24.5 9.33268L19.8333 13.9993V10.4993H15.1667V8.16602H19.8333V4.66602ZM11.6667 8.16602C11.025 8.16602 10.5 8.69102 10.5 9.33268C10.5 9.97435 11.025 10.4993 11.6667 10.4993C12.3083 10.4993 12.8333 9.97435 12.8333 9.33268C12.8333 8.69102 12.3083 8.16602 11.6667 8.16602ZM7 8.16602C6.35833 8.16602 5.83333 8.69102 5.83333 9.33268C5.83333 9.97435 6.35833 10.4993 7 10.4993C7.64167 10.4993 8.16667 9.97435 8.16667 9.33268C8.16667 8.69102 7.64167 8.16602 7 8.16602ZM8.16667 19.8327H12.8333V17.4993H8.16667V13.9993L3.5 18.666L8.16667 23.3327V19.8327ZM16.3333 19.8327C16.975 19.8327 17.5 19.3077 17.5 18.666C17.5 18.0244 16.975 17.4993 16.3333 17.4993C15.6917 17.4993 15.1667 18.0244 15.1667 18.666C15.1667 19.3077 15.6917 19.8327 16.3333 19.8327ZM21 19.8327C21.6417 19.8327 22.1667 19.3077 22.1667 18.666C22.1667 18.0244 21.6417 17.4993 21 17.4993C20.3583 17.4993 19.8333 18.0244 19.8333 18.666C19.8333 19.3077 20.3583 19.8327 21 19.8327Z"
          fill="#38414F"
        />
        <rect width="28" height="28" rx="8" fill="#D3B9E9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4559 6.125C17.4163 6.125 19.9742 7.84739 21.1778 10.3421C19.2812 14.6264 14.4959 15.7036 10.6661 13.8165C14.4557 13.5626 17.1462 12.7565 18.263 10.3434C17.3473 9.26703 15.9815 8.58341 14.4559 8.58341C12.0016 8.58341 9.96044 10.3514 9.54272 12.6799C11.2476 10.4171 13.3674 9.68247 15.8312 10.2141C12.0478 10.0351 10.0532 12.3668 10.0532 14.7554C10.0532 17.5063 12.8456 18.8819 15.1089 18.8819C17.8351 18.8819 20.7793 17.2886 21.875 14.2983C21.5046 18.06 18.3237 21 14.4557 21C10.3388 21 7 17.6695 7 13.5626C7 9.45565 10.3389 6.125 14.4559 6.125Z"
          fill="#2E3342"
        />
      </g>
      <defs>
        <clipPath id="clip0_287_1916">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
