import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { IconButton, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
  StyledContainer,
  StyledDescription,
  StyledPanel,
  StyledTitle,
} from '../../styles';
import Table from '../components/FinancialTopicsEvaluationTable';

export default function Step6TopicEvaluation() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { reportId } = useParams();
  return (
    <>
      <StyledContainer>
        <StyledTitle>
          <IconButton onClick={() => navigate(-1)}>
            <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#38414f' }} />
          </IconButton>
          {t('esg.dma.step6TopicEvaluation.title')}
        </StyledTitle>

        <StyledDescription>
          <Typography>
            <Trans i18nKey="esg.dma.step6TopicEvaluation.subtitle01" />
            <br />
            <Trans
              i18nKey="esg.dma.step6TopicEvaluation.subtitleLearnMore"
              components={{ a: <a target="_blank" /> }}
            />
            <br />
            <br />
            <Trans i18nKey="esg.dma.step6TopicEvaluation.subtitle02" />
          </Typography>
        </StyledDescription>

        <StyledPanel sx={{ p: '15px', pb: '30px' }}>
          <Table reportId={reportId as string} preliminary={false} />
        </StyledPanel>
      </StyledContainer>
      <div style={{ height: '100px' }} />
    </>
  );
}
