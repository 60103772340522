import React from 'react';
import { useTranslation } from 'react-i18next';

import { Member } from 'src/Superadmin/index.types';
import Row from './Row';
import { useAppSelector } from 'src/redux-file/hooks';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useOrganizationMembers } from 'src/Superadmin/hooks';
import NewPermissionRow from './NewPermissionRow';
import { StyledActionCell, StyledSelectCell } from './styles';

export default function UsersTable() {
  const { t } = useTranslation();

  const { user } = useAppSelector((state) => state.platform);

  const { organizationMembers } = useOrganizationMembers();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            {t(
              'superadmin.settings.tabs.users.usersTable.columns.firstNameAndLastName'
            )}
          </TableCell>
          <StyledSelectCell>
            {t('superadmin.settings.tabs.users.usersTable.columns.permissions')}
          </StyledSelectCell>
          <StyledSelectCell>
            {t('superadmin.settings.tabs.users.usersTable.columns.unit')}
          </StyledSelectCell>
          <StyledActionCell>GHG</StyledActionCell>
          <StyledActionCell>ESG</StyledActionCell>
          <StyledActionCell>LCA</StyledActionCell>
          <StyledActionCell>&nbsp;</StyledActionCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {organizationMembers?.map((member: Member) => {
          if (member.id === user?.id) return null;
          return (
            <React.Fragment key={member.id}>
              {member.organizations.map((organizationMember, idx) => (
                <Row
                  key={organizationMember.id}
                  renderUserColumn={idx === 0}
                  organizationMember={organizationMember}
                  member={member}
                />
              ))}
              <NewPermissionRow member={member} />
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
}
