import React from 'react';
import styled from 'styled-components';

/* eslint-disable no-unused-vars */

export const Row = styled(
  ({
    firstEl,
    secondEl,
    ...props
  }: {
    firstEl?: number;
    secondEl?: number;
    height?: number;
  } & React.HTMLAttributes<HTMLDivElement>) => <div {...props} />
)`
  display: grid;
  grid-template-columns:
    calc(${({ firstEl }) => firstEl || 50}% - 0.7rem)
    calc(${({ secondEl }) => secondEl || 50}% - 0.7rem);
  grid-column-gap: 1.5rem;
  ${({ height }) => height && `height: ${height}rem`}
`;

export default { Row };
