import React, { useEffect, useState } from 'react';
import SurveyContainer, {
  NavButtonProps,
} from '../../../components/Survey/Container';
import Title from '../../../components/Survey/Title';
import { useTranslation } from 'react-i18next';
import { Logo } from '../../../components/Survey/icons';
import ImageHeader from '../../../components/Survey/ImageHeader';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSupplierSurvey } from '../hooks';
import NotFound from '../../../views/components/NotFound';
import PageLoading from '../../../components/PageLoading';
import {
  ErrorBuilder,
  SchemaErrors,
  extractSchemaErrors,
  mapError,
} from '../../../utils/validation';
import { Box } from '@mui/material';
import TextField, { TextFieldProps } from 'src/components/TextField';
import {
  StyledContainer,
  StyledQuestionSubtitle,
  StyledQuestionTitle,
} from '../../../views/components/QuestionnaireV3/Question/index.styles';
import YesNoToggle from '../components/YesNoToggle';
import assets from '../assets';
import { GhgSupplierSurveyAnswerInSchema } from '../../interfaces';

type FormState = GhgSupplierSurveyAnswerInSchema;
type FormErrors = SchemaErrors<FormState>;

export const validateForm = (data: Partial<FormState>): FormErrors => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  const builder = new ErrorBuilder(data)
    .checkTruthy(
      ['company_name', 'contact_email', 'valid_since', 'valid_until'],
      'validation.required'
    )
    .checkNonNull(['emissions_known'], 'validation.required')
    .checkRule(
      ['valid_since', 'valid_until'],
      (value) =>
        dateRegex.test(value ?? '') && Number.isFinite(Date.parse(value ?? '')),
      'validation.invalidDate'
    );

  return builder.build();
};

export default function SupplierSurveyInitialQuestions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const survey = useSupplierSurvey();
  const [form, setForm] = useState<Partial<FormState>>({});
  const [errors, setErrors] = useState<FormErrors>({});

  useEffect(() => {
    const answer = survey.data?.answer;
    if (!!answer) setForm(answer);
  }, [survey.data]);

  if (survey.isError) return <NotFound />;
  if (!survey.data) return <PageLoading />;
  if (survey.data.answer?.is_submitted) return <Navigate to="../end" />;

  const prevButton: NavButtonProps = {
    label: 'ghg.supplierSurvey.back',
    onClick: () => navigate('../'),
  };

  const nextButton: NavButtonProps = {
    label: 'ghg.supplierSurvey.next',
    onClick: () => {
      const errors = validateForm(form);
      setErrors(errors);
      if (Object.keys(errors).length > 0) return;
      const validForm = form as FormState;
      survey.update
        .mutateAsync(validForm)
        .then(() => navigate('../questionnaire'))
        .catch((err) => setErrors(extractSchemaErrors(err, validForm)));
    },
  };

  const textFieldProps = (
    field: keyof FormState
  ): Partial<TextFieldProps<undefined>> => {
    return {
      value: form[field] || '',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setForm({ ...form, [field]: e.target.value }),
      errorText: mapError(errors, field, form[field]),
    };
  };

  return (
    <SurveyContainer
      logo={<Logo />}
      header={
        <ImageHeader
          imageSrc={assets.initialQuestions.image}
          text={t('ghg.supplierSurvey.mainHeader')}
          small
        />
      }
      prevLink={prevButton}
      nextLink={nextButton}
    >
      <Box>
        <Title title={t('ghg.supplierSurvey.initialQuestions.title')} />
        <Box display="flex" flexDirection="column">
          <StyledContainer>
            <StyledQuestionTitle>
              {t('ghg.supplierSurvey.initialQuestions.q01.title')}
            </StyledQuestionTitle>
            <TextField
              fullWidth
              label={t('ghg.supplierSurvey.initialQuestions.q01.label')}
              placeholder={t(
                'ghg.supplierSurvey.initialQuestions.q01.placeholder'
              )}
              {...textFieldProps('company_name')}
            />
          </StyledContainer>
          <StyledContainer>
            <StyledQuestionTitle>
              {t('ghg.supplierSurvey.initialQuestions.q02.title')}
            </StyledQuestionTitle>
            <TextField
              fullWidth
              label={t('ghg.supplierSurvey.initialQuestions.q02.label')}
              placeholder={t(
                'ghg.supplierSurvey.initialQuestions.q02.placeholder'
              )}
              {...textFieldProps('contact_email')}
              errorText={
                mapError(errors, 'contact_email', form.contact_email) &&
                'validation.invalidEmail'
              }
            />
          </StyledContainer>
          <StyledContainer>
            <StyledQuestionTitle>
              {t('ghg.supplierSurvey.initialQuestions.q03.title')}
            </StyledQuestionTitle>
            <StyledQuestionSubtitle>
              {t('ghg.supplierSurvey.initialQuestions.q03.since.title')}
            </StyledQuestionSubtitle>
            <TextField
              fullWidth
              label={t('ghg.supplierSurvey.initialQuestions.q03.since.label')}
              placeholder={t(
                'ghg.supplierSurvey.initialQuestions.q03.since.placeholder'
              )}
              sx={{ mb: '12px' }}
              {...textFieldProps('valid_since')}
            />
            <StyledQuestionSubtitle>
              {t('ghg.supplierSurvey.initialQuestions.q03.until.title')}
            </StyledQuestionSubtitle>
            <TextField
              fullWidth
              label={t('ghg.supplierSurvey.initialQuestions.q03.until.label')}
              placeholder={t(
                'ghg.supplierSurvey.initialQuestions.q03.until.placeholder'
              )}
              {...textFieldProps('valid_until')}
            />
          </StyledContainer>
          <StyledContainer>
            <StyledQuestionTitle>
              {t('ghg.supplierSurvey.initialQuestions.q04.title')}
            </StyledQuestionTitle>
            <YesNoToggle
              value={form.emissions_known}
              onChange={(value) => setForm({ ...form, emissions_known: value })}
              errorText={mapError(
                errors,
                'emissions_known',
                form.emissions_known
              )}
            />
          </StyledContainer>
        </Box>
      </Box>
    </SurveyContainer>
  );
}
