import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { useGhgOrganization, useLanguage } from 'src/common/hooks';
import {
  getDirectEmissionsData,
  getDirectEmissionsDataEmissionShares,
  putDirectEmissionsData,
  setSelectedDate,
} from '../../../redux-file/questionnaires/thunks';
import PageLoading from '../../../components/PageLoading';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import Title from '../../../components/Title';
import {
  CopyWrapper,
  Description,
  HintBox,
  PageContainer,
  PreviewBox,
  Reminder,
  Section,
  SectionGroupContainer,
  StyledHeader,
  StyledTh,
} from './index.styles';
import {
  timeConverter,
  getFirstDateOfPeriod,
} from '../../../utils/timeConverter';
import StationaryCombustionForm from '../../components/Questionnaire/forms/stationaryCombustion';
import StationaryCombustionPreview from '../../components/Questionnaire/preview/stationaryCombustion';
import {
  choices,
  units,
  mobileChoices,
  fugutive_emissions_choices,
} from '../../components/Questionnaire/forms/data';
import FugutiveEmissionsForm from '../../components/Questionnaire/forms/fugutiveEmissionsForm';
import FugutiveEmissionsPreview from '../../components/Questionnaire/preview/fugutiveEmissionsPreview';
import ProcessEmissionsPreview from '../../components/Questionnaire/preview/processEmissionsPreview';
import ProcessEmissionsForm from '../../components/Questionnaire/forms/processEmissionsForm';
import MobileCombustionConsumptionForm from '../../components/Questionnaire/forms/mobileCombustionConsumptionForm';
import MobileCombustionPreview from '../../components/Questionnaire/preview/mobileCombustion';
import QuestionnaireTitle from '../../components/Questionnaire/Title';
import AddProductButton from '../../../components/AddProductButton';
import QuestionnaireSectionTitle from '../../components/QuestionnaireSectionTitle';
import {
  fuel_consumption_cloud,
  fugitive_emission_cloud,
  process_emission_cloud,
  stationary_combustion_cloud,
  distance_traveled_cloud,
} from './clouds_data';
import MobileCombustionDistanceForm from '../../components/Questionnaire/forms/mobileCombustionDistanceForm';
import MobileCombustionDistancePreview from '../../components/Questionnaire/preview/mobileCombustionDistancePreview';
import UploadExcelButton from '../../../components/UploadExcelButton';
import { DirectEmissionsData } from '../../../redux-file/questionnaires/interfaces';
import AddButtonHolder from '../../components/Questionnaire/AddButtonHolder';
import { IntuitiveCalendarPeriod } from '../../components/IntuitiveCalendar';
import CopyForm from '../../components/CopyForm';

interface Combustion {
  amount_per_period: string;
  comment: string;
  manual_factor: null | string;
  name_of_equipement: string;
  type_of_fuel: string;
}

export interface stationaryCombustion extends Combustion {
  unit: string;
}

export interface mobileCombustion extends Combustion {
  unit_of_fuel: string;
}

export default function DirectEmissionQuestionnaire() {
  const dispatch = useAppDispatch();
  const { t, language } = useLanguage();

  const [activeStationaryCombustion, setActiveStationaryCombustion] = useState<
    undefined | number
  >(undefined);
  const [active2, setActive2] = useState<undefined | number>(undefined);
  const [active3, setActive3] = useState<undefined | number>(undefined);
  const [active4, setActive4] = useState<undefined | number>(undefined);
  const [active5, setActive5] = useState<undefined | number>(undefined);

  const [newFormStat, setNewFormStat] = useState(true);
  const [newFormMobConsumption, setNewFormMobConsumption] = useState(true);
  const [newFormMobDistance, setNewFormMobDistance] = useState(true);
  const [newFormFugutive, setNewFormFugutive] = useState(true);
  const [newFormProcess, setNewFormProcess] = useState(true);

  const stationary_combustion_ref = useRef(null);
  const mobile_combustion_consumption_ref = useRef(null);
  const mobile_combustion_distance_ref = useRef(null);
  const fugutive_emissions_ref = useRef(null);
  const process_emissions_ref = useRef(null);

  const {
    directEmissions,
    directEmissionsError,
    directEmissionsLoading,
    selectedDate,
    directEmissionsEmissionShares,
  } = useAppSelector((state) => state.questionnaire);

  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const stationaryCombustionInit = {
    amount_per_period: '',
    comment: '',
    manual_factor: null,
    name_of_equipement: '',
    type_of_fuel: '',
    unit: '',
    emission_shared: 0,
  };

  const mobileCombustionInit = {
    amount_per_period: '',
    comment: '',
    manual_factor: null,
    name_of_equipement: '',
    type_of_fuel: '',
    unit_of_fuel: '',
    emission_shared: 0,
  };

  const mobileCombustionDistanceInit = {
    name_of_equipement: '',
    manual_factor: null,
    amount_per_period: '',
    comment: '',
    type_of_vehicle: '',
    type_of_fuel: '',
    unit_of_distance: '',
    emission_shared: 0,
  };

  const fugutiveEmissionInit = {
    amount_kg_per_period: '',
    comment: '',
    emission_type: '',
    manual_factor: null,
    emission_shared: 0,
  };
  const processEmissionInit = {
    comment: '',
    kg_eq_co2_per_period: '',
    name_of_process: '',
    emission_shared: 0,
  };

  const [stationaryCombustionState, setStationaryCombustionState] = useState([
    stationaryCombustionInit,
  ]);

  const [
    mobileCombustionConsumptionState,
    setMobileCombustionConsumptionState,
  ] = useState([mobileCombustionInit]);

  const [mobileCombustionDistanceState, setMobileCombustionDistanceState] =
    useState([mobileCombustionDistanceInit]);

  const [fugutiveEmissionState, setFugutiveEmissionState] = useState([
    fugutiveEmissionInit,
  ]);
  const [processEmissionState, setProcessEmissionState] = useState([
    processEmissionInit,
  ]);

  useEffect(() => {
    if (directEmissions.stationary_combustion.length === 0) {
      setNewFormStat(true);
    } else {
      setNewFormStat(false);
    }

    setStationaryCombustionState(
      directEmissions.stationary_combustion.length !== 0 &&
        directEmissionsEmissionShares.stationary.length !== 0
        ? directEmissions.stationary_combustion.map((el: any, i: number) => ({
            ...el,
            emission_shared: directEmissionsEmissionShares.stationary[i]?.share,
          }))
        : [stationaryCombustionInit]
    );

    if (directEmissions.mobile_combustion_consumptionwise.length === 0) {
      setNewFormMobConsumption(true);
    } else {
      setNewFormMobConsumption(false);
    }

    setMobileCombustionConsumptionState(
      directEmissions.mobile_combustion_consumptionwise.length !== 0 &&
        directEmissionsEmissionShares.mobile_combustion_consumptionwise
          .length !== 0
        ? directEmissions.mobile_combustion_consumptionwise.map(
            (el: any, i: number) => ({
              ...el,
              emission_shared:
                directEmissionsEmissionShares.mobile_combustion_consumptionwise[
                  i
                ]?.share,
            })
          )
        : [mobileCombustionInit]
    );

    if (directEmissions.mobile_combustion_distancewise.length === 0) {
      setNewFormMobDistance(true);
    } else {
      setNewFormMobDistance(false);
    }

    setMobileCombustionDistanceState(
      directEmissions.mobile_combustion_distancewise.length !== 0 &&
        directEmissionsEmissionShares.mobile_combustion_distancewise.length !==
          0
        ? directEmissions.mobile_combustion_distancewise.map(
            (el: any, i: number) => ({
              ...el,
              emission_shared:
                directEmissionsEmissionShares.mobile_combustion_distancewise[i]
                  ?.share,
            })
          )
        : [mobileCombustionDistanceInit]
    );

    if (directEmissions.fugutive_emissions.length === 0) {
      setNewFormFugutive(true);
    } else {
      setNewFormFugutive(false);
    }

    setFugutiveEmissionState(
      directEmissions.fugutive_emissions.length !== 0 &&
        directEmissionsEmissionShares.fugutive_emissions.length !== 0
        ? directEmissions.fugutive_emissions.map((el: any, i: number) => ({
            ...el,
            emission_shared:
              directEmissionsEmissionShares.fugutive_emissions[i]?.share,
          }))
        : [fugutiveEmissionInit]
    );

    if (directEmissions.process_emissions.length === 0) {
      setNewFormProcess(true);
    } else {
      setNewFormProcess(false);
    }

    setProcessEmissionState(
      directEmissions.process_emissions.length !== 0 &&
        directEmissionsEmissionShares.process_emissions.length !== 0
        ? directEmissions.process_emissions.map((el: any, i: number) => ({
            ...el,
            emission_shared:
              directEmissionsEmissionShares.process_emissions[i]?.share,
          }))
        : [processEmissionInit]
    );
  }, [selectedDate, directEmissions, directEmissionsEmissionShares]);

  const combinedData = {
    stationary_combustion:
      stationaryCombustionState.length === 1 &&
      stationaryCombustionState[0].type_of_fuel === ''
        ? []
        : stationaryCombustionState,
    fugutive_emissions:
      fugutiveEmissionState.length === 1 &&
      fugutiveEmissionState[0].emission_type === ''
        ? []
        : fugutiveEmissionState,
    mobile_combustion_consumptionwise:
      mobileCombustionConsumptionState.length === 1 &&
      mobileCombustionConsumptionState[0].type_of_fuel === ''
        ? []
        : mobileCombustionConsumptionState,
    process_emissions:
      processEmissionState.length === 1 &&
      processEmissionState[0].kg_eq_co2_per_period === ''
        ? []
        : processEmissionState,
    mobile_combustion_distancewise:
      mobileCombustionDistanceState.length === 1 &&
      mobileCombustionDistanceState[0].amount_per_period === ''
        ? []
        : mobileCombustionDistanceState,
  };

  useEffect(() => {
    dispatch(
      getDirectEmissionsData({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
    dispatch(
      getDirectEmissionsDataEmissionShares({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
  }, [selectedDate, organization.id, reportingPeriod]);

  const sendData = (sentData: DirectEmissionsData) => {
    dispatch(
      putDirectEmissionsData({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
        data: sentData,
      })
    );
  };
  const refreshForm = () => {
    dispatch(
      getDirectEmissionsData({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
    dispatch(
      getDirectEmissionsDataEmissionShares({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
  };
  const combineStationaryCombustionData = (
    fullData: DirectEmissionsData,
    newData: any
  ) => {
    return {
      fugutive_emissions: fullData.fugutive_emissions,
      mobile_combustion_consumptionwise:
        fullData.mobile_combustion_consumptionwise,
      mobile_combustion_distancewise: fullData.mobile_combustion_distancewise,
      process_emissions: fullData.process_emissions,
      stationary_combustion: [...newData, ...fullData.stationary_combustion],
    };
  };
  const combineMobileCombustionDataConsumptionwise = (
    fullData: DirectEmissionsData,
    newData: any
  ) => {
    return {
      fugutive_emissions: fullData.fugutive_emissions,
      mobile_combustion_consumptionwise: [
        ...newData,
        ...fullData.mobile_combustion_consumptionwise,
      ],
      mobile_combustion_distancewise: fullData.mobile_combustion_distancewise,
      process_emissions: fullData.process_emissions,
      stationary_combustion: fullData.stationary_combustion,
    };
  };
  const combineMobileCombustionDataDistancewise = (
    fullData: DirectEmissionsData,
    newData: any
  ) => {
    return {
      fugutive_emissions: fullData.fugutive_emissions,
      mobile_combustion_consumptionwise:
        fullData.mobile_combustion_consumptionwise,
      mobile_combustion_distancewise: [
        ...newData,
        ...fullData.mobile_combustion_distancewise,
      ],
      process_emissions: fullData.process_emissions,
      stationary_combustion: fullData.stationary_combustion,
    };
  };
  const combineFugutiveEmissionData = (
    fullData: DirectEmissionsData,
    newData: any
  ) => {
    return {
      fugutive_emissions: [...newData, ...fullData.fugutive_emissions],
      mobile_combustion_consumptionwise:
        fullData.mobile_combustion_consumptionwise,
      mobile_combustion_distancewise: fullData.mobile_combustion_distancewise,
      process_emissions: fullData.process_emissions,
      stationary_combustion: fullData.stationary_combustion,
    };
  };
  const combineProcessEmissionData = (
    fullData: DirectEmissionsData,
    newData: any
  ) => {
    return {
      fugutive_emissions: fullData.fugutive_emissions,
      mobile_combustion_consumptionwise:
        fullData.mobile_combustion_consumptionwise,
      mobile_combustion_distancewise: fullData.mobile_combustion_distancewise,
      process_emissions: [...newData, ...fullData.process_emissions],
      stationary_combustion: fullData.stationary_combustion,
    };
  };

  return (
    <PageContainer>
      <SectionGroupContainer>
        <QuestionnaireTitle info text={t('direct-emission.title')} />

        <Description>{t('direct-emission.description')}</Description>
        <Reminder>{t('direct-emission.reminder')}</Reminder>

        <CopyWrapper>
          <Title title={t('forms.select-period')} description={null} />
          <CopyForm
            form="DirectEmissionsData"
            orgId={organization.id}
            refresh={() => refreshForm()}
          />
        </CopyWrapper>
        <IntuitiveCalendarPeriod
          reportingPeriod={reportingPeriod}
          selectedDate={getFirstDateOfPeriod(
            selectedDate ? new Date(selectedDate) : new Date(),
            reportingPeriod ?? 'M'
          )}
          setSelectedDate={(selDate: Date) =>
            dispatch(setSelectedDate(selDate))
          }
        />

        {directEmissionsLoading && <PageLoading />}
        {directEmissionsError && <SomethingWentWrong />}
        {!directEmissionsLoading && !directEmissionsError && (
          <>
            {/* stationary-combustion */}
            <Section>
              <div ref={stationary_combustion_ref} />
              <QuestionnaireSectionTitle
                level="primary"
                boxContent={stationary_combustion_cloud}
                text={t('direct-emission.stationary-combustion.title')}
                active={activeStationaryCombustion}
              />
              {newFormStat && (
                <>
                  <PreviewBox>
                    {stationaryCombustionState.map((_, id) => (
                      <StationaryCombustionForm
                        setNewForm={setNewFormStat}
                        combinedData={combinedData}
                        active={activeStationaryCombustion}
                        setActive={setActiveStationaryCombustion}
                        key={id}
                        id={id}
                        choices={choices}
                        units={units}
                        state={stationaryCombustionState}
                        setState={setStationaryCombustionState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('direct-emission.stationary-combustion.title')}
                    slug="StationaryCombustionData"
                    fullData={combinedData}
                    combineData={combineStationaryCombustionData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}

              {!newFormStat && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={30}>
                        {t(
                          'direct-emission.stationary-combustion.table.type_of_fuel'
                        )}
                      </StyledTh>
                      <StyledTh width={20}>
                        {t(
                          'direct-emission.stationary-combustion.table.name_of_equipement'
                        )}
                      </StyledTh>
                      <StyledTh width={15}>
                        {t(
                          'direct-emission.stationary-combustion.table.amount_per_period'
                        )}
                      </StyledTh>
                      <StyledTh width={20}>
                        {t('direct-emission.stationary-combustion.table.unit')}
                      </StyledTh>
                      <StyledTh width={15}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    {stationaryCombustionState.map(
                      (el: stationaryCombustion, id: number) => {
                        if (activeStationaryCombustion === id) {
                          return (
                            <StationaryCombustionForm
                              setNewForm={setNewFormStat}
                              combinedData={combinedData}
                              choices={choices}
                              units={units}
                              active={activeStationaryCombustion}
                              setActive={setActiveStationaryCombustion}
                              key={id}
                              id={id}
                              state={stationaryCombustionState}
                              setState={setStationaryCombustionState}
                            />
                          );
                        } else {
                          return (
                            <StationaryCombustionPreview
                              language={language}
                              setState={setStationaryCombustionState}
                              state={stationaryCombustionState}
                              setActive={setActiveStationaryCombustion}
                              key={id}
                              id={id}
                            />
                          );
                        }
                      }
                    )}
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setStationaryCombustionState([
                            stationaryCombustionInit,
                            ...stationaryCombustionState,
                          ]);
                          setActiveStationaryCombustion(0);
                          if (
                            stationary_combustion_ref &&
                            stationary_combustion_ref.current
                          ) {
                            // @ts-ignore
                            stationary_combustion_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('direct-emission.stationary-combustion.title')}
                        slug="StationaryCombustionData"
                        fullData={combinedData}
                        combineData={combineStationaryCombustionData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>

            {/* mobile-combustion */}
            <Section>
              <QuestionnaireSectionTitle
                level="primary"
                text={t('direct-emission.mobile-combustion.title')}
                active={false}
              />
              <Description
                dangerouslySetInnerHTML={{
                  __html: t('direct-emission.mobile-combustion.description'),
                }}
              />
            </Section>
            <Section>
              <HintBox>
                <span>{t('hint')}</span>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('direct-emission.mobile-combustion.hint.line1'),
                  }}
                />
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('direct-emission.mobile-combustion.hint.line2'),
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('direct-emission.mobile-combustion.hint.line3'),
                    }}
                  />
                </div>
              </HintBox>
            </Section>
            <Section>
              <div ref={mobile_combustion_consumption_ref} />
              <QuestionnaireSectionTitle
                level="secondary"
                text={t(
                  'direct-emission.mobile-combustion.fuel-consumption.title'
                )}
                boxContent={fuel_consumption_cloud}
                active={active2}
              />
              {newFormMobConsumption && (
                <>
                  <PreviewBox>
                    {mobileCombustionConsumptionState.map((el, id) => (
                      <MobileCombustionConsumptionForm
                        setNewForm={setNewFormMobConsumption}
                        combinedData={combinedData}
                        active={active2}
                        setActive={setActive2}
                        key={id}
                        id={id}
                        units={units}
                        choices={mobileChoices}
                        state={mobileCombustionConsumptionState}
                        setState={setMobileCombustionConsumptionState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t(
                      'direct-emission.mobile-combustion.fuel-consumption.title'
                    )}
                    slug="MobileCombustionDataConsumptionwise"
                    fullData={combinedData}
                    sendData={sendData}
                    refresh={refreshForm}
                    combineData={combineMobileCombustionDataConsumptionwise}
                  />
                </>
              )}

              {!newFormMobConsumption && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={30}>
                        {t(
                          'direct-emission.mobile-combustion.table.type_of_fuel'
                        )}
                      </StyledTh>
                      <StyledTh width={30}>
                        {t(
                          'direct-emission.mobile-combustion.table.name_of_equipement'
                        )}
                      </StyledTh>
                      <StyledTh width={13}>
                        {t(
                          'direct-emission.mobile-combustion.table.amount_per_period'
                        )}
                      </StyledTh>
                      <StyledTh width={13}>
                        {t('direct-emission.mobile-combustion.table.unit')}
                      </StyledTh>
                      <StyledTh width={13}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    {mobileCombustionConsumptionState.map(
                      (el: mobileCombustion, id: number) => {
                        if (active2 === id) {
                          return (
                            <MobileCombustionConsumptionForm
                              setNewForm={setNewFormMobConsumption}
                              combinedData={combinedData}
                              active={active2}
                              setActive={setActive2}
                              key={id}
                              id={id}
                              units={units}
                              choices={mobileChoices}
                              state={mobileCombustionConsumptionState}
                              setState={setMobileCombustionConsumptionState}
                            />
                          );
                        } else {
                          return (
                            <MobileCombustionPreview
                              language={language}
                              setState={setMobileCombustionConsumptionState}
                              state={mobileCombustionConsumptionState}
                              setActive={setActive2}
                              key={id}
                              id={id}
                            />
                          );
                        }
                      }
                    )}
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setMobileCombustionConsumptionState([
                            mobileCombustionInit,
                            ...mobileCombustionConsumptionState,
                          ]);
                          setActive2(0);
                          if (
                            mobile_combustion_consumption_ref &&
                            mobile_combustion_consumption_ref.current
                          ) {
                            // @ts-ignore
                            mobile_combustion_consumption_ref.current.scrollIntoView(
                              {
                                behavior: 'smooth',
                              }
                            );
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t(
                          'direct-emission.mobile-combustion.fuel-consumption.title'
                        )}
                        slug="MobileCombustionDataConsumptionwise"
                        fullData={combinedData}
                        sendData={sendData}
                        refresh={refreshForm}
                        combineData={combineMobileCombustionDataConsumptionwise}
                      />
                    }
                  />
                </>
              )}
            </Section>
            <Section>
              <div ref={mobile_combustion_distance_ref} />
              <QuestionnaireSectionTitle
                level="secondary"
                text={t(
                  'direct-emission.mobile-combustion.distance-travelled.title'
                )}
                boxContent={distance_traveled_cloud}
                active={active5}
              />
              {newFormMobDistance && (
                <>
                  <PreviewBox>
                    {mobileCombustionDistanceState.map((el, id) => (
                      <MobileCombustionDistanceForm
                        setNewForm={setNewFormMobDistance}
                        combinedData={combinedData}
                        active={active5}
                        setActive={setActive5}
                        key={id}
                        id={id}
                        state={mobileCombustionDistanceState}
                        setState={setMobileCombustionDistanceState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t(
                      'direct-emission.mobile-combustion.distance-travelled.title'
                    )}
                    slug="MobileCombustionDataDistancewise"
                    fullData={combinedData}
                    sendData={sendData}
                    refresh={refreshForm}
                    combineData={combineMobileCombustionDataDistancewise}
                  />
                </>
              )}

              {!newFormMobDistance && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={20}>
                        {t(
                          'direct-emission.mobile-combustion.table.type_of_fuel'
                        )}
                      </StyledTh>
                      <StyledTh width={20}>
                        {t(
                          'direct-emission.mobile-combustion.table.name_of_equipement'
                        )}
                      </StyledTh>
                      <StyledTh width={20}>
                        {t(
                          'direct-emission.mobile-combustion.table.type_of_vehicle'
                        )}
                      </StyledTh>
                      <StyledTh width={13}>
                        {t('direct-emission.mobile-combustion.table.distance')}
                      </StyledTh>
                      <StyledTh width={13}>
                        {t('direct-emission.mobile-combustion.table.unit')}
                      </StyledTh>
                      <StyledTh width={13}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    {mobileCombustionDistanceState.map(
                      (el: any, id: number) => {
                        if (active5 === id) {
                          return (
                            <MobileCombustionDistanceForm
                              setNewForm={setNewFormMobDistance}
                              combinedData={combinedData}
                              active={active5}
                              setActive={setActive5}
                              key={id}
                              id={id}
                              state={mobileCombustionDistanceState}
                              setState={setMobileCombustionDistanceState}
                            />
                          );
                        } else {
                          return (
                            <MobileCombustionDistancePreview
                              language={language}
                              setState={setMobileCombustionDistanceState}
                              state={mobileCombustionDistanceState}
                              setActive={setActive5}
                              key={id}
                              id={id}
                            />
                          );
                        }
                      }
                    )}
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setMobileCombustionDistanceState([
                            mobileCombustionDistanceInit,
                            ...mobileCombustionDistanceState,
                          ]);
                          setActive5(0);
                          if (
                            mobile_combustion_distance_ref &&
                            mobile_combustion_distance_ref.current
                          ) {
                            // @ts-ignore
                            mobile_combustion_distance_ref.current.scrollIntoView(
                              {
                                behavior: 'smooth',
                              }
                            );
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t(
                          'direct-emission.mobile-combustion.distance-travelled.title'
                        )}
                        slug="MobileCombustionDataDistancewise"
                        fullData={combinedData}
                        sendData={sendData}
                        refresh={refreshForm}
                        combineData={combineMobileCombustionDataDistancewise}
                      />
                    }
                  />
                </>
              )}
            </Section>
            <QuestionnaireSectionTitle
              level="primary"
              text={t('direct-emission.fugutive_emissions.main-title')}
              active={undefined}
            />
            {/* TODO: Ukrywamy do czasu znalezienia/wymyślenia nowego/lepszego copy */}
            {/*<Section>*/}
            {/*  <HintBox>*/}
            {/*    <span>{t('hint')}</span>*/}
            {/*    <p*/}
            {/*      dangerouslySetInnerHTML={{*/}
            {/*        __html: t('direct-emission.fugutive_emissions.hint.title'),*/}
            {/*      }}*/}
            {/*    />*/}
            {/*    <div>*/}
            {/*      <p*/}
            {/*        dangerouslySetInnerHTML={{*/}
            {/*          __html: t('direct-emission.fugutive_emissions.hint.desc'),*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </HintBox>*/}
            {/*</Section>*/}

            {/* fugutive emission */}
            <Section>
              <div ref={fugutive_emissions_ref} />
              <QuestionnaireSectionTitle
                level="secondary"
                text={t('direct-emission.fugutive_emissions.title')}
                boxContent={fugitive_emission_cloud}
                active={active3}
              />

              {newFormFugutive && (
                <>
                  <PreviewBox>
                    {fugutiveEmissionState.map((el, id) => (
                      <FugutiveEmissionsForm
                        setNewForm={setNewFormFugutive}
                        combinedData={combinedData}
                        active={active3}
                        setActive={setActive3}
                        key={id}
                        id={id}
                        choices={fugutive_emissions_choices}
                        state={fugutiveEmissionState}
                        setState={setFugutiveEmissionState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('direct-emission.fugutive_emissions.title')}
                    slug="FugutiveEmissionData"
                    fullData={combinedData}
                    sendData={sendData}
                    refresh={refreshForm}
                    combineData={combineFugutiveEmissionData}
                  />
                </>
              )}

              {!newFormFugutive && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={40}>
                        {t(
                          'direct-emission.fugutive_emissions.table.amount_kg_per_period'
                        )}
                      </StyledTh>
                      <StyledTh width={40}>
                        {t(
                          'direct-emission.fugutive_emissions.table.emission_type'
                        )}
                      </StyledTh>
                      <StyledTh width={20}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    {fugutiveEmissionState.map((el, id: number) => {
                      if (active3 === id) {
                        return (
                          <FugutiveEmissionsForm
                            setNewForm={setNewFormFugutive}
                            combinedData={combinedData}
                            active={active3}
                            setActive={setActive3}
                            key={id}
                            id={id}
                            choices={fugutive_emissions_choices}
                            state={fugutiveEmissionState}
                            setState={setFugutiveEmissionState}
                          />
                        );
                      } else {
                        return (
                          <FugutiveEmissionsPreview
                            language={language}
                            setState={setFugutiveEmissionState}
                            state={fugutiveEmissionState}
                            setActive={setActive3}
                            key={id}
                            id={id}
                          />
                        );
                      }
                    })}
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setFugutiveEmissionState([
                            fugutiveEmissionInit,
                            ...fugutiveEmissionState,
                          ]);
                          setActive3(0);
                          if (
                            fugutive_emissions_ref &&
                            fugutive_emissions_ref.current
                          ) {
                            // @ts-ignore
                            fugutive_emissions_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('direct-emission.fugutive_emissions.title')}
                        slug="FugutiveEmissionData"
                        fullData={combinedData}
                        sendData={sendData}
                        refresh={refreshForm}
                        combineData={combineFugutiveEmissionData}
                      />
                    }
                  />
                </>
              )}
            </Section>

            {/* process emission */}
            <Section>
              <div ref={process_emissions_ref} />
              <QuestionnaireSectionTitle
                level="secondary"
                text={t('direct-emission.process_emissions.title')}
                boxContent={process_emission_cloud}
                active={active4}
              />

              {newFormProcess && (
                <>
                  <PreviewBox>
                    {processEmissionState.map((el, id) => (
                      <ProcessEmissionsForm
                        setNewForm={setNewFormProcess}
                        combinedData={combinedData}
                        active={active4}
                        setActive={setActive4}
                        key={id}
                        id={id}
                        state={processEmissionState}
                        setState={setProcessEmissionState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('direct-emission.process_emissions.title')}
                    slug="ProcessEmissionData"
                    fullData={combinedData}
                    sendData={sendData}
                    refresh={refreshForm}
                    combineData={combineProcessEmissionData}
                  />
                </>
              )}

              {!newFormProcess && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={40}>
                        {t('direct-emission.process_emissions.name_of_process')}
                      </StyledTh>
                      <StyledTh width={40}>
                        {t(
                          'direct-emission.process_emissions.kg_eq_co2_per_period'
                        )}
                      </StyledTh>
                      <StyledTh width={20}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    {processEmissionState.map((el, id: number) => {
                      if (active4 === id) {
                        return (
                          <ProcessEmissionsForm
                            setNewForm={setNewFormProcess}
                            combinedData={combinedData}
                            active={active4}
                            setActive={setActive4}
                            key={id}
                            id={id}
                            state={processEmissionState}
                            setState={setProcessEmissionState}
                          />
                        );
                      } else {
                        return (
                          <ProcessEmissionsPreview
                            language={language}
                            setState={setProcessEmissionState}
                            state={processEmissionState}
                            setActive={setActive4}
                            key={id}
                            id={id}
                          />
                        );
                      }
                    })}
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setProcessEmissionState([
                            processEmissionInit,
                            ...processEmissionState,
                          ]);
                          setActive4(0);
                          if (
                            process_emissions_ref &&
                            process_emissions_ref.current
                          ) {
                            // @ts-ignore
                            process_emissions_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('direct-emission.process_emissions.title')}
                        slug="ProcessEmissionData"
                        fullData={combinedData}
                        sendData={sendData}
                        refresh={refreshForm}
                        combineData={combineProcessEmissionData}
                      />
                    }
                  />
                </>
              )}
            </Section>
          </>
        )}
      </SectionGroupContainer>
    </PageContainer>
  );
}
