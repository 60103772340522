import React from 'react';

export default function ArrowIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className || ''}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.41 9L12 13.58L16.59 9L18 10.41L12 16.41L6 10.41L7.41 9Z"
        fill="currentColor"
      />
    </svg>
  );
}
