import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';
import { TFunction } from 'react-i18next';
import { CbamReportCreateInSchema } from 'src/Cbam/types';

export type FormErrors = SchemaErrors<CbamReportCreateInSchema>;

export const requiredFields: (keyof CbamReportCreateInSchema)[] = ['name'];

export const validateForm = (
  data: CbamReportCreateInSchema,
  t: TFunction
): FormErrors => {
  const builder = new ErrorBuilder(data);
  return builder.checkTruthy(requiredFields, t('validation.required')).build();
};
