const entries = [
  {
    term: 'Aggregated goods category',
    definition:
      'A category of goods that are aggregated together for the purpose of calculating the carbon footprint of a product. The aggregated goods category is determined by the CN code of the product.',
  },
  {
    term: 'Carbon footprint',
    definition:
      'The total amount of greenhouse gases produced directly and indirectly to support human activities, usually expressed in equivalent tons of carbon dioxide (CO2).',
  },
  {
    term: 'Carbon leakage',
    definition:
      'The risk that companies move their production to countries with less stringent climate policies, resulting in an increase in global greenhouse gas emissions.',
  },
  {
    term: 'Carbon pricing',
    definition:
      'A policy that puts a price on greenhouse gas emissions, either through a carbon tax or a cap-and-trade system.',
  },
  {
    term: 'Carbon tax',
    definition:
      'A tax on greenhouse gas emissions, usually levied on the carbon content of fossil fuels.',
  },
  {
    term: 'Cap-and-trade system',
    definition:
      'A system that sets a cap on the total amount of greenhouse gas emissions allowed and issues permits to companies that allow them to emit a certain amount. Companies can buy and sell permits, creating a market for emissions.',
  },
  {
    term: 'CBAM',
    definition:
      'Carbon Border Adjustment Mechanism. A policy that puts a price on the carbon content of imported goods to prevent carbon leakage and level the playing field for domestic producers.',
  },
  {
    term: 'CN code',
    definition:
      'The Combined Nomenclature code, a classification system used to classify goods for customs purposes. The CN code determines the aggregated goods category of a product.',
  },
  {
    term: 'Emissions trading',
    definition:
      'A market-based approach to controlling pollution, where companies can buy and sell permits to emit greenhouse gases.',
  },
  {
    term: 'Greenhouse gases',
    definition:
      'Gases that trap heat in the atmosphere and contribute to the greenhouse effect. The main greenhouse gases are carbon dioxide (CO2), methane (CH4), and nitrous oxide (N2O).',
  },
  {
    term: 'Product carbon footprint',
    definition:
      'The total amount of greenhouse gases produced directly and indirectly to produce a product, usually expressed in equivalent tons of carbon dioxide (CO2).',
  },
  {
    term: 'Reporting declarant',
    definition:
      'Any of the following persons: the importer who lodges a customs declaration for release for free circulation of goods in its own name and on its own behalf; the person, holding an authorisation to lodge a customs declaration referred to in Article 182(1) of Regulation (EU) No 952/2013 of the European Parliament and of the Council (10), who declares the importation of goods; the indirect customs representative, where the customs declaration is lodged by the indirect customs representative appointed in accordance with Article 18 of Regulation (EU) No 952/2013, when the importer is established outside the Union or where the indirect customs representative has agreed to the reporting obligations in accordance with Article 32 of Regulation (EU) 2023/956.',
  },
  {
    term: 'Rebate',
    definition:
      'Any amount that reduces the amount due or paid by a person liable for the payment of a carbon price, before its payment or after, in a monetary form or in any other form.',
  },
  {
    term: 'Activity data',
    definition:
      'The amount of fuels or materials consumed or produced by a process relevant for the calculation-based methodology, expressed in terajoules, mass in tonnes or (for gases) volume in normal cubic metres, as appropriate.',
  },
  {
    term: 'Activity level',
    definition:
      'The quantity of goods produced (expressed in MWh for electricity, or in tonnes for other goods) within the boundaries of a production process.',
  },
  {
    term: 'Reporting period',
    definition:
      'A period that the operator of an installation has chosen to use as reference for the determination of embedded emissions.',
  },
  {
    term: 'Source stream',
    definition:
      'Any of the following: a specific fuel type, raw material or product giving rise to emissions of relevant greenhouse gases at one or more emission sources as a result of its consumption or production; a specific fuel type, raw material or product containing carbon and included in the calculation of greenhouse gas emissions using a mass balance method.',
  },
  {
    term: 'Emission source',
    definition:
      'A separately identifiable part of an installation or a process within an installation, from which relevant greenhouse gases are emitted.',
  },
  {
    term: 'Uncertainty',
    definition:
      'A parameter, associated with the result of the determination of a quantity, that characterises the dispersion of the values that could reasonably be attributed to the particular quantity, including the effects of systematic as well as of random factors, expressed in per cent, and describes a confidence interval around the mean value comprising 95 % of inferred values taking into account any asymmetry of the distribution of values.',
  },
  {
    term: 'Calculation factors',
    definition:
      'Net calorific value, emission factor, preliminary emission factor, oxidation factor, conversion factor, carbon content or biomass fraction.',
  },
  {
    term: 'Combustion emissions',
    definition:
      'Greenhouse gas emissions occurring during the exothermic reaction of a fuel with oxygen.',
  },
  {
    term: 'Emission factor',
    definition:
      'The average emission rate of a greenhouse gas relative to the activity data of a source stream assuming complete oxidation for combustion and complete conversion for all other chemical reactions.',
  },
  {
    term: 'Oxidation factor',
    definition:
      'The ratio of carbon oxidised to CO2 as a consequence of combustion to the total carbon contained in the fuel, expressed as a fraction, considering carbon monoxide (CO) emitted to the atmosphere as the molar equivalent amount of CO2.',
  },
  {
    term: 'Conversion factor',
    definition:
      'The ratio of carbon emitted as CO2 to the total carbon contained in the source stream before the emitting process takes place, expressed as a fraction, considering CO emitted to the atmosphere as the molar equivalent amount of CO2.',
  },
  {
    term: 'Accuracy',
    definition:
      'The closeness of the agreement between the result of a measurement and the true value of the particular quantity or a reference value determined empirically using internationally accepted and traceable calibration materials and standard methods, taking into account both random and systematic factors.',
  },
  {
    term: 'Calibration',
    definition:
      'The set of operations, which establishes, under specified conditions, the relations between values indicated by a measuring instrument or measuring system, or values represented by a material measure or a reference material and the corresponding values of a quantity realised by a reference standard.',
  },
  {
    term: 'Conservative',
    definition:
      'That a set of assumptions is defined in order to ensure that no under-estimation of reported emissions or over-estimation of production of heat, electricity or goods occurs.',
  },
  {
    term: 'Biomass',
    definition:
      'The biodegradable fraction of products, waste and residues from biological origin from agriculture, including vegetal and animal substances, from forestry and related industries, including fisheries and aquaculture, as well as the biodegradable fraction of waste, including industrial and municipal waste of biological origin.',
  },
  {
    term: 'Waste',
    definition:
      'Any substance or object which the holder discards or intends or is required to discard, excluding substances that have been intentionally modified or contaminated in order to meet this definition.',
  },
  {
    term: 'Residue',
    definition:
      'A substance that is not the end product(s) that a production process directly seeks to produce; it is not a primary aim of the production process and the process has not been deliberately modified to produce it.',
  },
  {
    term: 'Agricultural, aquaculture, fisheries and forestry residues',
    definition:
      'Residues that are directly generated by agriculture, aquaculture, fisheries and forestry and that do not include residues from related industries or processing.',
  },
  {
    term: 'Legal metrological control',
    definition:
      'The control by a public authority or regulator of the measurement tasks intended for the field of application of a measuring instrument, for reasons of public interest, public health, public safety, public order, protection of the environment, the levying of taxes and duties, the protection of consumers and fair trading.',
  },
  {
    term: 'Data flow activities',
    definition:
      'Activities related to the acquisition, processing and handling of data that are needed to draft an emissions report from primary source data.',
  },
  {
    term: 'Measurement system',
    definition:
      'A complete set of measuring instruments and other equipment, such as sampling and data processing equipment, used to determine variables such as the activity data, the carbon content, the calorific value or the emission factor of the greenhouse gas emissions.',
  },
  {
    term: 'Net calorific value (NCV)',
    definition:
      'The specific amount of energy released as heat when a fuel or material undergoes complete combustion with oxygen under standard conditions, less the heat of vaporisation of any water formed.',
  },
  {
    term: 'Process emissions',
    definition:
      'Greenhouse gas emissions other than combustion emissions occurring as a result of intentional and unintentional reactions between substances or their transformation, for a primary purpose other than the generation of heat, including from the following processes: the chemical, electrolytic or pyrometallurgical reduction of metal compounds in ores, concentrates and secondary materials; the removal of impurities from metals and metal compounds; the decomposition of carbonates, including those used for flue gas cleaning; chemical syntheses of products and intermediate products where the carbon bearing material participates in the reaction; the use of carbon containing additives or raw materials; the chemical or electrolytic reduction of metalloid oxides or non-metal oxides such as silicon oxides and phosphates.',
  },
  {
    term: 'Batch',
    definition:
      'An amount of fuel or material representatively sampled and characterised, and transferred as one shipment or continuously over a specific period of time.',
  },
  {
    term: 'Mixed fuel',
    definition: 'A fuel which contains both biomass and fossil carbon.',
  },
  {
    term: 'Mixed material',
    definition: 'A material which contains both biomass and fossil carbon.',
  },
  {
    term: 'Preliminary emission factor',
    definition:
      'The assumed total emission factor of a fuel or material based on the carbon content of its biomass fraction and its fossil fraction before multiplying it by the fossil fraction to produce the emission factor.',
  },
  {
    term: 'Fossil fraction',
    definition:
      'The ratio of fossil and inorganic carbon to the total carbon content of a fuel or material, expressed as a fraction.',
  },
  {
    term: 'Biomass fraction',
    definition:
      'The ratio of carbon stemming from fuel or material, expressed as a fraction.',
  },
  {
    term: 'Continuous emission measurement',
    definition:
      'A set of operations having the objective of determining the value of a quantity by means of periodic measurements, applying either measurements in the stack or extractive procedures with a measuring instrument located close to the stack, whilst excluding measurement methodologies based on the collection of individual samples from the stack.',
  },
  {
    term: 'Inherent CO2',
    definition: 'CO2 which is part of a source stream.',
  },
  {
    term: 'Fossil carbon',
    definition: 'Inorganic and organic carbon that is not biomass.',
  },
  {
    term: 'Measurement point',
    definition:
      'The emission source for which continuous emission measurement systems (CEMS) are used for emission measurement, or the cross-section of a pipeline system for which the CO2 flow is determined using continuous measurement systems.',
  },
  {
    term: 'Fugitive emissions',
    definition:
      'Irregular or unintended emissions from sources that are not localised, or too diverse or too small to be monitored individually.',
  },
  {
    term: 'Standard conditions',
    definition:
      'Temperature of 273.15 K and pressure conditions of 101,325 Pa defining normal cubic metres (Nm3).',
  },
  {
    term: 'Proxy data',
    definition:
      'Annual values which are empirically substantiated or derived from accepted sources and which an operator uses to substitute a data set for the purpose of ensuring complete reporting when it is not possible to generate all the required data or factors in the applicable monitoring methodology.',
  },
  {
    term: 'Measurable heat',
    definition:
      'A net heat flow transported through identifiable pipelines or ducts using a heat transfer medium, such as steam, hot air, water, oil, liquid metals and salts, for which a heat meter is or could be installed.',
  },
  {
    term: 'Heat meter',
    definition:
      'A thermal energy meter or any other device to measure and record the amount of thermal energy produced based upon flow volumes and temperatures.',
  },
  {
    term: 'Non-measurable heat',
    definition: 'All heat other than measurable heat.',
  },
  {
    term: 'Waste gas',
    definition:
      'A gas containing incompletely oxidised carbon in a gaseous state under standard conditions which is a result of any of the processes listed in the definition of process emissions.',
  },
  {
    term: 'Production process',
    definition:
      'The chemical or physical processes carried out in parts of an installation to produce goods under an aggregated goods category defined in Table 1 of Section 2 of this Annex, and its specified system boundaries regarding inputs, outputs and corresponding emissions.',
  },
  {
    term: 'Production route',
    definition:
      'A specific technology used in a production process to produce goods under an aggregated goods category.',
  },
  {
    term: 'Data set',
    definition:
      'One type of data, either at installation level or production process level as relevant in the circumstances, as any of the following: the amount of fuels or materials consumed or produced by a production process as relevant for the calculation-based methodology, expressed in terajoules, mass in tonnes, or for gases as volume in normal cubic metres, as appropriate, including for waste gases; a calculation factor; net quantity of measurable heat, and the relevant parameters required for determining this quantity, in particular: mass flow of heat transfer medium, and enthalpy of transmitted and returned heat transfer medium, as specified by composition, temperature, pressure and saturation; quantities of non-measurable heat, specified by the relevant quantities of fuels used for producing the heat, and the net calorific value (NCV) of the fuel mix; quantities of electricity; quantities of CO2 transferred between installations; quantities of precursors received from outside the installation, and their relevant parameters, such as country of origin, used production route, specific direct and indirect emissions, carbon price due; parameters relevant for a carbon price due.',
  },
  {
    term: 'Minimum requirements',
    definition:
      'Monitoring methods using the minimum efforts allowed for determining data in order to result in emission data acceptable for the purpose of Regulation (EU) 2023/956.',
  },
  {
    term: 'Recommended improvements',
    definition:
      'Monitoring methods which are proven means to ensure that data are more accurate or less prone to mistakes than by mere application of minimum requirements, and which may be chosen on a voluntary basis.',
  },
  {
    term: 'Misstatement',
    definition:
      'An omission, misrepresentation or error in the operator’s reported data, not considering the uncertainty permissible for measurements and laboratory analyses.',
  },
  {
    term: 'Material misstatement',
    definition:
      'A misstatement that, in the opinion of the verifier, individually or when aggregated with other misstatements, exceeds the materiality level or could affect the treatment of the operator’s report by the competent authority.',
  },
  {
    term: 'Reasonable assurance',
    definition:
      'A high but not absolute level of assurance, expressed positively in the verification opinion, as to whether the operator’s report subject to verification is free from material misstatement.',
  },
  {
    term: 'Eligible monitoring, reporting and verification system',
    definition:
      'The monitoring, reporting and verification systems where the installation is established for the purpose of a carbon pricing scheme, or compulsory emission monitoring schemes, or an emission monitoring scheme at the installation which can include verification by an accredited verifier, in accordance with Article 4(2) of Regulation (EU) 2023/956.',
  },
];

export default entries;
