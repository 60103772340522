import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import BasePage from '../Base';
import { HowWhyDataType } from './types';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  commonTextStyle: {
    position: 'absolute',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    color: '#165D5C',
  },
  mainTextHeader: {
    top: 158,
    left: 880,
    fontSize: 60,
    letterSpacing: 2,
    fontWeight: 700,
    fontStyle: 'bold',
  },
  mainText: {
    top: 256,
    left: 880,
    width: 992,
    color: '#606462',
    fontSize: 26,
  },
  whyTextHeader: {
    top: 482,
    left: 880,
    fontSize: 46,
    letterSpacing: 2,
    fontWeight: 700,
    fontStyle: 'bold',
  },
  whyText: {
    top: 555,
    left: 880,
    width: 495,
    fontSize: 26,
    color: '#606462',
  },
  howTextHeader: {
    top: 482,
    left: 1434,
    fontSize: 46,
    letterSpacing: 2,
    fontWeight: 700,
    fontStyle: 'bold',
  },
  howText: {
    top: 555,
    left: 1434,
    width: 417,
    fontSize: 26,
    color: '#606462',
  },
});

type HowWhyProps = {
  data: {
    how_why: HowWhyDataType;
  };
};

const HowWhyPage = ({ data }: HowWhyProps) => {
  const {
    top_right: topRight,
    main_text_header: mainTextHeader,
    main_text: mainText,
    how_text_header: howTextHeader,
    how_text: howText,
    why_text_header: whyTextHeader,
    why_text: whyText,
  } = data.how_why;

  const mainTextHeaderStyle = {
    ...styles.commonTextStyle,
    ...styles.mainTextHeader,
  };
  const mainTextStyle = { ...styles.commonTextStyle, ...styles.mainText };
  const whyTextHeaderStyle = {
    ...styles.commonTextStyle,
    ...styles.whyTextHeader,
  };
  const whyTextStyle = { ...styles.commonTextStyle, ...styles.whyText };
  const howTextHeaderStyle = {
    ...styles.commonTextStyle,
    ...styles.howTextHeader,
  };
  const howTextStyle = { ...styles.commonTextStyle, ...styles.howText };

  return (
    <BasePage
      backgroundImageName="Report_envirly_slide_3.png"
      topRight={topRight}
      pageNumber="02"
    >
      <View>
        <Text style={mainTextHeaderStyle}>{mainTextHeader}</Text>
        <Text style={mainTextStyle}>{mainText}</Text>
        <Text style={whyTextHeaderStyle}>{whyTextHeader}</Text>
        <Text style={whyTextStyle}>{whyText}</Text>
        <Text style={howTextHeaderStyle}>{howTextHeader}</Text>
        <Text style={howTextStyle}>{howText}</Text>
      </View>
    </BasePage>
  );
};

export default HowWhyPage;
