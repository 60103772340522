import React from 'react';
import { MenuItem, Select } from '@mui/material';

import { useEsgUserPermissions } from '../hooks';
import { useOrganizationMembers } from 'src/Superadmin/hooks';
import { ProductPlatform } from 'src/utils/api.interfaces';
import { useTranslation } from 'react-i18next';
import { EsgUserPermissionSchema } from '../types';
import { OrganizationMember } from 'src/Superadmin/index.types';

interface Props {
  userId: number;
  indicatorId: string | null;
  organizationId: number | null;
  reportId: number | null;
  indicatorCategoryId: number | null;
  questionId: string | null;
}

const getNullValue = (
  indicatorPerm: EsgUserPermissionSchema | undefined,
  indicatorCategoryPerm: EsgUserPermissionSchema | undefined,
  organizationMember: OrganizationMember | undefined
) => {
  if (indicatorPerm) return [indicatorPerm.role, 'fromIndicatorPermissions'];
  if (indicatorCategoryPerm)
    return [indicatorCategoryPerm.role, 'fromIndicatorPermissions'];
  if (organizationMember)
    return [organizationMember.role, 'fromGeneralPermissions'];
  return [null, null];
};

export default function PermissionSelect(props: Props) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'superadmin.settings.tabs.users.usersTable.permissions.options',
  });
  const {
    userId,
    indicatorId,
    organizationId,
    reportId,
    indicatorCategoryId,
    questionId,
  } = props;

  const { options, handleChange, esgUserPermissons } =
    useEsgUserPermissions(userId);

  const { userOrganizationMap } = useOrganizationMembers();

  const indicatorCategoryPerm = esgUserPermissons.find(
    (perm) =>
      perm.organization_id === organizationId &&
      perm.report_id === reportId &&
      perm.indicator_category_id === indicatorCategoryId &&
      perm.indicator_id === null &&
      perm.question_id === null
  );
  const indicatorPerm = esgUserPermissons.find(
    (perm) =>
      perm.organization_id === organizationId &&
      perm.report_id === reportId &&
      perm.indicator_category_id === indicatorCategoryId &&
      perm.indicator_id === indicatorId &&
      perm.question_id === null
  );
  const perm = esgUserPermissons.find(
    (perm) =>
      perm.organization_id === organizationId &&
      perm.report_id === reportId &&
      perm.indicator_category_id === indicatorCategoryId &&
      perm.indicator_id === indicatorId &&
      perm.question_id === questionId
  );

  const organizationMember = userOrganizationMap[userId]?.[organizationId || 0];
  const disabled = !organizationMember?.products?.includes(ProductPlatform.Esg);

  const [nullRole, nullTKey] = getNullValue(
    indicatorPerm,
    indicatorCategoryPerm,
    organizationMember
  );

  const nullValue =
    nullRole && nullTKey ? (
      <>
        {t(nullRole)}&nbsp;{t(nullTKey)}
      </>
    ) : (
      t('accessDenied')
    );

  return (
    <Select
      disabled={disabled}
      fullWidth
      size="small"
      value={perm?.role || '-'}
      onChange={(e) =>
        handleChange({
          id: perm?.id,
          organization_id: organizationId,
          question_id: questionId,
          indicator_id: indicatorId,
          indicator_category_id: indicatorCategoryId,
          role: e.target.value,
          app_user_id: userId,
          report_id: reportId,
        })
      }
    >
      <MenuItem value="-">{nullValue}</MenuItem>
      {options.roles.map((role) => (
        <MenuItem key={role[0]} value={role[0]}>
          {t(role[0])}
        </MenuItem>
      ))}
    </Select>
  );
}
