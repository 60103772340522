import styled from 'styled-components';

export const Hint = styled.p<{ color: string }>`
  border-radius: 0 16px 16px 16px;
  padding: 24px;
  background-color: ${({ color }) => color};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.36px;
  margin-bottom: 16px;
  color: #38414f;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  gap: 6px;

  button {
    background-color: transparent;
    border: 0;
    padding: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }

  p {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0.64px;
    color: #38414f;
  }
`;

export const SubTitle = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: #5a636f;
  margin-bottom: 32px;
`;

export const Box = styled.div`
  display: flex;
`;

export const RightColumn = styled.div`
  width: 60px;
  min-width: 60px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
`;

export const Line = styled.div`
  width: 2px;
  background-color: #bbb;
  height: 100%;
`;

export const Icon = styled.div`
  border-radius: 50%;
  border: 1px solid #bbb;
  background-color: #fff;
  height: 60px;
  width: 60px;
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
`;

export const Heading = styled.div`
  display: flex;
  position: relative;
  left: -85px;
  color: #38414f;
  margin-bottom: 12px;
`;
export const HeadingTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const HeadingTitle = styled.p`
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.56px;
`;
export const HeadingSubTitle = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0.48px;
`;

export const Warning = styled.p`
  color: var(--Error, #a03137);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.32px;
  margin-bottom: 32px;
`;

export const Warning2 = styled.p`
  color: var(--main-dark-main, #38414f);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.32px;
`;
