import { useMutation, useQueryClient } from 'react-query';
import { client } from 'src/utils/api-client';

export type LcaProductCopyInSchema = {
  name: string;
};

export const useProductActions = (productId: number) => {
  const queryClient = useQueryClient();
  const invalidateProducts = () => {
    queryClient.invalidateQueries({ queryKey: ['lca', 'products'] });
  };

  const copyProduct = useMutation({
    mutationFn: (data: LcaProductCopyInSchema) =>
      client.post(`/web/lca/products/${productId}/copy`, data),
    onSuccess: invalidateProducts,
  });

  const deleteProduct = useMutation({
    mutationFn: () => client.delete(`/web/lca/products/${productId}`),
    onSuccess: invalidateProducts,
  });

  return { copyProduct, deleteProduct };
};
