import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Typography } from '@mui/material';
import { StyledPanel } from 'src/components/StyledPanel';
import { ChevronRight } from '@mui/icons-material';

export default function Introduction() {
  return (
    <StyledPanel>
      <Typography variant="h3">Introduction to CBAM</Typography>
      <br />
      <Typography variant="body1">
        To begin with, find out the essential information about the EU Carbon
        Border Adjustment Mechanism. Watch the introductory video.
      </Typography>
      <br />
      <Link to="../help-center">
        <Button endIcon={<ChevronRight />}>Introduction to CBAM</Button>
      </Link>
    </StyledPanel>
  );
}
