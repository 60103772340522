import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import {
  TopBox,
  TopBoxTitle,
} from '../../views/components/OnboardingHeading/index.styles';

type ImageHeaderProps = {
  small?: boolean;
  imageSrc: string;
  text: string;
};

export default function ImageHeader({
  text,
  small,
  imageSrc,
}: ImageHeaderProps) {
  return (
    <TopBox
      style={{
        height: small ? '110px' : '195px',
        backgroundImage: `url(${imageSrc})`,
      }}
    >
      <TopBoxTitle>{text}</TopBoxTitle>
    </TopBox>
  );
}
