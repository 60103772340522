import React from 'react';
import { View, Text, StyleSheet, Link } from '@react-pdf/renderer';
import BasePage from '../Base';
import { EmissionDataSourcesDataType } from './types';
import { LOGO_MARGIN_LEFT } from '../../config';

const COL_1_LEFT = 130;
const COL_2_LEFT = 1075;
const TEXT_TOP = 300;

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  text: {
    color: '#606462',
    fontFamily: 'Poppins',
    fontSize: 25,
    fontStyle: 'normal',
    fontWeight: 500,
    width: 745,
    height: 520,
    lineHeight: 1.9,
    letterSpacing: 0.1,
  },
  mainHeader: {
    position: 'absolute',
    top: 125,
    left: LOGO_MARGIN_LEFT,
    fontFamily: 'Poppins',
    fontSize: 60,
    fontWeight: 700,
    fontStyle: 'bold',
    letterSpacing: 2,
    leadingTrim: 'both',
    textEdge: 'cap',
    color: '#165D5C',
  },
  textHighlight: {
    color: '#2F2F2F',
    fontFamily: 'Poppins',
    fontSize: 28,
    fontStyle: 'bold',
    backgroundColor: '#FDC342',
  },
  text1: { position: 'absolute', top: TEXT_TOP, left: COL_1_LEFT },
  text2: { position: 'absolute', top: TEXT_TOP, left: COL_2_LEFT },
  linkArea: {
    position: 'absolute',
    top: 920,
    left: 80,
    width: 1780,
    height: 75,
    backgroundColor: '#FDC342',
    borderRadius: 20,
    fontFamily: 'Poppins',
    color: '#2f2f2f',
    fontSize: 26,
    fontStyle: 'bold',
    paddingTop: 20,
    paddingLeft: 30,
    textDecoration: 'none',
  },
});

type EmissionDataSourcesProps = {
  data: {
    emission_data_sources: EmissionDataSourcesDataType;
  };
};

const isEvenNumber = (number: number) => number % 2 === 0;

const EmissionDataSourcesPage = ({ data }: EmissionDataSourcesProps) => {
  const {
    top_right: topRight,
    page_title: pageTitle,
    text_1,
    text_2,
    source_link_text: linkText,
    source_link: sourceLink,
  } = data.emission_data_sources;

  const text1Style = { ...styles.text, ...styles.text1 };
  const text2Style = { ...styles.text, ...styles.text2 };

  return (
    <BasePage
      backgroundImageName="Report_envirly_slide_data_sources.png"
      topRight={topRight}
      // mainHeader={pageTitle}
    >
      <View>
        <Text style={styles.mainHeader}>{pageTitle}</Text>

        <View>
          <Text style={text1Style}>{text_1}</Text>
        </View>

        <View>
          <Text style={text2Style}>
            {text_2.split('::').map((text, idx) =>
              isEvenNumber(idx) ? (
                <Text key={idx} style={styles.text}>
                  {text}
                </Text>
              ) : (
                <Text style={styles.textHighlight}>
                  &nbsp;&nbsp;
                  <Link key={idx} src={sourceLink}>
                    <Text style={styles.textHighlight}>{text}</Text>
                  </Link>
                  &nbsp;&nbsp;
                </Text>
              )
            )}
          </Text>
        </View>

        <Link src={sourceLink} style={styles.linkArea}>
          <Text>{linkText}</Text>
        </Link>
      </View>
    </BasePage>
  );
};

export default EmissionDataSourcesPage;
