import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { client } from '../../utils/api-client';
import React from 'react';
import {
  GhgSupplierSurveyAnswerInSchema,
  GhgSupplierSurveyWithTokenSchema,
} from '../interfaces';

export const useSupplierSurvey = () => {
  const { token } = useParams();
  const endpointUrl = `/web/ghg-suppliers/survey/answer/${token}`;
  const query = useQuery(['supplier_survey', token], () =>
    client
      .get<GhgSupplierSurveyWithTokenSchema>(endpointUrl)
      .then((response) => response.data)
  );

  const update = useMutation({
    mutationFn: (data: GhgSupplierSurveyAnswerInSchema) =>
      client.put(endpointUrl, data),
    onSuccess: () => query.refetch(),
  });

  const submit = useMutation({
    mutationFn: () => client.post(`${endpointUrl}/submit`),
    onSuccess: () => query.refetch(),
  });

  const fetchQv3Token = React.useCallback(async () => {
    const response = await query.refetch();
    return response.data?.answer?.qv3_token || '';
  }, [query.refetch, token]);

  return {
    ...query,
    update,
    submit,
    fetchQv3Token,
  };
};
