import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import { AutocompleteFreesolo } from '../fields';
import { useAppDispatch, useAppSelector } from '../../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import {
  putWasteAndOtherActivitiesData,
  getWasteAndOtherActivitiesData,
  getWasteAndOtherActivitiesDataEmissionShares,
} from '../../../../redux-file/questionnaires/thunks';
import { timeConverter } from '../../../../utils/timeConverter';
import { Overlay } from '../Title/index.styles';
import { Form, FormBox } from './index.styles';
import FormButtons from '../FormButtons';

export default function OtherEmissionsForm({
  state,
  setState,
  id,
  active,
  setActive,
  combinedData,
  setNewForm,
}: {
  state: {
    comment?: string | null;
    name: string;
    amount_kg_eq_co2_in_period: string;
  }[];
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  combinedData: any;
  setNewForm: Function;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector(
    (redux_state) => redux_state.questionnaire
  );
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const [
    amount_kg_eq_co2_in_period_error_state,
    set_amount_kg_eq_co2_in_period_error_state,
  ] = useState(false);
  let amount_kg_eq_co2_in_period_error = false;
  const amount_kg_eq_co2_in_period_validation = (value: string) => {
    if (value === '') amount_kg_eq_co2_in_period_error = true;
    return amount_kg_eq_co2_in_period_error;
  };

  const handleName = (value: string) => {
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      comment: tempForm.comment,
      name: value,
      amount_kg_eq_co2_in_period: tempForm.amount_kg_eq_co2_in_period,
    };
    newForms[id] = tempForm;
    setState(newForms);
  };

  const handleAmount = (value: string) => {
    amount_kg_eq_co2_in_period_validation(value);
    set_amount_kg_eq_co2_in_period_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      comment: tempForm.comment,
      name: tempForm.name,
      amount_kg_eq_co2_in_period: value,
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_amount_kg_eq_co2_in_period_error_state(true);
  };

  const handleSend = (data: any) => {
    if (!amount_kg_eq_co2_in_period_error) {
      dispatch(
        putWasteAndOtherActivitiesData({
          period: timeConverter(selectedDate, reportingPeriod),
          data,
        })
      ).then(() => {
        dispatch(
          getWasteAndOtherActivitiesData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
        dispatch(
          getWasteAndOtherActivitiesDataEmissionShares({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
      });
      setActive(undefined);
    }
  };

  const defineErrors = async () => {
    if (state[id].amount_kg_eq_co2_in_period === '') {
      set_amount_kg_eq_co2_in_period_error_state(true);
      amount_kg_eq_co2_in_period_error = true;
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    const tempcombinedData = {
      waste_data: combinedData.waste_data,
      other_fuel_related_activities_data:
        combinedData.other_fuel_related_activities_data,
      other_energy_related_activities:
        combinedData.other_energy_related_activities,
      other_emissions: tempState,
    };

    handleSend(tempcombinedData);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              handleSend(combinedData);
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <FormBox variant="100">
            <AutocompleteFreesolo
              active
              index={1}
              max={1}
              options={[]}
              label={t('waste-and-other.other-emissions.name')}
              error={false}
              value={state[id].name}
              setValue={(e: string) => handleName(e)}
            />
          </FormBox>
          <FormBox variant="100">
            <TextFieldNumber
              label={t('waste-and-other.other-emissions.amount')}
              active
              error={amount_kg_eq_co2_in_period_error_state}
              index={1}
              value={state[id].amount_kg_eq_co2_in_period}
              setValue={(e: string) => handleAmount(e)}
            />
          </FormBox>
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={state[id].amount_kg_eq_co2_in_period !== ''}
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
