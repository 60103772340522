import React from 'react';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import NewFieldAutocomplete from '../../../components/Questionnaire/fields/Autocomplete';
import { countriesISO2 } from '../../../../redux-file/helpers';
import {
  BottomHorizontalLine,
  FormWrapper,
  Icon,
  InputWrapper,
} from '../index.styles';
import deleteIcon from '../../../../images/delete.png';
import { useTranslation } from 'react-i18next';

export default function CountryForm({
  setForms,
  forms,
  idx,
}: {
  setForms: Function;
  forms: any;
  idx: number;
}) {
  const { t } = useTranslation();
  const handleCommutingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newForms = [...forms];
    const commutingType = newForms[idx];
    commutingType.country = event;
    setForms(newForms);
  };

  const setCommutingTimePerWeek = (value: string) => {
    const newForms = [...forms];
    const commutingType = newForms[idx];
    commutingType.number_of_nights = +value;
    setForms(newForms);
  };

  const countriesDropdowns = countriesISO2
    .map(([code]) => [code, t(`countries.${code}`)])
    .sort(([_1, a], [_2, b]) => a.localeCompare(b));

  return (
    <FormWrapper>
      <InputWrapper>
        <NewFieldAutocomplete
          options={countriesDropdowns}
          index={1}
          label={t('employee-survey.country')}
          value={forms[idx].country}
          setValue={handleCommutingChange}
          active
          error={false}
          max={99999}
        />
      </InputWrapper>
      <TextFieldNumber
        label={t('employee-survey.number-of-nights')}
        active
        error={false}
        index={2}
        value={forms[idx].number_of_nights}
        setValue={setCommutingTimePerWeek}
      />
      {forms.length >= 2 && (
        <>
          <BottomHorizontalLine />
          <Icon
            src={deleteIcon}
            onClick={() => {
              const newForms = [...forms];

              newForms.splice(idx, 1);

              setForms(newForms);
            }}
          />
        </>
      )}
    </FormWrapper>
  );
}
