import React from 'react';
import { ReactComponent as File } from './logo.svg';
import { Link } from 'react-router-dom';

export default function Logo() {
  return (
    <Link to="/">
      <File />
    </Link>
  );
}
