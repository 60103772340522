import React from 'react';
import SurveyContainer from '../../../components/Survey/Container';
import Title from '../../../components/Survey/Title';
import { Trans, useTranslation } from 'react-i18next';
import { Logo } from '../../../components/Survey/icons';
import ImageHeader from '../../../components/Survey/ImageHeader';
import { Subtitle } from '../styles';
import assets from '../assets';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const keyPrefix = 'cbam.productionProcessSurvey';

export default function ProductionProcessSurveyEnd() {
  const { t } = useTranslation(undefined, { keyPrefix });
  const navigate = useNavigate();

  React.useEffect(() => window.scrollTo(0, 0), []);

  // const { data } = useProductionProcesses();

  // TODO EN-1837: Get the organization name from the API
  // const orgName = data.length > 0 ? data[0].id : '';

  const prevLink = {
    onClick: () => {
      navigate('../questionnaire');
    },
    label: `${keyPrefix}.back`,
  };

  return (
    <SurveyContainer
      logo={<Logo />}
      header={
        <ImageHeader imageSrc={assets.end.image} text={t('mainHeader')} />
      }
      prevLink={prevLink}
    >
      <Box>
        <Title title={t('end.title')} />
        <Subtitle>{t('end.subtitle')}</Subtitle>
        <Trans t={t} i18nKey="end.message" />
        {/* <Typography>{orgName}</Typography> */}
      </Box>
    </SurveyContainer>
  );
}
