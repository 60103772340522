import React from 'react';

export default function ProductESGIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" rx="8" fill="#38414F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4559 6.125C17.4163 6.125 19.9742 7.84739 21.1778 10.3421C19.2812 14.6264 14.4959 15.7036 10.6661 13.8165C14.4557 13.5626 17.1462 12.7565 18.263 10.3434C17.3473 9.26703 15.9815 8.58341 14.4559 8.58341C12.0016 8.58341 9.96044 10.3514 9.54272 12.6799C11.2476 10.4171 13.3674 9.68247 15.8312 10.2141C12.0478 10.0351 10.0532 12.3668 10.0532 14.7554C10.0532 17.5063 12.8456 18.8819 15.1089 18.8819C17.8351 18.8819 20.7793 17.2886 21.875 14.2983C21.5046 18.06 18.3237 21 14.4557 21C10.3388 21 7 17.6695 7 13.5626C7 9.45565 10.3389 6.125 14.4559 6.125Z"
        fill="white"
      />
    </svg>
  );
}
