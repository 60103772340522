import React from 'react';

export default function DownloadIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 9.45452H15V3.34543H9V9.45452H5L12 16.5818L19 9.45452ZM11 11.4909V5.38179H13V11.4909H14.17L12 13.7003L9.83 11.4909H11ZM5 18.6182H19V20.6545H5V18.6182Z"
        fill="currentColor"
      />
    </svg>
  );
}
