import React from 'react';

export default function AssetsIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <g clipPath="url(#clip0_1262_89303)">
        <path
          d="M20 4.36328H4V6.39964H20V4.36328ZM21 14.5451V12.5087L20 7.41783H4L3 12.5087V14.5451H4V20.6542H14V14.5451H18V20.6542H20V14.5451H21ZM12 18.6178H6V14.5451H12V18.6178Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1262_89303">
          <rect
            width="24"
            height="24.4364"
            fill="currentColor"
            transform="translate(0 0.291016)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
