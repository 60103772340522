import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';

export interface ChangePasswordSchema {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

export type FormErrors = SchemaErrors<ChangePasswordSchema>;

export const validateForm = (data: ChangePasswordSchema): FormErrors => {
  return new ErrorBuilder(data)
    .checkTruthy(
      ['old_password', 'new_password', 'confirm_password'],
      'validation.required'
    )
    .build();
};
