import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CbamState {
  installations: { [id: number]: any };
  productionProcesses: { [id: number]: any };
  importedGoods: { [id: number]: any };
}

const initialState: CbamState = {
  installations: {
    1: { name: 'Some Installation' },
    2: {},
    3: {},
  },
  productionProcesses: {
    1: { basicData: { name: 'Process A' } },
    2: { basicData: { name: 'Process B' } },
  },
  importedGoods: {},
};

const cbamSlice = createSlice({
  name: 'cbam',
  initialState,
  reducers: {
    setInstallation: (state, action: PayloadAction<[number, any]>) => {
      const [id, data] = action.payload;
      state.installations[id] = data;
    },
    setProductionProcess: (state, action: PayloadAction<[number, any]>) => {
      const [id, data] = action.payload;
      state.productionProcesses[id] = data;
    },
    setImportedGood: (state, action: PayloadAction<[number, any]>) => {
      const [id, data] = action.payload;
      state.importedGoods[id] = data;
    },
  },
});

export const cbam = cbamSlice.reducer;

export const { setInstallation, setProductionProcess, setImportedGood } =
  cbamSlice.actions;
