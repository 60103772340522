import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import BasePage from '../Base';
import { MethodologyDataType } from './types';
import { LOGO_MARGIN_LEFT } from '../../config';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  textContainer: {
    position: 'absolute',
    top: 326,
    left: LOGO_MARGIN_LEFT,
    width: 880,
  },
  text: {
    leadingTrim: 'both',
    textEdge: 'cap',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    color: '#606462',
    fontSize: 26,
  },
});

type MethodologyProps = {
  data: {
    methodology: MethodologyDataType;
  };
};
const MethodologyPage = ({ data }: MethodologyProps) => {
  const {
    top_right: topRight,
    main_header: mainHeader,
    sub_header: subHeader,
    text_p1: textP1,
    text_p2: textP2,
    text_p3: textP3,
  } = data.methodology;

  return (
    <BasePage
      backgroundImageName="Report_envirly_slide_4.png"
      topRight={topRight}
      mainHeader={mainHeader}
      subHeader={subHeader}
      pageNumber="03"
    >
      <View>
        <View style={styles.textContainer}>
          <Text style={styles.text}>
            {textP1}
            {'\n\n'}
            {textP2}
            {'\n\n'}
            {textP3}
          </Text>
        </View>
      </View>
    </BasePage>
  );
};

export default MethodologyPage;
