import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { StyledCustomSelectValue, StyledNullComponent } from './index.styles';
import { t } from 'i18next';

export default function CustomSelect({
  key,
  fullWidth,
  options,
  value,
  onChange,
  disabled,
}: {
  key: string; // unique required
  options: any[];
  value: any;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: any) => void;
  disabled?: boolean;
  fullWidth?: boolean;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) =>
    !disabled && setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const selectedOption = options.find((opt) => opt.value === value);
  return (
    <center style={{ opacity: disabled ? 0.8 : 1, width: '100%' }}>
      {/* if value is null then show TextField else show Button (both trigger Menu with options) */}
      {value === null ? (
        <StyledNullComponent onClick={handleOpen}>
          {t('esg.common.select')}
          <ArrowDropDownIcon />
        </StyledNullComponent>
      ) : (
        <Button
          disabled={disabled}
          color={String(value)}
          id={key}
          variant="contained"
          disableElevation
          onClick={handleOpen}
          size="small"
          sx={{
            textTransform: 'none',
            borderRadius: '20px',
            height: '40px',
            minWidth: '40px',
            whiteSpace: 'nowrap',
          }}
          fullWidth={fullWidth}
        >
          <StyledCustomSelectValue>
            {selectedOption?.selectedLabel || selectedOption?.label}
          </StyledCustomSelectValue>
        </Button>
      )}
      <Menu id={key} anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => {
              onChange(option.value);
              handleClose();
            }}
            sx={{ minWidth: '150px' }}
            disableRipple
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </center>
  );
}
