import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// eslint-disable-next-line import/prefer-default-export
export const StyledTitle = styled(Typography)`
  color: #6e6e6e;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.45px;
  text-transform: uppercase;
`;
