import React from 'react';
import { ReactComponent as File } from './logo.svg';

interface LogoProps {
  width?: number;
  height?: number;
}

export default function Logo({ width = 135, height = 37 }: LogoProps) {
  return <File width={width} height={height} />;
}
