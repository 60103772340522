import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRecommendationItem } from '../../../redux-file/interfaces';
import {
  Container,
  Tag1,
  TagsWrapper,
  Title,
  Button,
  ShortDesc,
  MainImage,
  Author,
  AuthorCompany,
  AuthorName,
  AuthorAvatar,
  AuthorDesc,
  AuthorLink,
  Content,
  Clear,
} from './index.styles';
import { resolveMediaUrl } from '../../../utils/helpers';

export default function RecommendationItem({
  id,
  item,
}: {
  id: number;
  item: IRecommendationItem;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Title>
        <span>{id + 1}</span> {item.title}
      </Title>
      <ShortDesc dangerouslySetInnerHTML={{ __html: item.short_description }} />

      {open ? (
        <>
          <TagsWrapper>
            {item.tags.map((e: any) => (
              <Tag1 key={e.id}>{t(e.choice)}</Tag1>
            ))}
          </TagsWrapper>
          <Clear>
            {!!item.header_image && (
              <MainImage src={resolveMediaUrl(item.header_image)} alt="" />
            )}
            <Content
              dangerouslySetInnerHTML={{ __html: item.recommendation }}
            />
          </Clear>
          <Author>
            <AuthorName>
              <AuthorAvatar src={resolveMediaUrl(item.author.avatar)} alt="" />
              {item.author.first_name} {item.author.last_name}
            </AuthorName>
            <AuthorDesc>{item.author.position}</AuthorDesc>
            <AuthorLink
              href={item.author.reference_url}
              target="_blank"
              rel="noreferrer"
            >
              {item.author.reference_url}
            </AuthorLink>
            {!!item.author.recommending_organization?.logotype && (
              <a
                href={
                  item.author.recommending_organization.reference_url || '#'
                }
                target="_blank"
                rel="noreferrer"
              >
                <AuthorCompany
                  src={resolveMediaUrl(
                    item.author.recommending_organization.logotype
                  )}
                  alt=""
                />
              </a>
            )}
          </Author>
        </>
      ) : null}

      <Button open={open} onClick={() => setOpen(!open)} type="button">
        {open ? t('less') : t('more')}
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
          <path
            d="M10.59 7.41016L6 2.83016L1.41 7.41016L-1.23266e-07 6.00016L6 0.000156927L12 6.00016L10.59 7.41016Z"
            fill="#388276"
          />
        </svg>
      </Button>
    </Container>
  );
}
