import React from 'react';

export default function ProductSuppliersIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1560_14050)">
        <path
          d="M19.8333 4.66699L24.5 9.33366L19.8333 14.0003V10.5003H15.1667V8.16699H19.8333V4.66699ZM11.6667 8.16699C11.025 8.16699 10.5 8.69199 10.5 9.33366C10.5 9.97533 11.025 10.5003 11.6667 10.5003C12.3083 10.5003 12.8333 9.97533 12.8333 9.33366C12.8333 8.69199 12.3083 8.16699 11.6667 8.16699ZM7 8.16699C6.35833 8.16699 5.83333 8.69199 5.83333 9.33366C5.83333 9.97533 6.35833 10.5003 7 10.5003C7.64167 10.5003 8.16667 9.97533 8.16667 9.33366C8.16667 8.69199 7.64167 8.16699 7 8.16699ZM8.16667 19.8337H12.8333V17.5003H8.16667V14.0003L3.5 18.667L8.16667 23.3337V19.8337ZM16.3333 19.8337C16.975 19.8337 17.5 19.3087 17.5 18.667C17.5 18.0253 16.975 17.5003 16.3333 17.5003C15.6917 17.5003 15.1667 18.0253 15.1667 18.667C15.1667 19.3087 15.6917 19.8337 16.3333 19.8337ZM21 19.8337C21.6417 19.8337 22.1667 19.3087 22.1667 18.667C22.1667 18.0253 21.6417 17.5003 21 17.5003C20.3583 17.5003 19.8333 18.0253 19.8333 18.667C19.8333 19.3087 20.3583 19.8337 21 19.8337Z"
          fill="#5C5C5C"
        />
        <rect width="28" height="28" rx="8" fill="#5E2266" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4559 6.125C17.4163 6.125 19.9742 7.84739 21.1778 10.3421C19.2812 14.6264 14.4959 15.7036 10.6661 13.8165C14.4557 13.5626 17.1462 12.7565 18.263 10.3434C17.3473 9.26703 15.9815 8.58341 14.4559 8.58341C12.0016 8.58341 9.96044 10.3514 9.54272 12.6799C11.2476 10.4171 13.3674 9.68247 15.8312 10.2141C12.0478 10.0351 10.0532 12.3668 10.0532 14.7554C10.0532 17.5063 12.8456 18.8819 15.1089 18.8819C17.8351 18.8819 20.7793 17.2886 21.875 14.2983C21.5046 18.06 18.3237 21 14.4557 21C10.3388 21 7 17.6695 7 13.5626C7 9.45565 10.3389 6.125 14.4559 6.125Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1560_14050">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
