import React from 'react';
import { useParams } from 'react-router-dom';

import { StyledPanel } from 'src/Esg/styles';
import { useEsgOrganization } from 'src/common/hooks';
import { useEsgStakeholderAnalytics } from '../hooks';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';
import NoData from 'src/components/NoData';
import Qv3SummaryTable from 'src/views/components/QuestionnaireV3/Summary/SummaryTable';
import { Qv3SummaryTableType } from 'src/views/components/QuestionnaireV3/Summary/types';

export default function Table() {
  const { reportId } = useParams();
  const { id: organizationId } = useEsgOrganization();

  const { data, dataCount, isError } = useEsgStakeholderAnalytics(
    organizationId,
    reportId as string
  );

  if (isError) return <SomethingWentWrong />;
  if (data === undefined) return <PageLoading />;
  if (dataCount === 0) return <NoData />;

  return (
    <StyledPanel sx={{ minWidth: '940px' }}>
      <Qv3SummaryTable data={data} tableType={Qv3SummaryTableType.Grid} />
    </StyledPanel>
  );
}
