import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, IconButton } from '@mui/material';
import { StyledFlex } from 'src/components/StyledFlex';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Content, Title } from './index.styles';
import { StyledButton } from '../../index.styles';

export default function ScheduleMeetingDialog(props: {
  open: boolean;
  onClose: () => void;
}) {
  const { open, onClose } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={open} maxWidth="md">
      <IconButton
        sx={{ position: 'absolute', top: 12, right: 10 }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle sx={{ m: '20px' }}>
        <Title>{t('registration_bnp.dialog.title')}</Title>
      </DialogTitle>
      <DialogContent sx={{ p: '20px' }}>
        <Content>{t('registration_bnp.dialog.content1')}</Content>
        <br />
        <Content>{t('registration_bnp.dialog.content2')}</Content>
        <StyledFlex sx={{ mt: '40px', mb: '24px' }}>
          <StyledButton
            variant="outlined"
            onClick={() =>
              window.open('https://www.envirly.pl/umow-spotkanie', '_blank')
            }
          >
            {t('registration_bnp.dialog.schedule_meeting')}
          </StyledButton>
          <StyledButton variant="contained" onClick={() => onClose()}>
            {t('registration_bnp.dialog.close')}
          </StyledButton>
        </StyledFlex>
      </DialogContent>
    </Dialog>
  );
}
