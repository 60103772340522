import styled from 'styled-components';
import themeColors from '../../../../utils/theme';

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h1`
  color: ${themeColors.dark};
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 0;
`;

export const Country = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${themeColors.grayIcons};
  padding: 0;
  margin: 0;
`;

export const InfoBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    border-radius: 50%;
    margin-left: 8px;
  }

  &:hover {
    svg {
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const Desc = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  background-color: ${themeColors.pureWhite};
  padding: 24px;
  width: 264px;
  border: 1px solid ${themeColors.strokePressed};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px 0 16px 16px;
`;

export const Overlay = styled.div`
  background-color: ${themeColors.dark};
  opacity: 0.4;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
`;
