import cbam from 'src/images/surveys/cbam.png';

export default {
  intro: {
    image: cbam,
  },
  initialQuestions: {
    image: cbam,
  },
  questionnaire: {
    image: cbam,
  },
  end: {
    image: cbam,
  },
};
