import React from 'react';

import { Collapse, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DmaStage as Props } from './index.types';
import { StyledTitle, StyledSubtitle } from './index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import ExpandMore from 'src/components/ExpandMoreButton';
import { StyledPanel } from '../../../styles';

export default function DmaStage({
  title,
  subtitle,
  children,
  containerSx,
}: Props) {
  const [expanded, setExpanded] = React.useState(true);
  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <StyledPanel sx={containerSx}>
      <StyledFlex>
        <StyledTitle>{title}</StyledTitle>
        {(subtitle || children) && (
          <ExpandMore expand={expanded} onClick={handleExpandClick}>
            <ExpandMoreIcon />
          </ExpandMore>
        )}
      </StyledFlex>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <StyledSubtitle sx={{ mt: '12px' }}>{subtitle}</StyledSubtitle>
        {/* if children insert initial divider */}
        {children && <Divider sx={{ mt: '24px' }} />}
        {children}
      </Collapse>
    </StyledPanel>
  );
}
