import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { AppConfig } from '../config';

export default function registerSentry(appConfig: AppConfig) {
  Sentry.init({
    dsn: appConfig.sentryIngestUrl,
    environment: `${appConfig.environment}${appConfig.stage || ''}`,
    attachStacktrace: true,
    integrations: [new BrowserTracing()],
    release: appConfig.sentryRelease,
    tracesSampleRate:
      appConfig.environment === 'production' ||
      appConfig.environment === 'staging'
        ? 1.0
        : 0.0,
  });
}
