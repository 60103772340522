import React from 'react';
import {
  ProductionIcon,
  RawMaterialsIcon,
  DistributionIcon,
  UseStageIcon,
  EndOfLifeIcon,
} from './images';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Content = styled.div`
  padding: 24px;

  svg {
    margin-bottom: 40px;
  }
`;

const Title = styled.p`
  color: var(--Dark, #281302);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 32px;
  letter-spacing: 0.48px;
`;

const Desc = styled.p`
  color: var(--Dark-grey, #4d4d4d);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.54px;
`;

export function RawMaterialsContent() {
  const { t } = useTranslation();
  return (
    <Content>
      <RawMaterialsIcon />
      <Title>{t('lca.boundaries.modal.raw.title')}</Title>
      <Desc
        dangerouslySetInnerHTML={{ __html: t('lca.boundaries.modal.raw.desc') }}
      />
    </Content>
  );
}

export function ProductionContent() {
  const { t } = useTranslation();
  return (
    <Content>
      <ProductionIcon />
      <Title>{t('lca.boundaries.modal.production.title')}</Title>
      <Desc
        dangerouslySetInnerHTML={{
          __html: t('lca.boundaries.modal.production.desc'),
        }}
      />
    </Content>
  );
}

export function DistributionContent() {
  const { t } = useTranslation();
  return (
    <Content>
      <DistributionIcon />
      <Title>{t('lca.boundaries.modal.distribution.title')}</Title>
      <Desc
        dangerouslySetInnerHTML={{
          __html: t('lca.boundaries.modal.distribution.desc'),
        }}
      />
    </Content>
  );
}

export function UseStageIconContent() {
  const { t } = useTranslation();
  return (
    <Content>
      <UseStageIcon />
      <Title>{t('lca.boundaries.modal.stage.title')}</Title>
      <Desc
        dangerouslySetInnerHTML={{
          __html: t('lca.boundaries.modal.stage.desc'),
        }}
      />
    </Content>
  );
}

export function EndOfLifeIconContent() {
  const { t } = useTranslation();
  return (
    <Content>
      <EndOfLifeIcon />
      <Title>{t('lca.boundaries.modal.end.title')}</Title>
      <Desc
        dangerouslySetInnerHTML={{
          __html: t('lca.boundaries.modal.end.desc'),
        }}
      />
    </Content>
  );
}
