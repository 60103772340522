import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import {
  Autocomplete,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import { usePlatformNavigation } from 'src/utils/navigation';
import { useAppDispatch, useAppSelector } from 'src/redux-file/hooks';
import { setSelectedCompanies } from 'src/redux-file/ghgSuperdashboard/slice';

export default function SearchInput() {
  const { t } = useTranslation();
  const { productOrganizations } = usePlatformNavigation();
  const { selectedCompanies } = useAppSelector((s) => s.ghgSuperdashboard);
  const dispatch = useAppDispatch();

  const handleClick = (id: number) => {
    dispatch(setSelectedCompanies([...selectedCompanies, id]));
  };
  return (
    <Autocomplete
      freeSolo
      fullWidth
      disableClearable
      options={productOrganizations.map((org) => `${org.id}::${org.name}`)}
      renderOption={(item: { [key: string]: any }) => {
        const { key } = item;
        const [id, name] = key.split('::');
        const isSelected = selectedCompanies.includes(Number(id));
        return (
          <MenuItem
            key={item.key}
            disabled={isSelected}
            onClick={() => handleClick(Number(id))}
          >
            <ListItemText>
              <Typography noWrap sx={{ pr: '20px' }}>
                {name}
              </Typography>
            </ListItemText>
            {isSelected && (
              <ListItemSecondaryAction>
                <CheckIcon />
              </ListItemSecondaryAction>
            )}
          </MenuItem>
        );
      }}
      sx={{
        backgroundColor: '#fff',
        borderRadius: 5,
        '& fieldset': { border: 'none' },
      }}
      renderInput={(params) => (
        <TextField
          sx={{ pt: '2px' }}
          {...params}
          variant="outlined"
          size="small"
          placeholder={t('superdashboardGhg.sidebar.organizations.placeholder')}
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon sx={{ color: '#757575', ml: '4px' }} />,
          }}
        />
      )}
    />
  );
}
