import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getBusinessTravelEmissionsAPI,
  getCarbonFootprintAPI,
  getEmissionScope3API,
  getEmployeesMeansOfTransportAPI,
  getEmployeesStatsAPI,
  getIndustryBenchmarksAPI,
  getTotalEmissionAPI,
  getTotalEmissionDecreaseAPI,
  getPerEmployeeEmissionDecreaseAPI,
  getPerIncomeEmissionDecreaseAPI,
  getEmissionPerScopeAPI,
  getOrgStatsApi,
  getAllChartsAPI,
  getDashboardStatsAPI,
  getAllEmployeeSurveyChartsAPI,
  getEmployeeSurveyChartAPI,
  getSuperadminStatsAndChartsAPI,
} from '../../utils';

export const getOrgStats = createAsyncThunk(
  'getOrgStats',
  async ({ organizationId }: { organizationId: number }) =>
    await getOrgStatsApi(organizationId)
);

export const getDashboardStats = createAsyncThunk(
  'getDashboardStats',
  async ({
    start,
    end = new Date().toISOString(),
    organizationId,
  }: {
    start: string;
    end: string | null | undefined;
    organizationId: number;
  }) => await getDashboardStatsAPI(start, end, organizationId)
);

export const getAllCharts = createAsyncThunk(
  'getAllCharts',
  async ({
    start,
    end = new Date().toISOString(),
    organizationId,
  }: {
    start: string;
    end: string | null | undefined;
    organizationId: number;
  }) => await getAllChartsAPI(start, end, organizationId)
);

export const getCarbonFootprint = createAsyncThunk(
  'getCarbonFootprint',
  async ({
    start,
    end = new Date().toISOString(),
  }: {
    start: string;
    end: string | null | undefined;
  }) => getCarbonFootprintAPI(start, end)
);

export const getEmissionScope3 = createAsyncThunk(
  'getEmissionScope3',
  async ({
    start,
    end = new Date().toISOString(),
  }: {
    start: string;
    end: string | null | undefined;
  }) => getEmissionScope3API(start, end)
);

export const getEmployeesStats = createAsyncThunk(
  'getEmployeeStats',
  async ({
    start,
    end = new Date().toISOString(),
  }: {
    start: string;
    end: string | null | undefined;
  }) => getEmployeesStatsAPI(start, end)
);

export const getTotalEmission = createAsyncThunk(
  'getTotalEmission',
  async ({
    start,
    end = new Date().toISOString(),
  }: {
    start: string;
    end: string | null | undefined;
  }) => getTotalEmissionAPI(start, end)
);

export const getIndustryBenchmarks = createAsyncThunk(
  'getIndustryBenchmarks',
  async ({
    start,
    end = new Date().toISOString(),
  }: {
    start: string;
    end: string | null | undefined;
  }) => getIndustryBenchmarksAPI(start, end)
);

export const getBusinessTravelEmissions = createAsyncThunk(
  'getBusinessTravelEmissions',
  async ({
    start,
    end = new Date().toISOString(),
  }: {
    start: string;
    end: string | null | undefined;
  }) => getBusinessTravelEmissionsAPI(start, end)
);

export const getEmployeesMeansOfTransport = createAsyncThunk(
  'getEmployeesMeansOfTransport',
  async ({
    start,
    end = new Date().toISOString(),
  }: {
    start: string;
    end: string | null | undefined;
  }) => getEmployeesMeansOfTransportAPI(start, end)
);

export const getEmissionPerScope = createAsyncThunk(
  'getEmissionPerScope',
  async ({
    start,
    end = new Date().toISOString(),
  }: {
    start: string;
    end: string | null | undefined;
  }) => getEmissionPerScopeAPI(start, end)
);

export const getTotalEmissionDecrease = createAsyncThunk(
  'getTotalEmissionDecrease',
  async ({ orgId, targetId }: { orgId: number; targetId: string }) =>
    getTotalEmissionDecreaseAPI(orgId, targetId)
);

export const getPerEmployeeEmissionDecrease = createAsyncThunk(
  'getPerEmployeeEmissionDecrease',
  async ({ orgId, targetId }: { orgId: number; targetId: string }) =>
    getPerEmployeeEmissionDecreaseAPI(orgId, targetId)
);

export const getPerIncomeEmissionDecrease = createAsyncThunk(
  'getPerIncomeEmissionDecrease',
  async ({ orgId, targetId }: { orgId: number; targetId: string }) =>
    getPerIncomeEmissionDecreaseAPI(orgId, targetId)
);

export const getSuperadminStatsAndCharts = createAsyncThunk(
  'getSuperadminStatsAndCharts',
  async ({
    start,
    end = new Date().toISOString(),
    orgIds,
    orgMaxCount = 15,
    cancelSignal = undefined,
  }: {
    start: string;
    end: string | null | undefined;
    orgIds: number[];
    orgMaxCount: number | undefined;
    cancelSignal?: AbortSignal;
  }) => {
    return await getSuperadminStatsAndChartsAPI(
      start,
      end,
      orgIds,
      orgMaxCount || 15,
      cancelSignal
    );
  }
);

export const getAllEmployeeSurveyCharts = createAsyncThunk(
  'getAllEmployeeSurveyCharts',
  async ({
    organizationId,
    period,
  }: {
    organizationId: number;
    period: string;
  }) => await getAllEmployeeSurveyChartsAPI(organizationId, period)
);

export const getEmployeeSurveyChart = createAsyncThunk(
  'getEmployeeSurveyChart',
  async ({
    start,
    end = new Date().toISOString(),
    name,
  }: {
    start: string;
    end: string | null | undefined;
    name: string;
  }) => await getEmployeeSurveyChartAPI(start, end, name)
);
