import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ToastProvider() {
  return (
    <div>
      <style>
        {`.Toastify__toast{
          border-radius: 16px; 
          color: #38414F;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.36px; 
        }`}
      </style>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
