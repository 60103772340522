import React from 'react';
import { Overlay } from '../../Title/index.styles';
import { Form, SalesRegionBox } from '../index.styles';
import OneValueCheckboxField from '../../../../../components/CheckboxField';
import { useTranslation } from 'react-i18next';

interface Props {
  active: boolean;
  setActive: Function;
  state: any[];
  setState: Function;
}

export default function SalesRegionForm({
  active,
  setActive,
  state,
  setState,
}: Props) {
  const { t } = useTranslation();

  const handleEurope = () => {
    const tempState = [...state];
    tempState.splice(
      0,
      1,
      tempState[0] === '' ? (tempState[0] = 'europe') : (tempState[0] = '')
    );
    setState(tempState);
  };

  const handleAsia = () => {
    const tempState = [...state];
    tempState.splice(
      1,
      1,
      tempState[1] === '' ? (tempState[1] = 'asia') : (tempState[1] = '')
    );
    setState(tempState);
  };

  const handleAfrica = () => {
    const tempState = [...state];
    tempState.splice(
      2,
      1,
      tempState[2] === '' ? (tempState[2] = 'africa') : (tempState[2] = '')
    );
    setState(tempState);
  };

  const handleAustralia = () => {
    const tempState = [...state];
    tempState.splice(
      3,
      1,
      tempState[3] === ''
        ? (tempState[3] = 'australia_and_oceania')
        : (tempState[3] = '')
    );
    setState(tempState);
  };

  const handleNAmerica = () => {
    const tempState = [...state];
    tempState.splice(
      4,
      1,
      tempState[4] === ''
        ? (tempState[4] = 'north_america')
        : (tempState[4] = '')
    );
    setState(tempState);
  };

  const handleSAmerica = () => {
    const tempState = [...state];
    tempState.splice(
      5,
      1,
      tempState[5] === ''
        ? (tempState[5] = 'south_america')
        : (tempState[5] = '')
    );
    setState(tempState);
  };

  const handleAntarctica = () => {
    const tempState = [...state];
    tempState.splice(
      6,
      1,
      tempState[6] === '' ? (tempState[6] = 'antarctica') : (tempState[6] = '')
    );
    setState(tempState);
  };

  const handleNotKnown = () => {
    const tempState = [...state];
    tempState.splice(
      7,
      1,
      tempState[7] === '' ? (tempState[7] = 'not_known') : (tempState[7] = '')
    );
    setState(tempState);
  };

  return (
    <>
      {active && (
        <Overlay
        // onClick={() =>
        //   defineErrors().then(() => {
        //     setActive(false);
        //   })
        // }
        />
      )}
      <Form active={active}>
        <div onClick={() => setActive(true)} />
        <SalesRegionBox>
          <div>
            <OneValueCheckboxField
              label={t('europe')}
              value={state[0]}
              setValue={() => handleEurope()}
            />

            <OneValueCheckboxField
              label={t('asia')}
              value={state[1]}
              setValue={() => handleAsia()}
            />

            <OneValueCheckboxField
              label={t('africa')}
              value={state[2]}
              setValue={() => handleAfrica()}
            />

            <OneValueCheckboxField
              label={t('australia_and_oceania')}
              value={state[3]}
              setValue={() => handleAustralia()}
            />
          </div>
          <div>
            <OneValueCheckboxField
              label={t('north_america')}
              value={state[4]}
              setValue={() => handleNAmerica()}
            />

            <OneValueCheckboxField
              label={t('south_america')}
              value={state[5]}
              setValue={() => handleSAmerica()}
            />

            <OneValueCheckboxField
              label={t('antarctica')}
              value={state[6]}
              setValue={() => handleAntarctica()}
            />

            <OneValueCheckboxField
              label={t('not_known')}
              value={state[7]}
              setValue={() => handleNotKnown()}
            />
          </div>
        </SalesRegionBox>
      </Form>
    </>
  );
}
