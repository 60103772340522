import React from 'react';
import { useTranslation } from 'react-i18next';

import { RowType } from '../../types';
import { Container, Header, HeaderColumn, YesNoHeader } from '../styles';
import Row from './Row';

export type Props = { data: RowType[] };

export default function YesOrNoTable({ data }: Props) {
  const { t } = useTranslation();
  return (
    <Container>
      <Header>
        <HeaderColumn>{t('esgSummary.tables.no-lp')}</HeaderColumn>
        <HeaderColumn style={{ width: '70%' }}>
          {t('esgSummary.tables.question')}
        </HeaderColumn>
        <YesNoHeader>{t('esgSummary.tables.yes')}</YesNoHeader>
        <YesNoHeader>{t('esgSummary.tables.no')}</YesNoHeader>
      </Header>

      {data.map((row) => (
        <Row key={`${row.questionnaire_key}-${row.question_key}`} {...row} />
      ))}
    </Container>
  );
}
