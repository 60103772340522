import React from 'react';

export const EditIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 29.2505V33.0005H18.75L29.81 21.9405L26.06 18.1905L15 29.2505ZM32.71 19.0405C33.1 18.6505 33.1 18.0205 32.71 17.6305L30.37 15.2905C29.98 14.9005 29.35 14.9005 28.96 15.2905L27.13 17.1205L30.88 20.8705L32.71 19.0405Z"
      fill="#888888"
    />
  </svg>
);

export const ArrowIcon = ({ order }: { order: 'asc' | 'desc' }) => (
  <svg
    style={{ transform: order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)' }}
    width="28"
    height="53"
    viewBox="0 0 28 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="28" height="53" rx="4" fill="#38414F" />
    <path d="M14 34L7.0718 22L20.9282 22L14 34Z" fill="white" />
  </svg>
);

export const CompleteIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="#5A9531" />
    <rect
      x="4.55859"
      y="7.29004"
      width="5.05263"
      height="1.68421"
      rx="0.842105"
      transform="rotate(45 4.55859 7.29004)"
      fill="white"
    />
    <rect
      x="11.7031"
      y="4.9082"
      width="1.68421"
      height="8.42105"
      rx="0.842105"
      transform="rotate(45 11.7031 4.9082)"
      fill="white"
    />
  </svg>
);
