import React from 'react';
import Icon1 from './el1.png';
import Icon2 from './el2.png';
import Icon3 from './el3.png';
import Icon4 from './el4.png';

export const Icons = ({ idx }: { idx: number }) => {
  let icon;
  switch (idx) {
    case 1:
      icon = Icon1;
      break;
    case 2:
      icon = Icon2;
      break;
    case 3:
      icon = Icon3;
      break;
    case 4:
      icon = Icon4;
      break;
    default:
      icon = Icon1;
      break;
  }
  return <img src={icon} alt="ilustration" />;
};

export const BigArrows = () => (
  <>
    <svg
      width="51"
      height="38"
      viewBox="0 0 51 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.7678 20.7678C50.7441 19.7915 50.7441 18.2085 49.7678 17.2322L33.8579 1.32233C32.8816 0.346022 31.2986 0.346022 30.3223 1.32233C29.346 2.29864 29.346 3.88156 30.3223 4.85787L44.4645 19L30.3223 33.1421C29.346 34.1184 29.346 35.7014 30.3223 36.6777C31.2986 37.654 32.8816 37.654 33.8579 36.6777L49.7678 20.7678ZM-2.83533e-07 21.5L48 21.5L48 16.5L2.83533e-07 16.5L-2.83533e-07 21.5Z"
        fill="#46887C"
      />
    </svg>
    <svg
      width="51"
      height="38"
      viewBox="0 0 51 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.23223 17.2322C0.255924 18.2085 0.255924 19.7915 1.23223 20.7678L17.1421 36.6777C18.1184 37.654 19.7014 37.654 20.6777 36.6777C21.654 35.7014 21.654 34.1184 20.6777 33.1421L6.53553 19L20.6777 4.85786C21.654 3.88155 21.654 2.29864 20.6777 1.32233C19.7014 0.346017 18.1184 0.346017 17.1421 1.32233L1.23223 17.2322ZM51 16.5L3 16.5L3 21.5L51 21.5L51 16.5Z"
        fill="#46887C"
      />
    </svg>
  </>
);

export const SmallArrows = () => (
  <svg
    width="41"
    height="76"
    viewBox="0 0 41 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.7678 20.7678C40.7441 19.7915 40.7441 18.2085 39.7678 17.2322L23.8579 1.32233C22.8816 0.346021 21.2986 0.346021 20.3223 1.32233C19.346 2.29864 19.346 3.88155 20.3223 4.85787L34.4645 19L20.3223 33.1421C19.346 34.1184 19.346 35.7014 20.3223 36.6777C21.2986 37.654 22.8816 37.654 23.8579 36.6777L39.7678 20.7678ZM3 21.5L38 21.5L38 16.5L3 16.5L3 21.5Z"
      fill="#46887C"
    />
    <path
      d="M1.23223 55.2322C0.255924 56.2085 0.255924 57.7915 1.23223 58.7678L17.1421 74.6777C18.1184 75.654 19.7014 75.654 20.6777 74.6777C21.654 73.7014 21.654 72.1184 20.6777 71.1421L6.53553 57L20.6777 42.8579C21.654 41.8816 21.654 40.2986 20.6777 39.3223C19.7014 38.346 18.1184 38.346 17.1421 39.3223L1.23223 55.2322ZM38 54.5L3 54.5L3 59.5L38 59.5L38 54.5Z"
      fill="#46887C"
    />
  </svg>
);
