import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldNumber from '../../../../../../components/TextFieldNumber';
import { Select } from '../../../fields';
import AutocompleteFreesolo from '../../../fields/AutocompleteFreesolo';
import { Overlay } from '../../../Title/index.styles';
import { unit_uses_years } from '../../data';
import { Button, Box, Form, FormBox } from '../../index.styles';
import { sp_required_init } from '../../../../../Pages/Questionnaire/final-product-direct';

interface Props {
  state: {
    name: string;
    number_of_uses_or_yesrs_of_use: string;
    unit: string;
    number_of_products_sold: string;
    assumptions: string;
  };
  setState: Function;
  active: boolean;
  setActive: Function;
}

export default function RequiredForm({
  state,
  setState,
  active,
  setActive,
}: Props) {
  const { t } = useTranslation();

  const [unit_error_state, set_unit_error_state] = useState(false);
  const [uses_error_state, set_uses_error_state] = useState(false);
  const [sold_error_state, set_sold_error_state] = useState(false);

  let unit_error = false;
  let uses_error = false;
  let sold_error = false;

  const unit_validation = (value: string) => {
    if (value === '') unit_error = true;
    return unit_error;
  };

  const uses_validation = (value: string) => {
    if (value === '') uses_error = true;
    return uses_error;
  };

  const sold_validation = (value: string) => {
    if (value === '') sold_error = true;
    return sold_error;
  };

  const handleName = (value: string) => {
    const tempState = {
      name: value,
      number_of_uses_or_yesrs_of_use: state.number_of_uses_or_yesrs_of_use,
      assumptions: state.assumptions,
      unit: state.unit,
      number_of_products_sold: state.number_of_products_sold,
    };

    setState(tempState);
  };

  const handleUnit = (value: string) => {
    unit_validation(value);
    set_unit_error_state(false);
    const tempState = {
      name: state.name,
      number_of_uses_or_yesrs_of_use: state.number_of_uses_or_yesrs_of_use,
      assumptions: state.assumptions,
      unit: value,
      number_of_products_sold: state.number_of_products_sold,
    };

    setState(tempState);
    if (value === '') set_unit_error_state(true);
  };

  const handleUses = (value: string) => {
    uses_validation(value);
    set_uses_error_state(false);
    const tempState = {
      name: state.name,
      number_of_uses_or_yesrs_of_use: value,
      assumptions: state.assumptions,
      unit: state.unit,
      number_of_products_sold: state.number_of_products_sold,
    };

    setState(tempState);
    if (value === '') set_uses_error_state(true);
  };

  const handleSold = (value: string) => {
    sold_validation(value);
    set_sold_error_state(false);
    const tempState = {
      name: state.name,
      number_of_uses_or_yesrs_of_use: state.number_of_uses_or_yesrs_of_use,
      assumptions: state.assumptions,
      unit: state.unit,
      number_of_products_sold: value,
    };

    setState(tempState);
    if (value === '') set_sold_error_state(true);
  };

  const defineErrors = async () => {
    if (state.unit === '') {
      set_unit_error_state(true);
      unit_error = true;
    }
    if (state.number_of_uses_or_yesrs_of_use === '') {
      set_uses_error_state(true);
      uses_error = true;
    }
    if (state.number_of_products_sold === '') {
      set_sold_error_state(true);
      sold_error = true;
    }
  };

  return (
    <>
      {active && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              if (!unit_error && !uses_error && !sold_error) {
                setActive(false);
              }
            })
          }
        />
      )}
      <Form active={active}>
        <div onClick={() => setActive(true)}>
          <FormBox variant="100">
            <AutocompleteFreesolo
              active
              error={false}
              index={1}
              label={t('sold-products.final-products.direct.life-cycle.name')}
              value={state.name}
              setValue={(value: string) => handleName(value)}
              max={123}
              options={[]}
            />
          </FormBox>

          <FormBox variant="50">
            <TextFieldNumber
              label={t('sold-products.final-products.direct.life-cycle.usage')}
              active
              error={uses_error_state}
              index={1}
              value={state.number_of_uses_or_yesrs_of_use}
              setValue={(e: string) => handleUses(e)}
            />
          </FormBox>

          <FormBox variant="50">
            <Select
              active
              error={unit_error_state}
              index={1}
              label={t(`sold-products.final-products.direct.life-cycle.unit`)}
              value={state.unit}
              setValue={(value: string) => handleUnit(value)}
              max={123}
              options={unit_uses_years}
            />
          </FormBox>
          <FormBox variant="100">
            <TextFieldNumber
              label={t('sold-products.final-products.direct.life-cycle.amount')}
              active
              error={sold_error_state}
              index={1}
              value={state.number_of_products_sold}
              setValue={(e: string) => handleSold(e)}
            />
          </FormBox>
        </div>
        <Box>
          <Button
            type="button"
            onClick={() => {
              setState(sp_required_init);
              unit_error = false;
              set_unit_error_state(false);
              uses_error = false;
              set_uses_error_state(false);
              sold_error = false;
              set_sold_error_state(false);

              setActive(false);
            }}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 31C18 32.1 18.9 33 20 33H28C29.1 33 30 32.1 30 31V19H18V31ZM20 21H28V31H20V21ZM27.5 16L26.5 15H21.5L20.5 16H17V18H31V16H27.5Z"
                fill="#606462"
              />
              <rect x="22" y="23" width="1" height="6" fill="#606462" />
              <rect x="25" y="23" width="1" height="6" fill="#606462" />
            </svg>
          </Button>
        </Box>
      </Form>
    </>
  );
}
