import React, { HTMLAttributes } from 'react';

import { styled } from '@mui/material/styles';
import { StyledFlex } from '../StyledFlex';
import { Typography } from '@mui/material';

/* eslint-disable no-unused-vars */

export const StyledProgressBarContainer = styled(
  ({
    pct,
    height,
    ...props
  }: {
    pct: number;
    height: number;
  } & HTMLAttributes<HTMLDivElement>) => <StyledFlex {...props} />
)`
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  height: ${({ height }) => height}px;
  border-radius: ${({ height }) => height * 2}px;
  cursor: default;
`;

export const StyledProgressBar = styled(
  ({
    pct,
    height,
    color,
    ...props
  }: {
    pct: number;
    height: number;
    color: string;
  } & HTMLAttributes<HTMLDivElement>) => <div {...props} />
)`
  position: absolute;
  background-color: ${({ color }) => color || '#E9BC46'};
  width: ${({ pct }) => pct}%;
  height: ${({ height }) => height}px;
  border-radius: ${({ height }) => height * 2}px;
`;

export const StyledStep = styled(
  ({
    pct,
    height,
    pctLabel,
    color,
    ...props
  }: {
    pct: number;
    height: number;
    pctLabel: number;
    color: string;
  } & HTMLAttributes<HTMLDivElement>) => <StyledFlex {...props} />
)`
  z-index: 9;
  justify-content: center;
  left: ${(props) => props.pctLabel}%;
  height: calc(${(props) => props.height}px * 4);
  border-radius: calc(${(props) => props.height}px * 4);
  width: calc(${(props) => props.height}px * 4);
  background-color: ${({ pct, pctLabel, color }) =>
    pct >= pctLabel ? color : '#fff'};
  border: 1px solid
    ${({ pct, pctLabel, color }) => (pct >= pctLabel ? color : '#d9d9d9')};
`;

export const StyledStepLabel = styled(Typography)`
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.12px;
`;
