import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const SectionHeader = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

export const SubsectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-left: 0;

    .MuiTypography-root {
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 20px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`;
