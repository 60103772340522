import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-GB';
import pl from 'date-fns/locale/pl';
import {
  CalendarButton,
  CalendarOverlay,
  CalendarWrapper,
} from './index.styles';
import Icon from '../Icon';
import { CalendarProps } from './index.interfaces';
import { dateFormat } from '../../../utils/timeConverter';
import { useLanguage } from 'src/common/hooks';

registerLocale('en', en);
registerLocale('pl', pl);

export default function Calendar({
  calendarVariant,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  showCalendar,
  setShowCalendar,
  reportingPeriod,
}: CalendarProps) {
  const { language } = useLanguage();

  const handleChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    // @ts-ignore
    setStartTime(start);
    // @ts-ignore
    setEndTime(end);
  };

  return (
    <div>
      <CalendarButton
        type="button"
        onClick={() => setShowCalendar(true)}
        calendarVariant={calendarVariant}
      >
        {calendarVariant === 'normal' ? (
          <>
            <span>{dateFormat(startTime, reportingPeriod, language)}</span>
            <span> - </span>
            <span>{dateFormat(endTime, reportingPeriod, language)}</span>
          </>
        ) : null}
        <Icon name="calendar" alt="calendar icon" />
      </CalendarButton>
      {showCalendar && (
        <>
          <CalendarOverlay onClick={() => setShowCalendar(false)} />
          <CalendarWrapper>
            {reportingPeriod !== 'Q' ? (
              <DatePicker
                selected={startTime}
                onChange={handleChange}
                startDate={startTime}
                endDate={endTime}
                showMonthYearPicker={reportingPeriod === 'M'}
                showYearPicker={reportingPeriod === 'Y'}
                selectsRange
                maxDate={new Date()}
                inline
                calendarClassName="calendar"
                locale={language}
              />
            ) : (
              <DatePicker
                calendarClassName="calendar"
                selected={startTime}
                onChange={handleChange}
                startDate={startTime}
                endDate={endTime}
                dateFormat="QQQ yyyy"
                showQuarterYearPicker
                maxDate={new Date()}
                selectsRange
                inline
                locale={language}
              />
            )}
          </CalendarWrapper>
        </>
      )}
    </div>
  );
}

export function ActiveRangeCalendar({
  calendarVariant,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  showCalendar,
  setShowCalendar,
}: CalendarProps) {
  const { language } = useLanguage();
  const [lang, setLang] = useState<string>(language);

  const handleChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    // @ts-ignore
    setStartTime(start);

    if (end && end < new Date()) {
      const newEnd = new Date();
      newEnd.setDate(newEnd.getDate() + 1);
      // @ts-ignore
      setEndTime(newEnd);
    } else {
      // @ts-ignore
      setEndTime(end);
    }
  };

  useEffect(() => {
    setLang(language);
  }, [language]);

  return (
    <div>
      <CalendarButton
        type="button"
        onClick={() => setShowCalendar(true)}
        calendarVariant={calendarVariant}
      >
        {calendarVariant === 'normal' ? (
          <>
            <span>{startTime?.toLocaleString(lang)}</span>
            <span> - </span>
            <span>{endTime?.toLocaleString(lang)}</span>
          </>
        ) : null}
        <Icon name="calendar" alt="calendar icon" />
      </CalendarButton>
      {showCalendar && (
        <>
          <CalendarOverlay onClick={() => setShowCalendar(false)} />
          <CalendarWrapper>
            <DatePicker
              selected={startTime}
              onChange={handleChange}
              startDate={startTime}
              endDate={endTime}
              selectsRange
              inline
              calendarClassName="calendar"
              locale={lang}
            />
          </CalendarWrapper>
        </>
      )}
    </div>
  );
}
