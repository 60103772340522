import React from 'react';
import { useReports } from '../hooks';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { StyledFlex } from 'src/components/StyledFlex';
import { StyledPanel } from 'src/components/StyledPanel';
import ReportInfoPanel from './ReportInfoPanel';

export default function Reports() {
  const { data, create, delete: _delete } = useReports();

  const navigate = useNavigate();

  return (
    <Box>
      <StyledFlex>
        <Typography variant="h1">Reports</Typography>
        <Button onClick={() => navigate('./create')}>Add Report</Button>
      </StyledFlex>
      <br />
      <br />
      <ReportInfoPanel />
      <br />
      <br />
      <StyledPanel>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Report</TableCell>
              <TableCell>Period</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.period}</TableCell>
                <TableCell sx={{ width: '50px', pl: 0, pr: 0 }}>
                  <IconButton onClick={() => navigate(`${row.id}/about`)}>
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell sx={{ width: '50px', pl: 0, pr: 0 }}>
                  <IconButton onClick={() => _delete.mutate(row.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledPanel>
    </Box>
  );
}
