import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Image, Title, Text } from './index.styles';
import errorIcon from '../../images/error.png';
import NotFound from '../../views/components/NotFound';

export default function SomethingWentWrong({
  errorMessege,
}: {
  errorMessege?: string;
}) {
  const { t } = useTranslation();
  if (errorMessege === 'Request failed with status code 404') {
    return <NotFound />;
  }
  return (
    <Container>
      <Image src={errorIcon} />
      <Title>{t('error.went-wrong-title')}</Title>
      <Text
        dangerouslySetInnerHTML={{
          __html: errorMessege || (t('error.went-wrong-text') as string),
        }}
      />
    </Container>
  );
}
