import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export function EsgLogo() {
  return (
    <svg
      width="162"
      height="52"
      viewBox="0 0 162 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3244 10.7585C15.8209 10.7585 19.7061 13.4161 21.5342 17.2654C18.6534 23.876 11.3852 25.538 5.56837 22.6264C11.3242 22.2345 15.4107 20.9907 17.1069 17.2674C15.7162 15.6066 13.6417 14.5518 11.3244 14.5518C7.59673 14.5518 4.49651 17.2798 3.86205 20.8726C6.45157 17.3811 9.67122 16.2476 13.4133 17.0679C7.6669 16.7917 4.63734 20.3894 4.63734 24.075C4.63734 28.3196 8.87863 30.4421 12.3163 30.4421C16.4571 30.4421 20.9288 27.9837 22.5931 23.3697C22.0305 29.1739 17.1992 33.7103 11.3242 33.7103C5.07113 33.7103 0 28.5714 0 22.2345C0 15.8977 5.07137 10.7585 11.3244 10.7585Z"
        fill="#F9F9F9"
      />
      <path
        d="M51.0163 33.0283H45.7558V21.7043C45.7558 19.917 45.2996 18.5087 44.3872 17.4792C43.4748 16.4355 42.2417 15.9136 40.6878 15.9136C39.0911 15.9136 37.8081 16.4641 36.8386 17.565C36.5591 17.8784 36.3199 18.2232 36.1209 18.5996L35.9671 13.3773C36.4746 12.8961 37.0431 12.4904 37.6726 12.1604C38.9414 11.4884 40.424 11.1524 42.1205 11.1524C44.8862 11.1524 47.0603 11.996 48.6427 13.6831C50.2251 15.3703 51.0163 17.7009 51.0163 20.6748V33.0283Z"
        fill="#F9F9F9"
      />
      <path
        d="M33.7127 11.5813L34.3444 33.0283H30.124V11.5813H33.7127Z"
        fill="#F9F9F9"
      />
      <path
        d="M70.7421 33.0283L63.0532 11.5813H57.4495L66.2598 33.0283H70.7421Z"
        fill="#F9F9F9"
      />
      <path
        d="M72.4209 30.4257L80.1809 11.5813H74.5782L70.1272 24.0277L72.4209 30.4257Z"
        fill="#F9F9F9"
      />
      <path
        d="M90.3563 5.8979C89.615 5.8979 88.9877 5.64054 88.4745 5.12581C87.9755 4.59679 87.726 3.96053 87.726 3.21704C87.726 2.48784 87.9755 1.86588 88.4745 1.35116C88.9877 0.822132 89.615 0.55762 90.3563 0.55762C91.1119 0.55762 91.7462 0.822132 92.2595 1.35116C92.7727 1.86588 93.0293 2.48784 93.0293 3.21704C93.0293 3.96053 92.7727 4.59679 92.2595 5.12581C91.7462 5.64054 91.1119 5.8979 90.3563 5.8979Z"
        fill="#F9F9F9"
      />
      <path
        d="M87.726 33.0283V11.5813H92.9865V33.0283H87.726Z"
        fill="#F9F9F9"
      />
      <path
        d="M107.335 33.0283H102.264V11.5813H106.704L107.335 33.0283Z"
        fill="#F9F9F9"
      />
      <path
        d="M109.094 17.9955C109.5 17.5039 109.974 17.0888 110.518 16.75C111.473 16.1638 112.578 15.8707 113.833 15.8707C114.659 15.8707 115.515 15.9994 116.399 16.2568L117.04 11.4956C116.598 11.3812 116.149 11.2954 115.693 11.2382C115.251 11.181 114.816 11.1524 114.389 11.1524C112.749 11.1524 111.295 11.5313 110.026 12.2891C109.643 12.5205 109.284 12.7805 108.949 13.0692L109.094 17.9955Z"
        fill="#F9F9F9"
      />
      <path d="M129.269 33.0283H123.645V0H129.269V33.0283Z" fill="#F9F9F9" />
      <path
        d="M147.741 43.7517H142.247L155.516 11.5813H161.021L147.741 43.7517Z"
        fill="#F9F9F9"
      />
      <path
        d="M141.882 11.5813L147.731 24.0659L145.202 30.1981L135.958 11.5813H141.882Z"
        fill="#F9F9F9"
      />
      <path
        d="M2.11509 41.9724V44.5433H5.66542V46.174H2.11509V48.8918H6.11866V50.5666H0V40.2976H6.11866V41.9724H2.11509Z"
        fill="white"
      />
      <path
        d="M14.4775 50.6694C13.7423 50.6694 13.0775 50.547 12.4833 50.3021C11.8991 50.0573 11.4358 49.7047 11.0934 49.2444C10.7509 48.7841 10.5747 48.2405 10.5646 47.6137H12.8308C12.861 48.0348 13.0121 48.3678 13.284 48.6127C13.566 48.8575 13.9487 48.9799 14.4322 48.9799C14.9257 48.9799 15.3135 48.8673 15.5955 48.6421C15.8775 48.407 16.0185 48.1034 16.0185 47.7312C16.0185 47.4276 15.9228 47.1779 15.7315 46.982C15.5401 46.7861 15.2984 46.6343 15.0063 46.5266C14.7243 46.409 14.3315 46.2817 13.8279 46.1446C13.143 45.9487 12.584 45.7577 12.1509 45.5717C11.7279 45.3758 11.3603 45.0869 11.0481 44.7049C10.7459 44.3131 10.5948 43.7941 10.5948 43.1477C10.5948 42.5404 10.7509 42.0116 11.0632 41.5611C11.3754 41.1105 11.8135 40.7677 12.3775 40.5327C12.9416 40.2878 13.5862 40.1654 14.3113 40.1654C15.3991 40.1654 16.2804 40.425 16.9552 40.944C17.6401 41.4533 18.0178 42.1683 18.0883 43.0889H15.7617C15.7415 42.7363 15.5854 42.4474 15.2933 42.2221C15.0113 41.9871 14.6336 41.8696 14.1603 41.8696C13.7473 41.8696 13.4149 41.9724 13.1631 42.1781C12.9214 42.3837 12.8006 42.6825 12.8006 43.0742C12.8006 43.3484 12.8912 43.5786 13.0725 43.7647C13.2639 43.941 13.4955 44.0879 13.7674 44.2054C14.0495 44.3131 14.4423 44.4405 14.9459 44.5874C15.6307 44.7833 16.1897 44.9791 16.6228 45.175C17.0559 45.3709 17.4286 45.6647 17.7408 46.0565C18.053 46.4482 18.2091 46.9624 18.2091 47.599C18.2091 48.1475 18.0631 48.6567 17.771 49.1269C17.4789 49.597 17.0509 49.974 16.4869 50.2581C15.9228 50.5323 15.2531 50.6694 14.4775 50.6694Z"
        fill="white"
      />
      <path
        d="M29.5867 43.3081C29.3449 42.8772 29.0126 42.5491 28.5896 42.3238C28.1665 42.0986 27.673 41.9859 27.109 41.9859C26.4845 41.9859 25.9306 42.1231 25.4471 42.3973C24.9637 42.6715 24.586 43.0633 24.314 43.5726C24.0421 44.0818 23.9061 44.6695 23.9061 45.3355C23.9061 46.021 24.0421 46.6185 24.314 47.1278C24.5961 47.637 24.9838 48.0288 25.4774 48.303C25.9709 48.5773 26.545 48.7144 27.1996 48.7144C28.0054 48.7144 28.6651 48.5087 29.1788 48.0974C29.6924 47.6762 30.0298 47.0935 30.191 46.3491H26.5651V44.7772H32.2759V46.5695C32.1349 47.2845 31.8327 47.9455 31.3694 48.5528C30.9061 49.16 30.3068 49.6497 29.5716 50.0219C28.8464 50.3842 28.0306 50.5654 27.1241 50.5654C26.1068 50.5654 25.1853 50.3451 24.3594 49.9043C23.5436 49.4538 22.899 48.8319 22.4256 48.0386C21.9623 47.2453 21.7306 46.3442 21.7306 45.3355C21.7306 44.3267 21.9623 43.4257 22.4256 42.6323C22.899 41.8292 23.5436 41.2073 24.3594 40.7666C25.1853 40.3161 26.1018 40.0908 27.109 40.0908C28.2975 40.0908 29.3298 40.3748 30.2061 40.9429C31.0823 41.5011 31.6867 42.2896 32.019 43.3081H29.5867Z"
        fill="white"
      />
    </svg>
  );
}
