import React from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Member, OrganizationMember } from 'src/Superadmin/index.types';
import { useOrganizationMember } from './hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  member: Member;
  organizationMember: OrganizationMember;
}
export default function ActionMenu(props: Props) {
  const { t } = useTranslation();
  const { member, organizationMember } = props;
  const { deleteOrganizationMember } = useOrganizationMember(member.id);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>();

  const handleClose = () => setMenuAnchorEl(null);
  const handleClick = (e: React.MouseEvent<HTMLElement>) =>
    setMenuAnchorEl(e.currentTarget);

  const handleDelete = () => {
    deleteOrganizationMember.mutate(organizationMember.id);
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu open={!!menuAnchorEl} anchorEl={menuAnchorEl} onClose={handleClose}>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>
            {t('superadmin.settings.tabs.users.usersTable.menu.delete')}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
