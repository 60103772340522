import { createTheme } from '@mui/material';
import {
  DISABLED_COLOR,
  ERROR_COLOR,
  HOVER_COLOR,
  TEXT_COLOR,
  ThemeColors,
  themeColors as _themeColors,
  lightenColor,
} from '../helpers';

// eslint-disable-next-line import/prefer-default-export
export const getFieldTheme = (themeColors: ThemeColors) => {
  return createTheme({
    components: {
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: '16px ',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            gap: '10px',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(2px) sepia(5%)',
            '& .MuiPaper-root': {
              borderRadius: '16px',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0.32px',
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& .Mui-error .MuiSvgIcon-root': {
              color: ERROR_COLOR,
            },
            '& .Mui-error .MuiTypography-root': {
              color: ERROR_COLOR,
            },
            '& .Mui-disabled .MuiTypography-root': {
              color: DISABLED_COLOR,
            },
            '& .MuiFormLabel-root': {
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
              letterSpacing: '0.32px',
              '&.Mui-focused': {
                color: TEXT_COLOR,
              },
              '&.Mui-error': {
                color: ERROR_COLOR,
              },
            },
            '& .Mui-error .MuiFormLabel-root': {
              color: ERROR_COLOR,
            },

            '& .MuiRating-icon': {
              color: themeColors.main,
            },
            '& .MuiRating-root': {
              '&.Mui-disabled': {
                '& .MuiRating-icon': {
                  color: DISABLED_COLOR,
                },
              },
              '&.Mui-error': {
                '& .MuiRating-icon': {
                  color: ERROR_COLOR,
                },
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0.32px',
            borderRadius: '8px',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: themeColors.dark,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: ERROR_COLOR,
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: '#bababa',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-input': {
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
              letterSpacing: '0.32px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '8px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: themeColors.dark,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: ERROR_COLOR,
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: '#bababa',
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: DISABLED_COLOR,
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            '&:hover': {
              backgroundColor: HOVER_COLOR,
            },
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0.32px',
            borderRadius: '8px',
            '&.Mui-error': {
              borderColor: ERROR_COLOR,
              color: ERROR_COLOR,
            },

            '&.Mui-selected': {
              backgroundColor: themeColors.main,
              color: themeColors.contrastText,
              '&:hover': {
                backgroundColor: themeColors.light,
              },
              '&.Mui-disabled': {
                backgroundColor: HOVER_COLOR,
                color: DISABLED_COLOR,
              },
              '&.Mui-error': {
                backgroundColor: ERROR_COLOR,
                color: '#fff',
                '&:hover': {
                  backgroundColor: ERROR_COLOR,
                },
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          textSecondary: {
            color: '#38414F',
            border: '1px solid #D9D9D9',
            ':hover': {
              color: '#38414F',
            },
          },

          root: {
            fontWeight: 600,
            boxShadow: 'none',
            borderRadius: 28,
            fontSize: 18,
            padding: '0 24px',
            textTransform: 'none',

            '&.MuiButton-containedSecondary': {
              border: '1px solid #D9D9D9',
              backgroundColor: '#fff',
              height: '40px',
              color: '#38414F',
              fontSize: '14px',
              fontWeight: 700,
              alignSelf: 'center',
              padding: '0 18px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
              '&:pressed': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
              },
              '& .MuiButton-endIcon': {
                color: '#38414F',
                fontSize: '24px ',
                marginLeft: '3px',
              },
            },

            '&.Esg': {
              backgroundColor: _themeColors['esg.standard'],
              borderRadius: '28px',
              minWidth: '192px',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '15px',
              textTransform: 'none',
              padding: '10px 30px',

              '&.EsgPrimary': {
                backgroundColor: _themeColors['esg.standard'],
                color: '#fff',
                '&:hover': {
                  backgroundColor: lightenColor(
                    _themeColors['esg.standard'],
                    -1
                  ),
                },
              },
              '&.EsgSecondary': {
                backgroundColor: '#fff',
                color: _themeColors['esg.standard'],
                border: `1px solid ${DISABLED_COLOR}`,
                '&:hover': {
                  backgroundColor: HOVER_COLOR,
                },
              },
            },

            '&.file': {
              height: 40,
              backgroundColor: themeColors.main,
              borderRadius: 16,
              paddingLeft: 16,
              textTransform: 'unset',
              '& a': {
                color: '#38414f',
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '18px',
                letterSpacing: '0.28px',
                textDecoration: 'none',

                '&:hover': {
                  textDecoration: 'underline',
                },
              },
            },
            '&.attache': {
              color: '#38414f',
              textAlign: 'center',
              fontSize: 16,
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '18px',
              letterSpacing: '0.32px',
              display: 'flex',
              height: 40,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 24,
              border: '1px solid  #ccc',
              background: '#fff',
              marginTop: 32,
              padding: '0 26px',
              textTransform: 'unset',

              '&:hover': {
                border: '1px solid #ccc',
                background: 'rgba(0, 0, 0, 0.04)',
              },

              '&:active': {
                border: '1px solid  rgba(0, 0, 0, 0.08)',
                background: 'rgba(0, 0, 0, 0.08)',
              },
            },
          },
        },
      },
      // @ts-ignore
      EsgDescriptions: {
        styleOverrides: {
          root: {
            '& > p': {
              backgroundColor: themeColors.main,
              color: themeColors.contrastText,
            },
          },
        },
      },
    },
    palette: {
      text: {
        primary: TEXT_COLOR,
      },
      primary: {
        main: themeColors.main,
      },
      error: {
        main: ERROR_COLOR,
      },
    },
    typography: {
      fontFamily: 'Poppins',
    },
  });
};
