import React from 'react';
import SurveyContainer from '../../../components/Survey/Container';
import Title from '../../../components/Survey/Title';
import { Trans, useTranslation } from 'react-i18next';
import { Logo } from '../../../components/Survey/icons';
import ImageHeader from '../../../components/Survey/ImageHeader';
import assets from '../assets';
import PageLoading from '../../../components/PageLoading';
import { Box, Typography } from '@mui/material';
import NotFound from '../../../views/components/NotFound';
import { useCodeOfConductSurvey } from '../hooks';
import { Subtitle } from '../../SupplierSurvey/styles';
import { useNavigate } from 'react-router-dom';

export default function CodeOfConductSurveyEnd() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ghg.codeOfConductSurvey',
  });
  const { query } = useCodeOfConductSurvey();
  const navigate = useNavigate();

  if (query.isError) return <NotFound />;
  if (!query.data) return <PageLoading />;

  return (
    <SurveyContainer
      logo={<Logo />}
      header={
        <ImageHeader imageSrc={assets.end.image} text={t('mainHeader')} />
      }
      prevLink={{
        label: t('end.editResponse'),
        onClick: () => navigate('../form'),
      }}
    >
      <Box>
        <Title title={t('end.title')} />
        <Subtitle>{t('end.subtitle')}</Subtitle>
        <Trans t={t} i18nKey="end.message" />
        <Typography>{query.data.organization_name}</Typography>
      </Box>
    </SurveyContainer>
  );
}
