import { Box, Divider, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { StyledFlex } from 'src/components/StyledFlex';
import PaginatorSwitch, { usePaginationSwitch } from './PaginatorSwitch';
import DownloadButton from './DownloadButton';
import { StyledHeader } from './styles';
import Page from './Page';
import { Qv3SummaryTableSchema, Qv3SummaryTableType } from '../types';
import { parseSummaryData } from './utils';

interface Props {
  data: Qv3SummaryTableSchema[];
  tableType: Qv3SummaryTableType;
  customColumns?: string[];
  // eslint-disable-next-line
  onTableDelete?: (id: number) => void;
}

export default function Qv3SummaryTable(props: Props) {
  const { onTableDelete, data, tableType, customColumns } = props;
  const { currPage, handleNextClick, handlePrevClick, setCurrPage } =
    usePaginationSwitch();
  const pages = parseSummaryData(data, tableType, customColumns);
  const { id, title, columns, rows } = pages[currPage];

  const _onTableDelete = (id: number) => {
    setCurrPage(0);
    if (onTableDelete) onTableDelete(id);
  };
  return (
    <Box>
      <StyledFlex sx={{ mt: '-12px' }}>
        <PaginatorSwitch
          currPage={currPage}
          pagesCount={pages.length}
          onPrevClick={handlePrevClick}
          onNextClick={handleNextClick}
        />
        <Box>
          <DownloadButton format="csv" disabled />
          &nbsp;
          <DownloadButton format="pdf" disabled />
          &nbsp;
          {!!onTableDelete && (
            <IconButton onClick={() => _onTableDelete(id)}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </StyledFlex>
      <Divider sx={{ m: '20px -32px' }} />
      <StyledHeader>{title}</StyledHeader>
      <Divider sx={{ mt: '20px' }} />
      <Page columns={columns} rows={rows} />
    </Box>
  );
}
