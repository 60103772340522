import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import BasePage from '../Base';
import stylesCommon from '../stylesCommon';
import { CoverDataType } from './types';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  textContainer: {
    color: '#fff',
  },
  reportRangeText: {
    fontWeight: 600,
    fontSize: 56,
    letterSpacing: 2,
    textShadow: '1px 2px 15px rgba(0, 0, 0, 0.60)',
    fontFamily: 'Poppins',
    fontStyle: 'bold',
    lineHeight: 120,
    position: 'absolute',
    top: 571,
    left: 93,
  },
  reportTitle: {
    fontWeight: 700,
    fontFamily: 'Poppins',
    fontSize: 84,
    fontStyle: 'bold',
    letterSpacing: 2,
    position: 'absolute',
    top: 709,
    left: 93,
    lineHeight: 120,
  },
  companyNameText: {
    fontStyle: 'italic',
    fontWeight: 700,
    fontSize: 84,
    letterSpacing: 2,
    fontFamily: 'Poppins',
    lineHeight: 120,
    textShadow: '1px 2px 30px rgba(24, 45, 62, 0.50)',
    position: 'absolute',
    top: 836,
    left: 93,
  },
});

type IntroductionProps = {
  data: {
    cover: CoverDataType;
  };
};

const CoverPage = ({ data }: IntroductionProps) => {
  const {
    report_range: reportRange,
    report_title: reportTitle,
    company_name: companyName,
  } = data.cover;
  const todayString = new Date().toLocaleDateString('pl', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const companyNameStyle = styles.companyNameText;
  if (companyName.length > 30) companyNameStyle.fontSize = 62;
  if (companyName.length > 40) companyNameStyle.fontSize = 56;
  if (companyName.length > 60) companyNameStyle.fontSize = 36;
  if (companyName.length > 90) companyNameStyle.fontSize = 26;
  if (companyName.length > 120) companyNameStyle.fontSize = 20;

  return (
    <BasePage backgroundImageName="Report_envirly_slide_1.png">
      <View style={styles.textContainer}>
        <Text style={styles.reportRangeText}>{reportRange}</Text>
        <Text style={styles.reportTitle}>{reportTitle}</Text>
        <Text style={companyNameStyle}>{companyName}</Text>
        <Text style={stylesCommon.bottomRightFooter}>{todayString}</Text>
      </View>
    </BasePage>
  );
};

export default CoverPage;
