import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material';
import { client } from '../../../../utils/api-client';

import QuestionnairePreview from './Preview';
import Management from './Management';

const StyledContainer = styled('div')``;

export default function Preview() {
  const { questionnaireAnswerId } = useParams();

  useEffect(() => {
    const previewInterceptor = client.interceptors.request.use((config) => {
      // only allow GET requests,
      //  and requests related to language selection
      if (
        config?.method !== 'get' &&
        (config?.url?.indexOf('/web/users/me/language') ?? -1) === -1
      ) {
        alert('blocked !!');
        return Promise.reject(new Error('Blocked'));
      }
      return config;
    });
    return () => client.interceptors.request.eject(previewInterceptor);
  }, []);

  return (
    <StyledContainer>
      <Management />
      <QuestionnairePreview
        key={questionnaireAnswerId}
        id={String(questionnaireAnswerId)}
      />
    </StyledContainer>
  );
}
