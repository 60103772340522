import React from 'react';

export default function DirectEmissionIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11_7362)">
        <path
          d="M18.92 6.41014C18.72 5.80941 18.16 5.38177 17.5 5.38177H6.5C5.84 5.38177 5.29 5.80941 5.08 6.41014L3 12.509V20.6545C3 21.2145 3.45 21.6727 4 21.6727H5C5.55 21.6727 6 21.2145 6 20.6545V19.6363H18V20.6545C18 21.2145 18.45 21.6727 19 21.6727H20C20.55 21.6727 21 21.2145 21 20.6545V12.509L18.92 6.41014ZM6.5 16.5818C5.67 16.5818 5 15.8996 5 15.0545C5 14.2094 5.67 13.5272 6.5 13.5272C7.33 13.5272 8 14.2094 8 15.0545C8 15.8996 7.33 16.5818 6.5 16.5818ZM17.5 16.5818C16.67 16.5818 16 15.8996 16 15.0545C16 14.2094 16.67 13.5272 17.5 13.5272C18.33 13.5272 19 14.2094 19 15.0545C19 15.8996 18.33 16.5818 17.5 16.5818ZM5 11.4909L6.5 6.90905H17.5L19 11.4909H5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_7362">
          <rect
            width="24"
            height="24.4364"
            fill="currentColor"
            transform="translate(0 0.290894)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
