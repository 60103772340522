import React from 'react';
import { Box } from '@mui/material';
// import InstallationData from './InstallationData';
// import ProductionProcess from './ProductionProcess';

export default function OperatorSurvey() {
  return (
    <Box
      sx={{
        margin: '10%',
        '& .MuiInputBase-root': {
          mb: '15px',
        },
        '.hide-in-oprator-survey': {
          display: 'none',
        },
      }}
    >
      {/* <InstallationData /> */}
      {/* <ProductionProcess /> */}
    </Box>
  );
}
