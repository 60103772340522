import React from 'react';
import { StyledFlex } from 'src/components/StyledFlex';
import { StyledCellValue } from './styles';
import { withAlpha } from './utils';
import { formatNumberWithPrecision } from 'src/utils/helpers';

interface Props {
  value: number;
  max: number;
  color?: string;
}

// Impact Grid Cell labeled value
export default function Label(props: Props) {
  const { value, max, color } = props;
  const alpha = Math.max(0.2, value / max || 0);
  return (
    <StyledFlex
      sx={{
        borderRadius: '8px',
        backgroundColor: withAlpha(color || '#e0e0e0', alpha),
        height: '32px',
      }}
    >
      <StyledCellValue>
        {formatNumberWithPrecision(value, 5, 2)}
      </StyledCellValue>
    </StyledFlex>
  );
}
