import React from 'react';
import { styled as styledMUI } from '@mui/material/styles';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

export const StyledToggleButton = styledMUI(ToggleButton)`
  background: white;
  margin-top: 0px;
  border-radius: 24px;
  border: 2px solid var(--brand-color-main, #7E4308);
  background: var(--White, #FFF); 
  transform: translateY(-3px);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: #7E4308;

  &.MuiToggleButton-root.Mui-selected {
    background: #7E4308;
    color: #fff;
    transform: translateY(0px)
  }
  
  &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
  margin-left: -2px;
  border: 2px solid var(--brand-color-main, #7E4308);}
  
  &:hover {
    background: var(--brand-color-light, #FFF7E8); 
  }
`;

export const StyledToggleButtonGroup = styledMUI(ToggleButtonGroup)`
  &.MuiToggleButtonGroup-root {
    border-radius: 24px;
    background: #7E4308;
  }
`;

interface Props {
  options: Array<{
    value: string | boolean;
    name: string;
  }>;
  state: string | null | boolean;
  setState: Function;
}

export default function ToggleButtonLCA({ options, setState, state }: Props) {
  return (
    <StyledToggleButtonGroup
      value={state}
      exclusive
      onChange={(e) => setState(e)}
      fullWidth
    >
      {options.map((el) => (
        <StyledToggleButton key={el.name} value={el.value} aria-label={el.name}>
          {el.name}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
}
