import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { DmaStepDialog as Props } from './index.types';
import LightTooltip from 'src/components/LightTooltip';

export default function DmaStepDialog({ icon, content }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const contentWithHandleClose = React.cloneElement(content, { handleClose });

  return (
    <>
      <LightTooltip title={t('esg.dma.step.videoButton.toggle')}>
        <IconButton onClick={handleOpen}>{icon}</IconButton>
      </LightTooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <IconButton
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
          }}
          onClick={handleClose}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>
        {contentWithHandleClose}
      </Dialog>
    </>
  );
}
