import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingHedingProps } from './index.interfaces';
import {
  BottomBox,
  Container,
  Title,
  TopBox,
  TopBoxSubTitle,
  TopBoxTitle,
} from './index.styles';

export default function OnboardingHeading({
  children,
  text,
}: OnboardingHedingProps) {
  const { t } = useTranslation();
  return (
    <Container>
      <TopBox>
        <TopBoxTitle>{t('login.welcome')}</TopBoxTitle>
        <TopBoxSubTitle>{t('login.management-tool')}</TopBoxSubTitle>
      </TopBox>
      <BottomBox>
        <Title>{text}</Title>
        <div>{children}</div>
      </BottomBox>
    </Container>
  );
}
