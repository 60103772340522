import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const CheckboxWrapper = styled(Box)`
  display: flex;
  margin-top: 20px;
  margin-left: 10px;
  align-items: flex-start;
`;

export const ConsentsInformation = styled(Typography)`
  font-size: 12px;
  font-style: italic;
  color: #606462;
  margin-top: 20px;
`;
