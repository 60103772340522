import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import AddProductButton from '../../../components/AddProductButton';
import SoldProductsWasteForm from '../../components/Questionnaire/forms/fuels-and-raw-materials/waste-form';
import RequiredForm from '../../components/Questionnaire/forms/sold_products/direct/required_form';
import QuestionnaireTitle from '../../components/Questionnaire/Title';
import QuestionnaireSectionTitle from '../../components/QuestionnaireSectionTitle';
import { Breadcrumbs } from '../Targets/index.styles';
import {
  comment_cloud,
  final_product_direct_energy_cloud,
  final_product_direct_fuel_cloud,
  final_product_direct_leakage_cloud,
  final_product_direct_other_cloud,
  final_product_direct_product_life_cycle_cloud,
  final_product_direct_sales_region_cloud,
  fuel_and_raw_materials_waste_cloud,
} from './clouds_data';
import { waste_init } from './fuels-and-raw-materials';
import { index_url } from './index-sold-products';
import {
  AnotherButtonWrapper,
  CommentTextArea,
  Description,
  ExampleBox,
  FinalProductTable,
  FinalProductTableHead,
  FinalProductTableRow,
  PageContainer,
  PreviewBox,
  SaveButton,
  SaveProductButtonWrapper,
  Section,
  SectionGroupContainer,
} from './index.styles';
import { timeConverter } from '../../../utils/timeConverter';
import {
  getSoldProductsData,
  createSoldProductsFinalProductDirectData,
} from '../../../redux-file/questionnaires/thunks';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import SalesRegionForm from '../../components/Questionnaire/forms/sold_products/region';
import FugutiveFormSoldProducts from '../../components/Questionnaire/forms/sold_products/fugutiveEmissionsForm';
import FuelForm from '../../components/Questionnaire/forms/sold_products/fuelForm';
import EnergyForm from '../../components/Questionnaire/forms/sold_products/energyForm';
import OtherForm from '../../components/Questionnaire/forms/sold_products/otherForm';
import UploadExcelButton from '../../../components/UploadExcelButton';

export const sp_required_init = {
  name: '',
  number_of_uses_or_yesrs_of_use: '',
  unit: '',
  number_of_products_sold: '',
  assumptions: '',
};

export default function FinalProductDirect() {
  const { selectedDate } = useAppSelector((state) => state.questionnaire);

  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const fugutiveInit = {
    amount_kg_per_period: '',
    comment: '',
    emission_type: '',
    manual_factor: null,
  };

  const fuelInit = {
    amount_per_period: '',
    comment: '',
    manual_factor: null,
    name_of_equipement: '',
    type_of_fuel: '',
    unit: '',
  };

  const energyInit = {
    emission_factor_is_known: false,
    energy_usage_data_factor_unknown: {
      spending_pln_or_kwh_per_period: '',
      unit: '',
    },
    energy_usage_data_factor_known: {
      factor: '',
      amount_per_period: '',
      unit_label: '',
    },
    certified_sources_are_utilized: false,
    certified_energy_usage_data: {
      certified_energy_type_label: '',
      certified_percentge: '',
      emission_factor: '',
    },
  };

  const regions_init = ['', '', '', '', '', '', '', ''];

  const [required_state, set_required_state] = useState(sp_required_init);
  const [waste_state, set_waste_state] = useState([waste_init]);
  const [fugutive_state, set_fugutive_state] = useState([fugutiveInit]);
  const [fuel_state, set_fuel_state] = useState([fuelInit]);
  const [energy_state, set_energy_state] = useState([energyInit]);
  const [regions_state, set_regions_state] = useState(regions_init);
  const [other_state, set_other_state] = useState('');

  const [activeRequire, setActiveRequire] = useState(false);
  const [activeRegions, setActiveRegions] = useState(false);
  const [activeWaste, setActiveWaste] = useState<undefined | number>(undefined);
  const [activeFuel, setActiveFuel] = useState<undefined | number>(undefined);
  const [activeEnergy, setActiveEnergy] = useState<undefined | number>(
    undefined
  );
  const [activeFugutive, setActiveFugutive] = useState<undefined | number>(
    undefined
  );
  const [activeOther, setActiveOther] = useState(false);

  const combinedData = {
    ...required_state,
    sales_region: regions_state,
    fuel_data:
      fuel_state.length === 1 && fuel_state[0].type_of_fuel === ''
        ? []
        : fuel_state,
    energy_data:
      energy_state.length === 1 &&
      energy_state[0].emission_factor_is_known === false &&
      energy_state[0].energy_usage_data_factor_unknown
        .spending_pln_or_kwh_per_period === ''
        ? []
        : energy_state,
    fugutive_emissions_data:
      fugutive_state.length === 1 && fugutive_state[0].emission_type === ''
        ? []
        : fugutive_state,
    waste_data:
      waste_state.length === 1 && waste_state[0].waste_type === ''
        ? []
        : waste_state,
    other_emissions_kg_eq_co2: other_state === '' ? null : other_state,
  };

  const waste_ref = useRef(null);
  const fuel_ref = useRef(null);
  const energy_ref = useRef(null);
  const fugutive_ref = useRef(null);

  const combineFuelData = (fullData: any, newData: any) => {
    set_fuel_state([...newData, ...fullData.fuel_data]);
  };
  const combineWasteData = (fullData: any, newData: any) => {
    set_waste_state([...newData, ...fullData.waste_data]);
  };
  const combineEnergyData = (fullData: any, newData: any) => {
    set_energy_state([...newData, ...fullData.energy_data]);
  };
  const combineFugutiveData = (fullData: any, newData: any) => {
    set_fugutive_state([...newData, ...fullData.fugutive_emissions_data]);
  };

  return (
    <PageContainer>
      <SectionGroupContainer>
        <Breadcrumbs>
          <Link to={index_url}>{t('menu.sold-products')}</Link>
          <span>{'>'}</span>
          <div>{t(`sold-products.final-products.direct.title`)}</div>
        </Breadcrumbs>
        <QuestionnaireTitle
          info
          text={t('sold-products.final-products.direct.title')}
        />
        <Description>
          {t('sold-products.final-products.direct.desc')}
        </Description>
        <Section>
          <ExampleBox>
            <span>{t('example')}</span>
            <p>{t('sold-products.final-products.direct.example')}</p>
          </ExampleBox>

          <FinalProductTable>
            <FinalProductTableHead columns="1fr 1fr 2fr 2fr">
              <div>
                {t('sold-products.final-products.direct.table.head.col1')}
              </div>
              <div>
                {t('sold-products.final-products.direct.table.head.col2')}
              </div>
              <div>
                {t('sold-products.final-products.direct.table.head.col3')}
              </div>
              <div>
                {t('sold-products.final-products.direct.table.head.col4')}
              </div>
            </FinalProductTableHead>
            <FinalProductTableRow columns="1fr 1fr 2fr 2fr">
              <div>
                {t('sold-products.final-products.direct.table.row1.col1')}
              </div>
              <div>
                {t('sold-products.final-products.direct.table.row1.col2')}
              </div>
              <div>
                {t('sold-products.final-products.direct.table.row1.col3')}
              </div>
              <div>
                {t('sold-products.final-products.direct.table.row1.col4')}
              </div>
            </FinalProductTableRow>
          </FinalProductTable>
        </Section>
        <Section>
          <QuestionnaireSectionTitle
            active={false}
            level="primary"
            text={t('sold-products.fuels-and-raw-materials.basic')}
          />
        </Section>
        <Section>
          <QuestionnaireSectionTitle
            active={false}
            level="secondary"
            boxContent={final_product_direct_product_life_cycle_cloud}
            text={t('sold-products.final-products.direct.life-cycle.title')}
          />
          <PreviewBox>
            <RequiredForm
              state={required_state}
              setState={set_required_state}
              active={activeRequire}
              setActive={setActiveRequire}
            />
          </PreviewBox>
        </Section>

        <Section>
          <QuestionnaireSectionTitle
            active={false}
            level="secondary"
            boxContent={comment_cloud}
            text={t('sold-products.final-products.direct.description.title')}
          />

          <PreviewBox>
            <CommentTextArea
              placeholder={t(
                'sold-products.fuels-and-raw-materials.comment.placeholder'
              )}
              value={required_state.assumptions}
              onChange={(e) => {
                const tempState = {
                  name: required_state.name,
                  number_of_uses_or_yesrs_of_use:
                    required_state.number_of_uses_or_yesrs_of_use,
                  unit: required_state.unit,
                  number_of_products_sold:
                    required_state.number_of_products_sold,
                  assumptions: e.target.value,
                };

                set_required_state(tempState);
              }}
            />
          </PreviewBox>
        </Section>

        <Section>
          <QuestionnaireSectionTitle
            active={false}
            level="secondary"
            boxContent={final_product_direct_sales_region_cloud}
            text={t('sold-products.final-products.direct.region.title')}
          />
          <PreviewBox>
            <SalesRegionForm
              active={activeRegions}
              setState={set_regions_state}
              state={regions_state}
              setActive={setActiveRegions}
            />
          </PreviewBox>
        </Section>

        <Section>
          <QuestionnaireSectionTitle
            active={false}
            level="primary"
            text={t('sold-products.final-products.direct.emission.title')}
          />
        </Section>

        <Section>
          <div ref={fuel_ref} />
          <QuestionnaireSectionTitle
            active={false}
            level="secondary"
            boxContent={final_product_direct_fuel_cloud}
            text={t('sold-products.final-products.direct.fuel.title')}
          />

          {fuel_state.map((_, id) => (
            <PreviewBox key={id}>
              <FuelForm
                state={fuel_state}
                id={id}
                setState={set_fuel_state}
                active={activeFuel}
                setActive={setActiveFuel}
                setNewForm={() => {}}
              />
            </PreviewBox>
          ))}
          <AnotherButtonWrapper>
            {fuel_state[fuel_state.length - 1].amount_per_period !== '' &&
              fuel_state[fuel_state.length - 1].unit !== '' &&
              fuel_state[fuel_state.length - 1].type_of_fuel !== '' &&
              (fuel_state[fuel_state.length - 1].type_of_fuel !==
                'other.manual_factor' ||
                !!fuel_state[fuel_state.length - 1].manual_factor) && (
                <>
                  <AddProductButton
                    onClick={() => {
                      set_fuel_state([fuelInit, ...fuel_state]);
                      setActiveFuel(0);
                      if (fuel_ref && fuel_ref.current) {
                        // @ts-ignore
                        fuel_ref.current.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }
                    }}
                  />
                  <span>{t('popup.excel.or')}</span>
                </>
              )}
            <UploadExcelButton
              name={t('sold-products.final-products.direct.fuel.title')}
              slug="StationaryCombustionData"
              fullData={combinedData}
              combineData={combineFuelData}
              sendData={() => {}}
              refresh={() => {}}
            />
          </AnotherButtonWrapper>
        </Section>
        <Section>
          <div ref={energy_ref} />
          <QuestionnaireSectionTitle
            active={false}
            level="secondary"
            boxContent={final_product_direct_energy_cloud}
            text={t('sold-products.final-products.direct.energy.title')}
          />

          {energy_state.map((_, id) => (
            <PreviewBox key={id}>
              <EnergyForm
                id={id}
                setNewForm={() => {}}
                active={activeEnergy}
                setActive={setActiveEnergy}
                state={energy_state}
                setState={set_energy_state}
              />
            </PreviewBox>
          ))}

          <AnotherButtonWrapper>
            {(!energy_state[energy_state.length - 1].emission_factor_is_known &&
              energy_state[energy_state.length - 1]
                .energy_usage_data_factor_unknown.unit !== '' &&
              energy_state[energy_state.length - 1]
                .energy_usage_data_factor_unknown
                .spending_pln_or_kwh_per_period !== '') ||
              (energy_state[energy_state.length - 1].emission_factor_is_known &&
                energy_state[energy_state.length - 1]
                  .energy_usage_data_factor_known.factor !== '' &&
                energy_state[energy_state.length - 1]
                  .energy_usage_data_factor_known.amount_per_period !== '') ||
              (energy_state[energy_state.length - 1]
                .certified_sources_are_utilized &&
                energy_state[energy_state.length - 1]
                  .certified_energy_usage_data.certified_percentge !== '' &&
                energy_state[energy_state.length - 1]
                  .certified_energy_usage_data.emission_factor !== '' && (
                  <>
                    <AddProductButton
                      onClick={() => {
                        set_energy_state([energyInit, ...energy_state]);
                        setActiveEnergy(0);
                        if (waste_ref && waste_ref.current) {
                          // @ts-ignore
                          waste_ref.current.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }
                      }}
                    />
                    <span>{t('popup.excel.or')}</span>
                  </>
                ))}
            <UploadExcelButton
              name={t('sold-products.final-products.direct.energy.title')}
              slug="EnergyUsageData"
              fullData={combinedData}
              combineData={combineEnergyData}
              sendData={() => {}}
              refresh={() => {}}
            />
          </AnotherButtonWrapper>
        </Section>
        <Section>
          <div ref={fugutive_ref} />
          <QuestionnaireSectionTitle
            active={false}
            level="secondary"
            boxContent={final_product_direct_leakage_cloud}
            text={t('sold-products.final-products.direct.leakage.title')}
          />

          {fugutive_state.map((_, id) => (
            <PreviewBox key={id}>
              <FugutiveFormSoldProducts
                active={activeFugutive}
                setActive={setActiveFugutive}
                state={fugutive_state}
                setState={set_fugutive_state}
                setNewForm={() => {}}
                id={id}
              />
            </PreviewBox>
          ))}

          <AnotherButtonWrapper>
            {fugutive_state[fugutive_state.length - 1].amount_kg_per_period !==
              '' &&
              fugutive_state[fugutive_state.length - 1].emission_type !== '' &&
              (fugutive_state[fugutive_state.length - 1].emission_type !==
                'other.manual_factor' ||
                !!fugutive_state[fugutive_state.length - 1].manual_factor) && (
                <>
                  <AddProductButton
                    onClick={() => {
                      set_fugutive_state([fugutiveInit, ...fugutive_state]);
                      setActiveFugutive(0);
                      if (fugutive_ref && fugutive_ref.current) {
                        // @ts-ignore
                        fugutive_ref.current.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }
                    }}
                  />
                  <span>{t('popup.excel.or')}</span>
                </>
              )}
            <UploadExcelButton
              name={t('sold-products.final-products.direct.leakage.title')}
              slug="FugutiveEmissionData"
              fullData={combinedData}
              combineData={combineFugutiveData}
              sendData={() => {}}
              refresh={() => {}}
            />
          </AnotherButtonWrapper>
        </Section>
        <Section>
          <QuestionnaireSectionTitle
            active={false}
            level="secondary"
            boxContent={final_product_direct_other_cloud}
            text={t('sold-products.final-products.direct.other.title')}
          />
          <PreviewBox>
            <OtherForm
              active={activeOther}
              setActive={setActiveOther}
              state={other_state}
              setState={set_other_state}
            />
          </PreviewBox>
        </Section>

        <Section>
          <QuestionnaireSectionTitle
            active={false}
            level="primary"
            text={t('sold-products.final-products.direct.waste.main')}
          />
        </Section>
        <Section>
          <div ref={waste_ref} />
          <QuestionnaireSectionTitle
            active={false}
            level="secondary"
            boxContent={fuel_and_raw_materials_waste_cloud}
            text={t('sold-products.final-products.direct.waste.title')}
          />
          {waste_state.map((_, id) => (
            <PreviewBox key={id}>
              <SoldProductsWasteForm
                setNewForm={() => {}}
                id={id}
                setState={set_waste_state}
                state={waste_state}
                active={activeWaste}
                setActive={setActiveWaste}
              />
            </PreviewBox>
          ))}
          <AnotherButtonWrapper>
            {waste_state[waste_state.length - 1].waste_type !== '' &&
              waste_state[waste_state.length - 1].amount_kg !== '' &&
              (waste_state[waste_state.length - 1].waste_type !==
                'other.manual_factor' ||
                !!waste_state[waste_state.length - 1].manual_factor) && (
                <>
                  <AddProductButton
                    onClick={() => {
                      set_waste_state([waste_init, ...waste_state]);
                      setActiveWaste(0);
                      if (waste_ref && waste_ref.current) {
                        // @ts-ignore
                        waste_ref.current.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }
                    }}
                  />
                  <span>{t('popup.excel.or')}</span>
                </>
              )}
            <UploadExcelButton
              name={t('sold-products.final-products.direct.waste.title')}
              slug="WasteData"
              fullData={combinedData}
              combineData={combineWasteData}
              sendData={() => {}}
              refresh={() => {}}
            />
          </AnotherButtonWrapper>
        </Section>

        <Section>
          <SaveProductButtonWrapper>
            <SaveButton
              disabled={
                required_state.number_of_uses_or_yesrs_of_use === '' ||
                regions_state.filter((el) => el !== '').length === 0
              }
              type="button"
              onClick={() => {
                dispatch(
                  createSoldProductsFinalProductDirectData({
                    period: timeConverter(selectedDate, reportingPeriod),
                    data: combinedData,
                  })
                )
                  .then(() =>
                    dispatch(
                      getSoldProductsData({
                        period: timeConverter(selectedDate, reportingPeriod),
                      })
                    )
                  )
                  .finally(() => navigate(index_url));
              }}
            >
              {t('sold-products.buttons.save')}
            </SaveButton>
          </SaveProductButtonWrapper>
        </Section>
      </SectionGroupContainer>
    </PageContainer>
  );
}
