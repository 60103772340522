import React from 'react';
import { Navigate } from 'react-router-dom';
import { useEsgOrganization } from 'src/common/hooks';

export default function EsgReportHome() {
  const organization = useEsgOrganization();
  // if user can't write data (eg. has the audit role),
  // redirect to indicators
  if (!organization.can_write_data) {
    return <Navigate to="indicators" />;
  }
  // otherwise, redirect to DMA
  return <Navigate to="dma" />;
}
