import React from 'react';
import { StyledTextField } from '../productInformation';
import NumberInput from '../../../../components/esg/Inputs/number';
import { FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

interface Props {
  values: {
    functional_unit: string;
    data_collection_period: string;
    weight: number | null;
    weight_unit: string;
    dimensions: string;
    dimensions_unit: string;
    volume: number | null;
    volume_unit: string;
  };
  setValues: Function;
}

const weight_unit = [{ name: 'g' }, { name: 'kg' }, { name: 't' }];
const dimensions_unit = [{ name: 'mm' }, { name: 'cm' }, { name: 'm' }];
const volume_unit = [
  { label: 'mm3', value: 'mm_3' },
  { label: 'cm3', value: 'cm_3' },
  { label: 'm3', value: 'm_3' },
];

export default function ProductCharacteristics({ setValues, values }: Props) {
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent,
    name: string
  ) => {
    setValues({
      ...values,
      [name]: e.target.value,
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '24px',
        flexDirection: 'column',
      }}
    >
      <StyledTextField
        fullWidth
        label="Functional unit / declared unit"
        name="functional_unit"
        value={values.functional_unit}
        onChange={(e) => handleChange(e, 'functional_unit')}
      />
      <StyledTextField
        fullWidth
        label="Data collection period"
        name="data_collection_period"
        value={values.data_collection_period}
        onChange={(e) => handleChange(e, 'data_collection_period')}
      />
      <div
        style={{
          display: 'flex',
          gap: '24px',
        }}
      >
        <NumberInput
          color="#7E4308"
          state={values}
          label="Product weight"
          name="weight"
          value={values.weight}
          setValue={setValues}
        />
        <FormControl fullWidth>
          <InputLabel>Weight unit</InputLabel>
          <Select
            value={values.weight_unit}
            label="Weight unit"
            onChange={(event) => handleChange(event, 'weight_unit')}
          >
            {weight_unit.map((el) => (
              <MenuItem key={el.name} value={el.name}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div
        style={{
          display: 'flex',
          gap: '24px',
        }}
      >
        <StyledTextField
          fullWidth
          label="Product dimensions"
          name="dimensions"
          value={values.dimensions}
          onChange={(e) => handleChange(e, 'dimensions')}
        />
        <FormControl fullWidth>
          <InputLabel>Product dimension unit</InputLabel>
          <Select
            value={values.dimensions_unit}
            label="Product dimension unit"
            onChange={(event) => handleChange(event, 'dimensions_unit')}
          >
            {dimensions_unit.map((el) => (
              <MenuItem key={el.name} value={el.name}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div
        style={{
          display: 'flex',
          gap: '24px',
        }}
      >
        <NumberInput
          color="#7E4308"
          state={values}
          label="Product volume (optional)"
          name="volume"
          value={values.volume}
          setValue={setValues}
        />
        <FormControl fullWidth>
          <InputLabel>Volume unit (optional)</InputLabel>
          <Select
            value={values.volume_unit}
            label="Volume unit (optional)"
            onChange={(event) => handleChange(event, 'volume_unit')}
          >
            {volume_unit.map((el) => (
              <MenuItem key={el.value} value={el.value}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
