import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import BasePage from '../Base';
import { GoodsAndServicesEmissionsChartDataType } from './types';
import PDFChart from '../../../components/PDFChart';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  chart: { margin: '230px 0px 0px 0px' },
});

type GoodsAndServicesEmissionsChartProps = {
  goods_and_services_emissions_chart: GoodsAndServicesEmissionsChartDataType;
};

const GoodsAndServicesEmissionsChart = ({
  goods_and_services_emissions_chart,
}: GoodsAndServicesEmissionsChartProps) => {
  const {
    chart,
    page_title: pageTitle,
    top_right: topRight,
  } = goods_and_services_emissions_chart;
  return (
    <BasePage
      backgroundImageName="Report_envirly_slide_8.png"
      mainHeader={pageTitle}
      topRight={topRight}
    >
      <View>
        <PDFChart base64={chart} style={styles.chart} />
      </View>
    </BasePage>
  );
};

export default GoodsAndServicesEmissionsChart;
