import React from 'react';
import {
  useImportedGoods,
  useInstallations,
  useProductionProcesses,
} from '../hooks';
import { Box, Button, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { formatNumber } from '../ImportedGoods/Row';
import { client } from 'src/utils/api-client';
import { useMutation } from 'react-query';
import { getFilenameDateSuffix } from 'src/utils/timeConverter';
import { useParams } from 'react-router-dom';
import { quantityUnit } from '../utils';
import InfoPanel from '../components/InfoPanel';

function Row(props: { id: number }) {
  const { reportId } = useParams();
  const { id } = props;
  const importedGoods = useImportedGoods(reportId!);
  const data = importedGoods.data.find((x) => x.id === id);
  const productionProcesses = useProductionProcesses();
  const productionProcess = productionProcesses.data.find(
    (x) => x.id === data?.production_process_id
  );
  const installations = useInstallations();
  const installation = installations.data.find(
    (i) => i.id === productionProcess?.installation_id
  );

  const unit = quantityUnit(productionProcess);

  return (
    <>
      {data?.name}
      <ul>
        <li>
          Amount: {data?.amount} [{unit}]
        </li>
        <li>CN Code: {productionProcess?.cn_code} </li>
        <li>
          Aggregated goods category:{' '}
          {productionProcess?.aggregated_goods_category}{' '}
        </li>
        <li>Country of origin: {installation?.country} </li>
        <li>
          Specific electricity consumption:{' '}
          {formatNumber(data?.specific_electricity_consumption)} [MWh/unit]
        </li>
        <li>
          Specific embedded direct emissions:{' '}
          {formatNumber(data?.specific_attributed_direct_emissions)}{' '}
          [tCO2e/unit]
        </li>
        <li>
          Specific embedded indirect emissions:{' '}
          {formatNumber(data?.specific_attributed_indirect_emissions)}{' '}
          [tCO2e/unit]
        </li>
        <li>Total emissions: {formatNumber(data?.total_emissions)} [tCO2e]</li>
      </ul>
    </>
  );
}

export default function Summary() {
  const { reportId } = useParams();
  const importedGoods = useImportedGoods(reportId!);

  const mutationRequest = (url: string) => {
    return client.get(url, { responseType: 'blob' });
  };

  const downloadReport = (response: any, fileName: string) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response.data);
    link.download = fileName;
    link.click();
  };

  const exportXmlMutation = useMutation({
    mutationFn: () =>
      mutationRequest(`/web/cbam/reports/${reportId}/export-xml`),
    onSuccess: (response) => {
      const currentDate = getFilenameDateSuffix();
      downloadReport(response, `cbam-report-${currentDate}.zip`);
    },
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => exportXmlMutation.mutate()}
            endIcon={<DownloadIcon />}
          >
            Export to XML
          </Button>
        </Box>
      </Box>
      <br />
      <InfoPanel>
        <Typography>
          Exported report can be uploaded and submitted to CBAM Registry. Before
          submitting the report, please make sure that all data is correct and
          complete.
        </Typography>
      </InfoPanel>
      <br />

      {importedGoods.data.map((x) => (
        <Row id={x.id} key={x.id} />
      ))}
    </>
  );
}
