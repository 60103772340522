import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from 'react-router-dom';
import styles from './styles.module.css';
import Box from '../../components/Box';
import 'react-circular-progressbar/dist/styles.css';
import PageLoading from '../../../components/PageLoading';
import {
  ChartContainer,
  StyledTh,
  StyledHeader,
  StyledTd,
  Title,
  TitleHeader,
  Breadcrumbs,
  ButtonWrapper,
  EditButton,
  RemoveButton,
  BoxContainer,
  BackButton,
} from './index.styles';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import GraphComponent from '../../components/Dashboard/GraphComponent';
import {
  PageContainer,
  SectionGroupContainer,
} from '../Questionnaire/index.styles';
import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { useGhgOrganization } from 'src/common/hooks';

export default function TargetPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const organization = useGhgOrganization();

  const [showModal, setShowModal] = useState<boolean>(false);

  const { data, isLoading, isError } = useQuery(['target', id], () =>
    client
      .get(`/web/v2/target/${id}?organization_id=${organization.id}`)
      .then((res) => res.data)
  );

  const { organization_reduction_target: target, plotly_figure: targetChart } =
    data || {};

  const handleRemoveClick = () => {
    client
      .delete(`/web/v2/target/${id}?organization_id=${organization.id}`)
      .then(() => navigate('/ghg/target'));
  };

  if (isError) return <SomethingWentWrong />;

  if (data === undefined) return <PageLoading />;

  return (
    <>
      <PageContainer>
        <SectionGroupContainer>
          <Breadcrumbs>
            <Link to="/ghg/target/">{t('menu.reduction-targets')}</Link>
            <span>{'>'}</span>
            <div>
              {t(
                `targets.domains.${target?.domain?.replace(/\./gi, '-') ?? ''}`
              )}
            </div>
          </Breadcrumbs>
          <TitleHeader>
            <Title>
              {t(
                `targets.domains.${target?.domain?.replace(/\./gi, '-') ?? ''}`
              )}
            </Title>

            <ButtonWrapper>
              <EditButton to={`/ghg/target/edit/${id}`}>
                <span>{t('targets.edit')}</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1861_66997)">
                    <path
                      d="M3 17.2505V21.0005H6.75L17.81 9.94055L14.06 6.19055L3 17.2505ZM20.71 7.04055C21.1 6.65055 21.1 6.02055 20.71 5.63055L18.37 3.29055C17.98 2.90055 17.35 2.90055 16.96 3.29055L15.13 5.12055L18.88 8.87055L20.71 7.04055Z"
                      fill="#388276"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1861_66997">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </EditButton>

              <RemoveButton
                type="button"
                onClick={() => setShowModal(!showModal)}
              >
                <span>{t('targets.remove')}</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1861_64254)">
                    <path
                      d="M7 11V13H17V11H7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                      fill="#388276"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1861_64254">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </RemoveButton>
            </ButtonWrapper>
          </TitleHeader>
          <BoxContainer>
            <Box text={t('targets.basic-info')}>
              <StyledHeader>
                <StyledTh width={35}>{t('targets.domain-header')}</StyledTh>
                <StyledTh width={22}>{t('targets.period-header')}</StyledTh>
                <StyledTh width={22}>{t('targets.method-header')}</StyledTh>
                <StyledTh width={21}>
                  {t('targets.target-value-header')}
                </StyledTh>
              </StyledHeader>
              <StyledHeader>
                {/* TODO: fixnac kolory w tabelce, na wodoku ogolnym kolory sa brane pokolei w zaleznosci ile jest elementow, tu podaje id targetu ktory nie odpowiada temu z kolejnosci*/}
                <StyledTd width={35} colorIdx={id ? +id : 1}>
                  {t(
                    `targets.domains.${
                      target?.domain?.replace(/\./gi, '-') ?? ''
                    }`
                  )}
                </StyledTd>
                <StyledTd width={22} colorIdx={id ? +id : 1}>
                  {target?.start_period} - {target?.end_period}
                </StyledTd>
                <StyledTd width={22} colorIdx={id ? +id : 1}>
                  {t(`targets.method.${target?.method ?? ''}`)}
                </StyledTd>
                <StyledTd width={21} colorIdx={id ? +id : 1}>
                  {target?.target_value}
                </StyledTd>
              </StyledHeader>
            </Box>
          </BoxContainer>
          <BoxContainer>
            <Box text={t('targets.your-target')}>
              <ChartContainer>
                <GraphComponent
                  plotData={targetChart.value}
                  error={isError}
                  errorMsg={targetChart.error_msg}
                  errorType={targetChart.error_type}
                  loading={isLoading}
                />
              </ChartContainer>
            </Box>
          </BoxContainer>
          <BoxContainer>
            <Box text="targets.comment-header">
              <p>{target?.comment}</p>
            </Box>
          </BoxContainer>
          <ButtonWrapper>
            <BackButton to="/ghg/target/">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.00016 0L7.41016 1.41L2.83016 6L7.41016 10.59L6.00016 12L0.000156403 6L6.00016 0Z"
                  fill="#388276"
                />
              </svg>
              <span>{t('targets.back')}</span>
            </BackButton>
          </ButtonWrapper>
        </SectionGroupContainer>
      </PageContainer>

      {showModal && (
        <div className={styles.deleteModal}>
          <div className={styles.deleteModalOverlay} />
          <div className={styles.deleteModalBox}>
            <button
              type="button"
              className={styles.deleteModalClose}
              onClick={() => setShowModal(false)}
            >
              x
            </button>
            <h2 className={styles.deleteModalText}>
              {t('targets.modal-title')}
            </h2>
            <div className={styles.deleteModalButtonsBox}>
              <button
                className={styles.deleteModalButtonPrimary}
                type="button"
                onClick={handleRemoveClick}
              >
                {t('targets.yes')}
              </button>
              <button
                className={styles.deleteModalButtonSecondary}
                type="button"
                onClick={() => setShowModal(false)}
              >
                {t('targets.no')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
