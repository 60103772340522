import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Image, Title, Text } from './index.styles';
import loadingIcon from '../../images/loading.png';

export default function PageLoading() {
  const { t } = useTranslation();
  return (
    <Container>
      <Image src={loadingIcon} />
      <Title>{t('error.loading-title')}</Title>
      <Text>{t('error.loading-text')}</Text>
    </Container>
  );
}
