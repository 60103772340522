import { Font } from '@react-pdf/renderer';
import FontPoppinsBold from '../views/Report/Pdf/fonts/Poppins-Bold.ttf';
import FontPoppinsBoldItalic from '../views/Report/Pdf/fonts/Poppins-BoldItalic.ttf';
import FontPoppinsRegular from '../views/Report/Pdf/fonts/Poppins-Regular.ttf';
import FontLatoBlack from '../views/Report/Pdf/fonts/Lato-Black.ttf';
import FontLatoBold from '../views/Report/Pdf/fonts/Lato-Bold.ttf';

const registerFonts = () => {
  Font.register({
    family: 'Poppins',
    fonts: [
      { src: FontPoppinsRegular, fontStyle: 'normal' },
      { src: FontPoppinsBold, fontStyle: 'bold' },
      { src: FontPoppinsBoldItalic, fontStyle: 'italic' },
    ],
  });
  Font.register({
    family: 'Lato',
    fonts: [
      { src: FontLatoBlack, fontStyle: 'normal' },
      { src: FontLatoBold, fontStyle: 'bold' },
    ],
  });
};

export default registerFonts;
