import React from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { client } from 'src/utils/api-client';
import { StyledButton, StyledButtonBox } from '../index.styles';

export default function NotAClient() {
  const { t } = useTranslation();

  const mutation = useMutation({
    mutationFn: () => client.post('/web/events/redirect_to_bnp'),
    onSettled: () => {
      window.location.href =
        'https://www.bnpparibas.pl/male-firmy/pakiety-i-rachunki/konto-firmowe';
    },
  });

  return (
    <StyledButtonBox sx={{ justifyContent: 'center' }}>
      <StyledButton
        variant="contained"
        size="large"
        onClick={() => mutation.mutate()}
      >
        {t('registration_bnp.becomeClient')}
      </StyledButton>
    </StyledButtonBox>
  );
}
