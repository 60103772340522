import { useEffect } from 'react';
import { ProductPlatform } from '../../utils/api.interfaces';
import { usePlatformNavigation } from '../../utils/navigation';

type NavigateToProductProps = {
  product: ProductPlatform;
};
export const NavigateToProduct = ({ product }: NavigateToProductProps) => {
  const { navigateToProduct } = usePlatformNavigation();

  useEffect(() => {
    navigateToProduct(product);
  }, []);

  return null;
};

type NavigateToOrganizationProps = {
  organizationId: number | null;
};

export const NavigateToOrganization = ({
  organizationId,
}: NavigateToOrganizationProps) => {
  const { navigateToOrganization } = usePlatformNavigation();

  useEffect(() => {
    navigateToOrganization(organizationId);
  }, []);

  return null;
};
