import React from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldNumber from '../../../../../components/TextFieldNumber';
import { Box, Button, Form, FormBox } from '../index.styles';
import { Overlay } from '../../Title/index.styles';

export default function OtherForm({
  state,
  setState,

  active,
  setActive,
}: {
  state: string;
  setState: Function;
  active: boolean;
  setActive: Function;
}) {
  const { t } = useTranslation();

  const setAmountPerPeriod = (value: string) => {
    setState(value);
  };

  return (
    <>
      {active && <Overlay onClick={() => setActive(false)} />}
      <Form active={active}>
        <div onClick={() => setActive(true)}>
          <FormBox variant="100">
            <TextFieldNumber
              type="factor"
              label={t('sold-products.final-products.direct.other.name')}
              active
              error={false}
              index={1}
              value={state}
              setValue={(e: string) => setAmountPerPeriod(e)}
            />
          </FormBox>
        </div>
        {active && (
          <Box>
            <Button
              type="button"
              onClick={() => {
                setState('');
                setActive(false);
              }}
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 31C18 32.1 18.9 33 20 33H28C29.1 33 30 32.1 30 31V19H18V31ZM20 21H28V31H20V21ZM27.5 16L26.5 15H21.5L20.5 16H17V18H31V16H27.5Z"
                  fill="#606462"
                />
                <rect x="22" y="23" width="1" height="6" fill="#606462" />
                <rect x="25" y="23" width="1" height="6" fill="#606462" />
              </svg>
            </Button>
          </Box>
        )}
      </Form>
    </>
  );
}
