import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import {
  FormWrapper,
  StyledFormControl,
  StyledFormControlLabel,
  StyledFormLabel,
  StyledRadio,
} from '../index.styles';
import { useTranslation } from 'react-i18next';
import { days } from '../data';

export default function HomeOfficeForm({
  setForms,
  forms,
}: {
  setForms: Function;
  forms: any;
}) {
  const { t } = useTranslation();
  const handleCommutingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let commutingType = forms;
    commutingType = (event.target as HTMLInputElement).value;
    setForms(commutingType);
  };

  return (
    <FormWrapper>
      <StyledFormControl>
        <StyledFormLabel id="commuting-radio">
          {t('employee-survey.homeoffice-label')}
        </StyledFormLabel>
        <RadioGroup
          aria-labelledby="commuting-radio"
          name="commuting-buttons-group"
          value={forms}
          onChange={handleCommutingChange}
        >
          {days.map((el) => (
            <StyledFormControlLabel
              key={el.value}
              value={el.value}
              control={<StyledRadio />}
              label={el.value}
            />
          ))}
        </RadioGroup>
      </StyledFormControl>
    </FormWrapper>
  );
}
