import React from 'react';
import { Box } from '@mui/material';
import { Container } from './index.styles';
import Logo from './Logo';
import Modules from './Modules';
import Profile from './Profile';
import ChangeLanguage from './ChangeLanguage';
import Contact from './Contact';
import { useSidebar } from '../../views/components/Sidebar/hooks';
import { useAppSelector } from '../../redux-file/hooks';

export type TopBarProps = {
  children?: React.ReactNode;
};

export default function TopBar(props: TopBarProps) {
  const isLoggedIn = useAppSelector((state) => state.platform.user !== null);
  const sidebarState = useSidebar();
  const showLogo = !sidebarState.isShown;

  return (
    <Container>
      <Box sx={{ px: 3 }}>{showLogo && <Logo />}</Box>
      <Box>
        {isLoggedIn && <Modules />}
        {props.children}
        <Contact />
        <ChangeLanguage localOnly={!isLoggedIn} />
        {isLoggedIn && <Profile />}
      </Box>
    </Container>
  );
}
