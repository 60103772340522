import React from 'react';
import { Text, View, Link } from '@react-pdf/renderer';
import { PDF_WIDTH, MARGIN_LEFT } from '../config';
import { useTranslation } from 'react-i18next';
import { RowType } from '../../../../components/ESGSummary/types';
import { Li } from '../components';
import { linkStyles } from '../index.styles';

interface Props {
  questions: RowType[];
}

type AttachmentRow = {
  id: string;
  name: string;
  url: string;
};

const QuestionnaireAttachmentsTable = ({ questions }: Props) => {
  const { t } = useTranslation();
  // collect all files, we don't care about which questions they belong to
  const allFiles = questions.flatMap((question) =>
    question.fields.flatMap(
      (field) => Object.values<AttachmentRow>(field.answer)[0]
    )
  );

  return (
    <View
      style={{
        width: PDF_WIDTH - 2 * MARGIN_LEFT,
        fontSize: 40,
        border: '5px solid #ccc',
        borderRadius: '20px',
        backgroundColor: '#fff',
        marginBottom: '50px',
        fontFamily: 'Poppins',
        color: '#5A636F',
      }}
    >
      {/* columns */}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '35px',
          color: 'grey',
          fontStyle: 'bold',
          textTransform: 'uppercase',
          borderBottom: '2px solid grey',
        }}
      >
        <View style={{ width: '100%' }}>
          <Text>{t('esgSummary.tables.attachments')}</Text>
        </View>
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '35px',
        }}
      >
        {allFiles
          ?.map((file) => (
            <Link src={file.url} key={file.id} style={linkStyles.link}>
              {file.name}
            </Link>
          ))
          .map((item) => <Li value={item} />)}
      </View>
    </View>
  );
};

export default QuestionnaireAttachmentsTable;
