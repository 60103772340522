import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';
import { CbamImportedGoodInSchema } from '../types';
import { TFunction } from 'react-i18next';

export type FormErrors = SchemaErrors<CbamImportedGoodInSchema>;

export const requiredFields: (keyof CbamImportedGoodInSchema)[] = [
  'name',
  'amount',
  'import_area',
  'requested_procedure',
  'importer_id',
  'production_process_id',
  'report_id',
];

export const validateForm = (
  data: CbamImportedGoodInSchema,
  t: TFunction
): FormErrors => {
  const builder = new ErrorBuilder(data);
  return builder
    .checkTruthy(requiredFields, t('validation.required'))
    .checkRule(
      ['supporting_documents'],
      (documents) => documents.every((item) => item !== null),
      t('validation.requiredList')
    )
    .checkRule(
      ['supporting_documents'],
      (documents) => new Set(documents).size === documents.length,
      t('validation.duplicateList')
    )
    .checkRule(
      ['representative_id'],
      (representativeId) =>
        data.importer_id === null || representativeId !== data.importer_id,
      t('validation.representativeError')
    )
    .build();
};
