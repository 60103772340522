import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import { AutocompleteCategories } from '../fields';
import { waste_types } from './data';
import { Overlay } from '../Title/index.styles';
import { Form, FormBox } from './index.styles';
import {
  getWasteAndOtherActivitiesData,
  getWasteAndOtherActivitiesDataEmissionShares,
  putWasteAndOtherActivitiesData,
} from '../../../../redux-file/questionnaires/thunks';
import { timeConverter } from '../../../../utils/timeConverter';
import { useAppDispatch, useAppSelector } from '../../../../redux-file/hooks';
import FormButtons from '../FormButtons';
import OneValueCheckboxField from '../../../../components/CheckboxField';
import { useGhgOrganization } from '../../../../common/hooks';

export default function WasteForm({
  state,
  setState,
  id,
  active,
  setActive,
  combinedData,
  setNewForm,
}: {
  setNewForm: Function;
  state: any;
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  combinedData: any;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector(
    (redux_state) => redux_state.questionnaire
  );
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const [waste_type_error_state, set_waste_type_error_state] = useState(false);
  const [amount_kg_error_state, set_amount_kg_error_state] = useState(false);
  const [manual_factor_error_state, set_manual_factor_error_state] =
    useState(false);

  let waste_type_error = false;
  let amount_kg_error = false;
  let manual_factor_error = false;

  const waste_type_validation = (value: string) => {
    if (value === '') waste_type_error = true;
    return waste_type_error;
  };
  const amount_kg_validation = (value: string) => {
    if (value === '') amount_kg_error = true;
    return amount_kg_error;
  };
  const manual_factor_validation = (value: string) => {
    if (value === '') manual_factor_error = true;
    return manual_factor_error;
  };

  const handleWasteTypes = (value: string) => {
    waste_type_validation(value);
    set_waste_type_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      comment: tempForm.comment,
      waste_type: value,
      amount_kg: tempForm.amount_kg,
      manual_factor: tempForm.manual_factor,
      recycled_or_composed_flag: tempForm.recycled_or_composed_flag,
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_waste_type_error_state(true);
  };

  const handleManualFactor = (value: string) => {
    manual_factor_validation(value);
    set_manual_factor_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      comment: tempForm.comment,
      waste_type: tempForm.waste_type,
      amount_kg: tempForm.amount_kg,
      recycled_or_composed_flag: tempForm.recycled_or_composed_flag,
      manual_factor: value === '' ? null : value,
    };
    newForms[id] = tempForm;
    setState(newForms);

    if (newForms[id].waste_type === 'other.manual_factor' && value === '') {
      set_manual_factor_error_state(true);
    }
  };

  const handleRecycle = () => {
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      comment: tempForm.comment,
      waste_type: tempForm.waste_type,
      amount_kg: tempForm.amount_kg,
      recycled_or_composed_flag: !tempForm.recycled_or_composed_flag,
      manual_factor: tempForm.manual_factor,
    };
    newForms[id] = tempForm;
    setState(newForms);
  };

  const handleAmount = (value: string) => {
    amount_kg_validation(value);
    set_amount_kg_error_state(false);
    const newForms = [...state];
    let tempForm = newForms[id];

    tempForm = {
      comment: tempForm.comment,
      waste_type: tempForm.waste_type,
      amount_kg: value,
      recycled_or_composed_flag: tempForm.recycled_or_composed_flag,
      manual_factor: tempForm.manual_factor,
    };
    newForms[id] = tempForm;
    setState(newForms);
    if (value === '') set_amount_kg_error_state(true);
  };

  const handleSend = (data: any) => {
    if (!waste_type_error && !amount_kg_error && !manual_factor_error) {
      dispatch(
        putWasteAndOtherActivitiesData({
          period: timeConverter(selectedDate, reportingPeriod),
          data,
        })
      ).then(() => {
        dispatch(
          getWasteAndOtherActivitiesData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
        dispatch(
          getWasteAndOtherActivitiesDataEmissionShares({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
      });
      setActive(undefined);
    }
  };

  const defineErrors = async () => {
    if (state[id].waste_type === '') {
      set_waste_type_error_state(true);
      waste_type_error = true;
    }

    if (state[id].amount_kg === '') {
      set_amount_kg_error_state(true);
      amount_kg_error = true;
    }

    if (
      state[id].waste_type === 'other.manual_factor' &&
      !state[id].manual_factor
    ) {
      set_manual_factor_error_state(true);
      manual_factor_error = true;
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    const tempcombinedData = {
      other_fuel_related_activities_data:
        combinedData.other_fuel_related_activities_data,
      waste_data: tempState,
      other_energy_related_activities:
        combinedData.other_energy_related_activities,
      other_emissions: combinedData.other_emissions,
    };

    handleSend(tempcombinedData);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              handleSend(combinedData);
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <FormBox variant="100">
            <AutocompleteCategories
              active
              index={1}
              max={1}
              // @ts-ignore
              options={waste_types}
              label={t('waste-and-other.waste.waste_type')}
              error={waste_type_error_state}
              value={state[id].waste_type}
              setValue={(e: string) => handleWasteTypes(e)}
            />
          </FormBox>

          <FormBox variant="100">
            <TextFieldNumber
              type="mass"
              label={t('waste-and-other.waste.amount_kg')}
              active
              error={amount_kg_error_state}
              index={1}
              value={state[id].amount_kg}
              setValue={(e: string) => handleAmount(e)}
            />
          </FormBox>

          {state[id].waste_type === 'other.manual_factor' && (
            <FormBox variant="100">
              <TextFieldNumber
                type="factor"
                label={t('waste-and-other.waste.manual_factor')}
                active
                error={manual_factor_error_state}
                index={3}
                // @ts-ignore
                value={
                  state[id].manual_factor === null
                    ? ''
                    : state[id].manual_factor
                }
                setValue={(e: string) => handleManualFactor(e)}
              />
            </FormBox>
          )}

          <OneValueCheckboxField
            setValue={() => handleRecycle()}
            label={t('waste-and-other.waste.recycled_or_composed_flag')}
            value={state[id].recycled_or_composed_flag}
          />
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={
              state[id].waste_type !== '' &&
              state[id].amount_kg !== '' &&
              (state[id].waste_type !== 'other.manual_factor' ||
                !!state[id].manual_factor)
            }
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
