import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import { AutocompleteFreesolo } from '../fields';
import { useAppDispatch, useAppSelector } from '../../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import {
  getDirectEmissionsData,
  getDirectEmissionsDataEmissionShares,
  putDirectEmissionsData,
} from '../../../../redux-file/questionnaires/thunks';
import { timeConverter } from '../../../../utils/timeConverter';
import { Overlay } from '../Title/index.styles';
import { Form, FormBox } from './index.styles';
import FormButtons from '../FormButtons';

export default function ProcessEmissionsForm({
  state,
  setState,
  id,
  active,
  setActive,
  combinedData,
  setNewForm,
}: {
  state: any[];
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  combinedData: any;
  setNewForm: Function;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector(
    (redux_state) => redux_state.questionnaire
  );
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const [
    kg_eq_co2_per_period_error_state,
    set_kg_eq_co2_per_period_error_state,
  ] = useState(false);
  let kg_eq_co2_per_period_error = false;
  const kg_eq_co2_per_period_validation = (value: string) => {
    if (value === '') kg_eq_co2_per_period_error = true;
    return kg_eq_co2_per_period_error;
  };

  const setAmountPerPeriod = (value: string) => {
    kg_eq_co2_per_period_validation(value);
    set_kg_eq_co2_per_period_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];
    commutingType = {
      name_of_process: commutingType.name_of_process,
      kg_eq_co2_per_period: value,
      comment: commutingType.comment,
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_kg_eq_co2_per_period_error_state(true);
  };

  const setTypeOfFuel = (value: string) => {
    const newForms = [...state];
    let commutingType = newForms[id];
    commutingType = {
      name_of_process: value,
      kg_eq_co2_per_period: commutingType.kg_eq_co2_per_period,
      comment: commutingType.comment,
    };
    newForms[id] = commutingType;
    setState(newForms);
  };

  const handleSend = (data: any) => {
    if (!kg_eq_co2_per_period_error) {
      dispatch(
        putDirectEmissionsData({
          orgId: organization.id,
          period: timeConverter(selectedDate, reportingPeriod),
          data,
        })
      ).then(() => {
        dispatch(
          getDirectEmissionsData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
        dispatch(
          getDirectEmissionsDataEmissionShares({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
      });
      setActive(undefined);
    }
  };

  const defineErrors = async () => {
    if (state[id].kg_eq_co2_per_period === '') {
      set_kg_eq_co2_per_period_error_state(true);
      kg_eq_co2_per_period_error = true;
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    const tempcombinedData = {
      fugutive_emissions: combinedData.fugutive_emissions,
      mobile_combustion_consumptionwise:
        combinedData.mobile_combustion_consumptionwise,
      mobile_combustion_distancewise:
        combinedData.mobile_combustion_distancewise,
      process_emissions: tempState,
      stationary_combustion: combinedData.stationary_combustion,
    };

    handleSend(tempcombinedData);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              handleSend(combinedData);
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <FormBox variant="50">
            <AutocompleteFreesolo
              active
              index={1}
              max={1}
              // @ts-ignore
              options={[]}
              label={t('direct-emission.process_emissions.name_of_process')}
              error={false}
              value={
                state[id].name_of_process === null
                  ? ''
                  : state[id].name_of_process
              }
              setValue={(e: string) => setTypeOfFuel(e)}
            />
          </FormBox>
          <FormBox variant="50">
            <TextFieldNumber
              label={t(
                'direct-emission.process_emissions.kg_eq_co2_per_period'
              )}
              active
              error={kg_eq_co2_per_period_error_state}
              index={1}
              value={
                state[id].kg_eq_co2_per_period === null
                  ? ''
                  : state[id].kg_eq_co2_per_period
              }
              setValue={(e: string) => setAmountPerPeriod(e)}
            />
          </FormBox>
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={state[id].kg_eq_co2_per_period !== ''}
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
