import React, { useContext } from 'react';

import { StyledContainer, Descriptions } from './index.styles';
import QuestionnaireTitle from './QuestionnaireTitle';
import Question from '../Question';
import { Question as QuestionType } from '../Question/index.types';
import { Questionnaire as QuestionnaireType } from './index.types';
import { Button, ThemeProvider } from '@mui/material';
import { getThemeColors, preparefieldsValues } from '../helpers';
import { getFieldTheme } from '../Field/fieldTheme';
import IconAndSectionTitle from '../Question/IconAndSectionTitle';
import { QuestionnaireContext } from '../Data/QuestionnaireContext';
import { client } from 'src/utils/api-client';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { StyledFlex } from 'src/components/StyledFlex';
import { QuestionAnswer, QuestionAnswerId } from '../types';
import { AxiosResponse } from 'axios';
import { extractErrors } from 'src/utils/validation';
import { useNavigate } from 'react-router-dom';

type Props = {
  payload: QuestionnaireType;
  withBulkSaveButtons?: boolean;
};

type BulkRequest = {
  request: Promise<{ response?: AxiosResponse<any, any>; error?: any }>;
  questionKey: string;
  answerId: QuestionAnswerId;
};

const registerBulkRequest = (
  token: string,
  questionnaireAnswerId: string,
  questions: { [key: string]: QuestionType },
  answer: QuestionAnswer & { question_key: string }
) => {
  const args = { headers: { 'X-Questionnaires-Token': token } };
  const url = '/api-questionnaires/questionnaire-answer-records';
  const isNew = String(answer.id).includes('temp');
  const questionKey = answer.question_key;
  const fields = questions[answer.question_key].field_set;
  const record = preparefieldsValues(answer, fields);

  const request = !isNew
    ? client.put(`${url}/${answer.id}`, record, args)
    : client.post(url, record, {
        ...args,
        params: {
          questionnaire_answer_id: questionnaireAnswerId,
          question_key: questionKey,
        },
      });
  return {
    request: request
      .then((response) => ({ response }))
      .catch((error) => ({ error })),
    questionKey: answer.question_key,
    answerId: answer.id,
  };
};

export default function Questionnaire(props: Props) {
  const {
    id: questionnaireId,
    title,
    description,
    questions,
    subtitle,
  } = props.payload;

  const navigate = useNavigate();
  const themeColors = getThemeColors();
  const filteredDescription = description?.replace(/<p>&nbsp;<\/p>/g, '');

  const {
    invalidateQuestionnaireQuery,
    setModifiedQuestionAnswers,
    questionnaireAnswerState,
    modifiedQuestionAnswers,
    tokenAuth,
    setErrors,
  } = useContext(QuestionnaireContext);

  const bulkSave = () => {
    tokenAuth().then((token) => {
      const qaId = questionnaireAnswerState?.id;
      const requests: BulkRequest[] = [];
      for (const answers of Object.values(modifiedQuestionAnswers))
        for (const answer of Object.values(answers))
          requests.push(
            registerBulkRequest(token, String(qaId), questions, answer)
          );

      Promise.all(requests.map((r) => r.request))
        .then((results) => {
          const atLeastOneSuccess = results.some((result) => result.response);
          if (atLeastOneSuccess)
            toast.success(t('questionnaireV3.questionAnswer.changesSaved'));

          let _errors = {};
          results.forEach((result) => {
            if (result.error)
              _errors = { ..._errors, ...extractErrors(result.error) };
          });
          if (_errors) setErrors(_errors);
        })
        .finally(() => {
          setModifiedQuestionAnswers({});
          invalidateQuestionnaireQuery({});
        });
    });
  };

  return (
    <>
      <ThemeProvider theme={getFieldTheme(themeColors)}>
        <QuestionnaireTitle title={title} subtitle={subtitle} />
        <StyledContainer>
          {!!description && (
            <>
              <IconAndSectionTitle sectionTypeKey="intro" />
              <Descriptions
                dangerouslySetInnerHTML={{
                  __html: filteredDescription || description,
                }}
              />
            </>
          )}
          {Object.values(questions)?.map((question) => (
            <Question
              key={`questionnaire-${questionnaireId}-question-${question.id}`}
              payload={question}
            />
          ))}
        </StyledContainer>
      </ThemeProvider>

      {props.withBulkSaveButtons && (
        <StyledFlex sx={{ m: '20px 50px' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(-1)}
          >
            {t('esg.back')}
          </Button>
          <Button variant="contained" onClick={bulkSave}>
            {t('esg.draft')}
          </Button>
        </StyledFlex>
      )}
    </>
  );
}
