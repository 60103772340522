import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useLanguage } from 'src/common/hooks';

interface Props {
  children: React.ReactNode;
}
export default function MuixProvider(props: Props) {
  const { locale } = useLanguage();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {props.children}
    </LocalizationProvider>
  );
}
