import React from 'react';
import { PreviewBox } from '../../../Questionnaire/index.styles';
import image1 from './Chart_No_data_reported_1.png';
import image2 from './Chart_No_data_reported_2.png';
import image4 from './Chart_No_data_reported_4.png';
import image3 from './Chart_No_data_reported_3.png';
import styled from 'styled-components';

const Title = styled.p`
  color: var(--main-dark-main, #38414f);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.32px;
`;

export default function StakeholdersCharts() {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 24 }}>
      <PreviewBox style={{ padding: 24, width: 'calc(50% - 12px)' }}>
        <Title>Percentage of stakeholders who responded</Title>
        <img
          style={{ margin: '16px auto 0', display: 'block' }}
          src={image1}
          alt=""
        />
      </PreviewBox>
      <PreviewBox style={{ padding: 24, width: 'calc(50% - 12px)' }}>
        <Title>
          Percentage of stakeholders who responded by type of stakeholder
        </Title>
        <img
          style={{ margin: '16px auto 0', display: 'block' }}
          src={image2}
          alt=""
        />
      </PreviewBox>
      <PreviewBox style={{ padding: 24, width: '100%' }}>
        <Title>Number of stakeholders who responded by date and type</Title>
        <img
          style={{ margin: '16px auto 0', display: 'block' }}
          src={image3}
          alt=""
        />
      </PreviewBox>
      <PreviewBox style={{ padding: 24, width: '100%' }}>
        <Title>Number of responses by date and category</Title>
        <img
          style={{ margin: '16px auto 0', display: 'block' }}
          src={image4}
          alt=""
        />
      </PreviewBox>
    </div>
  );
}
