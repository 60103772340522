import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AddProductButton from '../../../components/AddProductButton';
import SoldProductsWasteForm from '../../components/Questionnaire/forms/fuels-and-raw-materials/waste-form';
import RequiredForm from '../../components/Questionnaire/forms/sold_products/direct/required_form';
import QuestionnaireTitle from '../../components/Questionnaire/Title';
import QuestionnaireSectionTitle from '../../components/QuestionnaireSectionTitle';
import { Breadcrumbs } from '../Targets/index.styles';
import {
  comment_cloud,
  final_product_direct_energy_cloud,
  final_product_direct_fuel_cloud,
  final_product_direct_leakage_cloud,
  final_product_direct_other_cloud,
  final_product_direct_product_life_cycle_cloud,
  final_product_direct_sales_region_cloud,
  fuel_and_raw_materials_waste_cloud,
} from './clouds_data';
import { waste_init } from './fuels-and-raw-materials';
import { index_url } from './index-sold-products';
import {
  AnotherButtonWrapper,
  CommentTextArea,
  DeleteButton,
  Description,
  ExampleBox,
  FinalProductTable,
  FinalProductTableHead,
  FinalProductTableRow,
  PageContainer,
  PreviewBox,
  SaveButton,
  SaveProductButtonWrapper,
  Section,
  SectionGroupContainer,
} from './index.styles';
import { timeConverter } from '../../../utils/timeConverter';
import {
  getSoldProductsData,
  getSoldProductsFinalProductIndirect,
  editSoldProductsFinalProductIndirectData,
  deleteSoldProductsFinalProductIndirect,
} from '../../../redux-file/questionnaires/thunks';
import { useAppDispatch, useAppSelector } from 'src/redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import SalesRegionForm from '../../components/Questionnaire/forms/sold_products/region';
import FugutiveFormSoldProducts from '../../components/Questionnaire/forms/sold_products/fugutiveEmissionsForm';
import FuelForm from '../../components/Questionnaire/forms/sold_products/fuelForm';
import EnergyForm from '../../components/Questionnaire/forms/sold_products/energyForm';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import OtherForm from '../../components/Questionnaire/forms/sold_products/otherForm';
import { sp_required_init } from './final-product-direct';
import styles from './styles.module.css';
import UploadExcelButton from '../../../components/UploadExcelButton';

export default function FinalProductIndirectEdit() {
  const { selectedDate, soldProductIndirect, soldProductsIndirectError } =
    useAppSelector((state) => state.questionnaire);

  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (id && !!reportingPeriod && !!selectedDate) {
      dispatch(
        getSoldProductsFinalProductIndirect({
          period: timeConverter(selectedDate, reportingPeriod),
          participant_id: id,
        })
      );
    }
  }, [id, selectedDate, reportingPeriod]);

  const fugutiveInit = {
    amount_kg_per_period: '',
    comment: '',
    emission_type: '',
    manual_factor: null,
  };

  const fuelInit = {
    amount_per_period: '',
    comment: '',
    manual_factor: null,
    name_of_equipement: '',
    type_of_fuel: '',
    unit: '',
  };

  const energyInit = {
    emission_factor_is_known: false,
    energy_usage_data_factor_unknown: {
      spending_pln_or_kwh_per_period: '',
      unit: '',
    },
    energy_usage_data_factor_known: {
      factor: '',
      amount_per_period: '',
      unit_label: '',
    },
    certified_sources_are_utilized: false,
    certified_energy_usage_data: {
      certified_energy_type_label: '',
      certified_percentge: '',
      emission_factor: '',
    },
  };

  const regions_init = ['', '', '', '', '', '', '', ''];

  const [required_state, set_required_state] = useState(sp_required_init);
  const [waste_state, set_waste_state] = useState([waste_init]);
  const [fugutive_state, set_fugutive_state] = useState([fugutiveInit]);
  const [fuel_state, set_fuel_state] = useState([fuelInit]);
  const [energy_state, set_energy_state] = useState([energyInit]);
  const [regions_state, set_regions_state] = useState(regions_init);
  const [other_state, set_other_state] = useState('');

  const [activeRequire, setActiveRequire] = useState(false);
  const [activeWaste, setActiveWaste] = useState<undefined | number>(undefined);
  const [activeFuel, setActiveFuel] = useState<undefined | number>(undefined);
  const [activeEnergy, setActiveEnergy] = useState<undefined | number>(
    undefined
  );
  const [activeFugutive, setActiveFugutive] = useState<undefined | number>(
    undefined
  );
  const [activeRegions, setActiveRegions] = useState(false);
  const [activeOther, setActiveOther] = useState(false);

  useEffect(() => {
    if (!!soldProductIndirect) {
      set_required_state({
        assumptions: soldProductIndirect.assumptions,
        name: soldProductIndirect.name,
        number_of_uses_or_yesrs_of_use:
          soldProductIndirect.number_of_uses_or_yesrs_of_use,
        unit: soldProductIndirect.unit,
        number_of_products_sold: soldProductIndirect.number_of_products_sold,
      });
      set_waste_state(
        soldProductIndirect.waste_data === undefined ||
          soldProductIndirect.waste_data.length === 0
          ? [waste_init]
          : soldProductIndirect.waste_data
      );
      set_fugutive_state(
        soldProductIndirect.fugutive_emissions_data === undefined ||
          soldProductIndirect.fugutive_emissions_data.length === 0
          ? [fugutiveInit]
          : soldProductIndirect.fugutive_emissions_data
      );
      set_fuel_state(
        soldProductIndirect.fuel_data === undefined ||
          soldProductIndirect.fuel_data.length === 0
          ? [fuelInit]
          : soldProductIndirect.fuel_data
      );
      set_energy_state(
        soldProductIndirect.energy_data === undefined ||
          soldProductIndirect.energy_data.length === 0
          ? [energyInit]
          : soldProductIndirect.energy_data
      );
      set_other_state(
        soldProductIndirect.other_emissions_kg_eq_co2 === null
          ? ''
          : soldProductIndirect.other_emissions_kg_eq_co2
      );
      let tempState = [...regions_state];
      soldProductIndirect.sales_region.map((el: string) => {
        if (el === 'europe') {
          tempState[0] = 'europe';
        }
        if (el === 'asia') {
          tempState[1] = 'asia';
        }
        if (el === 'africa') {
          tempState[2] = 'africa';
        }
        if (el === 'australia_and_oceania') {
          tempState[3] = 'australia_and_oceania';
        }
        if (el === 'north_america') {
          tempState[4] = 'north_america';
        }
        if (el === 'south_america') {
          tempState[5] = 'south_america';
        }
        if (el === 'antarctica') {
          tempState[6] = 'antarctica';
        }
        if (el === 'not_known') {
          tempState[7] = 'not_known';
        }
      });
      set_regions_state(tempState);
      tempState = [...regions_init];
    }
  }, [soldProductIndirect]);

  const combinedData = {
    ...required_state,
    sales_region: regions_state,
    fuel_data:
      fuel_state.length === 1 && fuel_state[0].type_of_fuel === ''
        ? []
        : fuel_state,
    energy_data:
      energy_state.length === 1 &&
      energy_state[0].emission_factor_is_known === false &&
      energy_state[0].energy_usage_data_factor_unknown
        .spending_pln_or_kwh_per_period === ''
        ? []
        : energy_state,
    fugutive_emissions_data:
      fugutive_state.length === 1 && fugutive_state[0].emission_type === ''
        ? []
        : fugutive_state,
    waste_data:
      waste_state.length === 1 && waste_state[0].waste_type === ''
        ? []
        : waste_state,
    other_emissions_kg_eq_co2: other_state === '' ? null : other_state,
  };
  const waste_ref = useRef(null);
  const fuel_ref = useRef(null);
  const energy_ref = useRef(null);
  const fugutive_ref = useRef(null);

  const combineFuelData = (fullData: any, newData: any) => {
    set_fuel_state([...newData, ...fullData.fuel_data]);
  };
  const combineWasteData = (fullData: any, newData: any) => {
    set_waste_state([...newData, ...fullData.waste_data]);
  };
  const combineEnergyData = (fullData: any, newData: any) => {
    set_energy_state([...newData, ...fullData.energy_data]);
  };
  const combineFugutiveData = (fullData: any, newData: any) => {
    set_fugutive_state([...newData, ...fullData.fugutive_emissions_data]);
  };

  if (soldProductsIndirectError) {
    return <SomethingWentWrong />;
  }

  return (
    <>
      <PageContainer>
        <SectionGroupContainer>
          <Breadcrumbs>
            <Link to={index_url}>{t('menu.sold-products')}</Link>
            <span>{'>'}</span>
            <div>{t(`sold-products.final-products.indirect.title`)}</div>
          </Breadcrumbs>
          <QuestionnaireTitle
            info
            text={t('sold-products.final-products.indirect.title')}
          />
          <Description>
            {t('sold-products.final-products.indirect.desc')}
          </Description>
          <Section>
            <ExampleBox>
              <span>{t('example')}</span>
              <p>{t('sold-products.final-products.indirect.example')}</p>
            </ExampleBox>

            <FinalProductTable>
              <FinalProductTableHead columns="1fr 1fr 1fr 1fr 1fr">
                <div>
                  {t('sold-products.final-products.indirect.table.head.col1')}
                </div>
                <div>
                  {t('sold-products.final-products.indirect.table.head.col2')}
                </div>
                <div>
                  {t('sold-products.final-products.indirect.table.head.col3')}
                </div>
                <div>
                  {t('sold-products.final-products.indirect.table.head.col4')}
                </div>
                <div>
                  {t('sold-products.final-products.indirect.table.head.col5')}
                </div>
              </FinalProductTableHead>
              <FinalProductTableRow columns="1fr 1fr 1fr 1fr 1fr">
                <div>
                  {t('sold-products.final-products.indirect.table.row1.col1')}
                </div>
                <div>
                  {t('sold-products.final-products.indirect.table.row1.col2')}
                </div>
                <div>
                  {t('sold-products.final-products.indirect.table.row1.col3')}
                </div>
                <div>
                  {t('sold-products.final-products.indirect.table.row1.col4')}
                </div>
                <div>
                  {t('sold-products.final-products.indirect.table.row1.col5')}
                </div>
              </FinalProductTableRow>
              <FinalProductTableRow columns="1fr 1fr 1fr 1fr 1fr">
                <div>
                  {t('sold-products.final-products.indirect.table.row2.col1')}
                </div>
                <div>
                  {t('sold-products.final-products.indirect.table.row2.col2')}
                </div>
                <div>
                  {t('sold-products.final-products.indirect.table.row2.col3')}
                </div>
                <div>
                  {t('sold-products.final-products.indirect.table.row2.col4')}
                </div>
                <div>
                  {t('sold-products.final-products.indirect.table.row2.col5')}
                </div>
              </FinalProductTableRow>
            </FinalProductTable>
          </Section>
          <Section>
            <QuestionnaireSectionTitle
              active={false}
              level="primary"
              text={t('sold-products.fuels-and-raw-materials.basic')}
            />
          </Section>
          <Section>
            <QuestionnaireSectionTitle
              active={false}
              level="secondary"
              boxContent={final_product_direct_product_life_cycle_cloud}
              text={t('sold-products.final-products.direct.life-cycle.title')}
            />
            <PreviewBox>
              <RequiredForm
                state={required_state}
                setState={set_required_state}
                active={activeRequire}
                setActive={setActiveRequire}
              />
            </PreviewBox>
          </Section>

          <Section>
            <QuestionnaireSectionTitle
              active={false}
              level="secondary"
              boxContent={comment_cloud}
              text={t('sold-products.final-products.direct.description.title')}
            />

            <PreviewBox>
              <CommentTextArea
                placeholder={t(
                  'sold-products.fuels-and-raw-materials.comment.placeholder'
                )}
                value={required_state.assumptions}
                onChange={(e) => {
                  const tempState = {
                    name: required_state.name,
                    number_of_uses_or_yesrs_of_use:
                      required_state.number_of_uses_or_yesrs_of_use,
                    unit: required_state.unit,
                    number_of_products_sold:
                      required_state.number_of_products_sold,
                    assumptions: e.target.value,
                  };

                  set_required_state(tempState);
                }}
              />
            </PreviewBox>
          </Section>

          <Section>
            <QuestionnaireSectionTitle
              active={false}
              level="secondary"
              boxContent={final_product_direct_sales_region_cloud}
              text={t('sold-products.final-products.direct.region.title')}
            />
            <PreviewBox>
              <SalesRegionForm
                state={regions_state}
                setState={set_regions_state}
                active={activeRegions}
                setActive={setActiveRegions}
              />
            </PreviewBox>
          </Section>

          <Section>
            <QuestionnaireSectionTitle
              active={false}
              level="primary"
              text={t('sold-products.final-products.direct.emission.title')}
            />
          </Section>

          <Section>
            <div ref={fuel_ref} />
            <QuestionnaireSectionTitle
              active={false}
              level="secondary"
              boxContent={final_product_direct_fuel_cloud}
              text={t('sold-products.final-products.direct.fuel.title')}
            />
            {fuel_state.map((_, idx) => (
              <PreviewBox key={idx}>
                <FuelForm
                  state={fuel_state}
                  id={idx}
                  setState={set_fuel_state}
                  active={activeFuel}
                  setActive={setActiveFuel}
                  setNewForm={() => {}}
                />
              </PreviewBox>
            ))}
            <AnotherButtonWrapper>
              {fuel_state[fuel_state.length - 1].amount_per_period !== '' &&
                fuel_state[fuel_state.length - 1].unit !== '' &&
                fuel_state[fuel_state.length - 1].type_of_fuel !== '' &&
                (fuel_state[fuel_state.length - 1].type_of_fuel !==
                  'other.manual_factor' ||
                  !!fuel_state[fuel_state.length - 1].manual_factor) && (
                  <>
                    <AddProductButton
                      onClick={() => {
                        set_fuel_state([fuelInit, ...fuel_state]);
                        setActiveFuel(0);
                        if (fuel_ref && fuel_ref.current) {
                          // @ts-ignore
                          fuel_ref.current.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }
                      }}
                    />
                    <span>{t('popup.excel.or')}</span>
                  </>
                )}
              <UploadExcelButton
                name={t('sold-products.final-products.direct.fuel.title')}
                slug="StationaryCombustionData"
                fullData={combinedData}
                combineData={combineFuelData}
                sendData={() => {}}
                refresh={() => {}}
              />
            </AnotherButtonWrapper>
          </Section>
          <Section>
            <div ref={energy_ref} />
            <QuestionnaireSectionTitle
              active={false}
              level="secondary"
              boxContent={final_product_direct_energy_cloud}
              text={t('sold-products.final-products.direct.energy.title')}
            />
            {energy_state.map((_, idx) => (
              <PreviewBox key={idx}>
                <EnergyForm
                  id={idx}
                  setNewForm={() => {}}
                  active={activeEnergy}
                  setActive={setActiveEnergy}
                  state={energy_state}
                  setState={set_energy_state}
                />
              </PreviewBox>
            ))}
            <AnotherButtonWrapper>
              {(!energy_state[energy_state.length - 1]
                .emission_factor_is_known &&
                energy_state[energy_state.length - 1]
                  .energy_usage_data_factor_unknown.unit !== '' &&
                energy_state[energy_state.length - 1]
                  .energy_usage_data_factor_unknown
                  .spending_pln_or_kwh_per_period !== '') ||
                (energy_state[energy_state.length - 1]
                  .emission_factor_is_known &&
                  energy_state[energy_state.length - 1]
                    .energy_usage_data_factor_known.factor !== '' &&
                  energy_state[energy_state.length - 1]
                    .energy_usage_data_factor_known.amount_per_period !== '') ||
                (energy_state[energy_state.length - 1]
                  .certified_sources_are_utilized &&
                  energy_state[energy_state.length - 1]
                    .certified_energy_usage_data.certified_percentge !== '' &&
                  energy_state[energy_state.length - 1]
                    .certified_energy_usage_data.emission_factor !== '' && (
                    <>
                      <AddProductButton
                        onClick={() => {
                          set_energy_state([energyInit, ...energy_state]);
                          setActiveEnergy(0);
                          if (waste_ref && waste_ref.current) {
                            // @ts-ignore
                            waste_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                      <span>{t('popup.excel.or')}</span>
                    </>
                  ))}
              <UploadExcelButton
                name={t('sold-products.final-products.direct.energy.title')}
                slug="EnergyUsageData"
                fullData={combinedData}
                combineData={combineEnergyData}
                sendData={() => {}}
                refresh={() => {}}
              />
            </AnotherButtonWrapper>
          </Section>
          <Section>
            <div ref={fugutive_ref} />
            <QuestionnaireSectionTitle
              active={false}
              level="secondary"
              boxContent={final_product_direct_leakage_cloud}
              text={t('sold-products.final-products.direct.leakage.title')}
            />
            {fugutive_state.map((_, idx) => (
              <PreviewBox key={idx}>
                <FugutiveFormSoldProducts
                  active={activeFugutive}
                  setActive={setActiveFugutive}
                  state={fugutive_state}
                  setState={set_fugutive_state}
                  setNewForm={() => {}}
                  id={idx}
                />
              </PreviewBox>
            ))}
            <AnotherButtonWrapper>
              {fugutive_state[fugutive_state.length - 1]
                .amount_kg_per_period !== '' &&
                fugutive_state[fugutive_state.length - 1].emission_type !==
                  '' &&
                (fugutive_state[fugutive_state.length - 1].emission_type !==
                  'other.manual_factor' ||
                  !!fugutive_state[fugutive_state.length - 1]
                    .manual_factor) && (
                  <>
                    <AddProductButton
                      onClick={() => {
                        set_fugutive_state([fugutiveInit, ...fugutive_state]);
                        setActiveFugutive(0);
                        if (fugutive_ref && fugutive_ref.current) {
                          // @ts-ignore
                          fugutive_ref.current.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }
                      }}
                    />
                    <span>{t('popup.excel.or')}</span>
                  </>
                )}
              <UploadExcelButton
                name={t('sold-products.final-products.direct.leakage.title')}
                slug="FugutiveEmissionData"
                fullData={combinedData}
                combineData={combineFugutiveData}
                sendData={() => {}}
                refresh={() => {}}
              />
            </AnotherButtonWrapper>
          </Section>
          <Section>
            <QuestionnaireSectionTitle
              active={false}
              level="secondary"
              boxContent={final_product_direct_other_cloud}
              text={t('sold-products.final-products.direct.other.title')}
            />
            <PreviewBox>
              <OtherForm
                active={activeOther}
                setActive={setActiveOther}
                state={other_state}
                setState={set_other_state}
              />
            </PreviewBox>
          </Section>

          <Section>
            <QuestionnaireSectionTitle
              active={false}
              level="primary"
              text={t('sold-products.final-products.direct.waste.main')}
            />
          </Section>
          <Section>
            <div ref={waste_ref} />
            <QuestionnaireSectionTitle
              active={false}
              level="secondary"
              boxContent={fuel_and_raw_materials_waste_cloud}
              text={t('sold-products.final-products.direct.waste.title')}
            />
            {waste_state.map((_, idx) => (
              <PreviewBox key={id}>
                <SoldProductsWasteForm
                  setNewForm={() => {}}
                  id={idx}
                  setState={set_waste_state}
                  state={waste_state}
                  active={activeWaste}
                  setActive={setActiveWaste}
                  key={idx}
                />
              </PreviewBox>
            ))}
            <AnotherButtonWrapper>
              {waste_state[waste_state.length - 1].waste_type !== '' &&
                waste_state[waste_state.length - 1].amount_kg !== '' &&
                (waste_state[waste_state.length - 1].waste_type !==
                  'other.manual_factor' ||
                  !!waste_state[waste_state.length - 1].manual_factor) && (
                  <>
                    <AddProductButton
                      onClick={() => {
                        set_waste_state([waste_init, ...waste_state]);
                        setActiveWaste(0);
                        if (waste_ref && waste_ref.current) {
                          // @ts-ignore
                          waste_ref.current.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }
                      }}
                    />
                    <span>{t('popup.excel.or')}</span>
                  </>
                )}
              <UploadExcelButton
                name={t('sold-products.final-products.direct.waste.title')}
                slug="WasteData"
                fullData={combinedData}
                combineData={combineWasteData}
                sendData={() => {}}
                refresh={() => {}}
              />
            </AnotherButtonWrapper>
          </Section>

          <Section>
            <SaveProductButtonWrapper twoButtons>
              <DeleteButton type="button" onClick={() => setShowModal(true)}>
                {t('sold-products.buttons.delete')}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2809_77388)">
                    <path
                      d="M7 11V13H17V11H7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                      fill="#388276"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2809_77388">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </DeleteButton>
              <SaveButton
                disabled={
                  required_state.number_of_uses_or_yesrs_of_use === '' ||
                  regions_state.filter((el) => el !== '').length === 0
                }
                type="button"
                onClick={() => {
                  if (id) {
                    dispatch(
                      editSoldProductsFinalProductIndirectData({
                        period: timeConverter(selectedDate, reportingPeriod),
                        data: combinedData,
                        participant_id: id,
                      })
                    )
                      .then(() =>
                        dispatch(
                          getSoldProductsData({
                            period: timeConverter(
                              selectedDate,
                              reportingPeriod
                            ),
                          })
                        )
                      )
                      .finally(() => navigate(index_url));
                  }
                }}
              >
                {t('sold-products.buttons.save')}
              </SaveButton>
            </SaveProductButtonWrapper>
          </Section>
        </SectionGroupContainer>
      </PageContainer>
      {showModal && (
        <div className={styles.deleteModal}>
          <div className={styles.deleteModalOverlay} />
          <div className={styles.deleteModalBox}>
            <button
              type="button"
              className={styles.deleteModalClose}
              onClick={() => setShowModal(false)}
            >
              x
            </button>
            <h2 className={styles.deleteModalText}>
              {t('sold-products.delete-modal')}
            </h2>
            <div className={styles.deleteModalButtonsBox}>
              <button
                className={styles.deleteModalButtonPrimary}
                type="button"
                onClick={() => {
                  if (id) {
                    dispatch(
                      deleteSoldProductsFinalProductIndirect({
                        period: timeConverter(selectedDate, reportingPeriod),
                        participant_id: id,
                      })
                    )
                      .then(() =>
                        dispatch(
                          getSoldProductsData({
                            period: timeConverter(
                              selectedDate,
                              reportingPeriod
                            ),
                          })
                        )
                      )
                      .finally(() => navigate(index_url));
                  }
                }}
              >
                {t('targets.yes')}
              </button>
              <button
                className={styles.deleteModalButtonSecondary}
                type="button"
                onClick={() => setShowModal(false)}
              >
                {t('targets.no')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
