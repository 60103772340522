import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../Box';
import { RadioGroup } from '@mui/material';
import {
  Container,
  SectionContainer,
  SubsectionContainer,
  SectionHeader,
  Row,
  Column,
} from './index.styles';
import {
  StyledFormControlLabel,
  StyledRadio,
} from '../RadioGroup/index.styles';

interface TypeProps {
  value: string;
  label: string;
}

interface Types2Props {
  setSelectedValue: Dispatch<SetStateAction<string | null>>;
  selectedValue: string | null;
  section1Items: TypeProps[];
  section2Items: TypeProps[];
  section3Items: TypeProps[];
  section4Items?: TypeProps[];
}

export default function Types({
  selectedValue,
  setSelectedValue,
  section1Items,
  section2Items,
  section3Items,
  section4Items,
}: Types2Props) {
  const { t } = useTranslation();

  return (
    <Box text="targets.type">
      <RadioGroup
        value={selectedValue}
        onChange={(_, newValue) => setSelectedValue(newValue)}
      >
        <Container>
          <Row>
            <SectionContainer>
              <SectionHeader>
                {t('targets.domains.emission-range')}
              </SectionHeader>
              <SubsectionContainer>
                {section1Items.map((item) => (
                  <StyledFormControlLabel
                    key={item.value}
                    value={item.value}
                    label={t(`targets.domains.${item.label}`)}
                    control={<StyledRadio />}
                  />
                ))}
              </SubsectionContainer>
            </SectionContainer>
            <SectionContainer>
              <SectionHeader>{t('targets.domains.scope_1')}</SectionHeader>
              <SubsectionContainer>
                {section2Items.map((item) => (
                  <StyledFormControlLabel
                    key={item.value}
                    value={item.value}
                    label={t(`targets.domains.${item.label}`)}
                    control={<StyledRadio />}
                  />
                ))}
              </SubsectionContainer>
            </SectionContainer>
            <SectionContainer>
              <SectionHeader>{t('targets.domains.scope_2')}</SectionHeader>
              <SubsectionContainer>
                {section3Items.map((item) => (
                  <StyledFormControlLabel
                    key={item.value}
                    value={item.value}
                    label={t(`targets.domains.${item.label}`)}
                    control={<StyledRadio />}
                  />
                ))}
              </SubsectionContainer>
            </SectionContainer>
          </Row>
          {section4Items && (
            <SectionContainer>
              <SectionHeader>{t('targets.domains.scope_3')}</SectionHeader>
              <SubsectionContainer>
                <Row>
                  <Column>
                    {section4Items.slice(0, 8).map((item) => (
                      <StyledFormControlLabel
                        key={item.value}
                        value={item.value}
                        label={t(`targets.domains.${item.label}`)}
                        control={<StyledRadio />}
                      />
                    ))}
                  </Column>
                  <Column>
                    {section4Items.slice(8).map((item) => (
                      <StyledFormControlLabel
                        key={item.value}
                        value={item.value}
                        label={t(`targets.domains.${item.label}`)}
                        control={<StyledRadio />}
                      />
                    ))}
                  </Column>
                </Row>
              </SubsectionContainer>
            </SectionContainer>
          )}
        </Container>
      </RadioGroup>
    </Box>
  );
}
