import React from 'react';

import { AboutEnvirlyDataType } from './types';
import CoverPage from './CoverPage';
import OurMissionPage from './OurMissionPage';
import MethodologyPage from './MethodologyPage';
import EmissionDataSourcesPage from './EmissionDataSourcesPage';
import ContactPage from './ContactPage';

const aboutEnvirlySection = (data: AboutEnvirlyDataType) => {
  return [
    <CoverPage data={data} />,
    <OurMissionPage data={data} />,
    <MethodologyPage data={data} />,
    <EmissionDataSourcesPage data={data} />,
    <ContactPage data={data} />,
  ];
};

export default aboutEnvirlySection;
