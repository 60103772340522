import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { styled as styledMUI } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import React from 'react';

export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 0 64px;
  margin-bottom: 80px;
`;

export const HiddenIconButton = styledMUI(IconButton)`
    visibility: hidden;
    margin: 5px;
    font-size: 2rem;
    z-index: 999;
position: relative;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    color: #38414f;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0.64px;
    margin: 0;
  }
`;

export const Subtitle = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.36px;
  color: #38414f;
  margin-top: 16px;
  margin-bottom: 40px;
`;

export const NewReportBox = styled(Link)`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 312px;
  height: 245px;
  text-decoration: none;
  border-radius: 16px;
  border: 1px solid #ccc;

  p {
    position: absolute;
    top: 24px;
    left: 24px;
    color: #38414f;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.36px;
  }
`;

export const Box = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-decoration: none;
  position: relative;
  z-index: 1;
`;

interface BoxItemInnerProps extends React.HTMLProps<HTMLDivElement> {
  buttonVisible: boolean;
}

export const BoxItemInner = styled.div<BoxItemInnerProps>`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 312px;
  height: 245px;
  text-decoration: none;
  border-radius: 16px;
  border: 1px solid #ccc;
  padding: 24px 24px 4px 24px;
  position: relative;

     .MuiIconButton-root {
    visibility: ${(props) =>
      props.buttonVisible ? 'visible !important' : 'hidden'};
  }
      &:hover {
      & .MuiIconButton-root {
    visibility: visible;
  }
`;

export const BoxName = styled.p`
  color: #38414f;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.48px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BoxYear = styled.p`
  color: #888;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.4px;
`;

export const BoxDate = styled.p`
  color: #6a6a6a;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.36px;
  padding-top: 5px;
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 80px;
`;
