import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ButtonGroupField from '../../../components/ButtonGroupField';
import forestImage from '../../../images/forest.png';
import shevronRight from '../../../images/shevron-right-white.png';
import { useAppDispatch } from 'src/redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import { setSelectedDate } from '../../../redux-file/questionnaires/thunks';
import { postCo2OrganizationOnboarding } from '../../../redux-file/session/thunks';
import { getOrganization } from '../../../redux-file/thunk';
import {
  Co2OrganizationOnboardingSchema,
  Industry,
  ReportingFreq,
  SupportedCountry,
} from '../../../utils/api.interfaces';
import { AutocompleteFreesolo } from '../../components/Questionnaire/fields';
import NewFieldAutocomplete from '../../components/Questionnaire/fields/Autocomplete';
import { titleData } from './data';
import {
  Box,
  ButtonsBox,
  Container,
  Disclaimer,
  EntryBox,
  MaintText,
  NextButton,
  Subtext,
  Subtitle,
  Wrapper,
} from './index.styled';

export default function Onboarding() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const organization = useGhgOrganization();

  const [reportingState, setReportingState] = useState<ReportingFreq>(
    ReportingFreq.M
  );
  const [industryCode, setIndustryCode] = useState<Industry | undefined>(
    undefined
  );
  const [countryState, setCountryState] = useState<SupportedCountry>(
    SupportedCountry.PL
  );

  const [error, setError] = useState(false);

  const options = [
    [ReportingFreq.M, t('onboarding.monthly')],
    [ReportingFreq.Q, t('onboarding.quarterly')],
    [ReportingFreq.Y, t('onboarding.yearly')],
  ];

  useEffect(() => {
    dispatch(getOrganization({ orgId: organization.id }));
    dispatch(setSelectedDate(new Date()));
  }, []);

  const industries = Object.values(Industry);

  const handleIndustryCode = (value: string) => {
    if (value === '' || !industries.includes(value[0] as Industry)) {
      setError(true);
    } else {
      setError(false);
      setIndustryCode(value[0] as Industry);
    }
  };

  const data: Co2OrganizationOnboardingSchema = {
    country: countryState,
    reporting_freq: reportingState,
    industry: industryCode!,
  };

  const countriesDropdowns = Object.values(SupportedCountry)
    .map((code) => [code, t(`countries.${code}`)])
    .sort(([_1, a], [_2, b]) => a.localeCompare(b));

  const industriesList = Object.values(Industry).map((x) => [x, x]);

  return (
    <Container>
      <EntryBox>
        <img src={forestImage} alt="envirly forest" />
        <div className="text-title">
          <h1>{t(titleData[0].title)}</h1>
          {!!titleData[0].subtitle && <p>{t(titleData[0].subtitle)}</p>}
        </div>

        <Box>
          <MaintText>{t(titleData[0].text)}</MaintText>

          <Subtext>{t('onboarding.welcome.subtext')}</Subtext>
          <Disclaimer>{t('onboarding.welcome.disclaimer')}</Disclaimer>

          <Subtitle>{t('onboarding.welcome.basic')}</Subtitle>
          <Wrapper>
            <NewFieldAutocomplete
              options={countriesDropdowns}
              index={1}
              label={t('employee-survey.country')}
              value={countryState}
              setValue={setCountryState}
              active
              error={false}
              max={99999}
            />
          </Wrapper>
          <Wrapper>
            <AutocompleteFreesolo
              active
              error={error}
              options={industriesList}
              value={industryCode ?? ''}
              setValue={handleIndustryCode}
              max={99}
              index={1}
              label={t('onboarding.industry')}
            />
          </Wrapper>
          <Subtitle>{t('onboarding.reporting-period')}</Subtitle>
          <ButtonGroupField
            options={options}
            value={reportingState}
            setValue={(period: string) =>
              setReportingState(period as ReportingFreq)
            }
          />

          <ButtonsBox>
            <NextButton
              error={error || !industryCode}
              onClick={() => {
                dispatch(
                  postCo2OrganizationOnboarding({
                    data,
                    orgId: organization.id,
                  })
                )
                  .then(() => {
                    dispatch(
                      getOrganization({
                        orgId: organization.id,
                      })
                    );
                  })
                  .finally(() => navigate('/ghg'));
              }}
            >
              <span>{t(titleData[0].next)}</span>
              <img src={shevronRight} alt="arrow right send button" />
            </NextButton>
          </ButtonsBox>
        </Box>
      </EntryBox>
    </Container>
  );
}
