import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar, Text, FloatedNumber } from './index.styles';

export interface TargetsAchievedProps {
  numberOfTargets: number;
  numberOfAchievedTargets: number;
}

export default function TargetsAchieved({
  numberOfAchievedTargets,
  numberOfTargets,
}: TargetsAchievedProps) {
  const { t } = useTranslation();
  const percent =
    numberOfTargets !== 0
      ? (numberOfAchievedTargets / numberOfTargets) * 100
      : 0;
  return (
    <div>
      <Text>
        <span>{numberOfAchievedTargets}</span>{' '}
        {t('recommendations.achieved.out-of')} <span>{numberOfTargets}</span>{' '}
        {t('recommendations.achieved.targets')}
      </Text>

      <ProgressBar length={percent}>
        <div />
      </ProgressBar>
      <FloatedNumber length={percent}>
        <div>
          <p>{Number.isNaN(percent) ? 0 : percent.toFixed()}%</p>
        </div>
      </FloatedNumber>
    </div>
  );
}
