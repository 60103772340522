import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import BasePage from '../Base';
import { EmissionRankingScope3PieChartDataType } from './types';
import PDFChart from '../../../components/PDFChart';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  chart: { margin: '230px 100px 0px 0px' },
});

type EmissionRankingScope3PieChartProps = {
  emission_ranking_scope_3_pie_chart: EmissionRankingScope3PieChartDataType;
};

const EmissionRankingScope3PieChart = ({
  emission_ranking_scope_3_pie_chart,
}: EmissionRankingScope3PieChartProps) => {
  const {
    chart,
    page_title: pageTitle,
    top_right: topRight,
  } = emission_ranking_scope_3_pie_chart;
  return (
    <BasePage
      backgroundImageName="Report_envirly_slide_8.png"
      mainHeader={pageTitle}
      topRight={topRight}
    >
      <View>
        <PDFChart base64={chart} style={styles.chart} />
      </View>
    </BasePage>
  );
};

export default EmissionRankingScope3PieChart;
