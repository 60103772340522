import React from 'react';
import { useTranslation } from 'react-i18next';
import FilledPlus from '../../images/filled-plus.png';
import { AddProducButtonProps } from './index.interfaces';
import { Icon, Text, Button } from './index.styles';

export default function AddProductButton({
  onClick,
  text,
}: AddProducButtonProps) {
  const { t } = useTranslation();
  return (
    <Button onClick={() => onClick()}>
      <Icon src={FilledPlus} />
      <Text>{t(text || 'forms.add-another')}</Text>
    </Button>
  );
}
