import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Text } from './index.styles';
import uploadExcel from '../../images/upload_excel.png';
import UploadExcelPopup, { ErrorProps } from '../UploadExcelPopup';

export default function UploadExcelButton({
  slug,
  fullData,
  combineData,
  sendData,
  refresh,
  name,
}: {
  slug: string;
  fullData: any;
  sendData: Function;
  combineData: (existingData: any, newData: any) => any;
  refresh: Function;
  name: string;
}) {
  const { t } = useTranslation();
  const [popupOpened, setPopupOpened] = useState(false);
  const [uploadError, setUploadError] = useState<ErrorProps[]>([]);
  return (
    <>
      <UploadExcelPopup
        error={uploadError}
        setError={setUploadError}
        slug={slug}
        opened={popupOpened}
        setOpened={setPopupOpened}
        fullData={fullData}
        combineData={combineData}
        sendData={sendData}
        refresh={refresh}
        name={name}
      />

      <Button
        onClick={() => {
          setPopupOpened(true);
          setUploadError([]);
        }}
      >
        <Icon src={uploadExcel} />
        <Text>{t('forms.excel')}</Text>
      </Button>
    </>
  );
}
