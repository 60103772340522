import React, { useState } from 'react';
import { Country, Desc, InfoBox, Title, TitleBox } from './index.styles';
import { QuestionnaireTitleProps } from './interface';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../redux-file/hooks';

export default function QuestionnaireTitle({
  text,
  info,
}: QuestionnaireTitleProps) {
  const { t } = useTranslation();
  const organization = useAppSelector(
    (state) => state.platform.activeOrganization
  );
  const country = organization?.country;

  const [visible, setVisible] = useState(false);

  // sorry for this kind of styling,
  // but the components in Co2 are so nested togather, that I had to do it this way
  const titleBoxStyle = !!info ? { minWidth: '95%' } : { minWidth: '50%' };

  return (
    <TitleBox style={titleBoxStyle}>
      <Title>{text}</Title>

      {info && (
        <InfoBox
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
        >
          <Country>{country ? t(`countries.${country}`) : ''}</Country>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14V10C9 9.44771 9.44772 9 10 9C10.5523 9 11 9.44772 11 10V14ZM11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6Z"
              fill="#388276"
            />
          </svg>
          {visible && <Desc>{t('forms.description')}</Desc>}
        </InfoBox>
      )}
    </TitleBox>
  );
}
