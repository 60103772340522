import React from 'react';
import { Select } from '@mui/material';
import { StyledMenuItem } from 'src/Superadmin/components/UsersTable/index.styles';
import { MemberRole } from 'src/Superadmin/index.types';
import { useTranslation } from 'react-i18next';

interface Props {
  value: any;
  onChange?: any;
}

export default function PermissionSelect(props: Props) {
  const { value, onChange } = props;

  const { t } = useTranslation();
  const roles = Object.values(MemberRole);

  return (
    <Select fullWidth value={value} onChange={onChange} size="small">
      {roles.map((role: MemberRole) => (
        <StyledMenuItem value={role} key={role}>
          {t(
            `superadmin.settings.tabs.users.usersTable.permissions.options.${role}`
          )}
        </StyledMenuItem>
      ))}
    </Select>
  );
}
