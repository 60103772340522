import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';
import { CbamInstallationOperatorInSchema } from '../types';
import { TFunction } from 'react-i18next';

export type FormErrors = SchemaErrors<CbamInstallationOperatorInSchema>;

export const requiredFields: (keyof CbamInstallationOperatorInSchema)[] = [
  'identifier',
  'name',
  'country',
  'city',
  'contact_name',
  'contact_email',
  'contact_phone_number',
];

export const validateForm = (
  data: CbamInstallationOperatorInSchema,
  t: TFunction
): FormErrors => {
  const builder = new ErrorBuilder(data);
  return builder
    .checkTruthy(requiredFields, t('validation.required'))
    .checkEmail(['contact_email'], t('validation.emailError'))
    .build();
};
