import { RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DescriptionContainer,
  Icon,
  InfoContainer,
} from '../../../../components/Title/index.styles';
import {
  StyledFormControl,
  StyledFormControlLabel,
  StyledFormLabel,
  StyledRadio,
} from './index.styles';
import InfoIcon from '../../../../images/info.png';
import InfoIconHover from '../../../../images/green-info.png';
import { RadioGroupTargetProps } from './index.interfaces';

export default function RadioGroupTarget({
  targetPercent,
  selectedTarget,
  setSelectedTarget,
  flex = true,
}: RadioGroupTargetProps) {
  const [hover, setHover] = useState<'total' | 'per_income' | 'per_employee'>();
  const onHover = (value: 'total' | 'per_income' | 'per_employee') => {
    setHover(value);
  };
  const { t } = useTranslation();
  const onLeave = () => {
    setHover(undefined);
  };

  return (
    <StyledFormControl className="radio-button-group">
      <StyledFormLabel id="radio-button-group" />
      <RadioGroup
        sx={{ flexDirection: flex ? 'column' : 'row' }}
        value={selectedTarget}
        onChange={(_, newValue) =>
          setSelectedTarget(newValue as 'total' | 'per_income' | 'per_employee')
        }
      >
        {Object.keys(targetPercent).map((option) => (
          <div
            style={{ display: 'flex', marginRight: flex ? 0 : '25px' }}
            key={option}
          >
            <StyledFormControlLabel
              key={option}
              value={option}
              disabled={option !== "total"}
              label={t(`targets.method.${option}`)}
              control={<StyledRadio />}
            />
            <div style={{ marginTop: '6px', marginLeft: '-10px' }}>
              {targetPercent[option].desc && (
                <DescriptionContainer
                  onMouseEnter={() =>
                    onHover(option as 'total' | 'per_income' | 'per_employee')
                  }
                  onMouseLeave={() => onLeave()}
                  role="button"
                >
                  <Icon src={hover ? InfoIconHover : InfoIcon} />
                </DescriptionContainer>
              )}
              {hover && hover === option ? (
                <InfoContainer>{t('targets.percents.desc')}</InfoContainer>
              ) : null}
            </div>
          </div>
        ))}
      </RadioGroup>
    </StyledFormControl>
  );
}
