import React, { useState } from 'react';
import { TabButton, TabContainer } from './index.styles';
import { TabProps, TabGroupProps } from './index.interfaces';
import { useTranslation } from 'react-i18next';

const TabGroup = ({ children: tabs }: TabGroupProps) => {
  const { t } = useTranslation();
  const subsites = React.Children.map(tabs, (subsite) => {
    if (!React.isValidElement(subsite)) {
      return null;
    }

    const { id, title, children } = subsite!.props;

    return { id, title, children };
  })!.filter(Boolean) as TabProps[];
  const [activeSubsiteId, setActiveSubsiteId] = useState(subsites[0]!.id);

  const handleTabClick = (subsiteId: string) => {
    setActiveSubsiteId(subsiteId);
  };

  const activeSubsite =
    subsites.find((subsite) => subsite.id === activeSubsiteId) || subsites[0];

  return (
    <div>
      <TabContainer>
        {subsites.map((subsite) => (
          <TabButton
            active={subsite.id === activeSubsiteId}
            key={subsite.id}
            onClick={() => handleTabClick(subsite.id)}
          >
            {t(`${subsite.title}`)}
          </TabButton>
        ))}
      </TabContainer>
      <div>{activeSubsite.children}</div>
    </div>
  );
};

export const Tab = ({ children }: TabProps) => <div>{children}</div>;

export default TabGroup;
