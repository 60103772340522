import React from 'react';
import { View, Image, Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

type PDFChartProps = {
  base64: any;
  style: any;
};

const NoData = () => {
  const { t } = useTranslation();
  return (
    <View
      style={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 300,
        width: 300,
      }}
    >
      <Text style={{ fontFamily: 'Poppins' }}>{t('raport.no-data')}</Text>
    </View>
  );
};

const PDFChart = ({ base64, style }: PDFChartProps) =>
  !base64 ? <NoData /> : <Image src={base64} style={style} />;

export default PDFChart;
