import { Button, Typography } from '@mui/material';
import React from 'react';
import { useMassBalance } from 'src/Cbam/hooks';
import MassBalance from './MassBalance';
import InfoPanel from 'src/Cbam/components/InfoPanel';

interface Props {
  id: number;
}
export default function MassBalances(props: Props) {
  const { id } = props;
  const massBalances = useMassBalance(Number(id));
  const [isNewVisible, setIsNewVisible] = React.useState(false);
  return (
    <>
      <Typography variant="h3">Mass balance method (B.3.2)</Typography>
      <br />
      <InfoPanel>
        <Typography>
          In Mass balance method, the CO2 quantities relevant for each source
          stream shall be calculated based on the carbon content in each
          material, without distinguishing fuels and process materials. Carbon
          leaving the installation in products instead of being emitted is taken
          into account by output source streams, which have therefore negative
          activity data.
        </Typography>
      </InfoPanel>
      <br />
      {!massBalances.data.length && !isNewVisible && (
        <Typography sx={{ m: '20px 0px' }}>No mass balance data</Typography>
      )}
      {massBalances.data.map((massBalance) => (
        <MassBalance
          key={massBalance.id}
          productionProcessId={id}
          id={massBalance.id}
        />
      ))}

      {!isNewVisible ? (
        <Button sx={{ mt: '20px' }} onClick={() => setIsNewVisible(true)}>
          Add mass based data
        </Button>
      ) : (
        <MassBalance
          productionProcessId={id}
          onSaved={() => setIsNewVisible(false)}
        />
      )}
    </>
  );
}
