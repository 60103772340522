import React from 'react';
import Diagram from './Diagram';

import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import { useLcaDiagram } from 'src/Lca/hooks';
import { Box } from '@mui/material';

export default function LCADiagram({ productId }: { productId: number }) {
  const { data, error } = useLcaDiagram(productId);

  if (error) return <SomethingWentWrong />;
  if (data === undefined) return <PageLoading />;

  return (
    <Box sx={{ mt: '12px' }}>
      <Diagram
        boundary={data?.lifecycle_boundaries}
        data={data?.stages || []}
        productId={productId}
      />
    </Box>
  );
}
