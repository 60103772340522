import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Typography } from '@mui/material';
import {
  Delete as DeleteIcon,
  Send as SendIcon,
  MoreTime as MoreTimeIcon,
  Preview as PreviewIcon,
  AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon,
} from '@mui/icons-material';
import {
  StyledHeader,
  StyledSection,
  StyledTitle,
  StyledCell,
  StyledCellHeader,
  StyledCellText,
  StyledColumnName,
} from '../../components/Table/index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import { getColorFromNumber } from 'src/Ghg/utils';
import PageLoading from 'src/components/PageLoading';
import LightTooltip from 'src/components/LightTooltip';
import InviteSupplierModal from '../components/InviteSupplierModal';
import { useSupplierSurvey } from '../hooks';
import { toast } from 'react-toastify';
import ConfirmationModal from 'src/components/ConfirmationModal';
import ProductPeriodModal from '../components/ProductPeriodModal';
import { GhgSupplierSurvey } from '../../interfaces';
import { format } from 'date-fns';
import { useGhgOrganization } from '../../../common/hooks';

const T_KEY = 'ghg.scope3GoodsAndServices.productsFromSuppliers';
const dateFormat = 'yyyy-MM-dd';

export default function ProductsFromSuppliers() {
  const { t } = useTranslation();

  const [deleteInvitation, setDeleteInvitation] =
    React.useState<GhgSupplierSurvey | null>(null);

  const [addReportSurvey, setAddReportSurvey] =
    React.useState<GhgSupplierSurvey | null>(null);

  const organization = useGhgOrganization();

  const [newInvitation, setNewInvitation] = React.useState<boolean>(false);
  const [editInvitation, setEditInvitation] =
    React.useState<GhgSupplierSurvey | null>(null);

  const { data, remove } = useSupplierSurvey(organization.id);

  const handleSendAgainClick = (invitation: GhgSupplierSurvey | null) =>
    setEditInvitation(invitation);

  const handleDeleteClick = (invitation: GhgSupplierSurvey) =>
    setDeleteInvitation(invitation);

  const handleDeleteInvitation = () =>
    remove.mutateAsync(deleteInvitation?.id as number).then(() => {
      toast.success(
        t('ghg.scope3GoodsAndServices.toasts.invitationDeleted') as string
      );
      setDeleteInvitation(null);
    });

  return (
    <div style={{ minHeight: '500px', minWidth: '900px' }}>
      <StyledTitle>{t(`${T_KEY}.title`)}</StyledTitle>

      <StyledSection>
        <StyledFlex
          sx={{ bgcolor: '#606462', color: '#fff', alignItems: 'normal' }}
        >
          <StyledHeader sx={{ borderLeft: '8px solid #606462' }}>
            <StyledColumnName>
              {t(`${T_KEY}.table.columns.productName`)}
            </StyledColumnName>
          </StyledHeader>
          <StyledHeader>
            <StyledColumnName>
              {t(`${T_KEY}.table.columns.supplierName`)}
            </StyledColumnName>
          </StyledHeader>
          <StyledHeader>
            <StyledColumnName>
              {t(`${T_KEY}.table.columns.reportingPeriod`)}
            </StyledColumnName>
          </StyledHeader>
          <StyledHeader>
            <StyledColumnName>
              {t(`${T_KEY}.table.columns.suppliersResponse`)}
            </StyledColumnName>
          </StyledHeader>
          <StyledHeader sx={{ minWidth: '195px', maxWidth: '195px' }}>
            <StyledColumnName>
              {t(`${T_KEY}.table.columns.editOptions`)}
            </StyledColumnName>
          </StyledHeader>
        </StyledFlex>
        {!data && <PageLoading />}

        {(data || []).map((row) => {
          const indentColor = getColorFromNumber(row.id);
          const {
            created_at: createdAt,
            product_name: productName,
            supplier_name: supplierName,
            answer,
          } = row;
          return (
            <StyledFlex key={row.id} sx={{ alignItems: 'normal' }}>
              <StyledCell
                style={{
                  borderLeft: `6px solid ${indentColor}`,
                }}
              >
                <StyledCellHeader>
                  {t(`${T_KEY}.table.sent`)}:{' '}
                  {format(new Date(createdAt), dateFormat)}
                </StyledCellHeader>
                <StyledCellText>{productName}</StyledCellText>
              </StyledCell>
              <StyledCell sx={{ borderLeft: `6px solid ${indentColor}` }}>
                <StyledCellText>{supplierName}</StyledCellText>
              </StyledCell>
              <StyledCell sx={{ borderLeft: `6px solid ${indentColor}` }}>
                <StyledCellText>
                  {answer?.goods.map((rp) => (
                    <Typography key={rp.id}>{rp.reporting_period}</Typography>
                  ))}
                </StyledCellText>
              </StyledCell>
              <StyledCell sx={{ borderLeft: `6px solid ${indentColor}` }}>
                <StyledCellText>
                  {answer?.submitted_at
                    ? format(new Date(answer.submitted_at), dateFormat)
                    : t(`${T_KEY}.table.noResponse`)}
                </StyledCellText>
              </StyledCell>
              <StyledCell
                sx={{
                  borderLeft: `6px solid ${indentColor}`,
                  minWidth: '195px',
                  maxWidth: '195px',
                }}
              >
                <LightTooltip title={t(`${T_KEY}.table.buttons.remove`)}>
                  <IconButton onClick={() => handleDeleteClick(row)}>
                    <DeleteIcon />
                  </IconButton>
                </LightTooltip>

                <LightTooltip title={t(`${T_KEY}.table.buttons.sendAgain`)}>
                  <IconButton onClick={() => handleSendAgainClick(row)}>
                    <SendIcon />
                  </IconButton>
                </LightTooltip>

                <LightTooltip title={t(`${T_KEY}.table.buttons.addToReport`)}>
                  <IconButton
                    disabled={!answer?.is_submitted}
                    onClick={() => setAddReportSurvey(row)}
                  >
                    <MoreTimeIcon />
                  </IconButton>
                </LightTooltip>

                <IconButton disabled>
                  <PreviewIcon />
                </IconButton>
              </StyledCell>
            </StyledFlex>
          );
        })}
      </StyledSection>

      <Button
        variant="contained"
        sx={{ mt: '25px' }}
        startIcon={
          <AddCircleOutlineOutlinedIcon sx={{ transform: 'scale(1.2)' }} />
        }
        onClick={() => setNewInvitation(true)}
      >
        {t(`goods-and-services.question1.method2.button`)}
      </Button>
      <InviteSupplierModal
        key={editInvitation?.id}
        open={!!newInvitation || !!editInvitation}
        onClose={() => {
          setNewInvitation(false);
          setEditInvitation(null);
        }}
        invitation={editInvitation}
      />

      <ProductPeriodModal
        surveyAnswerId={addReportSurvey?.answer?.id as number}
        open={!!addReportSurvey}
        onClose={() => setAddReportSurvey(null)}
      />

      <ConfirmationModal
        open={!!deleteInvitation}
        onClose={() => setDeleteInvitation(null)}
        onFalse={() => setDeleteInvitation(null)}
        onTrue={handleDeleteInvitation}
        titleKey={`${T_KEY}.table.deleteConfirmation.title`}
        textKey={`${T_KEY}.table.deleteConfirmation.text`}
      />
    </div>
  );
}
