import welcome from 'src/images/surveys/welcome.png';

export default {
  intro: {
    image: welcome,
  },
  initialQuestions: {
    image: welcome,
  },
  questionnaire: {
    image: welcome,
  },
  end: {
    image: welcome,
  },
};
