import React, { useEffect, useState } from 'react';
import { Outlet, RouteProps, useLocation } from 'react-router-dom';
import { EditButton, Title } from './index.styles';
import { Container } from '../ReportsIndex/index.styles';
import ReportTabs from '../../../../components/esg/reportTabs';
import { EditIcon } from './icon';
import EditNameModal from './editNameModal';
import { useRequiredSelector, useEsgOrganization } from 'src/common/hooks';
import { useTranslation } from 'react-i18next';

import EsgExportToolsButton from 'src/components/EsgExportToolsButton';

export default function ReportPage(props: RouteProps) {
  const { t } = useTranslation();
  const location = useLocation();

  const report = useRequiredSelector((state) => state.esg.activeReport);

  const [open, setOpen] = useState(false);

  const organization = useEsgOrganization();
  const canEdit = organization.can_write_data;

  useEffect(() => {
    localStorage.setItem('esg_report', report.id.toString() as string);
  }, []);

  const TabData = [
    {
      name: 'esg.indicators',
      url: `/esg/reports/${report.id}/indicators`,
    },
    {
      name: 'esg.summary',
      url: `/esg/reports/${report.id}/summary`,
    },
    {
      name: 'esg.download',
      url: `/esg/reports/${report.id}/download`,
    },
  ];

  if (canEdit) {
    TabData.unshift({
      name: 'esg.materialityAssesment',
      url: `/esg/reports/${report.id}/dma`,
    });
  }

  const handleClose = () => setOpen(false);

  // Determine if the current page is the DMA page
  const shouldHideEsgExportButton =
    location.pathname.split('/').pop() === 'dma';

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => canEdit && setOpen(true)}
        >
          <Title>
            {report.name} ({report.period})
          </Title>
          {canEdit && (
            <div>
              <EditButton className="EditButton" type="button">
                <EditIcon />
              </EditButton>
            </div>
          )}
        </div>
        {!shouldHideEsgExportButton && (
          <EsgExportToolsButton
            reportIds={[report.id]}
            organizationIds={[organization.id]}
            allowDocx
            size="medium"
          />
        )}
      </div>
      <EditNameModal
        key={report.id}
        open={open}
        setClose={handleClose}
        report={report}
        shouldRedirect // temporary solution before refactor
      />

      <ReportTabs data={TabData} />

      {props.children}
      <Outlet />
    </Container>
  );
}
