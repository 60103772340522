import React from 'react';

import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { useEsgUserPermissions } from '../hooks';
import { parseEsgUserPermissonsSubtopics } from '../utils';
import { StyledFlex } from 'src/components/StyledFlex';
import { StyledTableCell, StyledTableStickyCell } from '../styles';
import PageLoading from 'src/components/PageLoading';
import IndicatorCollapse from './IndicatorCollapse';
import PermissionSelect from './PermissionSelect';
import TableCollapsable from './TableCollapsable';
import { useTranslation } from 'react-i18next';

interface Props {
  userId: number;
  year: number | null;
}

export default function EsgUserPermissionTable(props: Props) {
  const { userId, year } = props;
  const { t } = useTranslation();

  const { isLoading, options, userOrganizations } =
    useEsgUserPermissions(userId);
  const reports = options.reports.filter((report) => report.period === year);
  const [expanded, setExpanded] = React.useState<number[]>([]);
  const subtopics = parseEsgUserPermissonsSubtopics(
    options.indicators,
    options.indicator_categories,
    options.questions
  );

  const handleExpandClick = (id: number, isExpanded: boolean) =>
    isExpanded
      ? setExpanded(expanded.filter((_id) => _id !== id))
      : setExpanded([...expanded, id]);

  if (isLoading) return <PageLoading />;

  if (year === null)
    return (
      <Typography>
        {t(
          'superadmin.settings.tabs.users.usersTable.permissions.other.noReportsAvailable'
        )}
      </Typography>
    );

  if (!userOrganizations.length)
    return (
      <Typography>
        {t(
          'superadmin.settings.tabs.users.usersTable.permissions.other.noOrganizationsReportAccess'
        )}
      </Typography>
    );

  return (
    <Box sx={{ overflowX: 'scroll' }}>
      <TableCollapsable isExpanded>
        <TableHead>
          <TableRow>
            <StyledTableStickyCell>
              {t(
                'superadmin.settings.tabs.users.usersTable.permissions.headers.subtopic'
              )}
            </StyledTableStickyCell>
            {userOrganizations.map((organization) => (
              <StyledTableCell key={`head-${organization.id}`}>
                {organization.name}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(subtopics).map((subtopic) => {
            const isExpanded = expanded.includes(subtopic.indicatorCategory.id);
            const Icon = isExpanded ? ExpandLessIcon : ExpandMoreIcon;

            const _handleExpandClick = () =>
              handleExpandClick(subtopic.indicatorCategory.id, isExpanded);

            const key = subtopic.indicatorCategory.id;

            return (
              <React.Fragment key={key}>
                {/* Indicator Category Row  */}
                <TableRow key={subtopic.indicatorCategory.id}>
                  <StyledTableStickyCell sx={{ p: 0 }}>
                    <MenuItem
                      onClick={_handleExpandClick}
                      sx={{ pt: '16px', pb: '16px', whiteSpace: 'normal' }}
                    >
                      <StyledFlex sx={{ position: 'sticky', left: 0 }}>
                        <ListItemIcon>
                          <Icon sx={{ fontWeight: 600 }} />
                        </ListItemIcon>
                        <Typography sx={{ fontWeight: 600, textWrap: 'wrap' }}>
                          {subtopic.indicatorCategory.name}
                        </Typography>
                      </StyledFlex>
                    </MenuItem>
                  </StyledTableStickyCell>
                  {userOrganizations.map((organization) => {
                    const report = reports.find(
                      (r) => r.esg_organization_id === organization.id
                    );
                    if (!report)
                      return (
                        <StyledTableCell key={key + organization.id}>
                          <Typography>
                            {t(
                              'superadmin.settings.tabs.users.usersTable.permissions.other.noReport'
                            )}
                          </Typography>
                        </StyledTableCell>
                      );
                    return (
                      <StyledTableCell
                        key={key + organization.id}
                        sx={{ p: '8px' }}
                      >
                        <PermissionSelect
                          userId={userId}
                          organizationId={organization.id}
                          questionId={null}
                          indicatorId={null}
                          indicatorCategoryId={subtopic.indicatorCategory.id}
                          reportId={report.id}
                        />
                      </StyledTableCell>
                    );
                  })}
                </TableRow>

                <IndicatorCollapse
                  subtopic={subtopic}
                  isExpanded={isExpanded}
                  userId={userId}
                  year={year}
                />
              </React.Fragment>
            );
          })}
        </TableBody>
      </TableCollapsable>
    </Box>
  );
}
