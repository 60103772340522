import React from 'react';
import {
  ProductInformationProps,
  StyledTextField,
} from '../productInformation';
import { FormControl } from '@mui/material';
import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { set } from 'lodash';
import { SmallButton } from '../index.styles';
import Autocomplete from 'src/components/Autocomplete';

interface LcaIndustryCode {
  code: string;
  description: string;
}

export default function BasicInfo({
  setValues,
  values,
}: ProductInformationProps) {
  const { data: cpcData } = useQuery('getCpc', () => {
    return client.get<LcaIndustryCode[]>('/web/industries/cpc');
  });

  const { data: naceData } = useQuery('getNace', () => {
    return client.get<LcaIndustryCode[]>('/web/industries/nace');
  });

  const naceOptional = values.nace_industries.slice(1);

  const removeNace = (i: number) => {
    setValues({
      ...values,
      nace_industries: values.nace_industries.splice(i, 1),
    });
  };
  const removeCpc = () => {
    setValues({
      ...values,
      cpc_industry: '',
    });
  };
  const addNace = () => {
    setValues({
      ...values,
      nace_industries: [...values.nace_industries, { code: '' }],
    });
  };
  const handleNace = (value: string | null, i: number) => {
    const temp = { ...values };
    set(temp, `nace_industries[${i}].code`, value);
    setValues(temp);
  };

  const handleCpc = (value: string | null) => {
    setValues({ ...values, cpc_industry: { code: value } });
  };

  const handleName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({ ...values, name: e.target.value });
  };
  const handleDesc = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({ ...values, description: e.target.value });
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '24px',
        flexDirection: 'column',
      }}
    >
      <StyledTextField
        fullWidth
        label="Name of the product"
        name="name"
        value={values.name}
        onChange={handleName}
      />
      <StyledTextField
        fullWidth
        label="Description of the product"
        name="description"
        value={values.description}
        onChange={handleDesc}
        multiline
        rows={3}
      />

      <FormControl fullWidth>
        <Autocomplete
          label="Main NACE code"
          placeholder="Main NACE code"
          options={naceData?.data.map((option: LcaIndustryCode) => ({
            value: option.code,
            label: `${option.code} ${option.description}`,
          }))}
          value={values.nace_industries[0].code ?? ''}
          onChange={(value: string | null) => handleNace(value, 0)}
          sx={{
            backgroundColor:
              values.nace_industries[0].code ?? '' ? '#F6F6F6' : 'inherit',
          }}
          handleClearClick={() =>
            setValues({
              ...values,
              nace_industries: [...values.nace_industries, { code: '' }],
            })
          }
        />
      </FormControl>

      {naceOptional.length >= 1 &&
        naceOptional.map((_, i: number) => (
          <div
            key={i}
            style={{ display: 'flex', alignItems: 'center', gap: 24 }}
          >
            <FormControl fullWidth>
              <Autocomplete
                label="Additional NACE codes (optional)"
                placeholder="Additional NACE codes (optional)"
                options={naceData?.data.map((option: LcaIndustryCode) => ({
                  value: option.code,
                  label: `${option.code} ${option.description}`,
                }))}
                value={naceOptional[i].code}
                onChange={(value: string | null) => handleNace(value, i + 1)}
                sx={{
                  backgroundColor:
                    values.nace_industries[0].code ?? ''
                      ? '#F6F6F6'
                      : 'inherit',
                }}
                handleClearClick={() => removeNace(i)}
              />
            </FormControl>
            <SmallButton type="button" onClick={() => removeNace(i)}>
              remove
            </SmallButton>
          </div>
        ))}
      <div>
        <SmallButton
          type="button"
          onClick={addNace}
          disabled={
            values.nace_industries.length > 9 ||
            values.nace_industries[values.nace_industries.length - 1]?.code ===
              ''
          }
        >
          Add another NACE code
        </SmallButton>
      </div>

      <FormControl fullWidth>
        <Autocomplete
          label="UN CPC code"
          placeholder="UN CPC code"
          options={cpcData?.data.map((option: LcaIndustryCode) => ({
            value: option.code,
            label: `${option.code} ${option.description}`,
          }))}
          value={values.cpc_industry?.code ?? ''}
          onChange={(value: string | null) => handleCpc(value)}
          sx={{
            backgroundColor:
              values.cpc_industry.code ?? '' ? '#F6F6F6' : 'inherit',
          }}
          handleClearClick={removeCpc}
        />
      </FormControl>
    </div>
  );
}
