import React from 'react';
import { StyledTitle } from '../index.styles';
import { useTranslation } from 'react-i18next';
import SuperadminTabs from './Tabs';
import TabContent from './Content';

import { SETTINGS_TABS, isTabKey } from './Tabs/index.types';

export default function Settings() {
  const { t } = useTranslation();

  const initialTab = (() => {
    const pathTab = window.location.hash.replace('#', '');
    if (isTabKey(pathTab)) return pathTab;
    return SETTINGS_TABS.STRUCTURE_TAB;
  })();

  const [tab, setTab] = React.useState<SETTINGS_TABS>(initialTab);

  return (
    <>
      <StyledTitle>{t('superadmin.settings.title')}</StyledTitle>
      <SuperadminTabs tab={tab} setTab={setTab} />
      <TabContent tab={tab} />
    </>
  );
}
