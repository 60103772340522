import React from 'react';
import {
  StyledHeader,
  StyledSection,
  StyledTitle,
  StyledCell,
  StyledCellText,
  StyledColumnName,
} from '../../components/Table/index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import { getColorFromNumber } from 'src/Ghg/utils';
import LightTooltip from 'src/components/LightTooltip';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddProductButton from 'src/components/AddProductButton';
import { toast } from 'react-toastify';
import ConfirmationModal from 'src/components/ConfirmationModal';
import ProductPeriodModal from '../components/ProductPeriodModal';
import {
  GOODS_AND_SERVICES_OR_CAPITAL_GOODS,
  GhgSupplierGoods,
} from '../../interfaces';
import { useSupplierGoods } from '../hooks';
import { useTranslation } from 'react-i18next';
import { useGhgOrganization } from '../../../common/hooks';

interface Props {
  data: GhgSupplierGoods[];
  onAddNewRow: () => void;
  type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
}

const tKey = 'ghg.scope3GoodsAndServices.goodsFromSuppliersTable';

export default function ProductTable({ data, onAddNewRow, type }: Props) {
  const { t } = useTranslation();
  const organization = useGhgOrganization();
  const { remove } = useSupplierGoods(organization.id);

  const [deleteRow, setDeleteRow] = React.useState<GhgSupplierGoods | null>(
    null
  );

  const [editRow, setEditRow] = React.useState<GhgSupplierGoods | undefined>(
    undefined
  );

  const handleDeleteClick = (row: GhgSupplierGoods) => setDeleteRow(row);

  const handleProductPeriodDelete = () => {
    if (deleteRow)
      remove
        .mutateAsync(deleteRow.id)
        .then(() =>
          toast.success(
            t(
              'ghg.scope3GoodsAndServices.toasts.productPeriodDeleted'
            ) as string
          )
        );
    setDeleteRow(null);
  };

  const handleEditClick = (row: GhgSupplierGoods) => setEditRow(row);

  const amountTKey =
    type === GOODS_AND_SERVICES_OR_CAPITAL_GOODS.CAPITAL_GOODS
      ? 'amountCapitalGoods'
      : 'amountGoodsAndServices';

  const title =
    type === GOODS_AND_SERVICES_OR_CAPITAL_GOODS.GOODS_AND_SERVICES
      ? t('ghg.scope3GoodsAndServices.goodsAnsServicesFromSuppliersTableTitle')
      : t('ghg.scope3GoodsAndServices.capitalGoodsFromSuppliersTableTitle');

  return (
    <div style={{ minWidth: '700px' }}>
      <StyledTitle>{title}</StyledTitle>

      <StyledSection>
        <StyledFlex
          sx={{ bgcolor: '#606462', color: '#fff', alignItems: 'normal' }}
        >
          <StyledHeader sx={{ borderLeft: '5px solid #606462' }}>
            <StyledColumnName>
              {t(`${tKey}.columns.${amountTKey}`)}
            </StyledColumnName>
          </StyledHeader>
          <StyledHeader>
            <StyledColumnName>{t(`${tKey}.columns.name`)}</StyledColumnName>
          </StyledHeader>
          <StyledHeader>
            <StyledColumnName>
              {t(`${tKey}.columns.providedBy`)}
            </StyledColumnName>
          </StyledHeader>
          <StyledHeader>
            <StyledColumnName>
              {t(`${tKey}.columns.emissionShare`)}
            </StyledColumnName>
          </StyledHeader>
          <StyledHeader
            sx={{ width: '10%', minWidth: '128px', maxWidth: '128px' }}
          >
            <StyledColumnName>{t(`${tKey}.columns.edit`)}</StyledColumnName>
          </StyledHeader>
        </StyledFlex>

        {(data || []).map((row) => {
          const indentColor = getColorFromNumber(row.id);
          const { survey, amount } = row;
          const { product_name: productName, supplier_name: supplierName } =
            survey;
          return (
            <StyledFlex key={row.id} sx={{ alignItems: 'normal' }}>
              <StyledCell sx={{ borderLeft: `6px solid ${indentColor}` }}>
                <StyledCellText>{amount}</StyledCellText>
              </StyledCell>
              <StyledCell sx={{ borderLeft: `6px solid ${indentColor}` }}>
                <StyledCellText>{productName}</StyledCellText>
              </StyledCell>
              <StyledCell sx={{ borderLeft: `6px solid ${indentColor}` }}>
                <StyledCellText>{supplierName}</StyledCellText>
              </StyledCell>
              <StyledCell sx={{ borderLeft: `6px solid ${indentColor}` }}>
                <StyledCellText>-</StyledCellText>
              </StyledCell>

              <StyledCell
                sx={{
                  borderLeft: `6px solid ${indentColor}`,
                  minWidth: '128px',
                  maxWidth: '128px',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <LightTooltip title={t(`${tKey}.buttons.edit.tooltip`)}>
                  <IconButton onClick={() => handleEditClick(row)}>
                    <EditIcon />
                  </IconButton>
                </LightTooltip>
                <LightTooltip title={t(`${tKey}.buttons.delete.tooltip`)}>
                  <IconButton onClick={() => handleDeleteClick(row)}>
                    <DeleteIcon />
                  </IconButton>
                </LightTooltip>
              </StyledCell>
            </StyledFlex>
          );
        })}
      </StyledSection>
      <AddProductButton onClick={onAddNewRow} />
      <br />

      <ProductPeriodModal
        key={editRow?.id}
        surveyAnswerId={editRow?.survey_answer_id as number}
        open={!!editRow}
        onClose={() => setEditRow(undefined)}
        instance={editRow}
      />

      <ConfirmationModal
        open={!!deleteRow}
        onClose={() => setDeleteRow(null)}
        onFalse={() => setDeleteRow(null)}
        onTrue={handleProductPeriodDelete}
        titleKey={`${tKey}.deleteConfirmation.title`}
        textKey={`${tKey}.deleteConfirmation.text`}
      />
    </div>
  );
}
