import { styled as styledMUI } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledContainer = styledMUI(Box)`
  padding-bottom: 150px;
  min-height: calc(100vh - 208px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
