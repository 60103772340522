import React from 'react';
import { OrganizationTreeNode } from '../../../components/StructureTable/index.types';
import {
  StyledRow,
  StyledTable,
  StyledCell,
  StyledProductCellContainer,
} from '../../../components/StructureTable/index.styles';
import Row from './Row';
import { useTranslation } from 'react-i18next';

interface Props {
  data: OrganizationTreeNode[];
}

const StructureTable = (props: Props) => {
  const { t } = useTranslation();
  const { data } = props;
  return (
    <StyledTable>
      <StyledRow>
        <StyledCell type="header" w="50%" sx={{ borderLeftColor: '#38414f' }}>
          {t('superadmin.settings.tabs.structure.table.unitName')}
        </StyledCell>
        <StyledCell type="header" w="20%">
          {t('superadmin.settings.tabs.structure.table.unitType')}
        </StyledCell>
        <StyledCell type="header" w="20%">
          <StyledProductCellContainer>
            <span>GHG</span>
            <span>ESG</span>
            <span>LCA</span>
          </StyledProductCellContainer>
        </StyledCell>
        <StyledCell type="header" w="10%" sx={{ textAlign: 'center' }}>
          {t('superadmin.settings.tabs.structure.table.select')}
        </StyledCell>
      </StyledRow>
      {data.map((organization) => (
        <Row key={organization.id} organization={organization} />
      ))}
    </StyledTable>
  );
};

export default StructureTable;
