import React from 'react';
import { useQuery } from 'react-query';

import PDFDownloadButton from './PDFDownloadButton';
import { client, handleToastError } from '../../../../utils/api-client';
import { EsgSummary } from '../../../components/ESGSummary/types';

type Props = {
  organizationId?: string | number;
  reportId: number;
};

export default function ESGSummary({ organizationId, reportId }: Props) {
  const { data, error, isFetching } = useQuery<EsgSummary>(
    ['esg-summary', 'pdf', organizationId, reportId],
    () =>
      client
        .get(`/web/esg_v2/${organizationId}/${reportId}/all-answers?view=pdf`)
        .then((response) => response.data)
        .catch(handleToastError)
  );

  return (
    <PDFDownloadButton isLoading={isFetching || !!error} esgSummary={data} />
  );
}
