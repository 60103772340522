import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledFlex } from 'src/components/StyledFlex';
import { StyledPanel } from 'src/components/StyledPanel';
import {
  useSupportingDocumentsForImportedGoods,
  useSupportingDocumentsForProductionProcesses,
} from '../hooks';
import { SupportingDocumentUsage } from '../types';
import { useTranslation } from 'react-i18next';

export default function SupportingDocuments() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.supportingDocuments',
  });
  const navigate = useNavigate();
  const documentsForImportedGoods = useSupportingDocumentsForImportedGoods();
  const documentsForProductionProcesses =
    useSupportingDocumentsForProductionProcesses();

  return (
    <Box>
      <StyledFlex>
        <Typography variant="h1">Supporting Documents</Typography>
      </StyledFlex>
      <br />
      <br />
      <StyledPanel>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '400px' }}>
                Reference number (for Imported Goods)
              </TableCell>
              <TableCell>Document type</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentsForImportedGoods.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.reference_number}</TableCell>
                <TableCell>{t(`types.${row.type}`)}</TableCell>
                <TableCell sx={{ width: '50px', pl: 0, pr: 0 }}>
                  <IconButton
                    onClick={() =>
                      navigate(String(row.id), {
                        state: {
                          usage: SupportingDocumentUsage.ForImportedGood,
                        },
                      })
                    }
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell sx={{ width: '50px', pl: 0, pr: 0 }}>
                  <IconButton
                    onClick={() =>
                      documentsForImportedGoods.delete.mutate(row.id)
                    }
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <Button
                  onClick={() =>
                    navigate('create', {
                      state: { usage: SupportingDocumentUsage.ForImportedGood },
                    })
                  }
                >
                  Add Document
                </Button>
              </TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledPanel>
      <br />
      <br />
      <StyledPanel>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '400px' }}>
                Reference number (for Production Processes)
              </TableCell>
              <TableCell>Document type</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentsForProductionProcesses.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.reference_number}</TableCell>
                <TableCell>{t(`types.${row.type}`)}</TableCell>
                <TableCell sx={{ width: '50px', pl: 0, pr: 0 }}>
                  <IconButton
                    onClick={() =>
                      navigate(String(row.id), {
                        state: {
                          usage: SupportingDocumentUsage.ForProductionProcess,
                        },
                      })
                    }
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell sx={{ width: '50px', pl: 0, pr: 0 }}>
                  <IconButton
                    onClick={() =>
                      documentsForProductionProcesses.delete.mutate(row.id)
                    }
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <Button
                  onClick={() =>
                    navigate('create', {
                      state: {
                        usage: SupportingDocumentUsage.ForProductionProcess,
                      },
                    })
                  }
                >
                  Add Document
                </Button>
              </TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledPanel>
    </Box>
  );
}
