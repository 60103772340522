import React from 'react';
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useSidebar } from '../hooks';
import { useTranslation } from 'react-i18next';

type OpenIconProps = {
  open: boolean;
};
const OpenIcon = ({ open }: OpenIconProps) => (
  <MenuOpenIcon sx={!open ? { transform: 'rotate(180deg)' } : undefined} />
);

export default function SidebarControllerItem() {
  const { isOpen, toggleByUser } = useSidebar();
  const { t } = useTranslation();

  return (
    <ListItem>
      <ListItemButton onClick={toggleByUser}>
        <ListItemIcon>
          <OpenIcon open={isOpen} />
        </ListItemIcon>
        <ListItemText primary={t('menu.min-menu')} />
      </ListItemButton>
    </ListItem>
  );
}
