import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from '../Calendar';
import { useGhgOrganization } from 'src/common/hooks';
import { BoxProps } from './index.interfaces';
import { ReportingFreq } from '../../../redux-file/interfaces';

type ContextlessBoxProps = BoxProps & {
  reportingFreq: ReportingFreq;
};

export function ContextlessBox({
  dashboard,
  children,
  text,
  infoModal,
  secondText,
  calendar,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  calendarVariant,
  recommendations,
  reportingFreq,
}: ContextlessBoxProps) {
  const { t } = useTranslation();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  return (
    <div
      className={`${styles.container} ${
        recommendations ? styles.recommendation : ''
      } ${dashboard ? styles.dashboard : ''}`}
    >
      {!!text && (
        <div className={styles.titleWrapper}>
          <div
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: t(text) }}
          />
          {!!secondText && (
            <div
              className={styles.secondText}
              dangerouslySetInnerHTML={{ __html: t(secondText) }}
            />
          )}
          {calendar && (
            <Calendar
              showCalendar={showCalendar}
              setShowCalendar={setShowCalendar}
              reportingPeriod={reportingFreq}
              calendarVariant={calendarVariant}
              setStartTime={setStartTime}
              startTime={startTime}
              setEndTime={setEndTime}
              endTime={endTime}
            />
          )}
          {!!infoModal && infoModal}
        </div>
      )}
      {children}
    </div>
  );
}

export default function Box(props: BoxProps) {
  const organization = useGhgOrganization();
  return (
    <ContextlessBox
      {...props}
      reportingFreq={organization.co2.reporting_freq}
    />
  );
}
