// TODO: feature-flags would be more appropriate than tags, but this is good enough

export enum OrganizationTag {
  HideScope3 = 'HideScope3',
  Pko = 'PKO',
  mBNP = 'mikro_bnp',
  TransportationSummary = 'TransportationSummary',
}

type TagsContainer = {
  tags: string[];
};

export const hasTag = (
  organization: TagsContainer,
  tag: OrganizationTag
): boolean => {
  return organization.tags.includes(tag);
};
