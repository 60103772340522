import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';
import { CbamActorInSchema } from '../types';
import { TFunction } from 'react-i18next';

export type FormErrors = SchemaErrors<CbamActorInSchema>;

export const requiredFields: (keyof CbamActorInSchema)[] = [
  'identifier',
  'name',
  'country',
  'city',
];

export const validateForm = (
  data: CbamActorInSchema,
  t: TFunction
): FormErrors => {
  const builder = new ErrorBuilder(data);
  return builder.checkTruthy(requiredFields, t('validation.required')).build();
};
