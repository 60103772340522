import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import themeColors from '../../../utils/theme';

/* eslint-disable no-unused-vars */

export const PageContainer = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 50px;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
  padding-top: 10px;
  padding-bottom: 150px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0 25px 0;

  button {
    border: 0;
    background-color: #fff;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    color: #606462;
    font-weight: 700;
    font-size: 18px;
    line-height: 48px;
    padding: 0;
  }
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d9dfdd;
  border-radius: 28px;
  padding: 8px 30px 8px 10px;
  margin-left: 0;
  margin-right: auto;
  cursor: pointer;
  > span {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: #388276;
  }
  > img {
    height: 12px;
    width: 7.41px;
    margin-right: 20px;
  }
  &:hover {
    transition: background-color 0.3s ease-in-out;
    background-color: #f9f9f9;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.03em;
  color: #132c1d;
  margin: 40px 0;
  & > .important-title {
    color: red;
  }
`;

export const SectionGroupContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 0 64px;
  & > div {
    margin-bottom: 10px;
  }
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 18px;
  color: ${themeColors.grayIcons};
  line-height: 28px;
`;

export const Reminder = styled.p`
  font-weight: 600;
  color: ${themeColors.redParagraphs};
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
`;

export const StyledTh = styled.div<{
  width: number;
  lastCallCustom?: boolean;
  numberOfIcons?: 'two' | 'three';
}>`
  background-color: #606462;
  border-left: 6px solid #fff;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  min-height: 57px;
  padding: 5px 10px 5px 11px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  width: ${({ width }) => width}%;

  &:first-child {
    border-left: 6px solid #606462;
  }

  &:last-child {
    border-left: 6px solid white;
    background-color: #606462;
    width: ${({ numberOfIcons }) =>
      numberOfIcons === 'three' ? '192px' : '128px'};
    min-width: ${({ numberOfIcons }) =>
      numberOfIcons === 'three' ? '192px' : '128px'};
  }
`;

export const StyledHeader = styled.div`
  display: flex;
`;

export const PreviewBox = styled.div`
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #dddddd;
  padding: 24px 24px 4px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const StyledTd = styled(
  ({
    color,
    width,
    lastCallCustom,
    numberOfIcons,
    ...props
  }: {
    color: string;
    width: number;
    lastCallCustom?: boolean;
    numberOfIcons?: 'two' | 'three';
  } & HTMLAttributes<HTMLDivElement>) => <div {...props} />
)`
  border-left: 6px solid ${({ color }) => color};
  width: ${({ width }) => width}%;
  background-color: ${themeColors.hoverButton};
  min-height: 57px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  padding-left: 11px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  color: ${themeColors.dark};
  font-weight: 500;

  &:last-child {
    border-left: 6px solid ${({ color }) => color};
    background-color: ${themeColors.hoverButton};
    padding-left: ${({ numberOfIcons }) =>
      numberOfIcons === 'three' ? '16px' : '8px'};
    width: ${({ numberOfIcons }) =>
      numberOfIcons === 'three' ? '192px' : '128px'};
    min-width: ${({ numberOfIcons }) =>
      numberOfIcons === 'three' ? '192px' : '128px'};

    button {
      background-color: transparent;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }

    button:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    button:active {
      background: rgba(0, 0, 0, 0.08);
    }

    svg {
      display: block;
      width: 48px;
      height: 48px;
    }
  }

  p {
    flex-grow: 1;
  }

  & > .comment {
    position: relative;
    top: -5px;
    display: block;
    margin-left: auto;
    width: 32px;
    height: 32px;
  }
`;

export const PointsTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 16px;
`;

export const PointsWrapper = styled.div`
  display: block;
`;

export const PointsElement = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
`;

export const PointNumber = styled.span`
  display: flex;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 3px solid ${themeColors.greenBrand};
  margin-right: 14px;
  color: ${themeColors.greenBrand};
  font-weight: 600;
  font-size: 18px;
`;

export const PointText = styled.p`
  padding-bottom: 0;
  font-weight: 400;
  font-size: 18px;

  b {
    font-weight: 700;
  }

  a {
    font-weight: 600;
    color: ${themeColors.greenBrand};
    text-decoration: none;
  }
`;

export const Section = styled.div`
  margin-top: 40px;
`;

export const HintBox = styled.div`
  position: relative;
  padding: 24px;
  border: 4px solid #fdc342;
  border-radius: 16px;
  background-color: #fff;

  a {
    font-weight: 600;
    color: ${themeColors.greenBrand};
    text-decoration: none;
  }

  & > p {
    padding-right: 100px;
  }

  div {
    margin-top: 28px;

    p {
      margin-bottom: 28px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  span {
    position: absolute;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: ${themeColors.dark};
    height: 44px;
    background: #fdc342;
    border-radius: 0 16px;
    top: -4px;
    right: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
`;

export const ExampleBox = styled.div`
  position: relative;
  padding: 54px 24px 24px;
  border: 4px solid #fdc342;
  border-radius: 16px;
  background-color: #fff;
  margin-bottom: 8px;

  span {
    position: absolute;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: ${themeColors.dark};
    height: 44px;
    background: #fdc342;
    border-radius: 16px 0;
    top: -4px;
    left: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }

  p {
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #2f2f2f;
  }
`;

export const HintTitle = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  padding: 0;
  margin: 0 0 25px;
  letter-spacing: 0.02em;
  color: ${themeColors.dark};
`;

export const ChecksListWrapper = styled.div`
  margin-top: 30px;
`;

export const ChecksListItem = styled.div`
  display: flex;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: 24px;
    min-width: 24px;
    margin-top: 13px;
  }
`;

export const CheckListItemTextMain = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
  letter-spacing: 0.02em;
  color: ${themeColors.dark};
  margin-bottom: 8px;
`;

export const CheckListItemText = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: ${themeColors.dark};
`;

export const SaveProductButtonWrapper = styled.div<{ twoButtons?: boolean }>`
  display: flex;
  justify-content: ${({ twoButtons }) =>
    twoButtons ? 'space-between' : 'flex-end'};
`;

const Button = css`
  height: 48px;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  border-radius: 24px;
  border: 0;
  background: ${themeColors.greenBrand};
  color: ${themeColors.pureWhite};

  &:hover {
    background: ${themeColors.greenHover};
  }

  &:active {
    background: ${themeColors.greenPressed};
  }
`;

export const SaveButton = styled.button`
  ${Button};
  padding: 0 28px;

  &:disabled {
    color: ${themeColors.grayInactive};
    background: ${themeColors.hoverButton};
  }
`;

export const AddButton = styled.button`
  ${Button};
  margin-top: 24px;
  padding: 0 36px 0 12px;

  svg {
    margin-right: 10px;
  }
`;

export const DeleteButton = styled.button`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #388276;
  padding-right: 12px;
  padding-left: 22px;

  svg {
    margin-left: 10px;
  }
`;

export const FinalProductDescription = styled.p`
  margin: 36px 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: ${themeColors.dark};
`;

export const FinalProductTable = styled.div`
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 48px;
`;

export const FinalProductTableHead = styled.div<{ columns: string }>`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: ${({ columns }) => columns};
  margin-bottom: 8px;

  div {
    background-color: #fdc342;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2f2f2f;
    padding: 5px 16px 3px;
    display: flex;
    align-items: center;
  }
`;

export const FinalProductTableRow = styled.div<{ columns: string }>`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: ${({ columns }) => columns};
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  div {
    background-color: #dddddd;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #2f2f2f;
    padding: 13px 16px 14px;
  }
`;

export const CommentTextArea = styled.textarea`
  width: 100%;
  height: 132px;
  margin-bottom: 16px;
  padding: 16px 30px;
  background: ${themeColors.hoverButton};
  border-radius: 4px;
  border: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #2f2f2f;
`;

export const AnotherButtonWrapper = styled.div`
  display: flex;
  align-content: center;
  position: relative;

  span {
    display: flex;
    align-items: center;
    margin: 8px 8px 0;
  }

  div {
    margin-top: 8px;
  }
`;
