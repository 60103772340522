import ParkIcon from '@mui/icons-material/Park';
import WaterIcon from '@mui/icons-material/Water';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import HotelIcon from '@mui/icons-material/Hotel';
import ScienceIcon from '@mui/icons-material/Science';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import ApartmentIcon from '@mui/icons-material/Apartment';

import { Stage, CategoryType, Subcategory } from './Diagram/index.types';
import { Node } from './Diagram/Node/index.types';
import { colors } from 'src/Lca/components/Button/helpers';

export const getCradlesAndGraves = (data: Stage[]) => {
  const cradles = [];
  const graves = [];
  for (const stage of data) {
    if (stage.group === 'cradle') cradles.push(stage);
    if (stage.group === 'grave') graves.push(stage);
  }
  return { cradles, graves };
};

export const lifeCyclePhases = [];

export const categorySettings: { [key: string]: CategoryType } = {
  'forestry-and-wood': { colors: colors['forestry-and-wood'], icon: ParkIcon },
  'water-supply': { colors: colors['water-supply'], icon: WaterIcon },
  'transport-and-machines': {
    colors: colors['transport-and-machines'],
    icon: LocalShippingIcon,
  },
  'land-plants-and-animals': {
    colors: colors['land-plants-and-animals'],
    icon: AgricultureIcon,
  },
  energy: { colors: colors.energy, icon: ElectricalServicesIcon },
  'tourism-services': { colors: colors['tourism-services'], icon: HotelIcon },
  'chemicals-and-plastics': {
    colors: colors['chemicals-and-plastics'],
    icon: ScienceIcon,
  },
  fuels: { colors: colors.fuels, icon: LocalGasStationIcon },
  'waste-management-and-recycling': {
    colors: colors['waste-management-and-recycling'],
    icon: DeleteIcon,
  },
  textiles: { colors: colors.textiles, icon: CheckroomIcon },
  'pulp-and-paper': {
    colors: colors['pulp-and-paper'],
    icon: ReceiptLongOutlinedIcon,
  },
  metals: { colors: colors.metals, icon: SettingsIcon },
  'building-and-construction': {
    colors: colors['building-and-construction'],
    icon: ApartmentIcon,
  },
};

export const getFlatNodes = (nodes: Node[], stageKey: string) => {
  let _nodes: Node[] = [];
  for (const node of nodes) {
    _nodes.push({ ...node, stage: stageKey, children: [] });
    if (node.children?.length) {
      _nodes = [..._nodes, ...getFlatNodes(node.children, stageKey)];
    }
  }
  return _nodes;
};

export const getUniqueSubcategoryGroups = (subcategories: Subcategory[]) => {
  const uniqueGroups: string[] = [];
  subcategories.forEach((obj) => {
    if (!uniqueGroups.includes(obj.group)) uniqueGroups.push(obj.group);
  });
  return uniqueGroups;
};
