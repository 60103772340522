import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import BasePage from '../Base';
import { ScopeDescriptionDataType } from './types';
import { PDF_HEIGHT, PDF_WIDTH } from '../../config';

const styles = StyleSheet.create({
  textContainer: {
    position: 'absolute',
    top: 486,
    left: 64,
    width: PDF_WIDTH - 2 * 64,
    heigth: PDF_HEIGHT - 488,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  header: {
    color: '#165D5C',
    fontFamily: 'Poppins',
    fontSize: 46,
    fontStyle: 'bold',
  },
  text: {
    color: '#606462',
    paddingTop: 10,
    fontFamily: 'Poppins',
    fontSize: 26,
    fontStyle: 'normal',
  },
});
type ScopeDescriptionProps = {
  data: {
    scope_description: ScopeDescriptionDataType;
  };
};
const ScopeDescriptionPage = ({ data }: ScopeDescriptionProps) => {
  const {
    top_right: topRight,
    scope_1_header: scope1Header,
    scope_1_text: scope1Text,
    scope_2_header: scope2Header,
    scope_2_text: scope2Text,
    scope_3_header: scope3Header,
    scope_3_text: scope3Text,
  } = data.scope_description;

  return (
    <BasePage
      backgroundImageName="Report_envirly_slide_6.png"
      topRight={topRight}
    >
      <View style={styles.textContainer}>
        <View style={{ width: 500 }}>
          <Text style={styles.header}>{scope1Header}</Text>
          <Text style={styles.text}>{scope1Text}</Text>
        </View>

        <View style={{ width: 65 }} />

        <View style={{ width: 518 }}>
          <Text style={styles.header}>{scope2Header}</Text>
          <Text style={styles.text}>{scope2Text}</Text>
        </View>

        <View style={{ width: 60 }} />

        <View style={{ width: 700 }}>
          <Text style={styles.header}>{scope3Header}</Text>
          <Text style={styles.text}>{scope3Text}</Text>
        </View>
      </View>
    </BasePage>
  );
};

export default ScopeDescriptionPage;
