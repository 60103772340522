import React from 'react';
import { useTranslation } from 'react-i18next';
import { Download } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

import Title from 'src/components/Title';
import { timeConverter } from 'src/utils/timeConverter';
import IntuitiveCalendar from 'src/views/components/IntuitiveCalendar';
import QuestionnaireTitle from 'src/views/components/Questionnaire/Title';
import {
  CopyWrapper,
  PageContainer,
  SectionGroupContainer,
  StyledHeader,
  StyledTd,
  StyledTh,
} from 'src/views/Pages/Questionnaire/index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import { useGhgOrganization } from '../../common/hooks';
import { getColorFromNumber } from '../utils';
import { TransportationEntry, TransportationSummarySchema } from './types';
import { StyledSection, StyledTitle } from '../components/Table/index.styles';
import { formatNumberWithPrecision } from '../../utils/helpers';
import { useTransportationSummary } from './hooks';
import PageLoading from '../../components/PageLoading';

type TableProps = {
  data?: TransportationSummarySchema;
};

const formatNumber = (
  value: number | null | undefined,
  precision: number = 2
) => {
  if (value == null) {
    return '-';
  }
  return formatNumberWithPrecision(value, precision);
};

function Table(props: TableProps) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ghg.scope3TransportationSummary',
  });

  const renderRow = (idx: number, row: TransportationEntry) => {
    const color = getColorFromNumber(idx);
    return (
      <StyledHeader key={idx}>
        <StyledTd width={13} color={color}>
          {row.period}
        </StyledTd>
        <StyledTd width={12} color={color}>
          {row.direction}
        </StyledTd>
        <StyledTd width={25} color={color}>
          {row.comment}
        </StyledTd>
        <StyledTd width={15} color={color}>
          {formatNumber(row.km_tonnes)}
        </StyledTd>
        <StyledTd width={15} color={color}>
          {formatNumber(row.emission_kg_eq_co2)}
        </StyledTd>
        <StyledTd width={20} color={color}>
          {formatNumber(row.emission_kg_eq_co2_per_km_tonnes, 5)}
        </StyledTd>
        {/* there's a weird last-child styling that ruins everything so add an empty div */}
        <div />
      </StyledHeader>
    );
  };

  return (
    <div>
      <StyledHeader>
        <StyledTh width={13}>{t('tableColumns.period')}</StyledTh>
        <StyledTh width={12}>{t('tableColumns.direction')}</StyledTh>
        <StyledTh width={25}>{t('tableColumns.comment')}</StyledTh>
        <StyledTh width={15}>{t('tableColumns.tonKm')}</StyledTh>
        <StyledTh width={15}>{t('tableColumns.emission')}</StyledTh>
        <StyledTh width={20}>{t('tableColumns.emissionPerTonKm')}</StyledTh>
        <div />
      </StyledHeader>
      {props.data === undefined && <PageLoading />}
      {props.data?.items.map((row, idx) => renderRow(idx, row))}
    </div>
  );
}

export default function TransportationSummary() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ghg.scope3TransportationSummary',
  });
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const [startTime, setStartTime] = React.useState<Date | null>(null);
  const [endTime, setEndTime] = React.useState<Date | null>(null);

  const { query, downloadCsv } = useTransportationSummary({
    startTime,
    endTime,
  });

  const periodRangeStr =
    startTime && endTime
      ? `${timeConverter(startTime, reportingPeriod)} - ${timeConverter(
          endTime,
          reportingPeriod
        )}`
      : t('anyPeriod');

  return (
    <PageContainer>
      <SectionGroupContainer>
        <StyledFlex>
          <QuestionnaireTitle info text={t('title')} />
        </StyledFlex>
        <Title title={t('selectPeriod')} description={null} />
        <IntuitiveCalendar
          reportingPeriod={reportingPeriod}
          startDate={startTime}
          setStartDate={setStartTime}
          endDate={endTime}
          setEndDate={setEndTime}
        />
        <Typography variant="body2" color="textSecondary">
          {t('periodRange', {
            range: periodRangeStr,
          })}
        </Typography>
        <div style={{ minHeight: '500px', minWidth: '900px' }}>
          <CopyWrapper>
            <StyledTitle>{t('tableTitle')}</StyledTitle>
            <Button variant="contained" onClick={downloadCsv}>
              <Download />
              {t('download')}
            </Button>
          </CopyWrapper>
          <StyledSection>
            <Table data={query.data} />
            <br />
            <Typography variant="body2" color="textSecondary">
              {t('totalEmission', {
                value: formatNumber(query.data?.total_kg_co2_eq),
              })}
            </Typography>
          </StyledSection>
        </div>
      </SectionGroupContainer>
    </PageContainer>
  );
}
