import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../views/Pages/ESG/create/closeIcon';

export type VideoType = 'general' | 'mbnp';

type LocalizedVideoId = string | { [lang: string]: string };
type LocalizedVideoIdMap = { [key in VideoType]: LocalizedVideoId };

const videoIds: LocalizedVideoIdMap = {
  general: { en: '846953577', pl: '810148436' },
  mbnp: '934727699',
};

const getVideoId = (videoType: VideoType, lang: string) => {
  const videoId = videoIds[videoType];
  if (typeof videoId === 'string') {
    return videoId;
  }
  return videoId[lang] || videoId.en;
};

export interface VideoTutorialProps {
  videoType: VideoType;
  open: boolean;
  onClose?: () => void;
}

export default function VideoTutorial(props: VideoTutorialProps) {
  const { t, i18n } = useTranslation();
  const tutorialVidId = getVideoId(
    props.videoType,
    i18n.language.toLowerCase()
  );
  const vimeoUrl = `https://player.vimeo.com/video/${tutorialVidId}?h=633a53ba7d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`;

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="lg">
      <IconButton
        sx={{
          position: 'absolute',
          right: '10px',
          top: '10px',
          fontSize: '24px',
        }}
        onClick={props.onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>{t('tutorial.video.header')}</DialogTitle>
      <DialogContent>
        <Typography>{t('tutorial.video.desc')}</Typography>
        <iframe
          src={vimeoUrl}
          width="826"
          height="465"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Envirly"
        />
      </DialogContent>
    </Dialog>
  );
}
