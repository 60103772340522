import React from 'react';
import styled, { css } from 'styled-components';

const WrapperStyle = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding-top: 25%;
  z-index: 100;
`;

export const Container = styled.div`
  ${WrapperStyle};
  z-index: 101;
`;

const Wrapper = styled.div`
  ${WrapperStyle};
  display: flex;

  img {
    animation-duration: 1s;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: block;
    margin: 0 auto;
    height: 100px;
    width: 100px;

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export default function Loader() {
  return (
    <Wrapper>
      <img src="/Loading.png" alt="" />
    </Wrapper>
  );
}
