import React, { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
  CloseIcon,
  StyledDialog,
  StyledDialogContentText,
  SelectDateText,
  GenerateReportButtonWrapper,
  GenerateReportButton,
} from './index.styles';
import IntuitiveCalendar from '../IntuitiveCalendar';
import PDFReports from '../../Report/Pdf';
import { ReportingFreq } from '../../../redux-file/interfaces';

type RaportPopupProps = {
  organizationIds: number[];
  reportingFreq: ReportingFreq;
  opened: boolean;
  setOpened: Function;
};

export default function RaportPopup({
  opened,
  setOpened,
  organizationIds,
  reportingFreq,
}: RaportPopupProps) {
  const [reportStartTime, setReportStartTime] = useState<Date | null>(null);
  const [reportEndTime, setReportEndTime] = useState<Date | null>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [page, setPage] = useState(1);

  const handleClose = () => {
    setOpened(false);
    setPage(1);
    setReportStartTime(null);
    setReportEndTime(null);
  };

  const validateReportRequest = () => {
    if (!reportStartTime || !reportEndTime) {
      return false;
    }
    return true;
  };

  const handleGenerateReport = () => {
    if (validateReportRequest()) setPage(2);
  };

  return (
    <StyledDialog
      fullScreen={fullScreen}
      open={opened}
      aria-labelledby="responsive-dialog-title"
      onClose={handleClose}
      maxWidth="lg"
    >
      <CloseIcon onClick={handleClose}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_783_76795)">
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#2F2F2F"
            />
          </g>
          <defs>
            <clipPath id="clip0_783_76795">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </CloseIcon>
      <DialogContent>
        {page === 1 && (
          <>
            <SelectDateText>{t('raport.date')}</SelectDateText>
            <IntuitiveCalendar
              reportingPeriod={reportingFreq}
              startDate={reportStartTime}
              setStartDate={setReportStartTime}
              endDate={reportEndTime}
              setEndDate={setReportEndTime}
            />
            <GenerateReportButtonWrapper>
              <GenerateReportButton
                disabled={!reportStartTime || !reportEndTime}
                onClick={handleGenerateReport}
              >
                {t('raport.generate')}
              </GenerateReportButton>
            </GenerateReportButtonWrapper>
          </>
        )}
        {page === 2 && (
          <StyledDialogContentText>
            <PDFReports.GreenhouseGasEmissions
              organizationIds={organizationIds}
              reportStartTime={reportStartTime}
              reportEndTime={reportEndTime}
              reportingFreq={reportingFreq}
            />
          </StyledDialogContentText>
        )}
      </DialogContent>
    </StyledDialog>
  );
}
