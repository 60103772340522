import {
  Checkbox,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { OrganizationSchema } from 'src/utils/api.interfaces';
import { usePlatformNavigation } from 'src/utils/navigation';
import TextWithTooltip from 'src/components/TextWithTooltip';
import { useAppDispatch, useAppSelector } from 'src/redux-file/hooks';
import { setSelectedCompanies } from 'src/redux-file/ghgSuperdashboard/slice';

interface Props {
  organization: OrganizationSchema;
  depth?: number;
}

const getNestedOrgIds = (
  organizations: OrganizationSchema[],
  orgId: number
): number[] => [
  orgId,
  ...organizations
    .filter((o) => o.parent_id === orgId)
    .flatMap((o: OrganizationSchema) => getNestedOrgIds(organizations, o.id)),
];

export default function OrganizationsCheckboxNode(props: Props) {
  const { organization, depth = 0 } = props;
  const { productOrganizations } = usePlatformNavigation();
  const { selectedCompanies } = useAppSelector((s) => s.ghgSuperdashboard);
  const dispatch = useAppDispatch();

  const organizations = productOrganizations.filter(
    (org) => org.parent_id === organization.id
  );

  const isChecked = selectedCompanies.includes(organization.id);

  const handleCheckboxClick = () => {
    const newOrgIds = getNestedOrgIds(productOrganizations, organization.id);
    const excludes = selectedCompanies.filter((id) => !newOrgIds.includes(id));
    const newCompaniesIds = isChecked ? excludes : [...newOrgIds, ...excludes];
    dispatch(setSelectedCompanies(newCompaniesIds));
  };

  return (
    <List sx={{ p: 0 }}>
      <MenuItem
        sx={{
          p: '0px',
          pl: `${depth * 14}px`,
        }}
        onClick={handleCheckboxClick}
      >
        <ListItemIcon>
          <Checkbox
            sx={{ p: '6px', color: '#fff' }}
            color="secondary"
            checked={isChecked}
          />
        </ListItemIcon>
        <ListItemText sx={{ textWrap: 'wrap' }}>
          <TextWithTooltip minLength={30} text={organization.name} />
        </ListItemText>
      </MenuItem>

      {organizations.map((org) => (
        <OrganizationsCheckboxNode
          depth={depth + 1}
          key={org.id}
          organization={org}
        />
      ))}
    </List>
  );
}
