import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useEsgOrganization } from 'src/common/hooks';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';
import { StyledPanel } from 'src/Esg/styles';
import NoData from 'src/components/NoData';
import { useEsgStakeholderAnalytics } from '../hooks';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { useQueryClient } from 'react-query';
import Qv3SummaryTable from 'src/views/components/QuestionnaireV3/Summary/SummaryTable';
import { Qv3SummaryTableType } from 'src/views/components/QuestionnaireV3/Summary/types';
import { toast } from 'react-toastify';

const T_KEY = 'esg.dma.stakeholderAnalytics.tables';
const COLUMNS = ['subtopic', 'question', 'answer'];

export default function Individual() {
  const { t } = useTranslation();
  const { reportId } = useParams();
  const { id: organizationId } = useEsgOrganization();

  const [
    deleteStakeholderAnswerConfirmation,
    setDeleteStakeholderAnswerConfirmation,
  ] = React.useState<number | null>(null);

  const { queryKey, data, isError, deleteAnswer } = useEsgStakeholderAnalytics(
    organizationId,
    reportId as string
  );

  const queryClient = useQueryClient();

  const handleDeleteQuestionAnswer = () =>
    deleteStakeholderAnswerConfirmation !== null &&
    deleteAnswer.mutateAsync(deleteStakeholderAnswerConfirmation).then(() => {
      setDeleteStakeholderAnswerConfirmation(null);
      queryClient.invalidateQueries({ queryKey });
      toast.success(t('questionnaireV3.summary.success') as string);
    });

  if (isError) return <SomethingWentWrong />;
  if (data === undefined) return <PageLoading />;
  if (Object.keys(data).length === 0) return <NoData />;

  return (
    <StyledPanel sx={{ minWidth: '940px' }}>
      <Qv3SummaryTable
        data={data}
        tableType={Qv3SummaryTableType.IndividualByField}
        customColumns={COLUMNS.map((col) => t(`${T_KEY}.columns.${col}`))}
        onTableDelete={setDeleteStakeholderAnswerConfirmation}
      />
      <ConfirmationModal
        open={!!deleteStakeholderAnswerConfirmation}
        onClose={() => setDeleteStakeholderAnswerConfirmation(null)}
        onFalse={() => setDeleteStakeholderAnswerConfirmation(null)}
        textKey="esg.dma.stakeholderAnalytics.tables.deleteConfirmationText"
        titleKey="esg.dma.stakeholderAnalytics.tables.deleteConfirmationTitle"
        onTrue={handleDeleteQuestionAnswer}
      />
    </StyledPanel>
  );
}
