import React from 'react';

export default function Co2Icon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 11.3281C0 10.3842 0.187086 9.5447 0.561259 8.80957C0.944134 8.06536 1.48364 7.48905 2.17977 7.08064C2.87591 6.66316 3.67647 6.45442 4.58144 6.45442C5.73007 6.45442 6.69596 6.77661 7.47911 7.42098C8.26226 8.05628 8.76696 8.92301 8.9932 10.0212H6.23912C6.07378 9.65814 5.84319 9.38134 5.54733 9.19075C5.26018 9.00016 4.92516 8.90486 4.54229 8.90486C3.95057 8.90486 3.47633 9.12722 3.11956 9.57193C2.77149 10.0076 2.59746 10.5929 2.59746 11.3281C2.59746 12.0723 2.77149 12.6667 3.11956 13.1115C3.47633 13.5471 3.95057 13.7649 4.54229 13.7649C4.92516 13.7649 5.26018 13.6696 5.54733 13.479C5.84319 13.2884 6.07378 13.0116 6.23912 12.6486H8.9932C8.76696 13.7468 8.26226 14.618 7.47911 15.2624C6.69596 15.8977 5.73007 16.2153 4.58144 16.2153C3.67647 16.2153 2.87591 16.0111 2.17977 15.6027C1.48364 15.1853 0.944134 14.6089 0.561259 13.8738C0.187086 13.1296 0 12.281 0 11.3281Z"
        fill="currentColor"
      />
      <path
        d="M14.6164 16.2426C13.7549 16.2426 12.9631 16.0338 12.2408 15.6164C11.5186 15.1898 10.9443 14.6044 10.5179 13.8602C10.1002 13.1069 9.89138 12.2583 9.89138 11.3145C9.89138 10.3706 10.1002 9.52655 10.5179 8.78234C10.9443 8.02905 11.5186 7.44367 12.2408 7.02619C12.9631 6.6087 13.7549 6.39996 14.6164 6.39996C15.4866 6.39996 16.2784 6.6087 16.992 7.02619C17.7142 7.44367 18.2842 8.02905 18.7019 8.78234C19.1195 9.52655 19.3284 10.3706 19.3284 11.3145C19.3284 12.2583 19.1195 13.1069 18.7019 13.8602C18.2842 14.6044 17.7142 15.1898 16.992 15.6164C16.2697 16.0338 15.4779 16.2426 14.6164 16.2426ZM14.6164 13.7649C15.269 13.7649 15.7824 13.5426 16.1566 13.0978C16.5395 12.6531 16.7309 12.0587 16.7309 11.3145C16.7309 10.5521 16.5395 9.95311 16.1566 9.51747C15.7824 9.07276 15.269 8.85041 14.6164 8.85041C13.9551 8.85041 13.4373 9.07276 13.0632 9.51747C12.689 9.95311 12.5019 10.5521 12.5019 11.3145C12.5019 12.0677 12.689 12.6667 13.0632 13.1115C13.4373 13.5471 13.9551 13.7649 14.6164 13.7649Z"
        fill="currentColor"
      />
      <path
        d="M20.0059 17.4174C20.5124 17.0036 20.9274 16.6469 21.2511 16.3474C21.5748 16.0479 21.8463 15.7402 22.0656 15.4244C22.2849 15.1031 22.3945 14.7982 22.3945 14.5096C22.3945 14.3353 22.3554 14.1992 22.2771 14.1012C22.204 14.0031 22.0943 13.9541 21.9481 13.9541C21.7967 13.9541 21.6793 14.0222 21.5957 14.1583C21.5122 14.289 21.473 14.4823 21.4782 14.7383H19.9903C20.0059 14.2536 20.1077 13.8534 20.2957 13.5376C20.4836 13.2163 20.729 12.9821 21.0319 12.8351C21.3347 12.6826 21.6714 12.6064 22.0421 12.6064C22.6843 12.6064 23.162 12.7725 23.4753 13.1046C23.7885 13.4368 23.9452 13.867 23.9452 14.3952C23.9452 14.9615 23.7624 15.4925 23.397 15.988C23.0367 16.4835 22.5851 16.9273 22.0421 17.3194H24V18.6181H20.0059V17.4174Z"
        fill="currentColor"
      />
    </svg>
  );
}
