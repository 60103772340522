import { styled as styledMUI } from '@mui/material/styles';

// eslint-disable-next-line import/prefer-default-export
export const StyledButton = styledMUI('button')`
background-color: #FFF7E8;
font-family: Poppins;
font-size: 16px;
padding: 10px 20px;
font-weight: 600; 
border-radius: 24px; 
cursor: pointer;
color: inherit;
display: flex;
justify-content: center;
align-items: center;
border: 3px solid #BABABA;
:hover {
  background-color: #F6F6F6;
}
:active {
  background-color: #F6F5F3;
}
`;
