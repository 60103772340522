import React from 'react';
import SurveyContainer, {
  SurveyContainerProps,
} from 'src/components/Survey/Container';
import { Esg, Logo } from './icons';

type ContainerProps = Omit<SurveyContainerProps, 'logo'>;

function EsgLogo() {
  return (
    <>
      <Logo />
      <Esg />
    </>
  );
}

export default function Container(props: ContainerProps) {
  return <SurveyContainer {...props} logo={<EsgLogo />} />;
}
