export default {
  // data comes not in camelCase as it comes from python-backend
  // its converted to camelCase within direct components
  introduction: {
    cover: {
      company_name: '',
      report_title: '',
      report_range: '',
    },
    how_why: {
      top_right: '',
      main_text_header: '',
      main_text: '',
      why_text_header: '',
      why_text: '',
      how_text_header: '',
      how_text: '',
    },
    methodology: {
      top_right: '',
      main_header: '',
      sub_header: '',
      text_p1: '',
      text_p2: '',
      text_p3: '',
    },
    scope_visualisation: {
      scope_1_main_header: '',
      scope_1_subheader: '',
      scope_2_main_header: '',
      scope_2_subheader: '',
      scope_3_main_header: '',
      scope_3_subheader_upstream: '',
      scope_3_subheader_downstream: '',
    },
    scope_description: {
      top_right: '',
      scope_1_header: '',
      scope_1_text: '',
      scope_2_header: '',
      scope_2_text: '',
      scope_3_header: '',
      scope_3_text: '',
    },
  },
  carbon_footprint: {
    cover: { section_title: '' },
    main_metrics: {
      page_title: '',
      top_right: '',
      metrics: [],
      helper_text_1: '',
      helper_text_2: '',
    },
    carbon_footprint_timeline: {
      chart: '',
      page_title: '',
      top_right: '',
    },
    carbon_footprint_table: {
      top_right: '',
      page_title: '',
      table_data: [
        // array of tables (multi table pages)
        {
          columns: [],
          data: [],
        },
      ],
    },
    total_emissions_by_scope_pie_chart: {
      page_title: '',
      top_right: '',
      chart: '',
    },
    total_emissions_by_scope_table: {
      top_right: '',
      page_title: '',
      table_data: [
        // array of tables (multi table pages)
        {
          columns: [],
          data: [],
        },
      ],
    },
    emission_ranking_scope_3_pie_chart: {
      page_title: '',
      top_right: '',
      chart: '',
    },
    goods_and_services_emissions_chart: {
      page_title: '',
      top_right: '',
      chart: '',
    },
  },
  employee_emissions: {
    cover: {
      section_title: '',
      report_range: '',
      description: '',
      chart_text: '',
      employee_surveys_pct_chart: '',
    },
    employee_emissions_and_means_of_transport: {
      top_right: '',
      employee_emissions_chart_title: '',
      employee_emissions_chart: '',
      employees_means_of_transport_chart_title: '',
      employees_means_of_transport_chart: '',
    },
    business_travel_emissions_map: {
      page_title: '',
      chart: '',
      top_right: '',
    },
    business_travel_emissions_table: {
      page_title: '',
      top_right: '',
      table_data: [
        // array of tables (multi table pages)
        {
          columns: [],
          data: [],
        },
      ],
    },
  },
  about_envirly: {
    cover: { section_title: '' },
    our_mission: {
      main_header: '',
      sub_header: '',
      text: '',
      top_right: '',
    },
    methodology: {
      page_title: '',
      text: '',
    },
    emission_data_sources: {
      page_title: '',
      top_right: '',
      pre_header: '',
      text_1: '',
      text_2: '',
      source_link_text: '',
      source_link: '',
    },
    contact: {
      contact_us: '',
      telephone_1: '',
      telephone_2: '',
      email: '',
      website: '',
      linkedin: '',
    },
  },
};
