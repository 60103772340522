import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableCell = styled(TableCell)`
  max-width: 300px;
  min-width: 300px;
  font-family: 'Poppins';
  vertical-align: top;
`;

export const StyledTableStickyCell = styled(StyledTableCell)`
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 2;
`;
