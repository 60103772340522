import React from 'react';

import {
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { StyledFlex } from 'src/components/StyledFlex';
import { StyledPanel } from '../../index.styles';
import { getYearPages } from './unit-utils';

interface Props {
  startYear: number;
  endYear: number;
  selectedYear: number;
  // eslint-disable-next-line no-unused-vars
  onYearChange: (year: number) => void;
  availableYears?: number[];
}

export default function YearSelector(props: Props) {
  const { startYear, endYear, selectedYear, onYearChange, availableYears } =
    props;
  const pages = getYearPages(startYear, endYear);
  const initialPage = Math.floor((selectedYear - startYear) / 4);
  const [page, setPage] = React.useState(initialPage);

  const handlePrevClick = () => page > 0 && setPage(page - 1);
  const handleNextClick = () =>
    page < Object.keys(pages).length - 1 && setPage(page + 1);

  const yearKey = Object.keys(pages)[page];
  if (!yearKey) return null;

  return (
    <StyledPanel sx={{ mt: '32px', p: '0px' }}>
      <StyledFlex sx={{ p: '12px 8px' }}>
        <IconButton onClick={handlePrevClick}>
          <ChevronLeftIcon />
        </IconButton>

        <Typography
          component="div"
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '34px',
          }}
        >
          {yearKey}
        </Typography>

        <IconButton onClick={handleNextClick}>
          <ChevronRightIcon />
        </IconButton>
      </StyledFlex>
      <Divider />
      <ButtonGroup fullWidth variant="text">
        {Array.from({ length: 4 }, (v, i) => {
          const value = pages[yearKey][i];
          const variant = value === selectedYear ? 'contained' : 'text';
          return (
            <Button
              key={i}
              sx={{
                borderRadius: '16px',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
              onClick={() => onYearChange(value)}
              variant={variant}
              disabled={
                !value || (availableYears && !availableYears.includes(value))
              }
            >
              {value || ' - '}
            </Button>
          );
        })}
      </ButtonGroup>
    </StyledPanel>
  );
}
