import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { client } from '../../../utils/api-client';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery } from 'react-query';
import PageLoading from 'src/components/PageLoading';

type Consent = {
  key: string;
  content: string;
  accepted: boolean;
  required: boolean;
};

type Consents = {
  consents: Consent[];
  are_valid: boolean;
};

const URL = '/web/users/me/consents';

export function useConsentsQuery() {
  const query = useQuery('consents', () =>
    client.get<Consents>(URL).then((response) => {
      return response.data;
    })
  );
  const areConsentsValid = query.data?.are_valid;
  return { ...query, areConsentsValid };
}

export default function CollectConsentsFormModal() {
  const { t } = useTranslation();
  const { data, isLoading, refetch } = useConsentsQuery();
  const [consents, setConsents] = useState<Consent[] | null>(null);

  useEffect(() => {
    if (data) {
      setConsents(data.consents);
    }
  }, [data]);

  const areAllRequiredConsentsAccepted =
    consents && consents.every((c) => c.accepted || !c.required);
  const submitMutation = useMutation({
    mutationFn: (consents_: Consent[]) => client.post(URL, consents_),
    onSuccess: () => refetch(),
  });

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <Dialog open maxWidth="md">
      <DialogTitle>{t('userConsents.title')}</DialogTitle>
      <DialogContent>
        <FormGroup>
          {consents?.map((consent: Consent) => (
            <FormControlLabel
              key={consent.key}
              label={
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: consent.content || consent.key,
                  }}
                />
              }
              control={
                <Checkbox
                  sx={{ width: '48px', height: '48px', alignSelf: 'start' }}
                  checked={consent.accepted}
                  onChange={(event) => {
                    const { checked } = event.target;
                    setConsents(
                      consents.map((c) =>
                        c.key === consent.key ? { ...c, accepted: checked } : c
                      )
                    );
                  }}
                />
              }
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          endIcon={<KeyboardArrowRightIcon />}
          disabled={submitMutation.isLoading || !areAllRequiredConsentsAccepted}
          onClick={() => consents !== null && submitMutation.mutate(consents)}
        >
          {t('userConsents.button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
