import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const LogosContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

export const Title = styled(Typography)`
  font-size: 32px;
  font-weight: Bold;
  margin-bottom: 20px;
`;

export const Subtitle = styled(Typography)`
  font-size: 18px;
  color: #606462;
  line-height: 28px;
  margin-bottom: 50px;
`;
