import { Box, Button, Fade, Popper } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubTitleText } from 'src/Esg/Dma/Stakeholders/StakeholdersAnalytics/Dashboard/StakeholdersImpactTable/index.styles';
import {
  StyledPopperPaper,
  StyledTextPopper,
  StyledTitlePopper,
} from './index.styles';
import InfoIcon from '@mui/icons-material/Info';

interface Props {
  placeholder: string | null | undefined;
}

export default function SampleAnswer(props: Props) {
  const { t } = useTranslation();
  const { placeholder } = props;
  const anchorToolsEl = React.useRef(null);
  const [isPopperOpen, setIsPopperOpen] = React.useState(false);

  if (!placeholder) return null;

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginBottom: '10px',
        }}
      >
        <Button
          ref={anchorToolsEl}
          sx={{
            padding: 0,
            '&:hover': {
              textDecoration: 'underline',
              color: '#38414f',
            },
          }}
          startIcon={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <InfoIcon sx={{ color: '#38414f', fontSize: '18px' }} />
            </Box>
          }
          onClick={() => setIsPopperOpen((prev) => !prev)}
        >
          <SubTitleText>{t('questionnaireV3.sampleAnswer')}</SubTitleText>
        </Button>
      </div>

      <Popper
        open={isPopperOpen}
        anchorEl={anchorToolsEl.current}
        transition
        placement="bottom-end"
        sx={{ zIndex: 999 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={250}>
            <StyledPopperPaper>
              <StyledTitlePopper>
                {t('questionnaireV3.sampleAnswer')}
              </StyledTitlePopper>
              <StyledTextPopper>{placeholder} </StyledTextPopper>
            </StyledPopperPaper>
          </Fade>
        )}
      </Popper>
    </>
  );
}
