import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import deleteIcon from '../../../../images/delete.png';
import { useTranslation } from 'react-i18next';
import {
  FormWrapper,
  StyledRadio,
  StyledFormLabel,
  StyledFormControlLabel,
  StyledFormControl,
  InputWrapper,
  BottomHorizontalLine,
  Icon,
} from '../index.styles';
import { businessTripData } from '../data';

export default function BusinessTripsForms({
  idx,
  setForms,
  forms,
}: {
  idx: number;
  setForms: Function;
  forms: any;
}) {
  const { t } = useTranslation();

  const handleCommutingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newForms = [...forms];
    const commutingType = newForms[idx];
    commutingType.type_of_trip_transport = (
      event.target as HTMLInputElement
    ).value;
    setForms(newForms);
  };

  const setCommutingTimePerWeek = (value: string) => {
    const newForms = [...forms];
    const commutingType = newForms[idx];
    commutingType.times_in_period = value;
    setForms(newForms);
  };

  const setCommutingAverageDistance = (value: string) => {
    const newForms = [...forms];
    const commutingType = newForms[idx];
    commutingType.average_distance_per_trip_km = value;
    setForms(newForms);
  };

  return (
    <FormWrapper>
      <StyledFormControl>
        <StyledFormLabel id="commuting-radio">
          {t('employee-survey.business-trip-label')}
        </StyledFormLabel>
        <RadioGroup
          aria-labelledby="commuting-radio"
          name="commuting-buttons-group"
          value={forms[idx].type_of_trip_transport}
          onChange={handleCommutingChange}
        >
          {businessTripData.map((el) => (
            <StyledFormControlLabel
              key={el.value}
              value={el.value}
              control={<StyledRadio />}
              label={t(`employee-survey.${el.value}`)}
            />
          ))}
        </RadioGroup>
      </StyledFormControl>

      <InputWrapper>
        <TextFieldNumber
          type="integer"
          label={t('employee-survey.times_in_period')}
          active
          error={false}
          index={1}
          value={
            forms[idx].times_in_period === null
              ? ''
              : forms[idx].times_in_period
          }
          setValue={setCommutingTimePerWeek}
        />
      </InputWrapper>
      <InputWrapper>
        <TextFieldNumber
          label={t('employee-survey.average_distance_per_trip_km')}
          active
          error={false}
          index={1}
          value={
            forms[idx].average_distance_per_trip_km === null
              ? ''
              : forms[idx].average_distance_per_trip_km
          }
          setValue={setCommutingAverageDistance}
        />
      </InputWrapper>
      {forms.length >= 2 && (
        <>
          <BottomHorizontalLine />
          <Icon
            src={deleteIcon}
            onClick={() => {
              const newForms = [...forms];
              newForms.splice(idx, 1);
              setForms(newForms);
            }}
          />
        </>
      )}
    </FormWrapper>
  );
}
