import styled from 'styled-components';
import { companiesColors } from '../../views/Pages/SuperDashboard/data';

export const StyledHeader = styled.div`
  display: flex;
`;

export const StyledTdCompany = styled.div<{ width: number }>`
  border-left: 6px solid ${companiesColors[5]};
  width: ${({ width }) => width}%;
  background-color: #f2f2f2;
  height: 57px;
  font-size: 15px;
  padding-left: 11px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  color: #2f2f2f;
  font-weight: 500;
`;

export const StyledThCompany = styled.div<{ width: number }>`
  background-color: #606462;
  border-left: 6px solid ${companiesColors[5]};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  height: 57px;
  padding-left: 11px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  width: ${({ width }) => width}%;
  margin-right: 8px;
`;
