import React from 'react';
import { useTranslation } from 'react-i18next';

import { EsgProgressBarViews as Tabs } from '../types';
import { SxProps, ToggleButton, ToggleButtonGroup } from '@mui/material';

interface Props {
  value: Tabs;
  sx?: SxProps;
}

export default function EsgAdministrationPanelToggle(props: Props) {
  const { sx = {}, value } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg.administrationPanel.toggle',
  });
  return (
    <ToggleButtonGroup fullWidth value={value} sx={sx}>
      <ToggleButton size="small" value={Tabs.LIST}>
        {t(Tabs.LIST)}
      </ToggleButton>
      <ToggleButton disabled size="small" value={Tabs.CALENDAR}>
        {t(Tabs.CALENDAR)}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
