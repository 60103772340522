import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import OneValueCheckboxField from '../../../../components/CheckboxField';
import { AverageMethodGoodData } from 'src/Ghg/Scope3GoodsAndServices/types';
import { asNumber } from '../../../../redux-file/questionnaires/thunks';
import { Overlay } from '../../../../views/components/Questionnaire/Title/index.styles';
import {
  CheckboxContainer,
  Form,
  FormBox,
} from '../../../../views/components/Questionnaire/forms/index.styles';
import {
  AutocompleteFreesolo,
  Select,
} from '../../../../views/components/Questionnaire/fields';
import FormButtons from '../../../../views/components/Questionnaire/FormButtons';
import { average_method_goods } from '../../../../views/components/Questionnaire/forms/data';
import _ from 'lodash';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from '../../../interfaces';

type FormData = any[]; // it is what it is

const validators = {
  factor: (x: any) => x !== '',
  amount: (x: any) => x !== '',
  quantity: (x: any) => x !== '',
  good: (x: any) => x !== '',
};

type FormFields = keyof typeof validators;

type FormErrors = {
  [key in FormFields]?: boolean;
};

export default function AverageMethodGoodForm({
  state,
  setState,
  id,
  active,
  setActive,
  variant,
  setNewForm,
  update,
}: {
  state: FormData;
  setState: (data: FormData) => unknown;
  id: number;
  setActive: Function;
  active: undefined | number;
  setNewForm: Function;
  update: (data: AverageMethodGoodData[]) => Promise<unknown>;
  variant: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
}) {
  const { t } = useTranslation();
  const questionVariant =
    variant === GOODS_AND_SERVICES_OR_CAPITAL_GOODS.GOODS_AND_SERVICES
      ? 'question1'
      : 'question2';

  const defineErrors = (item?: any) => {
    let toValidate: { [key in FormFields]?: any } = {};
    item ??= state[id];
    if (item.emission_factor_is_known) {
      const known = item.good_data_factor_known;
      toValidate = {
        factor: known.factor,
        amount: known.amount,
      };
    } else {
      const unknown = item.good_data_factor_unknown;
      toValidate = {
        good: unknown.good,
        quantity: unknown.quantity,
      };
    }

    const errors: FormErrors = {};
    for (const [k, v] of Object.entries(toValidate)) {
      const fieldKey = k as FormFields;
      if (!validators[fieldKey](v)) {
        errors[fieldKey] = true;
      }
    }
    setErrors(errors);
    return errors;
  };

  const [errors, setErrors] = useState<FormErrors>({});

  const updateForm = (setter: (value: any) => unknown) => {
    const newForms = [...state];
    const x = _.cloneDeep(newForms[id]);

    // default values for conditional fields
    x.good_data_factor_known ??= {
      factor: '',
      amount: '',
      unit_label: '',
    };

    x.good_data_factor_unknown ??= {
      good: '',
      quantity: '',
    };

    setter(x);
    newForms[id] = x;
    setState(newForms);
    if (Object.keys(errors).length > 0) defineErrors(x);
  };

  const handleEmissionFactor = () =>
    updateForm((x) => {
      x.emission_factor_is_known = !x.emission_factor_is_known;
    });

  const handleName = (value: string) =>
    updateForm((x) => {
      x.name_label = value;
    });

  const handleUnknownGood = (value: string) =>
    updateForm((x) => {
      x.good_data_factor_unknown.good = value;
    });

  const handleUnknownQuantity = (value: string) =>
    updateForm((x) => {
      x.good_data_factor_unknown.quantity = value;
    });

  const handleKnownUnitLabel = (value: string) =>
    updateForm((x) => {
      x.good_data_factor_known.unit_label = value;
    });

  const handleKnownAmount = (value: string) =>
    updateForm((x) => {
      x.good_data_factor_known.amount = value;
    });

  const handleKnownFactor = (value: string) =>
    updateForm((x) => {
      x.good_data_factor_known.factor = value;
    });

  const handleSend = (data: FormData) => {
    const err = defineErrors();
    if (Object.keys(err).length > 0) return;
    // cleanup models before sending them
    const items = data.map((x: any) => {
      const known = x.good_data_factor_known;
      const unknown = x.good_data_factor_unknown;
      const result: AverageMethodGoodData = {
        comment: x.comment || '',
        emission_factor_is_known: x.emission_factor_is_known,
        name_label: x.name_label || '',
        good_data_factor_known: x.emission_factor_is_known
          ? {
              factor: asNumber(known.factor)!,
              amount: asNumber(known.amount)!,
              unit_label: known.unit_label || '',
            }
          : null,
        good_data_factor_unknown: x.emission_factor_is_known
          ? null
          : {
              quantity: asNumber(unknown.quantity)!,
              good: unknown.good || '',
            },
      };
      return result;
    });
    update(items);
    setActive(undefined);
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    handleSend(tempState);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() => {
            handleSend(state);
          }}
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <CheckboxContainer>
            <OneValueCheckboxField
              label={t(
                `goods-and-services.${questionVariant}.form.i-know-factor`
              )}
              setValue={() => handleEmissionFactor()}
              value={state[id].emission_factor_is_known}
            />
          </CheckboxContainer>
          {state[id].emission_factor_is_known === false ? (
            <>
              <FormBox variant="100">
                <AutocompleteFreesolo
                  active
                  error={false}
                  index={1}
                  label={t(`goods-and-services.${questionVariant}.form.name`)}
                  value={state[id].name_label}
                  setValue={(value: string) => handleName(value)}
                  max={123}
                  options={[]}
                />
              </FormBox>
              <FormBox variant="100">
                <Select
                  active
                  error={errors.good}
                  index={2}
                  label={t(
                    `goods-and-services.${questionVariant}.form.average_good`
                  )}
                  value={state[id].good_data_factor_unknown.good}
                  setValue={(value: string) => handleUnknownGood(value)}
                  max={123}
                  options={average_method_goods}
                  sortAlphabetically
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  type="mass"
                  label={t(
                    `goods-and-services.${questionVariant}.form.quantity`
                  )}
                  active
                  error={errors.quantity}
                  index={1}
                  value={state[id].good_data_factor_unknown.quantity}
                  setValue={(e: string) => handleUnknownQuantity(e)}
                />
              </FormBox>
            </>
          ) : (
            <>
              <FormBox variant="100">
                <AutocompleteFreesolo
                  active
                  error={false}
                  index={1}
                  label={t(`goods-and-services.${questionVariant}.form.name`)}
                  value={state[id].name_label}
                  setValue={(value: string) => handleName(value)}
                  max={123}
                  options={[]}
                />
              </FormBox>
              <FormBox variant="50">
                <TextFieldNumber
                  label={t(
                    `goods-and-services.${questionVariant}.form.amount-of-emission`
                  )}
                  active
                  error={errors.amount}
                  index={1}
                  value={state[id].good_data_factor_known.amount}
                  setValue={(e: string) => handleKnownAmount(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <AutocompleteFreesolo
                  active
                  error={false}
                  index={1}
                  label={t(
                    `goods-and-services.${questionVariant}.form.unit-factor`
                  )}
                  value={state[id].good_data_factor_known.unit_label}
                  setValue={(value: string) => handleKnownUnitLabel(value)}
                  max={123}
                  options={[]}
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  type="factor"
                  label={t(`goods-and-services.${questionVariant}.form.factor`)}
                  active
                  error={errors.factor}
                  index={1}
                  value={state[id].good_data_factor_known.factor}
                  setValue={(e: string) => handleKnownFactor(e)}
                />
              </FormBox>
            </>
          )}
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={Object.entries(errors).length === 0}
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
