import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTitle = styled(Typography)`
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.34px;
  margin-left: 8px;
`;

export const StyledContainer = styled(Box)`
  color: #38414f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0px;
  border-bottom: 1px solid #e0e0e0;
`;
