import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { IconButton, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
  StyledContainer,
  StyledDescription,
  StyledPanel,
  StyledTitle,
} from '../../styles';
import Table from '../components/NonFinancialTopicsEvaluationTable';

export default function Step2TopicEvaluation() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { reportId } = useParams();
  return (
    <>
      <StyledContainer>
        <StyledTitle>
          <IconButton onClick={() => navigate(-1)}>
            <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#38414f' }} />
          </IconButton>
          {t('esg.dma.step2TopicEvaluation.title')}
        </StyledTitle>

        <StyledDescription>
          <Typography>
            <Trans i18nKey="esg.dma.step2TopicEvaluation.subtitle01" />
            <br />
            <br />
            <Trans
              i18nKey="esg.dma.step2TopicEvaluation.subtitleLearnMore"
              components={{ a: <a target="_blank" /> }}
            />
            <br />
            <Trans i18nKey="esg.dma.step2TopicEvaluation.subtitle02" />
          </Typography>
        </StyledDescription>

        <div style={{ overflowX: 'auto' }}>
          <StyledPanel
            sx={{
              p: '15px',
              pb: '30px',
              minWidth: '1500px',
            }}
          >
            <Table reportId={reportId as string} preliminary />
          </StyledPanel>
        </div>
      </StyledContainer>
      <div style={{ height: '100px' }} />
    </>
  );
}
