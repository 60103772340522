import styled from 'styled-components';

export const StyledDiagramContainer = styled('div')`
  border-radius: 25px;
  padding: 30px;
  background-color: #fff;
  margin-top: 30px;
`;

export const StyledLabel = styled('div')`
  padding: 10px 0px;
  color: #281302;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;
