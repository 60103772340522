import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../views/components/Layout';
import TopBar from '../../components/TopBar';
import EsgSidebar from '../components/Sidebar';

export default function EsgHome() {
  const { t } = useTranslation();
  return (
    <Layout sidebar={<EsgSidebar />} topbar={<TopBar />}>
      <Typography variant="h5" marginLeft="20px">
        {t('productHome.selectOrg')}
      </Typography>
    </Layout>
  );
}
