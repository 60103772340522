export const fileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',

  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/webp',

  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',

  'text/plain',
  'application/zip',
];

// TODO: refactor when we figure out how to do error handling

export enum FileUploadError {
  CONTENT_TOO_LARGE = 'CONTENT_TOO_LARGE',
  INVALID_CONTENT_TYPE = 'INVALID_CONTENT_TYPE',
  TOO_MANY_ATTACHMENTS = 'TOO_MANY_ATTACHMENTS',
  DUPLICATE_ATTACHMENT_NAME = 'DUPLICATE_ATTACHMENT_NAME',
}
