import React from 'react';

export default function RemoveIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 31C18 32.1 18.9 33 20 33H28C29.1 33 30 32.1 30 31V19H18V31ZM20 21H28V31H20V21ZM27.5 16L26.5 15H21.5L20.5 16H17V18H31V16H27.5Z"
        fill="#606462"
      />
      <rect x="22" y="23" width="1" height="6" fill="#606462" />
      <rect x="25" y="23" width="1" height="6" fill="#606462" />
    </svg>
  );
}
