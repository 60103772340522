import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

export const StyledDialogContentText = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #2f2f2f;
`;

export const StyledDialog = styledMUI(Dialog)`  
  & .MuiPaper-root {
    width: 496px;
    background: #FFFFFF;
    border: 1px solid #D9DFDD;
    border-radius: 26px;
    padding: 10px 10px 50px 10px;
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  background-color: white;
  border: 2px solid #ffffff;
  border-radius: 28px;
  padding: 7px 18px 7px 18px;
  margin-left: auto;
  margin-right: 0;
  > span,
  > a {
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.03em;
    color: black;
    text-decoration: none;
  }
  &:hover {
    transition: background-color, border 0.3s ease-in-out;
    background-color: #00000010;
  }
`;
