import styled from 'styled-components';

export const StyledContainer = styled('div')`
  display: flex;
  width: 100%;
`;

export const StyledButtonLabel = styled('div')`
  padding: 10px 0px;
  color: #281302;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 14px;
  text-transform: uppercase;
  max-width: 70px;
`;
