import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import BasePage from '../Base';
import { ContactDataType } from './types';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  container: {
    color: '#07403F',
    fontFamily: 'Poppins',
    fontSize: 30,
    fontStyle: 'bold',
    position: 'absolute',
    top: 210,
    width: 1920,
    height: 450,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  footerText: {
    position: 'absolute',
    top: 1000,
    left: 0,
    width: 1920,
    height: 70,
    color: '#fff',
    textAlign: 'center',
    fontStyle: 'bold',
    textShadow: '2px 2px black',
    fontFamily: 'Poppins',
    fontSize: 40,
    letterSpacing: 2,
  },
});

type ContactProps = {
  data: {
    contact: ContactDataType;
  };
};

const ContactUsPage = ({ data }: ContactProps) => {
  const {
    contact_us: contactUs,
    telephone_1: telephone1,
    telephone_2: telephone2,
    email,
    website,
    linkedin,
  } = data.contact;

  return (
    <BasePage backgroundImageName="Report_envirly_slide_33-1.png">
      <View>
        <View style={styles.container}>
          <Text style={{ fontStyle: 'normal' }}>{contactUs}</Text>
          <Text>{telephone1}</Text>
          <Text>{telephone2}</Text>
          <Text>{email}</Text>
          <Text>{website}</Text>
          <Text>{linkedin}</Text>
        </View>
        <Text style={styles.footerText}>Envirly Team</Text>
      </View>
    </BasePage>
  );
};

export default ContactUsPage;
