import { toast } from 'react-toastify';
import { client } from './api-client';
import { AxiosError } from 'axios';
import {
  ConfirmResetPasswordInSchema,
  CredentialsInSchema,
  LoginTokenInSchema,
  ResetPasswordOutSchema,
  SendResetPasswordCodeInSchema,
} from './api.interfaces';
import * as jwt from 'src/utils/jwt';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

type TokenPair = {
  access_token: string;
  refresh_token: string;
};

type AuthStateListener = (isAuthenticated: boolean) => void;

class AuthService {
  private _pendingRefetch: Promise<string> | null = null;
  private _listeners: AuthStateListener[] = [];
  private _lastIsAuthenticated: boolean | null = null;

  /* eslint-disable class-methods-use-this */
  private get accessToken(): string | null {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    return jwt.decodeToken(token) ? token : null;
  }

  private get refreshToken(): string | null {
    const token = localStorage.getItem(REFRESH_TOKEN_KEY);
    return jwt.decodeToken(token) ? token : null;
  }
  /* eslint-enable class-methods-use-this */

  private get isAuthenticated() {
    // we don't need to check access token here
    return !!this.refreshToken;
  }

  private async _refetchTokenPair() {
    try {
      const result = await client.post<TokenPair>('/web/auth/refresh', {
        refresh_token: this.refreshToken,
      });
      this.setTokenPair(result.data);
      return result.data.access_token;
    } catch (e) {
      const isTransient = e instanceof AxiosError && e.status === 502;
      if (!isTransient) this.signOut();
      throw new jwt.AuthError('Failed to refresh token', isTransient);
    }
  }

  private _notifyChanged() {
    const { isAuthenticated } = this;
    if (this._lastIsAuthenticated === isAuthenticated) return;
    this._lastIsAuthenticated = isAuthenticated;
    for (const listener of this._listeners) {
      listener(isAuthenticated);
    }
  }

  public refetchTokenPair() {
    // eslint-disable-next-line no-return-assign
    return (this._pendingRefetch ??= this._refetchTokenPair().finally(() => {
      this._pendingRefetch = null;
    }));
  }

  public setTokenPair({ access_token, refresh_token }: TokenPair) {
    jwt.tryUpdateTimestampSkewFromFreshToken(access_token);
    jwt.assertTokenValid(access_token);
    jwt.assertTokenValid(refresh_token);
    localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
    localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
    this._notifyChanged();
  }

  public signOut() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    this._notifyChanged();
  }

  public async ensureAccessToken() {
    const token = this.accessToken;
    return token ?? (await this.refetchTokenPair());
  }

  public onAuthStateChanged(listener: AuthStateListener) {
    listener(this.isAuthenticated);
    this._listeners.push(listener);
    return () => {
      this._listeners = this._listeners.filter((x) => x !== listener);
    };
  }

  public init() {
    this._notifyChanged();
  }
}

export const auth = new AuthService();

/**
 * Login to backend and store JSON web token on success
 *
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const login = (email: string, password: string): Promise<void> => {
  // Assert email or password is not empty
  if (!(email.length > 0)) {
    toast.error('Email was not provided');
  }
  if (!(password.length > 0)) {
    toast.error('Password was not provided');
  }

  const credentials: CredentialsInSchema = {
    email,
    password,
  };

  return client
    .post<TokenPair>('/web/auth/login', credentials)
    .then((response) => {
      auth.setTokenPair(response.data);
    });
};

export const sendPasswordResetEmail = (data: SendResetPasswordCodeInSchema) => {
  return client.post<ResetPasswordOutSchema>(
    '/web/auth/reset-password/send-token',
    data
  );
};

export const confirmPasswordReset = async (
  data: ConfirmResetPasswordInSchema
) => {
  try {
    const result = await client.post<ResetPasswordOutSchema>(
      '/web/auth/reset-password/confirm',
      data
    );
    return result.data;
  } catch (e) {
    // don't throw; the result contains more information
    if (e instanceof AxiosError && e.response?.status === 400) {
      return e.response.data as ResetPasswordOutSchema;
    }
    throw e;
  }
};

/**
 * Login to backend using a generated custom impersonation token and store JWT on success
 *
 * @param customToken
 * @returns true if user successfully signed in
 * @throws Error on http errors or failed attempts
 */
export const loginWithCustomToken = async (customToken: string) => {
  // Assert custom token is not empty
  if (!(customToken.length > 0)) {
    throw new Error('Custom token was not provided');
  }

  const token: LoginTokenInSchema = {
    token: customToken,
  };

  return client
    .post<TokenPair>('/web/auth/login-with-token', token)
    .then((response) => {
      auth.setTokenPair(response.data);
    });
};

export const handleLogout = () => {
  auth.signOut();
  window.location.href = '/';
};
