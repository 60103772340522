export const passwordValidators = [
  {
    validator: (password: string) => password.length >= 8,
    message: 'passwordValidation.lengthValidation',
  },
  {
    validator: (password: string) => /[a-z]/.test(password),
    message: 'passwordValidation.lowerCaseValidation',
  },
  {
    validator: (password: string) => /[A-Z]/.test(password),
    message: 'passwordValidation.upperCaseValidation',
  },
  {
    validator: (password: string) => /[0-9]/.test(password),
    message: 'passwordValidation.numberValidation',
  },
  {
    validator: (password: string) =>
      /[~!?@#$%^&*()[\]{}></\\|'.,;:]/.test(password),
    message: 'passwordValidation.specialCharacterValidation',
  },
];

export const validatePassword = (password: string) =>
  passwordValidators.every((validator) => validator.validator(password));

export const validatePasswords = (password: string, confirmPassword: string) =>
  validatePassword(password) && password === confirmPassword;
