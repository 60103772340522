export const scope1Items = [
  { value: 'scope_1.fugitive_emissions', label: 'scope_1-fugitive_emissions' },
  { value: 'scope_1.mobile_combustion', label: 'scope_1-mobile_combustion' },
  {
    value: 'scope_1.stationary_combustion',
    label: 'scope_1-stationary_combustion',
  },
  { value: 'scope_1.process_emissions', label: 'scope_1-process_emissions' },
];

export const scope2Items = [
  { value: 'scope_2.cooling', label: 'scope_2-cooling' },
  { value: 'scope_2.electricity', label: 'scope_2-electricity' },
  { value: 'scope_2.heat', label: 'scope_2-heat' },
  { value: 'scope_2.steam', label: 'scope_2-steam' },
];

export const scope3Items = [
  { value: 'scope_3.business_travel', label: 'scope_3-business_travel' },
  { value: 'scope_3.capital_goods', label: 'scope_3-capital_goods' },
  {
    value: 'scope_3.downstream_leased_assets',
    label: 'scope_3-downstream_leased_assets',
  },
  {
    value: 'scope_3.downstream_transportation_and_distribution',
    label: 'scope_3-downstream_transportation_and_distribution',
  },
  { value: 'scope_3.employee_commuting', label: 'scope_3-employee_commuting' },
  {
    value: 'scope_3.end_of_life_treatment_of_sold_products',
    label: 'scope_3-end_of_life_treatment_of_sold_products',
  },
  { value: 'scope_3.franchises', label: 'scope_3-franchises' },
  {
    value: 'scope_3.other_fuel_and_energy',
    label: 'scope_3-other_fuel_and_energy',
  },
  { value: 'scope_3.investments', label: 'scope_3-investments' },
  {
    value: 'scope_3.processing_of_sold_products',
    label: 'scope_3-processing_of_sold_products',
  },
  {
    value: 'scope_3.purchased_good_services',
    label: 'scope_3-purchased_good_services',
  },
  {
    value: 'scope_3.upstream_leased_assets',
    label: 'scope_3-upstream_leased_assets',
  },
  {
    value: 'scope_3.upstream_transportation_and_distribution',
    label: 'scope_3-upstream_transportation_and_distribution',
  },
  {
    value: 'scope_3.use_of_sold_products',
    label: 'scope_3-use_of_sold_products',
  },
  {
    value: 'scope_3.waste_generated_in_operations',
    label: 'scope_3-waste_generated_in_operations',
  },
];

export const generalItems = [
  { value: 'scope_1', label: 'scope_1' },
  { value: 'scope_2', label: 'scope_2' },
  { value: 'scope_3', label: 'scope_3' },
  { value: 'all', label: 'all' },
];
