import { AxiosResponse } from 'axios';
import { client } from '../utils/api-client';
import { OrganizationDataOutSchema } from 'src/utils/api.interfaces';

const wrap = <TResponse>(response: Promise<AxiosResponse<TResponse, any>>) =>
  response.then((r) => r.data);

type OrganizationIdParams = {
  organizationId: number;
};

// eslint-disable-next-line import/prefer-default-export
export const getOrganization = ({ organizationId }: OrganizationIdParams) =>
  wrap(
    client.get<OrganizationDataOutSchema>(
      `/web/organizations/${organizationId}`
    )
  );
