import React from 'react';
import { OrganizationalLogoOutSchema } from './types';
import { styled } from '@mui/material';

type OrganizationalLogoProps = {
  logo: OrganizationalLogoOutSchema | null;
};

const Logo = styled('img')({
  minWidth: '135px',
  maxWidth: '270px',
  height: 'auto',
  maxHeight: '270px',
  padding: '16px',
});

export default function OrganizationalLogo({ logo }: OrganizationalLogoProps) {
  if (!logo) return null;
  return <Logo src={logo.url} />;
}
