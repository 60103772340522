import React from 'react';

import { UniversalField } from '../types';
import { useTranslation } from 'react-i18next';
import { HOVER_COLOR } from '../../../helpers';
import { StyledTitle } from '../../index.styles';
import { TextField } from '@mui/material';

export default function SimpleTextField(props: UniversalField) {
  const { t } = useTranslation();

  const { field, value, onChange, errors, disabled } = props;
  const {
    title,
    label,
    placeholder,
    required,
    theme_color: themeColor,
  } = field;

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  /* backend side validation */
  React.useEffect(() => {
    const newMessage = errors?.[field.key];
    if (!!newMessage) setErrorMessage(newMessage);
  }, [errors]);

  /* Frontend side validation */
  const _validate = (_value: string) => {
    // excludes internal input validation error for cases: "0."
    const endsWithSingleDot = /^[^.]*\.$/.test(_value);

    // checking if value is a number
    const isNumber = /^\d+(\.\d+)?$/.test(String(_value));

    // set numeric validation message or clear if exists
    if (!isNumber && _value !== '' && !endsWithSingleDot) {
      setErrorMessage(
        t('questionnaireV3.fieldTypes.decimal.numberValidationErrorMessage')
      );
    }
    // required field
    else if (required && !_value) {
      setErrorMessage(t('questionnaireV3.field.fieldRequired'));
    }
    // cleaning error messages if none
    else if (!!errorMessage) {
      setErrorMessage(undefined);
    }
  };

  // local handle change injects frontend validation
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: _value } = e.target;
    _validate(_value);
    onChange(!!_value ? _value : null);
  };

  return (
    <>
      {!!title && <StyledTitle>{title}</StyledTitle>}

      <TextField
        fullWidth
        value={value}
        label={label}
        onChange={handleChange}
        error={!!errorMessage}
        helperText={errorMessage}
        disabled={disabled}
        placeholder={placeholder}
        sx={{
          mb: '32px',
          '& .MuiOutlinedInput-root': {
            backgroundColor: !!value ? HOVER_COLOR : 'inherit',
          },
        }}
      />
    </>
  );
}
