import React, { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTextFieldProps, FormControlLabel, FormGroup } from '@mui/material';
import {
  Button,
  Icon,
  Label,
  StyledCheckbox,
  StyledTextField,
  Task,
} from './index.styles';
import deleteIcon from '../../../images/delete.png';
import iconWhite from './icon-white.png';
import iconDark from './icon.png';
import { TaskListProps } from './index.interfaces';

export default function TaskList({
  tasks,
  deleteTask,
  addTask,
  changeTask,
}: TaskListProps) {
  const { t } = useTranslation();
  const inputRef = createRef<BaseTextFieldProps>();
  const [clicked, setClicked] = useState(false);
  const [value, setValue] = useState('');

  return (
    <div>
      <FormGroup>
        {tasks.length === 0 && <p>{t('recommendations.empty-list')}</p>}
        {tasks.map((task) => (
          <Task key={new Date().getTime() + task.id}>
            <FormControlLabel
              control={
                <StyledCheckbox
                  checked={task.checked}
                  onChange={() => {
                    changeTask(task.id, !task.checked);
                  }}
                />
              }
              label={<Label checked={task.checked}>{task.title}</Label>}
            />
            <Icon
              src={deleteIcon}
              onClick={() => {
                deleteTask(task.id);
              }}
            />
          </Task>
        ))}
        {clicked && (
          <StyledTextField
            inputRef={inputRef}
            hiddenLabel
            autoFocus
            onBlur={() => {
              if (value === '') setClicked(false);
            }}
            placeholder={t('recommendations.placeholder')}
            multiline
            variant="filled"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && value !== '') {
                addTask(value);
                setValue('');
                setClicked(false);
              }
            }}
          />
        )}
        <Button
          clicked={clicked}
          onClick={() => {
            if (!clicked) setClicked(true);
            else if (value !== '') {
              addTask(value);
              setValue('');
              setClicked(false);
            }
          }}
        >
          <img src={clicked ? iconWhite : iconDark} alt="plus icon" />
          <span>{t('recommendations.add-task')}</span>
        </Button>
      </FormGroup>
    </div>
  );
}
