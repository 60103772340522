import React from 'react';

export default function MandatoryCheckIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="check_nonclickable">
        <circle id="Ellipse 332" cx="12" cy="12" r="12" fill="#38414F" />
        <rect
          id="Rectangle 2057"
          x="6.83789"
          y="10.9351"
          width="7.57895"
          height="2.52632"
          rx="1.26316"
          transform="rotate(45 6.83789 10.9351)"
          fill="white"
        />
        <rect
          id="Rectangle 2058"
          x="17.5547"
          y="7.3623"
          width="2.52632"
          height="12.6316"
          rx="1.26316"
          transform="rotate(45 17.5547 7.3623)"
          fill="white"
        />
      </g>
    </svg>
  );
}
