import React from 'react';

import { Box, SxProps } from '@mui/material';

import { StyledPanel, StyledTitle } from 'src/Superadmin/index.styles';
import LcaUserPermissionsTable from './Table';
import { useTranslation } from 'react-i18next';

interface Props {
  sx?: SxProps;
  userId: number;
}

export default function LcaUserPermissions(props: Props) {
  const { sx, userId } = props;
  const { t } = useTranslation();

  return (
    <Box sx={sx}>
      <StyledTitle sx={{ fontSize: '28px' }}>
        {t('superadmin.settings.tabs.users.usersTable.permissions.title.lca')}
      </StyledTitle>

      <StyledPanel sx={{ mt: '32px' }}>
        <LcaUserPermissionsTable userId={userId} />
      </StyledPanel>
    </Box>
  );
}
