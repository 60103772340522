import styled from 'styled-components';

export const Units = styled.span`
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  padding-top: 8px;
`;

export const Main = styled.p`
  font-weight: 700;
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  word-break: break-all;
  line-height: 1;
`;

export const Container = styled.div`
  margin-bottom: 40px;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const MainTitle = styled.h2`
  font-weight: 600;
  font-size: 22px;
  color: #2f2f2f;
  margin-bottom: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Block = styled.div`
  border-right: 1px solid #b6bbb9;
  color: #606462;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 24px;
  padding-right: 10px;
  width: 25%;

  &:nth-child(4) {
    border-right: 0;
  }
  &:nth-child(8) {
    border-right: 0;
  }
`;
