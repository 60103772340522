import { Box, Tab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPanel = styled(Box)`
  padding: 36px 32px;
  border-radius: 16px;
  border: 1px solid #ccc;
  background: #fff;
`;

export const StyledTitle = styled(Typography)`
  display: flex;
  align-items: center;
  color: #38414f;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.64px;
`;

export const StyledContainer = styled(Box)`
  padding: 10px;
`;

export const StyledDescription = styled(Box)`
  color: #38414f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.48px;
  padding: 20px;
`;

export const StyledTab = styled(Tab)`
  text-transform: none;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.36px;
`;
