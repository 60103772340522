import styled from 'styled-components';

export const RowTriple = styled.div`
  display: grid;
  grid-template-columns:
    calc(33% - 0.7rem)
    calc(33% - 0.7rem)
    calc(33% - 0.7rem);
  grid-column-gap: 1.5rem;
`;

export const Text = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: #2f2f2f;
  margin-bottom: 24px;
`;
