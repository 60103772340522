import React from 'react';
import {
  styled as styledMUI,
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import { colors } from 'src/Lca/components/Button/helpers';

export const StyledTitle = styledMUI('div')`
  color: #281302;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.48px;
  padding: 20px 50px 0px 20px;
`;

export const StyledSectionName = styledMUI('div')`
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  padding: 10px 0px;
`;

const getSelectBorderColor = (props: any) => {
  if (props.error) {
    return '#d32f2f';
  }
  if (!!props?.categorykey) {
    return colors[props.categorykey].shadow;
  }
  return '#000';
};

export const StyledTextField = styledMUI(TextField)<{
  value: any;
  categorykey?: string;
}>`

background-color: ${(props) => (!!props?.value ? '#F6F6F6' : 'inherit')};

label.Mui-focused {
    color: #281302;    
  }

  .MuiOutlinedInput-root {
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${getSelectBorderColor}
  }
`;

const getCustomTheme = (color: string) =>
  createTheme({
    palette: {
      primary: {
        main: color,
      },
    },
  });

export const StyledSelect = (props: any) => (
  <ThemeProvider
    theme={getCustomTheme(colors[props?.categorykey || 'default'].shadow)}
  >
    <FormControl fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select
        sx={{
          backgroundColor: props?.value ? '#F6F6F6' : 'inherit',
        }}
        {...props}
      >
        {props.children}
      </Select>
      <FormHelperText sx={{ color: 'red' }}>{props.error}</FormHelperText>
    </FormControl>
  </ThemeProvider>
);

export const StyledRow = styledMUI('div')`
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledLine = styledMUI('div')`
  height: 10px;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  margin: 10px 0px;
`;
