import React from 'react';
import { EsgSummary } from './types';
import { useQuery } from 'react-query';

import YesOrNoTable from './Tables/YesOrNoTable';
import MultiAnswerTable, { AggregationMode } from './Tables/MultiAnswerTable';
import { client } from '../../../utils/api-client';
import { CircularProgress } from '@mui/material';
import { categorizeData } from './Tables/utils';
import { themeColors } from '../QuestionnaireV3/helpers';

interface Props {
  organizationId: number;
  reportId: string;
}

export default function ESGSummary({ organizationId, reportId }: Props) {
  const { data, error, isFetching } = useQuery<EsgSummary>(
    'esg-summary-pdf-report-data',
    () =>
      client
        .get(`/web/esg_v2/${organizationId}/${reportId}/all-answers`)
        .then((response) => response.data)
  );

  const [yesOrNoData, multianswerData] = categorizeData(data?.data || []);

  return (
    <>
      {(error || isFetching) && (
        <div style={{ width: '100%', textAlign: 'center' }}>
          {!!error && String(error)}
          {isFetching && (
            <CircularProgress sx={{ color: themeColors['esg.standard'] }} />
          )}
        </div>
      )}

      {!error && !isFetching && !!data?.data?.length && (
        <div>
          <YesOrNoTable data={yesOrNoData} />
          <br />
          <br />
          <MultiAnswerTable
            data={multianswerData}
            aggregation={AggregationMode.ByQuestion}
          />
        </div>
      )}
    </>
  );
}
