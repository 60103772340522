import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import BasePage from './Base';
import { PDF_WIDTH, MARGIN_LEFT } from '../config';
import { EsgSummary } from '../../../../components/ESGSummary/types';

// need to use Stylesheets since react-pdf is not supporting classNames or styled-components
const styles = StyleSheet.create({
  title: {
    marginTop: 340,
    marginLeft: MARGIN_LEFT,
    width: 1700,
    height: 500,
    color: '#38414F',
    fontFamily: 'Poppins',
    fontSize: 120,
    fontStyle: 'bold',
    fontWeight: 700,
    letterSpacing: 2,
  },
  context: {
    position: 'absolute',
    top: 860,
    left: MARGIN_LEFT,
    width: PDF_WIDTH - 3 * MARGIN_LEFT,
    color: '#5A636F',
    fontFamily: 'Poppins',
    fontSize: 100,
    fontStyle: 'normal',
    letterSpacing: 2,
  },
  companyName: {
    position: 'absolute',
    top: 3000,
    left: MARGIN_LEFT,
    width: PDF_WIDTH - 3 * MARGIN_LEFT,
    color: '#5A636F',
    fontFamily: 'Poppins',
    fontSize: 84,
    fontStyle: 'normal',
    letterSpacing: 2,
  },
});

type Props = {
  esgSummary: EsgSummary;
};

const CoverPage = ({ esgSummary }: Props) => {
  const { title, context, company_name: companyName } = esgSummary.pdf.cover;

  return (
    <BasePage backgroundImageName="cover.png">
      <View>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.context}>{context}</Text>
        <Text style={styles.companyName}>{companyName}</Text>
      </View>
    </BasePage>
  );
};

export default CoverPage;
