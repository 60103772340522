import { Typography } from '@mui/material';
import InfoPanel from '../components/InfoPanel';
import React from 'react';

export default function OperatorInfoPanel() {
  return (
    <InfoPanel>
      <Typography>
        Operator is an entity operating or controlling an Installation. It is
        responsible for collecting and reporting data on the Installation and
        its emissions.
      </Typography>
    </InfoPanel>
  );
}
