import { t } from 'i18next';

// eslint-disable-next-line import/prefer-default-export
export const parseResponseMessage = (
  errors: { [key: string]: string } | undefined
) => {
  const responseMessages: { [key: string]: string } = {
    'none is not an allowed value': t('questionnaireV3.field.fieldRequired'),
  };
  return Object.fromEntries(
    Object.entries(errors || {}).map(([key, value]) => [
      key,
      responseMessages[value] || value,
    ])
  );
};
